import { StateEntryProps } from '.';

export const videoDisplayStateEntry: StateEntryProps = {
  type: 'renderVideoDisplay',
  temporary: false,
  payload: {
    src: ' ',
    assetid: ' ', // the blank space is important, as empty strings ar enot recognized by intl
  },
  displayName: 'Video Display',
  nodeType: 'videoDisplayStateEntry',
  handleOutCount: 1,
};
