import { Typography } from '@mui/material';
import { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AccountContext, RoleEnum } from '../account';
import { useEvoachKeycloak } from '../account/useEvoachKeycloak';
import { CreateNewProgram } from '../components/CoachingPrograms/CreateNewProgram';
import { CreateNewModule } from '../components/CreateNewModule';
import {
  AnalyticsTab,
  AssetTab,
  InvitationTab,
  SharedModulesTab,
} from '../components/reception';
import { AdminAnalyticsTab } from '../components/reception/analyticsevoach';
import { ProgramAndInstanceTabs } from '../components/reception/coachingprograms/ProgramAndInstanceTabs';
import { ModuleListWithCards } from '../components/reception/modules/ModuleListWithCards';
import { SharedSessionList } from '../components/reception/sharedsessions/SharedSessionList';
import { TemplateTab } from '../components/reception/templates';
import { DevToolsContext } from '../devtools/DevToolsContext';
import { FeatureEnum } from '../entities/subscriptionTypes';
import { AppLayout } from '../layout/AppLayout';
import { AICoachSubscriptionPage } from '../pages/AICoachSubscriptionPage';
import { BuilderPage } from '../pages/BuilderPage';
import { DisclaimerPage } from '../pages/DisclaimerPage';
import { InvitationPage } from '../pages/InvitationPage';
import { NotLoggedInPage } from '../pages/NotLoggedInPage';
import { NoValidSubscriptionPage } from '../pages/NoValidSubscriptionPage';
import { ProfilePage } from '../pages/ProfilePage';
import { ProgramEditorPage } from '../pages/ProgramEditorPage';
import { ReceptionPage } from '../pages/ReceptionPage';
import { TranslationPage } from '../pages/TranslationPage';
import { PlansPage } from '../pages/PlansPage';

import {
  AppRoutes,
  InvitationRouteParamName,
  ModuleRouteLangParamName,
  ModuleRouteParamName,
  ProgramRouteParamName,
  ProgramRouteTypeParamName,
  ReceptionRoutes,
} from './routes';

const appRouterMessages = defineMessages({
  loading: {
    id: 'builder.approuter.loading',
    defaultMessage: 'Anwendung wird geladen ...',
  },
});

export const AppRouter: React.FC = () => {
  const { isAuthenticated } = useEvoachKeycloak();

  const {
    hasRole,
    hasFeature,
    isLoading: accountIsLoading,
  } = useContext(AccountContext);

  const path = window.location.pathname;

  // the following logging is not included in the keyloack context as the
  // concnering DevToolsContext is not available there
  const { l } = useContext(DevToolsContext);
  l('AppRouter: isLoading: ' + accountIsLoading);
  l('AppRouter: isAuthenticated: ' + isAuthenticated);

  // while user profile data is loading, display "loading" message ...
  if (accountIsLoading) {
    return (
      <Typography variant="body2" component="span">
        <FormattedMessage {...appRouterMessages.loading} />
      </Typography>
    );
  }

  // check whether the current login has the Creator Feature. This may be
  // false if the coach has no valid subscription (e.g. after a 30 days trial)
  if (
    path !== AppRoutes.SUBSCRIBE &&
    path !== AppRoutes.PLANS &&
    !accountIsLoading &&
    isAuthenticated &&
    !hasFeature(FeatureEnum.CREATOR_B)
  ) {
    return (
      <BrowserRouter>
        <AppLayout>
          <NoValidSubscriptionPage />
        </AppLayout>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route
            path={AppRoutes.PROFILE}
            element={
              !isAuthenticated ? (
                <Navigate to={AppRoutes.LOGIN} />
              ) : (
                <ProfilePage />
              )
            }
          />
          <Route
            path={AppRoutes.DISCLAIMER}
            element={
              !isAuthenticated ? (
                <Navigate to={AppRoutes.LOGIN} />
              ) : (
                <DisclaimerPage />
              )
            }
          />
          <Route
            path={AppRoutes.LOGIN}
            element={
              isAuthenticated ? (
                <Navigate to={AppRoutes.RECEPTION} />
              ) : (
                <NotLoggedInPage />
              )
            }
          />

          <Route
            path={AppRoutes.SIGNUP}
            element={
              isAuthenticated ? (
                <Navigate to={AppRoutes.RECEPTION} />
              ) : (
                <NotLoggedInPage isSignupPage />
              )
            }
          />
          <Route
            path={AppRoutes.RECEPTION}
            element={
              !isAuthenticated ? (
                <Navigate to={AppRoutes.LOGIN} />
              ) : (
                <ReceptionPage />
              )
            }
          >
            <Route
              index
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ModuleListWithCards />
                )
              }
            />
            <Route
              path={ReceptionRoutes.MODULES}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ModuleListWithCards />
                )
              }
            />
            <Route
              path={ReceptionRoutes.TEMPLATES}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <TemplateTab />
                )
              }
            />
            <Route
              path={ReceptionRoutes.INVITATIONS}
              element={
                !isAuthenticated ? (
                  <Navigate to={`${AppRoutes.LOGIN}?c=moduleinvitation`} />
                ) : (
                  <InvitationTab />
                )
              }
            />
            <Route
              path={ReceptionRoutes.SHARED_MODULES}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <SharedModulesTab />
                )
              }
            />
            <Route
              path={ReceptionRoutes.ASSETS}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <AssetTab />
                )
              }
            />
            <Route
              path={ReceptionRoutes.SHARED_SESSIONS}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <SharedSessionList />
                )
              }
            />
            <Route
              path={ReceptionRoutes.ANALYTICS}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <AnalyticsTab />
                )
              }
            />
            {hasRole(RoleEnum.EVOACHADMIN) && (
              <Route
                path={ReceptionRoutes.ANALYTICS_EVOACH}
                element={
                  !isAuthenticated ? (
                    <Navigate to={AppRoutes.LOGIN} />
                  ) : (
                    <AdminAnalyticsTab />
                  )
                }
              />
            )}
            <Route
              path={ReceptionRoutes.COACHING_PROGRAMS}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ProgramAndInstanceTabs />
                )
              }
            />
            <Route
              path={ReceptionRoutes.CURRENT_PROGRAMS}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ProgramAndInstanceTabs showInstanceTab />
                )
              }
            />
            <Route
              path="*"
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ModuleListWithCards />
                )
              }
            />
          </Route>
          <Route
            path={AppRoutes.NEWBUILDER}
            element={
              !isAuthenticated ? (
                <Navigate to={AppRoutes.LOGIN} />
              ) : (
                <CreateNewModule />
              )
            }
          />
          <Route
            path={AppRoutes.NEWPROGRAM}
            element={
              !isAuthenticated ? (
                <Navigate to={AppRoutes.LOGIN} />
              ) : (
                <CreateNewProgram />
              )
            }
          />
          <Route
            path={AppRoutes.SUBSCRIBE}
            element={<AICoachSubscriptionPage />}
          />
          <Route path={AppRoutes.PLANS} element={<PlansPage />} />
          <Route
            path={`${AppRoutes.PROGRAMEDITOR}/:${ProgramRouteParamName}/:${ProgramRouteTypeParamName}`}
            element={
              !isAuthenticated ? (
                <Navigate to={AppRoutes.LOGIN} />
              ) : (
                <ProgramEditorPage />
              )
            }
          />
          <Route
            path={`${AppRoutes.INVITATION}/:${InvitationRouteParamName}`}
            element={
              !isAuthenticated ? <NotLoggedInPage /> : <InvitationPage />
            }
          />
          <Route
            path={`${AppRoutes.PROGRAMINVITATION}/:${InvitationRouteParamName}`}
            element={
              !isAuthenticated ? <NotLoggedInPage /> : <InvitationPage />
            }
          />
          <Route
            path={`${AppRoutes.BUILDER}/:${ModuleRouteParamName}/:${ModuleRouteLangParamName}`}
            element={
              !isAuthenticated ? (
                <Navigate to={AppRoutes.LOGIN} />
              ) : (
                <BuilderPage />
              )
            }
          />
          <Route
            path={`${AppRoutes.TRANSLATION}/:${ModuleRouteParamName}/:${ModuleRouteLangParamName}`}
            element={
              !isAuthenticated ? (
                <Navigate to={AppRoutes.LOGIN} />
              ) : (
                <TranslationPage />
              )
            }
          />

          <Route
            path={AppRoutes.BLANK}
            element={<Navigate to={AppRoutes.RECEPTION} />}
          />
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
};
