import { CardImageEnum, generateRandomString } from '@evoach/ui-components';

import { ReadWriteType, StateMachine } from '../components/nodes/base.state';
import { ReactFlowElements } from '../components/ModuleEditor/ReactFlowHelper';

import { ModulePermission } from './ModulePermissions';
import { ModuleTranslation, TranslationProps } from './ModuleTranslation';
import { MetaDataProps, ModulePhase } from './MetaData';

// ! IMPORTANT
// DO NOT rename this interface to "Module" because this leads to
// strange behavior due to components in other libs that have the same name

export interface ModuleProps {
  [index: string]:
    | number
    | string
    | ModuleGlobalVarProps[]
    | Date
    | boolean
    | ModuleTranslation[]
    | ModulePermission[]
    | StateMachine
    | MetaDataProps
    | ReactFlowElements
    | CardImageEnum;

  mid: number;
  moduleid: string;
  tscreated: Date;
  tsupdated: Date;
  issubmodule: boolean;
  published: boolean;
  tspublished: Date;
  isdefaultmodule: boolean;
  uiTheme: string;
  //globalvariableaccessrequired: boolean; // PROD-1856
  permissions: ModulePermission[];
  translations: ModuleTranslation[];
  statemachine: StateMachine;
  metadata: MetaDataProps;
  buildergraph: ReactFlowElements;
  publicsessionprogressbar: boolean;
  providedglobalvariables: ModuleGlobalVarProps[];
  requiredglobalvariables: ModuleGlobalVarProps[];
}

/**
 * format in which we store global vars.
 */
export type ModuleGlobalVarProps = {
  /**
   * nodeType for which the variable is applied. This helps us
   * to generate default values when previewing a chatbot.
   */
  nodeType: string;
  /**
   * name of the variable
   */
  name: string;
  /**
   * We use this saveResultType to filter the
   * appropriate type for SelectValueFrom selections.
   * Due du MultipleOut, we also have to consider ReadWriteType[]
   */
  saveResultType: ReadWriteType | ReadWriteType[];
  /**
   * contains the value of a variable, especially in player
   */
  value?: Record<string, any>;
};

/*
  ! initialModule and initalModuleTemplate

  do not use this const directly but via getInitialModule function below => 
  it makes the translation keys unique
*/

const initialModuleTemplate: ModuleProps = {
  providedglobalvariables: [],
  requiredglobalvariables: [],
  statemachine: {} as StateMachine,
  mid: 0,
  moduleid: '',
  permissions: [],
  tscreated: new Date(),
  tsupdated: new Date(),
  tspublished: new Date(),
  metadata: {
    moduleLanguages: ['EN'],
    defaultLanguage: 'EN',
    title: '',
    description: '',
    duration: '',
    tscreated: '',
    tsupdated: '',
    tspublished: '',
    phases: [] as ModulePhase[],
    image: CardImageEnum.announcement,
    labels: [] as string[],
  } as MetaDataProps,
  publicsessionprogressbar: true,
  translations: [
    {
      lang: 'EN',
      metadatatranslation: {} as unknown as TranslationProps,
      statemachinetranslation: {},
    },
  ] as ModuleTranslation[],
  buildergraph: [] as ReactFlowElements,
  issubmodule: false,
  published: false,
  isdefaultmodule: false,
  uiTheme: '',
};

/*
{
  lang: 'DE',
  statemachinetranslation: {} as TranslationProps,
  metadatatranslation: ,
} as unknown as ModuleTranslation,
*/
export let initialModule = initialModuleTemplate;

/** modify template for initial module in global exported variable initialModule
 * @params {boolean} @optional asSubmodule (default is false) - if set, the flag issubmodule for the module is set
 * make keynames for metadata translation unique. For all future translations, we use the moduleid
 * to make the key unique. This is not working here because the module is not yet created,thus, we do
 * not have a moduleid. That's why we generate a random id
 */
export const prepareInitialModule = (asSubmodule?: boolean) => {
  initialModule = initialModuleTemplate;

  // fake string to be used in CreateNewModule.tsx => make useCreateEmptyModule unique
  initialModule.moduleid = generateRandomString(8);

  initialModule.issubmodule = asSubmodule !== undefined && asSubmodule === true;

  // uuid()
  const randomUUID = generateRandomString();
  const adaptlist = ['title', 'description', 'duration'];

  // reset metadata translations
  initialModule.translations[0].metadatatranslation = {};

  adaptlist.forEach((param: string) => {
    initialModule.metadata[param] = `${randomUUID.toString()}.${param}`;
    initialModule.translations[0].metadatatranslation[
      `${randomUUID.toString()}.${param}`
    ] = initialModule.translations[0].metadatatranslation[param];
    delete initialModule.translations[0].metadatatranslation[param];
  });
};
