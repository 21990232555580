import { CreatorImageProps } from '@evoach/ui-components';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AccountContext } from '../../account';
import { mapImageToPublicAsset } from '../../components/reception/assets/AssetHelper';
import { DevToolsContext } from '../../devtools/DevToolsContext';
import { ModuleProps } from '../../entities/Module';
import { ModulePermission } from '../../entities/ModulePermissions';
import { useEnvironment } from '../../environment/useEnvironment';
import { TranslationContext } from '../../intl/TranslationContext';
import { getAssetUrl } from '../asset/useFetchAssetUrl';
import { authorizedGet } from '../authorizedApi';

/**
 * useFetchModulesQuery
 *
 * if you make changes here, consider reviweing useFetchModules
 * which is slightly different in managing languages
 */

export const useFetchModulesQuery = (language: string | undefined) => {
  const { appendStaticMessages, locale } = useContext(TranslationContext);
  const { account } = useContext(AccountContext);
  const { l } = useContext(DevToolsContext);
  const { playerBasePath } = useEnvironment();

  // when a language is passed, we enforce that language as a filter
  let languageForModuleFetching = language ?? locale;
  let enforcefallback = false;

  l(
    'useFetchModulesQuery: languageForModuleFetching: ' +
      languageForModuleFetching
  );
  l('useFetchModulesQuery: enforcefallback: ' + enforcefallback);

  // if the selected language is "all" (e.g. by filtering in ModuleListWithCards),
  // we try to get the language of the UI as default but enforce a fallback to any
  // other language
  if (languageForModuleFetching.toLowerCase() === 'all') {
    languageForModuleFetching = locale;
    enforcefallback = true;
  }

  // get corresponding filter with useQuery
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `modules-${languageForModuleFetching}-${enforcefallback}`,
    async () => {
      const getModules = authorizedGet(
        `/module?language=${languageForModuleFetching}&enforcefallback=${enforcefallback}&withdefaultmodules=true`
      );
      const response = await getModules();
      const data: ModuleProps[] = await response.json();

      // resolve images for list
      for (let i = 0; i < data.length; i++) {
        if (data[i].metadata.assetid && data[i].metadata.assetid !== '') {
          data[i].metadata.resolvedsrc = await getAssetUrl(
            data[i].metadata.assetid ?? ''
          );
        } else {
          if (data[i].metadata.src && data[i].metadata.src !== '') {
            data[i].metadata.resolvedsrc = data[i].metadata.src;
          } else {
            if (data[i].metadata.image !== undefined) {
              data[i].metadata.resolvedsrc = mapImageToPublicAsset(
                data[i].metadata.image,
                playerBasePath
              );
            } else {
              data[i].metadata.resolvedsrc = '';
            }
          }
        }

        //
        // resolve coach logos
        //
        const providerAccounts = getModuleProviderAccounts(
          data[i].permissions,
          data[i].isdefaultmodule
        );

        let creatorImages: CreatorImageProps[] = [
          {
            imageURL: undefined,
            logoURL: undefined,
            firstName: '',
            lastName: '',
          },
        ];
        if (providerAccounts && Object.keys(providerAccounts).length !== 0) {
          const accountId = Object.keys(providerAccounts)[0];
          const firstInvitingAccount = providerAccounts[accountId];

          // if "invitingAccount" is the current user, then use account
          const profile =
            account?.accountid === accountId
              ? account?.metainfos?.profile
              : firstInvitingAccount?.metainfos?.profile;

          // Fallback logic for showing pictures of cards: if image exist, then use it.
          // If not, try the next.
          // logoPictureSrc > logoPictureAssetId > profilePictureSrc > profilePictureAssetId
          const coachLogoURL = profile?.logoPictureSrc
            ? profile?.logoPictureSrc
            : profile?.logoPictureAssetId
            ? await getAssetUrl(profile?.logoPictureAssetId)
            : undefined;

          const profileImageURL = profile?.profilePictureSrc
            ? profile?.profilePictureSrc
            : profile?.profilePictureAssetId
            ? await getAssetUrl(profile?.profilePictureAssetId)
            : undefined;

          creatorImages = [
            {
              imageURL: profileImageURL,
              logoURL: coachLogoURL,
              firstName: firstInvitingAccount?.givenname,
              lastName: firstInvitingAccount?.familyname,
            },
          ];
        }

        data[i].metadata.resolvedCoachLogoSrc = {
          creatorImages: creatorImages,
        };
      }

      // extract metadata translation from each module and check for
      // availability of requested language
      const translationsOfAllModules = data
        .map((currentModule: any) => {
          const allTranslations = currentModule.translations;
          let metaDataTranslationsInCurrentLanguage;

          // try to find a translation that matches the requested language
          metaDataTranslationsInCurrentLanguage = allTranslations
            .filter(
              (translation: any) =>
                translation.lang === languageForModuleFetching
            )
            .map((translation: any) => translation.metadatatranslation)
            .reduce(
              (prev: any, metaTranslations: any) => ({
                ...prev,
                ...metaTranslations,
              }),
              {}
            );

          // if no language could be found and we requested all, take the first one provided
          if (
            language === 'all' &&
            Object.keys(metaDataTranslationsInCurrentLanguage).length === 0
          ) {
            metaDataTranslationsInCurrentLanguage =
              currentModule.translations[0].metadatatranslation;
          }
          return metaDataTranslationsInCurrentLanguage;
        })
        .reduce(
          (prev: any, metaTranslations: any) => ({
            ...prev,
            ...metaTranslations,
          }),
          {}
        );

      // changed for PROD-1528, previously appendModuleMessages
      appendStaticMessages(translationsOfAllModules);

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    modules: data,
    refetch,
  };
};

//
// get the account(s) who provide the module
//
const getModuleProviderAccounts = (
  permissions: ModulePermission[],
  isDefaultModule: boolean
) => {
  const coaches: Record<string, any> = {};
  permissions
    .filter(
      (perm: ModulePermission) =>
        perm.owns ||
        (perm.invitinginvitationid !== null &&
          perm.invitinginvitationid !== undefined &&
          perm.invitinginvitationid !== '') ||
        isDefaultModule
    )
    // PROD-1839
    .filter((perm: ModulePermission) =>
      permissions.some(
        (allPermissionsPerm: ModulePermission) => allPermissionsPerm.owns
      )
        ? perm.owns
        : true
    )
    .forEach((perm: ModulePermission) => {
      if (isDefaultModule || perm.owns) {
        coaches[perm.account.accountid] = perm.account;
      } else {
        coaches[perm.invitingaccount.accountid] = perm.invitingaccount;
      }
    });
  return coaches;
};
