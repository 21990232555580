import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../routing/routes';

export const ProfileLink: React.FC = () => {
  const intl = useIntl();
  let navigate = useNavigate();

  const onclick = () => {
    // use navigate instead of .location.href to properly use React router and avoid page reloads
    navigate(`${AppRoutes.PROFILE}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" component="span">
        <div
          key="profilelink"
          onClick={() => {
            onclick();
          }}
        >
          {intl.formatMessage({
            id: 'builder.headermenu.menuitem.profilelink',
            defaultMessage: 'Mein Profil',
          })}
        </div>
      </Typography>
    </div>
  );
};
