// if onNodesChange fires several times per second in ModuleEditorPane
// we do not want to trigger a autoSave for "every pixel". That's why

import { authorizedPost } from '../../api/authorizedApi';

// we keep track whether an autoupdate was already started
let AutoSaveTriggered = false;

// if CMD+S was fired, this function is used to reset the value
export const setAutoSaveTriggered = (val: boolean) => {
  AutoSaveTriggered = val;
};

/**
 * Auto save triggers automatic saving of a module.
 *
 * Start auto-save in 1 seconds by creating a timeout trigger.
 * We fake a keydown event for CMD+S / CTRL+S as a direct onModuleSave()
 * call fails if directly used in any function due to React state management.
 *
 *  @optional @param {string} moduleid, if set it triggers sync of translation, @default '''
 * keys among all languages of a module. (PROD-1760)
 */
export const autoSave = (moduleid = '') => {
  if (!AutoSaveTriggered) {
    AutoSaveTriggered = true;
    setTimeout(() => {
      try {
        const evt = new KeyboardEvent('keydown', {
          key: 's',
          keyCode: 83,
          code: 'KeyS',
          ctrlKey: true,
          shiftKey: false,
          altKey: false,
          metaKey: false,
        });
        window.dispatchEvent(evt);
        // if module id is set, sync after save
        // assume that saving doesn't take longer than 1 s
        if (moduleid && moduleid.trim() !== '') {
          autoSync(moduleid);
        }
      } catch (_e) {
        console.error(_e);
      }
    }, 1000);
  }
};

// if operations are performed that influence translation keys,
// we auto-sync keys among languages
let AutoSyncTriggered = false;

// after triggering the sync, it has to be reset later
export const setAutoSyncTriggered = (val: boolean) => {
  AutoSyncTriggered = val;
};

/**
 * autoSync triggers backend and synchronizes translation keys among modules
 * It calls POST /module/:moduleid/translation/sync
 */
export const autoSync = (moduleid: string) => {
  if (!AutoSyncTriggered && moduleid && moduleid.trim() !== '') {
    AutoSyncTriggered = true;
    setTimeout(() => {
      try {
        const syncCall = authorizedPost(`/module/${moduleid}/translation/sync`);
        syncCall()
          .then((_res: Response) => {
            _res.json().then((_json: any) => {
              setAutoSyncTriggered(false);
            });
          })
          .catch((_reason: unknown) => {
            console.error(_reason);
          });
      } catch (_e) {
        console.error(_e);
      }
    }, 1000);
  }
};
