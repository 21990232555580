import React, { useContext, useMemo, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tooltip,
  Link,
  FormControl,
  Input,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import LabelIcon from '@mui/icons-material/Label';
import EditIcon from '@mui/icons-material/Edit';

import { useFetchAllAccounts } from '../../../api/account/useFetchAllAccounts';
import { Account } from '../../../entities';
import { SubscriptionDialog } from '../subscriptions/SubscriptionDialog';
import { formatDate, useTableStyles } from '../../tableHelper';
import { AccountMetainfosDialog } from '../subscriptions/AccountMetainfosDialog';
import { authorizedPost } from '../../../api';
import { WindowContext } from '../../window/WindowContext';

import { UserLabelDialog } from './UserLabelDialog';
import { AdminEditUser } from './AdminEditUser';

export const AdminUserList: React.FC = () => {
  const classes = useTableStyles();

  // admin only!
  const { accounts, refetch } = useFetchAllAccounts();

  const { alert } = useContext(WindowContext);

  // text filter for filtering accounts
  const [textFilter, setTextFilter] = useState<string>('');

  // if opening the account details like subscription infos, define account
  const [currentAccountId, setCurrentAccountId] = useState<string>('');
  // display subscription infos?
  const [subscriptionInfosOpen, setSubscriptionInfosOpen] =
    useState<boolean>(false);

  // open dialog to edit user
  const [editUserDialogOpen, setEditUserDialogOpen] = useState<boolean>(false);

  // display meta info dialog
  const [isOpenShowMetaInfo, setIsOpenShowMetaInfo] = useState<boolean>(false);
  // if opening the account details like subscription infos, define account
  const [currentMetaInfos, setCurrentMetaInfos] = useState<any>({});

  // user labels
  const [userLabelDialogIsOpen, setUserLabelDialogIsOpen] =
    useState<boolean>(false);

  const [orderBy, setOrderBy] = useState<string>('created');
  const [createdAsc, setCreatedAsc] = useState<boolean>(true);
  const [lastloginAsc, setLastloginAsc] = useState<boolean>(true);

  const [initialLabels, setInitialLabels] = useState<string[]>([]);

  const orderByCreated = () => {
    setOrderBy('created');
    setCreatedAsc(!createdAsc);
    return;
  };

  const orderByLastLogin = () => {
    setOrderBy('lastlogin');
    setLastloginAsc(!lastloginAsc);
    return;
  };

  const numberOfAccounts = accounts?.length ?? 0;

  const rows = useMemo(() => {
    if (!accounts) return [];
    return accounts
      .filter((acc: Account) => {
        return (
          acc.givenname.toLowerCase().includes(textFilter) ||
          acc.familyname.toLowerCase().includes(textFilter) ||
          acc.email.toLowerCase().includes(textFilter)
        );
      })
      .map((acc: Account) => {
        // create list object

        return {
          firstname: acc.givenname,
          lastname: acc.familyname,
          email: acc.email,
          numsubscriptions: acc.subscriptions.length,
          subscriptions: acc.subscriptions,
          lastlogin: formatDate(acc.tsLastLogin),
          tsLastLogin: acc.tsLastLogin,
          createdlogin: formatDate(acc.tscreated),
          tscreated: acc.tscreated,
          accountid: acc.accountid,
          iscoach: acc.roles.includes('Coach'),
          metainfos: acc.metainfos,
        };
      })
      .sort((a, b) => {
        if (orderBy === 'lastlogin') {
          return lastloginAsc
            ? new Date(b.tsLastLogin).valueOf() -
                new Date(a.tsLastLogin).valueOf()
            : new Date(a.tsLastLogin).valueOf() -
                new Date(b.tsLastLogin).valueOf();
        } else {
          return 0; // ignore this filter
        }
      })
      .sort((a, b) => {
        if (orderBy === 'created') {
          return createdAsc
            ? new Date(b.tscreated).valueOf() - new Date(a.tscreated).valueOf()
            : new Date(a.tscreated).valueOf() - new Date(b.tscreated).valueOf();
        } else {
          return 0; // ignore this filter
        }
      });
  }, [accounts, createdAsc, lastloginAsc, orderBy, textFilter]);

  const syncWithMarketing = async (accountid: string) => {
    if (!accountid) return;
    const syncurl: RequestInfo = `/account/${accountid}/sync`;

    const syncCall = authorizedPost(syncurl);

    try {
      await syncCall();
    } catch (_reason: any) {
      alert('Error while syncing with marketing automation');
      console.error(_reason);
      return;
    }
    alert('Sync with marketing automation successfull');
  };

  /**
   * copy to clipboard
   * code found @see https://www.w3.org/TR/clipboard-apis/#async-clipboard-api
   */
  const copyToClipboard = (email: string): void => {
    navigator.clipboard.writeText(email);
  };

  return (
    <>
      <Box>
        <Box>
          <FormControl
            variant="standard"
            style={{
              display: 'inline',
              alignContent: 'bottom',
              alignItems: 'bottom',
            }}
          >
            <Typography
              variant="body2"
              color="#AAAAAA"
              style={{ marginRight: '10px' }}
              component="span"
            >
              {rows.length}/{numberOfAccounts}
            </Typography>
            <Input
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                if (e.target.value !== undefined) {
                  setTextFilter(e.target.value.toLowerCase());
                }
              }}
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              }
              autoComplete="off"
              autoCorrect="off"
              placeholder="Filter accounts"
            />
          </FormControl>
        </Box>
        {rows.length > 0 ? (
          <TableContainer
            component={Paper}
            style={{
              minWidth: '800px',
              minHeight: '100%',
              overflowX: 'initial',
            }}
          >
            <Table
              stickyHeader
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                  paddingBottom: '0.5rem',
                },
              }}
            >
              <colgroup>
                <col style={{ width: '10%', textAlign: 'center' }} />
                <col
                  style={{
                    width: '15%',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                  }}
                />
                <col style={{ width: '15%', textAlign: 'left' }} />
                <col style={{ width: '30%', textAlign: 'left' }} />
                <col style={{ width: '5%', textAlign: 'left' }} />
                <col style={{ width: '5%', textAlign: 'left' }} />
                <col style={{ width: '10%', textAlign: 'left' }} />
                <col style={{ width: '10%', textAlign: 'center' }} />
              </colgroup>
              <TableHead className={classes.noWrapChildren}>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>E-Mail</TableCell>
                  <TableCell>Coach Role</TableCell>
                  <TableCell>Subscriptions</TableCell>
                  <TableCell>
                    <Link
                      color="inherit"
                      className={classes.cursorPointer}
                      onClick={() => orderByCreated()}
                    >
                      Created
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="inherit"
                      className={classes.cursorPointer}
                      onClick={() => orderByLastLogin()}
                    >
                      Last login
                    </Link>
                  </TableCell>
                  <TableCell>Account ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any) => (
                  <TableRow
                    key={'row' + row.accountid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover={true}
                  >
                    <TableCell>
                      <Tooltip
                        placement="top"
                        arrow
                        title="Click to edit user labels"
                      >
                        <LabelIcon
                          color="inherit"
                          className={classes.cursorPointer}
                          onClick={() => {
                            setCurrentAccountId(row.accountid);
                            setInitialLabels(row.metainfos?.labels ?? []);
                            setUserLabelDialogIsOpen(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        arrow
                        title="Click to sync user with Marketing Automation"
                      >
                        <SyncIcon
                          color="inherit"
                          className={classes.cursorPointer}
                          onClick={() => syncWithMarketing(row.accountid)}
                        />
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        arrow
                        title="Click to edit user properties"
                      >
                        <EditIcon
                          color="inherit"
                          className={classes.cursorPointer}
                          onClick={() => {
                            setCurrentMetaInfos(row.metainfos);
                            setCurrentAccountId(row.accountid);
                            setEditUserDialogOpen(true);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        placement="top"
                        arrow
                        title={
                          'Labels: ' +
                          (row.metainfos && row.metainfos.labels
                            ? row.metainfos?.labels.join(',')
                            : 'None')
                        }
                      >
                        <Box component="span">{row.firstname}</Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{row.lastname}</TableCell>
                    <TableCell>
                      <Tooltip
                        placement="top"
                        arrow
                        title="Click to copy e-mail address to clipboard"
                      >
                        <Link
                          color="inherit"
                          className={classes.cursorPointer}
                          onClick={() => copyToClipboard(row.email)}
                        >
                          {row.email}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      {row.iscoach === true ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        color="inherit"
                        className={classes.cursorPointer}
                        onClick={() => {
                          setCurrentAccountId(row.accountid);
                          setSubscriptionInfosOpen(true);
                        }}
                      >
                        {row.numsubscriptions}
                      </Link>
                    </TableCell>
                    <TableCell>{row.createdlogin}</TableCell>
                    <TableCell>{row.lastlogin}</TableCell>
                    <TableCell>
                      <Tooltip
                        placement="top"
                        arrow
                        title="Click to display user meta infos"
                      >
                        <Link
                          color="inherit"
                          className={classes.cursorPointer}
                          onClick={() => {
                            setCurrentMetaInfos(row.metainfos);
                            setIsOpenShowMetaInfo(true);
                          }}
                        >
                          <div className={classes.longTextNoWrap}>
                            {row.accountid}
                          </div>
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No user in database.</Typography>
        )}
      </Box>
      <SubscriptionDialog
        accountid={currentAccountId}
        isOpen={subscriptionInfosOpen}
        onClose={async () => {
          setSubscriptionInfosOpen(false);
          await refetch();
        }}
      />

      <AccountMetainfosDialog
        metainfos={currentMetaInfos}
        isOpen={isOpenShowMetaInfo}
        onClose={() => {
          setIsOpenShowMetaInfo(false);
        }}
      />

      <AdminEditUser
        metainfos={currentMetaInfos}
        accountid={currentAccountId}
        isOpen={editUserDialogOpen}
        onClose={async (requiresRefetech: boolean) => {
          setEditUserDialogOpen(false);
          if (requiresRefetech) await refetch();
        }}
      />

      <UserLabelDialog
        accountid={currentAccountId}
        isOpen={userLabelDialogIsOpen}
        onClose={() => {
          setUserLabelDialogIsOpen(false);
        }}
        initialLabels={initialLabels}
        onUpdate={async (_labels: string[]) => {
          setUserLabelDialogIsOpen(false);
          await refetch();
        }}
      />
    </>
  );
};
