import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from '@mui/material';

import {
  ProgramModuleAction,
  ProgramModuleActionLinkTypeEnum,
} from '../../../entities';

import { TypeEdit } from './TypeEdit';

interface ActionTypeDialogeProps {
  action: ProgramModuleAction;
  isOpen: boolean;
  onClose: Function;
  onUpdate: Function;
}

export const ActionTypeDialoge: React.FC<ActionTypeDialogeProps> = ({
  isOpen,
  onClose,
  onUpdate,
  action,
}) => {
  const intl = useIntl();
  const [type, setType] = useState<ProgramModuleActionLinkTypeEnum>(
    action.linkType
  );

  if (!isOpen) {
    return null;
  }

  const updateType = async () => {
    action.linkType = type;

    if (onUpdate) onUpdate(type);
  };

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.actiontypedialog.title',
          defaultMessage: 'Aktionstyp zuweisen',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" component="span">
          {intl.formatMessage({
            id: 'builder.actiontypedialog.content',
            defaultMessage:
              'Hier kannst du deiner Aktion einen Link-Typ zuweisen.',
          })}
          <TypeEdit
            initialType={type}
            sx={{ marginTop: '10px' }}
            onUpdate={(type: ProgramModuleActionLinkTypeEnum) => setType(type)}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {intl.formatMessage({
            id: 'builder.programlabeldialog.button.close',
            defaultMessage: 'Abbrechen',
          })}
        </Button>
        <Button color="secondary" onClick={updateType}>
          {intl.formatMessage({
            id: 'builder.programlabeldialog.button.save',
            defaultMessage: 'Übernehmen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
