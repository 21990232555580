import { evoachDefaultTheme } from '@evoach/ui-components';
import { Typography } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';
import { useIntl } from 'react-intl';

import { useFetchAnalytics } from '../../../api/analytics';

export const AnalysisModuleSessions: React.FC = () => {
  const intl = useIntl();

  const analysisId = '250174fc-1268-1441-8e5a-c3e40001ade7';
  const { analysis } = useFetchAnalytics(analysisId);

  /*
  {
    "numberofsessions": 3,
    "originalmoduleid": "babdcbed-a322-4ca4-b990-d1d79c78f7b1",
    "publicsession": false,
    "title": "Mit Coach B geteiltes Modul",
    "isdefaultmodule": false,
    "accountAid": 1,
    "moduleowner": "christian@evoach.com"
}
*/
  //console.log(analysis);

  const grouping: Record<string, any> = {};

  if (analysis) {
    analysis.forEach((line: any) => {
      if (!grouping[line.originalmoduleid]) {
        grouping[line.originalmoduleid] = {
          ...line,
          numberofsessions: parseInt(line.numberofsessions),
        };
      } else {
        grouping[line.originalmoduleid] = {
          ...grouping[line.originalmoduleid],
          numberofsessions:
            parseInt(grouping[line.originalmoduleid].numberofsessions) +
            parseInt(line.numberofsessions),
        };
      }
    });
  }

  const seriesData: number[] = !analysis
    ? []
    : Object.keys(grouping).map(
        (key: string) => grouping[key].numberofsessions
      ); // [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
  const catData: string[] = !analysis
    ? []
    : Object.keys(grouping).map((key: string) => {
        return grouping[key].title;
      });

  const series = [
    {
      name: intl.formatMessage({
        id: 'builder.analytics.numerofsessions',
        defaultMessage: 'Anzahl der Sessions',
      }),
      data: seriesData,
    },
  ];

  const chartheight =
    seriesData.length < 17 ? 350 : 350 + (seriesData.length - 17) * 17;

  const options = {
    chart: {
      height: chartheight,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    colors: [evoachDefaultTheme.palette.primary.main],
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: catData,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [
        intl.formatMessage({
          id: 'builder.analytics.numerofsessions',
          defaultMessage: 'Anzahl der Sessions',
        }),
      ],
    },
  };

  return (
    <>
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'builder.analytics.250174fc-1268-1441-8e5a-c3e40001ade7.description',
          defaultMessage:
            'Diese Statistik zeigt deine Module (Y-Achse) und die Anzahl der Sessions, die mit diesem Modul gestartet wurden (X-Achse).',
        })}
        <br />
        {intl.formatMessage({
          id: 'builder.analytics.chart.export',
          defaultMessage:
            'Wenn du auf den Button rechts oben über der Grafik klickst, kannst du die Daten auch exportieren.',
        })}
      </Typography>
      <div id="analyticschart" style={{ marginLeft: '5px', marginTop: '30px' }}>
        <Chart
          options={{ ...options, series: series }}
          series={series}
          height={chartheight}
          type="bar"
          width={700}
          id="apexchart"
          key={'apexchart' + analysisId}
        />
      </div>
    </>
  );
};
