import React, { useContext, useState } from 'react';
//import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
} from '@mui/material';

import { authorizedPost, authorizedPut } from '../../../api';
import { Program } from '../../../entities';
import { WindowContext } from '../../window/WindowContext';

const jsonAddDialogMessages = defineMessages({
  dialogTitle: {
    id: 'builder.reception.programs.programByJson.dialog.dialogTitle',
    defaultMessage: 'Programm über JSON Objekt erzeugen',
  },
  dialogCreateButton: {
    id: 'builder.reception.programs.programByJson.dialog.dialogCloseButton',
    defaultMessage: 'Programm erzeugen',
  },
  dialogCloseButton: {
    id: 'builder.reception.programs.programByJson.dialog.dialogCreateButton',
    defaultMessage: 'Abbrechen',
  },
});

/** perform basic check whether a JSON is a valid program */
const validprogram = (program: Program): boolean => {
  let valid = true;
  valid = valid && program.program !== undefined;
  valid =
    valid && program.program.modules && program.program.modules.length > 0;
  valid =
    valid &&
    program.program.modules[0].actions &&
    program.program.modules[0].actions.length > 0;
  return valid;
};

/** props for this button + dialog component */
export interface CreateProgramByJsonDialogProps {
  open: boolean;
  onCloseHandler: Function;
}

/** program by JSON component */
export const CreateProgramByJsonDialog: React.FC<
  CreateProgramByJsonDialogProps
> = ({ open, onCloseHandler }) => {
  const [jsonObject, setJsonObject] = useState<string>('');

  const intl = useIntl();
  const { alert } = useContext(WindowContext);

  const onJsonChange = (event: any) => {
    const programJson = event.target.value;

    try {
      if (!programJson || programJson.trim() === '') {
        setJsonObject('');
      } else {
        // basic check whether this is a valid program!
        if (!validprogram(JSON.parse(programJson))) {
          alert(
            intl.formatMessage({
              id: 'builder.reception.programs.programByJson.dialog.alert.noValidprogram',
              defaultMessage: 'Das ist kein gültiges Programm.',
            })
          );
          setJsonObject('');
        } else {
          setJsonObject(programJson);
        }
      }
    } catch (_e: any) {}
  };

  const createprogramByJson = async () => {
    if (!jsonObject || jsonObject.trim() === '') return;

    // 1) create program => does not considers all variables and
    // especially not the buildergraph ... s. 2.
    const createJsonprogram = authorizedPost(
      '/program',
      JSON.parse(jsonObject)
    );
    const createResponse = await createJsonprogram();

    if (!createResponse.ok) {
      // TODO display proper error message
      alert(
        intl.formatMessage({
          id: 'builder.reception.programs.programByJson.dialog.alert.creationFailed',
          defaultMessage:
            'Das Programm konnte nicht gespeichert werden (Anlegen ist fehlgeschlagen)',
        })
      );
      onCloseHandler();
    } else {
      const data = await createResponse.json();
      const programid = data.programid;

      // 2. update program ==> update also all other parameters and builder graph!
      const updateJsonprogram = authorizedPut(
        `/program/${programid}`,
        JSON.parse(jsonObject)
      );
      const updateResponse = await updateJsonprogram();
      if (!updateResponse.ok) {
        // TODO display proper error message
        alert(
          intl.formatMessage({
            id: 'builder.reception.programs.programByJson.dialog.alert.uploadFailed',
            defaultMessage:
              'Das Programm konnte nicht gespeichert werden (Aktualisierung ist fehlgeschlagen)',
          })
        );
        onCloseHandler();
      } else {
        alert(
          intl.formatMessage({
            id: 'builder.reception.programs.programByJson.dialog.alert.newprogramAdded',
            defaultMessage: 'Neues Program erfolgreich erstellt.',
          })
        );
        setJsonObject('');
        onCloseHandler();
      }
    }
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        <FormattedMessage {...jsonAddDialogMessages.dialogTitle} />
      </DialogTitle>
      <DialogContent>
        <TextField
          label={intl.formatMessage({
            id: 'builder.pages.builder.programByJson.dialog.textfieldlabel',
            defaultMessage: 'Das JSON Objekt hierher kopieren ...',
          })}
          value={jsonObject}
          onChange={(evt) => onJsonChange(evt)}
          multiline
          minRows={20}
          maxRows={20}
          fullWidth
          key="TextFieldDialog"
          sx={{ marginTop: '10px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setJsonObject('');
            onCloseHandler();
          }}
        >
          <FormattedMessage {...jsonAddDialogMessages.dialogCloseButton} />
        </Button>

        <Button onClick={createprogramByJson}>
          <FormattedMessage {...jsonAddDialogMessages.dialogCreateButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
