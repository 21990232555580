import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { useUpdateNodeInternals } from 'reactflow';
import { useEffect } from 'react';
import { Typography } from '@mui/material';

import { Description } from '../Description';

import { useGlobalStyles, calculateHandlePosition } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

import { StatePayloadProps } from '.';

/*
  AiGenericClassificationNode allows a generic classification of a chat or
  a variable. It is defined by a prompt describing the task and a list of
  options to choose from.
*/

export const AiGenericClassificationNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload: StatePayloadProps = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;

  // update internal position coordinates of handles when changing the number
  // of handles in this node.
  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    updateNodeInternals(nodeProps.id);
  }, [nodeProps.id, payload.keyTexts?.length, updateNodeInternals]);

  if (!payload.radioTexts) {
    payload.radioTexts = [];
    payload.keyTexts = [];
  }

  // it is very important to use the translation key for the id
  // as it is used for the .on target in the state machine transition
  // the id defines the "sourceHandle" in moduleUtils
  const sourceHandles = payload.keyTexts?.map(
    (keyText: string, index: number) => (
      <Handle
        id={keyText}
        key={keyText + index}
        className={classes['react-flow__handle']}
        type="source"
        position={Position.Bottom}
        style={{
          left: calculateHandlePosition(payload.keyTexts?.length ?? 1, index),
        }}
      />
    )
  );

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{
          width: '400px',
          background: nodeProps.data.state.entry[0].nodeMiniMapColor,
        }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          <Description nodetype={nodeType} propname="description" />
          <br />
          {intl.formatMessage(
            {
              id: 'builder.aigenericclassification.prompt.hint',
              defaultMessage:
                'Deine Anweisung, was klassifiziert werden soll, lautet: {prompt}. ',
            },
            {
              prompt: (
                <b>
                  {intl.formatMessage({
                    id: payload.message,
                  })}
                </b>
              ),
            }
          )}
          <br />
          &nbsp; <br />
          {payload.classificationKeys &&
          payload.classificationKeys.length > 0 ? (
            <>
              {intl.formatMessage({
                id: 'builder.aigenericclassification.options.hint',
                defaultMessage:
                  'Das sind deine Optionen für die Klassifikation. Die Ausgänge dieser Komponenten werden in der gleichen Reihenfolge angesteuert.',
              })}
              <br />
              &nbsp; <br />
              <ol>
                {(payload.classificationKeys ?? []).map(
                  (key: string, index: number) => (
                    <li key={key + '.' + index}>{key}</li>
                  )
                )}
              </ol>
            </>
          ) : (
            <>
              {intl.formatMessage({
                id: 'builder.aigenericclassification.nooptions.hint',
                defaultMessage:
                  'Du hast bisher noch keine Optionen zur Klassifikation eingegeben. Bitte gib mind. eine Option an.',
              })}
            </>
          )}
        </Typography>
      </div>
      {sourceHandles}
    </>
  );
};
