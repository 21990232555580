import { useIntl } from 'react-intl';
import { defineMessages } from '@formatjs/intl';
import React, { useMemo, useState, useEffect } from 'react';
import {
  DataGrid,
  GridCellValue,
  GridColumns,
  GridSortCellParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { formatDate, getTranslatedText } from '@evoach/ui-components';

import { useFetchSharedSessionsQuery } from '../../../api/session/useFetchSharedSessionsQuery';

import { ViewSessionButton } from './ListButtons';

const sharedsessionListTexts = defineMessages({
  sessionnameheader: {
    id: 'builder.components.reception.sharedsessionlistheader.sessionname',
    defaultMessage: 'Session',
  },
  sessionidheader: {
    id: 'builder.components.reception.sharedsessionlistheader.sessionid',
    defaultMessage: 'Session ID',
  },
  sessiondescrheader: {
    id: 'builder.components.reception.sharedsessionlistheader.descrheader',
    defaultMessage: 'Beschreibung',
  },
  sessionlastupdateheader: {
    id: 'builder.components.reception.sharedsessionlistheader.lastupdate',
    defaultMessage: 'Zuletzt bearbeitet',
  },
  sharedbyheader: {
    id: 'builder.components.reception.sharedsessionlistheader.sharedby',
    defaultMessage: 'Geteilt von',
  },
  sharedat: {
    id: 'builder.components.reception.sharedsessionlistheader.sharedat',
    defaultMessage: 'Geteilt am',
  },
});

/*
    ! SharedSessionList 
    
*/

/* interface ModuleListProps {
  setModuleId: Function;
  moduleid: string;
} */

export const SharedSessionList: React.FC = () => {
  const intl = useIntl();
  const [sessionid, setSessionId] = useState<string>('');
  const { sessions: sharedsessions } = useFetchSharedSessionsQuery();

  // if session list is reloaded, re-generate mapping for rows
  // rows is also used by child components
  const rows = useMemo(() => {
    return sharedsessions
      ? sharedsessions.map((sharedsession: any, mindex: number) => {
          return {
            id: mindex,
            sessionname: getTranslatedText(
              sharedsession.session_metadata.title,
              intl
            ),
            sessionid: sharedsession.session_sessionid,
            sessiondescription: getTranslatedText(
              sharedsession.session_metadata.description,
              intl
            ),
            sessioncreated: formatDate(
              sharedsession.session_tscreated,
              intl.locale
            ),
            sessionlastupdated: formatDate(
              sharedsession.session_tsupdated,
              intl.locale
            ),
            sessioncreatedRaw: new Date(sharedsession.session_tscreated), // for proper sorting, not displayed
            sessionlastupdatedRaw: new Date(sharedsession.session_tsupdated),
            sharedat: formatDate(
              sharedsession.sessionsharings_tscreated,
              intl.locale
            ),
            sharedatRaw: new Date(sharedsession.sessionsharings_tscreated),
            progress: sharedsession.progress ?? 0,
            sharedby: !sharedsession.sharedby_givenname
              ? ''
              : sharedsession.sharedby_givenname +
                ' ' +
                sharedsession.sharedby_familyname,
          };
        })
      : [];
  }, [sharedsessions, intl]);

  useEffect(() => {
    if (sharedsessions === undefined) {
      setSessionId('');
    } else {
      let modincl = false;
      sharedsessions.forEach((module: any) => {
        modincl = modincl || module.moduleid === sessionid;
      });
      modincl ? (modincl = false) : setSessionId('');
    }
  }, [sharedsessions, setSessionId, sessionid]);

  // by defining the sort model, we can use a custom comparer
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'invitationcreated',
      sort: 'asc' as GridSortDirection,
    },
  ]);

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: 'sessionname',
        headerName: intl.formatMessage(
          sharedsessionListTexts.sessionnameheader
        ),
        editable: false,
        width: 200,
        sortable: true,
      },
      {
        field: 'sharedby',
        headerName: intl.formatMessage(sharedsessionListTexts.sharedbyheader),
        editable: false,
        width: 200,
        sortable: true,
      },
      {
        field: 'progress',
        headerName: intl.formatMessage({
          id: 'builder.components.reception.sharedsessionlistheader.progress',
          defaultMessage: 'Fortschritt',
        }),
        renderCell: (cellValues: any) => <>{cellValues.row.progress ?? 0}%</>,
        editable: false,
        width: 20,
        sortable: true,
      },
      {
        field: 'sharedat',
        sortComparator: (
          _v1: GridCellValue,
          _v2: GridCellValue,
          param1: GridSortCellParams,
          param2: GridSortCellParams
        ) => {
          // this is necessary to have a proper sorting of dates
          // without this comparator, dates would be sorted
          // as formatted string, i.e., alphabetically.
          // see also https://mui.com/components/data-grid/sorting/#custom-comparator
          return (
            (param1.api.getCellValue(
              param1.id,
              'sharedatRaw' // use a UNIX time stamp
            ) as number) -
            (param2.api.getCellValue(param2.id, 'sharedatRaw') as number)
          );
        },
        headerName: intl.formatMessage(sharedsessionListTexts.sharedat),
        editable: false,
        width: 150,
        sortable: true,
      },
      {
        field: 'sessionlastupdated',
        sortComparator: (
          _v1: GridCellValue,
          _v2: GridCellValue,
          param1: GridSortCellParams,
          param2: GridSortCellParams
        ) => {
          // this is necessary to have a proper sorting of dates
          // without this comparator, dates would be sorted
          // as formatted string, i.e., alphabetically.
          // see also https://mui.com/components/data-grid/sorting/#custom-comparator
          return (
            (param1.api.getCellValue(
              param1.id,
              'sessionlastupdatedRaw' // use a UNIX time stamp
            ) as number) -
            (param2.api.getCellValue(
              param2.id,
              'sessionlastupdatedRaw'
            ) as number)
          );
        },
        headerName: intl.formatMessage(
          sharedsessionListTexts.sessionlastupdateheader
        ),
        editable: false,
        width: 150,
        sortable: true,
      },
      {
        field: 'sessiondescription',
        headerName: intl.formatMessage(
          sharedsessionListTexts.sessiondescrheader
        ),
        editable: false,
        width: 400,
        sortable: true,
      },
      {
        field: 'resumesessionbutton',
        headerName: '',
        renderCell: (cellValues: any) => <ViewSessionButton {...cellValues} />,
        disableClickEventBubbling: true,
        width: 200,
      },
    ];
  }, [intl]);

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const maxHeight = vh - 300 + 'px';

  return (
    <div
      style={{
        marginTop: 20,
        width: '100%',
        height: maxHeight,
        maxHeight: maxHeight,
      }}
    >
      <DataGrid
        pagination
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[5, 100]}
        checkboxSelection={false}
        disableSelectionOnClick
        onRowClick={(params: any) => {
          setSessionId(params.row.sessionid);
        }}
        style={{ minHeight: 400 }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']} // enforce only these two states for sorting
      />
    </div>
  );
};
