import { evoachDefaultTheme } from '@evoach/ui-components';
import { Box, FormControl, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { AssetType } from '../../PropertiesSidebar/assetHelper';

interface AssetTypeSelectProps {
  /**
   * onUpdate function should have signature like (value: AssetType | undefined) => {}
   */
  onUpdate: Function;
  narrowMode?: boolean;
  showAllOption?: boolean;
}

export const AssetTypeSelect: React.FC<AssetTypeSelectProps> = ({
  onUpdate,
  narrowMode = false,
  showAllOption = false,
}) => {
  const intl = useIntl();

  const [valueFrom, setValueFrom] = useState<AssetType | 'all'>('all');

  return (
    <FormControl>
      <EvoachSelect
        value={valueFrom}
        onChange={(event) => {
          setValueFrom(event.target.value as AssetType);
          onUpdate(event.target.value);
        }}
        renderValue={(value) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignmentBaseline: 'center',
                alignItems: 'center',
              }}
            >
              <Typography color="#999999" variant="body2">
                {intl.formatMessage({
                  id: 'builder.reception.assets.assettypeselect.typefilter.text',
                  defaultMessage: 'Dateityp:',
                })}
              </Typography>
              <Typography sx={{ marginLeft: '10px' }}>
                <>
                  {value === 'all'
                    ? intl.formatMessage({
                        id: 'builder.reception.assets.assettypeselect.showAllOption.text',
                        defaultMessage: 'Alle',
                      })
                    : value}
                </>
              </Typography>
            </Box>
          );
        }}
        sx={{
          height: narrowMode ? '25px' : '35px',
          color: narrowMode
            ? evoachDefaultTheme.palette.secondary.main
            : '#000000',
          borderColor: narrowMode
            ? evoachDefaultTheme.palette.secondary.main
            : '#000000',
          marginLeft: '0.5em',
        }}
      >
        {showAllOption && (
          <EvoachMenuItem
            key="assettypes_showAllOption"
            value="all"
            selected={'all' === valueFrom}
            sx={{
              color: narrowMode
                ? evoachDefaultTheme.palette.secondary.main
                : '#000000',
            }}
          >
            {intl.formatMessage({
              id: 'builder.reception.assets.assettypeselect.showAllOption.text',
              defaultMessage: 'Alle',
            })}
          </EvoachMenuItem>
        )}
        {Object.values<string>(AssetType).map(
          (assettype: string, index: number) => (
            <EvoachMenuItem
              key={`languages_${index}`}
              value={assettype}
              selected={assettype === valueFrom}
              sx={{
                color: narrowMode
                  ? evoachDefaultTheme.palette.secondary.main
                  : '#000000',
              }}
            >
              {assettype}
            </EvoachMenuItem>
          )
        )}
      </EvoachSelect>
    </FormControl>
  );
};
