import { Handle, NodeProps, Position } from 'reactflow';
import { VideoDisplay } from '@evoach/ui-components';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { useFetchAssetUrl } from '../../api/asset';
import { translateCreatorWithFallback } from '../../intl/translateWithFallback';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  VideoDisplay react-flow node for module builder graph canvas
*/

export const VideoDisplayNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const s3url = useFetchAssetUrl(
    payload.assetid
      ? translateCreatorWithFallback(intl, payload.assetid)
      : undefined
  ).url;

  let imgsrc = payload.src;

  if (!imgsrc.toLowerCase().startsWith('http')) {
    imgsrc =
      payload.assetid && payload.assetid.trim() !== '' && s3url !== ''
        ? s3url
        : translateCreatorWithFallback(intl, payload.src);
  }

  imgsrc =
    imgsrc && imgsrc.trim() !== ''
      ? imgsrc
      : 'https://www.youtube-nocookie.com/embed/iCPtjovlxlA';

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: 400 }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <div>
          {imgsrc && imgsrc.trim() !== '' ? (
            <VideoDisplay src="" resolvedsrc={imgsrc} assetid="" />
          ) : (
            <Typography>
              {intl.formatMessage({
                id: 'builder.nodes.videodisplay.noasset',
                defaultMessage:
                  'Du hast noch keine Video festgelegt. Du kannst ein Video zu evoach hochladen oder ein externes Video verlinken, z.B. von Youtube.',
              })}
            </Typography>
          )}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
