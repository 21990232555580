/*
  BuilderPage

  Pls check call hierarchy with responsibilities below

! BuilderPage                     - page for router, check route params, load module
    |_ ModuleBuilderWrapper       - management of module translation which is independent of the UI translation
      |_ ModuleBuilder            - keep state of Reract Flow nodes and edges, manage undo logic
        |_ ModuleEditor           - provide toolbar on top of editor canvas
          |_ ModuleEditorpane     - ReactFlow provider + ReactFlow component + management of canvas actions

*/

import React, { useEffect, useMemo } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { EvoachButton } from '@evoach/ui-components';

import { AppRoutes, ModuleRouteParams } from '../routing/routes';
import { useFetchModule } from '../api/module/useFetchModule';
import { ModuleBuilderWrapper } from '../components/ModuleBuilderWrapper';
import { SupportedLocales } from '../intl/SupportedLocales';
import { TranslationContext as GlobalTranslationContext } from '../intl/TranslationContext';
import ErrorBoundary from '../components/ErrorBoundary';
import { ApiError } from '../api/ApiError';
import { ErrorAccordion } from '../components/ErrorAccordion';

export const BuilderPage: React.FC = () => {
  const { moduleId, moduleLanguage } = useParams<ModuleRouteParams>();
  const { isLoading, isError, error, module } = useFetchModule(
    moduleId,
    moduleLanguage
  );

  const { setDefaultMessages } = React.useContext(GlobalTranslationContext);

  let navigate = useNavigate();

  const intl = useIntl();

  // set default messages for new elements
  // as we have only the SupportedLocales available, we have to fallback to EN
  // if the language of the module is not among the list of SupportedLocales.
  // Note: we have to remove setDefaultMessages from the dependency array,
  // because otherwise, we run into an infinite loop
  useEffect(() => {
    setDefaultMessages(
      moduleLanguage && moduleLanguage in SupportedLocales
        ? (moduleLanguage as SupportedLocales)
        : SupportedLocales.EN
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleLanguage]);

  const readableErrorMessage = useMemo(() => {
    if (isError) {
      if (error instanceof ApiError) {
        const m = error.details.message ?? '';

        // module not found
        if (m.startsWith('module not found')) {
          return intl.formatMessage({
            id: 'builder.pages.builder.error.read1',
            defaultMessage:
              'Das Modul konnte nicht gefunden werden. Es könnte auch sein, dass du nicht die erforderlichen Rechte hast.',
          });
        }
        // Translations for module
        if (m.toLowerCase().startsWith('translations for module')) {
          return intl.formatMessage({
            id: 'builder.pages.builder.error.read2',
            defaultMessage:
              'Die angeforderte Übersetzung für das Modul konnte nicht gefunden wurde. Wenn das Modul durch Duplizieren erstellt wurde, gibt es wahrscheinlich einen Fehler im Modul, das dupliziert wurde.',
          });
        }
        return m;
      } else {
        return 'Unbekannter Fehler (kein ApiError)';
      }
    } else {
      return '';
    }
  }, [error, intl, isError]);

  return (
    <div>
      {isError && (
        <>
          <ErrorAccordion
            readableErrorMessage={readableErrorMessage}
            error={error}
          />
          <EvoachButton
            onClick={() => {
              navigate(`${AppRoutes.RECEPTION}`);
            }}
            sx={{ marginTop: '30px' }}
          >
            {intl.formatMessage({
              id: 'builder.pages.builder.error.backtolistbutton',
              defaultMessage: 'Zurück zur Liste deiner Module',
            })}
          </EvoachButton>
        </>
      )}
      {isLoading && (
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'builder.router.approuter.loading',
            defaultMessage: 'Chatbot Editor wird geladen ...',
          })}

          <CircularProgress />
        </Typography>
      )}
      {!isLoading && !isError && (
        <ErrorBoundary>
          <ModuleBuilderWrapper module={module} />
        </ErrorBoundary>
      )}
    </div>
  );
};
