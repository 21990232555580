import React, { useState, useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useIntl } from 'react-intl';
import { validate } from 'uuid';
import { EvoachButton } from '@evoach/ui-components';

import { TranslationContext } from '../../intl/TranslationContext';
import { useEnvironment } from '../../environment/useEnvironment';
import { authorizedDelete, authorizedGet, authorizedPost } from '../../api';

//import { useGetPreviewSession } from '../../hooks/useGeneratePreviewSession';

/**
 * ! Comment
 *
 * The following functions are not only used by the component at the end of this
 * file but also by ModuleListWithCards.tsx. This is why they are extracted here.
 */

/*
 validUUID
  this check is using https://www.npmjs.com/package/uuid#uuidvalidatestr
*/
export const validUUID = (uuid: string | undefined): boolean => {
  return validate(uuid + '');
};

/**
 * delete a session
 * @param {string} previoussession = sessionid of sesstion to be deleted
 */
const deleteSession = async (previoussession: string) => {
  const deletionURL = `/session/${previoussession}`;
  const deletionCall = authorizedDelete(deletionURL);
  await deletionCall();
};

/**
 * create a new session and return the sessio id
 * @param {string} moduleid
 * @param {string} localeToPreview
 * @returns {string} sessionid
 */
const createSession = async (moduleid: string, localeToPreview: string) => {
  const sessioncreateURL = `/session/?moduleid=${moduleid}&language=${localeToPreview}&previewsession=true`;
  const sessionCreateCall = authorizedPost(sessioncreateURL);
  const response = await sessionCreateCall();
  const session = await response.json();

  return session.sessionid;
};

/**
 * return a list of sessionids of old preview-sessions
 * @returns {string[]} list of sessionids of preview-sessions
 */
const getLatestPreviewSession = async () => {
  const getsessionURL = `/session/?ispreview=true`;
  const getSessionCall = authorizedGet(getsessionURL);
  const getSessionListResponse = await getSessionCall();

  const previewsessions = (await getSessionListResponse.json()).sessions
    .filter((session: any) => session.previewsession)
    .map((session: any) => session.sessionid);

  return previewsessions;
};

/**
 * Start a new preview session
 *
 * 0) get last preview session
 * 1) delete last preview session
 * 2) create new preview session
 * 3) open window
 *
 * @param {string} moduleid
 * @param {Window | null} previewwindow
 * @param {string} playerBasePath
 * @param {Function} setpreviewwindow
 * @param {string} localeToPreview
 */
export const startPreviewSession = async (
  moduleid: string | undefined,
  previewwindow: Window | null,
  playerBasePath: string,
  setpreviewwindow: Function,
  localeToPreview: string
) => {
  // get list of last preview sessions
  const previoussessionids = await getLatestPreviewSession();

  // if there is a session in DB, delete it
  if (previoussessionids !== undefined) {
    //console.log('delete: ' + previoussessionid);
    // all preview sessions of all modules are deleted!
    previoussessionids.forEach(async (sessionid: string) => {
      await deleteSession(sessionid);
    });
  }

  // create empty session with current module for preview
  let newPreviewSessionId: string = '';
  if (moduleid !== undefined) {
    newPreviewSessionId = await createSession(moduleid, localeToPreview);
  }

  if (!validUUID(newPreviewSessionId)) {
    // PROD-1937 => add logs and try to find the reason
    alert(
      `Can not start preview session sessionid: ${newPreviewSessionId}, moduleid: ${moduleid}, localeToPreview: ${localeToPreview}`
    );
    return;
  }

  // for Window handling, s. also https://developer.mozilla.org/en-US/docs/Web/API/Window/open
  // it isn't as easy as it seems!
  let newpreviewwindow: Window | null = previewwindow;

  if (!newpreviewwindow || newpreviewwindow.closed) {
    newpreviewwindow = window.open(
      `${playerBasePath}/coaching/${newPreviewSessionId}`,
      'coachingbotpreviewwindow'
    );
  }

  if (newpreviewwindow) {
    newpreviewwindow.focus();
    setpreviewwindow(newpreviewwindow);
    newpreviewwindow.location =
      `${playerBasePath}/coaching/${newPreviewSessionId}` as unknown as Location;
  }
};

/**
 * Component ModulePreviewButton uses functions above
 */
export interface ModulePreviewButtonProps {
  moduleid?: string;
  isLoading: boolean;
  onsave: Function;
  stateMachineLocale?: string;
  showButton?: boolean;
  disabled?: boolean;
}

export const ModulePreviewButton: React.FC<ModulePreviewButtonProps> = (
  props
) => {
  const intl = useIntl();
  const { playerBasePath } = useEnvironment();
  const { locale: currentLocale } = useContext(TranslationContext);
  const {
    moduleid,
    isLoading,
    onsave,
    stateMachineLocale,
    showButton = true,
    disabled = false,
  } = props;

  const localeToPreview = stateMachineLocale ?? currentLocale;

  const [previewwindow, setpreviewwindow] = useState<any>(undefined);

  const onPreview = async () => {
    // save current work
    onsave();

    setTimeout(
      () =>
        startPreviewSession(
          moduleid,
          previewwindow,
          playerBasePath,
          setpreviewwindow,
          localeToPreview
        ),
      100
    );
  };

  if (showButton) {
    return (
      <EvoachButton
        onClick={onPreview}
        disabled={false || isLoading || moduleid === undefined || disabled}
        sx={{ height: '25px' }}
      >
        {intl.formatMessage({
          id: 'builder.moduleeditor.previewbuttontext',
          defaultMessage: 'Vorschau',
        })}
      </EvoachButton>
    );
  }

  return (
    <IconButton
      onClick={onPreview}
      disabled={false || isLoading || moduleid === undefined || disabled}
      aria-label={intl.formatMessage({
        id: 'builder.moduleeditor.previewbuttontext',
        defaultMessage: 'Vorschau',
      })}
    >
      <Tooltip
        placement="top"
        arrow
        title={intl.formatMessage({
          id: 'builder.moduleeditor.previewbuttontext',
          defaultMessage: 'Vorschau',
        })}
      >
        <RemoveRedEyeIcon />
      </Tooltip>
    </IconButton>
  );
};
