import { EvoachButton, evoachDefaultTheme } from '@evoach/ui-components';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { KeyVisualPositionEnum } from '../../entities';

export interface ProgramKeyVisualDialogProps {
  isOpen: boolean;
  position: KeyVisualPositionEnum | undefined;
  setKeyVisualPosition: (pos: KeyVisualPositionEnum) => void;
  linkTexts: string | undefined;
  setKeyVisualLinks: Function;
  handleClose: () => void;
}

export const ProgramKeyVisualDialog = ({
  isOpen,
  position = KeyVisualPositionEnum.TOP,
  setKeyVisualPosition,
  linkTexts = '',
  setKeyVisualLinks,
  handleClose,
}: ProgramKeyVisualDialogProps) => {
  const intl = useIntl();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '4px',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  };

  const [text, setText] = useState(linkTexts);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <Box display="flex" flexDirection="column">
          <FormLabel
            id="key-visual-position-radio"
            sx={{
              typography: 'body1',
              '.Mui-focused': {
                color: 'gray',
              },
            }}
          >
            {intl.formatMessage({
              id: 'builder.programs.metaeditor.keyvisual.dialog.position.label',
              defaultMessage: 'Position',
            })}
          </FormLabel>
          <FormControl>
            <RadioGroup
              aria-labelledby="key-visual-position-radio"
              value={position}
              name="key-visual-position"
              onChange={(_, value: string) =>
                setKeyVisualPosition(value as KeyVisualPositionEnum)
              }
            >
              <FormControlLabel
                value={KeyVisualPositionEnum.TOP}
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: evoachDefaultTheme.palette.secondary.main,
                      },
                    }}
                  />
                }
                label={intl.formatMessage({
                  id: 'builder.programs.metaeditor.keyvisual.dialog.top',
                  defaultMessage: 'Oben',
                })}
              />
              <FormControlLabel
                value={KeyVisualPositionEnum.BOTTOM}
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: evoachDefaultTheme.palette.secondary.main,
                      },
                    }}
                  />
                }
                label={intl.formatMessage({
                  id: 'builder.programs.metaeditor.keyvisual.dialog.bottom',
                  defaultMessage: 'Unten',
                })}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <FormControl fullWidth>
            <FormLabel
              id="key-visual-links-label"
              sx={{ marginBottom: '10px', typography: 'body1' }}
            >
              {intl.formatMessage({
                id: 'builder.programs.metaeditor.keyvisual.dialog.link.label',
                defaultMessage: 'Links',
              })}
            </FormLabel>
            <TextField
              value={text}
              onChange={(evt) => setText(evt.currentTarget.value)}
              id="keyvisual-links"
              onBlur={(evt) => setKeyVisualLinks(evt.currentTarget.value)}
              multiline
              rows={4}
              placeholder="http://your-link.com/"
            />
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <EvoachButton onClick={handleClose}>
            {intl.formatMessage({
              id: 'builder.programs.metaeditor.keyvisual.dialog.closebutton',
              defaultMessage: 'Schließen',
            })}
          </EvoachButton>
        </Box>
      </Box>
    </Modal>
  );
};
