import { CoachMessage, YesNoButton } from '@evoach/ui-components';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';

import { getProtectedText } from '../../intl/getProtectedText';
import { TranslationContext } from '../stateMachineTranslationContext';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  SendMailNode 
*/

export const SendMailNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const { stateMachineLocale } = useContext(TranslationContext);

  const mailHint = intl.formatMessage({
    id: 'builder.nodes.elements.SendMail.hint',
    defaultMessage:
      'Dieser Knoten sendet nach einer Bestätigung durch den Coachee eine Mail an die angegebene Emailadresse mit folgenden Variablen:',
  });
  const editableMessageHint = intl.formatMessage({
    id: 'builder.nodes.elements.sendmail.editableMessageHint',
    defaultMessage:
      'Dem Coachee werden dafür zwei Nachrichten angezeigt. Die erste Nachricht ist rechts editierbar:',
  });
  const protectedMessageHint = intl.formatMessage({
    id: 'builder.nodes.elements.sendmail.protectedMessageHint',
    defaultMessage:
      'Die zweite Nachricht wird immer angezeigt und ist nicht änderbar:',
  });
  const selectVariablesHint = intl.formatMessage({
    id: 'builder.nodes.elements.sendmail.selectVariablesHint',
    defaultMessage: 'Bitte rechts Variablen auswählen.',
  });

  const payload = nodeProps.data.state.entry[0].payload;

  const getValuesFrom = payload.getValuesFrom ?? [''];

  return (
    <>
      <Handle
        type="target"
        id="sendmailin"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ width: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {mailHint}
          <br />
          <ol>
            {getValuesFrom.length === 0 && selectVariablesHint}
            {getValuesFrom.map((label: string, index: number) => (
              <li key={'label' + index}>{label}</li>
            ))}
          </ol>
          <br />
          <div style={{ paddingBottom: '1em' }}>{editableMessageHint}</div>
          <CoachMessage
            message={translateKey(intl, payload.message)}
          ></CoachMessage>
          <br />
          <div style={{ paddingBottom: '1em' }}>{protectedMessageHint}</div>
          <CoachMessage
            message={getProtectedText(
              'sendMail.notifyCoachee',
              stateMachineLocale
            )}
          ></CoachMessage>
        </Typography>
        <div>
          <div style={{ margin: '1em 15%' }}>
            <YesNoButton
              yesButtonText={getProtectedText(
                'sendMail.yes',
                stateMachineLocale
              )}
              noButtonText={getProtectedText('sendMail.no', stateMachineLocale)}
              saveResultTo="evoachechokey.yesNo"
            />
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="yeshandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '30%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        style={{ left: '70%' }}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
