import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  audioText: {
    id: 'builder.nodes.copyLink.state.buttonText',
    defaultMessage: 'Link kopieren',
  },
});

/*
  Copy link component -  xState specific defintions
*/
export const copyLinkStateEntry: StateEntryProps = {
  type: 'renderCopyLink',
  temporary: false,
  payload: {
    link: '',
    buttonText: 'builder.nodes.copyLink.state.buttonText',
  },
  displayName: 'Copy Link',
  nodeType: 'copyLinkStateEntry',
  handleOutCount: 1,
};
