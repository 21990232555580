import { Handle, NodeProps, Position } from 'reactflow';
import { RadioButton } from '@evoach/ui-components';
import { useIntl } from 'react-intl';
import { useUpdateNodeInternals } from 'reactflow';
import { useEffect } from 'react';

import {
  useGlobalStyles,
  translateKey,
  calculateHandlePosition,
} from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

import { StatePayloadProps } from '.';

/*
  RadioButtonNode react-flow node for module builder graph canvas
*/

export const RadioButtonNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload: StatePayloadProps = nodeProps.data.state.entry[0].payload;

  // update internal position coordinates of handles when changing the number
  // of handles in this node.
  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    updateNodeInternals(nodeProps.id);
  }, [nodeProps.id, payload.keyTexts?.length, updateNodeInternals]);

  if (!payload.radioTexts) {
    payload.radioTexts = [];
    payload.keyTexts = [];
  }

  // it is very important to use the translation key for the id
  // as it is used for the .on target in the state machine transition
  // the id defines the "sourceHandle" in moduleUtils
  const sourceHandles = payload.keyTexts?.map(
    (keyText: string, index: number) => (
      <Handle
        id={keyText}
        key={keyText + index}
        className={classes['react-flow__handle']}
        type="source"
        position={Position.Bottom}
        style={{
          left: calculateHandlePosition(payload.keyTexts?.length ?? 1, index),
        }}
      />
    )
  );

  const radioTextTranslations = payload.radioTexts.map((radioText: string) =>
    translateKey(intl, radioText)
  );

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <RadioButton
          buttonText={translateKey(intl, payload.buttonText + '')}
          keyTexts={payload.keyTexts ?? []}
          radioTexts={radioTextTranslations ?? []}
          saveResultTo={payload.saveResultTo ?? ''}
        />
      </div>
      {sourceHandles}
    </>
  );
};
