/**
 * This files contains tyes and definitions for external API calls,
 * e.g, for OpenAI
 *
 * A copy of this file is used in the backend repo (folder types).
 * ! If you chnage this file, consider changing it in backend+player,too.
 */

/**
 * ExternalServiceEnum contains the external services that can be used.
 * We use GUIDs as service identifier as these identifiers are used in the
 * API calls. If we use identifiers, nobody from outside can see what
 * services we use in the backend.
 */
export enum ExternalServiceEnum {
  OPENAI = '385bf24b-c776-4c37-96f7-cdc7eaa20988', // OpenAI GPT-3
  OPENAI_CHAT_35 = '7f48a312-0004-40a0-b4bc-e5bc0320c0c9', // OpenAI GPT-3.5
  OPENAI_CHAT_35_TURBO_16K = '6dab4f55-89a3-4bb0-8672-4bbe45f2b2c4', // OpenAI GPT-3.5 Turbo 16k
  OPENAI_DOCUMENT_CHAT_35 = 'b2b2b2b2-0004-40a0-b4bc-e5bc0320c0c9', // OpenAI GPT-3.5 Turbo 16k (Document Handling)
  OPENAI_CHAT_4 = 'a67af413-ae4d-4730-ac4b-94c22b91d5b6', // OpenAI GPT-4
  OPENAI_DOCUMENT_CHAT_4 = 'ff36efe2-c6b2-4d1d-bd8e-dcb74a89e138', // OpenAI GPT-4 (Document Handling)
  BARD = 'c3c95dda-4399-4b0f-a888-e106f55f0d5d', // BARD
  OPENAI_CHAT_4o = 'bea7b3b3-4b0f-a888-e106f55f0d5d', // OpenAI GPT-4o
}

/**
 * mapping of names for displaying purposes only (e.g. in select lists)
 */
export const ExternalServiceEnumNameMappings: Record<string, string> = {
  '385bf24b-c776-4c37-96f7-cdc7eaa20988': 'OpenAI Completion GPT-3',
  '7f48a312-0004-40a0-b4bc-e5bc0320c0c9': 'OpenAI Chat GPT-3.5',
  '6dab4f55-89a3-4bb0-8672-4bbe45f2b2c4': 'OpenAI Chat GPT-3.5 Turbo 16k',
  'b2b2b2b2-0004-40a0-b4bc-e5bc0320c0c9':
    'OpenAI Chat GPT-3.5 + Document Handling',
  'a67af413-ae4d-4730-ac4b-94c22b91d5b6': 'OpenAI Chat GPT-4',
  'ff36efe2-c6b2-4d1d-bd8e-dcb74a89e138':
    'OpenAI Chat GPT-4 + Document Handling',
  'c3c95dda-4399-4b0f-a888-e106f55f0d5d': 'Google Bard',
  'bea7b3b3-4b0f-a888-e106f55f0d5d': 'OpenAI Chat GPT-4o',
};

/**
 * return a extneral service enum based on the name mappig (reverse function for mapping above)
 * @param {string} name - value as defined in ExternalServiceEnumNameMappings
 * @returns {ExternalServiceEnum} - enum value that matches the name
 */
export const getExternalServiceEnum = (name: string): ExternalServiceEnum => {
  const key = Object.keys(ExternalServiceEnumNameMappings).find(
    (key) => ExternalServiceEnumNameMappings[key] === name
  );
  return key
    ? (key as ExternalServiceEnum)
    : ExternalServiceEnum.OPENAI_CHAT_35;
};

/**
 * PromptEnum contains a list of pre-defined prompts that are available
 * as functions in the backend.
 */
export enum PromptEnum {
  INITALPROGRAMMODULE = 'initprogrammodule',
  ADDITIONALPROGRAMMOUDLE = 'additionalprogrammodule',
  PARAPHRASE = 'paraphrase',
  TOPICCLASSIFICATION = 'topicClassicfication',
  PICK_BEST_FITTING_CATEGORY = 'pickBestFittingCategory',
  PICKTOP_3_CATEGORIES = 'pickTop3Categories',
  PARAPHRASE_AND_PICK_TOP_CATEGORY = 'paraphraseAndPickBestFittingCategory',
  PARAPHRASE_AND_PICK_TOP_3_CATEGORIES = 'paraphraseAndPickTop3Categories',
  FREE_TEXT = 'freeText',
  CLASSIFY_SENTIMENT = 'classifySentiment',
  MICROCHAT_5_WHY = 'microchat5Why',
  MICROCHAT_SUPERVISOR = 'microchatSupervisor',
  MICROCHAT_MAGICQUESTIONSET = 'microchatMagicQuestionSet',
  MICROCHAT_HENLEY_8 = 'microchatHenleyEight',
  MICROCHAT_SOLUTION_FOCUSED = 'microchatSolutionFocused',
  MICROCHAT_AI_COACH = 'microchatAICoach', // same as SOlution focused but different name, Rebecca via Slack 2021-07-24
  MICROCHAT_FOLLOW_UP_QUESTIONS = 'microchatFollowUpQuestions',
  MICROCHAT_REFLECTION_SUPERVISOR = 'microchatReflectionSupervisor',
  MICROCHAT_EXECUTIVE_COACHING_EXPLORE = 'microchatExecutiveCoachingExplore',
  SINGLEPROMPT_SMART_PARAPHRASE = 'singlePromptSMARTParaphrase',
  LISTPROMPT_SUBGOAL_SUGGESTIONS = 'listPromptSubGoalsSuggestions',
  FREE_MICROCHAT = 'freeMicrochat',
  SUMMARIZE = 'summarize',
  MICROCHAT_PEAKLY_AI_COACH = 'microchatPeaklyAICoach',
  MICROCHAT_LEARNING_REFLECTIONS = 'microchatLearningReflections',
  TALK_TO_DOCUMENTS = 'talkToDocuments',
  MICROCHAT_GROW_COACH = 'microchatGrowCoach',
  MICROCHAT_ONBOARDING = 'microchatOnboarding',
  MICROCHAT_CLARIFY_COACHING_TOPIC = 'microchatClarifyCoachingTopic',
  GENERIC_CLASSIFICATION = 'genericClassification',
}

/**
 * this marker is used in backend for role=system info to the chatbot
 * an in player to detect the end of the conversation
 */
export const FINISH_MARKER = '_FINISHED_';

/**
 * this marker is used in backend for role=system info to the chatbot
 * and encapsulate and separate the context or additonal descriptions from
 * the rest of a prompt
 */
export const GROUPING_MARKER_OPEN = '=======================';
export const GROUPING_MARKER_CLOSE = '=======================';

/**
 * type of prompt variables that can be used in a prompt.
 * These are set and passed by the Player app
 */
export type PromptVariables = {
  /**
   * used to define the number of maximal sentences in an answer by OpenAI
   * e.g. for a summary
   */
  maxSentences?: number;
  /**
   * prepared text (potentially with line breaks) that should be summarized
   */
  textsToBeSummarized?: string;
  /**
   * generic array of values from the getValuesFrom variables during a chat
   */
  getValuesFrom?: Array<string | Record<string, any>>;
  /**
   * generic values from the getValueFrom variable during a chat
   */
  getValueFrom?: string | any;
  /**
   * define maximum number ofconversational turns with the AI. Is used
   * as variable within a priompt. No guarantee that the AI will stop.
   */
  maxTurns?: number;
  /**
   * accept any variable that is passed to the prompt by getStringValues.
   * In Player app there is a mapper that adds [varname]: varavlue to the
   * promptVariables and the varnames are defined by the user. We therefore
   * can't know the names in adavance
   */
  [k: string]: any;
};

/**
 * PromptData describes the input data of a prompt. The input data is
 * used differently depending on the prompt type used (@see PromptEnum)
 */
export type PromptData = {
  /**
   * language in which the prompt should be sent. The language is a code
   * like DE or EN. If the prompt isn't available in the corresponding languages,
   * the prompt in EN is chosen.
   */
  language: string;
  /**
   * This parameter allows for overwriting a prompt and serves two purposes:
   * - free text prompts (PROD-1966)
   * - debugging
   */
  overwritePrompt?: string;
  /**
   * title is provided for generating a new program module
   */
  title?: string;
  /**
   * description is provided for generating a new program module
   */
  description?: string;
  /**
   * input is used as single input variable for paraphrasing
   */
  input?: string;
  /**
   * promptVariables is used to identify and replace a varying number of
   * text variables in free text prompts
   */
  promptVariables?: PromptVariables;
  /**
   * list of classification keys that are used to classify the input
   * in the aiGenericClassification prompt
   */
  classificationKeys?: string[];
};

/**
 * PromptDataMicrochat has to be enhanced with props of Message Input
 * and for Coach Message (AI helper text), as this may be passed with
 * the prompt call!
 */
export type PromptDataMicrochat = PromptData & {
  /**
   * optional existing task id. If set, the task id isn't re-created but
   * re-used and the content of the previous sessions is taken as memory
   */
  taskid?: string;
  /**
   * if we allow talkig with chats, we need to know the accountid of the
   * coach. This is needed to make sure that the coach can't talk with
   * documents of other coaches.
   */
  accountid?: string;
  /**
   * if we allow talkig with chats, we need to know the documents we want to
   * talk to. THese are defined by the asset ids.
   * */
  assetids?: string[];
  /**
   * message input prop
   */
  emoticons?: boolean;
  /**
   * message input prop, translation key if set, not text!
   */
  placeholderText?: string;
  /**
   * message input prop
   */
  rowCount?: number;
  /**
   * directed agent mode - if set, the session state is used as chat memory
   * for the AI component.
   */
  directedAgentMode?: boolean;
  /**
   * indicates whether the prompt is used in preview mode. In preview mode,
   * the prompt AI memory is not written to S3. It may be also used to
   * skip certain persistance operations.
   */
  isPreview?: boolean;
  /**
   * the persona in the actual defenceShell prompt can be overwriten by this
   * encrypted property. It is set in aiMicrochatActions.ts in Player
   */
  persona?: string;
};

/**
 * a PromptResult contains the data from the backend call
 */
export type PromptResult = {
  /**
   * expected (!) language of the prompt output
   */
  language: string;
  /**
   * output = text as received from the external service
   * defined by @see ExternalServiceEnum
   */
  output: string;
  error?: string;
};

/**
 * AsyncCallTask is the description of a message that is put in the
 * backlend message queue for asyncHandler processing.
 */
export type AsyncCallTask = {
  /**
   * task id as receceived by @see AsyncCallResponse
   */
  taskid: string;
  /**
   * accountid of the calling account. This is very important as we can make sure
   * that you can't request taskid from other persons. For public modules,
   * the accountid is the accountid of the coach.
   */
  accountid: string;
  /**
   * external service like OpenAI
   */
  service: ExternalServiceEnum;
  payload: {
    prompt?: PromptEnum;
    promptData?: PromptData;
  };
};

/**
 * Before an asynchronous call is started that taks very long, a task id
 * is generated and stored in database. This task has a task id that is returned
 * with this AsyncCallResponse.
 * You can use this task id to poll the backend for a response.
 */
export type AsyncCallResponse = {
  /**
   * task id is a UUID returned by the backend.
   */
  taskid: string;
};
