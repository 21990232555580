import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';

//import { authorizedPut } from '../../../api/authorizedApi';
import { ModuleProps } from '../../../../entities';
import { DevToolsContext } from '../../../../devtools/DevToolsContext';
import { authorizedPut } from '../../../../api/authorizedApi';

import { parseTranslationFromString } from './importexport';

export interface ImportLanguageButtonProps {
  module: ModuleProps;
  importLanguage: string;
  refetchModule: Function;
}

export const ImportLanguageButton: React.FC<ImportLanguageButtonProps> = ({
  module,
  importLanguage,
  refetchModule,
}) => {
  const intl = useIntl();
  const { l } = useContext(DevToolsContext);

  const [importLanguageDialogOpen, setimportLanguageDialogOpen] =
    useState<boolean>(false);

  const [parsedData, setParsedData] = useState<any>(undefined);

  const onClose = async (close: boolean) => {
    // if "save" is clicked and parsing was successful, then save data
    if (close) {
      if (parsedData !== undefined) {
        await saveTranslation(parsedData);
        refetchModule();
      }
    }
    setimportLanguageDialogOpen(false);
  };

  const importLanguageAsJson = () => {
    setimportLanguageDialogOpen(true);
  };

  // write changed translations to backend
  const saveTranslation = useCallback(
    async (data: any) => {
      const url = `/module/${module.moduleid}/translation`;
      const updateModuleCall = authorizedPut(url, data);
      await updateModuleCall();
    },
    [module.moduleid]
  );

  const parseTranslation = useCallback(
    (filecontent: string | undefined) => {
      // no content? do nothing
      if (!filecontent) return;
      // try to parse JSON
      return parseTranslationFromString(filecontent, importLanguage, l);
    },
    [importLanguage, l]
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();
        reader.onabort = () => l('file reading was aborted');
        reader.onerror = () => l('file reading has failed');
        reader.onload = async () => {
          // check whether moduleid and language of imported file match to currently selected module and language
          const filesplit = file.path.split('/');
          const filenamesplit: string[] =
            filesplit[filesplit.length - 1].split('.');
          if (
            filenamesplit[0].toLowerCase() !== importLanguage.toLowerCase() ||
            filenamesplit[1] !== module.moduleid
          ) {
            // TODO find nicer popup
            alert(
              intl.formatMessage({
                id: 'builder.propertypane.importtranslation.wrongfile',
                defaultMessage:
                  'Die importierte Datei scheint nicht zur ausgewählten Sprache und/oder zum aktuellen Modul zu passen.',
              })
            );
            setParsedData(undefined);
            return;
          }
          // read file content as binaryStr
          const binaryStr = reader.result;
          // convert binaryString to string
          var enc = new TextDecoder('utf-8');
          var arr = new Uint8Array(binaryStr as ArrayBufferLike);
          // call file parser
          const data = parseTranslation(enc.decode(arr));
          setParsedData(data);
          if (data !== undefined) {
            alert(
              intl.formatMessage({
                id: 'builder.propertypane.importtranslation.successparsing',
                defaultMessage:
                  'Die Daten wurden erfolgreich importiert! Klicke auf "Speichern", um die Daten zu übernehmen.',
              })
            );
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [importLanguage, intl, l, module.moduleid, parseTranslation]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <IconButton
        onClick={() => importLanguageAsJson()}
        key={importLanguage + 'implang'}
        style={{ verticalAlign: 'top' }}
      >
        <Tooltip
          placement="top"
          arrow
          title={intl.formatMessage({
            id: 'builder.translationeditor.importtranslation.title',
            defaultMessage: 'Texte für diese Sprache importieren',
          })}
          key={importLanguage + 'importdefault'}
        >
          <FileUploadIcon />
        </Tooltip>
      </IconButton>

      <Dialog fullWidth open={importLanguageDialogOpen}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'builder.translationeditor.importlang.title',
            defaultMessage: 'Texte für eine Sprache importieren',
          })}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <div
              {...getRootProps()}
              style={{
                width: '98%',
                height: '100px',
                borderStyle: 'solid',
                borderWidth: '1px',
                paddingTop: '10px',
                paddingLeft: '10px',
              }}
            >
              <Typography variant="body2" component="span">
                {intl.formatMessage({
                  id: 'builder.translationeditor.importlang.description',
                  defaultMessage:
                    'Bitte ziehe die Datei mit den Texten in dieses Feld. Anschließend bestätige das Speichern. Existierende Texte werden überschrieben. Diese Aktion kann nicht rückgängig gemacht werden.',
                })}
              </Typography>
              <input {...getInputProps()} />
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose(false)}>
            {intl.formatMessage({
              id: 'builder.translationeditor.importlang.cancel',
              defaultMessage: 'Abbrechen',
            })}
          </Button>
          <Button color="secondary" onClick={() => onClose(true)}>
            {intl.formatMessage({
              id: 'builder.translationeditor.importlang.save',
              defaultMessage: 'Speichern',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
