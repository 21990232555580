import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  placeholderText: {
    id: 'builder.nodes.editableMultiselect.state.placeholderText',
    defaultMessage: 'Schreibe hier…',
  },
  buttonText: {
    id: 'builder.nodes.editableMultiselect.state.buttonText',
    defaultMessage: 'Weiter',
  },
  toolTipWarningMessage: {
    id: 'builder.nodes.editableMultiselect.state.tooltipWarning',
    defaultMessage:
      'Achtung! Du hast bereits die maximale Anzahl an Elementen ausgewählt!',
  },
});

export const editableMultiselectStateEntry: StateEntryProps = {
  type: 'renderEditableMultiselect',
  temporary: true,
  payload: {
    placeholderTexts: [
      'builder.nodes.editableMultiselect.state.placeholderText',
    ],
    elements: [],
    buttonText: 'builder.nodes.editableMultiselect.state.buttonText',
    saveResultTo: 'editablemulti',
    getValuesFrom: [''],
    minItems: 1,
    maxItems: 10,
    hideOwnInput: false,
    selectAll: false,
    toolTipWarningMessage:
      'builder.nodes.editableMultiselect.state.tooltipWarning',
  },
  saveResultType: ReadWriteType.typedObject,
  getValueType: [ReadWriteType.stringArray, ReadWriteType.typedObject],
  displayName: 'Editable Multiselect',
  nodeType: 'editableMultiselectStateEntry',
  handleOutCount: 1,
};
