import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'aiDocumentChatStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente können mit Hilfe Künstlicher Intelligenz Fragen zu den angegebenen Dokumenten beantwortet werden. Die Kontrolle über den Chatverlauf hat die KI.',
  },
  maxTurns: {
    id: 'aiDocumentChatStateEntry.maxTurns',
    defaultMessage:
      'maxTurns bestimmt die maximale Anzahl von Frage-Antwort-Wechseln in der Konversation.',
  },
  emoticons: {
    id: 'aiDocumentChatStateEntry.emoticons',
    defaultMessage:
      'Mit der Eigenschaft "emoticons" kannst du in den Eingabefeldern des Coachees die Option zur Auswahl von Emojis ein- und abschalten.',
  },
  assetids: {
    id: 'aiDocumentChatStateEntry.assetids',
    defaultMessage:
      'Du kannst ein oder mehrere Dokumente auswählen, die der KI für den Chat zur Verfügung gestellt werden.',
  },
  message: {
    id: 'aiDocumentChatStateEntry.message',
    defaultMessage:
      'Hier kannst du die primäre Aufgabe des Chatbots für den Dokumenten-Chat eingeben.',
  },
});

/**
 * the AI document chat state entryrequires a lot of addtional components.
 * Backend:
 * - via assets, documents are uploaded to the backend and assigned for use with AI
 * - via embeddingsHanlder they are processed and embeddings are stored on S3
 * - via asyncHandler the embeddings are used for the AI chat
 *
 */
export const aiDocumentChatStateEntry: StateEntryProps = {
  version: 2,
  type: 'renderInitMicrochat',
  temporary: false,
  payload: {
    externalService: ExternalServiceEnum.OPENAI_DOCUMENT_CHAT_35,
    promptType: PromptEnum.TALK_TO_DOCUMENTS,
    maxTurns: 20, // hidden for user ==> hidden via ValuesInput.tsx
    emoticons: false,
    message: '', // Free special an an
    assetids: [],
  },
  displayName: 'Document Chat (AI)',
  nodeType: 'aiDocumentChatStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 1,
};
