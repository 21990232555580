import { Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { Node } from 'reactflow';

import { isGetValueFromKey, StateEntryProps } from '../../nodes';

import { GetValueFromSelect } from './GetValueFromSelect';
import { PropertyHeaderLine } from './PropertyHeadLine';

interface GetValueFromProps {
  entry: StateEntryProps;
  updateGetValueFrom: Function;
  nodes: Node<any>[];
}

export const GetValueFrom: React.FC<GetValueFromProps> = ({
  entry,
  updateGetValueFrom,
  nodes,
}) => {
  const intl = useIntl();
  return (
    <Stack spacing={1}>
      {Object.keys(entry.payload)
        .filter(isGetValueFromKey)
        .map((payloadKey) => {
          const multiselect = payloadKey === 'getValuesFrom';
          const currentvalue = multiselect
            ? (entry.payload[payloadKey] as string[])
            : (entry.payload[payloadKey] as string);
          const getValueType = entry?.getValueType;
          // standard
          const header =
            payloadKey === 'firstValueToCompare'
              ? intl.formatMessage({
                  id: 'builder.moduleeditor.propertiesSidebar.firstValueToCompare.header',
                  defaultMessage: 'Vergleiche ...',
                })
              : payloadKey === 'secondValueToCompare'
              ? intl.formatMessage({
                  id: 'builder.moduleeditor.propertiesSidebar.secondValueToCompare.header',
                  defaultMessage: 'Mit ...',
                })
              : intl.formatMessage({
                  id: 'builder.moduleeditor.propertiesSidebar.getValueFrom.header',
                  defaultMessage: 'Wert erhalten von ...',
                });
          // vgl 1
          // vlg 2

          return (
            <div key={`ElementIdAndKey-${payloadKey}`}>
              <PropertyHeaderLine header={header} />
              <GetValueFromSelect
                updateValueFrom={(value: string) =>
                  updateGetValueFrom(value, payloadKey)
                }
                currentValue={currentvalue}
                multiselect={multiselect}
                getValueType={getValueType}
                nodes={nodes}
              />
            </div>
          );
        })}
    </Stack>
  );
};
