import { useContext } from 'react';
import { useQuery } from 'react-query';

import { TranslationContext } from '../../intl/TranslationContext';
import { authorizedGet } from '../authorizedApi';

export const useFetchSharedSessionsQuery = () => {
  const { appendModuleMessages } = useContext(TranslationContext);

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    'sharedsessions',
    async () => {
      const getSessions = authorizedGet(`/session/sharings/sharing`);
      const response = await getSessions();
      let data = await response.json();

      // mix coach data in sessions => they were extracted in backend to save
      // data while transfering again and again the same coach data
      data = data.map((sess: any) => {
        return {
          ...sess,
          session_metadata:
            typeof sess.session_metadata === 'string'
              ? JSON.parse(sess.session_metadata)
              : sess.session_metadata,
          session_metadatatranslation:
            typeof sess.session_metadatatranslation === 'string'
              ? JSON.parse(sess.session_metadatatranslation)
              : sess.session_metadatatranslation,
        };
      });

      // extract translations
      const translationsOfAllSessions = data
        .map(
          (currentSession: any) => currentSession.session_metadatatranslation
        )
        .reduce(
          (prev: any, metaTranslations: any) => ({
            ...prev,
            ...metaTranslations,
          }),
          {}
        );

      // add translations to provider
      appendModuleMessages(translationsOfAllSessions);

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    sessions: data,
    refetch,
  };
};
