import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  CardImageSize,
  CardIndicatorIcon,
  CardSize,
  formatDate,
  ObjectAddCard,
  ObjectCard,
  ObjectCardHeaderMenu,
  ObjectCardHeaderMenuItemProps,
} from '@evoach/ui-components';
import SearchIcon from '@mui/icons-material/Search';

import { AppRoutes } from '../../../routing/routes';
import { WindowContext } from '../../window/WindowContext';
import {
  authorizedDelete,
  authorizedGet,
  authorizedPatch,
  authorizedPost,
  authorizedPut,
} from '../../../api/authorizedApi';
import { useFetchProgram } from '../../../api/program/useFetchProgram';
import { AccountContext } from '../../../account/AccountContext';
import { RoleEnum } from '../../../account/AccountContextProps';
import { ConfirmDeletionDialog } from '../modules/ConfirmDeletionDialogue';
import {
  EditSession,
  initialProgramTemplate,
  Program,
  ProgramInstance,
  ProgramProps,
  ProgramTypeEnum,
} from '../../../entities';
import { useFetchPrograms } from '../../../api/program/useFetchPrograms';
import {
  NewObjectByJsonButton,
  NewObjectByJsonButtonTypeEnum,
} from '../modules/NewModuleByJsonButton';
import { useEnvironment } from '../../../environment/useEnvironment';
import { DevToolsContext } from '../../../devtools/DevToolsContext';
import { TranslationContext } from '../../../intl/TranslationContext';
import {
  EditSessionDialog,
  EditSessionObjectType,
} from '../modules/EditSessionDialog';

import { ManageCoacheesDialog } from './ManageCoacheesDialog';
import { ShareProgramDialog } from './ShareProgramDialog';
import { ProgramTypeDialog } from './ProgramTypeDialog';
import { AssignSubProgramDialog } from './AssignSubProgramDialog';

interface CoachingProgramsListProps {
  /**
   * display programs (false) or program instances (true)
   * @default false
   */
  instanceMode?: boolean;
  /**
   * use as sub-program-selection (true) or in a list view (false)
   * @default false
   */
  subProgramMode?: boolean;
  /**
   * a list of potentially pre-selected program ids.
   * Only used if selectMode = true
   */
  selectedProgramIds?: string[];
  /**
   * onSelect is triggered for each item that is selected.
   * Only used if selectMode = true
   * sends the program (instance) id and the information, whether item was
   * selected or unselected. Should have a function signature like:
   * (programid: string, selected: boolean) => {},
   */
  onSelect?: (programid: string, selected: boolean) => void;
  /**
   * list of program instance ids,
   * Only used if instanceMode = true
   */
  programModules?: string[];
  /**
   * parentProgramId is the id of the object that calls the select mode
   * If set, a backlink is added to the navigation URL for the edit button.
   * Works for selectMode && instanceMode = true only.
   */
  parentProgramId?: string;
}

export const CoachingProgramsList: React.FC<CoachingProgramsListProps> = ({
  instanceMode = false,
  subProgramMode = false,
  selectedProgramIds = [],
  onSelect = (_programid: string, _selected: boolean) => {},
  programModules = [],
  parentProgramId = '',
}) => {
  let navigate = useNavigate();

  const { messages } = useContext(TranslationContext);

  // ask for program type before creation
  const [programTypeDialogIsOpen, setProgramTypeDialogIsOpen] =
    useState<boolean>(false);
  // createNewProgram dialog?
  const createNewProgram = () => {
    // If we are in sub program mode, we create a sub-program.
    // If we are not in a sub-program mode, we ask what type of program to create
    if (!subProgramMode) {
      // ask first
      setProgramTypeDialogIsOpen(true);
    } else {
      // really
      reallyCreateNewProgram(true);
    }
  };

  const reallyCreateNewProgram = (
    issubprogram: boolean,
    programType: ProgramTypeEnum = ProgramTypeEnum.PROGRAM_WITHOUT_SUBMODULES
  ) => {
    // prepare initial program
    initialProgramTemplate.description =
      messages['builder.programs.emptyprogram.description'];
    initialProgramTemplate.title =
      messages['builder.programs.emptyprogram.title'];
    initialProgramTemplate.duration =
      messages['builder.programs.emptyprogram.duration'];

    // prepare initial module
    initialProgramTemplate.modules[0].title =
      messages['builder.programs.emptyprogram.modtitle'];
    initialProgramTemplate.modules[0].description =
      messages['builder.programs.emptyprogram.moddesc'];
    initialProgramTemplate.modules[0].duration =
      messages['builder.programs.emptyprogram.moddur'];
    initialProgramTemplate.modules[0].actions[0].description =
      messages['builder.programs.emptyprogram.actiondesc'];

    /* navigate(
      AppRoutes.NEWPROGRAM +
        `?issubprogram=${issubprogram}&parentProgramId=${parentProgramId}&programType=${programType}`
    );
 */
    createProgramApiCall(
      initialProgramTemplate,
      issubprogram,
      programType,
      parentProgramId
    );
  };

  const intl = useIntl();
  const { l } = useContext(DevToolsContext);
  const { hasRole, account } = useContext(AccountContext);
  const { alert } = useContext(WindowContext);

  // display only coaching program instances, which are owned by coach,i.e.,
  // not the one she was invited for and which are visible in Player for instance.
  const ownProgramInstancesOnly = true;
  const { programs, refetch: refetchPrograms } = useFetchPrograms(
    instanceMode,
    ownProgramInstancesOnly,
    programModules
  );

  const createProgramApiCall = (
    newProgram: ProgramProps,
    issubprogram: boolean,
    programtype: ProgramTypeEnum,
    parentProgramId: string
  ) => {
    const url = `/program?parentprogramid=${parentProgramId}`;
    const emptyProgramCall = authorizedPost(url, {
      program: newProgram,
      issubprogram: issubprogram,
      programtype: programtype,
    });

    emptyProgramCall()
      .then((response: any) => {
        response
          .json()
          .then((program: Program) => {
            // locally add program modules because this list isn't refreshed
            // automatically and interfers with the following refetch
            programModules.push(program.programid);
            refetchPrograms();
            editProgram(program.programid);
          })
          .catch((reason: unknown) => {
            alert(
              intl.formatMessage({
                id: 'builder.coachigprogramlist.create.error',
                defaultMessage:
                  'Beim Erstellen des Programms ist ein Fehler aufgetreten.',
              })
            );
            console.error(reason);
          });
      })
      .catch((reason: unknown) => {
        alert(
          intl.formatMessage({
            id: 'builder.coachigprogramlist.create.error2',
            defaultMessage:
              'Beim Erstellen des Programms ist ein Fehler aufgetreten.',
          })
        );
        console.error(reason);
      });
  };

  //console.log(instanceMode, ownProgramInstancesOnly, programModules);

  // catch window resize events
  const { clientHeight } = useContext(WindowContext);

  // if program list is reloaded, re-generate mapping for rows
  // rows is also used by child components
  /*  useEffect(() => {
    refetchPrograms();
  }, [instanceMode, refetchPrograms]);
 */
  const numberOfPrograms = useMemo(() => {
    if (!programs) {
      return 0;
    }

    if (instanceMode) {
      return (programs as ProgramInstance[]).filter(
        (program: ProgramInstance) => program && program.program.title
      ).length;
    } else {
      return (programs as Program[]).filter(
        (program: Program) => program && program.program.title
      ).length;
    }
  }, [instanceMode, programs]);

  // enable filtering the programs by typing text
  const [filterText, setFilterText] = useState<string>('');

  const rows = useMemo(() => {
    if (!programs) {
      return [];
    }

    let retVal = [];

    if (instanceMode) {
      retVal = (programs as ProgramInstance[])
        .filter(
          (programInstance: ProgramInstance) =>
            programInstance && programInstance.program.title
        )
        .map((programInstance: ProgramInstance, mindex: number) => {
          return {
            id: mindex,
            programtitle: programInstance.program.title,
            programid: programInstance.programinstanceid, // ! instance
            programdescription: programInstance.program.description,
            programduration: programInstance.program.duration,
            programimage: programInstance.program.image,
            programlanguage: programInstance.program.language,
            programcreation: programInstance.tscreated,
            programimagesrc: programInstance.program.resolvedsrc,
            programtype: programInstance.programtype,
            programissub: programInstance.issubprogram,
            programmodulesnum:
              programInstance.programmodules &&
              Array.isArray(programInstance.programmodules)
                ? programInstance.programmodules.length
                : 0,
            ordernumber: programInstance.program.ordernumber ?? 0,
            isshared: false, // only for programs, no for instances
          };
        });
    } else {
      retVal = (programs as Program[])
        .filter((program: Program) => program.issubprogram === subProgramMode)
        .filter((program: Program) =>
          programModules && subProgramMode
            ? programModules.includes(program.programid)
            : true
        ) // if there is a list of programModules set => display only this list
        .filter((program: Program) =>
          !subProgramMode
            ? true
            : program.programtype === ProgramTypeEnum.PROGRAM_WITHOUT_SUBMODULES
        ) // in select mode, do only show programs that have no sub modules
        .map((program: Program, mindex: number) => {
          return {
            id: mindex,
            programtitle: program.program.title,
            programid: program.programid, // ! program
            programdescription: program.program.description,
            programduration: program.program.duration,
            programimage: program.program.image,
            programlanguage: program.program.language,
            programcreation: program.tscreated,
            programimagesrc: program.program.resolvedsrc,
            programtype: program.programtype,
            programissub: program.issubprogram,
            programmodulesnum:
              program.programmodules && Array.isArray(program.programmodules)
                ? program.programmodules.length
                : 0,
            ordernumber: program.program.ordernumber ?? 0,
            isshared:
              program.permissions !== undefined &&
              program.permissions[0].invitinginvitationid !== null &&
              program.permissions[0].invitinginvitationid !== undefined,
          };
        });
    }

    return retVal
      .map((row: any) => {
        if (
          (row.programtitle + '')
            .toLowerCase()
            .includes(filterText.toLowerCase()) ||
          (row.programdescription + '')
            .toLowerCase()
            .includes(filterText.toLowerCase())
        ) {
          return row;
        } else {
          return undefined;
        }
      })
      .filter((row: any) => row !== undefined)
      .sort((elem1: any, elem2: any) =>
        elem1.ordernumber - 0 < elem2.ordernumber - 0 ? 1 : -1
      );
  }, [filterText, instanceMode, programModules, programs, subProgramMode]);

  // dialog management
  const [assignSubDialogIsOpen, setAssignSubDialogIsOpen] =
    useState<boolean>(false);

  const [sessionDialog, setSessionDialog] = useState(0);
  const [currentProgramId, setCurrentProgramId] = useState('');

  /**
   * helper function to open editor for program/programinstance with program(instance)id
   * @param {string} programid
   */
  const jumpToEditor = (programid: string) => {
    const backUrl =
      (subProgramMode && instanceMode) ||
      (parentProgramId && parentProgramId.trim() !== '')
        ? `?${instanceMode ? 'instance' : 'program'}=${parentProgramId}`
        : '';
    navigate(
      `${AppRoutes.PROGRAMEDITOR}/${programid}/${
        instanceMode ? 'instance' : 'program'
      }${backUrl}`
    );
  };

  // switch to editor to edit program
  const editProgram = (programid: string) => {
    if (!programid || programid === '') return;

    // check for session
    const apiUrl = `/editsession/${programid}?type=${instanceMode ? '2' : '1'}`;

    const startSessionCall = authorizedGet(apiUrl);
    startSessionCall()
      .then((response: Response) => {
        // rememeber id
        setCurrentProgramId(programid);
        // check for existing sessions
        response.json().then((res: EditSession[]) => {
          if (res.length === 0) {
            // no pending sessions
            jumpToEditor(programid);
          } else {
            if (res.length === 1) {
              // check whether this is my session
              if (res[0].accountid === account?.accountid) {
                setSessionDialog(1); // current user has 1 other session
              } else {
                setSessionDialog(2); // other user has 1 other session
              }
            } else {
              // warning, more than one user!
              setSessionDialog(3);
            }
          }
        });
      })
      .catch((_reason: unknown) => {
        // log to console in case of error
        l(_reason);
        // error => open anyway
        setSessionDialog(4);
      });
  };

  /**
   * If the program is not a subprogram, it is assigned to a sub-program.
   * If the program is a subprogram, the assignement is removed
   * @param {any} row
   */
  const assignSubProgram = (row: any) => {
    setSelectedProgramId(row.programid);
    if (parentProgramId && parentProgramId.trim() !== '' && row.programissub) {
      // issub and we have a parent id
      reallyAssignSubProgram(parentProgramId, 'remove', row.programid);
    } else {
      // now sub and no parent => ask to what program to assign
      setAssignSubDialogIsOpen(true);
    }
  };

  // confirm assignment / removal
  const reallyAssignSubProgram = (
    parentprogramid: string,
    type: 'remove' | 'assign',
    programid?: string
  ) => {
    const updateURL = `/program/${
      programid ?? selectedProgramId
    }?parentprogramid=${parentprogramid}`;
    const updateCall = authorizedPut(updateURL, {
      issubprogram: type === 'assign',
    });

    updateCall()
      .then((response: any) => {
        response
          .json()
          .then((program: Program) => {
            // locally add program modules because this list isn't refreshed
            // automatically and interfers with the following refetch
            if (type === 'remove') {
              programModules = programModules.filter(
                (id: string) => id !== program.programid
              );
            }
            refetchPrograms();
          })
          .catch((reason: unknown) => {
            alert(
              intl.formatMessage({
                id: 'builder.coachigprogramlist.assign.error',
                defaultMessage:
                  'Beim Aktualisieren des Programms ist ein Fehler aufgetreten.',
              })
            );
            console.error(reason);
          });
      })
      .catch((reason: unknown) => {
        alert(
          intl.formatMessage({
            id: 'builder.coachigprogramlist.assign.error2',
            defaultMessage:
              'Beim Aktualisieren des Programms ist ein Fehler aufgetreten.',
          })
        );
        console.error(reason);
      });

    setSelectedProgramId('');
  };

  /**
   * create new program instance for a program and redirect to editor
   * @param programid
   * @returns
   */
  const createProgramInstance = async (programid: string) => {
    if (!programid || programid === '') return;

    // create new instance
    const createUrl = `/programinstance/${programid}`;
    const createCall = authorizedPost(createUrl);
    const response = await createCall();
    const data = await response.json();

    // TODO handle errors in call properly
    // redirect to editor
    if (data && data.programinstanceid) {
      navigate(`${AppRoutes.PROGRAMEDITOR}/${data.programinstanceid}/instance`);
    }
    // TODO else show error
  };

  const { playerBasePath } = useEnvironment();
  const [previewwindow, setpreviewwindow] = useState<any>(undefined);

  const openHRView = (programid: string) => {
    let newpreviewwindow: Window | null = previewwindow;

    if (!newpreviewwindow || newpreviewwindow.closed) {
      newpreviewwindow = window.open(
        `${playerBasePath}/program/${programid}`,
        'coachingprogrampreviewwindow'
      );
    }

    if (newpreviewwindow) {
      newpreviewwindow.focus();
      setpreviewwindow(newpreviewwindow);
      newpreviewwindow.location =
        `${playerBasePath}/program/${programid}` as unknown as Location;
    }
  };

  // open share dialog?
  const [programShareDialogIsOpen, setProgramShareDialogIsOpen] =
    useState<boolean>(false);
  // remeber clicked program for sharing
  const [clickedProgramId, setClickedProgramId] = useState<string>('');
  // oen dialog
  const shareForEdit = (programid: string) => {
    if (programid) {
      setClickedProgramId(programid);
      setProgramShareDialogIsOpen(true);
    }
  };

  /**
   * duplicate a program by programid
   * @param {string} programid
   * @returns
   */
  const duplicateProgram = async (programid: string) => {
    if (!programid || programid === '') return;
    const duplicateURL = `/${
      instanceMode ? 'programinstance' : 'program'
    }/${programid}?parentprogramid=${parentProgramId}`;
    let duplicateCall;
    // PROD-2073
    if (instanceMode) {
      duplicateCall = authorizedPatch(duplicateURL);
    } else {
      duplicateCall = authorizedPost(duplicateURL);
    }

    duplicateCall()
      .then((response: any) => {
        response
          .json()
          .then((program: Program) => {
            // locally add program modules because this list isn't refreshed
            // automatically and interfers with the following refetch
            programModules.push(program.programid);
            refetchPrograms();
          })
          .catch((reason: unknown) => {
            alert(
              intl.formatMessage({
                id: 'builder.coachigprogramlist.duplicate.error',
                defaultMessage:
                  'Beim Duplizieren des Programms ist ein Fehler aufgetreten.',
              })
            );
            console.error(reason);
          });
      })
      .catch((reason: unknown) => {
        alert(
          intl.formatMessage({
            id: 'builder.coachigprogramlist.duplicate.error2',
            defaultMessage:
              'Beim Duplizieren des Programms ist ein Fehler aufgetreten.',
          })
        );
        console.error(reason);
      });
  };

  const [selectedProgramId, setSelectedProgramId] = useState<string>('');

  const [deletionDialogIsOpen, setDeletionDialogIsOpen] =
    useState<boolean>(false);

  const reallyDeleteProgram = async () => {
    if (!selectedProgramId || selectedProgramId === '') return;
    const deletionURL: RequestInfo = `/${
      instanceMode ? 'programinstance' : 'program'
    }/${selectedProgramId}${
      parentProgramId ? '?parentprogramid=' + parentProgramId : ''
    }`;
    const deletionCall = authorizedDelete(deletionURL);

    try {
      await deletionCall();
      refetchPrograms();
    } catch (reason: any) {
      console.error(reason);
      if (reason.httpStatus === 403) {
        // TODO nicer modal dialog than alert
        alert(
          intl.formatMessage({
            id: 'builder.reception.programlist.deleteprogram.forbidden',
            defaultMessage:
              'Du hast nicht die erforderlichen Rechte, um das Programm zu löschen.',
          })
        );
      } else {
        alert(
          intl.formatMessage({
            id: 'builder.reception.programlist.deleteprogram.couldnotdelete',
            defaultMessage: 'Das Programm konnte nicht gelöscht werden.',
          })
        );
      }
    }

    setSelectedProgramId('');
  };

  const deleteProgram = (programid: string) => {
    if (!programid || programid === '') return;
    setSelectedProgramId(programid);
    setDeletionDialogIsOpen(true);
  };

  // prepare data for CopyToClipboard
  const [programid, setProgramId] = useState<string>('');
  const { program } = useFetchProgram(programid);

  const [programJsonAsString, setProgramJsonAsString] = useState<string>('');

  useEffect(() => {
    if (!program) setProgramJsonAsString('');
    setProgramJsonAsString(JSON.stringify(program));
  }, [program]);

  const copyProgramToClipBoard = (programid: string) => {
    if (!programid || programid === '') return;
    setProgramId(programid);
  };

  // if copy string changes, trigger click on copytoclipboard component
  useEffect(() => {
    if (!programJsonAsString || programJsonAsString.trim() === '') return;
    navigator.clipboard.writeText(programJsonAsString).then(() => {
      alert(
        intl.formatMessage({
          id: 'builder.reception.programlist.cards.jsoncopied',
          defaultMessage:
            'Programm wurde als JSON in die Zwischenablage kopiert.',
        })
      );
      setProgramJsonAsString('');
    });
    //document.getElementById('clickcatcherforclipboard')?.click();
  }, [alert, intl, programJsonAsString]);

  const cardSize = CardSize.SMALL;

  // height calc
  const maxHeight = clientHeight - 315 + 'px';

  const [manageCoacheeDialogIsOpen, setManageCoacheeDialogIsOpen] =
    useState<boolean>(false);
  const [programIdForManageCoachees, setProgramIdForManageCoachees] =
    useState<string>('');
  const [programTitleForManageCoachees, setProgramTitleForManageCoachees] =
    useState<string>('');

  const closeManageCoacheesDialog = () => {
    setManageCoacheeDialogIsOpen(false);
  };

  const getCardJSX = (row: any) => {
    const menuitems: ObjectCardHeaderMenuItemProps[] = [];

    // Edit program
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.programlist.cards.menuitems.edit',
        defaultMessage: 'Editieren',
      }),
      disabled: false,
      onClick: () => editProgram(row.programid),
    });

    // duplicate program
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.programlist.cards.menuitems.duplicate',
        defaultMessage: 'Duplizieren',
      }),
      disabled: false,
      onClick: () => duplicateProgram(row.programid),
    });

    // delete program
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.programlist.cards.menuitems.delete',
        defaultMessage: 'Löschen',
      }),
      disabled: false,
      onClick: () => deleteProgram(row.programid),
    });

    // manage invitations to coaching programs instance
    if (instanceMode) {
      menuitems.push({
        text: intl.formatMessage({
          id: 'builder.reception.programlist.cards.menuitems.managecoachees',
          defaultMessage: 'Teilnehmer verwalten',
        }),
        disabled: false,
        onClick: () => {
          setProgramTitleForManageCoachees(row.programtitle);
          setProgramIdForManageCoachees(row.programid);
          setManageCoacheeDialogIsOpen(true);
        },
      });
    }

    // create new program instance
    if (!instanceMode && !(parentProgramId && parentProgramId.trim() !== '')) {
      menuitems.push({
        text: intl.formatMessage({
          id: 'builder.reception.programlist.cards.menuitems.preview',
          defaultMessage: 'Vorschau der Akquise-Ansicht',
        }),
        disabled: false,
        onClick: () => openHRView(row.programid),
      });

      menuitems.push({
        text: intl.formatMessage({
          id: 'builder.reception.programlist.cards.menuitems.startnew',
          defaultMessage: 'Programm mit neuer Gruppe starten',
        }),
        disabled: false,
        onClick: () => createProgramInstance(row.programid),
      });

      menuitems.push({
        text: intl.formatMessage({
          id: 'builder.reception.programlist.cards.menuitems.sharewithothercoaches',
          defaultMessage: 'Zum Bearbeiten teilen',
        }),
        disabled: false,
        onClick: () => shareForEdit(row.programid),
      });
    }

    // Add programs to sub-programs and revert it
    // This is only allowed for programes without sub-programes
    if (
      !instanceMode &&
      row.programtype === ProgramTypeEnum.PROGRAM_WITHOUT_SUBMODULES
    ) {
      //
      menuitems.push({
        text: !row.programissub
          ? intl.formatMessage({
              id: 'builder.reception.programlist.cards.menuitems.assigntosub',
              defaultMessage: 'Als Unterprogramm verwenden',
            })
          : intl.formatMessage({
              id: 'builder.reception.programlist.cards.menuitems.revertfromsub',
              defaultMessage: 'Zu normalem Programm konvertieren',
            }),

        disabled: false,
        onClick: () => assignSubProgram(row),
      });
    }

    // copy program as JSON
    if (hasRole(RoleEnum.EVOACHADMIN)) {
      menuitems.push({
        text: intl.formatMessage({
          id: 'builder.reception.programlist.cards.menuitems.copymodule2clipboard',
          defaultMessage: 'Als JSON in Zwischenablage kopieren',
        }),
        disabled: false,
        onClick: () => copyProgramToClipBoard(row.programid),
      });
    }

    // display creation date of a program
    menuitems.push({
      text:
        intl.formatMessage({
          id: 'builder.reception.programlist.cards.menuitems.createdatthe',
          defaultMessage: 'Erstellt am',
        }) +
        ' ' +
        formatDate(row.programcreation.toString(), intl.locale),
      disabled: true,
      onClick: () => {},
    });

    return (
      <ObjectCard
        title={row.programtitle}
        description={row.programdescription}
        duration={row.programduration}
        createdBy=""
        id={row.programid}
        image={row.programimage}
        imageSrc={row.programimagesrc}
        imageSize={CardImageSize.MEDIUM}
        cardSize={cardSize}
        indicator={
          row.isshared ? CardIndicatorIcon.SHAREDMODULE : CardIndicatorIcon.NONE
        }
        labels={
          row.programtype === ProgramTypeEnum.PROGRAM_WITH_SUBMODULES
            ? [
                intl.formatMessage(
                  {
                    id: 'builder.reception.programlist.cards.numsubprograms',
                    defaultMessage: 'Unterprogramme: {subs}',
                  },
                  { subs: row.programmodulesnum }
                ),
              ]
            : undefined
        }
        indicatorSharedModuleText={intl.formatMessage({
          id: 'builder.reception.programlist.cards.sharedtext',
          defaultMessage: 'Dieses Programm wurde mit dir geteilt.',
        })}
        flags={row.programsupportedlang}
        headermenu={
          <>
            <ObjectCardHeaderMenu
              id={row.programid}
              buttonTitle={intl.formatMessage({
                id: 'builder.reception.programlist.cards.editmodule',
                defaultMessage: 'Editieren',
              })}
              onButtonClick={(programid: string) => editProgram(programid)}
              selectable={false}
              onSelect={
                subProgramMode && !instanceMode
                  ? (programid: string) =>
                      onSelect(
                        programid,
                        !selectedProgramIds.includes(programid)
                      )
                  : undefined
              }
              defaultSelected={
                subProgramMode && Array.isArray(selectedProgramIds)
                  ? selectedProgramIds.includes(row.programid)
                  : false
              }
              menuitems={menuitems}
            />
          </>
        }
      />
    );
  };

  const getAddCardJSX = () => {
    // no add card for instances
    if (instanceMode) return;

    // add card for programs
    return (
      <ObjectAddCard
        label={
          parentProgramId && parentProgramId.trim() !== ''
            ? intl.formatMessage({
                id: 'builder.reception.programlist.cards.addnewsubmodule',
                defaultMessage: 'Neues Unterprogram erstellen',
              })
            : intl.formatMessage({
                id: 'builder.reception.programlist.cards.addnewmodule',
                defaultMessage: 'Neues Programm erstellen',
              })
        }
        onClick={() => createNewProgram()}
        cardSize={cardSize}
      />
    );
  };

  /*
  for grid layout, pls have a look at
  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Basic_Concepts_of_Grid_Layout
  */

  return (
    <div
      style={{
        width: '100%',
        height: maxHeight,
        maxHeight: maxHeight,
      }}
    >
      <AssignSubProgramDialog
        isOpen={assignSubDialogIsOpen}
        onClose={(parentprogramid?: string) => {
          if (parentprogramid) {
            setSelectedProgramId(parentprogramid);
            reallyAssignSubProgram(parentprogramid, 'assign');
          } else {
            setSelectedProgramId('');
          }
          setAssignSubDialogIsOpen(false);
        }}
        excludeProgramIds={[selectedProgramId]}
      />

      <ProgramTypeDialog
        isOpen={programTypeDialogIsOpen}
        onClose={(type?: ProgramTypeEnum) => {
          // type === undefined: dialog was closed without selecting an option
          if (type) {
            reallyCreateNewProgram(false, type);
          }
          setProgramTypeDialogIsOpen(false);
        }}
      />

      <ShareProgramDialog
        open={programShareDialogIsOpen}
        onCancel={() => setProgramShareDialogIsOpen(false)}
        onCreate={() => setProgramShareDialogIsOpen(false)}
        programid={clickedProgramId}
      />

      <ConfirmDeletionDialog
        title={intl.formatMessage({
          id: 'builder.reception.programlist.cards.deletiontitle',
          defaultMessage: 'Programm löschen',
        })}
        open={deletionDialogIsOpen}
        onConfirm={() => {
          reallyDeleteProgram();
          setDeletionDialogIsOpen(false);
        }}
        onClose={() => {
          setSelectedProgramId('');
          setDeletionDialogIsOpen(false);
        }}
        message={intl.formatMessage({
          id: 'builder.reception.programlist.cards.deletionmessage',
          defaultMessage:
            'Wenn du das Löschen bestätigst, wird das Program gelöscht und aus deiner Liste entfernt. Diese Aktion kann nicht rückgängig gemacht werden.',
        })}
      />
      <EditSessionDialog
        dialogType={sessionDialog}
        isOpen={sessionDialog > 0}
        onClose={(startEditor: boolean) => {
          setSessionDialog(0);
          if (startEditor) {
            jumpToEditor(currentProgramId);
          }
          setCurrentProgramId('');
        }}
        editSessionObjectType={EditSessionObjectType.PROGRAM}
      />
      <ManageCoacheesDialog
        open={manageCoacheeDialogIsOpen}
        onClose={closeManageCoacheesDialog}
        programInstanceId={programIdForManageCoachees}
        programTitle={programTitleForManageCoachees}
      />
      {!subProgramMode && (
        <div
          style={{
            width: '100%',
            display: 'grid',
            grid: 'auto / 480px 100px auto',
            overflow: 'hidden',
            marginLeft: '10px',
            alignContent: 'bottom',
            alignItems: 'bottom',
          }}
        >
          <FormControl
            variant="standard"
            style={{
              display: 'inline',
              alignContent: 'bottom',
              alignItems: 'bottom',
            }}
          >
            <Typography
              variant="body2"
              color="#AAAAAA"
              style={{ marginRight: '10px' }}
              component="span"
            >
              {rows.length}/{numberOfPrograms}
            </Typography>
            <Input
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                if (e.target.value !== undefined) {
                  setFilterText(e.target.value);
                }
              }}
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              }
              autoComplete="off"
              autoCorrect="off"
              placeholder={intl.formatMessage({
                id: 'builder.reception.programlist.cards.searchbox',
                defaultMessage: 'Programm suchen ...',
              })}
            />
          </FormControl>

          {hasRole(RoleEnum.EVOACHADMIN) && (
            <div
              style={{
                display: 'grid',
                grid: 'auto / auto auto ',
                width: '500px',
              }}
            >
              {!instanceMode && (
                <NewObjectByJsonButton
                  mode={NewObjectByJsonButtonTypeEnum.PROGRAM}
                />
              )}
            </div>
          )}
        </div>
      )}
      <Box
        sx={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {!instanceMode && (
          <Box
            style={{ marginTop: '20px', marginLeft: '10px' }}
            key="objectcardlistdiv_add"
            component="div"
          >
            {getAddCardJSX()}
          </Box>
        )}
        {(false || (subProgramMode && !instanceMode && rows.length === 0)) && (
          <Box
            sx={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              maxWidth: '600px',
            }}
          >
            <Typography sx={{ marginLeft: '20px' }}>
              {intl.formatMessage({
                id: 'builder.reception.programlist.subprograms.noprograms',
                defaultMessage:
                  'Du hast noch keine Unterprogramme angelegt. Wenn du hier ein Unterprogramm erstellst, wird es nur hier angezeigt und kann auch nur von hier aus editiert werden. ',
              })}
            </Typography>
          </Box>
        )}
        {true &&
          rows.map((row: any, index: number) => {
            //
            return (
              <Box
                style={{ marginTop: '20px', marginLeft: '10px' }}
                key={'objectcardlistdiv' + row.programid + '_' + index}
                component="div"
              >
                {getCardJSX(row)}
              </Box>
            );
          })}
      </Box>
    </div>
  );
};
