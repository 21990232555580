//import { EvoachButton } from '@evoach/ui-components';

import { Box, Button, Checkbox, Modal, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Tour, { ReactourStep } from 'reactour';

import { ProductTourButton } from './ProductTourButton';
import { ProductTourContext } from './ProductTourContext';

interface ProductTourManagerProps {
  tourid?: string;
  toursteps?: ReactourStep[];
}

/**
 * Component to display a product tour
 *
 * The tourid and the toursteps are required parameters.
 *
 * This component is a wrapper around ReactTour. @see https://github.com/elrumordelaluz/reactour
 * It provides additional functionality:
 * - Button configuration
 * - central change management
 * - central "skip this tour forever" checkbox
 *
 */
export const ProductTourManager: React.FC<ProductTourManagerProps> = ({
  tourid,
  toursteps,
}) => {
  const intl = useIntl();

  // use context to read/write user preference about displaying this tour
  const { isTourClosed, getAllowDisplayTour, setAllowDisplayTour, openTours } =
    useContext(ProductTourContext);

  useEffect(() => {
    if (openTours.indexOf(tourid!) !== -1) {
      setIsTourOpen(true);
    }
  }, [openTours, isTourClosed, tourid]);

  // remember if current step is last step
  const [isLastStep, setIsLastStep] = useState(false);

  // remember toursteps in state
  const [tourSteps] = useState<ReactourStep[]>(toursteps ?? []);

  // show onClose dialog
  const [closeQuestionDialogIsOpen, setCloseQuestionDialogIsOpen] =
    useState<boolean>(false);

  // the tour is displayed if it wasn't globally set to "never show again"
  const [isTourOpen, setIsTourOpen] = useState<boolean>(
    getAllowDisplayTour(tourid) === 'true' && !isTourClosed(tourid)
  );

  // toggle checkbox for "never show again"
  const [hideProductTour, setHideProductTour] = useState<boolean>(
    getAllowDisplayTour(tourid) === 'true'
  );

  // if checkbox is changed write change to local storage
  /* useEffect(() => {
    setAllowDisplayTour(showProductTour, tourid);
  }, [showProductTour, setAllowDisplayTour, tourid]);
 */

  // TODO toggle und initialer Start

  // if no tour steps are available, do not render anything
  if (!tourSteps || (Array.isArray(tourSteps) && tourSteps.length === 0)) {
    return <></>;
  }

  return (
    <>
      <Tour
        key={tourid}
        isOpen={isTourOpen && !isTourClosed(tourid)}
        onRequestClose={() => setIsTourOpen(false)}
        steps={tourSteps ?? []}
        getCurrentStep={(currentStep: number) =>
          setIsLastStep(currentStep === tourSteps.length - 1)
        }
        accentColor="#4199AC"
        nextButton={<ProductTourButton buttonType="next" />}
        prevButton={<ProductTourButton buttonType="previous" />}
        lastStepNextButton={<ProductTourButton buttonType="laststepnext" />}
        showCloseButton
        onBeforeClose={() => {
          setCloseQuestionDialogIsOpen(true);
          setAllowDisplayTour(!isLastStep, tourid);
        }}
        rounded={4}
        showNumber={false}
        disableDotsNavigation={false}
        showNavigation={true}
      />
      <Modal
        open={closeQuestionDialogIsOpen && !isLastStep}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => {
          setCloseQuestionDialogIsOpen(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '4px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography component="span">
            <Checkbox
              id="closeThisDialog"
              key="embeddableModuleCheckboxForProgressbar"
              checked={!hideProductTour}
              onChange={() => {
                setAllowDisplayTour(!hideProductTour, tourid);
                setHideProductTour(!hideProductTour);
              }}
            />
            {intl.formatMessage({
              id: 'builder.producttour.closeforeverdialog.checkbox.text',
              defaultMessage: 'Diese Produkttour nicht mehr anzeigen.',
            })}
          </Typography>
          <Button onClick={() => setCloseQuestionDialogIsOpen(false)}>
            {intl.formatMessage({
              id: 'builder.producttour.closeforeverdialog.button.text',
              defaultMessage: 'Produkttour schließen',
            })}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
