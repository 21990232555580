//import { cloneDeep } from 'lodash';
import { useContext } from 'react';
import { useQuery } from 'react-query';

//import { cleanUpTranslations } from '../../components/ModuleEditor/moduleUtils';
import { mapImageToPublicAsset } from '../../components/reception/assets/AssetHelper';
import { TranslationContext } from '../../components/stateMachineTranslationContext';
import { ModuleProps } from '../../entities/Module';
import { useEnvironment } from '../../environment/useEnvironment';
import { TranslationContext as GlobalTranslationContext } from '../../intl/TranslationContext';
import { getAssetUrl } from '../asset/useFetchAssetUrl';
import { authorizedGet } from '../authorizedApi';

/**
 * get a module incl. translations, statemachine, buildergraph, etc.
 * @param {string} moduleId to be fetched
 * @param {string} moduleLanguage to be fetched, if not set, the current state machine translation from TranslationContext is used
 * @returns isLoading, isError, error, module, refetch
 */
export const useFetchModule = (
  moduleId: string = '',
  moduleLanguage?: string
) => {
  const { appendModuleMessages } = useContext(GlobalTranslationContext);
  const { stateMachineLocale } = useContext(TranslationContext);
  const { playerBasePath } = useEnvironment();

  const language = moduleLanguage ?? stateMachineLocale;

  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `module-${moduleId}-${language}`,
    async () => {
      if (!moduleId || moduleId.trim() === '') return undefined;

      // use last stateMachineLocale for loading the module, assuming that this is your
      // prefered locale. By default / if no module was previously loaded, it is set to
      // the UI locale
      const url = `/module/${moduleId}?language=${language.toUpperCase()}`;

      const fetchModuleCall = authorizedGet(url);
      const response = await fetchModuleCall();

      const data: ModuleProps = await response.json();

      // resolve image of a module
      if (data.metadata.assetid) {
        data.metadata.resolvedsrc = await getAssetUrl(
          data.metadata.assetid ?? ''
        );
      } else {
        if (data.metadata.src) {
          data.metadata.resolvedsrc = data.metadata.src;
        } else {
          if (data.metadata.image) {
            data.metadata.resolvedsrc = mapImageToPublicAsset(
              data.metadata.image,
              playerBasePath
            );
          } else {
            data.metadata.resolvedsrc = '';
          }
        }
      }

      // clean up translations / may be relevant when workin gon PROD-1771
      /*
      data.translations[0].statemachinetranslation = cleanUpTranslations(
        data.statemachine,
        cloneDeep(data.translations[0].statemachinetranslation)
      );
      */

      // module messages are attached to global intl context
      appendModuleMessages({
        ...data.translations[0].metadatatranslation,
        ...data.translations[0].statemachinetranslation,
      });

      return data;
    },
    {
      retry: false,
      refetchOnMount: 'always', // PROD-1844
      cacheTime: 10000, // PROD-1844, 10 sec max cache time
    }
  );

  return {
    isLoading,
    isError,
    error,
    module: data as ModuleProps,
    refetch,
  };
};

/**
 * get a module summary, incl. metadata+translations but excl. statemachine, buildergraph, etc.
 * @param {string} moduleId to be fetched
 * @returns isLoading, isError, error, module, refetch
 */
export const useFetchModuleSummary = (
  moduleId: string = '',
  moduleLanguage?: string
) => {
  const { appendModuleMessages } = useContext(GlobalTranslationContext);
  const { stateMachineLocale } = useContext(TranslationContext);

  const langToFetch = moduleLanguage ?? stateMachineLocale;

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `summary-module-${moduleId}-${langToFetch}`,
    async () => {
      if (moduleId.length > 0) {
        // use last stateMachineLocale for loading the module, assuming that this is your
        // prefered locale. By default / if no module was previously loaded, it is set to
        // the UI locale
        const url = `/module/summary/${moduleId}?language=${langToFetch.toUpperCase()}`;

        const fetchModuleCall = authorizedGet(url);
        const response = await fetchModuleCall();

        const data = (await response.json()) as ModuleProps;

        // module messages are attached to global intl context
        // summary contains no statemachine translations!
        appendModuleMessages(data.translations[0].metadatatranslation);

        return data;
      } else {
        return {};
      }
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    module: data as ModuleProps,
    refetch,
  };
};
