import React, { useContext, useState } from 'react';
import { defineMessages, IntlContext } from 'react-intl';
import { Button } from '@mui/material';

import { RoleEnum } from '../../../account';

import { ShareLinkDialog } from './ShareLink';

const receptionPageMessages = defineMessages({
  createNewInvitation: {
    id: 'builder.reception.modules.createInvitation',
    defaultMessage: 'Einladung erstellen',
  },
});

interface CreateInvitationButtonProps {
  modules: any;
  disabled?: boolean;
  preSelectedModules?: string[];
}

export const CreateInvitationButton: React.FC<CreateInvitationButtonProps> = ({
  modules,
  disabled = false,
  preSelectedModules,
}) => {
  const intl = useContext(IntlContext);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  return (
    <span>
      <span style={{ marginLeft: '10px' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setDialogIsOpen(true);
          }}
          disabled={disabled}
        >
          {intl.formatMessage(receptionPageMessages.createNewInvitation)}
        </Button>
      </span>
      <ShareLinkDialog
        open={dialogIsOpen}
        onCancel={() => {
          setDialogIsOpen(false);
        }}
        onCreate={() => {
          setDialogIsOpen(false);
          // TODO update list of invitations if existing
        }}
        modules={
          preSelectedModules
            ? modules
                .filter((module: any) =>
                  preSelectedModules.includes(module.moduleid)
                )
                .map((module: any) => {
                  return {
                    ...module,
                    modulename: intl.formatMessage({ id: module.modulename }),
                  };
                })
            : modules
        }
        forCoach={RoleEnum.COACHEE}
      />
    </span>
  );
};
