import { EvoachButton } from '@evoach/ui-components';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { Asset } from '../../../entities';
import { WindowContext } from '../../window/WindowContext';

import { Dropzone } from './Dropzone';

interface AssetUploadDialogProps {
  /**
   * Function returns new asset from database.
   * Handler signature should be something like (asset: Asset) => {}
   */
  onUpload?: Function;
  isOpen?: boolean;
  onClose: Function;
  overwriteDefault?: boolean;
}

export const AssetUploadDialog: React.FC<AssetUploadDialogProps> = ({
  onUpload,
  isOpen = false,
  onClose,
  overwriteDefault = false,
}) => {
  const intl = useIntl();

  const [overWriteExistingAsset, setOverWriteExistingAsset] =
    useState<boolean>(overwriteDefault);

  const { alert } = useContext(WindowContext);

  const onLocalUpdate = (asset: Asset | number | undefined) => {
    const genericErrorMessage = intl.formatMessage({
      id: 'builder.reception.assets.uploaddialog.genericerror',
      defaultMessage:
        'Beim Hochladen deiner Datei ist ein Fehler aufgetreten. Bitte versuche es noch einmal.',
    });

    if (asset === undefined) {
      alert(genericErrorMessage);
    } else {
      if (typeof asset === 'number') {
        switch (asset) {
          case 400: // file already exists
            alert(
              intl.formatMessage({
                id: 'builder.reception.assets.uploaddialog.fileexistserror',
                defaultMessage:
                  'Deine Datei existiert schon im System. Wenn du sie überschreiben möchtest, setze bitte die entsprechende Option im Upload Dialog.',
              })
            );
            break;
          case -1: // all files rejected
            alert(
              intl.formatMessage({
                id: 'builder.reception.assets.uploaddialog.allrejectedfiles',
                defaultMessage:
                  'Alle Dateien haben Formate, die wir nicht unterstützen. Die Dateien werden nicht hochgeladen.',
              })
            );
            break;

          case -2: //  some files rejected files
            alert(
              intl.formatMessage({
                id: 'builder.reception.assets.uploaddialog.somerejectedfiles',
                defaultMessage:
                  'Einige der Dateien haben ein Format, das wir nicht unterstützen. Diese Dateien werden nicht hochgeladen, Dateien in unterstützen Formaten werden hochgeladen.',
              })
            );
            break;
          default:
            alert(genericErrorMessage);
        }
      } else {
        if (onUpload) {
          onUpload(asset);
        }
      }
    }
    setOverWriteExistingAsset(false);
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({
          id: 'builder.reception.assets.uploaddialog.title',
          defaultMessage: 'Datei hochladen',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Dropzone
            overWriteExistingAsset={overWriteExistingAsset}
            onUpdate={onLocalUpdate}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={overWriteExistingAsset}
                  onChange={() =>
                    setOverWriteExistingAsset(!overWriteExistingAsset)
                  }
                />
              }
              label={intl.formatMessage({
                id: 'builder.reception.assets.uploaddialog.overwritefile',
                defaultMessage: 'Existierende Dateien überschreiben',
              })}
            />
          </FormGroup>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EvoachButton
          onClick={() => {
            setOverWriteExistingAsset(false);
            onClose();
          }}
          autoFocus
        >
          {intl.formatMessage({
            id: 'builder.reception.assets.uploaddialog.button.close',
            defaultMessage: 'Schließen',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
