import { Edge } from 'reactflow';

import { InvokeStateProps, State, StateAlwaysProps } from '../nodes/base.state';

/**
 * Generate helper states for "Start new session" component
 *
 * s. also https://xstate.js.org/docs/guides/communication.html#invoking-promises
 *
 * Original States:
 *
 * "nodeid" => the start new session button
 * "yesnext" => the node that follows nodeid if session should be started
 * "nonext" => the node that follows nodeid if only permission is grantd
 *
 * Helper States:
 *
 * setGrantPermissionAndSession - create a permission and proceed to create session
 * setGrantPermission - create a permission and proceed with "nonext"
 * setCreateSession - create a new session and proceed with redirectToSession
 *
 * Helper Actions in Player (!) :
 *
 * redirectToSession - redirect to session in player and proceed with "yesnext"
 * renderCreateSessionError - display error after "setCreateSession"
 * renderGrantPermissionError - display error after "setGrantPermissionAndSession"
 *      or "setGrantPermission" if permission grant fails
 *
 * @param {string} nodeId is the id of the"Start New Session" node
 * @param {string} yesTarget is the id of the target after "yes"
 * @param {string} noTarget is the id of the target after "no"
 * @returns {InvokeStateProps[]} a list of new states
 */
export const getHelperStatesForNewSession = (
  nodeId: string,
  yesTarget: string,
  noTarget: string
): InvokeStateProps[] => {
  const helperStates: InvokeStateProps[] = [];

  // _setGrantPermissionAndSession
  //
  // Node for granting the permission before starting a session
  // proceed to start session (s. below)
  // The src "setGrantPermission" is managed as a so called xState service
  // in SessionPlayer. It contains a Promise of the backend call.
  // The onDone hook is trigegred, when the Promise resolves, the onError
  // hook is triggered, if the Promise rejects.
  //
  // This node uses the same src/Promise like _setGrantPermission but
  // it differs in the onDone and onError contents.
  //
  const grantPermSess: InvokeStateProps = {
    invoke: {
      id: nodeId + '_setGrantPermissionAndSession',
      src: 'setGrantPermission',
      onDone: {
        target: nodeId + '_setCreateSession',
      },
      onError: {
        target: yesTarget,
        actions: 'renderGrantPermissionError',
      },
    },
    stateKey: nodeId + '_setGrantPermissionAndSession',
  };
  helperStates.push(grantPermSess);

  // _setCreateSession
  //
  // start a session and proceed with the redirectToSession action
  // in SessionPlayer.
  // The src "setCreateSession" is managed as a so called xState service
  // in SessionPlayer. It contains a Promise of the backend call.
  // The onDone hook is trigegred, when the Promise resolves, the onError
  // hook is triggered, if the Promise rejects.
  //
  const createSessionState: InvokeStateProps = {
    invoke: {
      id: nodeId + '_setCreateSession',
      src: 'setCreateSession',
      onDone: {
        target: yesTarget,
        actions: 'redirectToSession',
      },
      onError: {
        target: yesTarget,
        actions: 'renderCreateSessionError',
      },
    },
    stateKey: nodeId + '_setCreateSession',
  };
  helperStates.push(createSessionState);

  // _setGrantPermission
  //
  // Grant permission to a module and proceed in state machine.
  // s. also _setGrantPermissionAndSession service above
  //
  const permOnlyState: InvokeStateProps = {
    invoke: {
      id: nodeId + '_setGrantPermission',
      src: 'setGrantPermission',
      onDone: {
        target: noTarget,
      },
      onError: {
        target: noTarget,
        actions: 'renderGrantPermissionError',
      },
    },
    stateKey: nodeId + '_setGrantPermission',
  };
  helperStates.push(permOnlyState);

  return helperStates;
};

/**
 * Generate helper states for "Paraphrase (AI)" component
 *
 * s. also https://xstate.js.org/docs/guides/communication.html#invoking-promises
 *
 * @param {string} nodeId is the id of the node
 * @param {string} targetNodeId target ode id as defined in statemachine
 * @returns {(InvokeStateProps | State)[]} a list of new states
 */
export const getHelperStatesForExternalCallSingleOut = (
  nodeId: string,
  targetNodeId: string
): (InvokeStateProps | State)[] => {
  const helperStates: (InvokeStateProps | State)[] = [];

  //
  // _initialExternalCall
  //
  const initialExternalCall: InvokeStateProps = {
    invoke: {
      id: nodeId + '_initialExternalCall',
      src: 'callInitialExternalUrl', // action in player!
      onDone: {
        target: nodeId + '_waitForPolling', // next state
        actions: 'saveTaskId',
      },
      onError: {
        target: targetNodeId,
      },
    },
    stateKey: nodeId + '_initialExternalCall',
  };
  helperStates.push(initialExternalCall);

  //
  // _waitForPolling
  //

  const waitforpollstate: State = {
    entry: [],
    after: [
      {
        delay: 3000, // polling interval for external calls, e.g., for Paraphrase
        target: nodeId + '_pollExternalCall',
      },
    ],
    stateKey: nodeId + '_waitForPolling',
  };
  helperStates.push(waitforpollstate);

  //
  // _initialExternalCall
  //
  // TODO check polling counter and exit if polling counter is > 6 (=1 min)?
  // TODO may be implemented with special state and guards
  const pollExternalCall: InvokeStateProps = {
    invoke: {
      id: nodeId + '_pollExternalCall',
      src: 'callPollExternalUrl', // action in player!
      onDone: {
        target: targetNodeId, // next state
        actions: 'savePollingResult', // action in player
      },
      onError: {
        target: nodeId + '_waitForPolling', // try again
        actions: 'increasePollingCounter',
      },
    },
    stateKey: nodeId + '_pollExternalCall',
  };
  helperStates.push(pollExternalCall);

  return helperStates;
};

/**
 * Generate helper states for "Topic Classification (AI)" component
 *
 * s. also https://xstate.js.org/docs/guides/communication.html#invoking-promises
 *
 * @param {string} nodeId is the id of the node
 * @param {string} targetNodeId target ode id as defined in statemachine
 * @returns {(InvokeStateProps | State)[]} a list of new states
 */
export const getHelperStatesForExternalCallMultipleOut = (
  nodeId: string,
  targetNodeId: string,
  keyTexts: string[],
  outgoingEdges: Edge<any>[],
  nodeDataType: string
): (InvokeStateProps | State)[] => {
  const helperStates: (InvokeStateProps | State)[] = [];

  //
  // _initialExternalCall
  //
  const initialExternalCall: InvokeStateProps = {
    invoke: {
      id: nodeId + '_initialExternalCall',
      src: 'callInitialExternalUrl', // action in player!
      onDone: {
        target: nodeId + '_waitForPolling', // next state
        actions: 'saveTaskId',
      },
      onError: {
        target: targetNodeId,
      },
    },
    stateKey: nodeId + '_initialExternalCall',
  };
  helperStates.push(initialExternalCall);

  //
  // _waitForPolling
  //

  const waitforpollstate: State = {
    entry: [],
    after: [
      {
        delay: 1000, // polling interval for external calls, here: classification which is fast
        target: nodeId + '_pollExternalCall',
      },
    ],
    stateKey: nodeId + '_waitForPolling',
  };
  helperStates.push(waitforpollstate);

  //
  // _initialExternalCall
  //
  // TODO check polling counter and exit if polling counter is > 6 (=1 min)?
  // TODO may be implemented with special state and guards
  const pollExternalCall: InvokeStateProps = {
    invoke: {
      id: nodeId + '_pollExternalCall',
      src: 'callPollExternalUrl', // action in player!
      onDone: {
        target: nodeId + '_branchWithGuards', // next state
        actions: 'savePollingResult', // action in player
      },
      onError: {
        target: nodeId + '_waitForPolling', // try again
        actions: 'increasePollingCounter',
      },
    },
    stateKey: nodeId + '_pollExternalCall',
  };
  helperStates.push(pollExternalCall);

  const alwaysGuards: StateAlwaysProps[] = [];

  //
  // for each key text (= name of out handle) find the id of the
  // target node to be reached behind that handle. Add the correct
  // guard to that transsition.

  if (
    nodeDataType === 'aiClassificationNodeStateEntry' ||
    nodeDataType === 'aiGenericClassificationStateEntry'
  ) {
    //
    // Guards are numbered by index: they are evaluated in Player
    //

    keyTexts.forEach((key: string, index: number) => {
      const targetEdge = outgoingEdges
        .filter((edge: Edge<any>) => edge.sourceHandle === key)
        .map((edge: Edge<any>) => edge.target);

      if (targetEdge.length > 0) {
        alwaysGuards.push({
          cond: 'classificationBranchGuard' + index,
          target: targetEdge[0],
        });
      } else {
        console.error(
          `could not add "always" transition for guard with key ${key}`
        );
      }
    });
  }

  if (nodeDataType === 'aiSentimentNodeStateEntry') {
    //
    // Guards are explicitely named in Player for specific sentiment
    //

    let targetEdge = outgoingEdges
      .filter(
        (edge: Edge<any>) => edge.sourceHandle === 'positivesentimenthandle'
      )
      .map((edge: Edge<any>) => edge.target);

    if (targetEdge.length > 0) {
      alwaysGuards.push({
        cond: 'sentimentPositiveGuard',
        target: targetEdge[0],
      });
    } else {
      console.error(
        `could not add "always" transition for positive sentiment guard`
      );
    }

    targetEdge = outgoingEdges
      .filter(
        (edge: Edge<any>) => edge.sourceHandle === 'neutralsentimenthandle'
      )
      .map((edge: Edge<any>) => edge.target);

    if (targetEdge.length > 0) {
      alwaysGuards.push({
        cond: 'sentimentNeutralGuard',
        target: targetEdge[0],
      });
    } else {
      console.error(
        `could not add "always" transition for neutral sentiment guard`
      );
    }

    targetEdge = outgoingEdges
      .filter(
        (edge: Edge<any>) => edge.sourceHandle === 'negativesentimenthandle'
      )
      .map((edge: Edge<any>) => edge.target);

    if (targetEdge.length > 0) {
      alwaysGuards.push({
        cond: 'sentimentNegativeGuard',
        target: targetEdge[0],
      });
    } else {
      console.error(
        `could not add "always" transition for negative sentiment guard`
      );
    }
  }

  const branchWithGuards: State = {
    entry: [],
    always: alwaysGuards,
    stateKey: nodeId + '_branchWithGuards',
  };
  helperStates.push(branchWithGuards);

  //
  // ! the following example applies to the aiClassificationNodeStateEntry only
  // we now have to match the keys of the out-handles of the nodes
  // to the targets of the edges.
  //
  /*
    These are examples of the keyTexts array with corresponding outgoing
    Edges from a sample node:

    Key Texts

    [
    "keytext.wdly",
    "keytext.oa9r",
    "keytext.ppiv",
    "keytext.28wh",
    "keytext.wvxb"
    ]

    correspondig edges

    [
    {
        "source": "wam1mjm3",
        "sourceHandle": "keytext.wdly",
        "target": "468pdodl",
        "targetHandle": "messageinhandle",
        "markerEnd": "arrowclosed",
        "type": "buttonedge",
        "id": "reactflow__edge-wam1mjm3keytext.wdly-468pdodlmessageinhandle"
    },
    {
        "source": "wam1mjm3",
        "sourceHandle": "keytext.oa9r",
        "target": "rxbiycwg",
        "targetHandle": "messageinhandle",
        "markerEnd": "arrowclosed",
        "type": "buttonedge",
        "id": "reactflow__edge-wam1mjm3keytext.oa9r-rxbiycwgmessageinhandle"
    },
    {
        "source": "wam1mjm3",
        "sourceHandle": "keytext.ppiv",
        "target": "ssitm6zg",
        "targetHandle": "messageinhandle",
        "markerEnd": "arrowclosed",
        "type": "buttonedge",
        "id": "reactflow__edge-wam1mjm3keytext.ppiv-ssitm6zgmessageinhandle"
    },
    {
        "source": "wam1mjm3",
        "sourceHandle": "keytext.28wh",
        "target": "o51fmipn",
        "targetHandle": "messageinhandle",
        "markerEnd": "arrowclosed",
        "type": "buttonedge",
        "id": "reactflow__edge-wam1mjm3keytext.28wh-o51fmipnmessageinhandle"
    },
    {
        "source": "wam1mjm3",
        "sourceHandle": "keytext.wvxb",
        "target": "aelj2p4c",
        "targetHandle": "messageinhandle",
        "markerEnd": "arrowclosed",
        "type": "buttonedge",
        "id": "reactflow__edge-wam1mjm3keytext.wvxb-aelj2p4cmessageinhandle"
    }
]
  */

  return helperStates;
};

/**
 * Generate helper states for "Micro-Chat (AI)" component
 *
 * s. also https://xstate.js.org/docs/guides/communication.html#invoking-promises
 *
 * @param {string} nodeId is the id of the node
 * @param {string} targetNodeId target ode id as defined in statemachine
 * @returns {(InvokeStateProps | State)[]} a list of new states
 */
export const getHelperStatesForMicroChat = (
  nodeId: string,
  targetNodeId: string
): (InvokeStateProps | State)[] => {
  const helperStates: (InvokeStateProps | State)[] = [];

  //
  // !stateKey ==> _nextInputMicrochat
  //
  // do not use messageInput as type => we need a special render function because
  // because we have to translate things in session (like placeholder texts)
  // and this can't be done in this abstract state machine definition
  //

  const nextInput: State = {
    entry: [
      {
        type: 'renderExternalMicrochatInput',
        temporary: true,
        payload: {
          placeholderText: undefined,
          rowCount: 3,
          milliSeconds: 0,
          saveResultTo: 'evoach.microchat.userinput',
          emoticons: true,
          getValueFrom: undefined,
        },
        nodeType: 'messageInputStateEntry',
      },
    ],
    on: {
      next: nodeId + '_initialMicrochatExternalCall',
      writeToContext: {
        actions: 'writeToContext',
      },
    },
    exit: [
      {
        type: 'renderCoacheeMessage',
        temporary: false,
        payload: {
          getValueFrom: 'evoach.microchat.userinput',
        },
      },
      {
        type: 'renderThreeDots',
        temporary: true,
        payload: {},
      },
    ],
    stateKey: nodeId + '_nextInputMicrochat',
  };
  helperStates.push(nextInput);

  //
  // !stateKey ==> _initialMicrochatExternalCall
  //
  const initialExternalCall: InvokeStateProps = {
    invoke: {
      id: nodeId + '_initialMicrochatExternalCall',
      src: 'callInitialMicrochatExternalUrl', // action in player!
      onDone: {
        target: nodeId + '_waitForMicrochatPolling', // next state
        actions: 'saveMicrochatTaskId', // player
      },
      onError: {
        target: targetNodeId,
      },
    },
    stateKey: nodeId + '_initialMicrochatExternalCall',
  };
  helperStates.push(initialExternalCall);

  //
  // !stateKey ==> _waitForMicrochatPolling
  //

  const waitforpollstate: State = {
    entry: [],
    after: [
      {
        delay: 3000, // polling interval for next conversation turn
        target: nodeId + '_pollExternalMicrochatCall',
      },
    ],
    stateKey: nodeId + '_waitForMicrochatPolling',
  };
  helperStates.push(waitforpollstate);

  //
  // !stateKey ==> _pollExternalMicrochatCall
  //
  // TODO check polling counter and exit if polling counter is > 6 (=1 min)?
  // TODO may be implemented with special state and guards
  const pollExternalCall: InvokeStateProps = {
    invoke: {
      id: nodeId + '_pollExternalMicrochatCall',
      src: 'callPollMicrochatExternalUrl', // action in player!
      onDone: {
        target: nodeId + '_printAssistantOutput', // next state => print result
        actions: 'saveMicrochatPollingResult', // save result (player)
      },
      onError: {
        target: nodeId + '_waitForMicrochatPolling', // try again
        actions: 'increaseMicrochatPollingCounter', // increase counter (player)
      },
    },
    stateKey: nodeId + '_pollExternalMicrochatCall',
  };
  helperStates.push(pollExternalCall);

  //
  // !stateKey ==> _printAssistantOutput
  //
  // do not use renderCoachMessage but renderExternalCoachMessage
  // as we have to replace FINISH_MARKER in client before printing.
  // Furthermore, helperText may be translateable later
  //
  const assistantOutput: State = {
    entry: [
      {
        type: 'renderExternalCoachMessage',
        temporary: false,
        payload: {
          helperText: 'AI', // TODO translateable, pass transl. key here, will be traslated in player
        },
        nodeType: 'coachMessageStateEntry',
        handleOutCount: 1,
      },
    ],
    after: {
      delay: 1000, // wait 1 second and then go on with chat
      target: nodeId + '__checkMicrochatEndCheck',
    },
    exit: [],
    stateKey: nodeId + '_printAssistantOutput',
  };
  helperStates.push(assistantOutput);

  //
  // !stateKey ==> __checkMicrochatEndCheck
  //
  const checkMicrochatEndGuards: StateAlwaysProps[] = [];

  // if result contains "FINISH" marker, quit microchat
  checkMicrochatEndGuards.push({
    cond: 'microchatFinishMarkerGuard',
    target: targetNodeId,
  });

  // if max number of conversational turns reached, quit microchat
  checkMicrochatEndGuards.push({
    cond: 'microchatMaxTurnsGuard',
    target: targetNodeId,
  });

  // if none of the previous guards is triggered, go to next input and
  // proceed with chat
  checkMicrochatEndGuards.push({
    cond: 'microchatNextTurnGuard',
    target: nodeId + '_nextInputMicrochat',
  });

  const checkMicrochatEnd: State = {
    entry: [],
    always: checkMicrochatEndGuards,
    stateKey: nodeId + '__checkMicrochatEndCheck',
  };
  helperStates.push(checkMicrochatEnd);

  return helperStates;
};
