import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { calculateHandlePosition, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';
/*
  AI Classification Node 
*/

export const AiClassificationNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;

  // TODO this is to read from a future payload parameter
  const classDescription = [
    'Work/Life-Balance',
    'Karriere',
    'Grenzen setzen',
    'Kommunikationsfähigkeiten',
    'Konflikt',
  ];

  const sourceHandles = payload.keyTexts?.map(
    (keyText: string, index: number) => (
      <Handle
        id={keyText}
        key={keyText + index}
        className={classes['react-flow__handle']}
        type="source"
        position={Position.Bottom}
        style={{
          left: calculateHandlePosition(payload.keyTexts?.length ?? 1, index),
        }}
      />
    )
  );
  return (
    <>
      <Handle
        type="target"
        id={nodeProps.data.state.entry[0].nodeType + 'in'}
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{
          width: '400px',
          background: nodeProps.data.state.entry[0].nodeMiniMapColor,
        }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {nodeType &&
            intl.formatMessage({
              id: nodeType,
            })}
          <br />
          &nbsp;
          <br />
          {intl.formatMessage(
            {
              id: 'builder.nodes.aiclassification.selection.in',
              defaultMessage:
                'Die Inhalte dieser Variable werden klassifiziert: {invar}',
            },
            {
              invar: <b>{payload.getValueFrom ?? '- -'}</b>,
            }
          )}
          <br />
          &nbsp;
          <br />
          {intl.formatMessage(
            {
              id: 'builder.nodes.aiclassification.classes',
              defaultMessage:
                'Die Ausgänge werden von links nach rechts für folgende Klassifikation verwendet: {classes}',
            },
            {
              classes: <b>{classDescription.join(', ')}</b>,
            }
          )}
        </Typography>
      </div>
      {sourceHandles}
    </>
  );
};
