import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from '@mui/material';

import { LabelEdit } from '../analyticsevoach/LabelEdit';
import { ProgramProps } from '../../../entities';

interface ProgramLabelDialogProps {
  initialLabels?: string[];
  program: ProgramProps;
  isOpen: boolean;
  onClose: Function;
  onUpdate: Function;
}

export const ProgramLabelDialog: React.FC<ProgramLabelDialogProps> = ({
  initialLabels = [],
  isOpen,
  onClose,
  onUpdate,
  program,
}) => {
  const intl = useIntl();
  const [labels, setLabels] = useState<string[]>(initialLabels);

  if (!isOpen) {
    return null;
  }

  const updateLabels = async () => {
    program.labels = labels;

    if (onUpdate) onUpdate(labels);
  };

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.programlabeldialog.title',
          defaultMessage: 'Programm Labels zuweisen',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" component="span">
          {intl.formatMessage({
            id: 'builder.programlabeldialog.content',
            defaultMessage:
              'Hier kannst du deinem Programm Labels zuweisen oder die Labels löschen. Die Labels sind nicht für den Coachee sichtbar und werden zur Zeit auch noch nicht im evoach System verwendet. Sie können für zukünftige Funktionen Verwendung finden (etwa: finde ähnliche Programme). Tipp einfach drauflos und mit der Return-Taste kannst du ein neues Label hinzufügen.',
          })}
          <LabelEdit
            initialLabels={initialLabels}
            sx={{ marginTop: '10px' }}
            noLabelsText={intl.formatMessage({
              id: 'builder.programlabeldialog.nolabels',
              defaultMessage: 'Es sind noch keine Labels vergeben',
            })}
            onUpdate={(labels: string[]) => setLabels(labels)}
            onClose={() => updateLabels()}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {intl.formatMessage({
            id: 'builder.programlabeldialog.button.close',
            defaultMessage: 'Abbrechen',
          })}
        </Button>
        <Button color="secondary" onClick={updateLabels}>
          {intl.formatMessage({
            id: 'builder.programlabeldialog.button.save',
            defaultMessage: 'Übernehmen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
