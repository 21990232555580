import React from 'react';
import { Box, Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import {
  isGetValueFromKey,
  StateEntryProps,
  isSaveResultToKey,
  isGetTopicsValuesFromKey,
  linkButtonStateEntry,
} from '../../nodes';
import { ElementPropertyPayloadSelect } from '../ElementPropertyPayloadSelect';
import { ElementPropertyPayloadTextfield } from '../ElementPropertyPayloadTextfield';

interface ValuesInputProps {
  entry: StateEntryProps;
  elementId: string;
  updatePayloadValue: Function;
}

// select only properties that are editable. For instance, this list enables us
// to add information to a node in the builder that is read from a variable
// via getValueFrom when rendered in plyer

const ignoreList = [
  'prompt',
  'scaleSize',
  'keyTexts',
  'radioTexts',
  'placeholderTexts',
  'series',
  'getValuesFrom',
  'getStringValues', // used in MultiplePercentage PROD-1393
  'scaleNames',
  'labels',
  'phase',
  'elements',
  'time', // TODO time input is default value ==> provide control to set default
  'date', // TODO date input is default value ==> provide control to set default
  'buttonTexts', // plural ! => MultiButton
  'predefinedEmotionsIndex', // EmotionsInput
  'predefinedNeedsIndex', // NeedsInput
  'inLoop', // loop names for entries are hidden and autogenerated elements
  'getLabelsFrom', // hidden value for auto-generating values in PolarChart (labels)
  'getSeriesFrom', // hidden value for auto-generating values in PolarChart (series)
  'messages', // value for random coach message
  'radarLabels', // value for random coach message
  'ownElements', // used in EmotionInput
  'cards', // used in HotOrNotSelector
  'firstValueToCompare', // used in CompareNumbers
  'secondValueToCompare', // used in CompareNumbers
  'moduleId', // used in Start New Session node
  'moduleTitleKey', // used in Start New Session node
  'keyNumbers', // used in SelectionCard // PROD-1406
  'images', // used in ImageSelector // PROD-1067
  'icsItems', // used in CalenderExport // PROD-1682
  'assetid', // now handled in AssetSelectInput // PROD-1476
  'componentSize', // now handled in SizeInput // PROD-1067
  'navigateTarget', // PROD-1677
  'promptType', // OpenAI related services
  'programOrModuleId', // certificate creation
  'programId', // certificate creation
  'templateId', // id of printing template to be used in a component
  'classificationKeys', // used for AI classification list,
  //'maxTurns', // used in AI Micro Chat
  'assetids', // used in AI Document Chat ==> DocumentSelect
  'getPersonaFrom', // used in AI persona definition in backend
];

const isEditablePayloadEntry = (payloadKey: string): boolean =>
  !isSaveResultToKey(payloadKey) &&
  !isGetValueFromKey(payloadKey) &&
  !isGetTopicsValuesFromKey(payloadKey) &&
  !ignoreList.includes(payloadKey);

// payloadKey !== 'src'; // now handled in AssetSelectInput // PROD-1476

const isEditablePayloadSelect = (payloadKey: string): boolean =>
  !isSaveResultToKey(payloadKey) &&
  !isGetValueFromKey(payloadKey) &&
  (payloadKey === 'bubbleType' ||
    payloadKey === 'audioImage' ||
    payloadKey === 'formatType' ||
    payloadKey === 'externalService');

//
// filter the message prop if it is a coach message
// if the message is replaced (!) by a variable, we do not display the message entry
//
export const doNotDisplayMessage = (entry: StateEntryProps) => {
  if (entry.nodeType === 'coachMessageStateEntry') {
    if (
      entry.payload.getValuesFrom !== undefined &&
      entry.payload.getValuesFrom.length > 0
    ) {
      return (
        entry.payload.getValuesFrom.filter((value) => value !== '').length > 0
      );
    }
    if (entry.payload.getValueFrom && entry.payload.getValueFrom.length > 0) {
      return true;
    }
  }
  return false;
};

/** value input for node properties
 * all properties in payload are provided as editiable controls (except variables)
 * we distinguish between value inputs for textfield and select list (enums).
 * enums are currently only available for bubbleType in MultipleDisplay
 * @param {StateEntryProps} entry describes the complete state entry with all properties
 * @param {string} elementId used for key generation only (makes property input unique)
 * @param {Function} updatePayloadValue handler to manage any change to property value
 */
export const ValuesInput: React.FC<ValuesInputProps> = ({
  entry,
  elementId,
  updatePayloadValue,
}) => {
  const intl = useIntl();

  return (
    <Stack spacing={1}>
      {Object.keys(entry.payload)
        .filter(isEditablePayloadEntry)
        .filter((payloadKey) => typeof entry.payload[payloadKey] !== 'boolean')
        .map((payloadKey) => (
          <Box key={`ElementIdAndKey-${elementId}-${payloadKey}`}>
            {((!isEditablePayloadSelect(payloadKey) && payloadKey !== 'src') ||
              (payloadKey === 'src' &&
                entry.nodeType === linkButtonStateEntry.nodeType)) &&
              !(payloadKey === 'message' && doNotDisplayMessage(entry)) && (
                <ElementPropertyPayloadTextfield
                  payloadKey={payloadKey}
                  entry={entry}
                  updatePayloadValue={updatePayloadValue}
                />
              )}
            {isEditablePayloadSelect(payloadKey) && (
              <ElementPropertyPayloadSelect
                payloadKey={payloadKey}
                entry={entry}
                updatePayloadValue={updatePayloadValue}
              />
            )}
            {payloadKey === 'message' && doNotDisplayMessage(entry) && (
              <>
                {intl.formatMessage({
                  id: 'builder.valuesinput.nomessage',
                  defaultMessage:
                    'Deine Nachricht wird aus dem Inhalt einer oder mehrerer Variablen erzeugt. Daher kannst du die Nachricht nicht editieren. Wenn keine Variablen ausgewählt sind, dann wird das Feld zum Editieren der Nachricht wieder angezeigt.',
                })}
              </>
            )}
          </Box>
        ))}
    </Stack>
  );
};
