import {
  ComponentSize,
  generateRandomString,
  ImageItem,
} from '@evoach/ui-components';
import { Box, Button, Stack } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Edge, Node } from 'reactflow';

import { AccountContext, RoleEnum } from '../../account';
import {
  getExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';
import { DefaultPrintTemplate } from '../../entities/PrintTemplatesListTypes';
import { TranslationContext as GlobalTranslationContext } from '../../intl/TranslationContext';
import { autoSave } from '../ModuleEditor/autosave';
import { ModuleEditorContext } from '../ModuleEditor/ModuleEditorContext';
import {
  findLoopEndNode,
  findNodeByGetValueFrom,
} from '../ModuleEditor/moduleHelper';
import {
  aiMicrochatAllowedPromptTypes,
  aiMicrochatMaxTurns,
  EmotionProps,
  emotionsInputStateEntry,
  hotOrNotSelectorStateEntry,
  isAiDocumentChatEntry,
  isAiGenericClassificationEntry,
  isAiListPromptEntry,
  isAiMicrochatEntry,
  isAssetStateEntry,
  isCompareMultipleNumbersStateEntry,
  isCreateCertificateStateEntry,
  isEditableMultiEntry,
  isEditableMultiselect,
  isEmotionInputEntry,
  isHotOrNotEntry,
  isImageSelectorEntry,
  isMultiButtonEntry,
  isMultipleInputEntry,
  isMultiplePercentageScaleEntry,
  isNeedsInputEntry,
  isPolarChartEntry,
  isRadarChartEntry,
  isRadioButtonEntry,
  isRandomCoachMessageEntry,
  isScaleInputEntry,
  isScaleInputMultiEntry,
  isSelectionCardEntry,
  isSingleAnswerPromptEntry,
  isStartNewSessionEntry,
  isTranslatedStatePayloadProp,
  isVariableDefineStringArrayNodeStateEntry,
  listPromptAllowedPromptTypes,
  NavigateTargetEnum,
  needsInputStateEntry,
  NodeDataProps,
  predefinedEmotionsSet,
  predefinedHotOrNotLists,
  predefinedNeedsSet,
  singleAnswerPromptAllowedPromptTypes,
  StateEntryProps,
  StatePayloadProps,
} from '../nodes';
import { PrintTemplateSelection } from '../reception/PrintTemplates/PrintTemplateSelection';
import { TranslationContext } from '../stateMachineTranslationContext';

import { NodeDebugOutput } from './NodeDebugOutput';
import {
  getDuplicateVariableNodesCount,
  isDuplicateVariable,
  updateVariablesInNodes,
  updateVariablesInTranslations,
} from './saveResultToHelper';
import {
  EditableMultiselectElementsInput,
  EmotionInputInput,
  GetValueFrom,
  HotOrNotInput,
  NeedsInputInput,
  OptionsInput,
  PlaceholderTextsInput,
  PolarChartInput,
  PropertyHeaderLine,
  RadarChartInput,
  ScaleSizeInput,
  ValuesInput,
  VariableEdit,
} from './VariableInputs';
import { AssetSelectInput } from './VariableInputs/AssetSelectInput';
import { GetStringValueFrom } from './VariableInputs/GetStringValueFrom';
import { GetTopicsValueFrom } from './VariableInputs/GetTopicsValueFrom';
import { ImageSelectorInput } from './VariableInputs/ImageSelectorInput';
import { ModuleSelect } from './VariableInputs/ModuleSelect';
import { MultiplePercentageScaleInputInput } from './VariableInputs/MultiplePercentageScaleInputInput';
import { MultiValueInput } from './VariableInputs/MultiValueInput';
import { NavigationTargetInput } from './VariableInputs/NavigationTargetInput';
import { PromptSelect } from './VariableInputs/PromptSelect';
import { SizeInput } from './VariableInputs/SizeInput';
import { VarNameChangeWarning } from './VarNameChangeWarning';
import DocumentSelect from './VariableInputs/DocumentSelect';
import { GetPersonaFrom } from './VariableInputs/GetPersonaFrom';
import { AnalyticsDisplay } from './AnalyticsDisplay';

// interface of updateElements is actually defined in ModuleBuilder and
// the function declaration of updateElements. We could also write
// updateElements: Function here, but this syntax gives us a nice mouse
// over hint what the function signature is.
export interface ElementPropertyListProps {
  nodeData: NodeDataProps;
  elementId: string;
}

//
// EntryLists component - displays all properties of all selected objects
//
export const EntryLists: React.FC<ElementPropertyListProps> = ({
  nodeData,
  elementId,
}) => {
  const intl = useIntl();

  const {
    nodes,
    edges,
    updateElements,
    moduleLoadedInDefaultLanguage,
    moduleid,
  } = useContext(ModuleEditorContext);

  const { hasRole, account } = useContext(AccountContext);

  const { addOrUpdateStateMachineTranslation, stateMachineTranslation } =
    React.useContext(TranslationContext);

  const { defaultMessages: globalMessages } = React.useContext(
    GlobalTranslationContext
  );

  /** update standard payload values that do not require special handling */
  const updatePayloadValue = (
    value: string,
    payloadKey: string,
    entry: StateEntryProps
  ) => {
    if (
      isTranslatedStatePayloadProp(
        payloadKey,
        entry.payload[payloadKey] as string
      )
    ) {
      // PROD-1810 - for old elements without assetid and src, there is no key
      // and no translation key => we have to generate it!
      if (entry.payload[payloadKey] === undefined) {
        entry.payload[payloadKey] = generateRandomString(8);
      }

      addOrUpdateStateMachineTranslation(
        entry.payload[payloadKey] as string,
        value
      );
    } else {
      const payload =
        nodeData.state.entry[entry.type === 'setProgressPercent' ? 1 : 0]
          .payload;

      if (payload) {
        if (payloadKey === 'externalService') {
          value = getExternalServiceEnum(value);
        }
        payload[payloadKey] = value;
      }
      console.log(payload);
      addOrUpdateStateMachineTranslation('forcenodererender', Date.now() + '');
    }
    updateElements([...nodes, ...edges], false);
    autoSave();
  };

  const updateSize = (newSize: ComponentSize, entry: StateEntryProps) => {
    const payload =
      nodeData.state.entry[entry.type === 'setProgressPercent' ? 1 : 0].payload;

    if (payload) {
      payload.componentSize = newSize;
    }
    addOrUpdateStateMachineTranslation('forcenodererender', Date.now() + '');
    updateElements([...nodes, ...edges], false);
    autoSave();
  };

  //
  // navigation button jumps to list of modules, etc - if we change the target
  // we also have to translate the name of the button
  //
  const updateNavigateTarget = (
    newTarget: NavigateTargetEnum,
    entry: StateEntryProps
  ) => {
    const payload =
      nodeData.state.entry[entry.type === 'setProgressPercent' ? 1 : 0].payload;

    if (payload) {
      payload.navigateTarget = newTarget;

      // switch also text of button
      switch (newTarget) {
        case NavigateTargetEnum.SESSIONLIST:
          addOrUpdateStateMachineTranslation(
            payload.buttonText + '',
            globalMessages['builder.nodes.navigatebutton.state.tosessionlist']
          );

          break;
        case NavigateTargetEnum.PROGRAMLIST:
          addOrUpdateStateMachineTranslation(
            payload.buttonText + '',
            globalMessages['builder.nodes.navigatebutton.state.tomprogramlist']
          );

          break;
        case NavigateTargetEnum.MODULELIST:
        default:
          addOrUpdateStateMachineTranslation(
            payload.buttonText + '',
            globalMessages['builder.nodes.navigatebutton.state.tomodulelist']
          );
      }
    }

    updateElements([...nodes, ...edges], false);
    autoSave();
  };

  // control elements for the warning dialog if a variable name is changed
  const [varNameChangeWarningIsOpen, setVarNameChangeWarningIsOpen] =
    useState<boolean>(false);
  const [varNameChangeWarningType, setVarNameChangeWarningType] =
    useState<string>('');

  const [saveResultToBeforeChange, setSaveResultToBeforeChange] =
    useState<string>('');

  const updateVariables = (allowDuplicateVarName: boolean) => {
    if (saveResultToBeforeChange.trim() === '') return;

    const oldVarName = saveResultToBeforeChange;
    let newVarName = nodeData.state.entry[0].payload.saveResultTo ?? '';
    let newnodes = nodes;
    let newVarNameRenamed = false;
    let changeTypeWarning = '';

    if (isDuplicateVariable(newVarName, newnodes) && !allowDuplicateVarName) {
      newVarName += '_2';
      nodeData.state.entry[0].payload.saveResultTo = newVarName;
      newVarNameRenamed = true;
    }

    //
    // if variable is not a duplicate, we change the names
    // we check for count and not for isDuplicate as isDuplicate triggers for
    // occurrences >=2. But for oldVarName, the variable was already changed,
    // so an occurence of >=1 indicates that it was a duplicate before change
    //
    if (getDuplicateVariableNodesCount(oldVarName, newnodes) === 0) {
      newnodes = updateVariablesInNodes(oldVarName, newVarName, newnodes);

      updateVariablesInTranslations(
        oldVarName,
        newVarName,
        newnodes,
        stateMachineTranslation,
        addOrUpdateStateMachineTranslation
      );

      changeTypeWarning = newVarNameRenamed ? 'renamedandupdated' : 'updated';
    } else {
      if (nodeData.nodeType === 'loopStartStateEntry') {
        changeTypeWarning = 'duplicatebutloop';
      } else {
        changeTypeWarning = 'duplicate';
      }
    }
    // inloop values have always to be changed, also if there are duplicates
    // otherwise we run into inconsistencies

    // if we changed the name of the loop (in a loop start node), we have
    // to chnage the loopName in the corresponding loop end node. We also
    // have to change all generated variables and the inLoop props of all
    // nodes within the loop.
    if (nodeData.nodeType === 'loopStartStateEntry') {
      const loopStartNode = newnodes.filter(
        (node: Node<any>) =>
          node.data.state.stateKey === nodeData.state.stateKey
      )[0];
      const loopEndNode = findLoopEndNode(loopStartNode, newnodes, edges);

      // PROD-1304
      // adapt existing inLoop props in order to calculate correct new var
      // names, e.g. in getValueFromSelect.tsx
      // oldVarName contains name of loop var before change
      newnodes = newnodes.map((node: Node<any>) => {
        const payload = node.data.state.entry[0].payload;
        // change inLoop props if available
        if (payload.inLoop !== undefined && payload.inLoop === oldVarName) {
          payload.inLoop = newVarName;
        }
        // change loop name in start node
        if (node.data.state.stateKey === loopStartNode.data.state.stateKey) {
          payload.saveResultTo = newVarName;
        }
        // change loop name in end node
        if (
          loopEndNode &&
          node.data.state.stateKey === loopEndNode.data.state.stateKey
        ) {
          payload.loopName = newVarName;
        }
        return node;
      });
      addOrUpdateStateMachineTranslation('enforceNodeUpdate', Date.now() + '');
    }

    // update elements in state , false = not part of undo operation
    updateElements([...newnodes, ...edges], false);

    setVarNameChangeWarningType(changeTypeWarning);
    setVarNameChangeWarningIsOpen(true);
    setSaveResultToBeforeChange('');

    autoSave();

    return;
  };

  // update payload. is triggered by GetValueFromSelect
  const updateGetValueFrom = (value: string | string[], payloadKey: string) => {
    const stateEntry = nodeData.state.entry[0];
    const payload = stateEntry.payload;
    if (payload) {
      // if nodeData.state.entry[0].payload contains getValuesFrom, take this
      // otherwise getValueFrom

      if (nodeData.state.entry[0].payload.getValuesFrom) {
        payload.getValuesFrom = value as string[];
      } else {
        payload[payloadKey] = value as string;
      }

      // if current element is emo input and getvalue fro is emo input
      // then copy settings of original element to get proper results.
      if (stateEntry.nodeType === 'emotionsInputStateEntry') {
        // check type of getValuesFrom
        const getFromNode = findNodeByGetValueFrom(value, nodes);
        if (getFromNode) {
          const stateEntryFrom = getFromNode.data.state
            .entry[0] as StateEntryProps;
          // if originating node is also emotionsinput, copy values for convenience
          if (
            stateEntryFrom &&
            stateEntryFrom.nodeType === 'emotionsInputStateEntry'
          ) {
            stateEntry.ownElements = stateEntryFrom.ownElements;
            stateEntry.predefinedEmotionsIndex =
              stateEntryFrom.predefinedEmotionsIndex;
            stateEntry.useOwnElements = stateEntryFrom.useOwnElements;
            stateEntry.payload.elements = stateEntryFrom.payload.elements;
          }
        }
      }
    }

    // false => not for undo operation
    updateElements([...nodes, ...edges], false);
  };

  // TODO refactor and combine updateGetStringValueFrom + updateGetTopicsValueFrom + updateGetPersonaFrom
  // update payload for allowed text variables. is triggered by GetValueFromSelect
  const updateGetStringValueFrom = (value: string[]) => {
    const payload = nodeData.state.entry[0].payload;
    if (payload) {
      payload.getStringValues = value;
    }
    // false = get Value From not necessary
    updateElements([...nodes, ...edges], false);
  };

  //
  const updateGetPersonaFrom = (value: string) => {
    const payload = nodeData.state.entry[0].payload;
    if (payload) {
      payload.getPersonaFrom = value;
    }
    // false = get Value From not necessary
    updateElements([...nodes, ...edges], false);
  };

  // update payload for topics in Single Display
  const updateGetTopicsValueFrom = (value: string[]) => {
    const payload = nodeData.state.entry[0].payload;
    if (payload) {
      payload.getTopicsValuesFrom = value;
    }
    // false = get Value From not necessary for undo
    updateElements([...nodes, ...edges], false);
  };

  /** special handler to update polar chart values for auto generation
   * valid for state definitions of v2 of Polar Chart state entry
   */
  const updateRadarChartAutoGeneration = (
    vartype: string,
    varname: string[] | string
  ) => {
    const payload = nodeData.state.entry.find(isRadarChartEntry)?.payload;
    if (payload) {
      if (vartype === 'series' && Array.isArray(varname)) {
        payload.getValuesFrom = varname;
      }
      if (vartype === 'labels' && typeof varname === 'string') {
        payload.getLabelsFrom = varname;
      }
      updateElements([...nodes, ...edges]);
    }
  };

  const updatePolarChartAutoGeneration = (vartype: string, varname: string) => {
    const payload = nodeData.state.entry.find(isPolarChartEntry)?.payload;
    if (payload) {
      if (vartype === 'series') {
        payload.getSeriesFrom = varname;
      }
      if (vartype === 'labels') {
        payload.getLabelsFrom = varname;
      }
      updateElements([...nodes, ...edges]);
    }
  };

  /** special handler to update polar chart values
   * valid for state definitions of v1 of Polar Chart state entry
   */
  const updatePolarChartValues = (
    labels: string[],
    getValuesFrom: string[],
    storeForUndo: boolean = true
  ) => {
    const payload = nodeData.state.entry.find(isPolarChartEntry)?.payload;
    if (payload) {
      payload.labels = labels;
      payload.getValuesFrom = getValuesFrom;
      updateElements([...nodes, ...edges], storeForUndo);
    }
  };

  /** variable definition handler for string arrays */
  const updateStringArray = (
    labels: string[],
    _listkeys: string[] = [],
    storeForUndo: boolean = true
  ) => {
    const payload = nodeData.state.entry.find(
      isVariableDefineStringArrayNodeStateEntry
    )?.payload;
    if (payload) {
      payload.labels = labels;
      updateElements([...nodes, ...edges], storeForUndo);
    }
  };

  /**
   * update values for an HotOrNot component when pre-defined lists are used
   *
   * @param {number]} emotionListIndex - index of predefined list in the global predefinedEmotionList
   */

  const updateHotOrNotValues = (listIndex: number) => {
    const stateEntry = nodeData.state.entry.find(isHotOrNotEntry);
    if (stateEntry) {
      removeAndAddPredefinedListTranslationsViaStateEntry(
        stateEntry,
        listIndex
      );
      // if index === -1, user defines own emotions ==> set entry flag
      stateEntry.useOwnElements = listIndex === -1;
      // false ==> this action can't be undone - it's not necessary
      updateElements([...nodes, ...edges], true);
    }
  };

  /**
   * update values for an EmotionInput component when pre-defined lists are used
   *
   * @param {number]} emotionListIndex - index of predefined list in the global predefinedEmotionList
   */
  const updateEmotionInputValues = (emotionListIndex: number) => {
    const stateEntry = nodeData.state.entry.find(isEmotionInputEntry);

    if (stateEntry) {
      removeAndAddPredefinedListTranslationsViaStateEntry(
        stateEntry,
        emotionListIndex
      );
      // if index === -1, user defines own emotions ==> set entry flag
      stateEntry.useOwnElements = emotionListIndex === -1;
      // false ==> this action can't be undone - it's not necessary
      updateElements([...nodes, ...edges], true);
    }
  };

  const updateOwnEmotionInput = (emotionList: EmotionProps[]) => {
    const stateEntry = nodeData.state.entry.find(isEmotionInputEntry);
    if (stateEntry && stateEntry.payload) {
      stateEntry.payload.elements = emotionList;
      stateEntry.ownElements = emotionList;
      updateElements([...nodes, ...edges]);
    }
  };

  /**
   * helper function for
   * - NeedsInput
   * - EmotionInput
   * - HotOrNotSelector
   *  for updating translation values
   *
   * @param {(StateEntryProps | undefined)} payload
   * @param {number} newindex
   */
  const removeAndAddPredefinedListTranslationsViaStateEntry = (
    stateEntry: StateEntryProps | undefined,
    newindex: number
  ) => {
    if (!moduleLoadedInDefaultLanguage) {
      alert(
        intl.formatMessage({
          id: 'builder.moduleeditor.entrylist.notindefaultlang',
          defaultMessage:
            'Du kannst deinem Chatbot nur dann neue Elemente hinzufügen oder Listen in Elementen ändern, wenn du ihn in der Default-Sprache geladen hast.',
        })
      );
      return;
    }

    const payload = stateEntry?.payload;

    if (stateEntry && payload) {
      //
      // Mae, 31.05.2022
      //
      // since React 18 migration I do not remove translations any more
      //

      let elementlist = [];
      let prefix = '';

      // newindex === -1 means: user enters own list
      // but do not add new ones
      if (newindex > -1) {
        // set new index, depending on object type
        if (stateEntry.type === needsInputStateEntry.type) {
          stateEntry.predefinedEmotionsIndex = newindex;
          elementlist = predefinedNeedsSet[newindex].needs;
          prefix = 'need';
        }
        if (stateEntry.type === emotionsInputStateEntry.type) {
          stateEntry.predefinedEmotionsIndex = newindex;
          elementlist = predefinedEmotionsSet[newindex].emotions;
          prefix = 'emo';
        }
        if (stateEntry.type === hotOrNotSelectorStateEntry.type) {
          stateEntry.predefinedListIndex = newindex;
          elementlist = predefinedHotOrNotLists[newindex].cards;
          prefix = 'hotnot';
        }

        // add new elements in translation format

        const newElements = elementlist.map(
          (listObject: Record<string, any>) => {
            const translationKey = `${elementId}.${prefix}.${generateRandomString(
              2
            )}`;
            const translationKeyDesc = translationKey + 'd';

            // translate the text in prop "value"
            addOrUpdateStateMachineTranslation(
              translationKey,
              listObject.value.startsWith('builder.')
                ? globalMessages[listObject.value]
                : listObject.value
            );

            // for "not or hot", we need to translate the description,
            // if it is existing
            if (
              stateEntry.type === hotOrNotSelectorStateEntry.type &&
              translationKeyDesc !== undefined &&
              listObject.description !== undefined
            ) {
              addOrUpdateStateMachineTranslation(
                translationKeyDesc,
                listObject.description.startsWith('builder.')
                  ? globalMessages[listObject.description]
                  : listObject.description
              );
              return {
                ...listObject,
                value: translationKey,
                description: translationKeyDesc,
              };
            } else {
              return { ...listObject, value: translationKey };
            }
          }
        );

        if (stateEntry.type === 'renderHotOrNotSelector') {
          payload.cards = newElements;
        } else {
          payload.elements = newElements;
        }
      } else {
        // reset list with own elements
        payload.elements = stateEntry.ownElements ?? [];
      }
    }

    autoSave();
    return;
  };

  /**
   * update values for an NeedsInput component
   *
   * @param {number} needsListIndex  - index of predefined list in the global predefinedNeedsList
   */
  const updateNeedsInputValues = (needsListIndex: number) => {
    removeAndAddPredefinedListTranslationsViaStateEntry(
      nodeData.state.entry.find(isNeedsInputEntry),
      needsListIndex
    );
    updateElements([...nodes, ...edges], true);
  };

  /** update radio button values from dedicated property pane control */
  const updateRadioButtonValues = (
    radioTexts: string[],
    keyTexts: string[],
    storeForUndo: boolean = true,
    keyNumbers: number[] = []
  ) => {
    updateNode(
      radioTexts,
      keyTexts,
      nodeData.state.entry.find(isRadioButtonEntry)?.payload,
      storeForUndo,
      keyNumbers
    );
  };

  /** update multi button values from dedicated property pane control */
  const updateMultiButtonValues = (
    buttonTexts: string[],
    keyTexts: string[] = [],
    storeForUndo: boolean = true,
    keyNumbers: number[] = []
  ) => {
    updateNode(
      buttonTexts,
      keyTexts,
      nodeData.state.entry.find(isMultiButtonEntry)?.payload,
      storeForUndo,
      keyNumbers
    );
  };
  const updateScaleInput = (scaleSize: number) => {
    const payload = nodeData.state.entry.find(isScaleInputEntry)?.payload;
    if (payload) {
      if (payload.scaleSize) {
        payload.scaleSize = scaleSize;
        payload.scale = Math.round(scaleSize / 2);
      }
    }
    updateElements([...nodes, ...edges]);
  };

  const updateScaleInputMulti = (scaleSize: number, keyTexts: string[]) => {
    const payload = nodeData.state.entry.find(isScaleInputMultiEntry)?.payload;
    if (payload) {
      if (payload.scaleSize) {
        payload.scaleSize = scaleSize;
        payload.scale = Math.round(scaleSize / 2);
      }
      payload.keyTexts = keyTexts;
      // check for all edges whether there are edges that match to this node
      // and of which their sourceHandle is not existig anymore (keytext deleted)
      // then remove

      // 1. all outgoing edges from the current node
      const nodeEdges = edges.filter(
        (edge: Edge<any>) => edge.source === nodeData.state.stateKey
      );

      // 2. all edges that have to be removed
      const edgeidstoberemoved = nodeEdges
        .filter((edge: Edge<any>) => !keyTexts.includes(edge.sourceHandle + ''))
        .map((edge: Edge<any>) => edge.id);

      // 3. remove edges
      const newEdgeList = edges.filter(
        (edge: Edge<any>) => !edgeidstoberemoved.includes(edge.id)
      );

      updateElements([...nodes, ...newEdgeList]);
    }
  };

  /**
   * update selection card value
   * @param buttonTexts
   * @param keyTexts
   * @param storeForUndo
   * @param keyNumbers
   */
  const updateSelectionCardValues = (
    buttonTexts: string[],
    keyTexts: string[],
    storeForUndo: boolean,
    keyNumbers: number[]
  ) => {
    updateNode(
      buttonTexts,
      keyTexts,
      nodeData.state.entry.find(isSelectionCardEntry)?.payload,
      storeForUndo,
      keyNumbers
    );
  };

  const updateImageSelectorValues = (
    images: ImageItem[],
    keyTexts: string[],
    storeForUndo: boolean
  ) => {
    const payload = nodeData.state.entry.find(isImageSelectorEntry)?.payload;
    if (payload) {
      payload.keyTexts = keyTexts;
      payload.images = images;

      updateElements([...nodes, ...edges], storeForUndo);
    }
  };

  const updateAiGenericClassificationValues = (
    classificationKeys: string[],
    keyTexts: string[],
    storeForUndo: boolean = true,
    keyNumbers: number[] = []
  ) => {
    //console.log(classificationKeys, keyTexts, storeForUndo, keyNumbers);
    updateNode(
      classificationKeys,
      keyTexts,
      nodeData.state.entry.find(isAiGenericClassificationEntry)?.payload,
      storeForUndo,
      keyNumbers
    );

    /* const payload = nodeData.state.entry.find(
      isAiGenericClassificationEntry
    )?.payload;
    if (payload) {
      payload.classificationKeys = classificationKeys;
    }
    console.log(classificationKeys);
    console.log('updateAiGenericClassificationValues');
    updateElements([...nodes, ...edges], storeForUndo); */
  };

  const updateMuliselectElements = (
    elements: any[],
    storeForUndo: boolean = true
  ) => {
    const payload = nodeData.state.entry.find(isEditableMultiEntry)?.payload;
    if (payload) {
      payload.elements = elements;
    }
    updateElements([...nodes, ...edges], storeForUndo);
  };

  const updateMultiplePercentScaleNames = (
    elements: any[],
    storeForUndo: boolean = true
  ) => {
    const payload = nodeData.state.entry.find(
      isMultiplePercentageScaleEntry
    )?.payload;
    if (payload) {
      payload.scaleNames = elements;
    }
    updateElements([...nodes, ...edges], storeForUndo);
  };

  const updateMuliselectElementsAutoGeneration = (getLabelsFrom: string) => {
    const payload = nodeData.state.entry.find(isEditableMultiEntry)?.payload;
    if (payload) {
      payload.getLabelsFrom = getLabelsFrom;
    }
    updateElements([...nodes, ...edges]);
  };

  const updateMultiplePercentageAutoGen = (type: string, value: string) => {
    const payload = nodeData.state.entry.find(
      isMultiplePercentageScaleEntry
    )?.payload;
    if (payload) {
      if (type === 'labels') {
        payload.getLabelsFrom = value;
      }
      if (type === 'series') {
        payload.getSeriesFrom = value;
      }
    }
    updateElements([...nodes, ...edges]);
  };

  const updateRandomCoachMessageValues = (
    messages: string[],
    keyTexts: string[]
  ) => {
    // for single output handles, we do not check the outgoing edges
    // but update nodes only
    if (
      nodeData.state.entry.find(isRandomCoachMessageEntry)?.nodeType ===
      'randomCoachMessageSingleStateEntry'
    ) {
      updateElements([...nodes, ...edges], true);
    } else {
      // randomCoachMessageStateEntry
      // update node and check whether outgoing edges have to be changed
      updateNode(
        messages,
        keyTexts,
        nodeData.state.entry.find(isRandomCoachMessageEntry)?.payload
      );
    }
  };

  const updateCompareMultipleNumbersValues = (
    intervalValues: number[],
    keys: string[]
  ) => {
    const payload = nodeData.state.entry.find(
      isCompareMultipleNumbersStateEntry
    )?.payload;
    if (payload) {
      payload.series = intervalValues;
      payload.keyTexts = keys;
    }
    updateElements([...nodes, ...edges], true);
  };

  /**
   * update a module id from within a ModuleSelect
   * @param {string} moduleid
   */
  const updateModuleInfo = (moduleid: string, moduletitlekey: string) => {
    const payload = nodeData.state.entry.find(isStartNewSessionEntry)?.payload;
    if (moduleid && payload) {
      payload.moduleId = moduleid;
      payload.moduleTitleKey = moduletitlekey;
    }

    updateElements([...nodes, ...edges], true);
  };

  /**
   * update prompt type
   * @param {PromptEnum} promptType
   */
  const updatePromptInfo = (promptType: PromptEnum) => {
    // TODO Danger! if the prompt select is used for other
    // TODO components, we have to add the find OR here
    /*  const payload = nodeData.state.entry.find(
      isSingleAnswerPromptEntry
    )?.payload; */
    const payload = nodeData.state.entry[0]?.payload;
    if (payload) {
      payload.promptType = promptType;
      payload.maxTurns = aiMicrochatMaxTurns[promptType] ?? 10;
    }

    updateElements([...nodes, ...edges], true);
  };

  const updateDocuments = (assetids: string[]) => {
    console.log('updateDocuments', assetids);

    const payload = nodeData.state.entry[0]?.payload;
    if (payload) {
      payload.assetids = assetids ?? [];
    }

    updateElements([...nodes, ...edges], true);
  };

  /**
   * update properties of the payload with entries provided with function
   *
   * @param {string[]} texts - actual text array to be changed. Prop for that array may differ per stateEntry type
   * @param {string[]} keyTexts - keys to used for generating dynamic handles, e.g. for radio buttons
   * @param {StatePayloadProps | undefined} payload - is the payload in which the values should be set
   * @param {boolean} storeForUndo - default: true = store this action that it can be undone
   * @param {numbers[]} keyNumbers - default: [] = array of numeric values that are assigned to textual values (PROD-1406)
   */
  const updateNode = (
    texts: string[],
    keyTexts: string[],
    payload: StatePayloadProps | undefined,
    storeForUndo: boolean = true,
    keyNumbers: number[] = []
  ) => {
    if (payload) {
      if (payload.buttonTexts) {
        payload.buttonTexts = texts;
      }
      if (payload.radioTexts) {
        payload.radioTexts = texts;
      }
      // if keynumbers are expected and provided, update prop
      // not available for each state entry type
      if (payload.keyNumbers !== undefined) {
        payload.keyNumbers = keyNumbers;
      }

      if (payload.classificationKeys) {
        payload.classificationKeys = texts;
      }

      payload.keyTexts = keyTexts;

      // check for all edges whether there are edges that match to this node
      // and of which their sourceHandle is not existig anymore (keytext deleted)
      // then remove

      // 1. all outgoing edges from the current node
      const nodeEdges = edges.filter(
        (edge: Edge<any>) => edge.source === nodeData.state.stateKey
      );

      // 2. all edges that have to be removed
      const edgeidstoberemoved = nodeEdges
        .filter((edge: Edge<any>) => !keyTexts.includes(edge.sourceHandle + ''))
        .map((edge: Edge<any>) => edge.id);

      // 3. remove edges
      const newEdgeList = edges.filter(
        (edge: Edge<any>) => !edgeidstoberemoved.includes(edge.id)
      );

      updateElements([...nodes, ...newEdgeList], storeForUndo);
    }
  };

  //
  const updateMultipleInputValues = (
    placeholderTexts: string[],
    storeForUndo: boolean = true
  ) => {
    const payload = nodeData.state.entry.find(isMultipleInputEntry)?.payload
      ? nodeData.state.entry.find(isMultipleInputEntry)?.payload
      : nodeData.state.entry.find(isEditableMultiEntry)?.payload;

    if (payload) {
      payload.placeholderTexts = placeholderTexts;
      updateElements([...nodes, ...edges], storeForUndo);
    }
  };

  const polarChartEntry = nodeData.state.entry.find(isPolarChartEntry);
  const radarChartEntry = nodeData.state.entry.find(isRadarChartEntry);
  const editableMultiselect = nodeData.state.entry.find(isEditableMultiselect);
  const radioButtonEntry = nodeData.state.entry.find(isRadioButtonEntry);
  const startNewSessionEntry = nodeData.state.entry.find(
    isStartNewSessionEntry
  );
  const multiplePercentageScaleInputEntry = nodeData.state.entry.find(
    isMultiplePercentageScaleEntry
  );

  const multiButtonEntry = nodeData.state.entry.find(isMultiButtonEntry);
  const selectionCardEntry = nodeData.state.entry.find(isSelectionCardEntry);
  const aiGenericClassificationEntry = nodeData.state.entry.find(
    isAiGenericClassificationEntry
  );
  const imageSelectorEntry = nodeData.state.entry.find(isImageSelectorEntry);
  const scaleInputEntry = nodeData.state.entry.find(isScaleInputEntry);
  const scaleInputMultiEntry = nodeData.state.entry.find(
    isScaleInputMultiEntry
  );

  const singleAnswerPromptEntry = nodeData.state.entry.find(
    isSingleAnswerPromptEntry
  );

  const documentChatPromptEntry = nodeData.state.entry.find(
    isAiDocumentChatEntry
  );

  const aiMicrochatEntry = nodeData.state.entry.find(isAiMicrochatEntry);

  const aiListPromptEntry = nodeData.state.entry.find(isAiListPromptEntry);

  const randomCoachMessageEntry = nodeData.state.entry.find(
    isRandomCoachMessageEntry
  );

  const compareMultipleNumbersEntry = nodeData.state.entry.find(
    isCompareMultipleNumbersStateEntry
  );

  const emotionInputEntry = nodeData.state.entry.find(isEmotionInputEntry);
  const hotOrNotEntry = nodeData.state.entry.find(isHotOrNotEntry);
  const needsInputEntry = nodeData.state.entry.find(isNeedsInputEntry);
  const variableDefineStringArrayNodeStateEntry = nodeData.state.entry.find(
    isVariableDefineStringArrayNodeStateEntry
  );
  const assetStateEntry = nodeData.state.entry.find(isAssetStateEntry);
  const createCertificateStateEntry = nodeData.state.entry.find(
    isCreateCertificateStateEntry
  );

  const [isTextFieldDialogOpen, setIsTextFieldDialogOpen] =
    useState<boolean>(false);

  const [isStatisticsOpen, setIsStatisticsOpen] = useState<boolean>(false);

  return (
    <>
      <Stack spacing={2}>
        {hasRole(RoleEnum.EVOACHADMIN) && (
          <>
            <Button
              onClick={() => setIsTextFieldDialogOpen(true)}
              style={{ width: '95%' }}
            >
              {'ID: ' + nodeData.state.stateKey}{' '}
            </Button>

            <NodeDebugOutput
              open={isTextFieldDialogOpen}
              onClose={() => setIsTextFieldDialogOpen(false)}
              value={nodeData}
            />

            <Button
              onClick={() => setIsStatisticsOpen(true)}
              style={{ width: '95%' }}
            >
              {intl.formatMessage({
                id: 'builder.entrylist.usagestats.button',
                defaultMessage: 'Nutzungsstatistik',
              })}
            </Button>

            <AnalyticsDisplay
              open={isStatisticsOpen}
              onClose={() => setIsStatisticsOpen(false)}
              value={nodeData}
              moduleid={moduleid}
            />
          </>
        )}
        <PropertyHeaderLine entry={nodeData.state.entry[0]} />

        {!!assetStateEntry && (
          <AssetSelectInput
            updatePayloadValue={updatePayloadValue}
            stateEntry={assetStateEntry}
          />
        )}

        {!!variableDefineStringArrayNodeStateEntry && (
          <MultiValueInput
            entry={variableDefineStringArrayNodeStateEntry}
            updateElementValues={updateStringArray}
          />
        )}
        {!!polarChartEntry && (
          <PolarChartInput
            elementId={elementId}
            entry={polarChartEntry}
            updatePolarChartValues={updatePolarChartValues}
            updatePolarChartAutoGeneration={updatePolarChartAutoGeneration}
            nodes={nodes}
          />
        )}
        {!!radarChartEntry && (
          <RadarChartInput
            entry={radarChartEntry}
            updateRadarChartAutoGeneration={updateRadarChartAutoGeneration}
            nodes={nodes}
          />
        )}
        {!!emotionInputEntry && (
          <EmotionInputInput
            entry={emotionInputEntry}
            updateEmotionInputValues={updateEmotionInputValues}
            updateOwnEmotionInput={updateOwnEmotionInput}
          />
        )}
        {!!hotOrNotEntry && (
          <HotOrNotInput
            entry={hotOrNotEntry}
            updateHotOrNotValues={updateHotOrNotValues}
          />
        )}
        {!!needsInputEntry && (
          <NeedsInputInput
            entry={needsInputEntry}
            updateNeedsInputValues={updateNeedsInputValues}
          />
        )}
        {!!radioButtonEntry && (
          <MultiValueInput
            entry={radioButtonEntry}
            updateElementValues={updateRadioButtonValues}
          />
        )}
        {!!multiButtonEntry && (
          <MultiValueInput
            entry={multiButtonEntry}
            updateElementValues={updateMultiButtonValues}
          />
        )}
        {!!selectionCardEntry && (
          <MultiValueInput
            entry={selectionCardEntry}
            updateElementValues={updateSelectionCardValues}
          />
        )}
        {!!aiGenericClassificationEntry && (
          <MultiValueInput
            entry={aiGenericClassificationEntry}
            updateElementValues={updateAiGenericClassificationValues}
          />
        )}
        {!!imageSelectorEntry && (
          <ImageSelectorInput
            entry={imageSelectorEntry}
            updateElementValues={updateImageSelectorValues}
          />
        )}
        {!!editableMultiselect && !!!needsInputEntry && (
          <EditableMultiselectElementsInput
            entry={editableMultiselect}
            updateElementValues={updateMuliselectElements}
            updateElementValuesAutoGeneration={
              updateMuliselectElementsAutoGeneration
            }
            nodes={nodes}
          />
        )}
        {!!editableMultiselect && !!needsInputEntry && (
          <>Legacy version of NeedsInput!</>
        )}
        {!!scaleInputMultiEntry && (
          <ScaleSizeInput
            entry={scaleInputMultiEntry}
            updateElementValues={updateScaleInputMulti}
          />
        )}
        {!!scaleInputEntry && (
          <ScaleSizeInput
            entry={scaleInputEntry}
            updateElementValues={updateScaleInput}
          />
        )}

        {!!randomCoachMessageEntry && (
          <MultiValueInput
            entry={randomCoachMessageEntry}
            updateElementValues={updateRandomCoachMessageValues}
          />
        )}

        {!!compareMultipleNumbersEntry && (
          <MultiValueInput
            entry={compareMultipleNumbersEntry}
            updateElementValues={updateCompareMultipleNumbersValues}
          />
        )}

        {!!startNewSessionEntry && (
          <ModuleSelect
            currentValue={startNewSessionEntry.payload.moduleId ?? ''}
            updateModuleInfo={updateModuleInfo}
          />
        )}

        {!!documentChatPromptEntry && (
          <DocumentSelect
            nodeType="aiDocumentChatStateEntry"
            updateDocuments={updateDocuments}
            currentValue={documentChatPromptEntry.payload.assetids ?? []}
          />
        )}

        {!!singleAnswerPromptEntry && (
          <PromptSelect
            nodeType="singleAnswerPromptStateEntry"
            updatePrompt={updatePromptInfo}
            currentValue={
              singleAnswerPromptEntry.payload.promptType ??
              singleAnswerPromptAllowedPromptTypes[0]
            }
            allowedPrompts={singleAnswerPromptAllowedPromptTypes}
          />
        )}

        {!!aiMicrochatEntry && (
          <PromptSelect
            nodeType="aiMicrochatStateEntry"
            updatePrompt={updatePromptInfo}
            currentValue={
              aiMicrochatEntry.payload.promptType ??
              aiMicrochatAllowedPromptTypes[0]
            }
            allowedPrompts={aiMicrochatAllowedPromptTypes}
          />
        )}

        {!!aiListPromptEntry && (
          <PromptSelect
            nodeType="ailistPromptStateEntry"
            updatePrompt={updatePromptInfo}
            currentValue={
              aiListPromptEntry.payload.promptType ??
              listPromptAllowedPromptTypes[0]
            }
            allowedPrompts={listPromptAllowedPromptTypes}
          />
        )}

        {!!createCertificateStateEntry && (
          <Box width="95%">
            <PrintTemplateSelection
              defaultTemplates={
                account?.metainfos?.printtemplates ?? [DefaultPrintTemplate]
              }
              isEditable={false}
            />
          </Box>
        )}

        {!!multiplePercentageScaleInputEntry && (
          <MultiplePercentageScaleInputInput
            entry={multiplePercentageScaleInputEntry}
            updateElementValues={updateMultiplePercentScaleNames}
            updateElementValuesAutoGeneration={updateMultiplePercentageAutoGen}
            nodes={nodes}
          />
        )}

        {nodeData.state.entry.map(
          (entry: StateEntryProps, entryIndex: number) => (
            <Stack key={`entry${entryIndex}`} spacing={1}>
              <ValuesInput
                entry={entry}
                elementId={elementId}
                updatePayloadValue={updatePayloadValue}
              />
              <OptionsInput
                entry={entry}
                elementId={elementId}
                updatePayloadValue={updatePayloadValue}
              />
              <SizeInput
                entry={entry}
                elementId={elementId}
                updateSize={updateSize}
              />
              <NavigationTargetInput
                entry={entry}
                elementId={elementId}
                updateNavigateTarget={updateNavigateTarget}
              />
              <PlaceholderTextsInput
                elementId={elementId}
                entry={entry}
                updateMultipleInputValues={updateMultipleInputValues}
              />
              <GetValueFrom
                entry={entry}
                updateGetValueFrom={updateGetValueFrom}
                nodes={nodes}
              />
              <GetStringValueFrom
                entry={entry}
                updateGetStringValueFrom={updateGetStringValueFrom}
                nodes={nodes}
              />
              <GetPersonaFrom
                entry={entry}
                updatePersonaFrom={updateGetPersonaFrom}
                nodes={nodes}
              />
              <GetTopicsValueFrom
                entry={entry}
                updateGetTopicsValueFrom={updateGetTopicsValueFrom}
                nodes={nodes}
              />
              <VariableEdit
                entry={entry}
                onRefactor={() => {
                  // nothing changed? return
                  if (
                    nodeData.state.entry[0].payload.saveResultTo ===
                    saveResultToBeforeChange
                  )
                    return;
                  // new value is duplicate? ask before changing
                  if (
                    isDuplicateVariable(
                      nodeData.state.entry[0].payload.saveResultTo ?? '',
                      nodes
                    )
                  ) {
                    // if new variable is duplicate, ask before changing
                    setVarNameChangeWarningType('duplicateask');
                    setVarNameChangeWarningIsOpen(true);
                  } else {
                    // anything else: update
                    updateVariables(false);
                  }
                }}
                onFocus={() => {
                  if (entry.payload && entry.payload.saveResultTo) {
                    setSaveResultToBeforeChange(entry.payload.saveResultTo);
                  }
                }}
              />
            </Stack>
          )
        )}
      </Stack>
      <VarNameChangeWarning
        isOpen={varNameChangeWarningIsOpen}
        onClose={() => {
          setVarNameChangeWarningIsOpen(false);
          if (varNameChangeWarningType === 'duplicateask') {
            updateVariables(false);
          }
        }}
        onConfirm={() => {
          setVarNameChangeWarningIsOpen(false);
          if (varNameChangeWarningType === 'duplicateask') {
            updateVariables(true);
          }
        }}
        warningType={varNameChangeWarningType}
      />
    </>
  );
};
