/*
  ModuleEditorPane

  Pls check call hierarchy with responsibilities below

  BuilderPage                     - page for router, check route params, load module
    |_ ModuleBuilderWrapper       - management of module translation which is independent of the UI translation
      |_ ModuleBuilder            - keep state of Reract Flow nodes and edges, manage undo logic
        |_ ModuleEditor           - provide toolbar on top of editor canvas
!          |_ ModuleEditorpane     - ReactFlow provider + ReactFlow component + management of canvas actions

*/

import React, {
  DragEvent,
  DragEventHandler,
  //MouseEvent,
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  isEdge,
  isNode,
  MiniMap,
  XYPosition,
  Node,
  useStore,
  Transform,
  useReactFlow,
  applyNodeChanges,
  applyEdgeChanges,
  useStoreApi,
  Connection,
  NodeChange,
  EdgeChange,
  ReactFlowInstance,
  useUpdateNodeInternals,
  Viewport,
} from 'reactflow';
import { Alert, AlertColor, AlertTitle, Snackbar } from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  evoachDefaultTheme,
  generateRandomString,
} from '@evoach/ui-components';
import { cloneDeep } from 'lodash';

import globalLoopTemplate from '../SelectionSidebar/elementTemplates/loopTemplate.json';
import globalMultipleInputTemplate from '../SelectionSidebar/elementTemplates/multipleInputTemplate.json';
import { TranslationContext as GlobalTranslationContext } from '../../intl/TranslationContext';
import { authorizedGet } from '../../api';
import {
  isLoopEnd,
  isLoopStart,
  loopEndStateEntry,
  loopStartStateEntry,
  StateEntryProps,
  StateNode,
} from '../nodes';
import * as CustomNodes from '../nodes';
import { TranslationContext } from '../stateMachineTranslationContext';
import {
  ModuleProps,
  ModuleTranslationProps,
  TranslationProps,
} from '../../entities';
import { DevToolsContext } from '../../devtools/DevToolsContext';
import { WindowContext } from '../window/WindowContext';
import { AccountContext, RoleEnum } from '../../account';

import 'reactflow/dist/style.css';

import {
  adjustDynamicHandles,
  adjustEdges,
  adjustNodePositions,
  connectLoopElementsByEdge,
  containsLoopNodes,
  containsVariables,
  findLoopEndNode,
  findLoopStartNode,
  findNodeByNodeId,
  getEdges,
  getListOfVariables,
  getLooseEdges,
  getNodeDistances,
  isWithinLoopByNodeId,
  moveNodes,
  regenerateNodeIds,
  removeLooseEdges,
  removeVariables,
} from './moduleHelper';
import {
  ModuleEditorContextMenu,
  ModuleEditorContextMenuDisplayProps,
} from './ModuleEditorContextMenu';
import { ReactFlowNodeEventContext } from './ReactFlowNodeEventContext';
import { autoSave, setAutoSaveTriggered } from './autosave';
import {
  getSelectedElements,
  //getSelectedNodes,
  getUnselectedEdges,
  ReactFlowElements,
  resetSelectedElements,
  setSelectedElements,
} from './ReactFlowHelper';
import { nodeTypes } from './nodeTypes';
import { edgeTypes } from './edgeTypes';
import { ModuleEditorContext } from './ModuleEditorContext';

import {
  getAllowedItemTypes,
  getClipboardAsTexts,
  getNodesByTexts,
  initArrayTranslation,
  initPayloadTranslation,
  initTranslation,
} from '.';

const moduleCanvasMessages = defineMessages({
  canvastitle: {
    id: 'builder.moduleeditor.canvasalert.title',
    defaultMessage: 'Hinweis',
  },
  canvascomment: {
    id: 'builder.moduleeditor.canvasalert.comment',
    defaultMessage: 'Diese Operation konnte nicht ausgeführt werden:',
  },
  alertnumedges: {
    id: 'builder.moduleeditor.canvasalert.toomanyedges',
    defaultMessage:
      'Es kann nur eine ausgehende Verbindung pro Elementausgang eingefügt werden.',
  },
  alertstartnode: {
    id: 'builder.moduleeditor.canvasalert.onlyonestartnode',
    defaultMessage: 'Es kann nur ein Startelement eingefügt werden.',
  },
  alertendnode: {
    id: 'builder.moduleeditor.canvasalert.onlyoneendnode',
    defaultMessage: 'Es kann nur ein Endelement eingefügt werden.',
  },
  alterloopconnect: {
    id: 'builder.moduleeditor.canvasalert.alterloopconnect',
    defaultMessage:
      'Bitte verbinde die Schleife nur mit dem zugehörigen Knoten.',
  },
  alerttargetsource: {
    id: 'builder.moduleeditor.canvasalert.alerttargetsource',
    defaultMessage: 'Ein Knoten darf nicht mit sich selbst verbunden werden.',
  },
});

interface ModuleEditorPaneProps {
  saveModuleHandler: Function;
  command?: string | undefined;
  updateViewport: (viewport: Viewport) => void;
  viewPort?: Viewport;
}

let globalCopyObjects: any = null;
let globalCopyObjectsTranslations: TranslationProps | undefined = undefined;

export const ModuleEditorPane: React.FC<ModuleEditorPaneProps> = ({
  saveModuleHandler,
  command,
  updateViewport,
  viewPort,
}) => {
  //
  // everything state machine translation
  //
  const {
    stateMachineLocale,
    addOrUpdateStateMachineTranslation,
    stateMachineTranslation,
  } = React.useContext(TranslationContext);

  //
  // everthing module
  //
  const {
    nodes,
    edges,
    updateEdges,
    updateNodes,
    undoElements,
    updateElements,
    moduleLoadedInDefaultLanguage,
    module,
  } = useContext(ModuleEditorContext);

  const { defaultMessages: globalMessages } = React.useContext(
    GlobalTranslationContext
  );

  const { hasRole } = useContext(AccountContext);

  const { alert } = useContext(WindowContext);

  const reactFlowStoreApi = useStoreApi();

  const getElements = useCallback(() => {
    return (nodes as ReactFlowElements).concat(edges);
  }, [edges, nodes]);

  const { l } = useContext(DevToolsContext);

  const intl = useIntl();

  const flowRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const transform = useStore((store) => store.transform);

  const projectPosition = (
    x: number,
    y: number,
    [tx, ty, tScale]: Transform = transform
  ): XYPosition => {
    const flowRect = flowRef.current?.getBoundingClientRect() || {
      left: 0,
      top: 0,
    };

    return {
      x: (x - tx - flowRect.left) / tScale,
      y: (y - ty - flowRect.top) / tScale,
    } as XYPosition;
  };

  const { setCenter, fitView } = useReactFlow(); // setCenter, fitView

  useEffect(() => {
    fitView();
  }, [fitView]);

  /**
   * removeLoopNodesAndVariables
   * Remove loop nodes,i.e., if you delete the start node, also delete the
   * end node - and vice versa).
   * Furthermore, delete the variables that were provided by the nodes to be
   * removed.
   * The nodes themselves will be removed by ReactFlow, @see onNodesChange
   * @param {Node<any>[]} nodesToBeRemove - list of nodes to be removed
   * @returns {Node<any>[]} additionalNodesToBeRemoved - a list of nodes that
   *     has to be removed in addition to the initial list.
   */
  const removeLoopNodesAndVariables = useCallback(
    (nodesToBeRemoved: Node<any>[]): Node<any>[] => {
      // TODO entferne globale variablen, die für Schleifen künstlich generiert wurden isInLoop

      let additionalNodesToBeRemoved: Node<any>[] = [];
      // check whether there is a loop node removed ==> remove both nodes +edge
      // do not allow removing only one node
      if (nodesToBeRemoved && containsLoopNodes(nodesToBeRemoved)) {
        // make sure, that both parts of the loop are deleted
        // 1. get all loopNodes contained in current deletion request
        const loopNodes = nodesToBeRemoved.filter(
          (node: Node<any>) => isLoopStart(node.data) || isLoopEnd(node.data)
        );

        // 2. for each loopNode add counterpart for deletion
        loopNodes
          .map((delnode: Node<any>) => {
            if (isLoopStart(delnode.data)) {
              // add loop end
              return findLoopEndNode(delnode, nodes, edges);
            }
            if (isLoopEnd(delnode.data)) {
              // add loop end
              return findLoopStartNode(delnode, nodes, edges);
            }
            return undefined;
          })
          .filter((elem: any) => elem !== undefined)
          .forEach((elem: any) => {
            additionalNodesToBeRemoved.push(elem);
          });
      }

      // check whether any of the deleted elements contains variables
      // if yes, try to remove their usage in all using elements
      if (containsVariables(nodesToBeRemoved)) {
        // log
        const listOfVariablesToBeDeleted = getListOfVariables(nodesToBeRemoved);
        removeVariables(listOfVariablesToBeDeleted, nodes);
      }
      return additionalNodesToBeRemoved;
    },
    [edges, nodes]
  );

  //
  // onNodesChange
  //
  // This is a central event handler for React Flow!
  // s. also https://reactflow.dev/docs/guides/migrate-to-v10/#new-api
  //
  // a NodeChange has a property called type. type can have these values:
  // "dimensions" | "position" | "select" | "remove" | "add" | "reset"
  //
  // This handler is called when
  // - one or more nodes are deleted ("remove")
  // - one or more nodes are moved in the canvas, e.g., by dragging ("position")
  //

  // when a lot of NodeChange events fire (e.g. when dragging a node on the canvas),
  // only one event (the start) should be saved
  const [anyChangeUndo, setAnyChangeUndo] = useState<boolean>(true);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      // I don't know whether different types can be intermixed
      // in one NodeChange Array. That's why I handle it in this
      // variable. In addition, I don't want to handle each node separately
      // because all former functions (implemented for React Flow 9) rely
      // on the list of nodes to be changed.
      const nodesToBeDeleted = changes
        .map((change: NodeChange) =>
          change.type === 'remove'
            ? findNodeByNodeId(change.id, nodes)
            : undefined
        )
        .filter((node) => node) as Node<any>[];

      // if there is anything to delete, do what a deletion has to do
      if (nodesToBeDeleted.length > 0) {
        if (!moduleLoadedInDefaultLanguage) {
          alert(
            intl.formatMessage({
              id: 'builder.moduleeditor.canvasalert.notindefaultlang.del',
              defaultMessage:
                'Du kannst deinem Chatbot nur dann Elemente löschen, wenn du ihn in der Default-Sprache geladen hast.',
            })
          );
          return;
        }
        // remove variables and loop-counterparts of nodes to be deleted
        // there may be additional nodes that have to be deleted in
        // addition to the actual nodes affected by "changes"
        const additionalNodeIdsToBeRemoved = removeLoopNodesAndVariables(
          nodesToBeDeleted
        ).map((node: Node<any>) => node.id);
        // updated nodes (applyNodeChanges is a ReactFlow action)
        // update nodes and remove programmatically the nodes that have to
        // be removed in addition because these are node loops
        updateNodes(
          applyNodeChanges(changes, nodes).filter(
            (node: Node<any>) => !additionalNodeIdsToBeRemoved.includes(node.id)
          ),
          true
        );
        // auto save after 1 sec
        autoSave(module.moduleid);
      } else {
        if (changes[0].type !== 'dimensions') {
          // for all other types than "remove", execute operation
          updateNodes(applyNodeChanges(changes, nodes), anyChangeUndo);
        } else {
          // if type is demensions, do not update our node list
          // but apply to react flow store. This seems to help to get rid
          // of "jumping" selection when using rectangle selection of nodes
          applyNodeChanges(changes, nodes);
        }
        // handle (un-)stable position updates
        if (changes && changes.length > 0 && changes[0].type === 'position') {
          setAnyChangeUndo(false);
        }
      }
    },
    [
      alert,
      anyChangeUndo,
      intl,
      module.moduleid,
      moduleLoadedInDefaultLanguage,
      nodes,
      removeLoopNodesAndVariables,
      updateNodes,
    ]
  );

  // if node dimensions change, we have to update node internals in order to adjust
  // edge positions, e.g., when number of handles changed. This is normally done
  // in the .node.tsx implementation of a node. But we want it to be performed once
  // after loading a new module.
  const updateNodeInternals = useUpdateNodeInternals();

  // when React Flow inits, call updateNodeInternals once to
  // adjust edges and nodes sizes for layouting
  const onInit = (reactFlowInstance: ReactFlowInstance) => {
    nodes.forEach((node) => updateNodeInternals(node.id));
    // if the module was stored with a viewport, re-set
    if (viewPort) {
      reactFlowInstance.setViewport(viewPort);
    } else {
      // ... otherwise fit view
      reactFlowInstance.fitView();
    }
  };

  //
  // onEdgesChange
  //
  // This is a central event handler for React Flow!
  // s. also https://reactflow.dev/docs/guides/migrate-to-v10/#new-api
  //
  // a EdgeChange has a property called type. type can have these values:
  // "select" | "remove" | "add" | "reset"
  //
  // This handler is called when
  // - one or more edges are deleted ("remove")
  //
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      let anyRemove = false;
      changes.forEach((change) => {
        anyRemove = anyRemove || change.type === 'remove';
      });
      // do not delete anything if
      if (!moduleLoadedInDefaultLanguage) {
        alert(
          intl.formatMessage({
            id: 'builder.moduleeditor.canvasalert.notindefaultlang.deledge',
            defaultMessage:
              'Du kannst in deinem Chatbot nur dann Kanten hinzufügen oder löschen, wenn du ihn in der Default-Sprache geladen hast.',
          })
        );
        return;
      }
      updateEdges(applyEdgeChanges(changes, edges), anyRemove);
      if (anyRemove) {
        autoSave();
      }
    },
    [alert, edges, intl, moduleLoadedInDefaultLanguage, updateEdges]
  );

  /**
   * onCutElements is triggered, when CMD+X is pressed or when the corresponding
   * context menu entry is triggerd.
   *
   * @param elementsToRemove
   * @returns
   */
  const onCutElements = useCallback(
    (elementsToRemove: ReactFlowElements) => {
      if (!moduleLoadedInDefaultLanguage) {
        alert(
          intl.formatMessage({
            id: 'builder.moduleeditor.canvasalert.notindefaultlang.cut',
            defaultMessage:
              'Du kannst in deinem Chatbot nur dann Elemente ausschneiden, wenn du ihn in der Default-Sprache geladen hast.',
          })
        );
        return;
      }
      // selected elements to be deleted
      const nodesToBeDeleted = elementsToRemove.filter(isNode);
      let edgesToBeDeleted = elementsToRemove.filter(isEdge);

      // add loose edges to the list of egdes, i.e., edges that would
      // have an open end if the nodesToBeDeleted are deleted
      edgesToBeDeleted = edgesToBeDeleted.concat(
        getLooseEdges(nodesToBeDeleted, getUnselectedEdges(edges))
      );

      // TODO chc loop relearted nodes and edges

      // get all nodes and egdes that are not in the list of deleted nodes
      // we do that by filtering and filter by !includes (id)
      const nodeIdsToDelete = nodesToBeDeleted.map(
        (node: Node<any>) => node.id
      );
      const edgeIdsToBeDeleted = edgesToBeDeleted.map(
        (edge: Edge<any>) => edge.id
      );

      updateNodes(
        nodes.filter((node: Node<any>) => !nodeIdsToDelete.includes(node.id))
      );
      updateEdges(
        edges.filter((edge: Edge<any>) => !edgeIdsToBeDeleted.includes(edge.id))
      );

      autoSave(module.moduleid);
    },
    [
      alert,
      edges,
      intl,
      module.moduleid,
      moduleLoadedInDefaultLanguage,
      nodes,
      updateEdges,
      updateNodes,
    ]
  );

  /**
   * initAndSetElementTranslationsForSingleElement takes a state entry and prepares
   * its payload for translation by replacing the translateable values by translation keys
   * @param {StateEntryProps} stateEntry - state entry to be processed
   * @param {string} elementId - current elementid
   * @returns
   */
  const initAndSetElementTranslationsForSingleElement = useCallback(
    (stateEntry: CustomNodes.StateEntryProps, elementId?: string) => {
      l('ModuleEditorPane: initAndSetElementTranslationsForSingleElement');
      // walk through payload and prepare translateable values

      initPayloadTranslation(
        stateEntry,
        addOrUpdateStateMachineTranslation,
        globalMessages
      );

      // if there is a saveResultToKey ==> init with internal value for echos
      const saveResultToKey = Object.keys(stateEntry.payload).find(
        CustomNodes.isSaveResultToKey
      );
      if (saveResultToKey) {
        // init with an default evoachechokey
        // use that prefix in order to decide whether to display or not the value
        stateEntry.payload[saveResultToKey] =
          'evoachechokey.' + (elementId ?? generateRandomString(4));
      }

      return stateEntry;
    },
    [l, addOrUpdateStateMachineTranslation, globalMessages]
  );

  /**
   * create translation keys and translation for new templates
   * this function can't be used for single elements as
   * translations already exist
   *
   */
  const initAndSetElementTranslationsForTemplate2 = useCallback(
    (
      stateEntry: CustomNodes.StateEntryProps,
      currentStateMachineTranslation: TranslationProps
    ): {
      stateEntry: CustomNodes.StateEntryProps;
      newTranslationKeys: Record<string, string>[];
    } => {
      const payloadKeyList = Object.keys(stateEntry.payload);

      // collect { origKey: initialValueOrKey, newKey: translationKey };
      // for the return value;
      let returnList: Record<string, string>[] = [];

      payloadKeyList
        .filter((key: string) =>
          typeof stateEntry.payload[key] === 'string'
            ? CustomNodes.isTranslatedStatePayloadProp(
                key,
                stateEntry.payload[key] as string
              )
            : CustomNodes.isTranslatedStatePayloadProp(key)
        )
        .forEach((payloadKey) => {
          if (Array.isArray(stateEntry.payload[payloadKey])) {
            const newKeys = initArrayTranslation(
              stateEntry,
              payloadKey,
              addOrUpdateStateMachineTranslation,
              globalMessages,
              currentStateMachineTranslation
            );
            // array of newKeys
            returnList = returnList.concat(newKeys);
            // special case
          } else {
            const newKey = initTranslation(
              stateEntry,
              payloadKey,
              addOrUpdateStateMachineTranslation,
              globalMessages,
              currentStateMachineTranslation
            );
            // single newKey
            returnList.push(newKey);
          }
        });

      const saveResultToKey = payloadKeyList.find(
        CustomNodes.isSaveResultToKey
      );
      if (saveResultToKey) {
        // TODO we may adapt all variable names of a template to
        // avoid interference with other existing elements
        // if we adapt this key, we have to adapt all related
        // getValue(s)From entries, too (strings+arrays of strings)
        // for now, just init with elementid if empty
        if ((stateEntry.payload[saveResultToKey] as string).trim() === '') {
          stateEntry.payload[saveResultToKey] = generateRandomString(8);
        }
      }

      // we save/need the returnList.flat() ??? is collected but not used
      // PROD-1345 - here was
      // return stateEntry!
      return { stateEntry: stateEntry, newTranslationKeys: returnList };
    },
    [addOrUpdateStateMachineTranslation, globalMessages]
  );

  /**
   * handle drop from an element that is dragged from the left panel to the canvas
   *
   * @param {DragEvent<HTMLDivElement>} event
   */
  const onDrop: DragEventHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!moduleLoadedInDefaultLanguage) {
      alert(
        intl.formatMessage({
          id: 'builder.moduleeditor.canvasalert.notindefaultlang',
          defaultMessage:
            'Du kannst deinem Chatbot nur dann neue Elemente hinzufügen oder Listen in Elementen ändern, wenn du ihn in der Default-Sprache geladen hast.',
        })
      );
      return;
    }

    // check whether templateData exists
    const templateData = event.dataTransfer.getData(
      'application/reactflow/template'
    );

    if (templateData && templateData.length > 0) {
      // if a loop is inserted, load template from static definition
      if (
        templateData === 'loopStateEntry' ||
        templateData === 'multipleInputDecomposedStateEntry'
      ) {
        // it is very important to use cloneDeep to provide templates.
        // otherwise, references of the template are passed; and if that
        // happens, you run into issues with duplicate ids etc.
        switch (templateData) {
          case 'loopStateEntry':
            onDropTemplate(globalLoopTemplate, event);
            break;
          case 'multipleInputDecomposedStateEntry':
            onDropTemplate(globalMultipleInputTemplate, event);
            break;
        }
      } else {
        // it's not a loop, it's a real template from database

        // templateData contains moduleid of template
        // load with explicit permission or as default template (astemplate=true)
        const fetchTemplateURL = `/module/${templateData}?language=${stateMachineLocale}&astemplate=true`;

        // fetch template from backend and then call onDropTemplate
        const fetchTempCall = authorizedGet(fetchTemplateURL);
        fetchTempCall().then((response: Response) => {
          response.json().then((templateData: any) => {
            onDropTemplate(templateData, event);
          });
        });
      }
    } else {
      onDropElement(event);
    }
  };

  /**
   * Handle drop of a single element
   *
   * @param {DragEvent<HTMLDivElement>} event
   * @return {*}
   */
  const onDropElement = (event: DragEvent<HTMLDivElement>) => {
    // get stateEntry for statemachine as defined in ElementSelection.tsx OnDrag
    // if no state entry is available, return without action
    const elementEntry = event.dataTransfer.getData(
      'application/reactflow/stateEntry'
    );
    if (!elementEntry || elementEntry === '') return;

    const stateEntry: CustomNodes.StateEntryProps = JSON.parse(elementEntry);

    // PROD-1969 . add ai flag
    if (
      stateEntry.nodeType === 'coachMessageStateEntry' &&
      !hasRole(RoleEnum.EVOACHADMIN)
    ) {
      // if we have a new coachMessage and the user is not an evoachAdmin,
      // we remove the props for aiBadge and helpertext as they are only needed
      // for AI functions
      delete stateEntry.payload.showAiBadge;
      delete stateEntry.payload.helperText;
    }

    // if there is already a StartPhaseNode, skip adding another one
    if (stateEntry.nodeType === 'phaseStartStateEntry') {
      if (
        nodes.find((node) => node.data.nodeType === 'phaseStartStateEntry') !==
        undefined
      ) {
        showHint(moduleCanvasMessages.alertstartnode, 'warning');
        return;
      }
    }
    // if there is already a EndPhaseNode, skip adding another one
    if (stateEntry.nodeType === 'phaseEndStateEntry') {
      if (
        nodes.find((node) => node.data.nodeType === 'phaseEndStateEntry') !==
        undefined
      ) {
        showHint(moduleCanvasMessages.alertendnode, 'warning');
        return;
      }
    }

    // if there are keyTexts, randomize keys to prevent the grapg from
    // having duplicate edge handle names on different nodes
    if (
      stateEntry.payload.keyTexts !== undefined &&
      Array.isArray(stateEntry.payload.keyTexts)
    ) {
      stateEntry.payload.keyTexts = stateEntry.payload.keyTexts.map(
        (_e) => 'keytext.' + generateRandomString(4)
      );
    }

    const position = projectPosition(event.clientX, event.clientY);
    addNewNode(stateEntry, position);
    // wait a second and then trigger save => auto-save.
    // sync translation keys as a new node was added
    autoSave(module.moduleid);
  };

  /** add a single node to the canvas
   * we need only a stateEntry and the position where to paste
   * @param {StateEntryProps} stateEntry - state entry template of the node to be created
   * @param {XYPosition} position - React Flow canvas position in which the element should be created
   * @param @option {boolean} updateGlobalElements - @default true - defines whether
   * the call immediatly updates the global set of elements of only returns the elements
   * @return {Node<any>} newNode - the newly created node
   */
  const addNewNode = (
    stateEntry: StateEntryProps,
    position: XYPosition,
    updateGlobalElements: boolean = true
  ) => {
    l('ModuleEditorPane: newNode in addNewNode:');
    const elementId = generateRandomString(8);

    const stateEntryList: CustomNodes.StateEntryProps[] = [
      initAndSetElementTranslationsForSingleElement(stateEntry, elementId),
      cloneDeep(CustomNodes.percentageEntryTemplate),
    ];

    const preparedElementState = new CustomNodes.State(
      elementId,
      stateEntryList
    );

    const newNode = new CustomNodes.StateNode(
      elementId,
      stateEntry.nodeType,
      position,
      preparedElementState,
      stateEntry.nodeMiniMapColor ?? '#CCCCCC'
    );

    l(newNode);

    if (updateGlobalElements) {
      updateNodes(nodes.concat(newNode));
    }
    return newNode;
  };

  /**
   * update translations of nodes when dropping to a canvas to make them unique
   * includes an update of translation. This function can only be used
   * with templates and not with single elements
   *
   * @see updateTranslationsForTemplate
   * @param {CustomNodes.StateNode} node node to be updated
   * @param {string} templateStateMachineTranslations list of translations that has to be adapted
   * @return {{node: CustomNodes.StateNode, newTranslationKeys: Record<string, string>[]}} list of modified translation keys
   */
  const updateTranslationsForTemplate = useCallback(
    (
      node: CustomNodes.StateNode,
      templateStateMachineTranslations: TranslationProps
    ): {
      node: CustomNodes.StateNode;
      newTranslationKeys: Record<string, string>[];
    } => {
      l(
        'ModuleEditorPane: updateTranslations: templateStateMachineTranslations'
      );
      // collect { origKey: initialValueOrKey, newKey: translationKey };
      // for the return value;
      let returnList: Record<string, string>[] = [];

      //l(templateStateMachineTranslations);
      node.data?.state.entry.forEach((stateEntry) => {
        // PROD-1345 - here was
        // stateEntry = initAnd .... => I changed it to
        // changedKeys = initAnd ...
        // if there are any issues with the template mechanism, check it here
        const { stateEntry: newStateEntry, newTranslationKeys: newKeys } =
          initAndSetElementTranslationsForTemplate2(
            stateEntry,
            templateStateMachineTranslations
          );
        returnList = returnList.concat(newKeys);
        stateEntry = newStateEntry;
      });

      // PROD-1345 - here was
      // return node!
      return { node: node, newTranslationKeys: returnList };
    },
    [initAndSetElementTranslationsForTemplate2, l]
  );

  /** addElementsToGraph gets a list of react-flow elements and their
   * translation information and adds them to the list of existing elements.
   * Before adding, node positions, node ids, edges, dynamic handle IDs and translation keys
   * are modified in order to avpid duplicates and side effects in the graph.
   */
  const addElementsToGraph = useCallback(
    (
      buildergraph: ReactFlowElements,
      updatedElements: ReactFlowElements,
      templateStateMachineTranslations: TranslationProps | undefined,
      startPosition: XYPosition
    ): {
      buildergraph: ReactFlowElements;
      newTranslationKeys: Record<string, string>[];
    } => {
      // move all existing elements and make space
      // end move existing nodes for re-layouting
      buildergraph = adjustNodePositions(
        buildergraph,
        startPosition.x,
        startPosition.y
      );

      // new node ids
      const newGraphWithMapping = regenerateNodeIds(buildergraph);

      // adjust edges to new node ids
      buildergraph = adjustEdges(
        newGraphWithMapping.buildergraph,
        newGraphWithMapping.idmap
      );

      // adjust ids of dynamic handles and their corresponding
      // keyTexts payload and transition keys
      buildergraph = adjustDynamicHandles(buildergraph);

      // for templates, templateStateMachineTranslations is filled
      // for copy/paste actions, templateStateMachineTranslations is undefined
      // as we have to re-use existing keys => we need a different handling

      // collect { origKey: initialValueOrKey, newKey: translationKey };
      // for the return value;
      let returnList: Record<string, string>[] = [];

      // modify translation keys and add translations to the current context
      // use template translation if available, stateMachineTranslation otherwise
      buildergraph = buildergraph.map((element: any) => {
        if (isEdge(element)) {
          // if this is an edge of a loop, do not add delete button
          if (element.sourceHandle === 'loopout') {
            return element;
          } else {
            // if this is not a loop edge, add delete button
            return { ...element, type: 'buttonedge' };
          }
        }
        l(
          'ModuleEditorPane: AddELementstoGraph: templateStateMachineTranslations:'
        );
        //l(templateStateMachineTranslations);
        const { newTranslationKeys: updatedTranslationKeys } =
          updateTranslationsForTemplate(
            element,
            templateStateMachineTranslations ?? stateMachineTranslation
          );
        returnList = returnList.concat(updatedTranslationKeys);
        return element;
      });

      // template only as we do not have to modify
      // existing translation keys if we handle copy/paste => there are only new key
      //if (templateStateMachineTranslations) {
      // update the newly generated translation keys in the payload of the
      // different node states
      // Object.keys(templateStateMachineTranslations).forEach((translationId) => {
      /* addOrUpdateStateMachineTranslation(
          translationId,
          templateStateMachineTranslations[translationId]
        ); */
      // translationkeys of template where modified for current module
      // we now remove the original translation keys of the template
      // from the current module to avoid duplicates (PROD-1172)
      // removeStateMachineTranslation(translationId);
      //});
      // }

      // elements is the list of elements that were in canvas before the
      // template was dragged into the canvas
      // .concat(updatedNodes).concat(elements.filter(isEdge)
      updateElements(updatedElements.concat(buildergraph));
      // PROD-1345 - here was
      // return buildergraph
      return { buildergraph: buildergraph, newTranslationKeys: returnList };
    },
    [l, stateMachineTranslation, updateElements, updateTranslationsForTemplate]
  );

  /**
   * onDropTemplate handles drop of a template to the canvas
   * also makes space and moves objects in the canvas
   *
   * @param {any} templateLoaded JSON with template data as fetched from backend
   * @param {DragEvent<HTMLDivElement>} event original event is needed for positioning information on screen (make place)
   */
  const onDropTemplate = (
    templateLoaded: any | undefined,
    event: DragEvent<HTMLDivElement>
  ) => {
    if (!templateLoaded) return;

    const template = cloneDeep(templateLoaded) as ModuleProps;

    // when pasting a template, try to choose the correct language
    // 1) try to find the current module language inside the template
    // 2) if template does not have a translation for the current module language, try to find an Englisch translation
    // 3) if there is no English translation, get the first available translation
    // 4) if there is no translation, then return an empty array ==> this will crash the builder
    const templateStateMachineTranslations =
      template.translations.find(
        (translation: ModuleTranslationProps) =>
          translation.lang === stateMachineLocale
      )?.statemachinetranslation ??
      template.translations.find(
        (translation: ModuleTranslationProps) => translation.lang === 'EN'
      )?.statemachinetranslation ??
      template.translations[0].statemachinetranslation ??
      {};

    //const idMap = new Map<string, string>();
    const templateNodes = template.buildergraph.filter(isNode);

    const projectedCursorPosition = projectPosition(
      event.clientX,
      event.clientY
    );

    // move existing elements downwards
    // 1) find the closest element comparing distance to drop coords
    // 2) find out how large the y-extensions of the new nodes is
    // 3) move all nodes below the lowest down on convas
    // 4) remove edge of highest node moved

    // ! 1) closest node to drop coords
    let currentnodes = nodes;

    // offset added to distances to distinguish nodes that have not to be moved
    // from the ones that have to be moved to make space to insert a template
    const ignoreNodeOffset = 10000;

    // calculate distances of all nodes to the projected insertion position
    // adding ignoreOffset for those that haven't to be moved because they're
    // "above" the insertion position
    const nodeDistances = getNodeDistances(
      currentnodes,
      projectedCursorPosition,
      ignoreNodeOffset
    );

    // if only one node is "below" the insertion point, it's not an insertion
    // at the end. Insertion at the end means, that all nodes are above
    // the insertion point which is indicated by the distance
    const insertAtEnd = !(
      nodeDistances.filter((dist: number) => dist <= ignoreNodeOffset).length >
      0
    );

    // remember all elements and use the new variable to
    // change positions, move edges, etc. - the whole list
    // is updated at the end of the function
    let updatedNodes = nodes;
    let updatedEdges = edges;

    // if we insert at the end, do not move any existing nodes
    if (!insertAtEnd) {
      // ! 2) extension of template in y-coordinates
      // elementOnTopPosition = top position
      // nodes = list of nodes in template

      const minY = templateNodes.reduce(
        (min: number, node: Node) => (min = Math.min(node.position.y, min)),
        1000000
      );

      const maxY = templateNodes.reduce(
        (max: number, node: Node) => (max = Math.max(node.position.y, max)),
        -1000000
      );
      const requiredYExtentForTemplate = Math.abs(maxY - minY);

      // as we calculate only the extent based on left upper coords,
      // we have to add some offset. Try the highest element of the
      // current elements in addition to the extent
      const offsetHeighthighestNode = templateNodes.reduce(
        (max: number, node: Node) =>
          (max = node.height ? Math.max(node.height, max) : max),
        100
      );

      // ! 3) move existing nodes down
      updatedNodes = moveNodes(
        nodes,
        requiredYExtentForTemplate + offsetHeighthighestNode + 75,
        nodeDistances,
        ignoreNodeOffset
      );

      // ! 4) remove all incoming edges of highest node moved
      // the highest node should be the closest

      const minDistance = nodeDistances.reduce(
        (min: number, val: number) => (min = Math.min(val, min)),
        1000000
      );

      const minNodeIndex = nodeDistances.findIndex(
        (element) => element === minDistance
      );

      const minNodeId = currentnodes[minNodeIndex].id;

      // check all edges that have target = minNodeId and remove them
      updatedEdges = updatedEdges.filter(
        (edge: Edge<any>) => edge.target.toString() !== minNodeId
      );
    }

    // add new elements incl. all modifications
    // updateELements in addElementstoGraph
    //const { newTranslationKeys } =// needed for updateModuleWithTemplateTranslations
    addElementsToGraph(
      template.buildergraph,
      (updatedNodes as ReactFlowElements).concat(updatedEdges),
      templateStateMachineTranslations,
      {
        x: projectedCursorPosition.x,
        y: projectedCursorPosition.y,
      } as XYPosition
    );

    autoSave(module.moduleid);

    /* PROD-1345 - add other translations in background

    In the previous steps, we added only the current language to display it 
    on the graph. By autosave, this translation is also saved. If the module
    has several translations and the template is available for these languages,
    the translations should also be added to the module.
    This is done by the following function!

    This call was removed Mae, 09.02.2023 because of PROD-1760
    We now use deepl for auto-translate.

    TODO integrate again?
    
    updateModuleWithTemplateTranslations(template.moduleid, newTranslationKeys);
    */

    // reset template
    event.dataTransfer.setData('application/reactflow/template', '');
  }; // onDropTemplate

  /**
   * onDragOver
   * Handler to prevent default behaviour of onDragOver.
   * Look at onDrop handler for dropping events.
   */
  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  /**
   * onNodesConnect
   * Handles connect of two nodes by a new edge and checks whether this operation is allowed
   */
  const onNodesConnect = (params: Connection) => {
    const edgeParams = params as Edge<any>;
    edgeParams.markerEnd = 'arrowclosed';
    edgeParams.type = 'smoothstep';

    // check whether source handle already has an edge. If yes, do not add connection
    const handleHasAlreadyOutgoingEdge = edges.reduce(
      (hasnode: boolean, edge: Edge<any>) =>
        (hasnode =
          hasnode ||
          (edge.source === edgeParams.source &&
            edge.sourceHandle === edgeParams.sourceHandle)),
      false
    );

    // there is already an outgoing edge, do not do anything
    if (handleHasAlreadyOutgoingEdge) {
      showHint(moduleCanvasMessages.alertnumedges, 'warning');
      return;
    }

    // loop handles can't be connected with other nodes than loop nodes
    if (
      (params.sourceHandle === 'loopout' && params.targetHandle !== 'loopin') ||
      (params.sourceHandle !== 'loopout' && params.targetHandle === 'loopin')
    ) {
      showHint(moduleCanvasMessages.alterloopconnect, 'warning');
      return;
    }

    // if both handles are for a loop, adapt edge
    if (params.sourceHandle === 'loopout' && params.targetHandle === 'loopin') {
      edgeParams.label = 'Loop';
      edgeParams.labelStyle = evoachDefaultTheme.typography.body2;
      edgeParams.type = 'smoothstep';
      edgeParams.animated = true;
      edgeParams.markerEnd = 'arrowclosed';
      edgeParams.style = { stroke: evoachDefaultTheme.palette.secondary.light };

      // connect nodes via loopName
      connectLoopElementsByEdge(params as Edge<any>, nodes);
    } else {
      edgeParams.type = 'buttonedge';
    }

    //
    // Do not allow to connect a node with itself.
    // If we would allow this the user couldn't remove the edge any more and
    // couldn't connect the node again.
    // TODO It may happen that we have to allow this once, but then we should
    // TODO explicitely add certain exceptions here.
    if (params.source === params.target) {
      showHint(moduleCanvasMessages.alerttargetsource, 'warning');
      return;
    }

    const snode = findNodeByNodeId(params.source, nodes);
    const tnode = findNodeByNodeId(params.target, nodes);

    //
    // if not a loop element, check whether the element is within a loop
    //
    // set an "inLoop" flag to indicate, target /source element is in loop
    // add to global list of available variables for getValueFrom
    // in GetValueFromSelect.tsx
    if (params.sourceHandle !== 'loopin' && params.sourceHandle !== 'loopout') {
      if (
        snode &&
        snode.data.state.entry[0].nodeType !== loopStartStateEntry.nodeType
      ) {
        snode.data.state.entry[0].payload.inLoop = isWithinLoopByNodeId(
          params.source,
          nodes,
          edges
        );
      }
    }

    if (params.targetHandle !== 'loopin' && params.targetHandle !== 'loopout') {
      if (
        tnode &&
        tnode.data.state.entry[0].nodeType !== loopEndStateEntry.nodeType
      ) {
        tnode.data.state.entry[0].payload.inLoop = isWithinLoopByNodeId(
          params.target,
          nodes,
          edges
        );
      }
    }

    // build elements to be updated (inLoop flags + nbew edge)
    const edgesToBeUpdated = addEdge(edgeParams, edges);

    let nodesToBeUpdated: Node<any>[] = nodes;
    // nodes touched by edge
    if (snode) {
      nodesToBeUpdated = nodesToBeUpdated
        .filter((element: any) => element.id !== snode.id)
        .concat(snode as Node<any>);
    }
    if (tnode) {
      nodesToBeUpdated = nodesToBeUpdated
        .filter((element: any) => element.id !== tnode.id)
        .concat(tnode as Node<any>);
    }
    // update
    updateElements(
      (edgesToBeUpdated as ReactFlowElements).concat(nodesToBeUpdated)
    );
    autoSave();
  };

  //
  // onNodeDragStop triggers autosave
  //
  // the actual movement of nodes is handled by 'position' type updates
  // in onNodesChange. When the change is stable, onNodeDragStop is triggered
  // by React Flow ==> this means autoSave().
  //
  const onNodeDragStop = (_event: any, _node: Node<any>) => {
    setAnyChangeUndo(true);
    autoSave();
  };

  //
  // onEdgeClick => remove edge
  //
  const onEdgeClick = (
    _changes: React.MouseEvent<Element, MouseEvent>,
    clickedge: Edge<any>
  ) => {
    // if the edge connects two loop end points, we do not delete
    if (clickedge.sourceHandle === 'loopout') return;

    // otherwise, delete
    updateEdges(
      edges.filter((edge) => edge.id !== clickedge.id),
      true
    );
    autoSave();
  };

  /**
   * translate a browser coordinate to the corresponding underlying
   * react-flow canvas coordinate.
   *
   * @param {number | undefined} clientx position in browser, defaults to 400
   * @param {number | undefined} clienty position in browser, defaults to 400
   */
  const getFlowChartCoord = useCallback(
    (clientx: number = 400, clienty: number = 400) => {
      //const [tx, ty, ts] = store.getState().transform;
      const [tx, ty, ts] = reactFlowStoreApi.getState().transform;
      const flowRect = flowRef.current?.getBoundingClientRect() || {
        left: 0,
        top: 0,
      };
      return {
        x: (clientx - flowRect.x - tx) / ts,
        y: (clienty - flowRect.y - ty) / ts,
      } as XYPosition;
    },
    [reactFlowStoreApi]
  );

  /** state that defines whether the context menu is shown or not */
  const [showContextMenu, setShowContextMenu] =
    useState<ModuleEditorContextMenuDisplayProps>({
      x: 0,
      y: 0,
      show: false,
    });

  /** handler for the contextMenu event of the browser
   * this is only considered within the react flow canvas
   */
  const contextHandler = (event: any) => {
    // it is very important to prevent the canvas from acting with default context menu
    event.preventDefault();
    setShowContextMenu({
      x: event.clientX,
      y: event.clientY,
      show: true,
    });
  };

  /** click on canvas closes context menu if open, does nothing otherwise */
  const onClick = useCallback(() => {
    if (showContextMenu.show) {
      setShowContextMenu({
        ...showContextMenu,
        show: false,
      });
    }
  }, [showContextMenu, setShowContextMenu]);

  /**
   * addObjects
   * add objects gets a list of react flow elements and add them to the
   * list of current elements - similar to @see onDropTemplate
   * If no coordinates are provided (e.g. when duplicating a node)
   * then the position of the first node in globalCopyObjects is used.
   * If coordinates are provided, they must be screen coordinates not
   * React Flow Chart coordinates!
   */
  const addObjects = useCallback(
    (
      x?: number,
      y?: number
    ): {
      buildergraph: ReactFlowElements;
      newTranslationKeys: Record<string, string>[];
    } => {
      if (globalCopyObjects !== null && globalCopyObjects !== undefined) {
        let addPosition: XYPosition;
        // check whether coordinates are provided or not.
        if (x === undefined || y === undefined) {
          const firstnode = globalCopyObjects.filter(isNode)[0];
          if (firstnode) {
            addPosition = firstnode.position;

            addPosition.x = addPosition.x + 30;
            addPosition.y = addPosition.y + 30;
          } else {
            // if no coordinates are provided and no node to-be-copied can be found,
            // we fall back to a click coordinate of 400, 400 on the screen. It should
            // lay somewhere in the canvas
            addPosition = getFlowChartCoord(400, 400);
          }
        } else {
          // coordionates provided have to be screen coordinates!
          addPosition = getFlowChartCoord(x, y);
        }

        // use existing stateMachineTranslation:
        // - after "copy" => translations = undefined means "existing translations"
        // - after "cut" => translations = globalCopyObjectsTranslations means
        // "remembered translations after removeObjects"
        return addElementsToGraph(
          removeLooseEdges(cloneDeep(globalCopyObjects)),
          resetSelectedElements(getElements()),
          globalCopyObjectsTranslations ?? undefined,
          addPosition
        );
      }
      return { buildergraph: [], newTranslationKeys: [] };
    },
    [addElementsToGraph, getElements, getFlowChartCoord]
  );

  /** if clipboard data is a list of strings, than paste coachMessages
   */
  const createElementsByClipboardData = useCallback(
    (strings: string[], pastePosition: XYPosition = { x: 0, y: 0 }) => {
      const untranslatedStateNodes = getNodesByTexts(strings);

      let specialOffSet = 0;
      const newElements = untranslatedStateNodes
        .map((stateNode: StateNode, index: number) => {
          // prepare translations
          const stateEntry = stateNode.data?.state.entry[0];

          if (stateEntry) {
            //prepare positions
            stateNode.position.x = pastePosition.x;
            stateNode.position.y =
              pastePosition.y + index * 150 + specialOffSet;

            if (
              ['needsInputStateEntry', 'emotionsInputStateEntry'].includes(
                stateEntry?.nodeType ?? ''
              )
            ) {
              specialOffSet = specialOffSet + 500;
            }

            if (
              [
                'aiCoachMessageStateEntry',
                'coachMessageStateEntry',
                'noteStateEntry',
              ].includes(stateEntry?.nodeType ?? '') &&
              (stateEntry.payload.message ?? '').length > 100
            ) {
              specialOffSet = specialOffSet + 50;
            }

            initAndSetElementTranslationsForSingleElement(stateEntry);
          }

          return stateNode;
        })
        .flat();

      // if there are at least 2 nodes, also add egdes
      const newEdges = newElements.length > 1 ? getEdges(newElements) : [];

      return (newElements as ReactFlowElements).concat(newEdges);
    },
    [initAndSetElementTranslationsForSingleElement]
  );

  /**
   * pasteOperation
   *
   * Contains two different paste operations.
   * 1) if globalCopyObjects is filled by a previous copy/cut operation, this
   *    content is pasted.
   * 2) if globalCopyObjects is empty, the clipboard is checked for text. If
   *    text is available, the "paste magic" is initiated
   *
   * @param {ClipboardEvent | undefined} event if function is called by CMD+V; undefined if called via context menu
   * @param {number} xpos (default 400). Dedicated paste position, e.g. defined by context menu
   * @param {number} ypos (default 400). Dedicated paste position, e.g. defined by context menu
   *
   */

  const pasteOperation = useCallback(
    (
      event: any | undefined,
      xpos: number | undefined,
      ypos: number | undefined
    ) => {
      // TODO if we have a CMD+P event, we can derive the paste position from
      // the current react flow center ...
      // nothing in own clipboard ==> start pasing real clipboard data
      // calc paste position or take 400,400 on screen - should be
      // somewhere on the left hand side of the visible canvas.

      ypos = event && event.clientY ? event.clientY : ypos ?? 400;
      xpos = event && event.clientX ? event.clientX : xpos ?? 400;
      const position = getFlowChartCoord(xpos, ypos);

      l('on Paste handler triggered');
      // this code is the same as in contextMenuActionHandler('paste)
      // I duplicated it because calling this other function
      // caused issues with useCallb ack and states.
      try {
        l('on Paste handler, globalCopyObjects: ');
        l(globalCopyObjects);
        // if there is anything in our own clipboard, use this for paste
        if (
          globalCopyObjects !== null &&
          globalCopyObjects !== undefined &&
          globalCopyObjects.length > 0
        ) {
          // simulate click position 400, 400
          const { buildergraph: newObjects } = addObjects(xpos, ypos);
          // reset own clipboard
          globalCopyObjects = undefined;
          // preselect newly pasted objects
          if (newObjects) setSelectedElements(newObjects);
          // return!! do not proceed with browser clipboard
          return;
        }

        // globalCopyObjects is undefined and event === undefined, then
        // this was triggered by the context menu and the clipboard contains
        // text. PROD-1807

        /** get
         * .type =
         * text/plain , text/html, text/rtf, image/png, image/jpeg, etc.
         * also available: .kind = string, image, etc. */

        const allowedItemTypes = getAllowedItemTypes(event);

        // if text is one of the allowed options, try to find all rows of a text
        if (
          allowedItemTypes !== null &&
          allowedItemTypes.includes('text/plain')
        ) {
          l('on Paste handler, get clipboard data for new object');
          // split by end of line and filter by non-empty string
          getClipboardAsTexts(event).then((items: string[]) => {
            if (items.length > 0) {
              // add a coach message for each line and connect these messages
              const newElements = cloneDeep(
                createElementsByClipboardData(items, position)
              );
              if (newElements.length > 0) {
                //newElements contains  nodes and edges
                updateElements(getElements().concat(newElements));
                setSelectedElements(newElements);
                globalCopyObjects = undefined;
              }
            }
          });
        } else {
          l('onPaste event. AllowdItemtypes:');
          l(allowedItemTypes);
        }
      } catch (_e) {
        // do nothing if an error occurs
        l(_e);
      }
      return;
    },
    [
      addObjects,
      createElementsByClipboardData,
      getElements,
      getFlowChartCoord,
      l,
      updateElements,
    ]
  );
  /**
   * the onCopy event copies currently selected React Flow elements to out own copy storage
   * we do not copy ito the clipboard as we want to handle the data by the context menu also,
   * and we don't have access to the clipboard from a self-written paste function
   * can't access clipboard directly
   * s. also https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
   * that's why we use our own "clipvoard" => globalCopyObjects
   */
  const onCopy = useCallback(
    (_e: any) => {
      globalCopyObjects = cloneDeep(getSelectedElements(nodes, edges));
      return globalCopyObjects;
    },
    [edges, nodes]
  );

  /** handler for context menu */
  const contextMenuActionHandler = useCallback(
    (eventtype: string) => {
      switch (eventtype) {
        case 'paste':
          l('context menu paste');
          pasteOperation(undefined, showContextMenu.x, showContextMenu.y);
          globalCopyObjects = undefined;
          autoSave();
          break;
        case 'copy':
          l('context menu copy');
          onCopy(null);
          break;
        case 'duplicate':
          l('context menu duplicate');
          // duplicate is used by node header menu (@see base.nodemenu.tsx)
          onCopy(null);
          const { buildergraph: newBuilderGraph } = addObjects();
          // reset own clipboard
          globalCopyObjects = undefined;
          // reset selections
          resetSelectedElements(getSelectedElements(nodes, edges));
          // preselect newly pasted objects
          if (newBuilderGraph && newBuilderGraph.length > 0)
            setSelectedElements(newBuilderGraph);
          autoSave();
          break;
        case 'cut':
          l('context menu cut');
          onCopy(null);
          // onCutElements has to post-process selection
          onCutElements(getSelectedElements(nodes, edges));
          autoSave();
          break;
        case 'undo':
          l('context menu undo');
          undoElements();
          autoSave();
          break;
        default:
          l('context menu unkown: ' + eventtype);
          break;
      }
      setShowContextMenu({
        ...showContextMenu,
        show: false,
      });
    },
    [
      addObjects,
      edges,
      l,
      nodes,
      onCopy,
      onCutElements,
      pasteOperation,
      showContextMenu,
      undoElements,
    ]
  );

  /**
   * handler for each node - passed by context provider for all react-flow nodes
   * moves down all nodes below a certain node.
   *
   * @param {string} nodeid - id of the first node to move (and all nodes below)
   * @param {number} movingOffsetY - offset how many pixels to move, default = 150. If positive, moves down, if negative, moves up
   *
   */
  const onPushNode = useCallback(
    (
      nodeid: string,
      movingOffsetY: number = 150,
      movingOffsetX: number = 0
    ) => {
      const clickedNode = findNodeByNodeId(nodeid, nodes);
      const ignoreNodeOffset = 10000;
      if (clickedNode) {
        const nodeDistances = getNodeDistances(
          nodes,
          clickedNode.position,
          ignoreNodeOffset
        );
        const updatedNodes = moveNodes(
          nodes,
          movingOffsetY ?? 150,
          nodeDistances,
          ignoreNodeOffset,
          movingOffsetX
        );
        updateNodes(updatedNodes, true);
        autoSave();
      }
    },
    [nodes, updateNodes]
  );

  /**
   * handle key down events
   * do not ad CMD+c or CMD+p as these are handled by explicit copy/paste handlers
   *
   * register a keydown handler to catch several keyboard events like
   * - "ESC" for closing the context menu
   * - "CMD+Z" + "CTRL+Z" for undo
   * - "CMD+P" + "CTRL+P" for paste ==> not handled here!
   * - "CMD+C" + "CTRL+C" for copy ==> not handled here!
   * - "CMD+X" + "CTRL+X" for cut
   * - CMD+V not considered here! Paste event is catched manually
   *
   * The multi selection of nodes is handled by react-flow itself.
   * By multiSelectionKeyCode={['Meta', 'Control']}
   */
  const onKeyDown = useCallback(
    (event: any) => {
      l('onKeyDown with ' + event.key);

      if (
        event.key.toLowerCase().startsWith('arrow') &&
        (event.ctrlKey || event.metaKey)
      ) {
        const selNodes = getSelectedElements(nodes, edges);
        if (selNodes.length > 0) {
          onPushNode(
            selNodes[0].id,
            event.key === 'ArrowUp'
              ? -150
              : event.key === 'ArrowDown'
              ? 150
              : 0,
            event.key === 'ArrowLeft'
              ? -150
              : event.key === 'ArrowRight'
              ? 150
              : 0
          );
        }
        event.preventDefault();
      }

      // close context menu if open
      if (event.key.toLowerCase() === 'escape') {
        onClick();
      }

      // save module
      if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
        setAutoSaveTriggered(false);
        saveModuleHandler();
        event.preventDefault();
      }
      // cut selected elements
      if (event.key === 'x' && (event.ctrlKey || event.metaKey)) {
        if (
          event.target &&
          event.target.id &&
          (event.target.id + '').startsWith('donotcatchpasteevent_')
        ) {
          return;
        }
        contextMenuActionHandler('cut');
        event.preventDefault();
      }

      // undo last operation
      if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        if (
          event.target.id &&
          (event.target.id + '').startsWith('donotcatchpasteevent_')
        ) {
          return;
        }
        undoElements();
        resetSelectedElements((nodes as ReactFlowElements).concat(edges));
        autoSave(module.moduleid);
        event.preventDefault();
      }

      // paste selected elements
      if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
        if (
          event.target.id &&
          (event.target.id + '').startsWith('donotcatchpasteevent_')
        ) {
          return;
        }
        //
        // ! important! This code covers a paste event for keyDown not for onPaste.
        // it is normally not executed, only by the synthetic event triggerd
        // in fuction pasteElements() in ModuleEditor
        //
        // if you click STRG+V or CMD+V in browser, an onPaste event is fired
        // which is handled independently of the keydown event. It has higher
        // priority then this keydown handler.
        // But if we trigger a synthetic keydown event as in pasetElements in
        // ModuleEditor, onPaste is not triggered, but this one!
        //

        //
        // trigger if there is no globalcopyobject
        // for text, only
        //
        if (globalCopyObjects === undefined) {
          // PROD-1932 - Firefox does not know navigator.clipboard.readText
          if (
            !(navigator && navigator.clipboard && navigator.clipboard.readText)
          ) {
            alert(
              'Functionality is not supported in your browser (onKeyDown, mep)'
            );
            return;
          }

          // get items from clipboard
          const items = navigator.clipboard.readText();

          // wait for the promise
          items.then((itemsl: string) => {
            if (!itemsl) return;

            // split the clipboard into several lines
            const items = itemsl
              .split('\n')
              .map((res: string) => res.split('\r'))
              .flat()
              .filter((s: string) => s !== '');

            // if items is not an array, return
            if (!Array.isArray(items)) return;

            // paste somewhere in visible canvas
            const position = getFlowChartCoord(400, 400);

            if (items.length > 0) {
              // add a coach message for each line and connect these messages
              const newElements = cloneDeep(
                createElementsByClipboardData(items, position)
              );
              if (newElements.length > 0) {
                //newElements contains  nodes and edges
                updateElements(getElements().concat(newElements));
                setSelectedElements(newElements);
                globalCopyObjects = undefined;
              }
            }
          });
        }
      }

      // copy selected elements
      if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
        if (
          event.target.id &&
          (event.target.id + '').startsWith('donotcatchpasteevent_')
        ) {
          return;
        }
        // important! This code covers a copy event for keyDown.
        // it should never be executed (!) because the copy event is
        // catched independently of the keyDown event by a dedicated
        // onCopy event listener.
        // contextMenuActionHandler('copy');
        // ! do not copy here as this leads to unexpected behaviour
      }
    },
    [
      l,
      nodes,
      edges,
      onPushNode,
      onClick,
      saveModuleHandler,
      contextMenuActionHandler,
      undoElements,
      module.moduleid,
      alert,
      getFlowChartCoord,
      createElementsByClipboardData,
      updateElements,
      getElements,
    ]
  );
  /**
   * onPaste handler to prevess event
   *
   * on paste event => handle different data types
   * note: eslint disabled for dependency array => how can we do better?
   *
   * After migrating to ReactFlow 10 and React 18, this handler doesn't seem
   * to fire any more and I don't know the reason. I leave it for reference
   * until we are sure that this is not causing any other effects ( Mae, 23.05.2022 )
   * No matter whether thios event handler triggers or the
   * contextMenuActionHandler('paste), the pasteOperation() call will be triggered
   * but potentially with different parameters
   */

  const onPaste = useCallback(
    (event: any) => {
      if (
        event.target.id &&
        (event.target.id + '').startsWith('donotcatchpasteevent_')
      ) {
        return;
      }

      pasteOperation(event, undefined, undefined);
      globalCopyObjects = undefined;
    },
    [pasteOperation]
  );

  //
  // onKeyDown event listener
  //
  // register a keydown handler to catch several keyboard events like
  // Add event listener if component is mounting and remove it if component is un-mounted
  //
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  //
  // onPaste event listener - handles "paste" events
  //
  // adding the onCopy event to a div or ReactFlow directly is not working
  // s. also // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
  useEffect(() => {
    window.addEventListener('paste', onPaste);
    return () => {
      window.removeEventListener('paste', onPaste);
    };
  }, [onPaste]);

  //
  // onCopy event listener handles "copy" events
  //
  useEffect(() => {
    window.addEventListener('copy', onCopy);
    return () => {
      window.removeEventListener('copy', onCopy);
    };
  }, [onCopy]);

  // remember whether alert is open
  const [alertMessage, setAlertMessage] = useState<any>({
    id: '',
    defaultmessage: '',
  });
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('info');
  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(false);
  const onAlertClose = () => setAlertMessageOpen(false);

  /** display an alert for on top of editor canvas */
  const showHint = async (message: any, localseverity: AlertColor) => {
    setAlertMessage(message);
    setAlertSeverity(localseverity);
    setAlertMessageOpen(true);
  };

  /** click on mini map centers the element in canvas and preserves zoom */
  const onMiniMapClick = (nodePosition: XYPosition) => {
    const [, , ts] = reactFlowStoreApi.getState().transform;
    setCenter(nodePosition.x, nodePosition.y, { zoom: ts });
  };

  /** handle commands passed from parent component, e.g. by voice input */

  // save command in state as we want to reset state after interpreting the
  // command. If we do not rest the command passed as prop, we have an
  // infinite loop in rendering the hook below
  const [passedCommand, setPassedCommand] = useState<string | undefined>(
    command
  );

  const [commandCounter, setCommandCounter] = useState<number>(0);

  // set the command in state when a new command is passed via props
  useEffect(() => {
    setPassedCommand(command);
  }, [command, setPassedCommand]);

  // actually interpreting the voice command
  useEffect(() => {
    if (passedCommand === undefined) return;

    const currentPastePosition: XYPosition = getFlowChartCoord(400, 400);
    currentPastePosition.y = currentPastePosition.y + commandCounter * 150;

    const newElements = cloneDeep(
      createElementsByClipboardData([passedCommand], currentPastePosition)
    );
    if (newElements.length > 0) {
      //console.log(elements.concat(newElements).concat(newEdges));
      //newElements contains  nodes and edges
      updateElements(getElements().concat(newElements));
      setSelectedElements(newElements);
      globalCopyObjects = undefined;
      setPassedCommand(undefined);
      setCommandCounter(commandCounter + 1);
    }
  }, [
    passedCommand,
    setPassedCommand,
    createElementsByClipboardData,
    updateElements,
    getFlowChartCoord,
    setCommandCounter,
    commandCounter,
    getElements,
  ]);

  const onSelectionDragStart = (event: any) => {
    // prevent the event from affecting other components
    event.stopPropagation();
    event.preventDefault();
  };
  //
  // onCanvasMove - ReactFLow Canvas is moved or zoomed.
  // Use the information when storing a module. Then reset module editor view
  // when reloading a module
  // s. https://reactflow.dev/docs/api/react-flow-props/#pane
  //
  const onCanvasMove = (_event: any, viewport: Viewport) => {
    updateViewport(viewport);
  };

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const maxHeight = vh - 143 + 'px';

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'block',
          float: 'left',
          height: maxHeight,
          border: 'solid 1px #CCCCCC',
        }}
      >
        <ReactFlowNodeEventContext.Provider
          value={{
            onCutNode: (__nodeid: string) => {
              contextMenuActionHandler('cut');
            },
            onCopyNode: (__nodeid: string) => {
              contextMenuActionHandler('copy');
            },
            onPushNode: (_nodeid: string, offset: number) =>
              onPushNode(_nodeid, offset),
            onDuplicateNode: (_nodeid: string) =>
              contextMenuActionHandler('duplicate'),
            displayMenu: true,
          }}
        >
          <ReactFlow
            ref={flowRef}
            style={{ minHeight: '700px' }}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onDrop={onDrop}
            onNodeDragStop={onNodeDragStop}
            onConnect={onNodesConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onContextMenu={contextHandler}
            onEdgeClick={onEdgeClick}
            onCopy={onCopy}
            onClick={onClick}
            onInit={onInit}
            onDragOver={onDragOver}
            onMove={onCanvasMove}
            connectOnClick
            onSelectionDragStart={onSelectionDragStart}
            multiSelectionKeyCode={['Meta', 'Control']}
          >
            <Controls />
            <Background variant={BackgroundVariant.Dots} gap={30} size={1} />
            <MiniMap
              onClick={(_, coordinates: XYPosition) =>
                onMiniMapClick(coordinates)
              }
              nodeColor={(node) => node.data.nodeMiniMapColor ?? '#CCCCCC'}
              nodeStrokeWidth={10}
              maskStrokeColor="black"
              maskStrokeWidth={10}
              pannable
              zoomable
            />
          </ReactFlow>
        </ReactFlowNodeEventContext.Provider>
        <Snackbar
          open={alertMessageOpen}
          autoHideDuration={
            (alertMessage.defaultMessage
              ? (alertMessage.defaultMessage as string).length * 25
              : 0) + 1300
          }
          onClose={onAlertClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity={alertSeverity}>
            <AlertTitle>
              <FormattedMessage {...moduleCanvasMessages.canvastitle} />
            </AlertTitle>
            <p>
              <FormattedMessage {...alertMessage} />
            </p>
          </Alert>
        </Snackbar>

        <ModuleEditorContextMenu
          show={showContextMenu}
          onClick={contextMenuActionHandler}
          clipboardFilled={!!globalCopyObjects}
        />
      </div>
    </div>
  );
};
