import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.scaleInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
  scaleName: {
    id: 'builder.nodes.scaleInput.state.scaleName',
    defaultMessage: 'Titel für 10er Skala',
  },
});

/**
 * v1 = initial version
 * v2 = support for getStringValues
 */
export const scaleInputStateEntry: StateEntryProps = {
  version: 'v2',
  type: 'renderScaleInput',
  temporary: true,
  payload: {
    buttonText: 'builder.nodes.scaleInput.state.buttonText',
    scaleName: 'builder.nodes.scaleInput.state.scaleName',
    saveResultTo: 'scale_result',
    scaleSize: 10,
    scale: 5,
    getValueFrom: '',
    getStringValues: [''],
  },
  saveResultType: ReadWriteType.number,
  getValueType: ReadWriteType.number,
  displayName: 'Scale Input Single',
  nodeType: 'scaleInputStateEntry',
  handleOutCount: 1,
};
