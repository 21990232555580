import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  helpertext: {
    id: 'builder.nodes.coachMessage.state.helpertext',
    defaultMessage: 'Diese Ausgabe wurde von einer KI-Funktion generiert.',
  },
  d: {
    id: 'aiCoachMessageStateEntry.description',
    defaultMessage:
      'Diese Komponente ist eine spezielle Coach Message, die verwendet werden sollte, wenn der Inhalt von einer KI erzeugt wurde und etwa über eine Variable hinzugefügt wird.',
  },
  showAiBadge: {
    id: 'aiCoachMessageStateEntry.showAiBadge',
    defaultMessage: 'showAiBadge schaltet den "AI" Hinweis ein.',
  },
  helperText: {
    id: 'aiCoachMessageStateEntry.helperText',
    defaultMessage:
      'Der helperText wird angezeigt, wenn man den Mauszeiger über den "AI" Hinweis bewegt.',
  },
});

/**
 * same as CoachMessage but with activated AI parameters
 *
 * s. also CoachMessage.state.tsx
 */
export const aiCoachMessageStateEntry: StateEntryProps = {
  version: 5,
  type: 'renderCoachMessage',
  temporary: false,
  payload: {
    message: 'builder.nodes.coachMessage.state.message',
    getValuesFrom: [''],
    getStringValues: [''],
    showAiBadge: true,
    helperText: 'builder.nodes.coachMessage.state.helpertext', // if showAiBadge is true, this helperText is displayed as tooltip
  },
  getValueType: [
    ReadWriteType.string,
    ReadWriteType.stringArray,
    ReadWriteType.typedObject,
  ],
  displayName: 'Coach Message (AI)',
  nodeType: 'aiCoachMessageStateEntry',
  handleOutCount: 1,
};
