import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.percentageScaleInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
  scaleName: {
    id: 'builder.nodes.percentageScaleInput.state.scaleName',
    defaultMessage: 'Titel für 100% Skala',
  },
});

export const percentageScaleInputStateEntry: StateEntryProps = {
  version: 'v2',
  type: 'renderPercentageScaleInput',
  temporary: true,
  payload: {
    buttonText: 'builder.nodes.percentageScaleInput.state.buttonText',
    scaleName: 'builder.nodes.percentageScaleInput.state.scaleName',
    saveResultTo: 'scale_result',
    scale: 50,
    getValueFrom: '',
    getStringValues: [''],
  },
  saveResultType: ReadWriteType.number,
  getValueType: ReadWriteType.number,
  displayName: 'Percentage Scale Input',
  nodeType: 'percentageScaleInputStateEntry',
  handleOutCount: 1,
};
