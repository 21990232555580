import { EvoachButton, evoachDefaultTheme } from '@evoach/ui-components';
import { Edit, Error } from '@mui/icons-material';
import {
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountContext } from '../account';
import { useEvoachKeycloak } from '../account/useEvoachKeycloak';
import { authorizedPost } from '../api';
import {
  CountrySelect,
  CountryType,
} from '../components/Profile/CountrySelect';
import { WindowContext } from '../components/window/WindowContext';
import { SubscriptionType } from '../entities/subscriptionTypes';
import { useEnvironment } from '../environment/useEnvironment';
import { AppRoutes } from '../routing/routes';

export enum OfferEnum {
  NONE = 0,
  STARTER = 1,
  VIP = 2,
  BASIC = 3,
  GROW = 4,
}

export interface SubscriberInfo {
  firstName: string;
  lastName: string;
  streetAddress: string;
  postCode: string;
  country: CountryType | null;
  city: string;
  mail: string;
  phone: string;
  offer: OfferEnum;
  vat: string;
  companyName: string;
  termsAccepted: boolean;
  promocode: string;
}

const tiers = [
  {
    title: 'Starter Package "AI Coach"',
    price: '599',
    offer: OfferEnum.STARTER,
    description: [
      'Setup fee + 6 months licence package *',
      'AI Coach "Prepare- and Follow-Up"',
      'Access to all other chatbot topics',
      'Unlimited chatbot creation',
      'Website Integration & Email invites',
    ],
    subText: '* after  6 months, a 29€ monthly fee applies',
    buttonText: 'Select',
    buttonVariant: 'contained',
  },
  {
    title: 'Your VIP "AI Coach"',
    price: '3.999',
    offer: OfferEnum.VIP,
    description: [
      'Setup fee + 6 months licence package *',
      'All benefits from Starter Package',
      'Development and integration of your specific',
      'AI coaching approach based on custom prompts',
      'Personal support',
    ],
    subText: '* after  6 months, a 99€ monthly fee applies',

    buttonText: 'Select',
    buttonVariant: 'contained',
  },
];

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '4px',
  p: 4,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
};

const reducer = (state: SubscriberInfo, action: SubscriberInfoAction) => {
  return { ...state, [action.type]: action.payload };
};

type SubscriberInfoAction = {
  type: keyof SubscriberInfo;
  payload: string | boolean | number | CountryType;
};

const getOfferTitle = (offer: OfferEnum) => {
  const selectedTier = tiers.filter((tier) => tier.offer === offer)?.[0];
  return selectedTier?.title ?? undefined;
};
const getOfferPrice = (offer: OfferEnum) => {
  const selectedTier = tiers.filter((tier) => tier.offer === offer)?.[0];
  return selectedTier?.price ?? undefined;
};

// Page
export const AICoachSubscriptionPage = () => {
  const { name, email, account } = useContext(AccountContext);
  const { isMobile, clientWidth } = useContext(WindowContext);
  const { builderBasePath } = useEnvironment();

  const separatedNames = name.split(' ');
  const initialState: SubscriberInfo = {
    firstName: separatedNames.length === 2 ? separatedNames[0] : '',
    lastName: separatedNames.length === 2 ? separatedNames[1] : name,
    mail: email,
    phone: '',
    city: '',
    streetAddress: '',
    postCode: '',
    country: null,
    companyName: '',
    vat: '',
    offer: OfferEnum.NONE,
    termsAccepted: false,
    promocode: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  //
  // states used to organize checkout process
  //
  const [isOfferSelectedAtLeastOnce, setOfferSelectedAtLeastOnce] =
    useState(false);
  const [isAddressEnteredOnce, setIsAddressEnteredOnce] = useState(false);
  const [isAddressBeingEdited, setIsAddressBeingEdited] = useState(false);
  const [isSubscriberInfoComplete, setIsSubscriberInfoComplete] =
    useState(false);
  const [isCheckoutSuccessful, setIsCheckoutSuccessful] = useState(false);
  const [isOrderinProgress, setIsOrderInProgress] = useState(false);

  const addressRef = useRef<null | HTMLDivElement>(null);
  const checkoutRef = useRef<null | HTMLDivElement>(null);
  const { isAuthenticated } = useEvoachKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    const subscriberInfoComplete =
      state.firstName !== '' &&
      state.lastName !== '' &&
      state.mail !== '' &&
      state.phone !== '' &&
      state.city !== '' &&
      state.streetAddress !== '' &&
      state.postCode !== '' &&
      state.country !== null;

    setIsSubscriberInfoComplete(subscriberInfoComplete);
  }, [state]);

  useEffect(() => {
    const scrollIntoView = () => {
      if (addressRef && addressRef.current) {
        addressRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    };

    const timeoutId = setTimeout(scrollIntoView, 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [addressRef, state.offer, isOfferSelectedAtLeastOnce]);

  useEffect(() => {
    const scrollIntoView = () => {
      if (checkoutRef && checkoutRef.current) {
        checkoutRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    };

    const timeoutId = setTimeout(scrollIntoView, 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [checkoutRef, isAddressEnteredOnce]);

  const handleChange = (
    type: keyof SubscriberInfo,
    value: string | boolean | number | CountryType
  ) => {
    if (type === 'offer') {
      setOfferSelectedAtLeastOnce(true);
    }
    dispatch({
      type: type,
      payload: value,
    });
  };

  const completeOrder = async () => {
    if (!account || !account.accountid) return;

    setIsOrderInProgress(true);

    const planLoad = {
      targetaccountid: account.accountid,
      subscriptioninfo: {
        type:
          // TODO: move logic to backend
          state.offer === OfferEnum.STARTER
            ? SubscriptionType.STARTER
            : SubscriptionType.BASIC,
        ...state,
        offerTitle: getOfferTitle(state.offer),
      },
      isselfservice: true,
    };
    const createPlan = authorizedPost('/subscription', planLoad);
    const createResponse = await createPlan();
    if (!createResponse.ok) {
      alert('Beim Erzeugen des Plans ist ein Fehler aufgetreten');
    } else {
      setIsCheckoutSuccessful(true);
    }
    setIsOrderInProgress(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="90vh"
    >
      <Box>
        <Modal open={isCheckoutSuccessful}>
          <Box sx={modalStyle}>
            <Typography variant="h6" marginBottom="1em">
              Thanks for your order!
            </Typography>
            <Box
              marginBottom="1em"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography marginBottom="2em">
                You have now access to:
              </Typography>
              <Typography variant="h6" fontStyle="italic">
                {getOfferTitle(state.offer)}
              </Typography>
              <Box padding="2em 3em">
                <Typography textAlign="center">
                  We will get in touch with you as soon as possible, to arrange
                  your personal onboarding call!
                </Typography>
              </Box>
            </Box>

            <Box alignSelf="center">
              <EvoachButton
                onClick={() => {
                  navigate(`${AppRoutes.RECEPTION}`);
                }}
              >
                Go to chatbots
              </EvoachButton>
            </Box>
          </Box>
        </Modal>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
        />
        <CssBaseline />

        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 3, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Get started!
          </Typography>
          <Grid container display="flex" alignItems="center" spacing={4}>
            <Grid item xs={8} sm={8} md={8}>
              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                component="p"
              >
                Get AIpina, the AI enriched hybrid- coaching chatbot companion,
                for scaling your coaching with your clients.
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <img
                src={`${builderBasePath}/assets/alpina.png`}
                alt=""
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={3} alignItems="flex-end">
            {tiers.map((tier) => (
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={12}
                md={6}
                sx={{ marginBottom: 2 }}
              >
                <Card
                  raised={state.offer === tier.offer}
                  sx={{
                    opacity:
                      state.offer !== tier.offer &&
                      state.offer !== OfferEnum.NONE
                        ? 0.5
                        : 1,
                    pb: 2,
                  }}
                >
                  <CardHeader
                    title={tier.title}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        €{tier.price}
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                      {tier.subText && (
                        <>
                          <br />
                          <Typography
                            component="li"
                            variant="body2"
                            align="center"
                            color="gray"
                          >
                            {tier.subText}
                          </Typography>
                        </>
                      )}
                    </ul>
                  </CardContent>
                  <CardActions
                    sx={{
                      pl: 8,
                      pr: 8,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      fullWidth
                      disabled={
                        tier.offer !== state.offer &&
                        state.offer !== OfferEnum.NONE
                      }
                      variant={tier.buttonVariant as 'outlined' | 'contained'}
                      onClick={() => {
                        if (isAuthenticated) {
                          handleChange(
                            'offer',
                            state.offer === tier.offer
                              ? OfferEnum.NONE
                              : tier.offer
                          );
                        }
                        setIsAddressBeingEdited(
                          isAddressEnteredOnce ? false : true
                        );
                        setOfferSelectedAtLeastOnce(true);
                      }}
                    >
                      {state.offer === OfferEnum.NONE
                        ? tier.buttonText
                        : state.offer === tier.offer
                        ? 'Deselect'
                        : tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
            {isOfferSelectedAtLeastOnce && isAddressBeingEdited && (
              <Grid item xs={12} sm={12} md={12}>
                <Paper
                  sx={{
                    padding: '2em',
                    marginBottom: '1em',
                    transition: 'all .5s ease-in-out',
                  }}
                  elevation={10}
                >
                  {!isAuthenticated ? (
                    <Grid container>
                      <Grid item xs={12} md={6} lg={6}>
                        <Typography>
                          Please login or signup to start with the secure order
                          process &nbsp;&nbsp;
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box paddingTop={isMobile ? '1em' : '0'}>
                          <Button
                            onClick={() => {
                              const loginUrlWithRedirect = `${AppRoutes.LOGIN}?custom_redirect=subscribe`;
                              navigate(loginUrlWithRedirect);
                            }}
                          >
                            Login / Signup
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {isAddressEnteredOnce && !isAddressBeingEdited ? (
                        <></>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={12} paddingBottom="1em">
                            <Typography variant="h6">
                              Invoice Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              id="firstName"
                              label="First name"
                              value={state.firstName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleChange('firstName', e.currentTarget.value)
                              }
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              label="Last name"
                              value={state.lastName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleChange('lastName', e.currentTarget.value)
                              }
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              id="streetAddress"
                              label="Address"
                              value={state.streetAddress}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleChange(
                                  'streetAddress',
                                  e.currentTarget.value
                                )
                              }
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <CountrySelect
                              country={state.country}
                              onCountryChange={(country) =>
                                handleChange('country', country)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              label="Post code"
                              value={state.postCode}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleChange('postCode', e.currentTarget.value)
                              }
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              label="City"
                              value={state.city}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleChange('city', e.currentTarget.value)}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              label="Company name"
                              value={state.companyName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleChange(
                                  'companyName',
                                  e.currentTarget.value
                                )
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              label="VAT"
                              value={state.vat}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleChange('vat', e.currentTarget.value)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              label="Business Email"
                              value={state.mail}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleChange('mail', e.currentTarget.value)}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              id="phone"
                              label="Phone"
                              value={state.phone}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleChange('phone', e.currentTarget.value)}
                              fullWidth
                              type="tel"
                              required
                            />
                          </Grid>{' '}
                          <Grid item xs={12} sm={6} md={6}></Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <TextField
                              id="promocode"
                              label="Promo Code"
                              value={state.promocode}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleChange('promocode', e.currentTarget.value)
                              }
                              sx={{ width: '200px' }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}></Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            display="flex"
                            justifyContent={isMobile ? 'center' : 'flex-end'}
                          >
                            <Tooltip
                              title="Please fill out all required fields / select an offer"
                              placement="top"
                              arrow
                            >
                              <Box>
                                <EvoachButton
                                  disabled={
                                    isOfferSelectedAtLeastOnce &&
                                    !isSubscriberInfoComplete
                                  }
                                  onClick={() => {
                                    setIsAddressEnteredOnce(true);
                                    setIsAddressBeingEdited(false);
                                  }}
                                >
                                  <Typography variant="body1" fontWeight={500}>
                                    {isAddressEnteredOnce &&
                                    isAddressBeingEdited
                                      ? 'Save'
                                      : 'Proceed to checkout'}
                                  </Typography>
                                </EvoachButton>
                              </Box>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </Paper>
                <Box ref={addressRef}></Box>
              </Grid>
            )}

            {isAddressEnteredOnce && (
              <Grid item xs={12} sm={12} md={12}>
                <Paper
                  sx={{
                    padding: isMobile ? '1.5em' : '2em',
                    marginBottom: '1em',
                    transition: 'all .5s ease-in-out',
                  }}
                  elevation={10}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      flexWrap="wrap"
                    >
                      <Grid container display="flex" flexDirection="row">
                        <Grid item xs={4} md={3}>
                          <Typography fontWeight={600}>
                            Invoice Details:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          md={9}
                          display="flex"
                          flexDirection="row"
                        >
                          <Box display="flex" flexDirection="column">
                            <Typography variant="body2">{`${state.firstName} ${state.lastName}`}</Typography>
                            <Typography variant="body2">{`${state.streetAddress}`}</Typography>
                            <Typography variant="body2">{`${state.postCode} ${state.city}`}</Typography>
                            <Typography variant="body2">{`${state.country?.label}`}</Typography>
                            <Typography variant="body2">{`${state.mail}`}</Typography>
                            <Typography variant="body2">{`${state.phone}`}</Typography>
                          </Box>
                          <Box>
                            {!isAddressBeingEdited && (
                              <IconButton
                                onClick={() => setIsAddressBeingEdited(true)}
                                sx={{ marginLeft: isMobile ? '0' : '10px' }}
                              >
                                <Edit />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container display="flex" flexDirection="row">
                        <Grid item xs={4} md={3}>
                          <Typography fontWeight={600}>Offer:</Typography>
                        </Grid>
                        <Grid item xs={8} md={9}>
                          {state.offer !== OfferEnum.NONE ? (
                            <Chip
                              sx={{ typography: 'body2' }}
                              color="secondary"
                              label={`${getOfferTitle(state.offer)}`}
                              onDelete={() =>
                                handleChange('offer', OfferEnum.NONE)
                              }
                            />
                          ) : (
                            <Box display="flex" alignItems="center" gap={1}>
                              <Typography fontWeight={400}>
                                Please select an offer!
                              </Typography>
                              <Error color="warning" />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      {state.offer !== OfferEnum.NONE ? (
                        <>
                          <Divider />
                          <Grid container display="flex">
                            <Grid item xs={4} md={3}>
                              <Typography fontWeight={600}>
                                Promo Code:
                              </Typography>
                            </Grid>
                            <Grid item xs={8} md={9}>
                              {!state.promocode || state.promocode === ''
                                ? 'No code provided.'
                                : state.promocode}
                              {state.promocode ? (
                                <div>
                                  (Will be applied to price after checkout)
                                </div>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                          <Grid container display="flex">
                            <Grid item xs={4} md={3}>
                              <Typography fontWeight={600}>Price:</Typography>
                            </Grid>
                            <Grid item xs={8} md={9}>
                              {`${getOfferPrice(state.offer)}€`}
                            </Grid>
                          </Grid>
                          <Grid container display="flex">
                            <Grid item xs={4} md={3}>
                              <Typography fontWeight={600}>Payment:</Typography>
                            </Grid>
                            <Grid item xs={8} md={9}>
                              Invoice
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      gap={2}
                      flexWrap="wrap"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {clientWidth < 900 ? (
                          <></>
                        ) : (
                          <img
                            src={`${builderBasePath}/assets/alpina.png`}
                            alt=""
                            style={{ width: '70%' }}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Box width="100%" marginTop={2} marginBottom={2}>
                      <Divider />
                    </Box>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Checkbox
                        color="secondary"
                        checked={state.termsAccepted}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange('termsAccepted', e.target.checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ marginRight: '1em' }}
                      />
                      <Typography textAlign="justify" variant="body2">
                        Hereby I accept the &nbsp;
                        <a
                          href="https://www.evoach.com/general-terms-and-conditions"
                          style={{
                            color: evoachDefaultTheme.palette.secondary.main,
                          }}
                        >
                          terms and conditions
                        </a>
                        .
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      display="flex"
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <Box paddingTop="15px">
                        <EvoachButton
                          disabled={
                            state.offer === OfferEnum.NONE ||
                            !state.termsAccepted ||
                            !isSubscriberInfoComplete
                          }
                          onClick={() => completeOrder()}
                        >
                          <Typography variant="h6">Order Now</Typography>
                          {isOrderinProgress && <CircularProgress />}
                        </EvoachButton>
                      </Box>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                </Paper>
                <Box ref={checkoutRef}></Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ typography: 'body2', marginTop: '5em' }}
      >
        <Box marginBottom="7px">
          <img
            src={`${builderBasePath}/logo512.png`}
            alt=""
            height="100px"
          ></img>
        </Box>
        <Box marginBottom="7px">evoach GmbH</Box>
        <Box marginBottom="7px">Rebecca Rutschmann & Anke Paulick</Box>
        <Box>Haid-und-Neu-Straße 7, 76131 Karlsruhe - Germany</Box>
        <Box>Contact: team@evoach.com</Box>
        <Box>
          Imprint:{' '}
          <a href="https://www.evoach.com/imprint">www.evoach.com/imprint</a>
        </Box>
      </Box>
    </Box>
  );
};
