import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';

import { useFetchPrograms } from '../../../api/program/useFetchPrograms';
import { Program, ProgramTypeEnum } from '../../../entities/Program';

interface AssignSubProgramDialogProps {
  isOpen: boolean;
  onClose: Function;
  excludeProgramIds?: string[];
}

export const AssignSubProgramDialog: React.FC<AssignSubProgramDialogProps> = ({
  isOpen,
  onClose,
  excludeProgramIds = [],
}) => {
  const intl = useIntl();

  const [selectedParentProgramId, setSelectedParentProgramId] =
    useState<string>('empty');

  const { programs } = useFetchPrograms(false, true, undefined);

  // from the list of programs, exclude all subprograms, all
  // programs that can't have subprograms and the program that should
  // be assigned.
  const programsToUse: Program[] = programs
    ? (programs as Program[]).filter(
        (program: Program) =>
          !program.issubprogram &&
          program.programtype === ProgramTypeEnum.PROGRAM_WITH_SUBMODULES &&
          !excludeProgramIds.includes(program.programid)
      )
    : [];

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.assignsubdialog.title',
          defaultMessage: 'Programm als Unterprogramme zuweisen',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'builder.assignsubdialog.content',
            defaultMessage:
              'Bitte wähle das Programm, zu dem dein Programm als Unterprogramm hinzugefügt werden soll:',
          })}
        </Typography>

        <FormControl
          key="parentprogrs"
          sx={{ width: '95%', marginTop: '20px' }}
        >
          <Select
            labelId="assign-simple-select-label"
            id="assign-simple-select"
            value={selectedParentProgramId}
            onChange={(evt: any) => {
              setSelectedParentProgramId(evt.target.value);
            }}
          >
            <MenuItem key="empty" value="empty">
              {intl.formatMessage({
                id: 'builder.assignsubdialog.target.label',
                defaultMessage: 'Bitte wähle ein Programm',
              })}
            </MenuItem>
            {programsToUse &&
              programsToUse.map((program: Program) => (
                <MenuItem key={program.programid} value={program.programid}>
                  {program.program.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Typography variant="body2" sx={{ marginTop: '20px' }}>
          {intl.formatMessage({
            id: 'builder.assignsubdialog.content2',
            defaultMessage:
              'Wenn du die Zuweisung bestätigst, wird dein Programm nur noch in der Liste der Unterprogramme des ausgewählten Programms auftauchen.',
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <EvoachButton
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {intl.formatMessage({
            id: 'builder.assignsubdialog.button.cancel',
            defaultMessage: 'Abbrechen',
          })}
        </EvoachButton>

        <EvoachButton
          color="secondary"
          onClick={() => {
            onClose(
              selectedParentProgramId === 'empty'
                ? undefined
                : selectedParentProgramId
            );
          }}
        >
          {intl.formatMessage({
            id: 'builder.assignsubdialog.button.assign',
            defaultMessage: 'Zuweisen',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
