import { useQuery } from 'react-query';

import { ProgramProps, ProgramTypeEnum } from '../../entities';
import { authorizedPost } from '../authorizedApi';

export const useCreateEmptyProgram = (
  newProgram: ProgramProps,
  issubprogram: boolean,
  parentProgramId: string = '',
  programtype: ProgramTypeEnum = ProgramTypeEnum.PROGRAM_WITHOUT_SUBMODULES
) => {
  //console.log(defaultMessages['builder.programs.emptyprogram.description']);

  const { isLoading, data, isError, error } = useQuery<any, Error>(
    'programData',
    async () => {
      const url = `/program?parentprogramid=${parentProgramId}`;
      const emptyProgramCall = authorizedPost(url, {
        program: newProgram,
        issubprogram: issubprogram,
        programtype: programtype,
      });
      const response = await emptyProgramCall();
      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    program: data,
    programId: data?.programid,
  };
};
