import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';

import { DevToolsContext } from '../../../../devtools/DevToolsContext';

interface SelectModulesProps {
  modules: Array<any>;
}

export const SelectModules: React.FC<SelectModulesProps> = ({ modules }) => {
  const intl = useIntl();

  const { l } = useContext(DevToolsContext);
  // TODO allow sharing link only if the rights are ok
  l('SelectModules: list of modules: ');
  l(modules);

  return (
    <Typography
      component="span"
      variant="subtitle2"
      style={{ marginTop: 5 }}
      key="modulNames"
    >
      {modules.length === 1 &&
        intl.formatMessage({
          id: 'builder.reception.modules.sharelink.module',
          defaultMessage: 'Chatbot: ',
        })}
      {modules.length > 1 &&
        intl.formatMessage({
          id: 'builder.reception.modules.sharelink.modules',
          defaultMessage: 'Chatbots: ',
        })}{' '}
      {modules.map((module: any, index: number) =>
        modules.length === 1 || index === 0 ? (
          <Box component="span" key={'modlist' + index}>
            {module.modulename}
          </Box>
        ) : (
          <Box component="span" key={'modlist' + index}>
            , {module.modulename}
          </Box>
        )
      )}
    </Typography>
  );
};
