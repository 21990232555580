import { evoachDefaultTheme } from '@evoach/ui-components';

import { StateEntryProps } from '.';
/*
  loopEndStateEntry xState specific defintions
*/
export const loopEndStateEntry: StateEntryProps = {
  version: 'v1',
  type: 'loopEnd',
  temporary: false,
  payload: {
    loopName: '',
  },
  displayName: 'Loop End',
  nodeType: 'loopEndStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 2,
};
