import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Checkbox,
} from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import { useNavigate } from 'react-router-dom';
import {
  EvoachButton,
  evoachDefaultTheme,
  InlineTextEdit,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { authorizedGet, authorizedPut } from '../../../api/authorizedApi';
import { ModuleProps } from '../../../entities/Module';
import {
  SupportedLocales,
  SupportedModuleLocales,
} from '../../../intl/SupportedLocales';
import { AppRoutes } from '../../../routing/routes';
import {
  LanguageSelectionHeightOptions,
  MetaDataEditorLanguageSelection,
} from '../../ModuleEditor/MetaDataEditorLanguageSelection';
import { WindowContext } from '../../window/WindowContext';
import { AccountContext } from '../../../account/AccountContext';
import { getLocaleMessages } from '../../../intl/getLocaleMessages';
import { stringIsNotTranslated } from '../../nodes/base.state';
import { useFetchTranslation } from '../../../api/module/useFetchTranslation';
import { FeatureEnum } from '../../../entities/subscriptionTypes';

import { TranslationTypeSelection } from './TranslationTypeSelection';

export interface TranslationEditorProps {
  module: ModuleProps;
}
export const TranslationEditor: React.FC<TranslationEditorProps> = ({
  module,
}) => {
  const intl = useIntl();

  // catch window resize events
  const { clientHeight, alert } = useContext(WindowContext);
  const { hasFeature } = useContext(AccountContext);

  const [showOnlyUntranslated, setShowOnlyUntranslated] =
    useState<boolean>(false);

  // get history to jump to different React routes
  let navigate = useNavigate();

  const fromLanguage = useMemo(
    () => module.metadata.defaultLanguage,
    [module.metadata.defaultLanguage]
  );

  /**
   * initial language on the right hand side
   */
  const toLanguagePreset = useMemo(() => {
    if (!module) return 'DE';
    if (module.metadata.moduleLanguages.length > 1) {
      return module.metadata.moduleLanguages.filter(
        (lang: string) => lang !== module.metadata.defaultLanguage
      )[0];
    } else {
      return module.metadata.defaultLanguage;
    }
  }, [module]);

  const [toLanguage, setToLanguage] = useState<string>(toLanguagePreset);

  const languagesNotAvailable = Object.keys(SupportedModuleLocales).filter(
    (l: string) => !module.metadata.moduleLanguages.includes(l)
  );

  // defines which test in the current list should appear in edit-mode
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedType, setSelectedType] = useState<'from' | 'to' | undefined>();

  const { translations: fT, refetch: refetchFromTranslations } =
    useFetchTranslation(module.moduleid, module.metadata.defaultLanguage);

  const { translations: tT, refetch: refetchToTranslations } =
    useFetchTranslation(module.moduleid, toLanguage);

  const [translationType, setTranslationType] = useState<'chatbot' | 'meta'>(
    'chatbot'
  );

  const { fromTranslations, toTranslations } = useMemo(() => {
    if (!fT || !tT) {
      return {
        fromTranslations: {},
        toTranslations: {},
      };
    }

    if (translationType === 'chatbot') {
      return {
        fromTranslations: fT?.statemachinetranslation ?? {},
        toTranslations: tT?.statemachinetranslation ?? {},
      };
    } else {
      return {
        fromTranslations: fT?.metadatatranslation ?? {},
        toTranslations: tT?.metadatatranslation ?? {},
      };
    }
  }, [fT, tT, translationType]);

  /**
   * save handler after changing a single text in one of the languages
   *
   * @param {'to' | 'from'} toOrFrom
   * @param {string} tkey - translation key
   * @param {string} updatedText - text to be set
   */
  const saveNewText = async (
    toOrFrom: 'to' | 'from',
    tkey: string,
    updatedText: string
  ) => {
    const data = {
      lang: toOrFrom === 'from' ? fromLanguage : toLanguage,
      metadatatranslation:
        translationType === 'meta'
          ? toOrFrom === 'from'
            ? { ...fromTranslations, [tkey]: updatedText }
            : { ...toTranslations, [tkey]: updatedText }
          : undefined,
      statemachinetranslation:
        translationType === 'chatbot'
          ? toOrFrom === 'from'
            ? { ...fromTranslations, [tkey]: updatedText }
            : { ...toTranslations, [tkey]: updatedText }
          : undefined,
    };
    const url = `/module/${module.moduleid}/translation`;
    const updateModuleCall = authorizedPut(url, data);
    updateModuleCall()
      .then(() => {
        //refetchModule();
        if (toOrFrom === 'to') {
          refetchToTranslations();
        } else {
          refetchFromTranslations();
        }
      })
      .catch((_reason: unknown) => {
        // TODO ALERT
        console.error(_reason);
      });
  };

  // the fromlanguage defines the keys to be displayed
  const fromTranslationKeys: string[] = Object.keys(fromTranslations ?? []);

  const onLanguageChange = (lang: string, toOrFrom: string) => {
    setSelectedIndex(-1);
    if (toOrFrom === 'to') {
      setToLanguage(lang);
    }
    refetchToTranslations();
  };

  const onTranslationTypeChange = (transtype: 'chatbot' | 'meta') => {
    setTranslationType(transtype);
    refetchFromTranslations();
    refetchFromTranslations();
  };

  const openInBuilder = (moduleid: string, language?: string) => {
    if (!moduleid || moduleid === '' || !language || language === '') {
      navigate(`${AppRoutes.RECEPTION}`);
    }
    navigate(`${AppRoutes.BUILDER}/${moduleid}/${language}`);
  };

  //
  // Deepl translate
  //
  const deeplTranslate = async () => {
    // if user  does not have proper plan, display message
    if (!hasFeature(FeatureEnum.DEEPL_B)) {
      alert(
        'Diese Funktion ist in deinem Abo-Modell leider nicht verfügbar und ist nur in höheren Abo-Modellen verfügbar.'
      );
      return;
    }

    // do not translate in same language
    if (fromLanguage === toLanguage) {
      return;
    }
    //
    // 1. Auto-translate with our own translations, if possible.
    // We avoid unnecessary deepl calls.
    //
    await autoTranslate();

    //
    // Do the rest with deepl
    //
    const deeplurl = `/module/${module.moduleid}/translation/deepl?translateto=${toLanguage}`;

    const deeplCall = authorizedGet(deeplurl);
    deeplCall()
      .then((res: any) => {
        res
          .json()
          .then((_res: any) => {
            // contains {numberOfTranslatedTexts: 1}
            //refetchModule();
            refetchToTranslations();
          })
          .catch((err: unknown) => console.error(err));
      })
      .catch((err: unknown) => console.error(err));
  };
  //
  // try auto translation of pre-defined evoach texts (PROD-1249)
  //
  const autoTranslate = async () => {
    setSelectedIndex(-1);
    if (
      (toLanguage === 'DE' && fromLanguage === 'EN') ||
      (toLanguage === 'EN' && fromLanguage === 'DE')
    ) {
      // Translation
      // 1. Load DE/EN in Var
      // 2. Load EN/DE in Var
      // 3. Iterate through all keys k of from translation
      // 4. Check whether text is in sourceLang and get translation key K
      // 5. Get corresponding value for K from targetLang
      // 6. Set translation of k

      const sourceTexts =
        fromLanguage === 'DE'
          ? await getLocaleMessages(SupportedLocales.DE)
          : await getLocaleMessages(SupportedLocales.EN);

      const targetTexts =
        toLanguage === 'DE'
          ? await getLocaleMessages(SupportedLocales.DE)
          : await getLocaleMessages(SupportedLocales.EN);

      let newTransl: Record<string, string> = {};
      fromTranslationKeys.forEach((key: string) => {
        const masterKey = Object.keys(sourceTexts).filter(
          (mkey: string) => fromTranslations[key] === sourceTexts[mkey]
        );
        // translation found
        if (masterKey.length > 0) {
          newTransl[key] = targetTexts[masterKey[0]];
        }
      });

      // clean up: if there is a key in toTranslation that is not
      // included in fromTranslation (since PROD-1843, this is always
      // the default language), we delete it because it has no
      // corresponding object in the state machine
      /*  const co: Record<string, string> = {};
      Object.keys(toTranslations).forEach((key: string) => {
        if (fromTranslationKeys.includes(key)) {
          co[key] = toTranslations[key];
        }
      });
 */
      //await saveTranslation('to');
    }
  };

  /**
   * languages differ and filter activated: check for translation
   * 1) "from" is not translated or
   * 2) "to" is not translated or
   * 3) "to" and "from" have the same content which is very unlikely for two different languages
   *
   * @param {string} tkey
   * @returns {boolean} true if is translated, false otherwise
   */
  const isTranslated = (tkey: string) =>
    !(
      stringIsNotTranslated(fromTranslations[tkey]) ||
      stringIsNotTranslated(toTranslations[tkey]) ||
      fromTranslations[tkey] === toTranslations[tkey]
    );

  const maxHeight = clientHeight - 100 + 'px';

  if (
    !fromTranslations ||
    !toTranslations ||
    Object.keys(fromTranslations).length === 0 ||
    Object.keys(toTranslations).length === 0
  ) {
    return <>Loading ...</>;
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'block',
        float: 'left',
        height: maxHeight,
        border: 'solid 1px #CCCCCC',
        overflow: 'hidden',
      }}
    >
      <Typography
        sx={{ paddingLeft: '10px', paddingTop: '5px', width: '100%' }}
        component="span"
      >
        {intl.formatMessage({
          id: 'builder.translationeditor.editor.textareaselection',
          defaultMessage: 'Textbereich:',
        })}
        <TranslationTypeSelection
          onTranslationTypeChange={onTranslationTypeChange}
          translationType={translationType}
        />
        <Box component="span" sx={{ paddingLeft: '50px' }}>
          {intl.formatMessage({
            id: 'builder.translationeditor.editor.showonlytranslated',
            defaultMessage: 'Nur unübersetzte Texte anzeigen:',
          })}
          <Checkbox
            checked={showOnlyUntranslated}
            onClick={() => {
              setSelectedIndex(-1);
              setShowOnlyUntranslated(!showOnlyUntranslated);
            }}
          />
        </Box>
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, height: '80%' }} aria-label="simple table">
          <TableHead sx={{ verticalAlign: 'top' }}>
            <TableRow sx={{ verticalAlign: 'top' }}>
              <TableCell align="left" width="50%" sx={{ verticalAlign: 'top' }}>
                <Typography
                  variant="body1"
                  color="primary"
                  component="span"
                  sx={{ verticalAlign: 'top' }}
                >
                  {intl.formatMessage(
                    {
                      id: 'builder.translationeditor.editor.displaytextfrom.default',
                      defaultMessage: 'Default-Sprache: {lang}, {num} Texte.',
                    },
                    {
                      lang: fromLanguage,
                      num: Object.keys(fromTranslations ?? []).length,
                    }
                  )}

                  <Tooltip
                    placement="top"
                    arrow
                    title={intl.formatMessage(
                      {
                        id: 'builder.translationeditor.editor.openinbuilderdefault',
                        defaultMessage:
                          'Chatbot in der Default-Sprache ({lang}) im Editormodus im Creator öffnen.',
                      },
                      { lang: fromLanguage }
                    )}
                  >
                    <EvoachButton
                      sx={{ marginLeft: '10px' }}
                      onClick={() =>
                        openInBuilder(module.moduleid, fromLanguage)
                      }
                    >
                      {intl.formatMessage(
                        {
                          id: 'builder.translationeditor.editor.buttonlang',
                          defaultMessage: 'Editor in {lang} öffnen',
                        },
                        { lang: fromLanguage }
                      )}
                    </EvoachButton>
                  </Tooltip>
                </Typography>
              </TableCell>
              <TableCell align="left" width="50%" sx={{ verticalAlign: 'top' }}>
                <Typography variant="body1" color="primary" component="span">
                  <MetaDataEditorLanguageSelection
                    onLanguageChange={(l: string) => onLanguageChange(l, 'to')}
                    moduleLocale={toLanguage}
                    excludeLocales={
                      module.metadata.moduleLanguages.length > 1
                        ? languagesNotAvailable.concat(fromLanguage)
                        : languagesNotAvailable
                    }
                    displayLabel={false}
                    heightOption={LanguageSelectionHeightOptions.SMALL}
                  />

                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      !hasFeature(FeatureEnum.DEEPL_B)
                        ? intl.formatMessage({
                            id: 'builder.translationeditor.editor.autotranslate.nofeature',
                            defaultMessage:
                              'Automatische Übersetzung sind nur in höheren Abo-Modellen verfügbar',
                          })
                        : intl.formatMessage({
                            id: 'builder.translationeditor.editor.autotranslate',
                            defaultMessage:
                              'Automatische Übersetzung von evoach-Texten mit Deepl.com',
                          })
                    }
                  >
                    <IconButton onClick={() => deeplTranslate()}>
                      <TranslateIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    placement="top"
                    arrow
                    title={intl.formatMessage(
                      {
                        id: 'builder.translationeditor.editor.openinbuilder',
                        defaultMessage:
                          'Chatbot in der Sprache {lang} im Creator öffnen.',
                      },
                      { lang: toLanguage }
                    )}
                  >
                    <EvoachButton
                      sx={{ marginLeft: '10px' }}
                      onClick={() => openInBuilder(module.moduleid, toLanguage)}
                    >
                      {intl.formatMessage(
                        {
                          id: 'builder.translationeditor.editor.buttonlang',
                          defaultMessage: 'Editor in {lang} öffnen',
                        },
                        { lang: toLanguage }
                      )}
                    </EvoachButton>
                  </Tooltip>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <div style={{ overflow: 'auto', height: '80%' }}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, height: '80%' }}
            aria-label="simple table"
          >
            <TableBody>
              {fromTranslationKeys
                .filter(
                  (tkey: string) =>
                    !tkey.endsWith('.src') && !tkey.endsWith('.assetid')
                )
                .filter((tkey: string) => {
                  // show all if both languages are equal
                  if (fromLanguage === toLanguage) {
                    return true;
                  }

                  // if filter not activated, show all
                  if (showOnlyUntranslated === false) {
                    return true;
                  }

                  return !isTranslated(tkey);
                })
                .map((tkey: string, index: number) => {
                  return (
                    <TableRow
                      key={'singlerow_' + tkey}
                      sx={{
                        '&:last-child td, &:last-child th, & td': {
                          border: 0,
                        },
                        border:
                          selectedIndex === index
                            ? `solid 1px ${evoachDefaultTheme.palette.secondary.main}`
                            : 'inherit',
                        backgroundColor: !isTranslated(tkey)
                          ? evoachDefaultTheme.palette.primary.main
                          : 'inherit',
                      }}
                    >
                      <TableCell
                        align="left"
                        width="50%"
                        padding="checkbox"
                        key={'singlerow_cell1_' + tkey}
                      >
                        <InlineTextEdit
                          editMode={
                            selectedIndex === index && selectedType === 'from'
                          }
                          onCancel={() => {
                            setSelectedIndex(-1);
                          }}
                          onSave={(updatedText: string) => {
                            saveNewText('from', tkey, updatedText);
                            setSelectedIndex(-1);
                          }}
                          onStartEdit={() => {
                            setSelectedType('from');
                            setSelectedIndex(index);
                          }}
                          key={`frominlineedit${index}`}
                          text={fromTranslations[tkey]}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        width="50%"
                        padding="checkbox"
                        key={'singlerow_cell2_' + tkey}
                      >
                        <InlineTextEdit
                          editMode={
                            selectedIndex === index && selectedType === 'to'
                          }
                          onCancel={() => {
                            setSelectedIndex(-1);
                          }}
                          onSave={(updatedText: string) => {
                            saveNewText('to', tkey, updatedText);
                            setSelectedIndex(-1);
                          }}
                          onStartEdit={() => {
                            setSelectedType('to');
                            setSelectedIndex(index);
                          }}
                          key={`toinlineedit${index}`}
                          text={toTranslations[tkey]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
