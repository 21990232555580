import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  audioText: {
    id: 'builder.nodes.audioDisplay.state.audioText',
    defaultMessage: 'Titel einfügen',
  },
});

export const audioDisplayStateEntry: StateEntryProps = {
  type: 'renderAudioDisplay',
  temporary: false,
  payload: {
    src: ' ',
    assetid: ' ', // blank is very important (not empty string!) as this is recognized as empty string for intl
    audioText: 'builder.nodes.audioDisplay.state.audioText',
    audioImage: 'palm-tree',
  },
  displayName: 'Audio Display',
  nodeType: 'audioDisplayStateEntry',
  handleOutCount: 1,
};
