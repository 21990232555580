import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.dateInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
  description: {
    id: 'builder.nodes.dateInput.state.description',
    defaultMessage: 'Evoach Chatbot Termin',
  },
});

/*
  DateInput xState specific definitions
*/

export const dateInputStateEntry: StateEntryProps = {
  type: 'renderDateInput',
  temporary: true,
  payload: {
    date: new Date().toUTCString(),
    buttonText: 'builder.nodes.dateInput.state.buttonText',
    saveResultTo: 'date_result',
    title: 'builder.nodes.dateInput.state.description',
  },
  saveResultType: ReadWriteType.date,
  displayName: 'Date Input',
  nodeType: 'dateInputStateEntry',
  handleOutCount: 1,
};
