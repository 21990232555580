import { Handle, NodeProps, Position } from 'reactflow';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { defineMessages } from 'react-intl';

import { TranslationContext as GlobalTranslationContext } from '../../intl/TranslationContext';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

// this message is to provide some input for yarn translations:extract
defineMessages({
  loopEndHint: {
    id: 'builder.nodes.loopEnd.hint',
    defaultMessage: 'Ende der Schleife mit dem Namen:',
  },
});

/*
  Loop end react-flow node for module builder graph canvas
*/

export const LoopEndNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const { defaultMessages } = useContext(GlobalTranslationContext);
  const payload = nodeProps.data.state.entry[0].payload;
  // do not use intl.formatMessage because the default texts of elements are stored in
  // defaultMessages and not in react-intl context! They are defined by the module
  // language.
  // In addition, this text is for the node description only and is not used in the statemachie
  const loopMessage = defaultMessages['builder.nodes.loopEnd.hint'];

  return (
    <>
      <Handle
        type="target"
        id="loopendinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {loopMessage}{' '}
          <strong>
            {(payload.loopName + '').startsWith('evoachechokey')
              ? ''
              : payload.loopName}
          </strong>
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Left}
        id="loopout"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow_controlement_handle']}
      />
      <Handle
        type="source"
        id="loopendouthandle"
        position={Position.Bottom}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
