import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'freeTextPromptStateEntry.description',
    defaultMessage:
      'Der Prompt im Feld "message" wird von einer Künstlichen Intelligenz beantwortet. Das Ergebnis wird in eine Variable geschrieben.',
  },
});

/**
 * The freeTextPrompt component is used to retrieve a single answer for a freely
 * editable prompt. It returns only a single string without further information,
 * i.e., no JSON information or chat information.
 */
export const freeTextPromptStateEntry: StateEntryProps = {
  version: 1,
  type: 'sendExternal',
  temporary: false,
  payload: {
    saveResultTo: '',
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.FREE_TEXT,
    message: '',
    getStringValues: [''],
    getPersonaFrom: '', // variable name of a strig containing a persona description for the ai
  },
  saveResultType: ReadWriteType.string,
  displayName: 'AI Free Text Prompt',
  nodeType: 'freeTextPromptStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 1,
};
