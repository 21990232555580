import { Box, CardMedia, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { VideoDisplay } from '@evoach/ui-components';

import {
  audioDisplayStateEntry,
  imageDisplayStateEntry,
  setAvatarImageStateEntry,
  StateEntryProps,
  videoDisplayStateEntry,
} from '../../nodes';
import { useFetchAsset } from '../../../api/asset/useFetchAsset';
import { AssetType } from '../assetHelper';
import { translateCreatorWithFallback } from '../../../intl/translateWithFallback';

import { AssetMenu } from './AssetMenu';

interface AssetSelectInputProps {
  stateEntry: StateEntryProps | undefined;
  /**
   * updates a payload entry in the calling function. Should have a signature
   * like (value: string, payloadKey: string, stateEntry: StateEntryProps) => {}
   */
  updatePayloadValue: Function;
}

// helper function for component
const getComponentTypeByAssetType = (assetType: AssetType) => {
  if (assetType === AssetType.IMAGE) return 'img';
  if (assetType === AssetType.AUDIO) return 'audio';
  if (assetType === AssetType.VIDEO) return 'video';
  return 'iframe';
};

export const AssetSelectInput: React.FC<AssetSelectInputProps> = ({
  stateEntry,
  updatePayloadValue,
}) => {
  const intl = useIntl();

  //
  // determine asset type as this conrol works for image, video and audio
  //
  const assetType = useMemo(() => {
    return stateEntry?.type === imageDisplayStateEntry.type ||
      stateEntry?.type === setAvatarImageStateEntry.type
      ? AssetType.IMAGE
      : stateEntry?.type === audioDisplayStateEntry.type
      ? AssetType.AUDIO
      : stateEntry?.type === videoDisplayStateEntry.type
      ? AssetType.VIDEO
      : AssetType.DOCUMENT;
  }, [stateEntry?.type]);

  //
  // the asset url is defined by the assetId. If the assetId is not set,
  // we choose the src (external URL) for retrieving the asset
  //

  const { asset } = useFetchAsset(
    translateCreatorWithFallback(intl, stateEntry?.payload.assetid + '')
  );

  const assetUrl = asset?.url ?? '';

  const externalUrl = translateCreatorWithFallback(
    intl,
    stateEntry?.payload.src + ''
  );

  let urlToUse = (
    assetUrl !== undefined &&
    assetUrl.trim() !== '' &&
    assetUrl !== 'filenotfound'
      ? assetUrl
      : externalUrl
  ).trim();

  // translatewithfallback returns the translation id if the translation can't be found
  // check that here and reset URL (PROD-1694)

  const urlInfoToShow =
    assetUrl && assetUrl !== '' && assetUrl !== 'filenotfound' ? (
      <>{asset?.key}</>
    ) : (
      <>{externalUrl}</>
    );

  //
  // proxy to update the payload value.
  // Wrapped in useCallback because the reference has to be updated
  // for each state change. If we do not wrap it, the state(Entry) is
  // not properly updated.
  //
  const localUpdateAssetValue = useCallback(
    (val: string, key: 'assetid' | 'src') => {
      if (key === 'src') {
        updatePayloadValue(' ', 'assetid', stateEntry);
        updatePayloadValue(val, 'src', stateEntry);
      }
      if (key === 'assetid') {
        updatePayloadValue(val, 'assetid', stateEntry);
      }
    },
    [stateEntry, updatePayloadValue]
  );

  return (
    <Box>
      {urlToUse !== undefined && urlToUse !== '' ? (
        <>
          {assetType !== AssetType.VIDEO ? (
            assetType === AssetType.DOCUMENT ? (
              <>
                {intl.formatMessage(
                  {
                    id: 'builder.editor.assetselectinput.documentselected',
                    defaultMessage:
                      'Du hast die Datei {filename} ausgewählt. Leider können wir für diesen Dateityp keine Vorschau anzeigen.',
                  },
                  { filename: asset?.key ?? '-' }
                )}
              </>
            ) : (
              <CardMedia
                component={getComponentTypeByAssetType(assetType)}
                controls={assetType === AssetType.AUDIO}
                src={urlToUse}
                sx={{ width: '95%', height: '80px', overflow: 'hidden' }}
              />
            )
          ) : (
            <VideoDisplay resolvedsrc={urlToUse} src="" assetid="" />
          )}
        </>
      ) : (
        <Typography variant="subtitle1" component="span">
          {intl.formatMessage({
            id: 'builder.editor.assetselectinput.noobjectselected',
            defaultMessage: 'Du hast noch keine Datei ausgewählt.',
          })}
        </Typography>
      )}
      <Tooltip placement="top" arrow title={urlInfoToShow}>
        <Typography
          variant="body2"
          fontWeight={500}
          sx={{
            width: '95%',
            marginTop: '20px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {urlInfoToShow}
        </Typography>
      </Tooltip>
      <div style={{ marginTop: '20px' }}>
        <AssetMenu
          externalUrl={externalUrl}
          updateAssetValue={localUpdateAssetValue}
          assetType={assetType}
          addPublicAssets={true}
        />
      </div>
    </Box>
  );
};
