import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Node } from 'reactflow';

import { TranslationContext } from '../../stateMachineTranslationContext';
import { ReadWriteType, StateEntryProps } from '../../nodes';

import { GetValueFromSelect } from './GetValueFromSelect';
import { PropertyHeaderLine } from './PropertyHeadLine';

export interface PolarChartInputProps {
  elementId: string;
  entry: StateEntryProps;
  updatePolarChartValues: Function;
  updatePolarChartAutoGeneration: Function;
  nodes: Node<any>[];
}

export const PolarChartInput: React.FC<PolarChartInputProps> = ({
  elementId,
  entry,
  updatePolarChartValues,
  updatePolarChartAutoGeneration,
  nodes,
}) => {
  const intl = useIntl();
  const { addOrUpdateStateMachineTranslation, removeStateMachineTranslation } =
    React.useContext(TranslationContext);
  const [labels, setLabels] = useState<string[]>(entry.payload.labels ?? []);
  const [valuesFrom, setValuesFrom] = useState<string[]>(
    entry.payload.getValuesFrom ?? []
  );

  const getTranslationKey = (index: number) => `${elementId}.label${index}`;

  // if both generation values are empty, then set to false as default
  // if any is set, then set to true
  const [autoGenerate, setAutoGenerate] = useState<boolean>(
    !(
      (entry.payload.getLabelsFrom === undefined ||
        entry.payload.getLabelsFrom.trim() === '') &&
      (entry.payload.getSeriesFrom === undefined ||
        entry.payload.getSeriesFrom.trim() === '')
    )
  );

  const [getLabelsVariable, setGetLabelsVariable] = useState<string>(
    entry.payload.getLabelsFrom ?? ''
  );
  const [getSeriesVariable, setGetSeriesVariable] = useState<string>(
    entry.payload.getSeriesFrom ?? ''
  );

  const updateAutoGenValueSeries = (value: string, _index: number) => {
    setGetSeriesVariable(value);
    updatePolarChartAutoGeneration('series', value);
  };

  const updateAutoGenValueLabels = (value: string, _index: number) => {
    setGetLabelsVariable(value);
    updatePolarChartAutoGeneration('labels', value);
  };

  const togglePolarChartGeneration = () => {
    setAutoGenerate(!autoGenerate);
  };

  const addSection = () => {
    const translationKey = getTranslationKey(labels.length);
    labels.push(translationKey);
    addOrUpdateStateMachineTranslation(
      translationKey,
      intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.polarcharinput.descriptionTextmessage',
        defaultMessage: 'Gib einen Beschreibungstext ein ...',
      })
    );
    valuesFrom.push('');
    setLabels(labels);
    setValuesFrom(valuesFrom);
    updatePolarChartValues(labels, valuesFrom);
  };

  const updateLabel = (value: string, index: number) => {
    addOrUpdateStateMachineTranslation(labels[index], value);
    updatePolarChartValues(labels, valuesFrom);
  };

  const updateValueFrom = (value: string, index: number) => {
    valuesFrom[index] = value;
    setValuesFrom(valuesFrom);
    updatePolarChartValues(labels, valuesFrom);
  };

  const removeSection = (index: number) => {
    const removedLabel = labels.splice(index, 1)[0];
    setLabels(labels);
    removeStateMachineTranslation(removedLabel);

    valuesFrom.splice(index, 1);

    setValuesFrom(valuesFrom);
    updatePolarChartValues(labels, valuesFrom, true);
  };

  const getValueType = entry?.getValueType;
  const version = entry?.version ?? 'v1';

  // ! first render, initial version to add values
  const v1JSX = (
    <>
      <Stack spacing={2}>
        {labels.map((label, index) => (
          <Stack direction="row" key={`dirstack${label}`}>
            <Stack key={label}>
              <TextField
                id={'donotcatchpasteevent_polarchart_val_' + index}
                key={`Section+${index}`}
                value={intl.formatMessage({
                  id: label,
                })}
                onChange={(evt) => updateLabel(evt.target.value, index)}
                style={{ width: '95%' }}
              />

              <GetValueFromSelect
                updateValueFrom={updateValueFrom}
                index={index}
                currentValue={valuesFrom[index]}
                key={`Section_gevauefrom+${index}`}
                getValueType={getValueType}
                nodes={nodes}
              />
            </Stack>
            <IconButton onClick={() => removeSection(index)}>
              <RemoveCircleIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      <IconButton onClick={addSection} disabled={labels.length > 9}>
        <AddCircleIcon />
      </IconButton>
    </>
  );

  // ! second render after implementation of loops
  // this new render allows autogenerating values for labels and series baased on
  // array variables (array of strings for labels and array of numbers for series)
  const v2JSX = (
    <Typography variant="body2" component="span">
      {intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.polarcharinput.autoGenLabels.hint',
        defaultMessage: 'Liste für Labels',
      })}
      <GetValueFromSelect
        updateValueFrom={updateAutoGenValueLabels}
        currentValue={getLabelsVariable}
        getValueType={ReadWriteType.stringArray}
        nodes={nodes}
      />
      {intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.polarcharinput.autoGenSeries.hint',
        defaultMessage: 'Liste für Werte',
      })}
      <GetValueFromSelect
        updateValueFrom={updateAutoGenValueSeries}
        currentValue={getSeriesVariable}
        getValueType={ReadWriteType.numberArray}
        nodes={nodes}
      />
    </Typography>
  );

  return (
    <div>
      <PropertyHeaderLine
        header={intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.polarcharinput.header',
          defaultMessage: 'Polar Chart Werte',
        })}
      />
      {version === 'v2' && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoGenerate}
                onChange={togglePolarChartGeneration}
                key="polarChartAutoGenCheckbox"
                id="polarChartAutoGenCheckboxId"
              />
            }
            label={
              <Typography variant="body2" component="span">
                {intl.formatMessage({
                  id: 'builder.moduleeditor.propertiessidebar.polarcharinput.checkboxtext',
                  defaultMessage: 'Werte aus Variablen generieren',
                })}
              </Typography>
            }
          />
        </FormGroup>
      )}
      {autoGenerate && v2JSX}
      {!autoGenerate && v1JSX}
    </div>
  );
};
