import { evoachDefaultTheme } from '@evoach/ui-components';

import { StateEntryProps } from '.';

export const setAvatarImageStateEntry: StateEntryProps = {
  type: 'setAvatarImage',
  temporary: false,
  payload: {
    src: ' ',
    assetid: ' ',
  },
  displayName: 'Coaching Avatar',
  nodeType: 'setAvatarImageStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.light,
  addPercentage: false,
  handleOutCount: 1,
};
