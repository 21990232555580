import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Stack,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@mui/material';

import { ModuleProps } from '../../entities';

const moduleDiagnoseMessages = defineMessages({
  dialogTitle: {
    id: 'builder.moduleeditor.diagnose.dialogTitle',
    defaultMessage: 'Moduldiagnose',
  },
  dialogSaveButton: {
    id: 'builder.moduleeditor.diagnose.dialogSaveButton',
    defaultMessage: 'Schließen',
  },
});
export interface ModuleDiagnoseProps {
  module: ModuleProps;
  open: boolean;
  onClose: () => void;
}
export const ModuleDiagnose: React.FC<ModuleDiagnoseProps> = ({
  module,
  open,
  onClose,
}) => {
  const intl = useIntl();

  const moduleSize = JSON.stringify(module).length;

  const lengthBuildergraph = module.buildergraph
    ? JSON.stringify(module.buildergraph).length
    : 0;

  const lengthStateMachine = module.statemachine
    ? JSON.stringify(module.statemachine).length
    : 0;

  const lengthTranslations = module.translations
    ? JSON.stringify(module.translations).length
    : 0;

  const numNodes =
    module.buildergraph &&
    (module.buildergraph as Record<string, any>[]).length > 0
      ? module.buildergraph.reduce(
          (numNodes: number, elem: any) => numNodes + (elem.source ? 0 : 1),
          0
        )
      : 0;

  const numEdges =
    module.buildergraph &&
    (module.buildergraph as Record<string, any>[]).length > 0
      ? module.buildergraph.reduce(
          (numNodes: number, elem: any) => numNodes + (!elem.source ? 0 : 1),
          0
        )
      : 0;

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage {...moduleDiagnoseMessages.dialogTitle} />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography component="span">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.description',
                      defaultMessage: 'Beschreibung',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.value',
                      defaultMessage: 'Wert',
                    })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.modulesize',
                      defaultMessage: 'Modulgröße gesamt:',
                    })}
                  </TableCell>
                  <TableCell>
                    {moduleSize}{' '}
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.characters',
                      defaultMessage: 'Zeichen',
                    })}{' '}
                    (100%)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.flowsize',
                      defaultMessage: 'Größe React-Flow buildergraph:',
                    })}
                  </TableCell>
                  <TableCell>
                    {lengthBuildergraph}{' '}
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.characters',
                      defaultMessage: 'Zeichen',
                    })}{' '}
                    ({Math.round((lengthBuildergraph / moduleSize) * 100)}%)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {numNodes}{' '}
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.nodes',
                      defaultMessage: 'Knoten',
                    })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {numEdges}{' '}
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.edges',
                      defaultMessage: 'Kanten',
                    })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.statemachinesize',
                      defaultMessage: 'Größe xState State Machine:',
                    })}
                  </TableCell>
                  <TableCell>
                    {lengthStateMachine}{' '}
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.characters',
                      defaultMessage: 'Zeichen',
                    })}{' '}
                    ({Math.round((lengthStateMachine / moduleSize) * 100)}%)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.translationdatasize',
                      defaultMessage: 'Größe Übersetzungsdaten:',
                    })}
                  </TableCell>
                  <TableCell>
                    {lengthTranslations}{' '}
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.diagnose.characters',
                      defaultMessage: 'Zeichen',
                    })}{' '}
                    ({Math.round((lengthTranslations / moduleSize) * 100)}%)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          <FormattedMessage {...moduleDiagnoseMessages.dialogSaveButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
