import { useQuery } from 'react-query';

import { ProgramPermission } from '../../entities';
import { authorizedGet } from '../authorizedApi';

/**
 * get all program permissions by programId, i.e.
 *    - owns
 *    - invitations
 *    - accepted invitations
 *
 * This list is used to organize the "Manage Coachees" dialog in the
 * Coaching Program List
 *
 * @param {string} programId to be fetched
 * @returns isLoading, isError, error, programPermissions: ProgramPermission[], refetch
 */
export const useFetchProgramPermissionsByProgramId = (
  programId: string = ''
) => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `programpermissions-${programId}`,
    async () => {
      if (!programId || programId.trim() === '') return undefined;

      const url = `/program/${programId}/allpermissions`;

      const fetchProgramPermissionsCall = authorizedGet(url);
      const response = await fetchProgramPermissionsCall();
      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    programPermissions: data as ProgramPermission[],
    refetch,
  };
};
