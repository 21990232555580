import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'freeMicrochatPromptStateEntry.description',
    defaultMessage:
      'Der Prompt im Feld "message" wird von einer Künstlichen Intelligenz als Basis des Microchat gewählt. Dem Prompt werden im Hintergrund automatisch Informationen zur Übersetzung und zum Marker gesetzt, mit dem die KI das Ende der Konversation anzeigen soll.',
  },
  getPersonaFrom: {
    id: 'freeMicrochatPromptStateEntry.getPersonaFrom',
    defaultMessage:
      'Die Variable bestimmt die Persona, mit der die KI geprompted wird. Bitte lasse das Feld leer, wenn die KI als Coach fungieren soll.',
  },
});

/**
 * The freeMicrochatPrompt component is used to define a prompt for a
 * microchat. The prompt is encoded for Player sessions and is not revealed
 * neither in API nor to the user.
 *
 * v2: getstringvalues
 * v3: getpersonafrom
 */
export const freeMicrochatPromptStateEntry: StateEntryProps = {
  version: 3,
  type: 'renderInitMicrochat',
  temporary: false,
  payload: {
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.FREE_MICROCHAT,
    message: '',
    maxTurns: 10, // displayed for users
    emoticons: true,
    getStringValues: [''],
    getPersonaFrom: '', // variable name of a strig containing a persona description for the ai
  },
  displayName: 'AI Free Microchat Prompt',
  nodeType: 'freeMicrochatPromptStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 1,
};

/*

! Implement Persona node??

TODO getPersonaFrom

1) Nicht als StringVariable, sonsdern als neuer Variablen Typ (ReadWriteType.Persona)
 ( macht das Sinn? Oder lieber String? Dann wird es schwierig, zwischen encrypted und
  nicht encrypted zu unterscheiden)
2) Nur von einem speziellen Knoten, der Personas definiert
3) Nur dann können wir die Persona verschlüsseln; wenn sie als CombineToString
  erzeugt wird, können wir nicht verschlüsseln (oder wir müssen das auch noch
    machen), daher ein eigener Knoten

TODO chathistory - nur im backend! nicht hin und her schicken

1) Variablennamen definieren (spezieller Typ in ReadWriteType.ChatHistory)
2) History Parameter für Free Microchats und Free Text Promps (Typ Chathistory)
3) wenn gesetzt wird TaskID des letzten Microchat gesetzt
4) wenn das im Backend ankommt, wird die History des letzten Microchats gelesen
    (Achtung Directed oder nicht!! => memoryHelper.ts L 169ff)
5) Die History wird in die Variable geschrieben . Wie ??? Schalter?
    a) Als OpenAI Chat?
    b) als Text-Transscript
6) Info wird als CONTEXT in den Prompt geschrieben => es muss einen passenden
    Platzhalter im Free Text Prompt dafür geben (etwa Variablenname des 
      ChatHistory Parameters)

Idee: memory markieren, wenn Variable da, dass nicht gelöscht werden darf

TODO backend

1) Introduce new Switch for this AI function
2) enable switch in Pro version, only

TODO player

- add persona Parameter to sendExternal ...

*/
