import { generateRandomString } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { XYPosition } from 'reactflow';

import { TranslationProps } from '../../entities';
import {
  coachMessageStateEntry,
  nextButtonStateEntry,
  phaseStateEntry,
  StateEntryProps,
  yesNoButtonStateEntry,
  StateNode,
  State,
  percentageEntryTemplate,
  isTranslatedStatePayloadProp,
  messageInputStateEntry,
  needsInputStateEntry,
  imageDisplayStateEntry,
  postItInputStateEntry,
  copyLinkStateEntry,
  linkButtonStateEntry,
  setAvatarImageStateEntry,
  moodInputStateEntry,
  emotionsInputStateEntry,
  phaseStartStateEntry,
  scaleInputStateEntry,
  EmotionProps,
} from '../nodes';

/**
 * getEventData
 *
 * Helper function to get the correct prop of a paste event for
 * paste event listeners.
 * @param {ClipboardEvent | DragEvent) event
 * @returns {DataTransfer | null} event data for the transfer
 */
const getEventData = (
  event: ClipboardEvent | DragEvent
): DataTransfer | null => {
  return event instanceof ClipboardEvent
    ? event.clipboardData
    : event.dataTransfer;
};

/** get allowed item types parses the current clipboard data that contains
 * information about the data types in which the current clipboard content can
 * be parsed and be returned.
 * Mabye be values like text/plain , text/html, text/rtf, image/png, image/jpeg, etc.
 * @param {any} event - an event that contains event.clipboardData || event.dataTransfer
 * @return {string[]} - array of types like ['test/plain'] etc
 */
export const getAllowedItemTypes = (
  event: ClipboardEvent | DragEvent | undefined
): string[] => {
  try {
    // PROD-1807
    if (event === undefined) {
      return ['text/plain'];
    }
    const transfer = getEventData(event);

    if (!transfer) return [];
    return Array.from(transfer.items as DataTransferItemList).map(
      (item: any) => item.type as string
    );
  } catch (_e: any) {
    return [];
  }
};

/** get allowed item "kinds"" parses the current clipboard data that contains
 * information about the data kinds in which the current clipboard content can
 * be parsed and be returned.
 * Mabye be values like string, image, etc
 * @param {any} event - an event that contains event.clipboardData || event.dataTransfer
 * @return {string[]} - array of types like ['string'] etc
 */
export const getAllowedItemKinds = (
  event: ClipboardEvent | DragEvent
): string[] => {
  try {
    const transfer = getEventData(event);
    if (!transfer) return [];
    return Array.from(transfer.items as DataTransferItemList).map(
      (item: any) => item.kind as string
    );
  } catch (_e: any) {
    return [];
  }
};

export const getStringsForLongText = (
  longstring: string,
  maxSentences: number = 2
): string[] => {
  const splittedByDots = longstring.split('.');
  const rows: string[] = [];
  let tempString = '';
  splittedByDots.forEach((smallstring: string, index: number) => {
    if (index + 1 === splittedByDots.length && smallstring === '') {
      return;
    }
    if ((index + 1) % maxSentences === 1) {
      if (tempString !== '') {
        rows.push(tempString);
      }
      tempString = smallstring.trim() + '.';
    } else {
      tempString = tempString + ' ' + smallstring.trim() + '.';
    }
  });
  if (tempString !== '' && tempString !== '.') {
    rows.push(tempString);
  }

  return rows;
};

/**
 * splitClipboardTextInLines
 * @param {string} text to be prepared
 * @returns {string[]} array of lines
 */
const splitClipboardTextInLines = (text: string) =>
  text
    .split('\n')
    .filter((item: any) => item !== undefined && item.trim() !== '')
    .map((item: string) => item.replaceAll('\t', '').replaceAll('\r', ''));

/**
 * handleSpecialCommands was created with VSCode refactor function
 * @param {string} clipboardTexts
 * @param {number} maxSentences, @default 2 - maximum number of sentences per coach message
 * @returns {string[]} list of strings
 */
const handleSpecialCommands = (
  clipboardTexts: string,
  maxSentences: number
) => {
  let resultList = splitClipboardTextInLines(clipboardTexts);

  resultList = resultList
    .filter(
      // PROD-1386
      (currentString: string) =>
        !currentString.startsWith('..........') &&
        !currentString.startsWith('______')
    )
    .map((currentString: string) => {
      // If we have one of our special commands, i.e., a colon within the first
      // 20 characters, then skip further processing.
      if (
        currentString.indexOf(':') === -1 ||
        currentString.indexOf(':') > 20
      ) {
        let numOfDots = 0;
        // count number of dots in the text as an indicator on how many
        // sentences are in it.
        // TODO add ! and ?
        for (let i = 0; i < currentString.length; i++) {
          if (currentString[i] === '.') numOfDots++;
        }
        // If a single line consists of several sentences, i.e., has more than
        // maxSentences dots, then split these sentences
        if (numOfDots > maxSentences) {
          return getStringsForLongText(currentString, maxSentences);
        } else {
          return currentString;
        }
      }
      return currentString;
    })
    .flat();
  return resultList;
};

/**
 * Split the current clipboard data into an array, if there is a \n in the text.
 * This allows copy/paste from Word/Excel/Google Doc etc.
 * @param {ClipboardEvent | DragEvent} event - an event that contains event.clipboardData || event.dataTransfer
 * @param {number} maxSentences, @default 2 - maximum number of sentences per coach message
 * @return {string[]} - array of texts after the split. Empty lines are removed and not returnded.
 */
export const getClipboardAsTexts = async (
  event: ClipboardEvent | DragEvent | undefined,
  maxSentences: number = 2
): Promise<string[]> => {
  try {
    // PRDO-1807
    if (event === undefined) {
      // PROD-1932 - Firefox does not know navigator.clipboard.readText
      if (!(navigator && navigator.clipboard && navigator.clipboard.readText)) {
        alert('Functionality is not supported in your browser (modulePaster)');
        return [];
      }
      const clipboardText = await navigator.clipboard.readText();
      return handleSpecialCommands(clipboardText, maxSentences);
    } else {
      // default handler when event is triggered
      const transfer = getEventData(event);
      if (!transfer) return [];
      const clipboardTexts = transfer.getData('text/plain');
      // split clipboard in different strings, remove tabs, remove empty lines
      return handleSpecialCommands(clipboardTexts, maxSentences);
    }
  } catch (_e: unknown) {
    console.error(_e);
    return [];
  }
};

/**
 * getNodesByTexts takes a list of strings / rows and translates it into a set of StateNodes
 * Important: payloads of state node entries are not translated, i.e., managed by translation keys
 * @param {string[]} messages - list of texts / rows that have to be translated into nodes
 * @returns {StateNode[]} array of state nodes, already including the percentage templates per node
 */
export const getNodesByTexts = (messages: string[]): StateNode[] => {
  //
  const elements: StateNode[] = [];

  messages.forEach((message: string) => {
    const colonSplit = message.includes(':') ? message.split(':') : [];
    let messageType = 'coachmessage';
    let stateEntry: StateEntryProps = cloneDeep(coachMessageStateEntry);
    if (colonSplit.length > 0) {
      messageType = colonSplit[0].toLowerCase().trim().replaceAll(/\s/g, '');
    }

    // consider only the command before the first :
    // if the rest of the message contains more :, leave them as they are
    // as they may be also part of a link
    let colonSplitPayload =
      colonSplit.length > 0
        ? message.replace(colonSplit[0] + ':', '')
        : message.trim();

    // if the input is something like "My first question:" or "Question 1:" without
    // a specific text after the colon, then create a phase!
    if (
      colonSplit.length > 0 &&
      colonSplitPayload === '' &&
      colonSplit[0].length < 25
    ) {
      messageType = 'phase';
      colonSplitPayload = colonSplit[0].replaceAll(':', '');
    }

    switch (messageType) {
      case 'copylink':
        stateEntry = cloneDeep(copyLinkStateEntry);
        stateEntry.payload.link = colonSplitPayload;
        break;
      case 'link':
      case 'url':
      case 'linkbutton':
        stateEntry = cloneDeep(linkButtonStateEntry);
        stateEntry.payload.src = colonSplitPayload;
        break;
      case 'postit':
      case 'postitinput':
      case 'post-it':
      case 'post-it-input':
        stateEntry = cloneDeep(postItInputStateEntry);
        stateEntry.payload.headline = colonSplitPayload;
        break;
      case 'image':
      case 'bild':
        stateEntry = cloneDeep(imageDisplayStateEntry);
        stateEntry.payload.src = colonSplitPayload;
        break;
      case 'avatar':
      case 'setavatar':
      case 'ich':
        stateEntry = cloneDeep(setAvatarImageStateEntry);
        stateEntry.payload.src = colonSplitPayload;
        break;
      case 'startphase':
      case 'start':
        stateEntry = cloneDeep(phaseStartStateEntry);
        stateEntry.payload.phasetext = colonSplitPayload;
        break;
      case 'phase':
        stateEntry = cloneDeep(phaseStateEntry);
        stateEntry.payload.phasetext = colonSplitPayload;
        break;
      case 'yesno':
      case 'yesnobutton':
        // take the message and add button in post processing below
        stateEntry = cloneDeep(coachMessageStateEntry);
        stateEntry.payload.message = colonSplitPayload;
        break;
      case 'button':
      case 'nextbutton':
        stateEntry = cloneDeep(nextButtonStateEntry);
        stateEntry.payload.buttonText = colonSplitPayload;
        break;
      case 'skala':
      case 'scala':
      case 'scale':
      case 'scaleinput':
        stateEntry = cloneDeep(scaleInputStateEntry);
        stateEntry.payload.scaleName = colonSplitPayload;
        break;
      case 'input':
      case 'messageinput':
      case 'message-input':
      case 'userinput':
        stateEntry = cloneDeep(messageInputStateEntry);
        stateEntry.payload.placeholderText = colonSplitPayload;
        break;
      case 'mood':
      case 'stimmung':
        // take the message and add button in post processing below
        stateEntry = cloneDeep(coachMessageStateEntry);
        break;
      case 'needsselector':
      case 'needs':
      case 'needsinput':
      case 'needinput':
      case 'bedürfnisse':
      case 'bedürfnisselektor':
        stateEntry = cloneDeep(coachMessageStateEntry);
        stateEntry.payload.message = colonSplitPayload;
        break;
      case 'emotions':
      case 'gefühle':
      case 'emotioninput':
      case 'emotionsinput':
      case 'gefühlsselektor':
        stateEntry = cloneDeep(emotionsInputStateEntry);
        stateEntry.payload.message = colonSplitPayload;
        break;
      case 'message':
      case 'coach':
      case 'coachmessage':
      case 'couchmessage':
      case 'nachricht':
        stateEntry = cloneDeep(coachMessageStateEntry);
        stateEntry.payload.message = colonSplitPayload;
        break;
      default:
        // if there is a : in the message, but the left hand side of the colon
        // is not recognized as a state entry, then we add the whole message
        // as coach message
        stateEntry = cloneDeep(coachMessageStateEntry);
        stateEntry.payload.message = message;
        break;
    }

    const elementId = generateRandomString(8);
    const stateEntryList: StateEntryProps[] = [
      stateEntry,
      percentageEntryTemplate,
    ];
    const preparedElementState = new State(elementId, stateEntryList);

    const newNode = new StateNode(
      elementId,
      stateEntry.nodeType,
      { x: 0, y: 0 } as XYPosition,
      preparedElementState,
      stateEntry.nodeMiniMapColor ?? '#CCCCCC'
    );

    elements.push(newNode);

    // post process based on previous node?

    if (['yesno', 'yesnobutton'].includes(messageType)) {
      // if we handled a yes no button with a text, we first added a
      // coachmessage with the message and then the yes/no button
      const additionalId = generateRandomString(8);
      elements.push(
        new StateNode(
          additionalId,
          yesNoButtonStateEntry.nodeType,
          { x: 0, y: 0 } as XYPosition,
          new State(additionalId, [
            cloneDeep(yesNoButtonStateEntry),
            percentageEntryTemplate,
          ] as StateEntryProps[]),
          yesNoButtonStateEntry.nodeMiniMapColor
        )
      );
    }

    if (['mood', 'stimmung'].includes(messageType)) {
      // if we handled a yes no button with a text, we first added a
      // coachmessage with the message and then the yes/no button
      const additionalId = generateRandomString(8);
      elements.push(
        new StateNode(
          additionalId,
          moodInputStateEntry.nodeType,
          { x: 0, y: 0 } as XYPosition,
          new State(additionalId, [
            cloneDeep(moodInputStateEntry),
            percentageEntryTemplate,
          ] as StateEntryProps[]),
          moodInputStateEntry.nodeMiniMapColor
        )
      );
    }

    if (
      ['needsinput', 'needs', 'needinput', 'needsselector'].includes(
        messageType
      )
    ) {
      // if we handled a needs selector, we took the message as a coach
      // message first and add the needs selector here
      const additionalId = generateRandomString(8);
      elements.push(
        new StateNode(
          additionalId,
          needsInputStateEntry.nodeType,
          { x: 0, y: 0 } as XYPosition,
          new State(additionalId, [
            cloneDeep(needsInputStateEntry),
            percentageEntryTemplate,
          ] as StateEntryProps[]),
          needsInputStateEntry.nodeMiniMapColor
        )
      );
    }
    if (
      ['coachmessage', 'coach', 'message'].includes(messageType) &&
      colonSplitPayload.endsWith('?')
    ) {
      // if we handled a coach message and the last character in the message
      // was a question mark, then add automatically a message input.
      const additionalId = generateRandomString(8);
      elements.push(
        new StateNode(
          additionalId,
          messageInputStateEntry.nodeType,
          { x: 0, y: 0 } as XYPosition,
          new State(additionalId, [
            cloneDeep(messageInputStateEntry),
            percentageEntryTemplate,
          ] as StateEntryProps[]),
          messageInputStateEntry.nodeMiniMapColor
        )
      );
    }
  });

  return elements;
};

/**
 * initArrayTranslation gets a payload of a stateEntry that is an array and prepares it for translation.
 * @see also initTranslation for non-array values
 * @param {StateEntryProps} stateEntry - state entry to be prepared
 * @param {string} payloadKey - payload key to be considered for translation
 * @param {Function} addOrUpdateStateMachineTranslation - context specific function to update translation
 * @param {TranslationProps} globalMessages - provide existing global translations to initialize localized default values
 * @returns {Record<string, string>[]} array of objects with { origKey: , newKey:  }
 */
export const initArrayTranslation = (
  stateEntry: StateEntryProps,
  payloadKey: string,
  addOrUpdateStateMachineTranslation: Function,
  globalMessages: TranslationProps,
  currentStateMachineTranslation?: TranslationProps
): Record<string, string>[] => {
  // collect { origKey: initialValueOrKey, newKey: translationKey };
  // for the return value;
  const returnList: Record<string, string>[] = [];

  // if you adapt this function/code part, consider changing other
  // translation key related code parts:
  // - initAndSetElementTranslationsForSingleElement.initArrayTranlslation in builder...ModuleEditorPane (you are here)
  // - initAndSetElementTranslationsForTemplate.initArrayTranlslation in builder...ModuleEditorPane
  // - updateKeys in backend...moduleService.duplicateModuleById
  const arrayPayload = stateEntry.payload[payloadKey] as any[];
  const newkey = generateRandomString(8);
  stateEntry.payload[payloadKey] = arrayPayload.map((elementvalue, index) => {
    const translationKey: string = `${newkey}.${index}`;
    //const initialValueOrKey = stateEntry.payload[payloadKey];

    if (typeof elementvalue === 'string') {
      const initialValue = currentStateMachineTranslation
        ? currentStateMachineTranslation[elementvalue as string]
        : elementvalue;
      addOrUpdateStateMachineTranslation(
        translationKey,
        elementvalue.startsWith('builder.')
          ? globalMessages[elementvalue]
          : initialValue
      );
      // collect for return
      returnList.push({ origKey: elementvalue, newKey: translationKey });
      return translationKey;
    } else {
      // if typeof value is not string, we expect it to be an object
      // e.g. an elements object with presets for NeedsInput or EditableMultiSelect
      if (typeof elementvalue === 'object' && elementvalue.value) {
        const initialValue = currentStateMachineTranslation
          ? currentStateMachineTranslation[elementvalue.value]
          : elementvalue.value;

        addOrUpdateStateMachineTranslation(
          translationKey,
          elementvalue.value.startsWith('builder.')
            ? globalMessages[elementvalue.value]
            : initialValue
        );

        // collect for return
        returnList.push({
          origKey: elementvalue.value,
          newKey: translationKey,
        });

        // TODO if you add any new object parameter here, add it also to
        // TODO cleanUpTranslations in  moduleUtils.ts

        // all objects only contain one translatable prop named "value". In
        // ImageSelector, we have an additional translateable props "src" and
        // "assetId" (@see ImageSelector.state.tsx). We have to consider that here
        if (
          elementvalue.src !== undefined &&
          elementvalue.assetid !== undefined
        ) {
          // new keys
          const translationKeySrc: string = `${newkey}.${index}.src`;
          const translationKeyAssetId: string = `${newkey}.${index}.assetid`;

          // intial values
          const initialValueSrc = currentStateMachineTranslation
            ? currentStateMachineTranslation[elementvalue.src]
            : elementvalue.src;
          const initialValueAssetId = currentStateMachineTranslation
            ? currentStateMachineTranslation[elementvalue.assetid]
            : elementvalue.assetid;

          // add new translations
          addOrUpdateStateMachineTranslation(
            translationKeySrc,
            elementvalue.src.startsWith('builder.')
              ? globalMessages[elementvalue.src]
              : initialValueSrc
          );
          addOrUpdateStateMachineTranslation(
            translationKeyAssetId,
            elementvalue.assetid.startsWith('builder.')
              ? globalMessages[elementvalue.assetid]
              : initialValueAssetId
          );

          // collect new keys for for return
          returnList.push({
            origKey: elementvalue.src,
            newKey: translationKeySrc,
          });
          returnList.push({
            origKey: elementvalue.assetid,
            newKey: translationKeyAssetId,
          });
          return {
            ...elementvalue,
            value: translationKey,
            src: translationKeySrc,
            assetid: translationKeyAssetId,
          };
        } else {
          return { ...elementvalue, value: translationKey };
        }
      }
      return elementvalue;
    }
  });

  // Special handling for emotionsInput - if you use your own elements and this is
  // a template, then we have to adapt the elements array (which is done above)
  // and then set the legacy parameter ownElements to the same value in parallel
  // to payload !!
  if (
    stateEntry.nodeType === emotionsInputStateEntry.nodeType &&
    payloadKey === 'elements' &&
    stateEntry.useOwnElements === true
  ) {
    stateEntry.ownElements = stateEntry.payload[payloadKey]?.map(
      (elem: any) => elem as EmotionProps
    );
  }

  return returnList;
};

/**
 * initTranslation prepares a single value for translation
 * @param {StateEntryProps} stateEntry - state entry to be prepared
 * @param {string} payloadKey - payload key to be considered for translation
 * @param {Function} addOrUpdateStateMachineTranslation - context specific function to update translation
 * @param {TranslationProps} globalMessages - provide existing global translations to initialize localized default values
 * @returns {Record<string, string>} an object with { origKey: , newKey:  }
 */
export const initTranslation = (
  stateEntry: StateEntryProps,
  payloadKey: string,
  addOrUpdateStateMachineTranslation: Function,
  globalMessages: TranslationProps,
  currentStateMachineTranslation?: TranslationProps
): Record<string, string> => {
  const translationKey = `${generateRandomString(8)}.${payloadKey}`;
  const initialValueOrKey = stateEntry.payload[payloadKey] as string;

  // PROD-1837 - consider that the value may not be present because
  // translation is lacking
  const initialValue =
    currentStateMachineTranslation &&
    currentStateMachineTranslation[initialValueOrKey]
      ? currentStateMachineTranslation[initialValueOrKey]
      : initialValueOrKey ?? '';

  addOrUpdateStateMachineTranslation(
    translationKey,
    initialValue && initialValue.startsWith('builder.')
      ? globalMessages[initialValue] ?? ''
      : initialValue ?? ''
  );
  stateEntry.payload[payloadKey] = translationKey;

  return { origKey: initialValueOrKey, newKey: translationKey };
};

/**
 * initPayloadTranslation prepares a stateEntry for translation
 * @param {StateEntryProps} stateEntry - state entry to be prepared
 * @param {Function} addOrUpdateStateMachineTranslation - context specific function to update translation
 * @param {TranslationProps} globalMessages - provide existing global translations to initialize localized default values
 */
export const initPayloadTranslation = (
  stateEntry: StateEntryProps,
  addOrUpdateStateMachineTranslation: Function,
  globalMessages: TranslationProps
) => {
  const payloadKeyList = Object.keys(stateEntry.payload);

  payloadKeyList
    .filter((key: string) => isTranslatedStatePayloadProp(key))
    .forEach((payloadKey) => {
      if (Array.isArray(stateEntry.payload[payloadKey])) {
        initArrayTranslation(
          stateEntry,
          payloadKey,
          addOrUpdateStateMachineTranslation,
          globalMessages
        );
      } else {
        initTranslation(
          stateEntry,
          payloadKey,
          addOrUpdateStateMachineTranslation,
          globalMessages
        );
      }
    });
};
