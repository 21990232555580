import * as CustomNodes from '../nodes';

export const nodeTypes = {
  audioDisplayStateEntry: CustomNodes.AudioDisplayNode,
  coachMessageStateEntry: CustomNodes.CoachMessageNode,
  aiCoachMessageStateEntry: CustomNodes.CoachMessageNode, // same node as Coach message but with additional props in payload
  yesNoButtonStateEntry: CustomNodes.YesNoButtonNode,
  randomCoachMessageStateEntry: CustomNodes.RandomCoachMessageNode,
  randomCoachMessageSingleStateEntry: CustomNodes.RandomCoachMessageSingleNode,
  dateInputStateEntry: CustomNodes.DateInputNode,
  postItInputStateEntry: CustomNodes.PostItInputNode,
  postItDisplayStateEntry: CustomNodes.PostItDisplayNode,
  messageInputStateEntry: CustomNodes.MessageInputNode,
  formattedInputStateEntry: CustomNodes.FormattedInputNode,
  moodInputStateEntry: CustomNodes.MoodInputNode,
  nextButtonStateEntry: CustomNodes.NextButtonNode,
  phaseStateEntry: CustomNodes.PhaseNode,
  phaseStartStateEntry: CustomNodes.PhaseStartNode,
  phaseEndStateEntry: CustomNodes.PhaseEndNode,
  multipleOutputCompositeStateEntry: CustomNodes.MultipleOuputCompositeNode,
  ratingInputStateEntry: CustomNodes.RatingInputNode,
  scaleInputStateEntry: CustomNodes.ScaleInputNode,
  percentageScaleInputStateEntry: CustomNodes.PercentageScaleInputNode,
  scaleInputMultiStateEntry: CustomNodes.ScaleInputMultiNode,
  multipleInputStateEntry: CustomNodes.MultipleInputNode,
  timeInputStateEntry: CustomNodes.TimeInputNode,
  videoDisplayStateEntry: CustomNodes.VideoDisplayNode,
  polarChartInputStateEntry: CustomNodes.PolarChartInputNode,
  polarChartStateEntry: CustomNodes.PolarChartNode,
  radarChartStateEntry: CustomNodes.RadarChartNode,
  multipleDisplayCompositeStateEntry: CustomNodes.MultipleDisplayCompositeNode,
  noteStateEntry: CustomNodes.NoteNode,
  radioButtonStateEntry: CustomNodes.RadioButtonNode,
  copyLinkStateEntry: CustomNodes.CopyLinkNode,
  imageDisplayStateEntry: CustomNodes.ImageDisplayNode,
  imageSelectorStateEntry: CustomNodes.ImageSelectorNode,
  imageSelectorDisplayStateEntry: CustomNodes.ImageSelectorDisplayNode,
  setAvatarImageStateEntry: CustomNodes.SetAvatarNode,
  scaleButtonStateEntry: CustomNodes.ScaleButtonNode,
  editableMultiselectStateEntry: CustomNodes.EditableMultiselectNode,
  needsInputStateEntry: CustomNodes.NeedsInputNode,
  valueSelectorStateEntry: CustomNodes.ValueSelectorNode,
  emotionsInputStateEntry: CustomNodes.EmotionsInputNode,
  multiButtonStateEntry: CustomNodes.MultiButtonNode,
  binarySorterStateEntry: CustomNodes.BinarySorterNode,
  binarySorterDisplayStateEntry: CustomNodes.BinarySorterDisplayNode,
  loopStartStateEntry: CustomNodes.LoopStartNode,
  loopEndStateEntry: CustomNodes.LoopEndNode,
  variableDefineStringArrayNodeStateEntry: CustomNodes.DefineStringArrayNode,
  linkButtonStateEntry: CustomNodes.LinkButtonNode,
  multipleInputSingleStateEntry: CustomNodes.MultipleInputSingleNode,
  selectionCardStateEntry: CustomNodes.SelectionCardNode,
  characterSelectorStateEntry: CustomNodes.CharacterSelectorNode,
  sceneCanvasStateEntry: CustomNodes.SceneCanvasNode,
  canvasEditorStateEntry: CustomNodes.CanvasEditorNode,
  canvasDisplayStateEntry: CustomNodes.CanvasDisplayNode,
  arrayCombineStringNodeStateEntry: CustomNodes.ArrayCombineNode,
  arrayCombineNumberNodeStateEntry: CustomNodes.ArrayCombineNode,
  sendMailNodeStateEntry: CustomNodes.SendMailNode,
  createCertificateStateEntry: CustomNodes.CreateCertificateNode,
  calenderExportCompositeStateEntry: CustomNodes.CalenderExportCompositeNode,
  singleDisplayStateEntry: CustomNodes.SingleDisplayNode,
  hotOrNotSelectorStateEntry: CustomNodes.HotOrNotSelectorNode,
  compareNumbersStateEntry: CustomNodes.CompareNumbersNode,
  shareSessionStateEntry: CustomNodes.ShareSessionNode,
  startNewSessionStateEntry: CustomNodes.StartNewSessionNode,
  valueCardListStateEntry: CustomNodes.ValueCardListNode,
  formulaNodeStateEntry: CustomNodes.FormulaNode,
  multiplePercentageScaleInputStateEntry:
    CustomNodes.MultiplePercentageScaleInputNode,
  compareMultipleNumbersStateEntry: CustomNodes.MultipleCompareNumbersNode,
  navigateButtonStateEntry: CustomNodes.NavigateButtonNode,
  downloadButtonStateEntry: CustomNodes.DownloadButtonNode,
  checkVariableStateEntry: CustomNodes.CheckVariableNode,
  paraphraseNodeStateEntry: CustomNodes.ParaphraseNode,
  aiClassificationNodeStateEntry: CustomNodes.AiClassificationNode,
  singleAnswerPromptStateEntry: CustomNodes.SingleAnswerPromptNode,
  freeTextPromptStateEntry: CustomNodes.FreeTextPromptNode,
  aiSentimentNodeStateEntry: CustomNodes.AiSentimentNode,
  aiMicrochatStateEntry: CustomNodes.AiMicrochatNode,
  ailistPromptStateEntry: CustomNodes.AiListPromptNode,
  freeMicrochatPromptStateEntry: CustomNodes.FreeMicrochatPromptNode,
  aiSummarizeStateEntry: CustomNodes.AiSummarizeNode,
  aiDocumentChatStateEntry: CustomNodes.AiDocumentChatNode,
  combineToStringStateEntry: CustomNodes.CombineToStringNode,
  setDirectedAgentModeStateEntry: CustomNodes.SetDirectedAgentModeNode,
  aiGenericClassificationStateEntry: CustomNodes.AiGenericClassificationNode,
};
