import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { ModuleEditorContext } from '../../ModuleEditor/ModuleEditorContext';
import { predefinedNeedsSet, StateEntryProps } from '../../nodes';
import { WindowContext } from '../../window/WindowContext';

import { PropertyHeaderLine } from './PropertyHeadLine';

export interface NeedsInputInputProps {
  entry: StateEntryProps;
  updateNeedsInputValues: Function;
}

/** NeedsInputInput component
 *
 * @param {StateEntryProps} entry - complete entry of the NeedsInput node
 * @param {Function} updateNeedsInputValues - handler to pass the index of the selected value
 *
 * TODO in a future version, this control may even handle "custom lists" with an editor of texts+icons. We may use PolarChartInput as template
 */
export const NeedsInputInput: React.FC<NeedsInputInputProps> = ({
  entry,
  updateNeedsInputValues,
}) => {
  const [currentNeedsListSelection, setCurrentNeedsListSelection] =
    useState<number>(entry.payload.predefinedNeedsIndex ?? 0);

  const { alert } = useContext(WindowContext);
  const { moduleLoadedInDefaultLanguage } = useContext(ModuleEditorContext);

  const updateNeedsList = (value: number) => {
    if (!moduleLoadedInDefaultLanguage) {
      alert(
        intl.formatMessage({
          id: 'builder.needsinputput.notindefaultlang',
          defaultMessage:
            'Du kannst deinem Chatbot nur dann neue Elemente hinzufügen oder Listen in Elementen ändern, wenn du ihn in der Default-Sprache geladen hast.',
        })
      );
      return;
    }
    setCurrentNeedsListSelection(value);
    updateNeedsInputValues(value);
  };

  const intl = useIntl();

  return (
    <div>
      <PropertyHeaderLine
        header={intl.formatMessage({
          id: 'builder.moduleeditor.propertiesSidebar.needsinput.header',
          defaultMessage: 'Vordefinierte Needs Liste',
        })}
      />
      <EvoachSelect
        value={currentNeedsListSelection}
        onChange={(event) => {
          updateNeedsList(event.target.value as number);
        }}
      >
        {predefinedNeedsSet.map((needsobject: any, index: number) => (
          <EvoachMenuItem key={`needsListEntry${index}`} value={index}>
            {intl.formatMessage({
              id: needsobject.needsListName,
            })}
          </EvoachMenuItem>
        ))}
      </EvoachSelect>
    </div>
  );
};
