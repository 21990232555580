//import { cloneDeep } from 'lodash';
import { useQuery } from 'react-query';
import { useContext } from 'react';

import { ModuleGlobalVarProps } from '../../entities/Module';
import { authorizedGet } from '../authorizedApi';
import { DevToolsContext } from '../../devtools/DevToolsContext';

/**
 * get all global variables provided by other modules of the same coach
 */
export const useFetchProvidedGlobalVariables = (moduleIdToExclude: string) => {
  const { l } = useContext(DevToolsContext);

  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `module-${moduleIdToExclude}`,
    async () => {
      // use last stateMachineLocale for loading the module, assuming that this is your
      // prefered locale. By default / if no module was previously loaded, it is set to
      // the UI locale
      const url = `/module/${moduleIdToExclude ?? 'none'}/globalvars/provided`;
      l('globvars: url:');
      l(url);

      const fetchModuleCall = authorizedGet(url);
      const response = await fetchModuleCall();

      const data: ModuleGlobalVarProps[] = await response.json();

      l('globvars: data:');
      l(data);
      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    globalVariables: data as ModuleGlobalVarProps[],
    refetch,
  };
};
