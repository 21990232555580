/*
  ModuleBuilder

  Pls check call hierarchy with responsibilities below

  BuilderPage                     - page for router, check route params, load module
    |_ ModuleBuilderWrapper       - management of module translation which is independent of the UI translation
!      |_ ModuleBuilder            - keep state of Reract Flow nodes and edges, manage undo logic
        |_ ModuleEditor           - provide toolbar on top of editor canvas
          |_ ModuleEditorpane     - ReactFlow provider + ReactFlow component + management of canvas actions

*/

import React, { useEffect, useMemo, useState } from 'react';
import { Alert, AlertTitle, Grid, Snackbar } from '@mui/material';
import { useIntl } from 'react-intl';
import { cloneDeep } from 'lodash';
import { Edge, Node, isEdge, isNode } from 'reactflow';

import { ModuleEditor } from '../components';
import { ModuleProps } from '../entities/Module';
import { useUpdateModule } from '../api/module/usePersistModule';
import { ApiError } from '../api/ApiError';

import { ElementPropertySidebar } from './PropertiesSidebar';
import { SelectionSidebar } from './SelectionSidebar/SelectionSidebar';
import { ReactFlowElements } from './ModuleEditor/ReactFlowHelper';
import ErrorBoundary from './ErrorBoundary';
import { ModuleEditorContext } from './ModuleEditor/ModuleEditorContext';
import { copyLinkStateEntry } from './nodes';

//
// interface for the UnodObject
// Object is stored in updateElements and used in undoElements
// have to be restored later
//
export interface UndoObject {
  nodes: Node<any>[];
  edges: Edge<any>[];
}

//
// ModuleBuilderProps => module
//
export interface ModuleBuilderProps {
  module: ModuleProps;
}

export const builderGraphWithoutStartPhaseNode = [];

export const ModuleBuilder: React.FC<ModuleBuilderProps> = (props) => {
  const { module } = props;
  const intl = useIntl();

  // useQuery hook for updating a module (mutate = save/PUT)
  const {
    isLoading,
    isError: isUpdateModuleError,
    error: updateModuleError,
    mutate,
  } = useUpdateModule();

  // try to extract the elements if the buildergraph is changing,
  // e.g., after re-loading the module
  const initialElements = useMemo(() => {
    return module.buildergraph && Array.isArray(module.buildergraph)
      ? module.buildergraph
      : builderGraphWithoutStartPhaseNode;
  }, [module.buildergraph]);

  const initialNodes = useMemo(() => {
    return initialElements.filter(isNode);
  }, [initialElements]);

  // in reactflow v11 edges are rendered iff target/source node has a handle
  // with the specified targetHandle / sourceHandle.
  // since we changed some of these over time, we have to make sure that all
  // legacy modules are still displayed correctly
  const updateLegacyEdgeHandles = (edge: any) => {
    let tempEdge = edge;

    // PROD-2021 - do not rename for old CppyLink
    if (notCopyLinkComponentAsSource(edge)) {
      if (edge?.sourceHandle === 'messagehandle') {
        tempEdge.sourceHandle = 'messageouthandle';
      }
      if (edge?.targetHandle === 'messagehandle') {
        tempEdge.targetHandle = 'messageinhandle';
      }
    }
    return tempEdge;
  };

  const notCopyLinkComponentAsSource = (edge: any) => {
    if (!edge || !edge.source) return true;

    const n = initialNodes.filter((node: Node) => node.id === edge.source);

    return n && n.length > 0 ? n[0].type !== copyLinkStateEntry.nodeType : true;
  };

  // there are no "elements" (ReactFlow 9) any more,
  // but separate objects for "nodes" and "edges" (ReactFlow 10)
  const [nodes, setNodes] = useState(initialElements.filter(isNode) ?? []);
  const [edges, setEdges] = useState(
    initialElements.filter(isEdge).map(updateLegacyEdgeHandles) ?? []
  );

  // array of UndoObjects => is filled by updateElements and changed by
  // undoElements accordingly
  const [undoBuffer, setUndoBuffer] = useState<UndoObject[]>([
    { nodes: nodes, edges: edges },
  ]);

  //
  // undoElements - un-does the last operation and restores translations
  // undo function - called by CTRL+Z / CMD+Z in ModuleEditorPane
  //
  const undoElements = () => {
    // if there is more than the initialElements, do an un-do
    if (undoBuffer.length >= 2) {
      // make a copy of the udoBuffer
      const newbuffer = cloneDeep(undoBuffer);
      // get element -2, .i.e., the state before the current state
      const undoToElements = cloneDeep(newbuffer[newbuffer.length - 2]);
      // remove current state as we return to last one
      newbuffer.pop();
      // save buffer without last state
      setUndoBuffer(newbuffer);
      // restore edges and nodes separately
      setNodes(undoToElements.nodes);
      setEdges(undoToElements.edges);
    }
  };

  const storeForUndoAction = (lnodes: Node<any>[], ledges: Edge<any>[]) => {
    const newbuffer = cloneDeep(undoBuffer);
    // remove first element if we have more than 10 undo steps
    if (newbuffer.length > 10) {
      newbuffer.shift();
    }

    // add the new one
    newbuffer.push({
      nodes: lnodes,
      edges: ledges,
    } as UndoObject);
    setUndoBuffer(newbuffer);
  };

  //
  // storeElements - fka "updateElements"
  // Updates the React Flow elements and prepares undo operations.
  // For instance, it's widely used in EntryLists.tsx but also in other components
  // storeElements is a central function used down the hierachy of components
  // dozens of times.
  // Disadvantage of this function: it expects a list of nodes+edges as ReactFlowElements.
  // This is a legacy of React Flow 9 where you only had "elements" but no nodes or edges
  //
  const storeElements = (
    elements: ReactFlowElements,
    storeForUndo: boolean = true
  ): void => {
    // split elements
    const lnodes = elements.filter(isNode);
    const ledges = elements.filter(isEdge);

    // update nodes and edges separately
    setNodes(lnodes);
    setEdges(ledges);

    // if this should be stored for undo .. push it on the undo buffer
    if (storeForUndo) {
      storeForUndoAction(lnodes, ledges);
    }
  };

  // update nodes while keepiong edges
  const updateNodes = (
    nodesParam: Node<any>[],
    storeForUndo: boolean = true
  ): void => {
    if (nodesParam) {
      setNodes(nodesParam);
      // if this should be stored for undo .. push it on the undo buffer
      if (storeForUndo) {
        storeForUndoAction(nodesParam, edges);
      }
    }
  };

  // update edges while keeping nodes
  const updateEdges = (
    edgesParam: Edge<any>[],
    storeForUndo: boolean = true
  ): void => {
    if (edgesParam) {
      setEdges(edgesParam);
      // if this should be stored for undo .. push it on the undo buffer
      if (storeForUndo) {
        storeForUndoAction(nodes, edgesParam);
      }
    }
  };

  //remember whether alert is open
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const onAlertClose = () => setErrorMessageOpen(false);

  // we have to handle the "open" of the dialogue separatly; otherwise,
  // React starts "infinite" rendering as the state toggles
  useEffect(() => {
    // only set to true if isUpdateModuleError is true
    // do NOT set to false if isUpdateModuleError is false !
    // this is done by onAlertClose
    if (isUpdateModuleError) setErrorMessageOpen(true);
  }, [isUpdateModuleError]);

  const [updateModuleErrorWithType, setUpdateModuleErrorWithType] =
    useState<any>('');

  useEffect(() => {
    if (updateModuleError) {
      let errormessage;
      if (updateModuleError instanceof ApiError) {
        errormessage = updateModuleError as ApiError;
      } else {
        errormessage = '...';
      }
      setUpdateModuleErrorWithType(errormessage);
    }
  }, [updateModuleError]);

  // PROD-1842 - check whether module is loaded in its default language
  const moduleLoadedInDefaultLanguage = useMemo(() => {
    if (!module) return false;
    return module.metadata.defaultLanguage === module.translations[0].lang;
  }, [module]);

  // locale={UIlocale}
  // this is important as we may have separate locales for both the Ui and the module!
  // The locales are independent! In ModuleBuilderWrapper (parent of this component)
  // we set the locale for the statemachine and add the corresponding values. But these
  // translations are added to the current UI translations even if the locale is different
  // to provide only one React Intl content for React to render! That means, the UI can
  // be of locale EN and the state machine can be of locale CN. If you switch the UI locale,
  // the state machine locale stays the same

  // the following Provider has a message property that contains a combination of difefrent texsts
  // the sequence of the elements in the spread operator is very important, it is espcially important
  // that metadata translations comes before statemachine translations. This is due to the fact that
  // phases are only stored in stateMachineTranslations and updated for metadata only. If you switch both.
  // displaying messages does not work properly.

  // if (module) console.log(module.requiredglobalvariables);

  return (
    <ModuleEditorContext.Provider
      value={{
        module: module,
        nodes: nodes,
        edges: edges,
        updateElements: storeElements,
        saveModule: mutate,
        updateNodes: updateNodes,
        updateEdges: updateEdges,
        undoElements: undoElements,
        moduleLoadedInDefaultLanguage: moduleLoadedInDefaultLanguage,
        moduleid: module.moduleid,
      }}
    >
      <Grid
        container
        spacing={3}
        direction="row"
        alignContent="center"
        alignItems="stretch"
        style={{
          width: '99.5vw',
          marginLeft: '-24px',
          marginTop: '-24px',
          paddingLeft: '5px',
          paddingTop: '5px',
          paddingRight: '5px',
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            paddingLeft: '5px',
            paddingTop: '5px',
            paddingRight: '2px',
            overflow: 'auto',
            height: '100%',
          }}
        >
          <ErrorBoundary>
            <SelectionSidebar />
          </ErrorBoundary>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            paddingLeft: '5px',
            paddingTop: '5px',
            paddingRight: '5px',
          }}
        >
          {module && (
            <ErrorBoundary>
              <ModuleEditor moduleIsLoading={isLoading} />
            </ErrorBoundary>
          )}
          <Snackbar
            open={errorMessageOpen}
            autoHideDuration={15000}
            onClose={onAlertClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="error">
              <AlertTitle>
                {intl.formatMessage({
                  id: 'builder.moduleeditor.alert.title',
                  defaultMessage: 'Fehler',
                })}
              </AlertTitle>
              {intl.formatMessage({
                id: 'builder.moduleeditor.alert.hint',
                defaultMessage:
                  'Beim Speichern des Moduls ist ein Fehler aufgetreten.',
              })}
              <p>
                <b>Message:</b>
                <br />
                {updateModuleErrorWithType instanceof ApiError
                  ? updateModuleErrorWithType.message
                  : updateModuleErrorWithType}
              </p>
              <p>
                <b>Details:</b>
                <br />
                {updateModuleErrorWithType instanceof ApiError
                  ? updateModuleErrorWithType.details.message
                  : updateModuleErrorWithType}
              </p>
              <p>
                {intl.formatMessage({
                  id: 'builder.moduleeditor.alert.closehint',
                  defaultMessage:
                    'Diese Meldung schließt sich automatisch nach 15 Sekunden.',
                })}
              </p>
            </Alert>
          </Snackbar>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            paddingLeft: '2px',
            paddingTop: '5px',
            paddingRight: 0,
            marginRight: 0,
          }}
        >
          <ErrorBoundary>
            <ElementPropertySidebar />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </ModuleEditorContext.Provider>
  );
};
