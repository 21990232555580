import { CopyLink, evoachDefaultTheme } from '@evoach/ui-components';
import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { authorizedDelete, authorizedPost } from '../../../api';
import { useFetchProgramPermissionsByProgramId } from '../../../api/program/useFetchProgramPermissions';
import { ProgramPermission } from '../../../entities';
import { useEnvironment } from '../../../environment/useEnvironment';

export interface ShareProgramDialogProps {
  open: boolean;
  onCancel: () => void;
  onCreate: () => void;
  programid: string;
}

export const ShareProgramDialog: React.FC<ShareProgramDialogProps> = ({
  open,
  onCancel,
  onCreate,
  programid,
}) => {
  const { builderBasePath } = useEnvironment();

  const [invitationId, setInvitationId] = useState<string>('');

  const { programPermissions, refetch } =
    useFetchProgramPermissionsByProgramId(programid);

  const intl = useIntl();

  const acceptedInvitations = useMemo(() => {
    if (!programPermissions) return [];
    return programPermissions.filter(
      (perm: ProgramPermission) =>
        perm.invitinginvitationid !== null &&
        perm.invitinginvitationid !== undefined
    );
  }, [programPermissions]);

  // avoid creating multiple invitationIds for one program
  useEffect(() => {
    const invitationIdLinks =
      programPermissions !== undefined
        ? programPermissions.filter((perm) => perm.invitationid !== null)
        : [];
    invitationIdLinks.length > 0
      ? setInvitationId(invitationIdLinks[0].invitationid ?? '')
      : setInvitationId('');
  }, [programPermissions, open]);

  // function for CREATE button
  const createShareLink = async () => {
    if (!programid || programid === '') {
      setInvitationId('');
    }
    const invitURL = `/program/${programid}/invitation`;
    const createShareLinkCall = authorizedPost(invitURL);
    const response = await createShareLinkCall();

    if (!response.ok) {
      setInvitationId('');
    } else {
      const data = await response.json();

      setInvitationId(data.invitationid);
    }
  };

  const handleCloseAfterCreation = () => {
    setInvitationId('');
    onCreate();
  };

  const revokeAcceptedInvitation = async (accountId: string) => {
    if (
      window.confirm(
        intl.formatMessage({
          id: 'builder.coachingprogram.list.managecoachees.confirmrevoke',
          defaultMessage:
            'Bist du sicher, dass die Person aus dem Coaching Programm gelöscht werden soll?',
        })
      )
    ) {
      const permissionId = programPermissions.filter(
        (perm: ProgramPermission) =>
          perm.account?.accountid !== accountId &&
          perm.invitinginvitationid !== null &&
          perm.invitinginvitationid !== undefined
      )[0].programpermissionid;
      const revokeInviteURL = `/program/${programid}/allpermissions/${permissionId}`;
      const revokeInviteCall = authorizedDelete(revokeInviteURL);
      await revokeInviteCall();

      refetch();
    }
  };

  return (
    <>
      <Dialog fullWidth onClose={onCancel} open={open}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'builder.programs.shareprogramdialog.title',
            defaultMessage: 'Programm zum Bearbeiten teilen',
          })}
        </DialogTitle>
        <DialogContent>
          {invitationId !== '' && invitationId !== undefined ? (
            <div>
              <Box
                component="div"
                sx={{
                  typography: 'subtitle2',
                  marginBottom: '0.5rem',
                }}
              >
                {intl.formatMessage({
                  id: 'builder.programs.shareprogramdialog.yourcoachlink',
                  defaultMessage:
                    'Teile diesen Link mit Personen, damit sie dein Program bearbeiten können.',
                })}
              </Box>
              <CopyLink
                buttonText={intl.formatMessage({
                  id: 'builder.reception.modules.sharelink.buttonText',
                  defaultMessage: 'Link kopieren',
                })}
                link={`${builderBasePath}/programinvitation/${invitationId}`}
              />
            </div>
          ) : (
            <Typography
              component="span"
              variant="body1"
              style={{ marginTop: 15 }}
            >
              {intl.formatMessage({
                id: 'builder.programs.shareprogramdialog.description',
                defaultMessage:
                  'Wenn du eine Einladung für ein Program erzeugst, kann jeder Coach mit dieser Einladung das Programm bearbeiten.',
              })}
            </Typography>
          )}
          {acceptedInvitations.length > 0 && (
            <Box sx={{ marginTop: '2rem', typography: 'subtitle2' }}>
              {intl.formatMessage({
                id: 'builder.programs.shareprogramdialog.someoneaccpeted',
                defaultMessage:
                  'Momentan können folgende Personen dein Program bearbeiten:',
              })}
            </Box>
          )}
          {acceptedInvitations.map(
            (acceptedInvite: ProgramPermission, index) => (
              <Box
                sx={{ marginTop: '1em', typography: 'body1' }}
                key={index + acceptedInvite.invitinginvitationid!}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box>{`${acceptedInvite.account.givenname} ${acceptedInvite.account.familyname} (${acceptedInvite.account.email})`}</Box>
                  <Tooltip
                    placement="top"
                    arrow
                    title={intl.formatMessage({
                      id: 'builder.programs.shareprogramdialog.deletetooltip',
                      defaultMessage: 'Löschen',
                    })}
                  >
                    <Delete
                      sx={{
                        cursor: 'pointer',
                        color: evoachDefaultTheme.palette.secondary.main,
                        marginLeft: '0.5em',
                      }}
                      color="secondary"
                      onClick={() =>
                        revokeAcceptedInvitation(acceptedInvite.invitationid!)
                      }
                    />
                  </Tooltip>
                </Box>
              </Box>
            )
          )}
        </DialogContent>
        <DialogActions>
          {invitationId !== '' && invitationId !== undefined ? (
            <>
              <Button onClick={handleCloseAfterCreation}>
                {intl.formatMessage({
                  id: 'builder.programs.shareprogramdialog.buttonText',
                  defaultMessage: 'Schließen',
                })}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onCancel}>
                {intl.formatMessage({
                  id: 'builder.programs.shareprogramdialog.buttoncancel',
                  defaultMessage: 'Abbrechen',
                })}
              </Button>
              <Button onClick={createShareLink}>
                {intl.formatMessage({
                  id: 'builder.programs.shareprogramdialog.buttoncreate',
                  defaultMessage: 'Link erzeugen',
                })}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
