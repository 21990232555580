import React, { useState } from 'react';
//import { useIntl } from 'react-intl';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
  Typography,
} from '@mui/material';

import { authorizedPost, authorizedPut } from '../../../api';
import { ModuleProps } from '../../../entities/Module';

const shareLinkDialogMessages = defineMessages({
  dialogTitle: {
    id: 'builder.reception.modules.moduleByJson.dialog.dialogTitle',
    defaultMessage: 'Modul oder Vorlage über JSON Objekt erzeugen',
  },
  dialogCreateButton: {
    id: 'builder.reception.modules.moduleByJson.dialog.dialogCloseButton',
    defaultMessage: 'Modul erzeugen',
  },
  dialogCloseButton: {
    id: 'builder.reception.modules.moduleByJson.dialog.dialogCreateButton',
    defaultMessage: 'Abbrechen',
  },
});

/** perform basic check whether a JSON is a vlid module */
const validModule = (module: any): boolean => {
  let valid = true;
  valid = valid && module.translations && module.translations.length > 0;
  valid = valid && module.buildergraph;
  valid = valid && module.statemachine;
  valid = valid && module.metadata;
  return valid;
};

/** props for this button + dialog component */
export interface CreateModuleByJsonDialogProps {
  open: boolean;
  onCloseHandler: Function;
}

/** module by JSON component */
export const CreateModuleByJsonDialog: React.FC<
  CreateModuleByJsonDialogProps
> = ({ open, onCloseHandler }) => {
  const [jsonObject, setJsonObject] = useState<string>('');

  const intl = useIntl();

  const onJsonChange = (event: any) => {
    const moduleJson = event.target.value;

    try {
      if (!moduleJson || moduleJson.trim() === '') {
        setJsonObject('');
      } else {
        // basic check whether this is a valid module!
        if (!validModule(JSON.parse(moduleJson))) {
          alert(
            intl.formatMessage({
              id: 'builder.reception.modules.moduleByJson.dialog.alert.noValidModule',
              defaultMessage: 'Das ist kein gültiges Modul',
            })
          );
          setJsonObject('');
        } else {
          setJsonObject(moduleJson);
        }
      }
    } catch (_e: any) {}
  };

  const createModuleByJson = async () => {
    if (!jsonObject || jsonObject.trim() === '') return;

    const newModule: ModuleProps = JSON.parse(jsonObject);

    // what is the available translation in JSON file?
    const copiedLanguage = newModule.translations[0].lang;

    // modify module to avoid errors: allow only language copied
    newModule.metadata.defaultLanguage = copiedLanguage;
    newModule.metadata.moduleLanguages = [copiedLanguage];

    // add a label that indicates that the module was created by JSON-copy
    if (!newModule.metadata.labels) {
      newModule.metadata.labels = ['CopiedByJson'];
    } else {
      if (Array.isArray(newModule.metadata.labels)) {
        newModule.metadata.labels.push('CopiedByJson');
      } else {
        newModule.metadata.labels = ['CopiedByJson'];
      }
    }

    // modifiy title to indicate JSON copy
    const metadataTitleKey = newModule.metadata.title;
    newModule.translations[0].metadatatranslation[metadataTitleKey] =
      'BY JSON: ' +
      newModule.translations[0].metadatatranslation[metadataTitleKey];
    // title key appears both in metadatatranslation and statemachinetranslation
    newModule.translations[0].statemachinetranslation[metadataTitleKey] =
      'BY JSON: ' +
      newModule.translations[0].statemachinetranslation[metadataTitleKey];

    // reset defaultModule if available
    newModule.isdefaultmodule = false;

    //
    // 1) create module => does not considers all variables and
    // especially not the buildergraph ... that's why we have to update the module
    // immediately after creation
    //
    const createJsonModule = authorizedPost('/module', newModule);
    const createResponse = await createJsonModule();

    if (!createResponse.ok) {
      alert(
        intl.formatMessage({
          id: 'builder.reception.modules.moduleByJson.dialog.alert.creationFailed',
          defaultMessage:
            'Das Modul konnte nicht gespeichert werden (Anlegen ist fehlgeschlagen)',
        })
      );
      onCloseHandler();
    } else {
      const data = await createResponse.json();
      const moduleid = data.moduleid;

      //
      // 2) update module ==> update also all other parameters and builder graph!
      //
      const updateJsonModule = authorizedPut(`/module/${moduleid}`, newModule);
      const updateResponse = await updateJsonModule();
      if (!updateResponse.ok) {
        alert(
          intl.formatMessage({
            id: 'builder.reception.modules.moduleByJson.dialog.alert.uploadFailed',
            defaultMessage:
              'Das Modul konnte nicht gespeichert werden (Aktualisierung ist fehlgeschlagen)',
          })
        );
        onCloseHandler();
      } else {
        alert(
          intl.formatMessage({
            id: 'builder.reception.modules.moduleByJson.dialog.alert.newModuleAdded',
            defaultMessage:
              'Neues Modul / neue Vorlage erstellt. Sprachen, die in der Kopie nicht vorhanden waren, wurden aus den Metadaten gelöscht.',
          })
        );
        setJsonObject('');
        onCloseHandler();
      }
    }
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        <FormattedMessage {...shareLinkDialogMessages.dialogTitle} />
      </DialogTitle>
      <DialogContent>
        <Typography>
          {intl.formatMessage({
            id: 'builder.pages.builder.moduleByJson.dialog.languagehint',
            defaultMessage:
              'Bitte beachte, dass nur eine einzige Sprache übernommen wird, wenn du ein Modul per JSON erzeugst. Auch, wenn das Originalmodul in mehreren Sprachen verfügbar ist.',
          })}
        </Typography>
        <TextField
          label={intl.formatMessage({
            id: 'builder.pages.builder.moduleByJson.dialog.textfieldlabel',
            defaultMessage: 'Das JSON Objekt hierher kopieren ...',
          })}
          value={jsonObject}
          onChange={(evt) => onJsonChange(evt)}
          multiline
          minRows={20}
          maxRows={20}
          fullWidth
          key="TextFieldDialog"
          sx={{ marginTop: '10px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setJsonObject('');
            onCloseHandler();
          }}
        >
          <FormattedMessage {...shareLinkDialogMessages.dialogCloseButton} />
        </Button>

        <Button onClick={createModuleByJson}>
          <FormattedMessage {...shareLinkDialogMessages.dialogCreateButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
