import { CardImageEnum, ValueCardProps } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { EvoachValueList } from './ValueSelector.state';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  skip: {
    id: 'builder.nodes.hotornotselector.skipButtonText',
    defaultMessage: 'Überspringen',
  },
  list1: {
    id: 'builder.nodes.hotornotselector.listname.0',
    defaultMessage: 'Werteliste',
  },
  list2: {
    id: 'builder.nodes.hotornotselector.listname.1',
    defaultMessage: 'Moving Motivators',
  },
  list3: {
    id: 'builder.nodes.hotornotselector.listname.2',
    defaultMessage: 'Saboteure',
  },
  list4: {
    id: 'builder.nodes.hotornotselector.listname.3',
    defaultMessage: '2-Faktor Theorie',
  },
  goal: {
    id: 'builder.nodes.hotornotselector.list.1.goal',
    defaultMessage: 'Ziel',
  },
  goaldesc: {
    id: 'builder.nodes.hotornotselector.list.1.goaldesc',
    defaultMessage:
      'Mein Lebensziel spiegelt sich in der Arbeit wider, die ich tue.',
  },
  honor: {
    id: 'builder.nodes.hotornotselector.list.1.honor',
    defaultMessage: 'Ehre',
  },
  honordesc: {
    id: 'builder.nodes.hotornotselector.list.1.honordesc',
    defaultMessage:
      'Ich bin stolz darauf, dass sich meine persönlichen Werte in meiner Arbeit widerspiegeln.',
  },
  Freedom: {
    id: 'builder.nodes.hotornotselector.list.1.Freedom',
    defaultMessage: 'Freiheit',
  },
  Freedomd: {
    id: 'builder.nodes.hotornotselector.list.1.Freedomd',
    defaultMessage:
      'Ich bin unabhängig von anderen und habe meine eigene Arbeit und Verantwortung.',
  },
  Power: {
    id: 'builder.nodes.hotornotselector.list.1.Power',
    defaultMessage: 'Einfluss',
  },
  Powerd: {
    id: 'builder.nodes.hotornotselector.list.1.Powerd',
    defaultMessage:
      'Es gibt genug Raum für mich, um zu beeinflussen, was um mich herum geschieht.',
  },
  Curiosity: {
    id: 'builder.nodes.hotornotselector.list.1.Curiosity',
    defaultMessage: 'Neugier',
  },
  Curiosityd: {
    id: 'builder.nodes.hotornotselector.list.1.Curiosityd',
    defaultMessage:
      'Ich habe viele Dinge zu untersuchen und darüber nachzudenken.',
  },
  Mastery: {
    id: 'builder.nodes.hotornotselector.list.1.Mastery',
    defaultMessage: 'Kompetenz',
  },
  Masteryd: {
    id: 'builder.nodes.hotornotselector.list.1.Masteryd',
    defaultMessage:
      'Meine Arbeit fordert meine Kompetenz heraus, liegt aber noch im Rahmen meiner Möglichkeiten.',
  },
  Status: {
    id: 'builder.nodes.hotornotselector.list.1.Status',
    defaultMessage: 'Status',
  },
  Statusd: {
    id: 'builder.nodes.hotornotselector.list.1.Statusd',
    defaultMessage:
      'Meine Position ist gut und wird von den Menschen, die mit mir arbeiten, anerkannt.',
  },
  Order: {
    id: 'builder.nodes.hotornotselector.list.1.Order',
    defaultMessage: 'Ordnung',
  },
  Orderd: {
    id: 'builder.nodes.hotornotselector.list.1.Orderd',
    defaultMessage:
      'Es gibt genügend Regeln und Strategien für ein stabiles Umfeld.',
  },
  Acceptance: {
    id: 'builder.nodes.hotornotselector.list.1.Acceptance',
    defaultMessage: 'Anerkennung',
  },
  Acceptanced: {
    id: 'builder.nodes.hotornotselector.list.1.Acceptanced',
    defaultMessage:
      'Die Menschen um mich herum finden gut, was ich tue und wer ich bin.',
  },
  Relatedness: {
    id: 'builder.nodes.hotornotselector.list.1.Relatedness',
    defaultMessage: 'Eingebettetsein',
  },
  Relatednessd: {
    id: 'builder.nodes.hotornotselector.list.1.Relatednessd',
    defaultMessage:
      'Ich habe gute soziale Kontakte zu den Menschen in und um meinen Arbeitsplatz.',
  },
  controller: {
    id: 'builder.nodes.hotornotselector.list.2.controller',
    defaultMessage: 'Kontrolleur',
  },
  controllerd: {
    id: 'builder.nodes.hotornotselector.list.2.controllerdesc',
    defaultMessage:
      'Angstbedingtes Bedürfnis, die Kontrolle zu übernehmen und Situationen und Handlungen von Menschen nach dem eigenen Willen zu steuern. Große Angst und Ungeduld, wenn dies nicht möglich ist.',
  },
  restless: {
    id: 'builder.nodes.hotornotselector.list.2.restless',
    defaultMessage: 'Rastlose',
  },
  restlessd: {
    id: 'builder.nodes.hotornotselector.list.2.restlessdesc',
    defaultMessage:
      'Ständig auf der Suche nach mehr Reiz bei der nächsten Aktivität oder ständige Beschäftigung. Selten mit der aktuellen Tätigkeit zufrieden oder in sich ruhend.',
  },
  hyperachiever: {
    id: 'builder.nodes.hotornotselector.list.2.hyperachiever',
    defaultMessage: 'Überflieger',
  },
  hyperachieverd: {
    id: 'builder.nodes.hotornotselector.list.2.hyperachieverdesc',
    defaultMessage:
      'Abhängig von ständiger Leistung und Erfolg für das eigene Selbstwertgefühl und zur Selbstbestätigung. Die letzte Errungenschaft wird schnell abgewertet, und der Bedarf nach mehr steigt wieder.',
  },
  stickler: {
    id: 'builder.nodes.hotornotselector.list.2.stickler',
    defaultMessage: 'Pedant',
  },
  sticklerd: {
    id: 'builder.nodes.hotornotselector.list.2.sticklerdesc',
    defaultMessage:
      'Übertriebener Perfektionismus und ein Bedürfnis nach Ordnung und Organisation. Versucht krampfhaft, zu viele Dinge perfekt zu machen.',
  },
  avoider: {
    id: 'builder.nodes.hotornotselector.list.2.avoider',
    defaultMessage: 'Vermeider',
  },
  avoiderd: {
    id: 'builder.nodes.hotornotselector.list.2.avoiderdesc',
    defaultMessage:
      'Konzentriert sich in extremer Weise rein auf das Positive und Angenehme. Vermeidet schwierige und unangenehme Aufgaben und Konflikte.',
  },
  hyperrational: {
    id: 'builder.nodes.hotornotselector.list.2.hyperrational',
    defaultMessage: 'Hyper-Rationalist',
  },
  hyperrationald: {
    id: 'builder.nodes.hotornotselector.list.2.hyperrationaldesc',
    defaultMessage:
      'Konzentriert sich intensiv und ausschließlich auf die rationale Verarbeitung von allem, auch von Beziehungen. Kann als gefühllos, rücksichtslos oder intellektuell arrogant wahrgenommen werden.',
  },
  victim: {
    id: 'builder.nodes.hotornotselector.list.2.victim',
    defaultMessage: 'Opfer',
  },
  victimd: {
    id: 'builder.nodes.hotornotselector.list.2.victimdesc',
    defaultMessage:
      'Sie agieren emotional und launisch, um Aufmerksamkeit und Zuneigung zu erlangen. Extremer Fokus auf innere Gefühle, insbesondere auf schmerzhafte. Märtyrer-Attitüde.',
  },
  pleaser: {
    id: 'builder.nodes.hotornotselector.list.2.pleaser',
    defaultMessage: 'Schmeichler',
  },
  pleaserd: {
    id: 'builder.nodes.hotornotselector.list.2.pleaserdesc',
    defaultMessage:
      'Versuchen indirekt, Akzeptanz und Zuneigung zu erhalten, indem sie anderen helfen, ihnen gefallen, sie retten oder ihnen schmeicheln. Verliert die eigenen Bedürfnisse aus den Augen und wird dadurch verbittert.',
  },
  judge: {
    id: 'builder.nodes.hotornotselector.list.2.judge',
    defaultMessage: 'Richter',
  },
  judged: {
    id: 'builder.nodes.hotornotselector.list.2.judgedesc',
    defaultMessage:
      'Universeller Saboteur, der jeden beeinträchtigt. Er ist derjenige, der einen immer wieder wegen Fehlern oder Unzulänglichkeiten niedermacht, der zwanghaft vor zukünftigen Risiken warnt, der einen mitten in der Nacht aufweckt, um sich Sorgen zu machen, der darauf fixiert, was mit anderen oder Ihrem Leben nicht stimmt, usw.. Der Richter aktiviert anderen Saboteure, verursacht einen Großteil des Stresses und des Unglücklichseins.',
  },
  hypervigilant: {
    id: 'builder.nodes.hotornotselector.list.2.hypervigilant',
    defaultMessage: 'Angsthase',
  },
  hypervigilantd: {
    id: 'builder.nodes.hotornotselector.list.2.hypervigilantdesc',
    defaultMessage:
      'Anhaltende intensive Angst vor allen Gefahren und dem, was schief gehen könnte. Eine nie ruhende Wachsamkeit.',
  },
  advancement: {
    id: 'builder.nodes.hotornotselector.list.3.advancement',
    defaultMessage: 'Weiterentwicklung',
  },
  advancementdesc: {
    id: 'builder.nodes.hotornotselector.list.3.advancementdesc',
    defaultMessage:
      'Ich bin zufrieden mit der Art und Weise, wie ich die mir gestellten Aufgaben löse und sehe positive Ergebnisse bei den gesetzten Zielen.',
  },
  recognition: {
    id: 'builder.nodes.hotornotselector.list.3.recognition',
    defaultMessage: 'Anerkennung',
  },
  recognitiondesc: {
    id: 'builder.nodes.hotornotselector.list.3.recognitiondesc',
    defaultMessage:
      'Ich fühle mich wertgeschätzt und erhalte ausreichend Lob für das Erreichen meiner Ziele und die gute Zusammenarbeit mit Kollegen, Vorgesetzten und Teams.',
  },
  theworkitself: {
    id: 'builder.nodes.hotornotselector.list.3.theworkitself',
    defaultMessage: 'Die Arbeit selbst',
  },
  theworkitselfdesc: {
    id: 'builder.nodes.hotornotselector.list.3.theworkitselfdesc',
    defaultMessage:
      'Ich mag, was ich tue, und meine Arbeit fordert meine Fähigkeiten heraus, liegt aber noch im Rahmen meiner Möglichkeiten.',
  },
  responsibility: {
    id: 'builder.nodes.hotornotselector.list.3.responsibility',
    defaultMessage: 'Verantwortung',
  },
  responsibilitydesc: {
    id: 'builder.nodes.hotornotselector.list.3.responsibilitydesc',
    defaultMessage:
      'Ich erhalte genug Verantwortung und Entscheidungsfreiheit, um gute Lösungen zu finden.',
  },
  promotion: {
    id: 'builder.nodes.hotornotselector.list.3.promotion',
    defaultMessage: 'Weiterkommen/Promotion',
  },
  promotiondesc: {
    id: 'builder.nodes.hotornotselector.list.3.promotiondesc',
    defaultMessage:
      'Ich habe die Möglichkeit aufzusteigen und meinen Status und meine Position zu verbessern.',
  },
  possibilityforgrowth: {
    id: 'builder.nodes.hotornotselector.list.3.possibilityforgrowth',
    defaultMessage: 'Wachstumsmöglichkeit',
  },
  possibilityforgrowthdesc: {
    id: 'builder.nodes.hotornotselector.list.3.possibilityforgrowthdesc',
    defaultMessage:
      'Ich habe die Möglichkeit, mich persönlich weiterzuentwickeln und aufzusteigen, indem ich neue Fertigkeiten und Techniken bei der Arbeit entwickle und mir berufliches Wissen aneigne.',
  },
  policiesandrules: {
    id: 'builder.nodes.hotornotselector.list.3.policiesandrules',
    defaultMessage: 'Regeln & Richtlinien',
  },
  policiesandrulesdesc: {
    id: 'builder.nodes.hotornotselector.list.3.policiesandrulesdesc',
    defaultMessage:
      'Es gibt genügend Regeln und Richtlinien für ein stabiles Umfeld, sie sind mir klar und werden gut vermittelt und behindern meinen Arbeitsablauf nicht auf negative Weise.',
  },
  status: {
    id: 'builder.nodes.hotornotselector.list.3.status',
    defaultMessage: 'Status',
  },
  statusdesc: {
    id: 'builder.nodes.hotornotselector.list.3.statusdesc',
    defaultMessage:
      'Meine Position ist gut, und ich fühle mich für meine Arbeit anerkannt.',
  },
  salary: {
    id: 'builder.nodes.hotornotselector.list.3.salary',
    defaultMessage: 'Gehalt',
  },
  salarydesc: {
    id: 'builder.nodes.hotornotselector.list.3.salarydesc',
    defaultMessage:
      'Mein Gehalt und andere Leistungen reichen aus, um das Leben zu führen, das ich mir wünsche.',
  },
  safety: {
    id: 'builder.nodes.hotornotselector.list.3.safety',
    defaultMessage: 'Sicherheit',
  },
  safetydesc: {
    id: 'builder.nodes.hotornotselector.list.3.safetydesc',
    defaultMessage:
      'Ich fühle mich an meinem Arbeitsplatz körperlich und psychisch sicher.',
  },
  supervision: {
    id: 'builder.nodes.hotornotselector.list.3.supervision',
    defaultMessage: 'Vorgesetzte',
  },
  supervisiondesc: {
    id: 'builder.nodes.hotornotselector.list.3.supervisiondesc',
    defaultMessage:
      'Mein Vorgesetzten sind fair, kompetent und unterstützen mich dabei, meine Ziele zu erreichen.',
  },
  effectonpersonallife: {
    id: 'builder.nodes.hotornotselector.list.3.effectonpersonallife',
    defaultMessage: 'Auswirkung auf Privatleben',
  },
  effectonpersonallifedesc: {
    id: 'builder.nodes.hotornotselector.list.3.effectonpersonallifedesc',
    defaultMessage:
      'Meine Arbeit hat keine negativen Auswirkungen auf mein Privatleben (lange Arbeitszeiten, Stress, Pendeln, Verfügbarkeit, Reisen).',
  },
  relationships: {
    id: 'builder.nodes.hotornotselector.list.3.relationships',
    defaultMessage: 'Beziehungen',
  },
  relationshipsdesc: {
    id: 'builder.nodes.hotornotselector.list.3.relationshipsdesc',
    defaultMessage:
      'Ich pflege gute Arbeits- und soziale Beziehungen zu meinen Mitarbeitern, Vorgesetzten, Kollegen und Untergebenen während der Arbeit.',
  },
  workingconditions: {
    id: 'builder.nodes.hotornotselector.list.3.workingconditions',
    defaultMessage: 'Arbeitsumgebung',
  },
  workingconditionsdesc: {
    id: 'builder.nodes.hotornotselector.list.3.workingconditionsdesc',
    defaultMessage:
      'Die physische Arbeitsumgebung bietet alles, was ich brauche (Platz, Belüftung, Temperatur, Nähe, Sicherheit usw.), und ich habe alle Werkzeuge, die ich zum Arbeiten brauche.',
  },
});

// use EvoachValueList, defined in ValueSelector.state.tsx to generate ValueCards
const preDefinedValueCards: ValueCardProps[] = EvoachValueList.map(
  (evalue: any) => {
    return {
      type: 'hotornotcard',
      value: evalue.value,
      description: undefined,
      image: evalue.image,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps;
  }
);

// define list interface
export interface HotOrNotListEntryProps {
  listName: string;
  cards: ValueCardProps[];
}

// create empty list
export const predefinedHotOrNotLists: HotOrNotListEntryProps[] = [];

// element 0 in list => default of version 1 (values)
predefinedHotOrNotLists.push({
  listName: 'builder.nodes.hotornotselector.listname.0',
  cards: preDefinedValueCards,
});

// element 1 in list => Moving Motivators
predefinedHotOrNotLists.push({
  listName: 'builder.nodes.hotornotselector.listname.1',
  cards: [
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.goal',
      description: 'builder.nodes.hotornotselector.list.1.goaldesc',
      image: CardImageEnum.goal,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.honor',
      description: 'builder.nodes.hotornotselector.list.1.honordesc',
      image: CardImageEnum.honor,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.Freedom',
      description: 'builder.nodes.hotornotselector.list.1.Freedomd',
      image: CardImageEnum.freedom,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.Power',
      description: 'builder.nodes.hotornotselector.list.1.Powerd',
      image: CardImageEnum.power,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.Curiosity',
      description: 'builder.nodes.hotornotselector.list.1.Curiosityd',
      image: CardImageEnum.curiosity,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.Mastery',
      description: 'builder.nodes.hotornotselector.list.1.Masteryd',
      image: CardImageEnum.mastery,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.Status',
      description: 'builder.nodes.hotornotselector.list.1.Statusd',
      image: CardImageEnum.status,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.Order',
      description: 'builder.nodes.hotornotselector.list.1.Orderd',
      image: CardImageEnum.order,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.Acceptance',
      description: 'builder.nodes.hotornotselector.list.1.Acceptanced',
      image: CardImageEnum.acceptance,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.1.Relatedness',
      description: 'builder.nodes.hotornotselector.list.1.Relatednessd',
      image: CardImageEnum.relatedness,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
  ],
});

predefinedHotOrNotLists.push({
  listName: 'builder.nodes.hotornotselector.listname.2',
  cards: [
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.controller',
      description: 'builder.nodes.hotornotselector.list.2.controllerdesc',
      image: CardImageEnum.controller,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.restless',
      description: 'builder.nodes.hotornotselector.list.2.restlessdesc',
      image: CardImageEnum.restless,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.hyperachiever',
      description: 'builder.nodes.hotornotselector.list.2.hyperachieverdesc',
      image: CardImageEnum.excitement,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.stickler',
      description: 'builder.nodes.hotornotselector.list.2.sticklerdesc',
      image: CardImageEnum.stickler,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.avoider',
      description: 'builder.nodes.hotornotselector.list.2.avoiderdesc',
      image: CardImageEnum.avoider,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.hyperrational',
      description: 'builder.nodes.hotornotselector.list.2.hyperrationaldesc',
      image: CardImageEnum.hyper_rational,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.victim',
      description: 'builder.nodes.hotornotselector.list.2.victimdesc',
      image: CardImageEnum.victim,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.pleaser',
      description: 'builder.nodes.hotornotselector.list.2.pleaserdesc',
      image: CardImageEnum.pleaser,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.judge',
      description: 'builder.nodes.hotornotselector.list.2.judgedesc',
      image: CardImageEnum.strength,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.2.hypervigilant',
      description: 'builder.nodes.hotornotselector.list.2.hypervigilantdesc',
      image: CardImageEnum.hyper_vigilant,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
  ],
});

predefinedHotOrNotLists.push({
  listName: 'builder.nodes.hotornotselector.listname.3',
  cards: [
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.advancement',
      description: 'builder.nodes.hotornotselector.list.3.advancementdesc',
      image: CardImageEnum.power1,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.recognition',
      description: 'builder.nodes.hotornotselector.list.3.recognitiondesc',
      image: CardImageEnum.recognition2,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.theworkitself',
      description: 'builder.nodes.hotornotselector.list.3.theworkitselfdesc',
      image: CardImageEnum.m_work,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.responsibility',
      description: 'builder.nodes.hotornotselector.list.3.responsibilitydesc',
      image: CardImageEnum.freedom,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.promotion',
      description: 'builder.nodes.hotornotselector.list.3.promotiondesc',
      image: CardImageEnum.goal,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.possibilityforgrowth',
      description:
        'builder.nodes.hotornotselector.list.3.possibilityforgrowthdesc',
      image: CardImageEnum.curiosity,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.policiesandrules',
      description: 'builder.nodes.hotornotselector.list.3.policiesandrulesdesc',
      image: CardImageEnum.h_policies_rules,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.status',
      description: 'builder.nodes.hotornotselector.list.3.statusdesc',
      image: CardImageEnum.status,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.salary',
      description: 'builder.nodes.hotornotselector.list.3.salarydesc',
      image: CardImageEnum.h_salary,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.safety',
      description: 'builder.nodes.hotornotselector.list.3.safetydesc',
      image: CardImageEnum.h_safe,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.supervision',
      description: 'builder.nodes.hotornotselector.list.3.supervisiondesc',
      image: CardImageEnum.h_supervision,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.effectonpersonallife',
      description:
        'builder.nodes.hotornotselector.list.3.effectonpersonallifedesc',
      image: CardImageEnum.effectonpersonallife,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.relationships',
      description: 'builder.nodes.hotornotselector.list.3.relationshipsdesc',
      image: CardImageEnum.raltedned,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
    {
      type: 'hotornotcard',
      value: 'builder.nodes.hotornotselector.list.3.workingconditions',
      description:
        'builder.nodes.hotornotselector.list.3.workingconditionsdesc',
      image: CardImageEnum.h_work_conditions,
      skipButtonText: '', // is currently not used
      liked: undefined,
    } as ValueCardProps,
  ],
});

export const hotOrNotSelectorStateEntry: StateEntryProps = {
  version: 2,
  type: 'renderHotOrNotSelector',
  temporary: true, // remove selector after finishing
  payload: {
    cards: preDefinedValueCards,
    getValueFrom: '',
    saveResultTo: '',
    buttonText: 'builder.nodes.hotornotselector.skipButtonText', // will replace skipButtonText for each card
  },
  predefinedListIndex: 0,
  ownElements: [],
  useOwnElements: false,
  saveResultType: ReadWriteType.typedObject,
  getValueType: ReadWriteType.typedObject,
  displayName: 'Hot-or-not Selector',
  nodeType: 'hotOrNotSelectorStateEntry',
  handleOutCount: 1,
};
