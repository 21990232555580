import React, { useContext, useState } from 'react';
import { Grid, Button, Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../../account';
import ErrorBoundary from '../../ErrorBoundary';

import { AnalysisModuleSessions } from './AnalysisModuleSessions';
import { AnalysisModuleTranslationQuality } from './AnalysisModuleTranslationQuality';
import { AnalysisModuleSessionProgress } from './AnalysisModuleSessionProgress';

export const AnalyticsTab: React.FC = () => {
  const [analysisId, setAnalysisId] = useState<number>(1);
  const intl = useIntl();
  const { hasRole } = useContext(AccountContext);

  return (
    <div>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={3}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'fit-content',
                }}
              >
                <Button
                  variant="contained"
                  onClick={(_evt) => {
                    setAnalysisId(1);
                  }}
                  color="secondary"
                >
                  {intl.formatMessage({
                    id: 'builder.analytics.250174fc-1268-1441-8e5a-c3e40001ade7.button',
                    defaultMessage: 'Anzahl der Sessions pro Modul.',
                  })}
                </Button>
                {hasRole(RoleEnum.EVOACHADMIN) && (
                  <>
                    <Button
                      variant="contained"
                      onClick={(_evt) => {
                        setAnalysisId(2);
                      }}
                      color="secondary"
                      sx={{ marginTop: '10px' }}
                    >
                      {intl.formatMessage({
                        id: 'builder.analytics.moduletransqa.button',
                        defaultMessage: 'Übersetzungsqualität deiner Module.',
                      })}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(_evt) => {
                        setAnalysisId(3);
                      }}
                      color="secondary"
                      sx={{ marginTop: '10px' }}
                    >
                      {intl.formatMessage({
                        id: 'builder.analytics.sessionstats.button',
                        defaultMessage: 'Status aller Sessions deiner Module',
                      })}
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={9}>
              {analysisId === 1 && (
                <ErrorBoundary>
                  <AnalysisModuleSessions />
                </ErrorBoundary>
              )}

              {analysisId === 2 && (
                <ErrorBoundary>
                  <AnalysisModuleTranslationQuality />
                </ErrorBoundary>
              )}

              {analysisId === 3 && (
                <ErrorBoundary>
                  <AnalysisModuleSessionProgress />
                </ErrorBoundary>
              )}

              {analysisId === 0 &&
                intl.formatMessage({
                  id: 'builder.propertypane.emotionsinput.checkboxtext',
                  defaultMessage: 'Bitte wähle links eine Statistik aus.',
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
