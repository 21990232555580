import { useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress, Modal, Paper } from '@mui/material';
import { defineMessages } from '@formatjs/intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { defineMessage, useIntl } from 'react-intl';

import { useEvoachKeycloak } from '../account/useEvoachKeycloak';
import { useEnvironment } from '../environment/useEnvironment';
import { AppRoutes } from '../routing/routes';
import { AccountContext } from '../account';
import { SubscriptionType } from '../entities/subscriptionTypes';

const plansPageTexts = defineMessages({
  subText: {
    id: 'builder.plans.offer.subtext',
    defaultMessage: '30 Tage kostenlose Probeversion',
  },
  loginHint: {
    id: 'builder.plans.hint.loginorsignup',
    defaultMessage:
      'Bitte einloggen / registrieren, um sicher mit dem Bestellprozess fortzufahren',
  },
  forwardToCheckout: {
    id: 'builder.plans.hint.forwardtocheckout',
    defaultMessage: 'Weiterleiten zum Checkout...',
  },
  getStarted: {
    id: 'builder.plans.header.headline',
    defaultMessage: "Los geht's!",
  },
  headerDescription: {
    id: 'builder.plans.header.description',
    defaultMessage:
      'Erstelle mit unserem Coaching Creator deine eigenen, Coaching-Chatbots und Coaching-Programme mit AI!',
  },
  buttonText: {
    id: 'builder.plans.offer.buttontext',
    defaultMessage: 'Auswählen',
  },
});

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '4px',
  p: 4,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
};

const useCheckout = () => {
  const { account } = useContext(AccountContext);
  const clientReferenceId = `?client_reference_id=${account?.accountid}`;
  //TODO differentiate STAGING vs PRODUCTION
  return {
    checkoutUrls: {
      [SubscriptionType.STARTER]: `https://buy.stripe.com/test_aEUg0ggXSaQ903C8wy${clientReferenceId}`,
      [SubscriptionType.BASIC]: `https://buy.stripe.com/test_9AQdS80YU7DX4jSaEH${clientReferenceId}`,
      [SubscriptionType.GROW]: `https://buy.stripe.com/test_5kAaFW0YU0bveYw5ko${clientReferenceId}`,
      [SubscriptionType.FREE_TIER]: false,
      [SubscriptionType.TRIAL_30_DAYS]: false,
      [SubscriptionType.PRO]: false,
    },
  };
};

const usePlans = () => {
  const plans = [
    {
      title: 'Starter "AI"',
      price: '29',
      offer: SubscriptionType.STARTER,
      description: [
        defineMessage({
          id: 'builder.plans.offer.starter.descriptions.1',
          defaultMessage:
            'Starte mit ersten Chatbots zur Vor- und Nachbereitung von Sessions',
        }),
        defineMessage({
          id: 'builder.plans.offer.starter.descriptions.2',
          defaultMessage: '10 aktive Nutzer (Klienten)',
        }),
        defineMessage({
          id: 'builder.plans.offer.starter.descriptions.3',
          defaultMessage: '50 Sessions/Monat',
        }),
        defineMessage({
          id: 'builder.plans.offer.starter.descriptions.4',
          defaultMessage: '1 Admin',
        }),
        defineMessage({
          id: 'builder.plans.offer.starter.descriptions.5',
          defaultMessage: 'Wöchentlicher Open-Hour Support',
        }),
        defineMessage({
          id: 'builder.plans.offer.starter.descriptions.6',
          defaultMessage:
            'Begrenzte Chatbots: AI Chatbots nur zur Vor- und Nachbereitung von Coaching Sitzungen',
        }),
      ],
    },
    {
      title: 'Basic "AI"',
      price: '99',
      offer: SubscriptionType.BASIC,
      description: [
        defineMessage({
          id: 'builder.plans.offer.basic.descriptions.1',
          defaultMessage: 'Arbeite effektiv mit Einzelpersonen & Teams',
        }),
        defineMessage({
          id: 'builder.plans.offer.basic.descriptions.2',
          defaultMessage:
            'Unbegrenzt (AI) Chatbots teilen, bearbeiten und erstellen',
        }),
        defineMessage({
          id: 'builder.plans.offer.basic.descriptions.3',
          defaultMessage: '10 Nutzer (gleichzeitige Coaching Prozesse)',
        }),
        defineMessage({
          id: 'builder.plans.offer.basic.descriptions.4',
          defaultMessage: '100 Sessions/Monat',
        }),
        defineMessage({
          id: 'builder.plans.offer.basic.descriptions.5',
          defaultMessage: '1 Admin',
        }),
        defineMessage({
          id: 'builder.plans.offer.basic.descriptions.6',
          defaultMessage: 'Beinhaltet alle momentanen AI Features',
        }),
      ],
    },
    {
      title: 'Grow',
      price: '299',
      offer: SubscriptionType.GROW,
      description: [
        defineMessage({
          id: 'builder.plans.offer.grow.descriptions.1',
          defaultMessage:
            'Perfekt um ein hybrides Gruppen-Coaching-Programm kreieren.',
        }),
        defineMessage({
          id: 'builder.plans.offer.grow.descriptions.2',
          defaultMessage: 'Enthält alle Features aus dem Basic "AI" Plan',
        }),
        defineMessage({
          id: 'builder.plans.offer.grow.descriptions.3',
          defaultMessage: '500 Nutzer Sessions',
        }),
        defineMessage({
          id: 'builder.plans.offer.grow.descriptions.4',
          defaultMessage: '5 Admins',
        }),
        defineMessage({
          id: 'builder.plans.offer.grow.descriptions.5',
          defaultMessage: 'Coaching Program Creator',
        }),
        defineMessage({
          id: 'builder.plans.offer.grow.descriptions.6',
          defaultMessage: 'Unbegrenzt Coaching Chatbots und Programme',
        }),
      ],
    },
  ];
  return plans;
};

export const PlansPage = () => {
  const { builderBasePath } = useEnvironment();
  const { checkoutUrls } = useCheckout();
  const { isAuthenticated } = useEvoachKeycloak();
  const navigate = useNavigate();
  const intl = useIntl();

  const plans = usePlans();

  const loginHintRef = useRef<null | HTMLDivElement>(null);

  const [isOfferSelectedAtLeastOnce, setIsOfferSelectedAtLeastOnce] =
    useState(false);
  const [isForwardToCheckoutMessageShown, setIsForwardToCheckoutMessageShown] =
    useState(false);

  useEffect(() => {
    if (isAuthenticated || !isOfferSelectedAtLeastOnce) return;
    const scrollIntoView = () => {
      if (loginHintRef && loginHintRef.current) {
        loginHintRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    };

    const timeoutId = setTimeout(scrollIntoView, 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOfferSelectedAtLeastOnce, isAuthenticated]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="90vh"
    >
      <Box>
        <Modal open={isForwardToCheckoutMessageShown}>
          <Box sx={modalStyle}>
            <Typography
              variant="h6"
              margin="1em"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                spacing: 2,
              }}
            >
              {intl.formatMessage(plansPageTexts.forwardToCheckout)} &nbsp;{' '}
              <CircularProgress />
            </Typography>
          </Box>
        </Modal>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
        />
        <CssBaseline />

        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 3, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            {intl.formatMessage(plansPageTexts.getStarted)}
          </Typography>
          <Grid container display="flex" alignItems="center" spacing={4}>
            <Grid item xs={8} sm={8} md={8}>
              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                component="p"
              >
                {intl.formatMessage(plansPageTexts.headerDescription)}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <img
                src={`${builderBasePath}/assets/alpina.png`}
                alt=""
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="lg" component="main">
          <Grid container spacing={3} alignItems="stretch">
            {plans.map((plan) => (
              <Grid
                item
                key={plan.title}
                xs={12}
                sm={12}
                md={4}
                sx={{ marginBottom: 2 }}
              >
                <Card
                  sx={{
                    pb: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardHeader
                    title={plan.title}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        €{plan.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        {intl.formatMessage({
                          id: 'builder.plans.offer.permonth',
                          defaultMessage: '/ Monat',
                        })}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <ul>
                        {plan.description.map((line, i) => (
                          <Typography
                            component="li"
                            variant="body2"
                            align="center"
                            key={line.id}
                            sx={{
                              pb: i === 0 ? 3 : 1,
                              pt: i === 0 ? 2 : 0,
                            }}
                          >
                            {intl.formatMessage(line)}
                          </Typography>
                        ))}
                      </ul>
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{
                      pl: 8,
                      pr: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Typography
                      component="div"
                      variant="body2"
                      align="center"
                      color="gray"
                    >
                      {intl.formatMessage(plansPageTexts.subText)}
                    </Typography>

                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        if (isAuthenticated) {
                          setIsForwardToCheckoutMessageShown(true);

                          if (!checkoutUrls[plan.offer]) return;

                          const offerCheckoutUrl = checkoutUrls[
                            plan.offer
                          ] as string;

                          setTimeout(
                            () => (window.location.href = offerCheckoutUrl),
                            1000
                          );
                        } else {
                          setIsOfferSelectedAtLeastOnce(true);
                        }
                      }}
                    >
                      {intl.formatMessage(plansPageTexts.buttonText)}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
            {isOfferSelectedAtLeastOnce && !isAuthenticated && (
              <Grid item xs={12} sm={12} md={12}>
                <Paper
                  sx={{
                    padding: '2em',
                    marginBottom: '1em',
                    transition: 'all .5s ease-in-out',
                  }}
                  elevation={10}
                >
                  <Grid container gap={2}>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      lg={7}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography>
                        {intl.formatMessage(plansPageTexts.loginHint)}
                        &nbsp;&nbsp;
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box>
                        <Button
                          onClick={() => {
                            const loginUrlWithRedirect = `${AppRoutes.LOGIN}?custom_redirect=plans`;
                            navigate(loginUrlWithRedirect);
                          }}
                        >
                          {intl.formatMessage({
                            id: 'builder.plans.hint.login.button',
                            defaultMessage: 'Login / Registrieren',
                          })}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Box ref={loginHintRef} />
        </Container>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ typography: 'body2', marginTop: '5em' }}
      >
        <Box marginBottom="7px">
          <img
            src={`${builderBasePath}/logo512.png`}
            alt=""
            height="100px"
          ></img>
        </Box>
        <Box marginBottom="7px">evoach GmbH</Box>
        <Box marginBottom="7px">Rebecca Rutschmann & Anke Paulick</Box>
        <Box>Haid-und-Neu-Straße 7, 76131 Karlsruhe - Germany</Box>
        <Box>Contact: team@evoach.com</Box>
        <Box>
          Imprint:{' '}
          <a href="https://www.evoach.com/imprint">www.evoach.com/imprint</a>
        </Box>
      </Box>
    </Box>
  );
};
