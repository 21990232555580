import { evoachDefaultTheme } from '@evoach/ui-components';

import { StateEntryProps } from '.';
/*
  loopStateEntry xState specific defintions
*/
export const loopStateEntry: StateEntryProps = {
  version: 'v1',
  type: 'loopTemplate',
  temporary: false,
  payload: {},
  displayName: 'Loop',
  nodeType: 'loopStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handledByTemplate: true,
};
