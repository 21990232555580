import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Checkbox,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { AccountContext, RoleEnum } from '../../../account';
import { useFetchSubscriptions } from '../../../api/subscription/useFetchSubscriptions';
import { Subscription } from '../../../entities/Subscription';
import { formatDate, useTableStyles } from '../../tableHelper';

import { SubscriptionEnableToggle } from './SubscriptionEnableToggle';
import { SubscriptionTypeText } from './SubscriptionTypeText';
import { SubscriptionAddPlan } from './SubscriptionAddPlan';

interface SubscriptionListProps {
  accountid: string;
  onUpdate?: Function;
}

export const SubscriptionList: React.FC<SubscriptionListProps> = ({
  accountid,
  onUpdate,
}) => {
  const intl = useIntl();
  const classes = useTableStyles();
  const { subscriptions, refetch } = useFetchSubscriptions(accountid);
  const { hasRole } = useContext(AccountContext);
  const [addPlanIsOpen, setAddPlanIsOpen] = useState<boolean>(false);

  const [hideInactive, setHideInactive] = useState<boolean>(true);

  const planIsActive = (enddate: any) =>
    enddate === null ||
    (enddate !== null && new Date(enddate) > new Date(Date.now()));

  const pnum = subscriptions?.length ?? 0;
  const hnum =
    subscriptions?.filter((s: Subscription) =>
      hideInactive ? planIsActive(s.enddate) : true
    ).length ?? 0;

  return (
    <>
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="body2">
          <Checkbox
            id="isactivesub"
            key="isactivesub"
            checked={hideInactive}
            color="secondary"
            onChange={() => {
              setHideInactive(!hideInactive);
            }}
          />
          {intl.formatMessage(
            {
              id: 'builder.subscriptionlist.filter.hideinactive',
              defaultMessage:
                'Abgelaufene Plände ausblenden. Zeige {hnum}/{pnum}',
            },
            { hnum: hnum, pnum: pnum }
          )}
        </Typography>
        {subscriptions && subscriptions.length > 0 ? (
          <TableContainer
            component={Paper}
            style={{
              minHeight: '100%',
              overflowX: 'initial',
            }}
          >
            <Table
              stickyHeader
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                  paddingBottom: '0.5rem',
                },
              }}
            >
              <colgroup>
                <col style={{ width: '10%', textAlign: 'left' }} />
                <col style={{ width: '10%', textAlign: 'left' }} />
                <col style={{ width: '10%', textAlign: 'left' }} />
                {hasRole(RoleEnum.EVOACHADMIN) && (
                  <col style={{ width: '10%', textAlign: 'left' }} />
                )}
              </colgroup>
              <TableHead className={classes.noWrapChildren}>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.subscriptionlist.table.header.type',
                      defaultMessage: 'Typ',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.subscriptionlist.table.header.start',
                      defaultMessage: 'Aktiviert am',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.subscriptionlist.table.header.end',
                      defaultMessage: 'Enddatum',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.subscriptionlist.table.header.actions',
                      defaultMessage: 'Aktiv',
                    })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions
                  .filter((s: Subscription) =>
                    hideInactive ? planIsActive(s.enddate) : true
                  )
                  .map((row: Subscription) => (
                    <TableRow
                      key={'subsclistrow' + row.subscriptionid}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      hover={true}
                    >
                      <TableCell>
                        <SubscriptionTypeText
                          type={row.subscriptioninfo.type}
                        />
                      </TableCell>
                      <TableCell>{formatDate(row.startdate)}</TableCell>
                      <TableCell>{formatDate(row.enddate)}</TableCell>
                      <TableCell>
                        {hasRole(RoleEnum.EVOACHADMIN) ? (
                          <SubscriptionEnableToggle
                            subscriptionid={row.subscriptionid}
                            onChange={() => refetch()}
                            subscriptions={[...subscriptions]}
                          />
                        ) : planIsActive(row.enddate) ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" sx={{ marginTop: '20px' }}>
            {intl.formatMessage({
              id: 'builder.subscriptionlist.nosubscriptions',
              defaultMessage: 'Keine Subscriptions vorhanden.',
            })}
          </Typography>
        )}
        <Button
          color="secondary"
          sx={{ marginTop: '15px' }}
          onClick={() => setAddPlanIsOpen(true)}
        >
          {intl.formatMessage({
            id: 'builder.subscriptionlist.button.addplan',
            defaultMessage: 'Neuen Plan hinzufügen',
          })}
        </Button>
      </Box>
      <SubscriptionAddPlan
        isOpen={addPlanIsOpen}
        onClose={() => {
          setAddPlanIsOpen(false);
          refetch();
          if (onUpdate) onUpdate();
        }}
        accountid={accountid}
      />
    </>
  );
};
