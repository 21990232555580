import { useQuery } from 'react-query';

import { ApiError } from '../ApiError';
import { authorizedGet } from '../authorizedApi';

// reduce API and S3 traffic by caching pre-signed GET Urls
const cachedAssets: Record<string, string> = { '': 'filenotfound' };
cachedAssets[' '] = 'filenotfound';

export const deleteCachedAsset = (assetid: string) => {
  try {
    if (assetid) {
      delete cachedAssets[assetid];
    }
  } catch (_reason: any) {}
};

/**
 *
 * @param {assetid: string | undefined} assetid
 * @returns {{ isLoading, data, isError, error, refetch }} useQuery result
 */
export const useFetchAssetUrl = (assetid: string | undefined) => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `asseturl-${assetid}`,
    async () => {
      if (assetid === undefined || assetid === '' || assetid === 'undefined') {
        return '';
      } else {
        return await getAssetUrl(assetid);
      }
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    url: data,
    refetch,
  };
};

/**
 * get asset URL from backend
 *
 * @param {string} assetid
 * @returns {Promise<string>} pre-signed AWS URL to asset
 */
export const getAssetUrl = async (assetid: string): Promise<string> => {
  // if already cached, return cache
  if (Object.keys(cachedAssets).includes(assetid)) {
    return cachedAssets[assetid];
  }

  try {
    const assetURL: RequestInfo = `/asset/${assetid}`;
    const getAssetURL = authorizedGet(assetURL);
    const response = await getAssetURL();

    const url = (await response.json()).url;

    if (url) {
      cachedAssets[assetid] = url;
    }

    return url;
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.httpStatus === 404) {
        return '';
      }
    }
    return '';
  }
};

/**
 * if there is an asset id, get asset url, otherwise return external src
 *
 * @param {string} assetId - potential assetId
 * @param {string} src - potential external URL
 * @returns
 */
export const getResolvedUrl = async (assetId: string, src: string) => {
  // if there is an assetId, get pre-signed URL and set resolvedUrl
  if (assetId.trim() !== '') {
    return await getAssetUrl(assetId);
  } else {
    // no assetid, assume external URL
    return src;
  }
};
