import { CardImageEnum } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  values: {
    id: 'builder.nodes.valueSelector.state.values.fan',
    defaultMessage: 'Werte',
  },
  reliability: {
    id: 'builder.nodes.valueSelector.state.values.reliability',
    defaultMessage: 'Zuverlässigkeit',
  },
  honesty: {
    id: 'builder.nodes.valueSelector.state.values.honesty',
    defaultMessage: 'Ehrlichkeit',
  },
  trustworthiness: {
    id: 'builder.nodes.valueSelector.state.values.trustworthiness',
    defaultMessage: 'Vertrauenswürdigkeit',
  },
  caring: {
    id: 'builder.nodes.valueSelector.state.values.caring',
    defaultMessage: 'Fürsorge',
  },
  tolerance: {
    id: 'builder.nodes.valueSelector.state.values.tolerance',
    defaultMessage: 'Toleranz',
  },
  acceptance: {
    id: 'builder.nodes.valueSelector.state.values.acceptance',
    defaultMessage: 'Akzeptanz',
  },
  appreciation: {
    id: 'builder.nodes.valueSelector.state.values.appreciation',
    defaultMessage: 'Wertschätzung',
  },
  concern: {
    id: 'builder.nodes.valueSelector.state.values.concern',
    defaultMessage: 'Rücksichtnahme',
  },
  protection: {
    id: 'builder.nodes.valueSelector.state.values.protection',
    defaultMessage: 'Schutz',
  },
  joy: {
    id: 'builder.nodes.valueSelector.state.values.joy',
    defaultMessage: 'Freude',
  },
  prosperity: {
    id: 'builder.nodes.valueSelector.state.values.prosperity',
    defaultMessage: 'Wohlstand',
  },
  beauty: {
    id: 'builder.nodes.valueSelector.state.values.beauty',
    defaultMessage: 'Schönheit',
  },
  achievement: {
    id: 'builder.nodes.valueSelector.state.values.achievement',
    defaultMessage: 'Leistung',
  },
  success: {
    id: 'builder.nodes.valueSelector.state.values.success',
    defaultMessage: 'Erfolg',
  },
  growth: {
    id: 'builder.nodes.valueSelector.state.values.growth',
    defaultMessage: 'Wachstum',
  },
  wisdom: {
    id: 'builder.nodes.valueSelector.state.values.wisdom',
    defaultMessage: 'Weisheit',
  },
  power: {
    id: 'builder.nodes.valueSelector.state.values.power',
    defaultMessage: 'Macht',
  },
  status: {
    id: 'builder.nodes.valueSelector.state.values.status',
    defaultMessage: 'Status',
  },
  prestige: {
    id: 'builder.nodes.valueSelector.state.values.prestige',
    defaultMessage: 'Prestige',
  },
  dominance: {
    id: 'builder.nodes.valueSelector.state.values.dominance',
    defaultMessage: 'Überlegenheit',
  },
  recognition: {
    id: 'builder.nodes.valueSelector.state.values.recognition',
    defaultMessage: 'Anerkennung',
  },
  independence: {
    id: 'builder.nodes.valueSelector.state.values.independence',
    defaultMessage: 'Unabhängigkeit',
  },
  creativity: {
    id: 'builder.nodes.valueSelector.state.values.creativity',
    defaultMessage: 'Kreativität',
  },
  exploration: {
    id: 'builder.nodes.valueSelector.state.values.exploration',
    defaultMessage: 'Entdeckergeist',
  },
  curiosity: {
    id: 'builder.nodes.valueSelector.state.values.curiosity',
    defaultMessage: 'Neugierde',
  },
  freedom: {
    id: 'builder.nodes.valueSelector.state.values.freedom',
    defaultMessage: 'Freiheit',
  },
  selfrespect: {
    id: 'builder.nodes.valueSelector.state.values.selfrespect',
    defaultMessage: 'Selbstachtung',
  },
  excitement: {
    id: 'builder.nodes.valueSelector.state.values.excitement',
    defaultMessage: 'Begeisterung',
  },
  innovation: {
    id: 'builder.nodes.valueSelector.state.values.innovation',
    defaultMessage: 'Innovation',
  },
  challenge: {
    id: 'builder.nodes.valueSelector.state.values.challenge',
    defaultMessage: 'Herausforderung',
  },
  variety: {
    id: 'builder.nodes.valueSelector.state.values.variety',
    defaultMessage: 'Abwechslung',
  },
  safety: {
    id: 'builder.nodes.valueSelector.state.values.safety',
    defaultMessage: 'Sicherheit',
  },
  harmony: {
    id: 'builder.nodes.valueSelector.state.values.harmony',
    defaultMessage: 'Harmonie',
  },
  stability: {
    id: 'builder.nodes.valueSelector.state.values.stability',
    defaultMessage: 'Stabilität',
  },
  belonging: {
    id: 'builder.nodes.valueSelector.state.values.belonging',
    defaultMessage: 'Zugehörigkeit',
  },
  obedience: {
    id: 'builder.nodes.valueSelector.state.values.obedience',
    defaultMessage: 'Gehorsam',
  },
  respect: {
    id: 'builder.nodes.valueSelector.state.values.respect',
    defaultMessage: 'Respekt',
  },
  commitment: {
    id: 'builder.nodes.valueSelector.state.values.commitment',
    defaultMessage: 'Verbindlichkeit',
  },
  humbleness: {
    id: 'builder.nodes.valueSelector.state.values.humbleness',
    defaultMessage: 'Bescheidenheit',
  },
  modesty: {
    id: 'builder.nodes.valueSelector.state.values.modesty',
    defaultMessage: 'Anstand',
  },
  politeness: {
    id: 'builder.nodes.valueSelector.state.values.politeness',
    defaultMessage: 'Höflichkeit',
  },
  health: {
    id: 'builder.nodes.valueSelector.state.values.health',
    defaultMessage: 'Gesundheit',
  },
  love: {
    id: 'builder.nodes.valueSelector.state.values.love',
    defaultMessage: 'Liebe',
  },
  purpose: {
    id: 'builder.nodes.valueSelector.state.values.purpose',
    defaultMessage: 'Sinn',
  },
  intimacy: {
    id: 'builder.nodes.valueSelector.state.values.intimacy',
    defaultMessage: 'Nähe',
  },
  justice: {
    id: 'builder.nodes.valueSelector.state.values.justice',
    defaultMessage: 'Gerechtigkeit',
  },
  equality: {
    id: 'builder.nodes.valueSelector.state.values.equality',
    defaultMessage: 'Gleichheit',
  },
  courage: {
    id: 'builder.nodes.valueSelector.state.values.courage',
    defaultMessage: 'Mut',
  },
  strength: {
    id: 'builder.nodes.valueSelector.state.values.strength',
    defaultMessage: 'Stärke',
  },
  buttonText: {
    id: 'builder.nodes.valueSelector.state.buttonText',
    defaultMessage: 'Weiter',
  },
  placeholder: {
    id: 'builder.nodes.valueSelector.state.placeholder',
    defaultMessage: 'Füge einen Wert hinzu… ',
  },
});

export const predefinedValuesSet: Record<string, any>[] = [];

// TODO user specific hard-coded lists
// save per list item index explicitely in object as some lists elements are not displayed
// save per list item the list of accountids that are allowed to see the list (+role evoachAdmin)

// for lists see also https://evoach.atlassian.net/browse/PROD-984

// images are not necessary for ValueSelector, but this list is also used by
// HotOrNotSelector and there we need an image. The card list there is generated
// out of this list and in order to avoid code duplication and improve code
// readability, we define images here and use them in a mapper there.
export const EvoachValueList = [
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.reliability',
    starred: false,
    image: CardImageEnum.reliability, // TODO
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.honesty',
    starred: false,
    image: CardImageEnum.honesty, // TODO
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.trustworthiness',
    starred: false,
    image: CardImageEnum.trustworthiness,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.caring',
    starred: false,
    image: CardImageEnum.caring,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.tolerance',
    starred: false,
    image: CardImageEnum.tolerance,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.acceptance',
    starred: false,
    image: CardImageEnum.acceptancevalue,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.appreciation',
    starred: false,
    image: CardImageEnum.appreciation,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.concern',
    starred: false,
    image: CardImageEnum.concern,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.protection',
    starred: false,
    image: CardImageEnum.protection,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.joy',
    starred: false,
    image: CardImageEnum.joy,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.prosperity',
    starred: false,
    image: CardImageEnum.prosperity,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.beauty',
    starred: false,
    image: CardImageEnum.beauty,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.achievement',
    starred: false,
    image: CardImageEnum.achievement,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.success',
    starred: false,
    image: CardImageEnum.success,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.growth',
    starred: false,
    image: CardImageEnum.growth,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.wisdom',
    starred: false,
    image: CardImageEnum.wisdom,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.power',
    starred: false,
    image: CardImageEnum.powervalue,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.status',
    starred: false,
    image: CardImageEnum.status,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.prestige',
    starred: false,
    image: CardImageEnum.prestige,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.dominance',
    starred: false,
    image: CardImageEnum.dominance,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.recognition',
    starred: false,
    image: CardImageEnum.recognition,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.independence',
    starred: false,
    image: CardImageEnum.independence,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.creativity',
    starred: false,
    image: CardImageEnum.creativity,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.exploration',
    starred: false,
    image: CardImageEnum.exploration,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.curiosity',
    starred: false,
    image: CardImageEnum.curiosityvalue,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.freedom',
    starred: false,
    image: CardImageEnum.freedomvalue,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.selfrespect',
    starred: false,
    image: CardImageEnum.selfrespect,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.excitement',
    starred: false,
    image: CardImageEnum.excitement,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.innovation',
    starred: false,
    image: CardImageEnum.innovation,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.challenge',
    starred: false,
    image: CardImageEnum.challenge,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.variety',
    starred: false,
    image: CardImageEnum.diversity,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.safety',
    starred: false,
    image: CardImageEnum.safety,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.harmony',
    starred: false,
    image: CardImageEnum.harmony,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.stability',
    starred: false,
    image: CardImageEnum.stability,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.belonging',
    starred: false,
    image: CardImageEnum.belonging,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.obedience',
    starred: false,
    image: CardImageEnum.obedience,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.respect',
    starred: false,
    image: CardImageEnum.respect,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.commitment',
    starred: false,
    image: CardImageEnum.commitment,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.humbleness',
    starred: false,
    image: CardImageEnum.humbleness,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.modesty',
    starred: false,
    image: CardImageEnum.modesty,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.politeness',
    starred: false,
    image: CardImageEnum.politeness,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.health',
    starred: false,
    image: CardImageEnum.health,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.love',
    starred: false,
    image: CardImageEnum.love,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.purpose',
    starred: false,
    image: CardImageEnum.purpose,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.intimacy',
    starred: false,
    image: CardImageEnum.intimacy,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.justice',
    starred: false,
    image: CardImageEnum.justice,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.equality',
    starred: false,
    image: CardImageEnum.equality,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.courage',
    starred: false,
    image: CardImageEnum.courage,
  },
  {
    type: 'valueSelector',
    value: 'builder.nodes.valueSelector.state.values.strength',
    starred: false,
    image: CardImageEnum.strength,
  },
];

// set 1 - based on Sylvias requirements
predefinedValuesSet.push({
  valuesListName: 'builder.nodes.valueSelector.state.values.fan',
  values: EvoachValueList,
});

// set 2 - non-violent communication

export const valueSelectorStateEntry: StateEntryProps = {
  type: 'renderValueSelector',
  temporary: true,
  payload: {
    placeholderTexts: ['builder.nodes.valueSelector.state.placeholder'],
    elements: predefinedValuesSet[0].values,
    predefinedNeedsIndex: 0,
    buttonText: 'builder.nodes.valueSelector.state.buttonText',
    saveResultTo: 'valueSelector',
    getValuesFrom: [''],
    minItems: 1,
    maxItems: 5,
    hideOwnInput: false,
    selectAll: false,
    //message: 'bitte wähle deine Bedürfnisse aus', // later for composite
  },
  saveResultType: ReadWriteType.typedObject,
  getValueType: [ReadWriteType.stringArray, ReadWriteType.typedObject],
  displayName: 'Value Selector',
  nodeType: 'valueSelectorStateEntry',
  handleOutCount: 1,
};
