import {
  evoachDefaultTheme,
  generateRandomString,
} from '@evoach/ui-components';
import { Box, Button, Divider, Link } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { Node, isNode, useReactFlow, useStoreApi, XYPosition } from 'reactflow';
import { useIntl } from 'react-intl';

import { DevToolsContext } from '../../devtools/DevToolsContext';
import { TranslationContext as GlobalTranslationContext } from '../../intl/TranslationContext';
import { initPayloadTranslation } from '../ModuleEditor/modulePaster';
import * as CustomNodes from '../nodes';
import {
  percentageEntryTemplate,
  phaseEndStateEntry,
  State,
  StateEntryProps,
} from '../nodes';
import { TranslationContext } from '../stateMachineTranslationContext';
import { ModuleEditorContext } from '../ModuleEditor/ModuleEditorContext';

import {
  checkForEndNode,
  checkForNodesWithoutVariables,
  checkForTranslations,
  getNodesWithUnconnectedOutHandles,
  getNodesWith0Percentage,
  checkIfModuleTooBig,
} from './qualityChecks';

export const QualityTab: React.FC = () => {
  const { l } = useContext(DevToolsContext);

  const { module, nodes, edges, updateElements } =
    useContext(ModuleEditorContext);

  const { defaultMessages: globalMessages } = React.useContext(
    GlobalTranslationContext
  );

  const { addOrUpdateStateMachineTranslation } =
    React.useContext(TranslationContext);

  const reactFlowInstance = useReactFlow();
  const reactFlowStoreApi = useStoreApi();
  const intl = useIntl();

  // collect qa check information
  const [nodesOutgoingEdges, setNodesOutgoingEdges] = useState<Node<any>[]>([]);
  const [moduleTooBig, setModuleTooBig] = useState(false);
  const [nodesZeroPercentage, setNodesZeroPercentage] = useState<Node<any>[]>(
    []
  );

  const [isEndNodeExisting, setEndNodeExisting] = useState(true);
  const [untranslatedNodes, setUntranslatedNodes] = useState<Node<any>[]>([]);
  const [nodesWithoutVariables, setNodesWithoutVariables] = useState<
    Node<any>[]
  >([]);

  const [lastCheck, setLastCheck] = useState<number | undefined>(undefined);

  const checkModule = () => {
    l(module);
    setModuleTooBig(checkIfModuleTooBig(module));
    setNodesOutgoingEdges(getNodesWithUnconnectedOutHandles(module));
    setNodesZeroPercentage(getNodesWith0Percentage(module));
    setEndNodeExisting(checkForEndNode(module));
    setUntranslatedNodes(checkForTranslations(module, intl));
    setNodesWithoutVariables(checkForNodesWithoutVariables(module));
    setLastCheck(Date.now());
  };

  // code duplication: see ModuleEditorPane
  const initAndSetElementTranslationsForSingleElement = useCallback(
    (stateEntry: CustomNodes.StateEntryProps) => {
      initPayloadTranslation(
        stateEntry,
        addOrUpdateStateMachineTranslation,
        globalMessages
      );
      return stateEntry;
    },
    [addOrUpdateStateMachineTranslation, globalMessages]
  );

  const addEndPhaseNode = () => {
    // construct new end phase node
    // code duplication: see ModuleEditorPane
    const elementId = generateRandomString(8);
    let stateEntry: StateEntryProps = cloneDeep(phaseEndStateEntry);
    let percentageTemplate = cloneDeep(percentageEntryTemplate);
    percentageTemplate.payload.progressPercent = 100;

    const stateEntryList: CustomNodes.StateEntryProps[] = [
      initAndSetElementTranslationsForSingleElement(stateEntry),
      percentageTemplate,
    ];
    const preparedElementState = new State(elementId, stateEntryList);

    // position node under the lowest node
    const lowestNode = nodes.reduce((prevNode, currentNode) =>
      currentNode.position.y > prevNode.position.y ? currentNode : prevNode
    );
    const xOffset = lowestNode.width ? lowestNode.width / 2 : 50;
    const yOffset = lowestNode.height ? lowestNode.height * 2 : 200;
    const position = {
      x: lowestNode.position.x + xOffset,
      y: lowestNode.position.y + yOffset,
    };

    const newEndNode = new CustomNodes.StateNode(
      elementId,
      stateEntry.nodeType,
      position,
      preparedElementState,
      stateEntry.nodeMiniMapColor
    );

    // find all out handles, which are not connected to another node
    const emptySourceHandles = getNodesWithUnconnectedOutHandles(module)
      .flatMap((n) => {
        const node = reactFlowInstance.getNode(n.id);
        const propSymbol =
          node !== undefined ? Object.getOwnPropertySymbols(node) : undefined;
        const handleBounds =
          propSymbol !== undefined && propSymbol.length !== 0
            ? (node as unknown as any)[propSymbol[0]].handleBounds
            : undefined;
        return handleBounds !== undefined &&
          handleBounds.length !== 0 &&
          handleBounds.source !== undefined
          ? handleBounds.source.map((h: any) => {
              return { sourceId: n.id, handleId: h.id };
            })
          : [];
      })
      .filter((sourceHandle) => {
        return !edges.some(
          (edge) =>
            edge.source === sourceHandle.sourceId &&
            edge.sourceHandle === sourceHandle.handleId
        );
      });

    // connect the empty handles to the new end phase node
    const missingEdges = emptySourceHandles.map((handle) => {
      const newEdgeToEndNode = {
        source: handle.sourceId,
        sourceHandle: handle.handleId,
        target: newEndNode.id,
        targetHandle: 'phasehandle',
        arrowHeadType: 'arrow',
        type: 'buttonedge',
        id: generateRandomString(8),
      };
      return newEdgeToEndNode;
    });

    const updatedElements = module.buildergraph
      .concat(newEndNode)
      .concat(missingEdges);

    // updateElements
    updateElements(updatedElements);
  };

  /**
   * generate a Link for a node. If you click in the link,
   * the node is centered in editor
   *
   * @param {Node<any>} node - the node for which the Link is generated
   * @returns
   */
  const getNodeLinkJSX = (node: Node<any>) => {
    return (
      <Link
        sx={{
          width: '80%',
          color: evoachDefaultTheme.palette.secondary.main,
          textDecoration: 'underline',
          textDecorationColor: evoachDefaultTheme.palette.secondary.main,
          cursor: 'pointer',
        }}
        key={'nodeMissOutEd' + node.id}
        onClick={() => {
          onNodeClick(node.id, node.position);
        }}
      >
        {node.data.nodeType
          .replace('StateEntry', '')
          .split(/(?=[A-Z])/)
          .map((s: string) => {
            return ' ' + s.charAt(0).toUpperCase() + s.slice(1);
          })}
      </Link>
    );
  };

  // TODO add auto selection of node to highlight it
  const onNodeClick = (_nodeid: string, nodePosition: XYPosition) => {
    const [, , ts] = reactFlowStoreApi.getState().transform;
    reactFlowInstance.setCenter(nodePosition.x, nodePosition.y, { zoom: ts });
  };

  const dividerStyle = {
    marginTop: '15px',
    padding: '0px 5px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  };

  // TODO add checkbox for autocheck (take care of performance and browser behaviour!!)

  return (
    <>
      <Button
        style={{ margin: '1rem' }}
        onClick={() => {
          checkModule();
        }}
      >
        {intl.formatMessage({
          id: 'builder.qualitypane.checkbutton',
          defaultMessage: 'Modul prüfen',
        })}
      </Button>
      <br />
      {intl.formatMessage({
        id: 'builder.qualitypane.checkbutton.notclicked',
        defaultMessage:
          'Ein Modul wird auf Qualität geprüft, wenn du auf den "Modul prüfen" Button klickst.',
      })}
      {lastCheck !== undefined && (
        <>
          <br />
          &nbsp;
          <br />
          {intl.formatMessage({
            id: 'builder.qualitypane.checkbutton.lastcheck',
            defaultMessage: 'Letzte Prüfung:',
          })}{' '}
          {new Date(lastCheck).getHours() +
            ':' +
            new Date(lastCheck).getMinutes()}
          <Box key="qamoduletoobig" sx={dividerStyle}>
            <Divider />
            <br />
            {moduleTooBig ? (
              <>
                {intl.formatMessage(
                  {
                    id: 'builder.qualitypane.moduletoobig',
                    defaultMessage:
                      'Dein Modul ist sehr groß. Es hat {len} Elemente. Überleg dir mal, ob du dieses Modul vielleicht in mehrere Module aufteilen willst.',
                  },
                  { len: module.buildergraph.filter(isNode).length }
                )}
              </>
            ) : (
              <>
                {intl.formatMessage({
                  id: 'builder.qualitypane.moduletoobig.ok',
                  defaultMessage: '\u2713 Modulgröße ist in Ordnung.',
                })}
              </>
            )}
            <br />
          </Box>
          <Box key="qaoutgoingedges" sx={dividerStyle}>
            <Divider />
            <br />
            {nodesOutgoingEdges.length > 0 ? (
              <>
                {intl.formatMessage({
                  id: 'builder.qualitypane.nodesOutgoingEdges.some',
                  defaultMessage:
                    'Die folgenden Knoten sind nicht vollständig verbunden. Klicke auf den Namen, um zum Knoten zu springen.',
                })}
                <br />
                {nodesOutgoingEdges.map((node: Node<any>) =>
                  getNodeLinkJSX(node)
                )}

                {!isEndNodeExisting && !module.issubmodule && (
                  <Box sx={dividerStyle}>
                    <br />
                    <Divider />
                    <br />
                    {intl.formatMessage({
                      id: 'builder.qualitypane.endnodemissing',
                      defaultMessage:
                        'Es exisitiert noch kein End-Knoten. Möchtest du ihn hinzufügen und alle Knoten mit offenen Ausgängen automatisch verbinden?',
                    })}
                    <br />

                    <Link
                      sx={{
                        width: '80%',
                        color: evoachDefaultTheme.palette.secondary.main,
                        textDecoration: 'underline',
                        textDecorationColor:
                          evoachDefaultTheme.palette.secondary.main,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        addEndPhaseNode();
                      }}
                    >
                      {intl.formatMessage({
                        id: 'builder.qualitypane.addendphasenode',
                        defaultMessage: 'Hinzufügen',
                      })}
                    </Link>
                  </Box>
                )}
              </>
            ) : (
              <>
                {intl.formatMessage({
                  id: 'builder.qualitypane.nodesOutgoingEdges.some.ok',
                  defaultMessage: '\u2713 Alle Knoten vollständig verbunden.',
                })}
              </>
            )}
          </Box>
          <Box key="qanopercentage" sx={dividerStyle}>
            <Divider />
            <br />
            {nodesZeroPercentage.length > 0 ? (
              <>
                {intl.formatMessage({
                  id: 'builder.qualitypane.nodesZeroPercentage.some',
                  defaultMessage:
                    'Die folgenden Knoten zeigen in der Progress Bar 0 Prozent an. Klicke auf den Namen, um zum Knoten zu springen.',
                })}
                <br />
                {nodesZeroPercentage.map((node: Node<any>) =>
                  getNodeLinkJSX(node)
                )}
              </>
            ) : (
              <>
                {intl.formatMessage({
                  id: 'builder.qualitypane.nodesZeroPercentage.allok',
                  defaultMessage:
                    '\u2713 Alle Knoten haben eine Prozentangabe für die Fortschrittsanzeige.',
                })}
              </>
            )}
          </Box>
          <Box key="qauntranslatednodes" sx={dividerStyle}>
            <Divider />
            <br />
            {untranslatedNodes.length > 0 ? (
              <>
                {intl.formatMessage({
                  id: 'builder.qualitypane.untranslatednodes',
                  defaultMessage:
                    'Die folgenden Knoten sind nicht vollständig übersetzt.',
                })}
                <br />
                {untranslatedNodes.map((node: Node<any>) =>
                  getNodeLinkJSX(node)
                )}
              </>
            ) : (
              <>
                {intl.formatMessage({
                  id: 'builder.qualitypane.untranslatednodes.ok',
                  defaultMessage: '\u2713 Keine fehlende Übersetzung.',
                })}
              </>
            )}
          </Box>
          <Box key="nodeswithoutvars" sx={dividerStyle}>
            <Divider />
            <br />
            {nodesWithoutVariables.length > 0 ? (
              <>
                <br />
                {intl.formatMessage({
                  id: 'builder.qualitypane.nodesWithoutVariables',
                  defaultMessage:
                    'Die folgenden Knoten benötigen unbedingt eine zugewiesene Variable, besitzen aber noch keine Zuweisung.',
                })}
                <br />
                {nodesWithoutVariables.map((node: Node<any>) =>
                  getNodeLinkJSX(node)
                )}
              </>
            ) : (
              <>
                {intl.formatMessage({
                  id: 'builder.qualitypane.nodesWithoutVariables.ok',
                  defaultMessage: '\u2713 Variablenzuweisungen sind ok',
                })}
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};
