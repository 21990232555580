import audiodisplay from './previewimages/Preview_Audio_Display.png';
import binarysorter from './previewimages/Preview_Binary_Sorter.png';
import button from './previewimages/Preview_Button.png';
import cardhorizontal from './previewimages/Preview_Card_Horizontal.png';
import characterselector from './previewimages/Preview_Character_Selector.png';
import coachmessage from './previewimages/Preview_Coach_Message.png';
import coacheemessage from './previewimages/Preview_Coachee_Message.png';
import copylink from './previewimages/Preview_Copy_Link.png';
import dateinput from './previewimages/Preview_Date_Input.png';
import editablemultiselect from './previewimages/Preview_Editable_Multiselect.png';
import emoticoninput from './previewimages/Preview_Emoticon_Input.png';
import emoticonpicker from './previewimages/Preview_Emoticon_Picker.png';
import emotionicon from './previewimages/Preview_Emotion_Icon.png';
import formattedinput from './previewimages/Preview_Formatted_Input.png';
import hotornotselection from './previewimages/Preview_Hot_Or_Not_Selection.png';
import imagedisplay from './previewimages/Preview_Image_Display.png';
import imageselect from './previewimages/Preview_Image_Select.png';
import linkbutton from './previewimages/Preview_Link_Button.png';
import messageinput from './previewimages/Preview_Message_Input.png';
import moodicon from './previewimages/Preview_Mood_Icon.png';
import moodinput from './previewimages/Preview_Mood_Input.png';
import multibutton from './previewimages/Preview_Multi_Button.png';
import multipleinputdecomposed from './previewimages/Preview_MultipleInputDecomposed.png';
import multipledisplay from './previewimages/Preview_Multiple_Display.png';
import multipledisplaycomposite from './previewimages/Preview_Multiple_Display_Composite.png';
import multipleinput from './previewimages/Preview_Multiple_Input.png';
import multipleinputcomposite from './previewimages/Preview_Multiple_Input_Composite.png';
import multipleoutput from './previewimages/Preview_Multiple_Output.png';
import multipleoutputcomposit from './previewimages/Preview_Multiple_Output_Composit.png';
import multiplepercentagescaleinput from './previewimages/Preview_Multiple_Percentage_Scale_Input.png';
import objectcard from './previewimages/Preview_Object_Card.png';
import pdfprinterbutton from './previewimages/Preview_Pdf_Printer_Button.png';
import percentscaleinput from './previewimages/Preview_Percent_Scale_Input.png';
import phase from './previewimages/Preview_Phase.png';
import phaseend from './previewimages/Preview_PhaseEnd.png';
import phasestart from './previewimages/Preview_PhaseStart.png';
import polarchartdisplay from './previewimages/Preview_Polarchart_Display.png';
import polarchartinput from './previewimages/Preview_Polarchart_Input.png';
import postitdisplay from './previewimages/Preview_Post_It_Display.png';
import postitinput from './previewimages/Preview_Post_It_Input.png';
import progressbar from './previewimages/Preview_Progress_Bar.png';
import radarchartdisplay from './previewimages/Preview_Radarchart_Display.png';
import radiobutton from './previewimages/Preview_Radio_Button.png';
import scalebutton from './previewimages/Preview_Scale_Button.png';
import scaleinput from './previewimages/Preview_Scale_Input.png';
import scenecanvas from './previewimages/Preview_Scene_Canvas.png';
import selectioncard from './previewimages/Preview_Selection_Card.png';
import setavatarimage from './previewimages/Preview_SetAvatarImage.png';
import singledisplay from './previewimages/Preview_Single_Display.png';
import starratinginput from './previewimages/Preview_Star_Rating_Input.png';
import timeinput from './previewimages/Preview_Time_Input.png';
import valuecard from './previewimages/Preview_Value_Card.png';
import valuecardlist from './previewimages/Preview_Value_Card_List.png';
import verticaltimeline from './previewimages/Preview_Vertical_Timeline.png';
import videodisplay from './previewimages/Preview_Video_Display.png';
import yesnobutton from './previewimages/Preview_Yes_No_Button.png';
import arraycombinenumbernode from './previewimages/Preview_arrayCombineNumberNode.png';
import arraycombinestringnode from './previewimages/Preview_arrayCombineStringNode.png';
import comparemultiplenumbers from './previewimages/Preview_compareMultipleNumbers.png';
import comparenumbers from './previewimages/Preview_compareNumbers.png';
import formulanode from './previewimages/Preview_formulaNode.png';
import loop from './previewimages/Preview_loop.png';
import loopend from './previewimages/Preview_loopEnd.png';
import loopstart from './previewimages/Preview_loopStart.png';
import multipleinputsingle from './previewimages/Preview_multipleInputSingle.png';
import needsinput from './previewimages/Preview_needsInput.png';
import note from './previewimages/Preview_note.png';
import randomcoachmessage from './previewimages/Preview_randomCoachMessageMulti.png';
import randomcoachmessagesingle from './previewimages/Preview_randomCoachMessageSingle.png';
import scaleinputmulti from './previewimages/Preview_scaleInputMulti.png';
import sendmailnode from './previewimages/Preview_sendMailNode.png';
import sharesession from './previewimages/Preview_shareSession.png';
import startnewsession from './previewimages/Preview_startNewSession.png';
import valueselector from './previewimages/Preview_valueSelector.png';
import variabledefinestringarraynode from './previewimages/Preview_variableDefineStringArrayNode.png';

export const previewImages: Record<string, any> = {};
previewImages['audiodisplay'] = audiodisplay;
previewImages['binarysorter'] = binarysorter;
previewImages['nextbutton'] = button;
previewImages['cardhorizontal'] = cardhorizontal;
previewImages['characterselector'] = characterselector;
previewImages['coachmessage'] = coachmessage;
previewImages['coacheemessage'] = coacheemessage;
previewImages['copylink'] = copylink;
previewImages['dateinput'] = dateinput;
previewImages['editablemultiselect'] = editablemultiselect;
previewImages['emotionsinput'] = emoticoninput;
previewImages['emoticonpicker'] = emoticonpicker;
previewImages['emotionicon'] = emotionicon;
previewImages['formattedinput'] = formattedinput;
previewImages['hotornotselector'] = hotornotselection;
previewImages['imagedisplay'] = imagedisplay;
previewImages['imageselect'] = imageselect;
previewImages['linkbutton'] = linkbutton;
previewImages['messageinput'] = messageinput;
previewImages['moodicon'] = moodicon;
previewImages['moodinput'] = moodinput;
previewImages['multibutton'] = multibutton;
previewImages['multipleinputdecomposed'] = multipleinputdecomposed;
previewImages['multipledisplay'] = multipledisplay;
previewImages['multipledisplaycomposite'] = multipledisplaycomposite;
previewImages['multipleinput'] = multipleinput;
previewImages['multipleinputcomposite'] = multipleinputcomposite;
previewImages['multipleoutput'] = multipleoutput;
previewImages['multipleoutputcomposite'] = multipleoutputcomposit;
previewImages['multiplepercentagescaleinput'] = multiplepercentagescaleinput;
previewImages['objectcard'] = objectcard;
previewImages['pdfprinterbutton'] = pdfprinterbutton;
previewImages['percentagescaleinput'] = percentscaleinput;
previewImages['phase'] = phase;
previewImages['phaseend'] = phaseend;
previewImages['phasestart'] = phasestart;
previewImages['polarchart'] = polarchartdisplay;
previewImages['polarchartinput'] = polarchartinput;
previewImages['postitdisplay'] = postitdisplay;
previewImages['postitinput'] = postitinput;
previewImages['progressbar'] = progressbar;
previewImages['radarchart'] = radarchartdisplay;
previewImages['radiobutton'] = radiobutton;
previewImages['scalebutton'] = scalebutton;
previewImages['scaleinput'] = scaleinput;
previewImages['scenecanvas'] = scenecanvas;
previewImages['selectioncard'] = selectioncard;
previewImages['setavatarimage'] = setavatarimage;
previewImages['singledisplay'] = singledisplay;
previewImages['ratinginput'] = starratinginput;
previewImages['timeinput'] = timeinput;
previewImages['valuecard'] = valuecard;
previewImages['valuecardlist'] = valuecardlist;
previewImages['verticaltimeline'] = verticaltimeline;
previewImages['videodisplay'] = videodisplay;
previewImages['yesnobutton'] = yesnobutton;
previewImages['arraycombinenumbernode'] = arraycombinenumbernode;
previewImages['arraycombinestringnode'] = arraycombinestringnode;
previewImages['comparemultiplenumbers'] = comparemultiplenumbers;
previewImages['comparenumbers'] = comparenumbers;
previewImages['formulanode'] = formulanode;
previewImages['loop'] = loop;
previewImages['loopend'] = loopend;
previewImages['loopstart'] = loopstart;
previewImages['multipleinputsingle'] = multipleinputsingle;
previewImages['needsinput'] = needsinput;
previewImages['note'] = note;
previewImages['randomcoachmessage'] = randomcoachmessage;
previewImages['randomcoachmessagesingle'] = randomcoachmessagesingle;
previewImages['scaleinputmulti'] = scaleinputmulti;
previewImages['sendmailnode'] = sendmailnode;
previewImages['sharesession'] = sharesession;
previewImages['startnewsession'] = startnewsession;
previewImages['valueselector'] = valueselector;
previewImages['variabledefinestringarraynode'] = variabledefinestringarraynode;
