import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.startNewSession.state.message',
    defaultMessage:
      'Möchtest du direkt mit dem nächsten Modul weitermachen? Wenn du "Nein" klickst, findest du das Modul später in deiner Modulliste und kannst die Coaching-Session zu einem anderen Zeitpunkt starten.',
  },
  yesButtonText: {
    id: 'builder.nodes.startNewSession.state.yesButtonText',
    defaultMessage: 'Ja, starte die neue Coaching-Session.',
  },
  noButtonText: {
    id: 'builder.nodes.startNewSession.state.noButtonText',
    defaultMessage: 'Nein, ich möchte später starten.',
  },
});

export const startNewSessionStateEntry: StateEntryProps = {
  type: 'renderStartNewSession',
  temporary: false,
  payload: {
    message: 'builder.nodes.startNewSession.state.message',
    buttonTextYes: 'builder.nodes.startNewSession.state.yesButtonText',
    buttonTextNo: 'builder.nodes.startNewSession.state.noButtonText',
    saveResultTo: 'evoachechokey.startNewSessionDecision', // variable name for echo
    moduleId: '',
    moduleTitleKey: '',
  },
  displayName: 'Start New Session',
  nodeType: 'startNewSessionStateEntry',
  saveResultType: ReadWriteType.string,
  handleOutCount: 2, // number of outgoing handles
};
