import { Handle, NodeProps, Position } from 'reactflow';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { Description } from '../Description';

import { calculateHandlePosition, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  Node implementation for check variable
*/

export const CheckVariableNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;

  return (
    <>
      <Handle
        type="target"
        id="checkvarinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ width: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Description nodetype={nodeType} propname="description" />
        <br />
        &nbsp;
        <br />
        <Typography component="span">
          {!payload.getValueFrom || payload.getValueFrom.trim() === '' ? (
            <>
              {intl.formatMessage({
                id: 'builder.nodes.CheckVariableNode.novarselected',
                defaultMessage: 'Du hast noch keine Variable ausgewählt!',
              })}
            </>
          ) : (
            <>
              {intl.formatMessage(
                {
                  id: 'builder.nodes.CheckVariableNode.varselected',
                  defaultMessage:
                    'Die folgende Variable wird geprüft: {varname}',
                },
                { varname: <strong>{payload.getValueFrom}</strong> }
              )}
            </>
          )}
        </Typography>
        <br />
        &nbsp;
        <br />
        <Typography component="span">
          {intl.formatMessage({
            id: 'builder.nodes.CheckVariableNode.handledesc',
            defaultMessage:
              'Der linke Pfad wird gewählt, wenn die Variable bei der Prüfung gefüllt ist. ',
          })}
        </Typography>
      </div>
      <Handle
        type="source"
        key="checkvarouthandle_set_key"
        id="checkvarouthandle_set"
        position={Position.Bottom}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{
          left: calculateHandlePosition(2, 0),
        }}
      />
      <Handle
        type="source"
        key="checkvarouthandle_notset_key"
        id="checkvarouthandle_notset"
        position={Position.Bottom}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{
          left: calculateHandlePosition(2, 1),
        }}
      />
    </>
  );
};
