import { HotOrNotSelection, ValueCardProps } from '@evoach/ui-components';
import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';

import { useEnvironment } from '../../environment/useEnvironment';
import { mapImageToPublicAsset } from '../reception/assets/AssetHelper';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  CoachMessage react-flow node for module builder graph canvas
*/

export const HotOrNotSelectorNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const { playerBasePath } = useEnvironment();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  // replace all skipButtonTexts with buttonText
  const cards = payload.cards.map((card: ValueCardProps) => {
    return {
      ...card,
      imageSrc: card.image
        ? mapImageToPublicAsset(card.image, playerBasePath)
        : card.imageSrc
        ? card.imageSrc
        : '',
      value: translateKey(intl, card.value),
      description: card.description
        ? translateKey(intl, card.description)
        : undefined,
      skipButtonText: translateKey(intl, payload.buttonText),
    };
  });

  return (
    <>
      <Handle
        type="target"
        id="messageinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ maxWidth: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <HotOrNotSelection cards={cards} saveResultTo="none" />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="messageouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
