import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  minText: {
    id: 'builder.nodes.scaleButton.state.minText',
    defaultMessage: '0 - Unwahrscheinlich',
  },
  maxText: {
    id: 'builder.nodes.scaleButton.state.maxText',
    defaultMessage: '10 - Sehr wahrscheinlich',
  },
});

export const scaleButtonStateEntry: StateEntryProps = {
  type: 'renderScaleButton',
  temporary: true,
  payload: {
    minText: 'builder.nodes.scaleButton.state.minText',
    maxText: 'builder.nodes.scaleButton.state.maxText',
    scaleMin: 1,
    scaleMax: 10,
    saveResultTo: 'scale_button_result',
  },
  saveResultType: ReadWriteType.number,
  displayName: 'Scale Button',
  nodeType: 'scaleButtonStateEntry',
  handleOutCount: 1,
};
