import { useQuery } from 'react-query';

import {
  Program,
  ProgramInstance,
  ProgramInstancePermission,
  ProgramModule,
} from '../../entities';
import { ProgramRouteTypeEnum } from '../../routing/routes';
import { resolveS3ApiCall } from '../account/useFetchOrCreateAccountQuery';
import { getAssetUrl } from '../asset';
import { authorizedGet } from '../authorizedApi';

/**
 * get a program depending
 * @param {string} programId to be fetched
 * @returns isLoading, isError, error, program, refetch
 */
export const useFetchProgram = (programId: string = '') => {
  return useFetchProgramOrInstance(programId, ProgramRouteTypeEnum.PROGRAM);
};

/**
 * useFetchProgramOrInstance gets a program from a different endpoint, depending on programType
 * @param {string} programId
 * @param {ProgramRouteTypeEnum} programRouteType
 * @returns
 */
export const useFetchProgramOrInstance = (
  programId: string = '',
  programRouteType: ProgramRouteTypeEnum | undefined
) => {
  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `program-${programId}-${programRouteType}`,
    async () => {
      if (!programRouteType || !programId || programId.trim() === '')
        return undefined;

      let url = '';
      if (programRouteType === ProgramRouteTypeEnum.INSTANCE + '') {
        url = `/programinstance/${programId}`;
      } else {
        url = `/program/${programId}`;
      }

      const fetchProgramCall = authorizedGet(url);
      const response = await fetchProgramCall();
      const data: Program | ProgramInstance = await response.json();

      // fix programmodules default
      if (data.programmodules && !Array.isArray(data.programmodules)) {
        data.programmodules = [];
      }

      //
      // resolve assets
      //
      // 1. resolve program asset
      // ==> resolved in ProgramMetaEditor.tsx
      // 2. resolve assets of program modules ==> for timeline
      //    ==> editor resolves it in ProhgramModuleEditor.tsx
      for (let i = 0; i < data.program.modules.length; i++) {
        data.program.modules[i] = await resolveProgramModuleAssets(
          data.program.modules[i]
        );
      }

      let profile;

      // resolve the correct account for displaying a profile
      if (programRouteType === ProgramRouteTypeEnum.INSTANCE) {
        // program instance
        const invitingaccounts = (
          data.permissions as ProgramInstancePermission[]
        ).filter((perm: ProgramInstancePermission) => perm.invitingaccount);

        if (invitingaccounts.length > 0) {
          profile = invitingaccounts[0].invitingaccount?.metainfos?.profile;
        }
      } else {
        // program
        profile = data.permissions[0].account?.metainfos?.profile;
      }

      // if profile is available, try to resolve picture
      if (profile) {
        // profile picture if available
        profile.profilePictureResolvedUrl =
          profile.profilePictureSrc && profile.profilePictureSrc !== ''
            ? profile.profilePictureSrc
            : profile.profilePictureAssetId &&
              profile.profilePictureAssetId !== ''
            ? await resolveS3ApiCall(profile.profilePictureAssetId)
            : '';
        // logo if available
        profile.logoPictureResolvedUrl =
          profile.logoPictureSrc && profile.logoPictureSrc !== ''
            ? profile.logoPictureSrc
            : profile.logoPictureAssetId && profile.logoPictureAssetId !== ''
            ? await resolveS3ApiCall(profile.logoPictureAssetId)
            : '';
      }

      return data;
    },
    {
      retry: false,
    }
  );

  if (programRouteType === ProgramRouteTypeEnum.INSTANCE) {
    return {
      isLoading,
      isError,
      error,
      program: data as ProgramInstance,
      refetch,
    };
  } else {
    return {
      isLoading,
      isError,
      error,
      program: data as Program,
      refetch,
    };
  }
};

/**
 * resolve a image of a single program module
 * @param {ProgramModule} cmod
 * @returns {Promise<ProgramModule>} resolved program module
 */
export const resolveProgramModuleAssets = async (cmod: ProgramModule) => {
  if (cmod.assetid && cmod.assetid !== '') {
    cmod.resolvedsrc = await getAssetUrl(cmod.assetid);
  } else {
    if (cmod.src && cmod.src !== '') {
      cmod.resolvedsrc = cmod.src;
    } else {
      // TODO map .image prop to player path
      // function can be fodu in AssetHelper  mapImageToPublicAsset
      cmod.resolvedsrc = '';
    }
  }
  return cmod;
};
