import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { getTabProps, TabPanelProps } from '../../tabHelper';

import { CoachingProgramsList } from './CoachingProgramsList';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span" sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export interface ProgramAndInstanceTabsProps {
  showInstanceTab?: boolean;
}

export const ProgramAndInstanceTabs: React.FC<ProgramAndInstanceTabsProps> = ({
  showInstanceTab = false,
}) => {
  const [tabvalue, setTabValue] = React.useState(showInstanceTab ? 1 : 0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const intl = useIntl();

  return (
    <Box component="span" sx={{ width: '100%' }}>
      <Box component="span" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabvalue} onChange={handleChange} aria-label="Reception">
          <Tab
            label={intl.formatMessage({
              id: 'builder.coachigprogramstab.templates',
              defaultMessage: 'Meine Programmvorlagen',
            })}
            {...getTabProps(0, 'programtemplates')}
          />
          <Tab
            label={intl.formatMessage({
              id: 'builder.coachigprogramstab.running',
              defaultMessage: 'Laufende Programme',
            })}
            {...getTabProps(1, 'runningprograms')}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabvalue} index={0}>
        <CoachingProgramsList />
      </TabPanel>
      <TabPanel value={tabvalue} index={1}>
        <CoachingProgramsList instanceMode />
      </TabPanel>
    </Box>
  );
};
