import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';
import { Program, ProgramInstance } from '../../entities';
import { getAssetUrl } from '../asset';
import { useEnvironment } from '../../environment/useEnvironment';
import { mapImageToPublicAsset } from '../../components/reception/assets/AssetHelper';

/**
 * read programs or program instances
 *
 * @param {boolean} instanceMode, true = GET /programinstance, false = GET /program
 * @returns useQuery result, program has a different type, correspondingly
 */
export const useFetchPrograms = (
  instanceMode: boolean,
  ownProgramInstancesOnly?: boolean,
  programIdList: string[] = []
) => {
  const { playerBasePath } = useEnvironment();

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `programsData${instanceMode}${ownProgramInstancesOnly}`,
    async () => {
      const instanceQueryString =
        ownProgramInstancesOnly === undefined
          ? ''
          : ownProgramInstancesOnly
          ? `?owns=true&programidlist=${programIdList ?? ''}`
          : `?programidlist=${programIdList ?? ''}`;
      const getProgramsURL = !instanceMode
        ? `/program?programidlist=${programIdList ?? ''}`
        : `/programinstance${instanceQueryString}`;

      const fetchProgramsCall = authorizedGet(getProgramsURL);
      const response = await fetchProgramsCall();

      const data: Program[] | ProgramInstance[] = await response.json();

      // fix data.programmodules
      for (let i = 0; i < data.length; i++) {
        if (data[i].programmodules && !Array.isArray(data[i].programmodules)) {
          data[i].programmodules = [];
        }
      }

      // resolve images for list
      for (let i = 0; i < data.length; i++) {
        if (data[i].program.assetid) {
          data[i].program.resolvedsrc = await getAssetUrl(
            data[i].program.assetid ?? ''
          );
        } else {
          if (data[i].program.src) {
            data[i].program.resolvedsrc = data[i].program.src;
          } else {
            if (data[i].program.image !== undefined) {
              data[i].program.resolvedsrc = data[i].program.image
                ? mapImageToPublicAsset(data[i].program.image, playerBasePath)
                : '';
            } else {
              data[i].program.resolvedsrc = '';
            }
          }
        }
      }

      return data;
    },
    {
      retry: false,
      refetchOnMount: 'always',
    }
  );

  return {
    isLoading,
    isError,
    error,
    programs: !instanceMode ? (data as Program[]) : (data as ProgramInstance[]),
    refetch,
  };
};
