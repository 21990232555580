import { Handle, NodeProps, Position } from 'reactflow';
import { MessageInput } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { useGlobalStyles, translateKey } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  MessageInput react-flow node for module builder graph canvas
*/

export const MessageInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <MessageInput
          placeholderText={translateKey(intl, payload.placeholderText)}
          emoticons={payload.emoticons}
          rowCount={payload.rowCount}
          saveResultTo={payload.saveResultTo}
          milliSeconds={payload.milliseconds}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
