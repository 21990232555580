import { FormControl, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useFetchModulesQuery } from '../../../api';
import { DevToolsContext } from '../../../devtools/DevToolsContext';
import { ModuleProps } from '../../../entities/Module';
import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';

export interface ModuleSelectProps {
  /**
   * updateModuleInfo calls a function with paramerts moduleid, title (translation key),
   * and translatedTitle - in that order!
   */
  updateModuleInfo: Function;
  currentValue: string;
  displayWidth?: string;
}

/** GetValueFrom Select generates a select list to select one or more values
 *
 * @param {Function} updateValueFrom handler to update a value
 * @param {string} currentValue current value of parameter
 * @param {boolean} multiselect false = only one value can be select, true = multiplevalues can be selected
 * @param {number} @optional index check whether a certain index of the initial value has to be updated
 * @param {ReadWriteType} getValueType defines the value type that is returned by the component
 */
export const ModuleSelect: React.FC<ModuleSelectProps> = ({
  updateModuleInfo,
  currentValue,
  displayWidth = '95%',
}) => {
  const intl = useIntl();

  // fetch a list of all modules to be presented in the selection
  const { modules: origModules } = useFetchModulesQuery('all');

  // extract the translations of the modules to avoid re-render of translations
  // which leads to errors when switching windows / focus between windows.
  // With useMemo we memoize the translations and the values we need
  const modules = useMemo(() => {
    if (!origModules || origModules.length < 1) return [];
    return origModules
      .filter(
        (mod: ModuleProps) =>
          !mod.issubmodule && mod.metadata.title.trim() !== ''
      )
      .map((mod: ModuleProps) => {
        return {
          moduleid: mod.moduleid,
          metadata: {
            title: mod.metadata.title,
            titleTranslated: intl.formatMessage({ id: mod.metadata.title }),
          },
        };
      });
    // normally, there would be "intl" in the dependency array. But adding it
    // re-translates texts anytime we switch focus between windows. This leads
    // to errors .

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origModules]);

  const { l } = useContext(DevToolsContext);

  // select only one module!
  const multiSelect = false;

  const handleChange = (value: string) => {
    const mod = modules.filter((mod: ModuleProps) => mod.moduleid === value);
    if (!mod || mod.length === 0) return;
    updateModuleInfo(
      value,
      mod[0].metadata.title,
      mod[0].metadata.titleTranslated
    );
  };

  l('ModuleSelect: ' + currentValue);

  return (
    <FormControl sx={{ width: displayWidth }}>
      {modules !== undefined && modules.length > 0 && (
        <EvoachSelect
          value={currentValue}
          onChange={(event) => handleChange(event.target.value as string)}
          multiple={multiSelect}
          displayEmpty
        >
          {modules.map((selectableValue: any, index: number) => (
            <EvoachMenuItem
              key={`SelectableValue${index}`}
              value={selectableValue.moduleid}
            >
              {selectableValue.metadata.titleTranslated}
            </EvoachMenuItem>
          ))}
          <EvoachMenuItem key="program-module-select-empty" value="">
            {intl.formatMessage({
              id: 'builder.propertypane.startNewSession.nomoduleselected',
              defaultMessage: 'Kein Modul ausgewählt',
            })}
          </EvoachMenuItem>
        </EvoachSelect>
      )}
      {(modules === undefined || modules.length === 0) && (
        <Typography>
          {intl.formatMessage({
            id: 'builder.propertypane.startNewSession.nomodules',
            defaultMessage: 'Keine Module vorhanden.',
          })}
        </Typography>
      )}
    </FormControl>
  );
};
