import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { Description } from '../Description';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  AI Sentiment Node 
*/

export const AiSentimentNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;

  return (
    <>
      <Handle
        type="target"
        id={nodeProps.data.state.entry[0].nodeType + 'in'}
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{
          width: '400px',
          marginBottom: '10px',
          background: nodeProps.data.state.entry[0].nodeMiniMapColor,
        }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          <Description nodetype={nodeType} propname="description" />
          <br />
          &nbsp;
          <br />
          {intl.formatMessage(
            {
              id: 'builder.nodes.aisentiment.selection.in',
              defaultMessage:
                'Der Inhalt dieser Variable wird analysiert: {invar}',
            },
            {
              invar: <b>{payload.getValueFrom ?? '- -'}</b>,
            }
          )}
          <br />
          &nbsp;
          <br />
          {intl.formatMessage(
            {
              id: 'builder.nodes.aisentiment.classes',
              defaultMessage:
                'Die Gefühlsanalyse kann die Ergebnisse "positiv", "neutral" und "negativ" haben. Entsprechend wird zum passenden Ausgang verzweigt.',
            },
            {
              classes: <b>asjk</b>,
            }
          )}
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="positivesentimenthandle"
        key="positivesentimenthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '15%', border: 'none' }}
      >
        <div className={classes['handletextstyle']}>Positive</div>
      </Handle>
      <Handle
        type="source"
        position={Position.Bottom}
        id="neutralsentimenthandle"
        key="neutralsentimenthandle"
        isConnectable={nodeProps.isConnectable}
        style={{ left: '50%', border: 'none' }}
        className={classes['react-flow__handle']}
      >
        <div className={classes['handletextstyle']}>Neutral</div>
      </Handle>
      <Handle
        type="source"
        position={Position.Bottom}
        id="negativesentimenthandle"
        key="negativesentimenthandle"
        isConnectable={nodeProps.isConnectable}
        style={{ left: '85%', border: 'none' }}
        className={classes['react-flow__handle']}
      >
        <div className={classes['handletextstyle']}>Negative</div>
      </Handle>
    </>
  );
};
