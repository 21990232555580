import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { authorizedPatch } from '../../../api/authorizedApi';
import { DevToolsContext } from '../../../devtools/DevToolsContext';
import { SupportedModuleLocales } from '../../../intl/SupportedLocales';
import {
  LanguageSelectionHeightOptions,
  MetaDataEditorLanguageSelection,
} from '../../ModuleEditor/MetaDataEditorLanguageSelection';

export interface NewLanguageButtonProps {
  moduleid: string;
  defaultLanguage: string;
  moduleLanguages: string[];
  refetchModule: Function;
}

export const NewLanguageButton: React.FC<NewLanguageButtonProps> = ({
  moduleid,
  defaultLanguage,
  moduleLanguages,
  refetchModule,
}) => {
  const intl = useIntl();
  const { l } = useContext(DevToolsContext);

  const [newLanguageDialogOpen, setNewLanguageDialogOpen] =
    useState<boolean>(false);

  const createNewLanguage = () => {
    setNewLanguageDialogOpen(!newLanguageDialogOpen);
  };

  const [toLang, setToLang] = useState<string>(
    Object.keys(SupportedModuleLocales).filter(
      (lang: string) => !moduleLanguages.includes(lang)
    )[0] ?? ''
  );

  const onClose = async (doit: boolean) => {
    if (
      doit &&
      toLang &&
      toLang !== '' &&
      defaultLanguage &&
      defaultLanguage !== '' &&
      toLang !== defaultLanguage
    ) {
      const duplicateTranslation = authorizedPatch(
        `/module/${moduleid}/translation?languagefrom=${defaultLanguage}&languageto=${toLang}`
      );
      await duplicateTranslation();
      //await response.json();
      l('duplicated Translation from ' + defaultLanguage + ' to ' + toLang);

      refetchModule();
    }
    setNewLanguageDialogOpen(false);
  };

  const onLanguageChange = (newLang: string) => {
    setToLang(newLang);
  };

  return (
    <>
      <Button onClick={() => createNewLanguage()}>
        {intl.formatMessage({
          id: 'builder.translationeditor.newlang.title',
          defaultMessage: 'Neue Sprachversion erstellen',
        })}
      </Button>

      <Dialog fullWidth open={newLanguageDialogOpen}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'builder.translationeditor.newlang.title',
            defaultMessage: 'Neue Sprachversion erstellen',
          })}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2" component="span">
              {intl.formatMessage({
                id: 'builder.translationeditor.newlang.description',
                defaultMessage:
                  'Die neue Sprache wird als Kopie der Default-Sprache bereitgestellt. Als Zielsprache können nur Sprachen gewählt werden, für die es noch keine Sprachversion des Moduls gibt. Welche Sprachversion soll erstellt werden?',
              })}
              <MetaDataEditorLanguageSelection
                onLanguageChange={onLanguageChange}
                moduleLocale={defaultLanguage}
                excludeLocales={moduleLanguages}
                heightOption={LanguageSelectionHeightOptions.MEDIUM}
              />
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose(false)}>
            {intl.formatMessage({
              id: 'builder.translationeditor.dialog.cancel',
              defaultMessage: 'Abbrechen',
            })}
          </Button>
          <Button color="secondary" onClick={() => onClose(true)}>
            {intl.formatMessage({
              id: 'builder.translationeditor.dialog.ok',
              defaultMessage: 'Ok',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
