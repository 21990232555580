import { ICSFormatItem } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.calenderExport.state.message',
    defaultMessage:
      'Füge die Aktionspunkte jetzt deinem Kalender hinzu und setze eine Erinnerung 3 Tage vor den Fälligkeitsterminen.',
  },
  buttonText: {
    id: 'builder.nodes.calenderExport.state.buttonText',
    defaultMessage: 'Weiter',
  },
  linkButtonText: {
    id: 'builder.nodes.calenderExport.state.linkButtonText',
    defaultMessage: 'Zu Kalender hinzufügen',
  },
});

const exampleItem: ICSFormatItem = {
  startDate: new Date('August 8, 2022 18:00:00'),
  endDate: new Date('August 8, 2022 19:00:00'),
  subject: 'test1',
  description: 'test description 1',
  location: 'Berlin',
};

/**
 * CoachMessage xState specific defintions
 * versions
 * - string: v1 OR undefined => initial version
 * - string: v2 => getStringValues
 * - number: 3 => apply maxWidth in builder for each node - do not change visualisation
 *    in builder for old nodes as it changes layout
 */
export const calenderExportCompositeStateEntry: StateEntryProps = {
  version: 3,
  type: 'renderCalenderExportComposite',
  temporary: false,
  payload: {
    message: 'builder.nodes.calenderExport.state.message',
    getValueFrom: '',
    icsItems: exampleItem,
    buttonText: 'builder.nodes.calenderExport.state.buttonText',
    linkButtonText: 'builder.nodes.calenderExport.state.linkButtonText',
  },
  getValueType: [ReadWriteType.date, ReadWriteType.typedObject],
  displayName: 'Calendar Export',
  nodeType: 'calenderExportCompositeStateEntry',
  handleOutCount: 1,
};
