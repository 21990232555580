import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.sendmail.state.message',
    defaultMessage: 'Hier ist Deine editierbare Coach Nachricht.',
  },
});

//
//  combine list of strings to an number array
//
export const sendMailNodeStateEntry: StateEntryProps = {
  type: 'renderSendMail',
  temporary: false,
  payload: {
    message: 'builder.nodes.sendmail.state.message',
    email: '',
    getValuesFrom: [],
  },
  getValueType: [
    ReadWriteType.string,
    ReadWriteType.stringArray,
    ReadWriteType.number,
    ReadWriteType.numberArray,
    ReadWriteType.time,
    ReadWriteType.date,
  ],
  displayName: 'Send Mail',
  nodeType: 'sendMailNodeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 1,
};
