import {
  Stack,
  FormControl,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useContext, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import TreeMenu from 'react-simple-tree-menu';
import { evoachDefaultTheme } from '@evoach/ui-components';

import { useFetchModules } from '../../api/module/useFetchModules';
import { ModuleProps } from '../../entities/Module';
import { useGlobalStyles } from '../nodes';
import { DevToolsContext } from '../../devtools/DevToolsContext';

import { ListItem } from './TreeViewHelper';

const templateSelectionMessages = defineMessages({
  header: {
    id: 'builder.moduleeditor.templateSelection.header',
    defaultMessage: 'Ziehe die gewünschten Vorlage in die Arbeitsfläche',
  },
  loadingtemplates: {
    id: 'builder.moduleeditor.templateSelection.loadingtemplates',
    defaultMessage: 'Lade Vorlagen ...',
  },
});

/** get a single treenode for an element */
export const getTreeNode = (
  template: any,
  splitted: string[],
  depth: number = 0
): Record<string, any> => {
  // no "/" contained => create new group
  if (depth === 0 && splitted.length === 1) {
    return {
      key: 'Others',
      label: 'Others',
      nodes: [
        {
          key: splitted[depth],
          label: splitted[depth],
          nodes: [],
          template: template,
        },
      ],
      template: undefined,
    };
  }

  return {
    key: splitted[depth],
    label: splitted[depth],
    nodes:
      depth + 1 === splitted.length
        ? []
        : [getTreeNode(template, splitted, depth + 1)],
    template: depth + 1 === splitted.length ? template : undefined,
  };
};

/** generate tree nodes for tree view  */
export const getTreeNodes = (templates: any): Record<string, any>[] => {
  // 2) merge nodes in leaves to arrays
  // for each array element => if there are two elements of same key => merge nodes, delete merged
  // works only for depth 1 !!! not recursive in a large tree
  const mergeNodes = (
    localrawList: any,
    currentIndex: number,
    template: any
  ) => {
    const findOthers = localrawList.map(
      (filtertemp: any, innerIndex: number) =>
        filtertemp.key === template.key &&
        !(filtertemp === template) &&
        innerIndex > currentIndex
          ? filtertemp
          : undefined // use null instead of undefined because this is better to check at the automatic test
    );
    findOthers.forEach((findOther: any) => {
      if (findOther !== undefined) {
        /* findOther.nodes.forEach((template2: any, currentIndex2: number) => {
          expandNodes(findOther.nodes, currentIndex2, template2);
        }); */
        template.nodes = template.nodes.concat(findOther.nodes);
      }
    });
  };

  // 1) create a raw list of items
  const rawList = templates.map((template: any) => {
    const groupName =
      template.translations[0].metadatatranslation[template.metadata.title];
    const splitted = groupName.split('/').map((gname: string) => gname.trim());

    return getTreeNode(template, splitted, 0);
  });

  rawList.forEach((template: any, currentIndex: number) => {
    mergeNodes(rawList, currentIndex, template);
  });

  // 3. remove duplicates
  const groupNames: string[] = [];
  const rawList2 = rawList
    .map((templ: any) => {
      if (!groupNames.includes(templ.key)) {
        groupNames.push(templ.key);
        return templ;
      } else {
        return undefined;
      }
    })
    .filter((templ: any) => templ !== undefined);

  // 4. recurse one level more
  // for each element, check nodes of next depth

  rawList2.forEach((template: any) => {
    if (template.nodes.length > 0) {
      template.nodes.forEach((template2: any, index2: number) => {
        mergeNodes(template.nodes, index2, template2);
      });

      // 3. remove duplicates
      const groupNames: string[] = [];
      const rawList3 = template.nodes
        .map((templ: any) => {
          if (!groupNames.includes(templ.key)) {
            groupNames.push(templ.key);
            return templ;
          } else {
            return undefined;
          }
        })
        .filter((templ: any) => templ !== undefined);

      template.nodes = rawList3;
    }
  });

  return rawList2;
};

// use a global variable that is modified within component
// this is necessary as TReeMenu can't cope with states, properly.
// you would need a special Warpper component for that
let treeData: any[] = [];

/** Template Selection Component */
export const TemplateSelection: React.FC = () => {
  const styles = useGlobalStyles();
  const intl = useIntl();
  const { l } = useContext(DevToolsContext);

  //const [template, setTemplate] = useState<any>(undefined);

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    _elementNodeType: any,
    element: any
  ) => {
    // transfer moduleid of tenplate to builder ==> template is loaded there
    //const template=any

    event.dataTransfer.setData(
      'application/reactflow/template',
      element && element.moduleid ? element.moduleid : ''
    );
    event.dataTransfer.effectAllowed = 'move';
    //setTemplate(template.moduleid);
  };

  const [descriptionTextHidden, setDescriptionTextHidden] = useState<number>(0);
  const [descriptionText, setDescriptionText] = useState<string>('');

  const [treedata, setTreedata] = useState<any[]>(treeData);

  const showDescriptionText = (element: any, offsetTop?: number) => {
    setDescriptionTextHidden(element === '' ? 0 : offsetTop ?? 0);
    if (element !== '') {
      setDescriptionText(
        intl.formatMessage({ id: element.metadata.description })
      );
    }
  };

  const { modules, isLoading: isLoadingModuleList } = useFetchModules(
    true,
    true,
    true
  );

  useEffect(() => {
    l('TemplateSelection: useEffect with modules changed');

    if (isLoadingModuleList || !modules || modules.length === 0) return;
    // modify global variable as the TreeMenu can't cope with states
    const treedata = modules
      ? getTreeNodes(
          modules.filter((module: ModuleProps) => module.issubmodule)
        )
      : [];

    setTreedata(treedata);
    //console.log(treeData);
  }, [isLoadingModuleList, l, modules]);

  if (isLoadingModuleList) {
    return (
      <Typography component="span" variant="body2">
        <FormattedMessage {...templateSelectionMessages.loadingtemplates} />
      </Typography>
    );
  }

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const maxHeight = vh - 200 + 'px';

  return (
    <div>
      <TreeMenu data={treedata} debounceTime={125} resetOpenNodesOnDataUpdate>
        {({ search, items }) => {
          return (
            <>
              <FormControl variant="standard">
                <Input
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    if (search && e.target.value !== undefined) {
                      search(e.target.value);
                    }
                  }}
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  }
                  autoComplete="off"
                  autoCorrect="off"
                  placeholder={intl.formatMessage({
                    id: 'builder.pages.builder.templateSelection.searchbox',
                    defaultMessage: 'Vorlage suchen ...',
                  })}
                />
              </FormControl>
              <Stack
                sx={{
                  height: maxHeight,
                  maxHeight: maxHeight,
                  overflow: 'auto',
                  display: 'flex',
                  marginTop: '10px',
                }}
              >
                {items.map(({ _reset, ...props }, index: number) => {
                  // we have to map that here explicitely, because
                  // passing props leads to warnings due to wrongly
                  // named DOM nodes (props). It's an issue of the
                  // component
                  return ListItem({
                    level: props.level,
                    hasNodes: props.hasNodes,
                    isOpen: props.isOpen,
                    label: props.label,
                    searchTerm: props.searchTerm,
                    openNodes: props.openNodes,
                    toggleNode: props.toggleNode,
                    onClick: props.onClick,
                    focused: props.focused,
                    element: props.template,
                    styles: styles,
                    onDragStart: onDragStart,
                    showPreviewImage: showDescriptionText,
                    index: index, // needed for making keys unqiue
                  });
                })}
              </Stack>
            </>
          );
        }}
      </TreeMenu>
      {descriptionTextHidden !== 0 && (
        <div
          key="previewimage"
          style={{
            maxWidth: '200px',
            background: 'white',
            padding: '16px',
            borderColor: evoachDefaultTheme.palette.secondary.main,
            borderStyle: 'solid',
            borderWidth: '1px',
            zIndex: 5000,
            position: 'absolute',
            left: '300px',
            top: descriptionTextHidden - 30 + 'px',
          }}
          hidden={descriptionTextHidden === 0}
        >
          {descriptionText}
        </div>
      )}
    </div>
  );
};
