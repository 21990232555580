import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.ratingInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

export const ratingInputStateEntry: StateEntryProps = {
  type: 'renderRatingInput',
  temporary: true,
  payload: {
    buttonText: 'builder.nodes.ratingInput.state.buttonText',
    saveResultTo: 'rating_result',
  },
  saveResultType: ReadWriteType.number,
  displayName: 'Rating Input',
  nodeType: 'ratingInputStateEntry',
  handleOutCount: 6,
};
