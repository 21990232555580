import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.sceneCanvas.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

/**
 * Scene Canvas xState specific defintions
 */
export const sceneCanvasStateEntry: StateEntryProps = {
  version: 1,
  type: 'renderSceneCanvas',
  temporary: true,
  payload: {
    getValueFrom: '',
    saveResultTo: 'sceneCanvasResult',
    buttonText: 'builder.nodes.sceneCanvas.state.buttonText',
  },
  getValueType: [ReadWriteType.characters],
  saveResultType: [ReadWriteType.characters],
  displayName: 'Scene Canvas',
  nodeType: 'sceneCanvasStateEntry',
  handleOutCount: 1,
};
