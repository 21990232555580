import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useIntl } from 'react-intl';

import { authorizedPut } from '../../../api/authorizedApi';
import { ModuleProps } from '../../../entities';

export interface SetDefaultLanguageButtonProps {
  module: ModuleProps;
  defaultLanguage: string;
  newDefaultLanguage: string;
  moduleLanguages: string[];
  refetchModule: Function;
}

export const SetDefaultLanguageButton: React.FC<
  SetDefaultLanguageButtonProps
> = ({
  module,
  defaultLanguage,
  newDefaultLanguage,
  moduleLanguages,
  refetchModule,
}) => {
  const intl = useIntl();

  const [defaultLanguageDialogOpen, setDefaultLanguageDialogOpen] =
    useState<boolean>(false);

  const setDefaultNewLanguage = () => {
    setDefaultLanguageDialogOpen(!defaultLanguageDialogOpen);
  };

  const onClose = async (doit: boolean) => {
    if (doit && moduleLanguages.includes(newDefaultLanguage)) {
      const updateSequence = { metadata: module.metadata };
      updateSequence.metadata.defaultLanguage = newDefaultLanguage;
      const updateMatadataCall = authorizedPut(
        `/module/${module.moduleid}`,
        updateSequence
      );
      const response = await updateMatadataCall();
      await response.json();
      refetchModule();
    }
    setDefaultLanguageDialogOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => setDefaultNewLanguage()}
        key={newDefaultLanguage + 'icodef'}
        style={{ verticalAlign: 'top' }}
        disabled={defaultLanguage === newDefaultLanguage}
      >
        <Tooltip
          placement="top"
          arrow
          title={intl.formatMessage({
            id: 'builder.translationeditor.setdefault.title',
            defaultMessage:
              'Dieses Sprache zur Default-Sprache des Modules machen.',
          })}
          key={newDefaultLanguage + 'defau'}
        >
          <PlayCircleOutlineIcon />
        </Tooltip>
      </IconButton>

      <Dialog fullWidth open={defaultLanguageDialogOpen}>
        <DialogTitle>Neue Default-Sprache definieren</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2" component="span">
              {intl.formatMessage({
                id: 'builder.translationeditor.setdefault.description',
                defaultMessage:
                  'Dieses Sprache zur Default-Sprache des Modules machen. Die Default-Sprache eines Moduls hat eine besondere Bedeutung. Sie wird als Vorlage verwendet, wenn du neue Sprachversionen anlegst. Außerdem wird sie für neue Coaching-Sessions verwendet, wenn das Modul in der Sprache deines Coachees nicht verfügbar ist. Soll die Default-Sprache geändert werden?',
              })}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose(false)}>
            {intl.formatMessage({
              id: 'builder.translationeditor.dialog.cancel',
              defaultMessage: 'Abbrechen',
            })}
          </Button>
          <Button color="secondary" onClick={() => onClose(true)}>
            {intl.formatMessage({
              id: 'builder.translationeditor.dialog.ok',
              defaultMessage: 'Ok',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
