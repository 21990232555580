import React from 'react';

import { TemplateList } from './TemplateList';

export const TemplateTab: React.FC = () => {
  return (
    <div style={{ height: '60vh' }}>
      <TemplateList />
    </div>
  );
};
