import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';

/**
 * get module information for embedding the module externally, incl.
 * the allowedReferers or any other parameters
 * @param {string} moduleId to be fetched
 * @returns isLoading, isError, error, module, refetch
 */
export const useFetchModuleEmbed = (moduleId: string | undefined) => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `module-${moduleId}-embed`,
    async () => {
      if (!moduleId || moduleId.trim() === '') return undefined;

      const url = `/module/${moduleId}/external`;

      const fetchDyn = authorizedGet(url);
      const response = await fetchDyn();

      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    embeddingInformation: data,
    refetch,
  };
};
