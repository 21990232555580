import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { evoachDefaultTheme } from '@evoach/ui-components';
import { useIntl } from 'react-intl';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';

import { ModuleProps } from '../../../entities';
import { AppRoutes } from '../../../routing/routes';
import { AccountContext, RoleEnum } from '../../../account';

import { NewLanguageButton } from './NewLanguageButton';
import { DeleteLanguageButton } from './DeleteLanguageButton';
import { SetDefaultLanguageButton } from './SetDefaultLanguageButton';
import { ExportLanguageButton } from './ExportImport/ExportLanguageButton';
import { ImportLanguageButton } from './ExportImport/ImportLanguageButton';

/*
  the tab implementation in this component is derived from the examples 
  at https://mui.com/components/tabs/
*/

const Tab = styled(TabUnstyled)`
  font-family: Roboto;
  color: #808080;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: normal;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  border: none;
  display: flex;
  justify-content: center;

  &:focus {
    color: #fff;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #ffffff;
    color: ${evoachDefaultTheme.palette.primary.main};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: Roboto;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  background-color: #cccccc;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export interface TranslationSidebarProps {
  module: ModuleProps;
  refetchModule: Function;
  performQACheck: Function;
}
export const TranslationSidebar: React.FC<TranslationSidebarProps> = ({
  module,
  refetchModule,
  performQACheck,
}) => {
  const intl = useIntl();
  let navigate = useNavigate();
  const { hasRole } = useContext(AccountContext);

  const [defaultLanguage, setDefaultLanguage] = useState<string>(
    module?.metadata?.defaultLanguage ?? intl.locale.toUpperCase()
  );

  const [moduleLanguages, setModuleLanguages] = useState<string[]>(
    module?.metadata?.moduleLanguages ?? []
  );

  useEffect(() => {
    setDefaultLanguage(
      module?.metadata?.defaultLanguage ?? intl.locale.toUpperCase()
    );
  }, [module?.metadata?.defaultLanguage, intl.locale]);

  useEffect(() => {
    setModuleLanguages(module?.metadata?.moduleLanguages ?? []);
  }, [module?.metadata?.moduleLanguages]);

  const openLanguageInBuilder = (lang: string) => {
    if (!module || module.moduleid === '' || !lang || lang === '') {
      navigate(`${AppRoutes.RECEPTION}`);
    }
    navigate(`${AppRoutes.BUILDER}/${module.moduleid}/${lang}`);
  };

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#cccccc',
        borderRadius: '5px',
        height: '100%',
      }}
    >
      <TabsUnstyled
        defaultValue={0}
        style={{
          width: '100%',
        }}
        component="div"
      >
        <TabsList>
          <Tab>Sprachen</Tab>
        </TabsList>
        <TabPanel value={0} style={{ paddingLeft: '10px' }}>
          <Typography variant="h6" color="primary" component="div">
            {intl.formatMessage({ id: module.metadata.title })}
          </Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: '10px' }}
            component="div"
          >
            {intl.formatMessage({
              id: module.metadata.description,
            })}
          </Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: '10px', marginBottom: '10px' }}
            component="div"
          >
            {intl.formatMessage({
              id: 'builder.translationeditor.sidebar.duration',
              defaultMessage: 'Dauer:',
            })}{' '}
            {intl.formatMessage({ id: module.metadata.duration })}
          </Typography>
          <Divider
            sx={{ marginTop: '10px', marginBottom: '10px', width: '80%' }}
          />
          <Typography
            variant="body2"
            sx={{ marginTop: '10px', marginBottom: '10px' }}
            component="div"
          >
            {intl.formatMessage({
              id: 'builder.translationeditor.sidebar.availablelanguages',
              defaultMessage: 'Verfügbare Sprachen für dieses Modul:',
            })}
          </Typography>
          <Typography
            variant="body1"
            color="secondary"
            component="div"
            fontWeight={800}
          >
            {moduleLanguages.map((l: string) => (
              <Box
                style={{
                  display: 'grid',
                  grid: hasRole(RoleEnum.EVOACHADMIN)
                    ? 'auto / 120px 25px 25px 25px 25px 25px'
                    : 'auto / 120px 25px 25px 25px',
                }}
                key={l + 'masterbox'}
              >
                <Box key={l + 'col1'} style={{ marginTop: '8px' }}>
                  {l} {defaultLanguage === l && <>(Default)</>}
                </Box>
                <Box key={l + 'col2'}>
                  <IconButton
                    onClick={() => openLanguageInBuilder(l)}
                    key={l + 'icop'}
                  >
                    <Tooltip
                      placement="top"
                      arrow
                      title={intl.formatMessage({
                        id: 'builder.translationeditor.sidebar.openineditor',
                        defaultMessage:
                          'Modul in dieser Sprache im Creator öffnen.',
                      })}
                      key={l + 'open'}
                    >
                      <RemoveRedEyeIcon key={l + 'previcon'} />
                    </Tooltip>
                  </IconButton>
                </Box>
                <Box key={l + 'col3'}>
                  <DeleteLanguageButton
                    moduleid={module.moduleid}
                    defaultLanguage={defaultLanguage}
                    languageToDelete={l}
                    refetchModule={refetchModule}
                  />
                </Box>
                <Box key={l + 'col4'}>
                  <SetDefaultLanguageButton
                    module={module}
                    defaultLanguage={defaultLanguage}
                    newDefaultLanguage={l}
                    moduleLanguages={moduleLanguages}
                    refetchModule={refetchModule}
                  />
                </Box>
                {hasRole(RoleEnum.EVOACHADMIN) && (
                  <>
                    <Box key={l + 'col5'}>
                      <ExportLanguageButton
                        module={module}
                        exportLanguage={l}
                      />
                    </Box>
                    <Box key={l + 'col6'}>
                      <ImportLanguageButton
                        module={module}
                        importLanguage={l}
                        refetchModule={refetchModule}
                      />
                    </Box>
                  </>
                )}
              </Box>
            ))}
          </Typography>
          <Box sx={{ marginTop: '20px' }}>
            <NewLanguageButton
              moduleid={module.moduleid}
              defaultLanguage={defaultLanguage}
              moduleLanguages={moduleLanguages}
              refetchModule={refetchModule}
            />
          </Box>
          <Button sx={{ marginTop: '10px' }} onClick={() => performQACheck()}>
            Qualitätsanalyse
          </Button>

          <Typography
            color={evoachDefaultTheme.palette.primary.main}
            sx={{ marginTop: '100px' }}
          >
            {intl.formatMessage({
              id: 'builder.translationeditor.sidebar.clickHint2',
              defaultMessage:
                'Klicke auf einen der Texte rechts, um ihn zu editieren. Änderungen werden automatisch gespeichert.',
            })}
          </Typography>
        </TabPanel>
      </TabsUnstyled>
    </Box>
  );
};
