import {
  audioDisplayStateEntry,
  imageDisplayStateEntry,
  videoDisplayStateEntry,
} from '../nodes';

export enum AudioFileExtensions {
  wav = 'wav',
  wma = 'wma',
  mp3 = 'mp3',
  mp4 = 'mp4',
  m4a = 'm4a',
  aac = 'aac',
  ogg = 'ogg',
  webm = 'webm',
  flac = 'flac',
}

export enum VideoFileExtensions {
  webm = 'webm',
  mp4 = 'mp4',
  m4p = 'm4p',
  m4v = 'm4v',
  mpg = 'mpg',
  mpeg = 'mpeg',
  mpv = 'mpv',
  mp2 = 'mp2',
  avi = 'avi',
  mov = 'mov',
  qt = 'qt',
  wmv = 'wmv',
  mkv = 'mkv',
  aac = 'aac',
}

export enum ImageFileExtensions {
  jpg = 'jpg',
  jpeg = 'jpeg',
  gif = 'gif',
  png = 'png',
  bmp = 'bmp',
}

export enum AssetType {
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  DOCUMENT = 'document', // PROD-1752
}

export enum AssetSort {
  BYNAME = 'name',
  BYDATE = 'date',
}

// PROD-1752
export enum DocumentFileExtensions {
  doc = 'doc',
  pdf = 'pdf',
  docx = 'docx',
  ods = 'ods',
  ppt = 'ppt',
  pptx = 'pptx',
  xls = 'xls',
  xlsx = 'xlsx',
  txt = 'txt', // for simple AI-splitting cases
  csv = 'csv', // esepcially for AI cases when each lie becomes an embedding
}

export const FileTypesAllowedForAiIntegration: string[] = [
  DocumentFileExtensions.txt,
  DocumentFileExtensions.pdf,
  //DocumentFileExtensions.docx,
  DocumentFileExtensions.csv,
].map((ext) => ext.toLowerCase() + '');

/**
 * array of all valid file extensions
 */
export const validExtensions = Object.keys(AudioFileExtensions)
  .concat(Object.keys(VideoFileExtensions))
  .concat(Object.keys(ImageFileExtensions))
  .concat(Object.keys(DocumentFileExtensions)); // PROD-1752

/**
 * checks whether the extension of the file indictaes an allowed file type,
 * e.g., for uploading.
 *
 * @param {string} filename
 * @returns
 */
export const isValidFileType = (filename: string): boolean =>
  validExtensions.includes(getFileExtension(filename));

/**
 * return the file extension of the key in lower case
 * @param {string} key of the asset
 * @returns {string} file extension like 'jpg' (lower case)
 */
export const getFileExtension = (fileName: string) => {
  if (fileName.split('.').length > 0) {
    return fileName.split('.').pop()!.toLowerCase();
  } else {
    return '';
  }
};

export const isMatchingAssetType = (assetKey: string, nodeType: string) => {
  const extension = getFileExtension(assetKey);
  return (
    (isImageExtension(extension) &&
      nodeType === imageDisplayStateEntry.nodeType) ||
    (isAudioExtension(extension) &&
      nodeType === audioDisplayStateEntry.nodeType) ||
    (isVideoExtension(extension) &&
      nodeType === videoDisplayStateEntry.nodeType)
  );
};

export const isAudioExtension = (extension: string): boolean => {
  return Object.values<string>(AudioFileExtensions).includes(extension);
};

export const isVideoExtension = (extension: string): boolean => {
  return Object.values<string>(VideoFileExtensions).includes(extension);
};

export const isImageExtension = (extension: string): boolean => {
  return Object.values<string>(ImageFileExtensions).includes(extension);
};

// PROD-1752
export const isDocumentExtension = (extension: string): boolean => {
  return Object.values<string>(DocumentFileExtensions).includes(extension);
};
