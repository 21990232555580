import { Handle, NodeProps, Position } from 'reactflow';
import { AudioDisplay, isTranslated } from '@evoach/ui-components';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { useFetchAssetUrl } from '../../api/asset';
import { translateCreatorWithFallback } from '../../intl/translateWithFallback';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  AudioDisplay react-flow node for module builder graph canvas
*/

export const AudioDisplayNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const s3url = useFetchAssetUrl(
    translateCreatorWithFallback(intl, payload.assetid)
  ).url;

  let imgsrc = payload.src;

  if (!imgsrc.toLowerCase().startsWith('http')) {
    imgsrc =
      payload.assetid && payload.assetid.trim() !== '' && s3url !== ''
        ? s3url
        : translateCreatorWithFallback(intl, payload.src);
  }

  imgsrc =
    imgsrc && imgsrc.trim() !== ''
      ? imgsrc
      : 'http://commondatastorage.googleapis.com/codeskulptor-assets/Epoq-Lepidoptera.ogg';

  // note: the conditional handling of audioText is due to the fact
  // that audioText once was not translated and now is translated
  return (
    <span style={{ height: 10 }}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        {imgsrc && imgsrc.trim() !== '' ? (
          <AudioDisplay
            src={payload.src}
            resolvedsrc={imgsrc}
            assetid={payload.assetid}
            audioText={
              !isTranslated(payload.audioText)
                ? payload.audioText
                : translateKey(intl, payload.audioText)
            }
            audioImage={payload.audioImage}
          />
        ) : (
          <Typography>
            {intl.formatMessage({
              id: 'builder.nodes.audiodisplay.noasset',
              defaultMessage:
                'Du hast noch keine Audiodatei festgelegt. Du kannst eine Datei zu evoach hochladen oder eine externe Audiodatei verlinken.',
            })}
          </Typography>
        )}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </span>
  );
};
