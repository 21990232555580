import React from 'react';
import { useIntl } from 'react-intl';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  Grid,
  DialogActions,
  Paper,
  Box,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { EvoachButton } from '@evoach/ui-components';

import { ProgramTypeEnum } from '../../../entities/Program';

interface ProgramTypeDialogProps {
  isOpen: boolean;
  onClose: Function;
}

export const ProgramTypeDialog: React.FC<ProgramTypeDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen} maxWidth="md">
      <DialogTitle
        sx={{
          padding: '2em 1em 1em 3em',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <>
          {intl.formatMessage({
            id: 'builder.programtypedialog.title',
            defaultMessage: 'Wie möchtest du dein Programm strukturieren?',
          })}
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </>
      </DialogTitle>
      <DialogContent sx={{ padding: '3em', marginBottom: '1em' }}>
        <Grid container spacing={3}>
          <Grid item xs={6} height={425}>
            <Paper
              sx={{
                padding: '10px',
                height: '100%',
                margin: '1em',
                display: 'flex',
              }}
              elevation={6}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                gap="1em"
                padding="1em"
                flexGrow={1}
              >
                <Box>
                  <Typography variant="h6" color="primary">
                    {intl.formatMessage({
                      id: 'builder.programtypedialog.option1.title',
                      defaultMessage: 'Basis Programm',
                    })}
                  </Typography>
                </Box>
                <Box>
                  <img
                    height="150px"
                    width="100%"
                    style={{ objectFit: 'cover' }}
                    src="/assets/programs/program_simple.png"
                    alt="simple program"
                  ></img>
                </Box>
                <Box sx={{ typography: 'body2' }} height="6em">
                  {intl.formatMessage({
                    id: 'builder.programtypedialog.option2.desc',
                    defaultMessage:
                      'Dein Programm besteht aus verschiedenen Programmbausteinen. Das können Video-Calls, Peer-Group- oder Coaching-Sessions oder auch Lerneinheiten sein.',
                  })}
                </Box>

                <EvoachButton
                  color="secondary"
                  onClick={() => {
                    onClose(ProgramTypeEnum.PROGRAM_WITHOUT_SUBMODULES);
                  }}
                >
                  {intl.formatMessage({
                    id: 'builder.programtypedialog.button.close2',
                    defaultMessage: 'Erstellen',
                  })}
                </EvoachButton>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6} height={425}>
            <Paper
              sx={{
                padding: '10px',
                height: '100%',
                margin: '1em',
                display: 'flex',
              }}
              elevation={6}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                gap="1em"
                padding="1em"
                flexGrow={1}
              >
                <Typography variant="h6" color="primary">
                  {intl.formatMessage({
                    id: 'builder.programtypedialog.option2.title',
                    defaultMessage: 'Komplexes Programm',
                  })}
                </Typography>
                <img
                  height="150px"
                  width="100%"
                  style={{ objectFit: 'contain' }}
                  src="/assets/programs/program_subprograms.png"
                  alt="simple program"
                ></img>
                <Box sx={{ typography: 'body2' }} height="6em">
                  {intl.formatMessage({
                    id: 'builder.programtypedialog.option1.desc',
                    defaultMessage:
                      'Dein Programm besteht aus mehreren Unterprogrammen, die wiederum aus verschiedenen Programmbausteinen bestehen.',
                  })}
                </Box>
                <EvoachButton
                  color="secondary"
                  onClick={() => {
                    onClose(ProgramTypeEnum.PROGRAM_WITH_SUBMODULES);
                  }}
                >
                  {intl.formatMessage({
                    id: 'builder.programtypedialog.button.close',
                    defaultMessage: 'Erstellen',
                  })}
                </EvoachButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {false && (
          <EvoachButton
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            {intl.formatMessage({
              id: 'builder.programtypedialog.button.cancel',
              defaultMessage: 'Abbrechen',
            })}
          </EvoachButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
