import { Handle, NodeProps, Position } from 'reactflow';
import {
  MultipleInputComposite,
  MultipleInputCompositeProps,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  MultipleInput react-flow node for module builder graph canvas
*/

export const MultipleInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  let texts: string[] = payload.placeholderTexts;
  if (!texts) {
    texts = ['TODO transl keys'];
  }

  const props: MultipleInputCompositeProps = {
    saveResultTo: payload.saveResultTo,
    placeholderTexts: texts.map((label: string) => {
      return translateKey(intl, label);
    }),
    buttonText: translateKey(intl, payload.buttonText),
    presetValues: [''],
    message: translateKey(intl, payload.message),
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <MultipleInputComposite {...props} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
