import { EmotionEnum } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

export interface EmotionProps {
  type: string; // 'emotioninput'
  icon: EmotionEnum; // icon
  value: string; // name of emotion
  starred?: boolean;
}

export interface EmotionsListEntry {
  emotionsListName: string;
  emotions: EmotionProps[];
}

defineMessages({
  anxiety: {
    id: 'builder.nodes.emotionsInput.state.emotion.anxiety',
    defaultMessage: 'Angst',
  },
  excited: {
    id: 'builder.nodes.emotionsInput.state.emotion.excited',
    defaultMessage: 'Aufgeregt',
  },
  aversion: {
    id: 'builder.nodes.emotionsInput.state.emotion.aversion',
    defaultMessage: 'Abneigung',
  },
  happy: {
    id: 'builder.nodes.emotionsInput.state.emotion.happy',
    defaultMessage: 'Glücklich',
  },
  content: {
    id: 'builder.nodes.emotionsInput.state.emotion.content',
    defaultMessage: 'Fröhlich',
  },
  grateful: {
    id: 'builder.nodes.emotionsInput.state.emotion.grateful',
    defaultMessage: 'Dankbar',
  },
  tired: {
    id: 'builder.nodes.emotionsInput.state.emotion.tired',
    defaultMessage: 'Müde',
  },
  arrogant: {
    id: 'builder.nodes.emotionsInput.state.emotion.arrogant',
    defaultMessage: 'Arrogant',
  },
  angry: {
    id: 'builder.nodes.emotionsInput.state.emotion.angry',
    defaultMessage: 'Wütend',
  },
  sad: {
    id: 'builder.nodes.emotionsInput.state.emotion.sad',
    defaultMessage: 'Traurig',
  },
  stressed: {
    id: 'builder.nodes.emotionsInput.state.emotion.stressed',
    defaultMessage: 'Gestresst',
  },
  scared: {
    id: 'builder.nodes.emotionsInput.state.emotion.scared',
    defaultMessage: 'Verängstigt',
  },
  confused: {
    id: 'builder.nodes.emotionsInput.state.emotion.confused',
    defaultMessage: 'Verwirrt',
  },
  judgemental: {
    id: 'builder.nodes.emotionsInput.state.emotion.judgemental',
    defaultMessage: 'Wertend',
  },
  ashamed: {
    id: 'builder.nodes.emotionsInput.state.emotion.ashamed',
    defaultMessage: 'Beschämt',
  },
  helpless: {
    id: 'builder.nodes.emotionsInput.state.emotion.helpless',
    defaultMessage: 'Hilflos',
  },
  crying: {
    id: 'builder.nodes.emotionsInput.state.emotion.crying',
    defaultMessage: 'Weinend',
  },
  exhausted: {
    id: 'builder.nodes.emotionsInput.state.emotion.exhausted',
    defaultMessage: 'Erschöpft',
  },
  fuming: {
    id: 'builder.nodes.emotionsInput.state.emotion.fuming',
    defaultMessage: 'Kochend',
  },
  indifferent: {
    id: 'builder.nodes.emotionsInput.state.emotion.indifferent',
    defaultMessage: 'Gleichgültig',
  },
  anxious: {
    id: 'builder.nodes.emotionsInput.state.emotion.anxious',
    defaultMessage: 'Besorgt',
  },
  hurt: {
    id: 'builder.nodes.emotionsInput.state.emotion.hurt',
    defaultMessage: 'Verletzt',
  },
  proud: {
    id: 'builder.nodes.emotionsInput.state.emotion.proud',
    defaultMessage: 'Stolz',
  },
  empty: {
    id: 'builder.nodes.emotionsInput.state.emotion.empty',
    defaultMessage: 'Leer',
  },
  cold: {
    id: 'builder.nodes.emotionsInput.state.emotion.cold',
    defaultMessage: 'Kühl',
  },
  down: {
    id: 'builder.nodes.emotionsInput.state.emotion.down',
    defaultMessage: 'Niedergeschlagen',
  },
  disgusted: {
    id: 'builder.nodes.emotionsInput.state.emotion.disgusted',
    defaultMessage: 'Abgeneigt',
  },
  contempt: {
    id: 'builder.nodes.emotionsInput.state.emotion.contempt',
    defaultMessage: 'Verachtung',
  },
  surprised: {
    id: 'builder.nodes.emotionsInput.state.emotion.surprised',
    defaultMessage: 'Überrascht',
  },
  placeholderTexts: {
    id: 'builder.nodes.emotionsInput.state.placeholderTexts',
    defaultMessage: 'Füge ein Emotion hinzu…',
  },
  buttonText: {
    id: 'builder.nodes.emotionsInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
  message: {
    id: 'builder.nodes.emotionsInput.state.message',
    defaultMessage: 'Bitte wähle deine Gefühle aus',
  },
  genericList: {
    id: 'builder.nodes.emotionsInput.state.genericlist',
    defaultMessage: 'Generische Liste',
  },
  basicemotions: {
    id: 'builder.nodes.emotionsInput.state.basicEmotions',
    defaultMessage: 'Basisemotionen',
  },
  nonviolentcomm: {
    id: 'builder.nodes.emotionsInput.state.nonviolentcommunication',
    defaultMessage: 'Gewaltfreie Kommunikation',
  },
  pain: {
    id: 'builder.nodes.emotionsInput.state.pain',
    defaultMessage: 'Schmerz',
  },
  vulnerable: {
    id: 'builder.nodes.emotionsInput.state.vulnerable',
    defaultMessage: 'Verletzbar',
  },
  annoyed: {
    id: 'builder.nodes.emotionsInput.state.annoyed',
    defaultMessage: 'Genervt',
  },
  alarmed: {
    id: 'builder.nodes.emotionsInput.state.alarmed',
    defaultMessage: 'Beunruhigt',
  },
  tense: {
    id: 'builder.nodes.emotionsInput.state.tense',
    defaultMessage: 'Angespannt',
  },
  desire: {
    id: 'builder.nodes.emotionsInput.state.desire',
    defaultMessage: 'Sehnsucht',
  },
});

export const predefinedEmotionsSet: EmotionsListEntry[] = [];
// predefined emotion lists

// -------------------------------------------------
// set 1 - original list, originally made for Christoph
predefinedEmotionsSet.push({
  emotionsListName: 'builder.nodes.emotionsInput.state.genericlist',
  emotions: [
    {
      type: 'emotioninput',
      icon: EmotionEnum.EXCITED,
      value: 'builder.nodes.emotionsInput.state.emotion.excited',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.HAPPY,
      value: 'builder.nodes.emotionsInput.state.emotion.happy',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.CONTENT,
      value: 'builder.nodes.emotionsInput.state.emotion.content',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.GRATEFUL,
      value: 'builder.nodes.emotionsInput.state.emotion.grateful',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.TIRED,
      value: 'builder.nodes.emotionsInput.state.emotion.tired',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.ARROGANT,
      value: 'builder.nodes.emotionsInput.state.emotion.arrogant',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.ANGRY,
      value: 'builder.nodes.emotionsInput.state.emotion.angry',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.SAD,
      value: 'builder.nodes.emotionsInput.state.emotion.sad',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.STRESSED,
      value: 'builder.nodes.emotionsInput.state.emotion.stressed',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.SCARED,
      value: 'builder.nodes.emotionsInput.state.emotion.scared',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.CONFUSED,
      value: 'builder.nodes.emotionsInput.state.emotion.confused',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.JUDGEMENTAL,
      value: 'builder.nodes.emotionsInput.state.emotion.judgemental',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.ASHAMED,
      value: 'builder.nodes.emotionsInput.state.emotion.ashamed',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.HELPLESS,
      value: 'builder.nodes.emotionsInput.state.emotion.helpless',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.CRYING,
      value: 'builder.nodes.emotionsInput.state.emotion.crying',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.EXHAUSTED,
      value: 'builder.nodes.emotionsInput.state.emotion.exhausted',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.FUMING,
      value: 'builder.nodes.emotionsInput.state.emotion.fuming',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.INDIFFERENT,
      value: 'builder.nodes.emotionsInput.state.emotion.indifferent',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.ANXIOUS,
      value: 'builder.nodes.emotionsInput.state.emotion.anxious',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.HURT,
      value: 'builder.nodes.emotionsInput.state.emotion.hurt',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.PROUD,
      value: 'builder.nodes.emotionsInput.state.emotion.proud',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.EMPTY,
      value: 'builder.nodes.emotionsInput.state.emotion.empty',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.COLD,
      value: 'builder.nodes.emotionsInput.state.emotion.cold',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.DOWN,
      value: 'builder.nodes.emotionsInput.state.emotion.down',
    },
  ],
});

// set 2 - 6 Emotionen nach Pail Eckman
predefinedEmotionsSet.push({
  emotionsListName: 'builder.nodes.emotionsInput.state.basicEmotions',
  emotions: [
    {
      type: 'emotioninput',
      icon: EmotionEnum.HAPPY,
      value: 'builder.nodes.emotionsInput.state.emotion.happy',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.SAD,
      value: 'builder.nodes.emotionsInput.state.emotion.sad',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.ANGRY,
      value: 'builder.nodes.emotionsInput.state.emotion.angry',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.SCARED,
      value: 'builder.nodes.emotionsInput.state.emotion.anxiety',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.DISGUSTED,
      value: 'builder.nodes.emotionsInput.state.emotion.aversion',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.CONTEMPT,
      value: 'builder.nodes.emotionsInput.state.emotion.contempt',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.SURPRISED,
      value: 'builder.nodes.emotionsInput.state.emotion.surprised',
    },
  ],
});

// set 3 - GfK
predefinedEmotionsSet.push({
  emotionsListName: 'builder.nodes.emotionsInput.state.nonviolentcommunication',
  emotions: [
    {
      type: 'emotioninput',
      icon: EmotionEnum.HAPPY,
      value: 'builder.nodes.emotionsInput.state.emotion.happy',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.SCARED,
      value: 'builder.nodes.emotionsInput.state.emotion.anxiety',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.ANGRY,
      value: 'builder.nodes.emotionsInput.state.emotion.angry',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.TIRED,
      value: 'builder.nodes.emotionsInput.state.emotion.tired',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.HURT,
      value: 'builder.nodes.emotionsInput.state.pain',
    },

    {
      type: 'emotioninput',
      icon: EmotionEnum.DOWN,
      value: 'builder.nodes.emotionsInput.state.vulnerable',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.DISGUSTED,
      value: 'builder.nodes.emotionsInput.state.emotion.aversion',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.CONFUSED,
      value: 'builder.nodes.emotionsInput.state.emotion.confused',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.DISGUSTED,
      value: 'builder.nodes.emotionsInput.state.annoyed',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.SAD,
      value: 'builder.nodes.emotionsInput.state.emotion.sad',
    },

    {
      type: 'emotioninput',
      icon: EmotionEnum.ASHAMED,
      value: 'builder.nodes.emotionsInput.state.emotion.ashamed',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.SCARED,
      value: 'builder.nodes.emotionsInput.state.alarmed',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.ANXIOUS,
      value: 'builder.nodes.emotionsInput.state.tense',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.EMPTY,
      value: 'builder.nodes.emotionsInput.state.emotion.indifferent',
    },
    {
      type: 'emotioninput',
      icon: EmotionEnum.EXHAUSTED,
      value: 'builder.nodes.emotionsInput.state.desire',
    },
  ],
});

// ! emotion state entry

export const emotionsInputStateEntry: StateEntryProps = {
  version: 'v2',
  type: 'renderEmotionInputComposite',
  temporary: true,
  payload: {
    placeholderTexts: ['builder.nodes.emotionsInput.state.placeholderTexts'],
    elements: predefinedEmotionsSet[1].emotions,
    buttonText: 'builder.nodes.emotionsInput.state.buttonText',
    saveResultTo: 'emotionsinputComposite',
    getValuesFrom: [''],
    minItems: 1,
    maxItems: 5,
    hideOwnInput: false,
    selectAll: false,
    message: 'builder.nodes.emotionsInput.state.message',
  },
  predefinedEmotionsIndex: 1,
  ownElements: [],
  useOwnElements: false,
  saveResultType: ReadWriteType.typedObject,
  getValueType: [ReadWriteType.stringArray, ReadWriteType.typedObject],
  displayName: 'Emotions Input',
  nodeType: 'emotionsInputStateEntry',
  handleOutCount: 1,
};
