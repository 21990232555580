import { Paper, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { evoachDefaultTheme } from '@evoach/ui-components';

/** used in tree view for displaying +/- in front of collapsed/expanded groups */
export const ToggleIcon = (on: boolean) => (
  <span style={{ marginRight: 1 }}>
    {!on && (
      <KeyboardArrowDownIcon
        fontSize="medium"
        color="secondary"
        style={{ verticalAlign: 'middle' }}
      />
    )}
    {on && (
      <KeyboardArrowUpIcon
        fontSize="medium"
        color="secondary"
        style={{ verticalAlign: 'middle' }}
      />
    )}
  </span>
);

/** ListItemProps for props passing
 * without this interface definition, you have to access the properties
 * in the props variable. But this leads to React DOM access warnings as
 * the property names contain upper case letters which is not allowed for
 * DOM nodes.
 */
interface ListItemProps {
  hasNodes: boolean;
  isOpen: boolean;
  label: string;
  searchTerm: string;
  openNodes: string[];
  toggleNode: Function | undefined; // should be destruct here in favor of ToggleIcon
  onClick: Function;
  focused: boolean;
  level: number;
  element: any;
  styles: any; // evoach - style defintion for component
  onDragStart: Function; // evoach - handler for on Drag start
  index: number; // evoach - making keys unique
  showPreviewImage?: Function; // evoach - display preview image
}

/** generate a ListItem both for branches (hasNodes=true) and leafes (hasNodes=false)
 * @param {ListItemProps} TreeView specific properties
 * @returns
 */
export const ListItem = ({
  level = 0,
  hasNodes,
  isOpen,
  label,
  searchTerm,
  openNodes,
  toggleNode,
  onClick,
  focused,
  element,
  styles,
  onDragStart,
  index,
  showPreviewImage,
  ...localprops
}: ListItemProps) => {
  //console.log(element);
  // we can't use label as prefix for keys to make them unique as
  // elements elements can have same name!
  // We need another keyPrefix to make keys unqiue
  // we use this function for elements and for templates, so we need
  // a logic to find a proper prefix
  const keyPrefix = index + '_';

  return (
    <div
      {...localprops}
      style={{
        paddingLeft: level * 5 + 'px',
        cursor: 'pointer',
        boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
        zIndex: focused ? 999 : 'unset',
        position: 'relative',
      }}
      key={keyPrefix + '_enclosing'}
    >
      {hasNodes && (
        <div
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
          onClick={(e) => {
            hasNodes && toggleNode && toggleNode();
            e.stopPropagation();
          }}
          key={keyPrefix + '__hasnodes'}
        >
          {ToggleIcon(isOpen)}
          <Typography
            variant="body2"
            fontWeight="medium"
            fontSize="14"
            color={evoachDefaultTheme.palette.secondary.main}
            component="span"
          >
            {label}
          </Typography>
        </div>
      )}

      {!hasNodes && (
        <>
          <Paper
            component="div"
            key={keyPrefix + '_nonodes'}
            id={keyPrefix + '_nonodes'}
            className={styles.elementselectionbutton}
            onDragStart={(event: any) =>
              onDragStart(event, element.nodeType, element)
            }
            style={{ border: 'none', boxShadow: 'none', width: '85%' }}
            draggable
            onMouseOver={(e: any) => {
              if (showPreviewImage !== undefined) {
                showPreviewImage(
                  element,
                  e.clientY
                );
              }

              const thisDiv = document.getElementById(keyPrefix + '_nonodes');
              if (thisDiv) {
                thisDiv.style.backgroundColor = '#DAE8EC';
              }
            }}
            onMouseOut={() => {
              if (showPreviewImage !== undefined) {
                showPreviewImage('');
              }
              const thisDiv = document.getElementById(keyPrefix + '_nonodes');
              if (thisDiv) {
                thisDiv.style.backgroundColor = 'inherit';
              }
            }}
          >
            <Typography variant="body2" component="span">
              {label}
            </Typography>
          </Paper>
        </>
      )}
    </div>
  );
};
