import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { ImageItem } from '@evoach/ui-components';

import { StateEntryProps } from '../../nodes';
import { ImageListEditorDialog } from '../ListEditorDialog/ImageListEditorDialog';
import { autoSave } from '../../ModuleEditor/autosave';

import { PropertyHeaderLine } from './PropertyHeadLine';

export interface ImageSelectorInputProps {
  /**
   * current (StateEntryProps) entry to be changed
   */
  entry: StateEntryProps;
  /**
   * calls function with parameters (listelements, listkeys, storeForUndo, listkeynumbers)
   */
  updateElementValues: Function;
}

/**
 *
 * @param {ImageSelectorInputProps}
 * @returns
 */
export const ImageSelectorInput: React.FC<ImageSelectorInputProps> = ({
  entry,
  updateElementValues,
}) => {
  const intl = useIntl();

  const [isListEditorOpen, setIsListEditorOpen] = useState<boolean>(false);

  const handleListEditorDialog = () => {
    if (isListEditorOpen) {
      autoSave();
    }
    setIsListEditorOpen(!isListEditorOpen);
  };

  // update elements from image list component
  const updateElements = (
    listelements: ImageItem[],
    listkeys: string[],
    storeForUndo: boolean = true
  ) => {
    updateElementValues(listelements, listkeys, storeForUndo);
  };

  return (
    <div>
      <PropertyHeaderLine
        header={intl.formatMessage({
          id: 'builder.moduleeditor.propertiesSidebar.multivalueinput.header',
          defaultMessage: 'Listeneinträge',
        })}
      />
      <br />
      <Button color="secondary" onClick={handleListEditorDialog}>
        {intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.multivalueinput.ownlistdialog',
          defaultMessage: 'Listeneinträge editieren',
        })}
      </Button>
      <ImageListEditorDialog
        listelements={entry.payload.images ?? []}
        listkeys={entry.payload.keyTexts ?? []}
        open={isListEditorOpen}
        onClose={handleListEditorDialog}
        updateElements={updateElements}
      />
    </div>
  );
};
