import protectedTexts from '../lang/protectedTexts.json';

/** This method provides the capability to display texts in the UI, which are
 * immutable by the user, but can be translated to a specified locale.
 * These texts are listed in ../lang/protectedTexts.json
 *
 * Both logic and texts are duplicated in Player.
 * TODO: Automatically sync from Builder to Player
 *
 * @param {string} key text key listed in protectedTexts.json
 * @param {string} locale the locale to select the text from
 */

export const getProtectedText = (key: string, locale: string) => {
  const translations = ((protectedTexts as Record<string, any>)[locale] ??
    protectedTexts['EN']) as Record<string, string>;
  return translations[key];
};
