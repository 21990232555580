import { Typography } from '@mui/material';
import { evoachDefaultTheme } from '@evoach/ui-components';

import { getUserLanguage } from '../../intl/TranslationContext';

export const SupportLink: React.FC = () => {
  const locale = getUserLanguage().toLowerCase();
  const supportLink = 'https://www.evoach.com/helpandfaqs?lang=' + locale;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" component="span">
        <a
          href={supportLink}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: evoachDefaultTheme.palette.text.primary,
          }}
        >
          FAQ
        </a>
      </Typography>
    </div>
  );
};
