import { FormControl, Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../../account';
import { DevToolsContext } from '../../../devtools/DevToolsContext';
import { PromptEnum } from '../../../entities/ExternalServicesTypes';
import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { evoachAdminOnlyPrompts } from '../../nodes/AiMicrochat.state';
import { Description } from '../../Description';

import { PropertyHeaderLine } from './PropertyHeadLine';

export interface PromptSelectProps {
  updatePrompt: Function;
  currentValue: PromptEnum;
  displayWidth?: string;
  allowedPrompts?: PromptEnum[];
  nodeType: string;
}

/** GetValueFrom Select generates a select list to select one or more values
 *
 * @param {Function} updateValueFrom handler to update a value
 * @param {string} currentValue current value of parameter
 * @param {boolean} multiselect false = only one value can be select, true = multiplevalues can be selected
 * @param {number} @optional index check whether a certain index of the initial value has to be updated
 * @param {ReadWriteType} getValueType defines the value type that is returned by the component
 */
export const PromptSelect: React.FC<PromptSelectProps> = ({
  updatePrompt,
  currentValue,
  displayWidth = '95%',
  allowedPrompts = [],
  nodeType,
}) => {
  const intl = useIntl();

  const { hasRole } = useContext(AccountContext);

  const [selectValue, setSelectValue] = useState(currentValue);

  //
  //
  //
  const prompts = useMemo(() => {
    if (!allowedPrompts || allowedPrompts.length < 1) return [];

    //
    // Filter out all prompts that are not visible to standard users
    // Introduced with PROD-2071
    //
    return allowedPrompts.filter((prompt: PromptEnum) => {
      return (
        hasRole(RoleEnum.EVOACHADMIN) ||
        (!hasRole(RoleEnum.EVOACHADMIN) &&
          !evoachAdminOnlyPrompts.includes(prompt))
      );
    });
  }, [allowedPrompts, hasRole]);

  const { l } = useContext(DevToolsContext);

  // select only one module!
  const multiSelect = false;

  const handleChange = (value: PromptEnum) => {
    setSelectValue(value);
    updatePrompt(value);

    /* 
    const mod = prompts.filter((mod: ModuleProps) => mod.moduleid === value);
    if (!mod || mod.length === 0) return;
    updateModuleInfo(
      value,
      mod[0].metadata.title,
      mod[0].metadata.titleTranslated
    ); */
  };

  l('ModuleSelect: ' + currentValue);

  return (
    <>
      <PropertyHeaderLine
        header={intl.formatMessage({
          id: 'builder.propertypane.promptselect.header',
          defaultMessage: 'Wähle die KI Funktion',
        })}
      />
      <Description nodetype={nodeType} propname="promptType" />
      <FormControl sx={{ width: displayWidth }}>
        {prompts !== undefined && prompts.length > 0 && (
          <EvoachSelect
            value={selectValue}
            onChange={(event) => handleChange(event.target.value as PromptEnum)}
            multiple={multiSelect}
            displayEmpty
          >
            {prompts.map((selectableValue: any, index: number) => (
              <EvoachMenuItem
                key={`SelectableValue${index}`}
                value={selectableValue}
              >
                {selectableValue}
              </EvoachMenuItem>
            ))}
          </EvoachSelect>
        )}
        {(prompts === undefined || prompts.length === 0) && (
          <Typography>
            {intl.formatMessage({
              id: 'builder.propertypane.promptselect.noprompts',
              defaultMessage: 'Keine Prompts vorhanden.',
            })}
          </Typography>
        )}
      </FormControl>
    </>
  );
};
