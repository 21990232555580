import { evoachDefaultTheme } from '@evoach/ui-components';

import { ReadWriteType, StateEntryProps } from '.';

//
// although the function is the same, we distinguish the nodes for numbers and strings
// to distinguish the data types ReadWriteType.number and ReadWriteType.string when
// selecting variables

//
//  combine list of numbers to an number array
//
export const arrayCombineNumberNodeStateEntry: StateEntryProps = {
  type: 'setCombineToArray',
  temporary: false,
  payload: {
    getValuesFrom: [],
    saveResultTo: '',
  },
  getValueType: ReadWriteType.number,
  saveResultType: ReadWriteType.numberArray,
  displayName: 'Combine to number list',
  nodeType: 'arrayCombineNumberNodeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 1,
};

//
//  combine list of strings to an number array
//
export const arrayCombineStringNodeStateEntry: StateEntryProps = {
  type: 'setCombineToArray',
  temporary: false,
  payload: {
    getValuesFrom: [],
    saveResultTo: '',
  },
  getValueType: [
    ReadWriteType.string,
    ReadWriteType.stringArray,
    ReadWriteType.typedObject,
  ],
  saveResultType: ReadWriteType.stringArray,
  displayName: 'Combine to string list',
  nodeType: 'arrayCombineStringNodeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 1,
};
