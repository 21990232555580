import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { Description } from '../Description';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  AI Chat with Documents
*/

export const AiDocumentChatNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  //const payload = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;
  const message = nodeProps.data.state.entry[0].payload.message
    ? intl.formatMessage({
        id: nodeProps.data.state.entry[0].payload.message,
        defaultMessage: '',
      })
    : '';

  return (
    <>
      <Handle
        type="target"
        id={nodeProps.data.state.entry[0].nodeType + 'in'}
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{
          width: '400px',
          background: nodeProps.data.state.entry[0].nodeMiniMapColor,
        }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          <Description nodetype={nodeType} propname="description" />
          <br />
          &nbsp;
          <br />
          {intl.formatMessage({
            id: 'builder.nodes.AiDocumentChatNode.promptType',
            defaultMessage:
              'Die KI wird Fragen zu den angegebenen Dokumenten beantworten.',
          })}
          {message !== '' && (
            <>
              <br />
              {intl.formatMessage(
                {
                  id: 'builder.nodes.AiDocumentChatNode.primaryTask',
                  defaultMessage:
                    'Dabei wird die KI zusätzlich diese spezielle Anweisung berücksichtigen: {specialMessage}',
                },
                {
                  specialMessage: (
                    <p>
                      <b>{message}</b>
                    </p>
                  ),
                }
              )}

              <br />
            </>
          )}
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id={nodeProps.data.state.entry[0].nodeType + 'out'}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
