import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  yes: {
    id: 'builder.nodes.yesNoButton.state.yes',
    defaultMessage: 'Ja',
  },
  no: {
    id: 'builder.nodes.yesNoButton.state.no',
    defaultMessage: 'Nein',
  },
});

export const yesNoButtonStateEntry: StateEntryProps = {
  type: 'renderYesNoButton',
  temporary: true,
  payload: {
    buttonTextYes: 'builder.nodes.yesNoButton.state.yes',
    buttonTextNo: 'builder.nodes.yesNoButton.state.no',
    saveResultTo: '',
  },
  displayName: 'Yes/No Button',
  saveResultType: ReadWriteType.string,
  nodeType: 'yesNoButtonStateEntry',
  handleOutCount: 2, // number of outgoing handles
};
