import { EvoachButton, evoachDefaultTheme } from '@evoach/ui-components';
import {
  Box,
  FormControl,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Input,
  InputAdornment,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import React, { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SearchIcon from '@mui/icons-material/Search';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';

import { useFetchAssets } from '../../../api/asset/useFetchAssets';
import { Asset } from '../../../entities';
import {
  AssetSort,
  AssetType,
  getFileExtension,
  isAudioExtension,
  isDocumentExtension,
  isImageExtension,
  isVideoExtension,
} from '../../PropertiesSidebar/assetHelper';
import { TabPanelProps, getTabProps } from '../../tabHelper';
import { useEnvironment } from '../../../environment/useEnvironment';

import { AssetUploadDialog } from './AssetUploadDialog';
import { AssetTypeSelect } from './AssetTypeSelect';
import { SortAssetBySelect } from './SortAssetBySelect';
import { getPublicAssets } from './AssetHelper';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span" sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};
/**
 * Asset selector props
 */
interface AssetSelectorProps {
  /**
   * is used as sx of the envelopping Box in component
   */
  sx?: SxProps<Theme>;
  /**
   * click functino with sgnature (asset: Asset) => {}. If you set
   * addPublicAssets to true, you have to handle the case that there is
   * no assetId but an externalUrl in metainfos.
   */
  onAssetSelect?: Function;
  /**
   * filters according to AssetType. If undefined, no filter is applied
   */
  assetType?: AssetType;
  /**
   * a text that is used as a filter on the asset keys. Not case-sensitive
   */
  assetTitleFilter?: string;
  /**
   * a function that is used to update the list also in the asset tab
   */
  onUpdateList?: Function;
  /**
   * add static images with public access (no S3 URL). Default: false
   */
  addPublicAssets?: boolean;
  /**
   * a pre-selected asset if available
   */
  asset?: Asset;
}

/**
 * Asset selector lets the user display and select her own assets as well
 * as the default assets.
 *
 * @param {AssetSelectorProps} AssetSelectorProps parameters to component
 */
export const AssetSelector: React.FC<AssetSelectorProps> = ({
  sx,
  onAssetSelect,
  assetType,
  assetTitleFilter,
  onUpdateList = () => {},
  addPublicAssets = false,
  asset,
}) => {
  const { playerBasePath } = useEnvironment();

  // get user assets and default assets
  const { assets, refetch } = useFetchAssets();

  const intl = useIntl();
  const [tabvalue, setTabValue] = useState<number>(addPublicAssets ? 1 : 0);
  const [usedAssetTitleFilter, setUsedAssetTitleFilter] = useState<string>(
    assetTitleFilter ? assetTitleFilter : ''
  );
  const [shownAssetType, setShownAssetType] = useState<AssetType | undefined>(
    assetType
  );
  const [assetSort, setAssetSort] = useState<AssetSort>(AssetSort.BYDATE);

  const [isAssetUploadOpen, setIsAssetUploadOpen] = useState<boolean>(false);

  // if the selected asset is changing, refetch. This is especially
  // useful of the selected asset is set/unset from outside, e.g. after
  // deleting the asset.
  useEffect(() => {
    refetch();
  }, [asset, refetch]);

  // tab clicked
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // asset is selected when image is clicked
  const onPreviewImageClick = (asset: Asset) => {
    if (onAssetSelect) {
      onAssetSelect(asset);
    }
  };

  /*   console.log('shownAssetType', shownAssetType);
  console.log('addPublicAssets', addPublicAssets);
 */
  // check addPublicAssets => save state via useMemo to avoid adding the
  // public static default assets again and again.
  const listOfAssets: Asset[] = useMemo(() => {
    const list: Asset[] = [assets].flat() ?? [];

    // addPublicAssets = false => do not add anything and return actual asset list
    if (!addPublicAssets) {
      return list;
    }

    // add public static default assets only for images
    if (!shownAssetType || shownAssetType === AssetType.IMAGE) {
      const addAssets = getPublicAssets(playerBasePath);
      // for unknown reasons listOfAssets.concat(addAssets) is not
      // working. Pushing all values works
      addAssets.forEach((a: Asset) => {
        list.push(a);
      });
    }
    return list;
  }, [assets, addPublicAssets, shownAssetType, playerBasePath]);

  const onUpload = (asset: Asset | number | undefined) => {
    if (asset === undefined || typeof asset === 'number') {
      alert(
        intl.formatMessage({
          id: 'builder.reception.assets.assettab.uploaderror',
          defaultMessage:
            'Beim Hochladen deiner Datei ist ein Fehler aufgetreten. Bitte versuche es noch einmal.',
        })
      );
    } else {
      onUpdateList();
      setUsedAssetTitleFilter(usedAssetTitleFilter === '' ? '.' : '');
      refetch();
    }
    // close upload dialog
    setIsAssetUploadOpen(false);
  };

  const getListJSX = (ownassets: boolean) => {
    return (
      <>
        {listOfAssets !== undefined && (
          <ImageList
            sx={{
              minWidth: '90%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            rowHeight={164}
          >
            {listOfAssets
              .filter((a: Asset) => a) //get rid of undefined if existing
              .filter((asset: Asset) => {
                return (asset?.isDefaultAsset ?? false) === !ownassets;
              })
              .filter((asset: Asset) => {
                // ! apply type filter if present
                if (!shownAssetType) return true;

                const extension = getFileExtension(asset.key);
                if (
                  shownAssetType === AssetType.AUDIO &&
                  isAudioExtension(extension)
                ) {
                  return true;
                }

                if (
                  shownAssetType === AssetType.IMAGE &&
                  isImageExtension(extension)
                ) {
                  return true;
                }

                if (
                  shownAssetType === AssetType.VIDEO &&
                  isVideoExtension(extension)
                ) {
                  return true;
                }

                if (
                  shownAssetType === AssetType.DOCUMENT &&
                  isDocumentExtension(extension)
                ) {
                  return true;
                }

                return false;
              })
              .filter((asset: Asset) => {
                // ! apply text filter if present
                if (!usedAssetTitleFilter || usedAssetTitleFilter === '')
                  return true;
                return (asset.key + '')
                  .toLowerCase()
                  .includes(usedAssetTitleFilter.toLowerCase());
              })
              .sort((a: any, b: any) => {
                // ! apply sort after
                if (assetSort === AssetSort.BYDATE) {
                  return a.tscreated.localeCompare(b.tscreated) === 1 ? -1 : 1;
                } else {
                  return a.key.localeCompare(b.key);
                }
              })
              .map((asset: Asset, index: number) => {
                const extension = getFileExtension(asset.key);
                //
                // check whether metadata is available. This is also a good
                // indicator whether thumbnail is available
                //
                const assetHasMetadata = Object.keys(asset.metadata).length > 0;

                // check subtitle
                const assetSubtitle =
                  assetHasMetadata && asset.metadata.width!
                    ? `${extension}, ${asset.metadata.width} x ${asset.metadata.height}`
                    : extension;

                return (
                  <ImageListItem
                    key={`imglist${asset.key}`}
                    sx={{
                      padding: '5px',
                      height: '160px',
                      marginBottom: '5px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor:
                          evoachDefaultTheme.palette.secondary.main,
                      },
                    }}
                    onClick={() => onPreviewImageClick(asset)}
                  >
                    {asset.thumbnailUrl !== undefined &&
                    asset.thumbnailUrl !== '' &&
                    assetHasMetadata ? (
                      <img
                        style={{ width: '160px' }}
                        src={`${asset.thumbnailUrl}`}
                        alt={asset.key}
                        loading="lazy"
                        onError={(event: any) => {
                          event.target.src = '/assets/noimg.png';
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          width: '160px',
                          height: '160px',
                          textAlign: 'center',
                          margin: 'auto',
                        }}
                        component="span"
                        key={`${index}nopreview`}
                        variant="body2"
                      >
                        {isAudioExtension(getFileExtension(asset.key)) && (
                          <MusicNoteIcon
                            sx={{
                              color: 'rgba(0, 0, 0, 0.5)',
                              width: '50%',
                              marginTop: '20%',
                              fontSize: '300%',
                            }}
                          />
                        )}
                        {isVideoExtension(getFileExtension(asset.key)) && (
                          <LocalMoviesIcon
                            sx={{
                              color: 'rgba(0, 0, 0, 0.5)',
                              width: '50%',
                              marginTop: '20%',
                              fontSize: '300%',
                            }}
                          />
                        )}
                        {isImageExtension(getFileExtension(asset.key)) && (
                          <ImageIcon
                            sx={{
                              color: 'rgba(0, 0, 0, 0.5)',
                              width: '50%',
                              marginTop: '20%',
                              fontSize: '300%',
                            }}
                          />
                        )}
                        {isDocumentExtension(getFileExtension(asset.key)) && (
                          <ArticleIcon
                            sx={{
                              color: 'rgba(0, 0, 0, 0.5)',
                              width: '50%',
                              marginTop: '20%',
                              fontSize: '300%',
                            }}
                          />
                        )}
                      </Typography>
                    )}
                    <ImageListItemBar
                      title={asset.key}
                      subtitle={assetSubtitle}
                      sx={{
                        margin: '5px',
                        '.MuiImageListItemBar-title': {
                          fontVariant: 'body2',
                          fontSize: '12px',
                        },
                      }}
                    />
                  </ImageListItem>
                );
              })}
          </ImageList>
        )}
      </>
    );
  };

  // TODO remove height / adapt to available screen size
  return (
    <Box
      sx={{
        width: '100%',
        height: '600px',
        margin: '10px',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '4px',
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        overflow: 'auto',
        ...sx,
      }}
    >
      <Box component="span" sx={{ width: '100%', padding: '10px' }}>
        <Box>
          <FormControl
            variant="standard"
            style={{
              display: 'inline',
              alignContent: 'bottom',
              alignItems: 'bottom',
              margin: '10px',
            }}
          >
            <Input
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                setUsedAssetTitleFilter(e.target.value);
              }}
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              }
              autoComplete="off"
              autoCorrect="off"
              placeholder={intl.formatMessage({
                id: 'builder.reception.assets.assettab.searchbox',
                defaultMessage: 'Datei suchen ...',
              })}
            />
            <EvoachButton
              sx={{ marginLeft: '10px' }}
              onClick={() => setIsAssetUploadOpen(true)}
            >
              {intl.formatMessage({
                id: 'builder.reception.assets.assettab.uploadbutton',
                defaultMessage: 'Datei hochladen',
              })}
            </EvoachButton>
            <AssetUploadDialog
              isOpen={isAssetUploadOpen}
              onClose={() => setIsAssetUploadOpen(false)}
              onUpload={onUpload}
            />
            <AssetTypeSelect
              onUpdate={(value: AssetType | 'all') =>
                setShownAssetType(value === 'all' ? undefined : value)
              }
              showAllOption
            />
            <SortAssetBySelect
              onUpdate={(value: AssetSort) => {
                setAssetSort(value);
              }}
            />
          </FormControl>
          <Tabs value={tabvalue} onChange={handleChange} aria-label="Reception">
            <Tab
              label={intl.formatMessage({
                id: 'builder.reception.assets.assetselector.tab.own',
                defaultMessage: 'Eigene Dateien',
              })}
              {...getTabProps(0, 'asset')}
            />
            <Tab
              label={intl.formatMessage({
                id: 'builder.reception.assets.assetselector.tab.evoach',
                defaultMessage: 'evoach Dateien',
              })}
              {...getTabProps(1, 'asset')}
            />
          </Tabs>
        </Box>
        <TabPanel value={tabvalue} index={0}>
          {getListJSX(true)}
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          {getListJSX(false)}
        </TabPanel>
      </Box>
    </Box>
  );
};
