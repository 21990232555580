import { evoachDefaultTheme } from '@evoach/ui-components';
import { Box, FormControl, InputLabel, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SupportedModuleLocales } from '../../intl/SupportedLocales';
import { TranslationContext } from '../../intl/TranslationContext';
import { EvoachMenuItem, EvoachSelect } from '../customMaterialComponents';

export interface MetaDataEditorLanguageSelectionProps {
  moduleLocale: string;
  onLanguageChange: Function;
  excludeLocales?: string[];
  showAllOption?: boolean;
  showFlags?: boolean;
  displayLabel?: boolean;
  heightOption: LanguageSelectionHeightOptions;
}

export enum LanguageSelectionHeightOptions {
  MEDIUM = 'medium',
  SMALL = 'small',
  NARROW = 'narrow',
}

/*
  Language selector based on SupportedModuleLocales
  You can add an "All" option with showAllOption = true. Then a translated
  "Alle" Option is added., The value of the selection is "all" in that case, 
  independently of the translation.
*/
export const MetaDataEditorLanguageSelection: React.FC<
  MetaDataEditorLanguageSelectionProps
> = ({
  moduleLocale,
  onLanguageChange,
  excludeLocales = [],
  showAllOption = false,
  displayLabel = false,
  heightOption = LanguageSelectionHeightOptions.MEDIUM,
}) => {
  const intl = useIntl();
  const { locale } = useContext(TranslationContext);

  const displayLocales = Object.keys(SupportedModuleLocales).filter(
    (lang: string) => !excludeLocales.includes(lang)
  );

  const [valueFrom, setValueFrom] = useState<string>(
    excludeLocales.length > 0
      ? displayLocales.includes(moduleLocale)
        ? moduleLocale
        : displayLocales[0]
      : moduleLocale
  );

  const handleChange = (newlocale: string) => {
    setValueFrom(newlocale);
    onLanguageChange(newlocale);
  };

  useEffect(() => {
    if (moduleLocale === undefined) return;
    setValueFrom(moduleLocale);
  }, [moduleLocale]);

  // convert enum to size option
  const narrowMode = heightOption === LanguageSelectionHeightOptions.NARROW;
  const size = !narrowMode
    ? heightOption === LanguageSelectionHeightOptions.MEDIUM
      ? 'medium'
      : 'small'
    : undefined;

  return (
    <FormControl size={size}>
      {displayLabel && (
        <InputLabel id="metadata-editor-language-selection-label">
          {intl.formatMessage({
            id: 'builder.metadataeditorlanguageselection.label',
            defaultMessage: 'Sprache',
          })}
        </InputLabel>
      )}
      <EvoachSelect
        value={valueFrom}
        onChange={(event) => handleChange(event.target.value as string)}
        sx={{
          height: narrowMode ? '25px' : '100%',
          color: narrowMode
            ? evoachDefaultTheme.palette.secondary.main
            : 'black',
          borderColor: evoachDefaultTheme.palette.secondary.main,
          width: '5em',
        }}
        label={displayLabel ? (locale === 'DE' ? 'Sprache' : 'Language') : ''}
        renderValue={(value) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignmentBaseline: 'center',
                alignItems: 'center',
              }}
            >
              <Typography>
                <>
                  {value === 'all'
                    ? intl.formatMessage({
                        id: 'builder.metadataeditorlanguageselection.showAllOption',
                        defaultMessage: 'Alle',
                      })
                    : value}
                </>
              </Typography>
            </Box>
          );
        }}
      >
        {showAllOption && (
          <EvoachMenuItem
            key="languages_showAllOption"
            value="all"
            selected={'all' === valueFrom}
            sx={{
              color: narrowMode
                ? evoachDefaultTheme.palette.secondary.main
                : '#000000',
            }}
          >
            {intl.formatMessage({
              id: 'builder.metadataeditorlanguageselection.showAllOption.text',
              defaultMessage: 'Alle',
            })}
          </EvoachMenuItem>
        )}
        {displayLocales.map((slocale: string, index: number) => (
          <EvoachMenuItem
            key={`languages_${index}`}
            value={slocale}
            selected={slocale === valueFrom}
            sx={{
              color: narrowMode
                ? evoachDefaultTheme.palette.secondary.main
                : '#000000',
            }}
          >
            {/* {showFlags && (
              <>
                <ObjectCardFlags flags={[slocale]} />
                &nbsp;
              </>
            )} */}
            {slocale}
          </EvoachMenuItem>
        ))}
      </EvoachSelect>
    </FormControl>
  );
};
