import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import React from 'react';
import { Node } from 'reactflow';

import {
  isGetTopicsValuesFromKey,
  ReadWriteType,
  StateEntryProps,
} from '../../nodes';

import { GetValueFromSelect } from './GetValueFromSelect';
import { PropertyHeaderLine } from './PropertyHeadLine';

interface GetTopicsValueFromProps {
  entry: StateEntryProps;
  updateGetTopicsValueFrom: Function;
  nodes: Node<any>[];
}

export const GetTopicsValueFrom: React.FC<GetTopicsValueFromProps> = ({
  entry,
  updateGetTopicsValueFrom,
  nodes,
}) => {
  const intl = useIntl();
  return (
    <Stack spacing={1}>
      {Object.keys(entry.payload)
        .filter(isGetTopicsValuesFromKey)
        .map((payloadKey) => {
          // prototype: allow strings, only
          const getValueType = [
            ReadWriteType.stringArray,
            ReadWriteType.typedObject,
          ];
          return (
            <div key={`ElementIdAndKey-${payloadKey}`}>
              <PropertyHeaderLine
                header={intl.formatMessage({
                  id: 'builder.moduleeditor.propertiesSideBar.GetTopicsValueFrom.allowedVariables',
                  defaultMessage: 'Themen werden erzeugt aus ...',
                })}
              />
              <GetValueFromSelect
                updateValueFrom={updateGetTopicsValueFrom}
                currentValue={entry.payload[payloadKey] as string[]}
                multiselect
                getValueType={getValueType}
                nodes={nodes}
              />
            </div>
          );
        })}
    </Stack>
  );
};
