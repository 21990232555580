import { Handle, NodeProps, Position } from 'reactflow';
import { CalenderExportComposite, ICSFormatItem } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  CalenderExport react-flow node for module builder graph canvas
*/

export const CalenderExportCompositeNode: React.FC<NodeProps<any>> = (
  nodeProps: NodeProps<any>
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const exampleItem: ICSFormatItem = {
    startDate: new Date('August 8, 2022 18:00:00'),
    endDate: new Date('August 8, 2022 19:00:00'),
    subject: 'test1',
    description: 'test description 1',
    location: 'Berlin',
  };

  const payload = nodeProps.data.state.entry[0].payload;

  const version = nodeProps.data.state.entry[0].version;
  const cmStyle: Record<string, string> =
    typeof version === 'number' && version >= 3 ? { maxWidth: '400px' } : {};

  return (
    <>
      <Handle
        type="target"
        id="messageinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={cmStyle}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <CalenderExportComposite
          message={translateKey(intl, payload.message)}
          buttonText={translateKey(intl, payload.buttonText)}
          linkButtonText={translateKey(intl, payload.linkButtonText)}
          icsItems={exampleItem}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="messageouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
