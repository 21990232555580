import React from 'react';
import { Navigate } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';
import { CircularProgress, Typography } from '@mui/material';

import { AppRoutes } from '../routing/routes';
import { useCreateEmptyModule } from '../api/module/useCreateEmptyModule';
import { initialModule } from '../entities';

const builderPageMessages = defineMessages({
  error: {
    id: 'builder.moduleeditor.error',
    defaultMessage: 'Es ist ein Fehler aufgetreten: ',
  },
  loading: {
    id: 'builder.moduleeditor.approuter.loading',
    defaultMessage: 'Modul wird geladen ...',
  },
});

export const CreateNewModule: React.FC = () => {
  const { isLoading, isError, error, moduleId } =
    useCreateEmptyModule(initialModule);

  return (
    <>
      {isError && (
        <Typography variant="body2">
          <FormattedMessage {...builderPageMessages.error} />
          {error?.message}
        </Typography>
      )}
      {isLoading && (
        <Typography variant="body2">
          <FormattedMessage {...builderPageMessages.loading} />
          <CircularProgress />
        </Typography>
      )}
      {!isLoading && !isError && (
        <Navigate
          to={`${AppRoutes.BUILDER}/${moduleId}/${initialModule.metadata.defaultLanguage}`}
        />
      )}
    </>
  );
};
