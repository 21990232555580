import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';

/**
 * get an analysis for a goven analysisid
 * @param {string} analysisid to be fetched
 * @returns isLoading, isError, error, analysis, refetch
 */
export const useFetchAnalytics = (analysisid: string) => {
  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `analytics-${analysisid}`,
    async () => {
      if (!analysisid || analysisid.trim() === '') return undefined;

      const url = `/analytics/${analysisid}`;

      const fetchAnalyticsCall = authorizedGet(url);
      const response = await fetchAnalyticsCall();

      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    analysis: data,
    refetch,
  };
};
