import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import {
  SubscriptionType,
  SubscriptionTypeValues,
} from '../../../entities/subscriptionTypes';
import { authorizedPost } from '../../../api/authorizedApi';
import { AccountContext, RoleEnum } from '../../../account';

import { SubscriptionTypeText } from './SubscriptionTypeText';

interface SubscriptionAddPlanProps {
  accountid: string;
  isOpen: boolean;
  onClose: Function;
}

export const SubscriptionAddPlan: React.FC<SubscriptionAddPlanProps> = ({
  accountid,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const { account, hasRole } = useContext(AccountContext);

  const [plan, setPlan] = useState<SubscriptionType>(
    SubscriptionType.FREE_TIER
  );

  if (!isOpen) {
    return null;
  }

  const onAdd = async () => {
    if (!hasRole(RoleEnum.EVOACHADMIN)) return;
    if (!accountid || accountid === '') return;

    const planLoad = {
      targetaccountid: accountid,
      subscriptioninfo: {
        type: plan,
      },
      createdbyaccountid: account?.accountid,
    };
    const createPlan = authorizedPost('/subscription', planLoad);
    const createResponse = await createPlan();
    if (!createResponse.ok) {
      alert('Beim Erzeugen des Plans ist ein Fehler aufgetreten');
    }
  };

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.subscription.addplan.',
          defaultMessage: 'Benutzer neuen Plan zuweisen',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'builder.subscription.addplan.content',
            defaultMessage:
              'Bitte wählen den Plan, der dem Benutzer zugewiesen werden soll.',
          })}
        </Typography>
        <FormControl fullWidth sx={{ marginTop: '20px' }}>
          <InputLabel id="plan-select-label">Age</InputLabel>
          <Select
            labelId="plan-select-label"
            id="plan-select"
            value={plan}
            label="Subscription Plan"
            onChange={(evt: any) => {
              setPlan(evt.target.value);
            }}
          >
            {SubscriptionTypeValues.map((s: number) => (
              <MenuItem key={`${s}tiermenuitem`} value={s as SubscriptionType}>
                <SubscriptionTypeText type={s as SubscriptionType} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {intl.formatMessage({
            id: 'builder.subscription.addplan.button.close',
            defaultMessage: 'Schließen',
          })}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            onAdd();
            onClose();
          }}
        >
          {intl.formatMessage({
            id: 'builder.subscription.addplan.button.add',
            defaultMessage: 'Hinzufügen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
