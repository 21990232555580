import { useQuery } from 'react-query';
import CryptoJS from 'crypto-js';

import { ApiError } from '../ApiError';
import { authorizedGet, authorizedPost } from '../authorizedApi';
import { keycloak } from '../../keycloak';
import { FeatureEnum } from '../../entities/subscriptionTypes';
import { getEnvironment } from '../../environment/useEnvironment';
import { Account } from '../../entities/Account';

// decrption of a text
// used for decrypting features that are encrypted in GET /account in backend
// s. https://www.npmjs.com/package/crypto-js
const decryptText = (text: string, encpass: string) => {
  const b = CryptoJS.AES.decrypt(text, encpass);
  return b.toString(CryptoJS.enc.Utf8);
};

/**
 * Hook to get a user account. If the user accout does not exist, it is created
 * in our backend. If this passes, we had a valid token thus a valid Keycloak
 * user and the only thing missing was the account creation in our database.
 *
 * This Hook handles all this management.
 */

export const useFetchOrCreateAccountQuery = () => {
  const { apiBasePath } = getEnvironment();

  const { isLoading, data, isError, error, failureCount, refetch } = useQuery<
    Account & { features: Record<FeatureEnum, boolean | number> },
    Error
  >(
    'userAccount',
    async () => {
      try {
        const getAccount = authorizedGet('/account');
        const getAccountResponse = await getAccount();

        let res = await getAccountResponse.json();

        res = {
          ...res,
          features: JSON.parse(
            decryptText(
              res.features,
              'px' +
                (apiBasePath === 'http://localhost:3001'
                  ? apiBasePath
                  : 'https://api.evoach.de')
            )
          ),
        };

        // resolve profile pictures
        if (res.metainfos?.profile) {
          // profile picture if available
          res.metainfos.profile.profilePictureResolvedUrl =
            res.metainfos.profile.profilePictureSrc &&
            res.metainfos.profile.profilePictureSrc !== ''
              ? res.metainfos.profile.profilePictureSrc
              : res.metainfos.profile.profilePictureAssetId &&
                res.metainfos.profile.profilePictureAssetId !== ''
              ? await resolveS3ApiCall(
                  res.metainfos.profile.profilePictureAssetId
                )
              : '';
          // logo if available
          res.metainfos.profile.logoPictureResolvedUrl =
            res.metainfos.profile.logoPictureSrc &&
            res.metainfos.profile.logoPictureSrc !== ''
              ? res.metainfos.profile.logoPictureSrc
              : res.metainfos.profile.logoPictureAssetId &&
                res.metainfos.profile.logoPictureAssetId !== ''
              ? await resolveS3ApiCall(res.metainfos.profile.logoPictureAssetId)
              : '';
        }

        return res;
      } catch (error) {
        if (error instanceof ApiError) {
          if (error.httpStatus === 404) {
            // account will be created: save in localstorage that user has
            // a valid token and that account creation was once triggered by
            // a frontend. This is used to render the NotLoggedInPage properly
            // and distinguish between /login and /signup UI
            //
            // TODO params is not the best parameter name as it written to metainfos. Refactor later
            //
            const body = localStorage.getItem('evoachSignUpParameters');
            const createAccount = authorizedPost(
              '/account',
              body ? { params: body } : {}
            );

            const createAccountResponse = await createAccount();

            let res = await createAccountResponse.json();

            res = {
              ...res,
              features: JSON.parse(
                decryptText(res.features, 'px' + apiBasePath)
              ),
            };

            return res;
          }
        }
        throw error;
      }
    },
    {
      retry: 8, // PROD-1289
      // only load when we have an auth token
      enabled: !!keycloak.token,
      // disable refetching on moung and on window focus
      // to avoid multiple calls to the same information. This also
      // prevents the backend from logging several events to be logged
      // s. https://tanstack.com/query/v4/docs/reference/useQuery
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isLoading,
    account: data ?? null,
    isError,
    error,
    failureCount,
    refetch,
  };
};

// reduce API and S3 traffic by caching pre-signed GET Urls
const cachedAssets: Record<string, string> = { '': '' };
cachedAssets[' '] = '';

export const resolveS3ApiCall = async (assetid: string) => {
  // if already cached, return cache
  if (Object.keys(cachedAssets).includes(assetid)) {
    return cachedAssets[assetid];
  }

  const assetURL: RequestInfo = `/asset/${assetid}`;
  const getAssetURL = authorizedGet(assetURL);
  const response = await getAssetURL();
  const url = (await response.json()).url;
  if (url) {
    cachedAssets[assetid] = url;
  }
  return url;
};
