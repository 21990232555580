import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.createCertificate.state.message',
    defaultMessage:
      'Dein Zertifikat wird jetzt erstellt. Du kannst es in wenigen Augenblicken in deinem Profil abrufen.',
  },
  desc: {
    id: 'createCertificateStateEntry.description',
    defaultMessage:
      'Diese Komponente erzeugt ein Zertifikat für den Coachee. Du kannst eine Nachricht angeben, die als Information ausgegeben wird. Außerdem kannst du auswählen, wofür was das Zertifikat erstellt werden soll.',
  },
});

//
//  create certificate
//
export const createCertificateStateEntry: StateEntryProps = {
  version: 3,
  type: 'renderCreateCertificate',
  temporary: false,
  payload: {
    message: 'builder.nodes.createCertificate.state.message',
    programOrModuleId: '',
    templateId: '', // not yet used or set => if empty, backend chooses default
    title: ' ',
    points1: '',
    points2: '',
    points3: '',
  },
  displayName: 'Create Certificate',
  nodeType: 'createCertificateStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 1,
};
