import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.linkButtonDisplay.state.buttonText',
    defaultMessage: 'Öffne Link',
  },
});

export const linkButtonStateEntry: StateEntryProps = {
  type: 'renderLinkButton',
  temporary: false,
  payload: {
    buttonText: 'builder.nodes.linkButtonDisplay.state.buttonText',
    newTab: true,
    src: 'https://www.evoach.com',
  },
  displayName: 'Link Button',
  nodeType: 'linkButtonStateEntry',
  handleOutCount: 1,
};
