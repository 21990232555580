import { useQuery } from 'react-query';

import { ModuleProps } from '../../entities';
import { authorizedPost } from '../authorizedApi';

export const useCreateEmptyModule = (newmodule: ModuleProps) => {
  const { isLoading, data, isError, error } = useQuery<any, Error>(
    `machineData-${newmodule.moduleid}`,
    async () => {
      const url = `/module?language=${newmodule.metadata.defaultLanguage.toUpperCase()}`;
      const emptyModuleCall = authorizedPost(url, newmodule);
      const response = await emptyModuleCall();
      const data = await response.json();
      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    module: data,
    moduleId: data?.moduleid,
  };
};
