import Keycloak, { KeycloakConfig } from 'keycloak-js';

/**
 * Init Keycloak Client
 *
 * We normally use Keycloak Production for all stages: local, Staging and Prod.
 *
 * The URL for the Keycloak production environment is:
 *      https://evoach.keycloak.cloud/auth/
 * The URL fpr the Keyacloak staging environment is:
 *      https://evoach-test.keycloak.cloud/auth/
 *
 * Important related tickets are PROD-1922 and PROD-1564.
 */
export const keycloak = new Keycloak({
  clientId: 'evoach-staging-creator', // PROD-1564
  realm: 'evoach',
  url:
    process.env.REACT_APP_KEYCLOAK_URL ?? 'https://evoach.keycloak.cloud/auth/',
} as KeycloakConfig);
