import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Tabs, Tab, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span" sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const getTabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const DisclaimerPage: React.FC = () => {
  const intl = useIntl();

  const [tabvalue, setTabValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // disable this when intergation is ready, s. PROD-891
  const showSupport = false;

  return (
    <Box component="span" sx={{ width: '100%' }}>
      <Box component="span" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabvalue} onChange={handleChange} aria-label="Reception">
          <Tab
            label={intl.formatMessage({
              id: 'builder.disclaimerpage.tabs.uicompdisclaimer',
              defaultMessage: 'Open Source Lizenzen - UI Components',
            })}
            {...getTabProps(0)}
          />
          <Tab
            label={intl.formatMessage({
              id: 'builder.disclaimerpage.tabs.builder',
              defaultMessage: 'Open Source Lizenzen - Chatbot Creator',
            })}
            {...getTabProps(1)}
          />
          {showSupport && (
            <Tab
              label={intl.formatMessage({
                id: 'builder.disclaimerpage.tabs.support',
                defaultMessage: 'Support and FAQ',
              })}
              {...getTabProps(2)}
            />
          )}{' '}
        </Tabs>
      </Box>
      <TabPanel value={tabvalue} index={0}>
        <iframe
          title="evoach-ui-components-disclaimer"
          style={{
            overflow: 'auto',
            width: '95%',
            height: '700px',
            marginTop: '20px',
          }}
          src="/uicompdisclaimer.txt"
          scrolling="auto"
        />
      </TabPanel>
      <TabPanel value={tabvalue} index={1}>
        <iframe
          title="evoach-creator-disclaimer"
          style={{
            overflow: 'auto',
            width: '95%',
            height: '700px',
            marginTop: '20px',
          }}
          src="/appdisclaimer.txt"
          scrolling="auto"
        />
      </TabPanel>
      {showSupport && (
        <TabPanel value={tabvalue} index={2}>
          <iframe
            title="evoach-creator-faq-support"
            style={{
              overflow: 'auto',
              width: '95%',
              height: '700px',
              marginTop: '20px',
            }}
            src="https://www.evoach.com"
            scrolling="auto"
          />
        </TabPanel>
      )}
    </Box>
  );
};
