import { NodeProps } from 'reactflow';
import { Box, Typography } from '@mui/material';
import { evoachDefaultTheme } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';

/*
  Note react-flow node for module builder graph canvas
*/

export const NoteNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const payload = nodeProps.data.state.entry[0].payload;

  const intl = useIntl();
  const version = nodeProps.data.state.entry[0].version;
  const cmStyle =
    typeof version === 'number' && version >= 3
      ? { maxWidth: '400px', background: evoachDefaultTheme.palette.error.main }
      : {};

  return (
    <>
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={cmStyle}
      >
        <Box
          sx={{
            display: 'block',
            padding: '15px',
            borderRadius: '6px 6px 6px 0',
            backgroundColor: evoachDefaultTheme.palette.error.main,
          }}
        >
          <Typography>{translateKey(intl, payload.message)}</Typography>
        </Box>
      </div>
    </>
  );
};
