import { Handle, NodeProps, Position } from 'reactflow';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  Phase react-flow node for module builder graph canvas
  is not rendered as element in a chatbot but is used to 
  generate the list of phases in the statemachine
*/

export const PhaseNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.phasenodestyleselected
            : classes.phasenodestyle
        }
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography>{translateKey(intl, payload.phasetext)}</Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="phasehandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};

export const PhaseStartNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <div
        className={
          nodeProps.selected
            ? classes.phasenodestyleselected
            : classes.phasenodestyle
        }
      >
        <Typography>{translateKey(intl, payload.phasetext)}</Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="phasehandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};

export const PhaseEndNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        id="phasehandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.phasenodestyleselected
            : classes.phasenodestyle
        }
      >
        <Typography>{translateKey(intl, payload.phasetext)}</Typography>
      </div>
    </>
  );
};
