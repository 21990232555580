import { Checkbox, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { useUpdateSubscription } from '../../../api/subscription/useUpdateSubscription';
import { Subscription } from '../../../entities/Subscription';

interface SubscriptionEnableToggleProps {
  subscriptionid: string;
  onChange?: Function;
  subscriptions: Subscription[];
}

/**
 * SubscriptionEnableToggle
 *
 * With this component you can toggle the validity of a subscription plan by
 * setting the enddate.
 * If enddate is null or lies in the future, the subscription is value,
 * otherwise it is invalid.
 *
 * ! IMPORTANT: if we have an external billing / subscription management,
 * enabling a plan in our system without enabling the plan in the external system
 * would prevent the user from being billed! This flow has to be considered
 * carefully.
 *
 * TODO set validity to some time in future
 *
 */
export const SubscriptionEnableToggle: React.FC<
  SubscriptionEnableToggleProps
> = ({ subscriptionid, onChange, subscriptions }) => {
  const intl = useIntl();

  const { mutate } = useUpdateSubscription(subscriptionid);

  const subscription = subscriptions.find(
    (s: Subscription) => s.subscriptionid === subscriptionid
  );

  const [isActive, setIsActive] = useState<boolean>(
    subscription !== undefined &&
      (subscription.enddate === null ||
        (subscription.enddate !== null &&
          new Date(subscription.enddate) > new Date(Date.now())))
  );

  // toggle active / inactive state by updating enddate
  const toggleActive = () => {
    const isnewactive = !isActive;

    // toggle setting and if successful, refetch list by calling onChange
    mutate(
      {
        enddate: isnewactive ? null : new Date(Date.now()),
      },
      {
        onSuccess: () => {
          if (onChange) {
            onChange();
          }
        },
      }
    );

    setIsActive(isnewactive);
  };

  return (
    <Tooltip
      placement="top"
      arrow
      title={intl.formatMessage({
        id: 'builder.subscription.enabletoggle.tooltip',
        defaultMessage:
          'Klicken, um den Plan aktiv oder inaktiv zu schalten. Ein Häkchen bedeutet, dass der Plan aktiv ist.',
      })}
    >
      <Checkbox
        id="isactivesub"
        key="isactivesub"
        checked={isActive}
        color="secondary"
        onChange={() => {
          toggleActive();
        }}
      />
    </Tooltip>
  );
};
