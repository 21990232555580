//import React from 'react';
import Box from '@mui/material/Box';
//import Tab from '@mui/material/Tab';
//import TabContext from '@mui/lab/TabContext';
//import TabList from '@mui/lab/TabList';
//import TabPanel from '@mui/lab/TabPanel';
import { Typography } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { evoachDefaultTheme } from '@evoach/ui-components';

import { ElementSelection, TemplateSelection } from '.';

const selectionSidebarMessages = defineMessages({
  elementsTabLabel: {
    id: 'builder.pages.builder.selectionSidebar.elementsTabLabel',
    defaultMessage: 'Elemente',
  },
  templatesTabLabel: {
    id: 'builder.pages.builder.selectionSidebar.templatesTabLabel',
    defaultMessage: 'Vorlagen',
  },
});

/*
  the tab implementation in this component is derived from the examples 
  at https://mui.com/components/tabs/
*/

const Tab = styled(TabUnstyled)`
  font-family: Roboto;
  color: #808080;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: normal;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  border: none;
  display: flex;
  justify-content: center;

  &:focus {
    color: #fff;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #ffffff;
    color: ${evoachDefaultTheme.palette.primary.main};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: Roboto;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  background-color: #cccccc;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export const SelectionSidebar = () => {
  const intl = useIntl();

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#cccccc',
        borderRadius: '5px',
      }}
    >
      <TabsUnstyled defaultValue={0} component="div">
        <TabsList>
          <Tab>
            <Typography variant="body2" fontWeight="medium" fontSize="14">
              {intl.formatMessage(selectionSidebarMessages.elementsTabLabel)}
            </Typography>
          </Tab>
          <Tab>
            <Typography variant="body2" fontWeight="medium" fontSize="14">
              {intl.formatMessage(selectionSidebarMessages.templatesTabLabel)}
            </Typography>
          </Tab>
        </TabsList>
        <TabPanel value={0}>
          <Box paddingLeft="10px">
            <ElementSelection />
          </Box>
        </TabPanel>
        <TabPanel value={1}>
          <Box paddingLeft="10px">
            <TemplateSelection />
          </Box>
        </TabPanel>
      </TabsUnstyled>
    </Box>
  );
};
