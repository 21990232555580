import { defineMessages } from '@formatjs/intl';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { evoachDefaultTheme } from '@evoach/ui-components';

import { authorizedDelete, useFetchInvitations } from '../../../api';
import { useEnvironment } from '../../../environment/useEnvironment';

import { InvitationDetailsDialog } from './InvitationDetailsDialog';
import { DeleteDialog } from './DeleteDialog';

const sharedModulesListTexts = defineMessages({
  createdheader: {
    id: 'builder.reception.sharedmodules.listheader.created',
    defaultMessage: 'Erstellt am',
  },
  includedmodulesheader: {
    id: 'builder.reception.sharedmodules.listheader.includedmodules',
    defaultMessage: 'Enthaltene Module',
  },
  noteheader: {
    id: 'builder.reception.sharedmodules.listheader.note',
    defaultMessage: 'Notiz',
  },
  acceptedheader: {
    id: 'builder.reception.sharedmodules.listheader.accepted',
    defaultMessage: 'Akzeptiert von',
  },
  rightsheader: {
    id: 'builder.reception.sharedmodules.listheader.rights',
    defaultMessage: 'Rechte',
  },
  linkheader: {
    id: 'builder.reception.sharedmodules.listheader.link',
    defaultMessage: 'Freigabelink',
  },
  deleteheader: {
    id: 'builder.reception.sharedmodules.listheader.delete',
    defaultMessage: 'Löschen',
  },
  person: {
    id: 'builder.reception.sharedmodules.person',
    defaultMessage: 'Personen',
  },
  confirm: {
    id: 'builder.reception.sharedmodules.confirm',
    defaultMessage:
      'Bist du sicher, dass du das Modul nicht mehr teilen möchtest?',
  },
  copiedToClipboard: {
    id: 'builder.reception.sharedmodules.copiedtoclipboard',
    defaultMessage: 'In Zwischenablage kopiert!',
  },
  emptyDefaultMessage: {
    id: 'builder.reception.sharedmodules.emptydefaultmessage',
    defaultMessage: 'Du hast bisher noch keine Module geteilt.',
  },
});

const useStyles = makeStyles({
  longTextNoWrap: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '18vw',
    textOverflow: 'ellipsis',
  },
  noWrapChildren: { '& > *': { whiteSpace: 'nowrap' } },
  noWrap: { whiteSpace: 'nowrap' },
  cursorPointer: {
    cursor: 'pointer',
    color: evoachDefaultTheme.palette.secondary.main,
    textDecoration: 'underline',
    textDecorationColor: evoachDefaultTheme.palette.secondary.main,
  },
  spacedIcons: {
    '& > *': {
      marginRight: '0.5rem',
    },
  },
});

interface SharedModulesListProps {}

export const SharedModulesList: React.FC<SharedModulesListProps> = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { invitations, refetch: refetchInvitations } = useFetchInvitations();
  const { builderBasePath } = useEnvironment();
  const [invitationDialogId, setInvitationDialogId] = useState<string>('');
  const [isInvitationDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [deleteDialogId, setDeleteDialogId] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsDialogOpen(invitationDialogId !== '');
  }, [invitationDialogId]);

  useEffect(() => {
    setIsDeleteDialogOpen(deleteDialogId !== '');
  }, [deleteDialogId]);

  const deleteInvitation = async (
    invitationid: string,
    isCascading: boolean
  ) => {
    const deletionurl: RequestInfo = `/invitation/${invitationid}`;
    const body = {
      isCascading: isCascading,
    };
    const deleteCall = authorizedDelete(deletionurl, body);
    await deleteCall();
  };

  const rows = useMemo(() => {
    return invitations
      ? invitations
          .filter((i: any) => i) // delete all null and undefined stuff
          .filter(
            (i: any) =>
              i.CoachPermission_InviteCoacheesToSessions === true ||
              i.CoachPermission_CopyModuleAsTemplate === true ||
              i.CoachPermission_EditOriginalModule === true
          )
          .map((invitation: any, mindex: number) => {
            return {
              id: mindex,
              invitationId: invitation.invitationid,
              invitationCreatedRaw: new Date(invitation.tscreated), // needed for proper sorting, s. below
              invitationCreated: new Intl.DateTimeFormat(intl.locale, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }).format(Date.parse(invitation.tscreated + '')),
              modules: invitation.modules.join(', '),
              note: invitation.note,
              accountsAccepted: invitation.accountsAccepted ?? 0,
              rights: {
                CoachPermission_InviteCoacheesToSessions:
                  invitation.CoachPermission_InviteCoacheesToSessions,
                CoachPermission_CopyModuleAsTemplate:
                  invitation.CoachPermission_CopyModuleAsTemplate,
                CoachPermission_EditOriginalModule:
                  invitation.CoachPermission_EditOriginalModule,
                CoacheePermission_StartSession:
                  invitation.CoacheePermission_StartSession,
              },
              invitationLink:
                builderBasePath + `/invitation/` + invitation.invitationid,
            };
          })
          .sort(
            (a: any, b: any) => b.invitationCreatedRaw - a.invitationCreatedRaw
          )
      : [];
  }, [invitations, intl, builderBasePath]);

  const handleCopyInvitationUrl = (invitationUrl: string) => {
    navigator.clipboard
      .writeText(invitationUrl)
      .then(() =>
        alert(intl.formatMessage(sharedModulesListTexts.copiedToClipboard))
      );
  };

  return (
    <>
      {rows.length > 0 ? (
        <>
          <DeleteDialog
            open={isDeleteDialogOpen}
            onClose={() => setDeleteDialogId('')}
            onDelete={(isCascading) =>
              deleteInvitation(deleteDialogId, isCascading).then(() =>
                refetchInvitations()
              )
            }
          />
          <InvitationDetailsDialog
            open={isInvitationDialogOpen}
            onClose={() => setInvitationDialogId('')}
            invitationid={invitationDialogId}
            csvFileName="module"
          ></InvitationDetailsDialog>
          <TableContainer
            component={Paper}
            style={{
              minWidth: '800px',
              minHeight: '100%',
              overflowX: 'initial',
            }}
          >
            <Table
              stickyHeader
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                  paddingBottom: '0.5rem',
                },
              }}
            >
              <colgroup>
                <col style={{ width: '10%', textAlign: 'left' }} />
                <col style={{ width: '20%', textAlign: 'left' }} />
                <col style={{ width: '10%', textAlign: 'left' }} />
                <col style={{ width: '15%', textAlign: 'left' }} />
                <col style={{ width: '20%', textAlign: 'left' }} />
                <col style={{ width: '15%', textAlign: 'left' }} />
                <col style={{ width: '10%', textAlign: 'center' }} />
              </colgroup>
              <TableHead>
                <TableRow className={classes.noWrapChildren}>
                  <TableCell>
                    {intl.formatMessage(sharedModulesListTexts.createdheader)}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage(
                      sharedModulesListTexts.includedmodulesheader
                    )}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage(sharedModulesListTexts.acceptedheader)}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage(sharedModulesListTexts.rightsheader)}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage(sharedModulesListTexts.linkheader)}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage(sharedModulesListTexts.noteheader)}
                  </TableCell>
                  <TableCell align="center">
                    {intl.formatMessage(sharedModulesListTexts.deleteheader)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover={true}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.noWrap}
                    >
                      {row.invitationCreated}
                    </TableCell>
                    <TableCell>
                      <Tooltip placement="top" arrow title={row.modules}>
                        <div className={classes.longTextNoWrap}>
                          {row.modules}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {row.accountsAccepted > 0 ? (
                        <Link
                          color="inherit"
                          className={classes.cursorPointer}
                          onClick={() =>
                            setInvitationDialogId(row.invitationId)
                          }
                        >
                          {row.accountsAccepted +
                            ' ' +
                            intl.formatMessage(sharedModulesListTexts.person)}
                        </Link>
                      ) : (
                        <>
                          {row.accountsAccepted +
                            ' ' +
                            intl.formatMessage(sharedModulesListTexts.person)}
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      className={`${classes.spacedIcons} ${classes.noWrap}`}
                    >
                      <>
                        <Tooltip
                          placement="top"
                          arrow
                          title={intl.formatMessage({
                            id: 'builder.reception.modules.permissions.CoachPermission_InviteCoacheesToSessions.list',
                            defaultMessage:
                              'Die eingeladene Person darf andere zu Coaching Sessions mit den Modulen einladen.',
                          })}
                        >
                          <ForwardToInboxIcon
                            color={
                              row.rights
                                .CoachPermission_InviteCoacheesToSessions
                                ? 'action'
                                : 'disabled'
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          placement="top"
                          arrow
                          title={intl.formatMessage({
                            id: 'builder.reception.modules.permissions.CoachPermission_CopyModuleAsTemplate.list',
                            defaultMessage:
                              'Die eingeladene Person darf die Module für eigene Zwecke kopieren und die Kopien bearbeiten.',
                          })}
                        >
                          <ContentCopyIcon
                            color={
                              row.rights.CoachPermission_CopyModuleAsTemplate
                                ? 'action'
                                : 'disabled'
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          placement="top"
                          arrow
                          title={intl.formatMessage({
                            id: 'builder.reception.modules.permissions.CoachPermission_EditOriginalModule.list',
                            defaultMessage:
                              'Die eingeladene Person darf jeweils das Original der Module editieren.',
                          })}
                        >
                          <EditIcon
                            color={
                              row.rights.CoachPermission_EditOriginalModule
                                ? 'action'
                                : 'disabled'
                            }
                          />
                        </Tooltip>
                      </>
                    </TableCell>
                    <TableCell
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span className={classes.longTextNoWrap}>
                        {row.invitationLink}
                      </span>
                      <ContentCopyIcon
                        className={classes.cursorPointer}
                        color="secondary"
                        onClick={() =>
                          handleCopyInvitationUrl(row.invitationLink)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <>
                        {row.note !== undefined && (
                          <Tooltip placement="top" arrow title={row.note}>
                            <div className={classes.longTextNoWrap}>
                              {row.note}
                            </div>
                          </Tooltip>
                        )}
                      </>
                    </TableCell>
                    <TableCell align="center">
                      <DeleteIcon
                        className={classes.cursorPointer}
                        color="secondary"
                        onClick={() => setDeleteDialogId(row.invitationId)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography>
          {intl.formatMessage(sharedModulesListTexts.emptyDefaultMessage)}
        </Typography>
      )}
    </>
  );
};
