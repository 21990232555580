import { evoachDefaultTheme } from '@evoach/ui-components';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { EdgeProps, getBezierPath } from 'reactflow';

// 2 * foreignObjectSize === width
const foreignObjectSize = 80;

//
// new component for a custom Edge with an "x" in the center of
// the edge line. Clicking on an edge and sepcifically on that X
// deletes the edge.
// code inspired / copied from
// https://reactflow.dev/docs/examples/edges/edge-with-button/
// possible with React Flow 10, only afaik
//
export const ButtonEdge: React.FC<EdgeProps<any>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  markerEnd,
}) => {
  const [path, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={path}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        style={{
          width: '80px',
          height: '80px',
          background: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <Box
          sx={{
            padding: '25px',
            height: '30px',
            width: '30px',
            ':hover': {
              opacity: 1,
            },
            opacity: 0,
          }}
        >
          <Box
            sx={{
              width: '24px',
              height: '24px',
              background: evoachDefaultTheme.palette.secondary.main,
              border: '3px solid #fff',
              color: '#FFFFFF',
              cursor: 'pointer',
              borderRadius: '50%',
              fontSize: '15px',
              lineHeight: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Close
              fontSize="inherit"
              sx={{ stroke: 'white', strokeWidth: 1.2 }}
            />
          </Box>
        </Box>
      </foreignObject>
    </>
  );
};
