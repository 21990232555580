import React, { useState } from 'react';
//import { useIntl } from 'react-intl';
import { useIntl } from 'react-intl';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
} from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';

/** props for this button + dialog component */
export interface ProgramModuleByJsonDialogProps {
  open: boolean;
  onCloseHandler: Function;
}

/** program by JSON component */
export const ProgramModuleByJsonDialog: React.FC<
  ProgramModuleByJsonDialogProps
> = ({ open, onCloseHandler }) => {
  const [jsonObject, setJsonObject] = useState<string>('');

  const intl = useIntl();

  const onJsonChange = (event: any) => {
    const programJson = event.target.value;
    setJsonObject(programJson ?? '');
  };

  const createprogramModuleByJson = () => {
    onCloseHandler(jsonObject);
    setJsonObject('');
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.programmodulebyjson.title',
          defaultMessage: 'Programmmodul über JSON erstellen',
        })}
      </DialogTitle>
      <DialogContent>
        <TextField
          label={intl.formatMessage({
            id: 'builder.programmodulebyjson.textfieldlabel',
            defaultMessage: 'Das JSON Objekt hierher kopieren ...',
          })}
          value={jsonObject}
          onChange={(evt) => onJsonChange(evt)}
          multiline
          minRows={20}
          maxRows={20}
          fullWidth
          key="TextFieldDialog"
          sx={{ marginTop: '10px' }}
        />
      </DialogContent>
      <DialogActions>
        <EvoachButton
          onClick={() => {
            setJsonObject('');
            onCloseHandler();
          }}
        >
          {intl.formatMessage({
            id: 'builder.programmodulebyjson.cancel',
            defaultMessage: 'Abbrechen',
          })}
        </EvoachButton>

        <EvoachButton
          onClick={() => {
            createprogramModuleByJson();
          }}
        >
          {intl.formatMessage({
            id: 'builder.programmodulebyjson.create',
            defaultMessage: 'Programmmodul über JSON erstellen',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
