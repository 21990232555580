import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { useFetchAssetUrl } from '../../api/asset';
import { translateCreatorWithFallback } from '../../intl/translateWithFallback';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  AudioDisplay react-flow node for module builder graph canvas
*/

export const ImageDisplayNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  // TODO - replace this logic with getResolvedUrl
  // TODO s. implementation in ImageSelector.node

  const s3url = useFetchAssetUrl(
    translateCreatorWithFallback(intl, payload.assetid)
  ).url;

  let imgsrc = payload.src;

  if (!imgsrc.toLowerCase().startsWith('http')) {
    imgsrc =
      s3url !== '' ? s3url : translateCreatorWithFallback(intl, payload.src);
  }

  imgsrc =
    imgsrc && imgsrc.trim() !== ''
      ? imgsrc
      : 'https://static.wixstatic.com/media/088cbd_83283001fd964101ab11898a3e3a2871~mv2.jpg';

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: 400 }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />

        {imgsrc && imgsrc.trim() !== '' ? (
          <img
            src={imgsrc}
            alt={translateKey(intl, payload.altText)}
            style={{
              marginTop: 10,
              maxHeight: 200,
              width: 400,
              overflow: 'hidden',
              objectFit: 'contain',
            }}
          />
        ) : (
          <Typography>
            {intl.formatMessage({
              id: 'builder.nodes.imagedisplay.noasset',
              defaultMessage:
                'Du hast noch kein Bild festgelegt. Du kannst ein Bild zu evoach hochladen oder ein externes Bild verlinken.',
            })}
          </Typography>
        )}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="afterimagehandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
