import { ReadWriteType, StateEntryProps } from '.';

/**
 *
 * ! important
 *
 * this is a fake object that is handled with a template
 * for the original composite => MultipleInput => CoachMessage + MultipleInput in UI-components
 * for the single multiple input component => MultipleInputSingle => MultipleInput in UI-components
 */

export const multipleInputDecomposedStateEntry: StateEntryProps = {
  type: 'renderMultipleInputTemplate',
  temporary: true,
  payload: {},
  saveResultType: ReadWriteType.stringArray,
  getValueType: ReadWriteType.stringArray,
  displayName: 'Multiple Input (v2)',
  nodeType: 'multipleInputDecomposedStateEntry',
  handledByTemplate: true,
};
