import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { CircularProgress, Typography } from '@mui/material';

import { initialProgramTemplate } from '../../entities';
import { AppRoutes, ProgramRouteTypeEnum } from '../../routing/routes';
import { useCreateEmptyProgram } from '../../api/program/useCreateEmptyProgram';

export const CreateNewProgram: React.FC = () => {
  // check whether program is a subppriogram ==> indicated via query param in route URL
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const issubprogram = (query.get('issubprogram') ?? '') === 'true';
  const parentProgramId = query.get('parentProgramId') ?? '';
  const programtype = query.get('programType') as any;

  const { isLoading, isError, error, programId } = useCreateEmptyProgram(
    initialProgramTemplate,
    issubprogram,
    parentProgramId,
    programtype
  );

  const backUrl =
    issubprogram && parentProgramId && parentProgramId.trim() !== ''
      ? `?program=${parentProgramId}`
      : '';

  const intl = useIntl();

  console.log('create new program ' + programId);

  return (
    <>
      {isError && (
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'builder.programs.createprogram.error',
            defaultMessage: 'Es ist ein Fehler aufgetreten:',
          })}
          {error?.message}
        </Typography>
      )}
      {isLoading && (
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'builder.programs.createprogram.loading',
            defaultMessage: 'Neues Programm wird geladen.',
          })}
          <CircularProgress />
        </Typography>
      )}
      {!isLoading && !isError && (
        <Navigate
          to={`${AppRoutes.PROGRAMEDITOR}/${programId}/${ProgramRouteTypeEnum.PROGRAM}${backUrl}`}
        />
      )}
    </>
  );
};
