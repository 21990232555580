import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import {
  CardImageEnum,
  ValueCardList,
  ValueCardProps,
} from '@evoach/ui-components';

import { mapImageToPublicAsset } from '../reception/assets/AssetHelper';
import { useEnvironment } from '../../environment/useEnvironment';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  ValueCardListNode react-flow node for module builder graph canvas
  PROD-1371
*/

export const ValueCardListNode: React.FC<NodeProps<any>> = (
  nodeProps: NodeProps<any>
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const { playerBasePath } = useEnvironment();

  const payload = nodeProps.data.state.entry[0].payload;
  const cmStyle: Record<string, string> = { maxWidth: '400px' };

  const dummycards: ValueCardProps[] = [
    {
      type: 'hotornot',
      value: 'Value 1',
      imageSrc: mapImageToPublicAsset(CardImageEnum.a_day_off, playerBasePath),
    },
    {
      type: 'hotornot',
      value: 'Value 2',
      imageSrc: mapImageToPublicAsset(CardImageEnum.acceptance, playerBasePath),
    },
  ];

  console.log(dummycards);
  return (
    <>
      <Handle
        type="target"
        id="valuecardlistinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={cmStyle}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography variant="body2" sx={{ marginTop: '10px' }}>
          {intl.formatMessage({
            id: 'builder.nodes.valueCardListNode.explain',
            defaultMessage:
              'Diese Komponente zeigt eine Liste von Einträgen an, die als Ausgabe z.B. aus einem Hot-Or-Not-Selector kommen. Sie liest die Liste aus dieser Variable: ',
          })}{' '}
          <b>{payload.getValueFrom}</b>.<br />
          {intl.formatMessage({
            id: 'builder.nodes.valueCardListNode.demo',
            defaultMessage: 'Das sieht dann etwa so aus:',
          })}
          <div style={{ marginTop: '10px', marginLeft: '10px' }}>
            <ValueCardList cardsPerRow={2} cards={dummycards} />
          </div>
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="valuecardlistouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
