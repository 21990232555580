import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';

/**
 * get an analysis for a specific component
 * @param {string} moduleid
 * @param {string} stateky
 * @returns {any} statistics
 */
export const useQueryStatsForComponent = (
  moduleid: string,
  statekey: string
) => {
  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `analytics-component-${moduleid}-${statekey}`,
    async () => {
      if (!moduleid || moduleid.trim() === '') return undefined;
      if (!statekey || statekey.trim() === '') return undefined;

      const url = `/analytics/${moduleid}/component/${statekey}`;

      const fetchAnalyticsCall = authorizedGet(url);
      const response = await fetchAnalyticsCall();
      const data = await response.json();
      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    statistics: data,
    refetch,
  };
};
