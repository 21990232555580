import { Handle, NodeProps, Position } from 'reactflow';
import {
  EditableMultiselect,
  EditableMultiselectProps,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { useGlobalStyles, translateKey } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  MultipleInput react-flow node for module builder graph canvas
*/

export const NeedsInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  let texts: string[] = payload.placeholderTexts;
  if (!texts) {
    texts = ['TODO transl keys'];
  }

  const elements = payload.elements;

  const props: EditableMultiselectProps = {
    saveResultTo: payload.saveResultTo,
    placeholderTexts: texts.map((label: string) => {
      return translateKey(intl, label);
    }),
    buttonText: translateKey(intl, payload.buttonText),
    presetValues: [''],
    elements: elements.map((element: any) => {
      return {
        starred: element.starred,
        type: element.type,
        value: translateKey(intl, element.value),
      };
    }),
    hideOwnInput: payload.hideOwnInput,
    selectAll: payload.selectAll,
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '620px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <EditableMultiselect {...props} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
