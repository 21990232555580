import { EvoachButton } from '@evoach/ui-components';
import { defineMessages } from '@formatjs/intl';
import {
  Box,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../../account';
import { useFetchAssetUrl } from '../../../api/asset';
import { Asset } from '../../../entities/Asset';
import {
  AudioFileExtensions,
  DocumentFileExtensions,
  FileTypesAllowedForAiIntegration,
  getFileExtension,
  ImageFileExtensions,
  VideoFileExtensions,
} from '../../PropertiesSidebar/assetHelper';

import { AssetDefaultButton } from './AssetDefaultButton';
import { AssetDeleteButton } from './AssetDeleteButton';
import { AssetUploadDialog } from './AssetUploadDialog';
import { AssetAiPrepareCheckbox } from './AssetAiPrepareCheckbox';

const assetPreviewMessages = defineMessages({
  none: {
    id: 'builder.reception.assets.assetpreview.loading',
    defaultMessage: 'Bitte klicke auf eines der Elemente in der Liste links.',
  },
});

interface AssetPreviewProps {
  asset: Asset | undefined;
  onUpdate?: Function;
}

export const getComponentType = (extension: string) => {
  if (Object.values<string>(ImageFileExtensions).includes(extension))
    return 'img';
  if (Object.values<string>(AudioFileExtensions).includes(extension))
    return 'audio';
  if (Object.values<string>(VideoFileExtensions).includes(extension))
    return 'video';
  if (Object.values<string>(DocumentFileExtensions).includes(extension))
    return 'iframe';
  return 'iframe';
};

export const AssetPreview: React.FC<AssetPreviewProps> = ({
  asset,
  onUpdate,
}) => {
  let s3url = useFetchAssetUrl(asset?.assetId).url;
  s3url =
    (!s3url || s3url === '') && asset?.metadata.externalUrl
      ? asset?.metadata.externalUrl
      : s3url;
  const intl = useIntl();
  const { hasRole } = useContext(AccountContext);
  const [isAssetUploadOpen, setIsAssetUploadOpen] = useState<boolean>(false);
  const [downloadFile, setDownloadFile] = useState<boolean>(false);

  if (!asset || Object.keys(asset).length === 0) {
    return (
      <Typography variant="body2" component="span">
        <FormattedMessage {...assetPreviewMessages.none} />
      </Typography>
    );
  }

  const extension = getFileExtension(asset.key);

  if (s3url && s3url !== '') {
    return (
      <Box>
        {!Object.values<string>(VideoFileExtensions).includes(extension) ? (
          Object.values<string>(DocumentFileExtensions).includes(extension) ? (
            <>
              <Typography>
                {intl.formatMessage({
                  id: 'builder.reception.assetpreview.table.documenthint2',
                  defaultMessage:
                    'Für Dokumente können wir leider keine Vorschau anzeigen. Du kannst das Dokument aber hier herunterladen.',
                })}
              </Typography>
              {!downloadFile ? (
                <EvoachButton
                  onClick={() => setDownloadFile(true)}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  {intl.formatMessage({
                    id: 'builder.reception.assets.assetpreview.downloadbutton',
                    defaultMessage: 'Download',
                  })}
                </EvoachButton>
              ) : (
                <Typography style={{ marginTop: '10px' }} fontWeight={500}>
                  <CardMedia
                    sx={{ width: '0px', height: '0px', border: '0px' }}
                    component={getComponentType(extension)}
                    controls={false}
                    src={s3url}
                  />
                  {intl.formatMessage({
                    id: 'builder.reception.assets.assetpreview.downloadstarted',
                    defaultMessage: 'Download wurde gestartet ...',
                  })}
                </Typography>
              )}
            </>
          ) : (
            <CardMedia
              component={getComponentType(extension)}
              controls={Object.values<string>(AudioFileExtensions).includes(
                extension
              )}
              src={s3url}
            />
          )
        ) : (
          <CardMedia
            component="video"
            src={s3url}
            sx={{ maxWidth: '500px', maxHeight: '400px' }}
            controls
          />
        )}

        <Typography
          variant="subtitle1"
          fontWeight={500}
          sx={{ marginTop: '20px' }}
        >
          {intl.formatMessage(
            {
              id: 'builder.reception.assetpreview.table.filename',
              defaultMessage: 'Dateiname: {filename}',
            },
            { filename: asset.key }
          )}
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '20px' }} component="div">
          <TableContainer component={Box}>
            <Table size="small" aria-label="simple table">
              <TableBody>
                <TableRow key="assetcreatedrow">
                  <TableCell component="td" scope="row">
                    {intl.formatMessage({
                      id: 'builder.reception.assetpreview.table.created',
                      defaultMessage: 'Erstellt',
                    })}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {asset.tscreated + ''}
                  </TableCell>
                </TableRow>
                {Object.values<string>(ImageFileExtensions).includes(
                  extension
                ) &&
                  (asset.metadata.externalUrl === undefined ||
                    asset.metadata.externalUrl === '') && (
                    <>
                      <TableRow key="assetsize">
                        <TableCell component="td" scope="row">
                          {intl.formatMessage({
                            id: 'builder.reception.assetpreview.table.size',
                            defaultMessage: 'Größe (in Bytes)',
                          })}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {asset.metadata.size}
                        </TableCell>
                      </TableRow>
                      <TableRow key="asetwidth">
                        <TableCell component="td" scope="row">
                          {intl.formatMessage({
                            id: 'builder.reception.assetpreview.table.width',
                            defaultMessage: 'Breite',
                          })}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {asset.metadata.width}
                        </TableCell>
                      </TableRow>
                      <TableRow key="assetheight">
                        <TableCell component="td" scope="row">
                          {intl.formatMessage({
                            id: 'builder.reception.assetpreview.table.height',
                            defaultMessage: 'Höhe',
                          })}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {asset.metadata.height}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                <TableRow key="assetisdefault">
                  <TableCell component="td" scope="row">
                    {intl.formatMessage({
                      id: 'builder.reception.assetpreview.table.isdefault',
                      defaultMessage: 'Öffentliche Datei',
                    })}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {asset.isDefaultAsset.toString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
        <Box sx={{ marginTop: '20px' }}>
          {(!asset.isDefaultAsset ||
            (asset.isDefaultAsset &&
              (asset.metadata.externalUrl === undefined ||
                asset.metadata.externalUrl === ''))) && (
            <EvoachButton
              onClick={() => setIsAssetUploadOpen(true)}
              variant="contained"
              color="secondary"
              size="small"
            >
              {intl.formatMessage({
                id: 'builder.reception.assets.assetpreview.updatebutton',
                defaultMessage: 'Datei aktualisieren',
              })}
            </EvoachButton>
          )}
          <AssetUploadDialog
            isOpen={isAssetUploadOpen}
            onClose={() => setIsAssetUploadOpen(false)}
            onUpload={() => {
              if (onUpdate) onUpdate();
            }}
            overwriteDefault
          />
          {(!asset.isDefaultAsset || hasRole(RoleEnum.EVOACHADMIN)) && (
            <span style={{ marginLeft: '20px' }}>
              <AssetDeleteButton
                assetid={asset.assetId}
                onDelete={() => {
                  if (onUpdate) onUpdate();
                }}
              />
            </span>
          )}
          {hasRole(RoleEnum.EVOACHADMIN) && (
            <>
              <span style={{ marginLeft: '20px' }}>
                <AssetDefaultButton
                  assetid={asset.assetId}
                  isDefault={asset.isDefaultAsset}
                  onUpdate={() => {
                    if (onUpdate) onUpdate();
                  }}
                />
              </span>
            </>
          )}
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          {hasRole(RoleEnum.EVOACHADMIN) &&
            FileTypesAllowedForAiIntegration.includes(extension) && (
              <span style={{ marginLeft: '20px' }}>
                <AssetAiPrepareCheckbox
                  assetid={asset.assetId}
                  isActivated={asset.useforai}
                />
              </span>
            )}
        </Box>
      </Box>
    );
  } else {
    return <div>{asset.key}</div>;
  }
};
