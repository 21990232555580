import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.nextButton.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

export const nextButtonStateEntry: StateEntryProps = {
  type: 'renderNextButton',
  temporary: true,
  payload: {
    buttonText: 'builder.nodes.nextButton.state.buttonText',
  },
  displayName: 'Next Button',
  nodeType: 'nextButtonStateEntry',
  handleOutCount: 1,
};
