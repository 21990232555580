import { Handle, NodeProps, Position } from 'reactflow';
import { EmotionInputComposite } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  MultipleInput react-flow node for module builder graph canvas
*/

export const EmotionsInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  let texts: string[] = payload.placeholderTexts;
  if (!texts) {
    texts = ['TODO transl keys'];
  }

  const elements = payload.elements
    .filter((element: any) => element)
    .map((element: any) => {
      return {
        type: element.type,
        value: translateKey(intl, element.value),
        icon: element.icon,
      };
    });

  //console.log(elements);

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '550px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <EmotionInputComposite
          saveResultTo={payload.saveResultTo}
          placeholderTexts={texts.map((label: string) => {
            return translateKey(intl, label);
          })}
          buttonText={translateKey(intl, payload.buttonText)}
          presetValues={['']}
          hideOwnInput={payload.hideOwnInput}
          selectAll={payload.selectAll}
          elements={elements}
          message={
            payload.message
              ? translateKey(intl, payload.message)
              : intl.formatMessage({
                  id: 'builder.nodes.EmotionsInput.pleaseAddAgain.message',
                  defaultMessage:
                    'Bitte entferne den Knoten und fügen ihn erneut hinzu',
                })
          }
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
