import { useState, useEffect, useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { authorizedPatch, authorizedPost } from '../api';
import { EditSessionTypeEnum } from '../entities/EditSession';

import {
  AppRoutes,
  ModuleRouteLangParamName,
  ModuleRouteParamName,
  ProgramRouteParamName,
  ProgramRouteTypeParamName,
} from './routes';

/**
 * checks whether the current React route is starting an editor session
 * or ends an editor session. It sets the isInSession flag to true/false
 * accordingly.
 *
 * For React Router @see https://reactrouter.com/en/main
 * For matchPath @see https://reactrouter.com/en/main/utils/match-path
 *
 * @param {boolean} writeToBackend - if set to true, it triggers an start/end
 * session call to the backend. Default is false.
 *
 * @returns {object} {isInSession: boolean; isModuleSession: boolean; isProgramSession: boolean;}
 */
export const useSession = (
  writeToBackend: boolean = false
): {
  isInSession: boolean;
  isModuleSession: boolean;
  isProgramSession: boolean;
} => {
  const [isInSession, setIsInSession] = useState<boolean>(false);
  const [currentEditSessionId, setCurrentEditSessionId] = useState<string>('');

  const location = useLocation();

  const isModuleSession = location.pathname.startsWith(AppRoutes.BUILDER);
  const isProgramSession = location.pathname.startsWith(
    AppRoutes.PROGRAMEDITOR
  );

  //
  // log session start and end to API
  //
  const logToApi = useCallback(
    (type: 'startsession' | 'stopsession') => {
      // API call not requested, then return and do nothing
      if (!writeToBackend) return;

      let res;

      if (type === 'startsession') {
        // start session
        // parse parameters for module editor
        if (isModuleSession) {
          res = matchPath(
            AppRoutes.BUILDER +
              `/:${ModuleRouteParamName}/:${ModuleRouteLangParamName}`,
            location.pathname
          );
          // res now contains params object with these parameters:
          // moduleId
          // moduleLanguage
        }
        // parse parameters for program editor
        if (isProgramSession) {
          res = matchPath(
            AppRoutes.PROGRAMEDITOR +
              `/:${ProgramRouteParamName}/:${ProgramRouteTypeParamName}`,
            location.pathname
          );

          // res now contains params object with these parameters:
          // programId
          // programRouteType
        }

        let apiUrl = '/editsession/';
        // build URL
        if (isModuleSession) {
          // module is type 0
          apiUrl += `${res?.params.moduleId}?type=${EditSessionTypeEnum.MODULE}`;
        } else {
          if (isProgramSession) {
            // program session is type 1
            apiUrl += `${res?.params.programId}?type=`;
            if (res?.params.programRouteType === 'program') {
              apiUrl += EditSessionTypeEnum.PROGRAM;
            } else {
              // program instance edit session is type 2
              apiUrl += EditSessionTypeEnum.PROGRAMINSTANCE;
            }
          }
        }
        // start session and wait for editsessionid
        // remember it to use it to stop the session
        const startSessionCall = authorizedPost(apiUrl);
        startSessionCall()
          .then((response: Response) => {
            response.json().then((res: any) => {
              setCurrentEditSessionId(res.editsessionid ?? '');
            });
          })
          .catch(() => {
            // in case of an error, reset sessionid anyway
            setCurrentEditSessionId('');
          });
      } else {
        // end session

        // stop the session with the previously remembered session id
        const endSessionCall = authorizedPatch(
          `/editsession/${currentEditSessionId}`
        );
        endSessionCall().finally(() => {
          setCurrentEditSessionId('');
        });
      }
    },
    [
      currentEditSessionId,
      isModuleSession,
      isProgramSession,
      location.pathname,
      writeToBackend,
    ]
  );

  //
  // toggle isInSession if rpoute changes and send corresponding
  // API calls if parameter is set
  //
  useEffect(() => {
    if (isInSession && !(isModuleSession || isProgramSession)) {
      logToApi('stopsession');
    }

    if (!isInSession && (isModuleSession || isProgramSession)) {
      logToApi('startsession');
    }

    setIsInSession(isModuleSession || isProgramSession);
  }, [
    isInSession,
    isModuleSession,
    isProgramSession,
    location.pathname,
    logToApi,
  ]);

  // return object
  return { isInSession, isModuleSession, isProgramSession };
};
