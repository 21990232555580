import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import React, { useContext } from 'react';
import { Node } from 'reactflow';

import { AccountContext, RoleEnum } from '../../../account';
import { Description } from '../../Description';
import { ReadWriteType, StateEntryProps } from '../../nodes';

import { GetValueFromSelect } from './GetValueFromSelect';
import { PropertyHeaderLine } from './PropertyHeadLine';

interface GetPersonaFromProps {
  entry: StateEntryProps;
  updatePersonaFrom: Function;
  nodes: Node<any>[];
}

export const GetPersonaFrom: React.FC<GetPersonaFromProps> = ({
  entry,
  updatePersonaFrom,
  nodes,
}) => {
  const intl = useIntl();

  const { hasRole } = useContext(AccountContext);

  //
  // persona payload only available for evoach admin and for ( TODO ) selected users
  //
  if (!hasRole(RoleEnum.EVOACHADMIN)) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {Object.keys(entry.payload)
        .filter((payloadKey: string) => payloadKey === 'getPersonaFrom')
        .map((payloadKey: string) => {
          // prototype: allow strings, only
          const getValueType = ReadWriteType.string;
          return (
            <div key={`ElementIdAndKey-${payloadKey}`}>
              <PropertyHeaderLine
                header={intl.formatMessage({
                  id: 'builder.moduleeditor.propertiesSideBar.getPersonaFrom',
                  defaultMessage: 'Persona für KI Prompt',
                })}
              />

              <Description
                nodetype={entry.nodeType}
                propname="getPersonaFrom"
              />

              <GetValueFromSelect
                updateValueFrom={updatePersonaFrom}
                currentValue={entry.payload[payloadKey] as string}
                multiselect={false}
                getValueType={getValueType}
                nodes={nodes}
              />
            </div>
          );
        })}
    </Stack>
  );
};
