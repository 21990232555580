import { useContext } from 'react';
import { useQuery } from 'react-query';

import { TranslationContext } from '../../components/stateMachineTranslationContext';
import { ModuleTranslation } from '../../entities/ModuleTranslation';
import { authorizedGet } from '../authorizedApi';

/**
 * get a module translation for a specific language
 * @param {string} moduleId to be fetched
 * @param {string} moduleLanguage to be fetched, if not set, the current state machine translation from TranslationContext is used
 * @returns isLoading, isError, error, module, refetch
 */
export const useFetchModuleTranslation = (
  moduleId: string,
  moduleLanguage?: string
) => {
  const { stateMachineLocale } = useContext(TranslationContext);

  const language = moduleLanguage ?? stateMachineLocale;

  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `moduletranslation-${moduleId}-${language}`,
    async () => {
      if (!moduleId || moduleId.trim() === '') return undefined;

      const url = `/module/${moduleId}/translation?language=${moduleLanguage}`;

      const fetchTranslationCall = authorizedGet(url);
      const response = await fetchTranslationCall();

      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    translation: data as ModuleTranslation,
    refetch,
  };
};
