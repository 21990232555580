import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../../account';
import { getTabProps, TabPanelProps } from '../../tabHelper';

import { AdminSingleModule } from './AdminSingleModule';
import { AdminStatsSubTab } from './AdminStatsSubTab';
import { AdminUserList } from './AdminUserList';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span" sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const AdminAnalyticsTab: React.FC = () => {
  const { hasRole } = useContext(AccountContext);
  const [tabvalue, setTabValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const intl = useIntl();

  // not released ... for evoach only
  if (!hasRole(RoleEnum.EVOACHADMIN)) {
    return (
      <p>
        {intl.formatMessage({
          id: 'builder.reception.adminanalyticstab.notpublicmessage',
          defaultMessage:
            'Diese Funktion ist nur für evoach Mitarbeiter verfügbar.',
        })}
      </p>
    );
  }

  return (
    <Box component="span" sx={{ width: '100%' }}>
      <Box component="span" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabvalue} onChange={handleChange} aria-label="Reception">
          <Tab label="User Management" {...getTabProps(0, 'adminanalytics')} />
          <Tab label="Statistics" {...getTabProps(1, 'adminanalytics')} />
          <Tab
            label="Single Module Check"
            {...getTabProps(2, 'singlemodulecheck')}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabvalue} index={0}>
        <AdminUserList />
      </TabPanel>
      <TabPanel value={tabvalue} index={1}>
        <AdminStatsSubTab />
      </TabPanel>
      <TabPanel value={tabvalue} index={2}>
        <AdminSingleModule />
      </TabPanel>
    </Box>
  );
};
