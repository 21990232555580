import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

/**
 * conform deletion dialog
 * Is used for modules and templates, and also for Coaching Programs.
 * Coaching Programs use the message param, but not templates and module lists.
 */
export interface ConfirmDeletionDialogProps {
  title: string;
  open: boolean;
  onConfirm: Function;
  onClose: Function;
  message?: string;
}
export const ConfirmDeletionDialog: React.FC<ConfirmDeletionDialogProps> = ({
  title,
  open,
  onConfirm,
  onClose,
  message,
}) => {
  const intl = useIntl();

  if (!open) return <></>;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message ??
            intl.formatMessage({
              id: 'builder.reception.modulelist.confirmdeletion.hinttext',
              defaultMessage:
                'Wenn du das Löschen bestätigst, wird das Modul aus deiner Liste entfernt. Diese Aktion kann nicht rückgängig gemacht werden.',
            })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} autoFocus>
          {intl.formatMessage({
            id: 'builder.reception.modulelist.confirmdeletion.cancelbutton',
            defaultMessage: 'Abbrechen',
          })}
        </Button>
        <Button onClick={() => onConfirm()}>
          {intl.formatMessage({
            id: 'builder.reception.modulelist.confirmdeletion.deletebutton',
            defaultMessage: 'Löschen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
