import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { ProductTourContext } from '../../producttour/ProductTourContext';

export const ProductTourMenuLink: React.FC = () => {
  const intl = useIntl();
  const { openTour } = useContext(ProductTourContext);
  const location = useLocation();

  const [isReceptionPage, setIsReceptionPage] = useState(true);

  useEffect(() => {
    setIsReceptionPage(location.pathname.startsWith('/reception'));
  }, [location]);

  const handleClick = () => {
    if (isReceptionPage) {
      openTour('reception');
    }
  };

  return (
    <Box
      sx={{
        display: isReceptionPage ? 'flex' : 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        typography: 'body2',
      }}
      component="span"
      key="producttourmenulink"
      onClick={() => {
        handleClick();
      }}
    >
      {intl.formatMessage({
        id: 'builder.headermenu.menuitem.producttourmenulink',
        defaultMessage: 'Produkttour anzeigen',
      })}
    </Box>
  );
};
