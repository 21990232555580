import { Handle, NodeProps, Position } from 'reactflow';
import {
  EditableMultiselect,
  EditableMultiselectProps,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { useGlobalStyles, translateKey } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  MultipleInput react-flow node for module builder graph canvas
*/

export const EditableMultiselectNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  let texts: string[] = payload.placeholderTexts;
  if (!texts) {
    texts = ['TODO transl keys'];
  }

  const elements = payload.elements;

  const props: EditableMultiselectProps = {
    saveResultTo: payload.saveResultTo,
    placeholderTexts: texts.map((label: string) => {
      return translateKey(intl, label);
    }),
    buttonText: translateKey(intl, payload.buttonText),
    presetValues: [''],
    elements: elements.map((element: any) => {
      if (typeof element === 'string') {
        return {
          starred: false,
          type: 'editableMultiselect',
          value: translateKey(intl, element),
        };
      } else {
        return {
          starred: element.starred,
          type: element.type,
          value: translateKey(intl, element.value),
        };
      }
    }),
    hideOwnInput: payload.hideOwnInput,
    selectAll: payload.selectAll,
    toolTipWarningMessage: translateKey(intl, payload.toolTipWarningMessage),
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '600px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography sx={{ marginBottom: '10px' }}>
          {intl.formatMessage({
            id: 'builder.nodes.elements.editableMultiselect.listhint',
            defaultMessage:
              'Die vordefinierten Elemente, die hier angezeigt werden sollen, werden entweder aus der Variable in "getLabelsFrom" erstellt oder können in den Eigenschaften manuell eingegeben werden.',
          })}
        </Typography>

        <EditableMultiselect {...props} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
