import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

export interface DurationDialogProps {
  open: boolean;
  onFeedback: Function;
}

export const DurationDialog: React.FC<DurationDialogProps> = ({
  open,
  onFeedback,
}) => {
  const intl = useIntl();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({
          id: 'builder.moduleeditor.durationdialog.title',
          defaultMessage: 'Dauer des Moduls schätzen',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({
            id: 'builder.moduleeditor.durationdialog.description',
            defaultMessage:
              'Die Dauer des Modules wird anhand des Inhalts des Chatbot-Editors geschätzt und in der Modulbeschreibung gespeichert. Etwaige Angaben werden überschrieben. Im Anschluss wird die veränderte Einstellung angezeigt.',
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onFeedback('ok')}>
          {intl.formatMessage({
            id: 'builder.moduleeditor.durationdialog.confirm',
            defaultMessage: 'Ok',
          })}
        </Button>
        <Button onClick={() => onFeedback('cancel')} autoFocus>
          {intl.formatMessage({
            id: 'builder.moduleeditor.durationdialog.cancel',
            defaultMessage: 'Abbrechen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
