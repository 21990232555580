import { defineMessages } from '@formatjs/intl';

export const templateListTexts = defineMessages({
  title: {
    id: 'builder.reception.templates.listheader.title',
    defaultMessage: 'Name',
  },
  description: {
    id: 'builder.reception.templates.listheader.description',
    defaultMessage: 'Beschreibung',
  },
  category: {
    id: 'builder.reception.templates.listheader.category',
    defaultMessage: 'Kategorie',
  },
  author: {
    id: 'builder.reception.templates.listheader.author',
    defaultMessage: 'Autor',
  },
  duration: {
    id: 'builder.reception.templates.listheader.duration',
    defaultMessage: 'Dauer',
  },
  languages: {
    id: 'builder.reception.templates.listheader.languages',
    defaultMessage: 'Sprachen',
  },
  type: {
    id: 'builder.reception.templates.listheader.type',
    defaultMessage: 'Typ',
  },
  actions: {
    id: 'builder.reception.templates.listheader.actions',
    defaultMessage: 'Aktionen',
  },
  emptyDefaultMessage: {
    id: 'builder.reception.templates.emptydefaultmessage',
    defaultMessage: 'Keine Vorlagen mit den ausgewählten Filtern verfügbar',
  },
  publicTemplate: {
    id: 'builder.reception.templates.permission.public',
    defaultMessage: 'Evoach Vorlagen',
  },
  sharedTemplate: {
    id: 'builder.reception.templates.permission.shared',
    defaultMessage: 'Mit mir geteilt',
  },
  personalTemplate: {
    id: 'builder.reception.templates.permission.personal',
    defaultMessage: 'Meine Vorlagen',
  },
  language: {
    id: 'builder.reception.templates.filter.language',
    defaultMessage: 'Sprache',
  },
  editButton: {
    id: 'builder.reception.templates.edit',
    defaultMessage: 'Editieren',
  },
  duplicate: {
    id: 'builder.reception.templates.duplicate',
    defaultMessage: 'Duplizieren',
  },
  preview: {
    id: 'builder.reception.templates.menu.preview',
    defaultMessage: 'Vorschau',
  },
  share: {
    id: 'builder.reception.templates.menu.share',
    defaultMessage: 'Teilen',
  },
  removeFromList: {
    id: 'builder.reception.templates.menu.remove',
    defaultMessage: 'Aus Liste entfernen',
  },
  delete: {
    id: 'builder.reception.templates.menu.delete',
    defaultMessage: 'Löschen',
  },
  translationManagement: {
    id: 'builder.reception.templates.menu.translationmanagement',
    defaultMessage: 'Übersetzungsmanagement',
  },
  created: {
    id: 'builder.reception.templates.menu.created',
    defaultMessage: 'Erstellt am ',
  },
  copyJsonToClipboard: {
    id: 'builder.reception.templates.menu.copytocliboard',
    defaultMessage: 'Kopiere als JSON',
  },
  lastModified: {
    id: 'builder.reception.templates.menu.lastmodified',
    defaultMessage: 'Zuletzt bearbeitet am ',
  },
  addNew: {
    id: 'builder.reception.templates.addnew.title',
    defaultMessage: 'Neue Vorlage',
  },
  addNewDescription: {
    id: 'builder.reception.templates.addnew.description',
    defaultMessage:
      'Diese Vorlage wurde mit dem evoach Chatbot Creator gebaut.',
  },
  addNewDuration: {
    id: 'builder.reception.templates.addnew.duration',
    defaultMessage: 'Wenige Minuten',
  },
});
