import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  phaseNode: {
    id: 'builder.nodes.phaseNode.state.phasetext',
    defaultMessage: 'Neue Phase',
  },
  phaseStartNode: {
    id: 'builder.nodes.phaseNode.state.phasestarttext',
    defaultMessage: 'Start',
  },
  phaseEndNode: {
    id: 'builder.nodes.phaseNode.state.phaseendtext',
    defaultMessage: 'Ende',
  },
  descProp: {
    id: 'setProgressPercentEntry.progressPercent',
    defaultMessage:
      'Hier kannst du eintragen, zu wieviel Prozent des Chats an dieser Komponente bereits vorüber sind. Die Information steuert den Fortschrittsbalken, der links vom Chat angezeigt wird.',
  },
});

// nodeType must be name of exported variable

export const phaseStateEntry: StateEntryProps = {
  type: 'setProgressPhase',
  temporary: true,
  payload: {
    phasetext: 'builder.nodes.phaseNode.state.phasetext',
  },
  displayName: 'Phase',
  nodeType: 'phaseStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.light,
  addPercentage: false,
};

export const phaseStartStateEntry: StateEntryProps = {
  type: 'setProgressPhase',
  temporary: true,
  payload: {
    phasetext: 'builder.nodes.phaseNode.state.phasestarttext',
  },
  displayName: 'Start',
  nodeType: 'phaseStartStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.light,
  addPercentage: false,
};

export const phaseEndStateEntry: StateEntryProps = {
  type: 'setProgressPhase',
  temporary: true,
  payload: {
    phasetext: 'builder.nodes.phaseNode.state.phaseendtext',
  },
  displayName: 'End',
  nodeType: 'phaseEndStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.light,
  addPercentage: false,
  handleOutCount: 0,
};
