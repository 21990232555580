import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { cloneDeep } from 'lodash';
import { Edge, isEdge, isNode, Node } from 'reactflow';
import { useNavigate } from 'react-router-dom';
import { generateRandomString } from '@evoach/ui-components';

import { ModuleProps } from '../../entities/Module';
import { authorizedPost, authorizedPut } from '../../api/authorizedApi';
import { AppRoutes } from '../../routing/routes';

import { generateModule } from './moduleUtils';

export interface ExtractTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  module: ModuleProps;
  selectedNodes: Node<any>[];
}

export const ExtractTemplateDialog: React.FC<ExtractTemplateDialogProps> = ({
  open,
  onClose,
  module,
  selectedNodes,
}) => {
  const intl = useIntl();
  let navigate = useNavigate();

  const [newTemplateId, setNewTemplateId] = useState<string>('');

  // reset creation status if module chnages
  useEffect(() => {
    setNewTemplateId('');
  }, [module]);

  const onExtract = async () => {
    if (selectedNodes.length === 0) return;

    // 0. Make deep Clone of module
    const newTemplate = cloneDeep(module);

    // 1. remove unselected nodes
    const selectedNodeIds = selectedNodes.map((node: Node<any>) => node.id);
    newTemplate.buildergraph = newTemplate.buildergraph.filter(
      (elem: Node<any> | Edge<any>) => {
        if (isEdge(elem)) {
          return true;
        }
        if (isNode(elem)) {
          return selectedNodeIds.includes(elem.id);
        }
        return false;
      }
    );

    // 2. remove all egdes that are not fully connected
    newTemplate.buildergraph = newTemplate.buildergraph.filter(
      (elem: Node<any> | Edge<any>) => {
        if (isNode(elem)) {
          return true;
        }
        if (isEdge(elem)) {
          // return true = include edge
          // edge only included, when both source and target are included
          // in the selectedNode list
          return (
            selectedNodeIds.includes(elem.source) &&
            selectedNodeIds.includes(elem.target)
          );
        }
        return false;
      }
    );

    // 3. rename title etc, reset moduleid with dummydata

    // reset moduleid ==> will be reset during backend creation process
    newTemplate.moduleid = 'xbq';

    // make the extract to a template
    newTemplate.issubmodule = true;

    // modify uuid for description translation keys to render lists properly
    // after creation

    const newKey = generateRandomString(4);

    const resetMeta = (prop: string) => {
      const oldKey = newTemplate.metadata[prop] as string;
      newTemplate.translations[0].metadatatranslation[newKey + '.' + prop] =
        newTemplate.translations[0].metadatatranslation[oldKey];
      newTemplate.metadata[prop] = newKey + '.' + prop;
      delete newTemplate.translations[0].metadatatranslation[oldKey];
    };
    // rename title
    resetMeta('title');

    newTemplate.translations[0].metadatatranslation[
      newTemplate.metadata.title
    ] =
      intl.formatMessage({
        id: 'builder.extracttemplate.dialog.prefixtext',
        defaultMessage: 'Vorlage erstellt aus: ',
      }) +
      newTemplate.translations[0].metadatatranslation[
        newTemplate.metadata.title
      ];

    // reset description
    resetMeta('description');

    newTemplate.translations[0].metadatatranslation[
      newTemplate.metadata.description
    ] =
      newTemplate.translations[0].metadatatranslation[
        newTemplate.metadata.title
      ];

    // reset duration
    resetMeta('duration');

    newTemplate.translations[0].metadatatranslation[
      newTemplate.metadata.duration
    ] = '- -';

    // reset languages => only take the current language of the module
    // TODO also extract others (iin background?)
    newTemplate.metadata.defaultLanguage = newTemplate.translations[0].lang;
    newTemplate.metadata.moduleLanguages = [newTemplate.translations[0].lang];

    // 4. generate module (removes also unused translations)
    const newTemplateGenerated = generateModule(
      newTemplate.buildergraph.filter(isNode),
      newTemplate.buildergraph.filter(isEdge),
      newTemplate.translations[0].statemachinetranslation,
      newTemplate.translations[0].metadatatranslation,
      newTemplate
    );

    // 5. Save new machine requires two steps as new machines are creatd empty:
    //    a) create new module
    //    b) update the new module with the full JSON

    const createJsonModule = authorizedPost('/module', newTemplateGenerated);
    const createResponse = await createJsonModule();

    if (!createResponse.ok) {
      setNewTemplateId('error_create');
    } else {
      const data = await createResponse.json();
      const newTemplateModuleId = data.moduleid;

      // 2. update module ==> update also all other parameters and builder graph!
      const updateJsonModule = authorizedPut(
        `/module/${newTemplateModuleId}`,
        newTemplateGenerated
      );
      const updateResponse = await updateJsonModule();
      if (!updateResponse.ok) {
        setNewTemplateId('error_update');
      } else {
        setNewTemplateId(newTemplateModuleId);
      }
    }
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.extracttemplate.dialog.title',
          defaultMessage: 'Vorlage aus selektierten Elementen erstellen',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {selectedNodes.length === 0 && (
            <>
              {intl.formatMessage({
                id: 'builder.extracttemplate.dialog.nothingselected',
                defaultMessage:
                  'Du hast keine Elemente selektiert. Bitte selektiere zuerst Knoten in deinem Modul, damit du diese als Vorlage speichern kannst.',
              })}
            </>
          )}
          {selectedNodes.length > 0 && newTemplateId === '' && (
            <>
              {intl.formatMessage({
                id: 'builder.extracttemplate.dialog.description',
                defaultMessage:
                  'Wenn du auf "Vorlage erstellen" klickst, werden die von dir selektierten Elemente als neue Vorlage gespeichert. Dabei wird nur die aktuell angezeigte Übersetzung übernommen.',
              })}
            </>
          )}
          {newTemplateId === 'error_create' && (
            <>
              {intl.formatMessage({
                id: 'builder.extracttemplate.dialog.creationFailed',
                defaultMessage:
                  'Die Vorlage konnte nicht extrahiert werden ("Anlegen" ist fehlgeschlagen)',
              })}
            </>
          )}
          {newTemplateId === 'error_update' && (
            <>
              {intl.formatMessage({
                id: 'builder.extracttemplate.dialog.uploadFailed',
                defaultMessage:
                  'Das Modul konnte nicht gespeichert werden ("Aktualisierung" ist fehlgeschlagen)',
              })}
            </>
          )}
          {newTemplateId !== '' && !newTemplateId.startsWith('error_') && (
            <>
              {intl.formatMessage({
                id: 'builder.extracttemplate.dialog.jumptoeditor',
                defaultMessage:
                  'Die Vorlage wurde extrahiert und gespeichert. Wenn du sie gleich im Editor anschauen möchtest, klicke bitte auf den Link unten.',
              })}
              <br />
              <Button
                onClick={() => {
                  navigate(
                    `${AppRoutes.BUILDER}/${newTemplateId}/${module.translations[0].lang}`
                  );
                }}
              >
                {intl.formatMessage({
                  id: 'builder.extracttemplate.dialog.jumpbutton',
                  defaultMessage: 'Neu erstellte Vorlage editieren',
                })}
              </Button>
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {intl.formatMessage({
            id: 'builder.extracttemplate.dialog.button.close',
            defaultMessage: 'Schließen',
          })}
        </Button>
        {newTemplateId === '' && selectedNodes.length > 0 && (
          <Button onClick={() => onExtract()}>
            {intl.formatMessage({
              id: 'builder.extracttemplate.dialog.button.extract',
              defaultMessage: 'Vorlage erstellen',
            })}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
