import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.sceneCanvas.state.buttonText',
    defaultMessage: 'Weiter',
  },
  fullscreenTooltipMessage: {
    id: 'builder.nodes.sceneCanvas.state.fullscreenTooltipMessage',
    defaultMessage: 'Arbeitsfläche maximieren',
  },
});

/**
 * Scene Canvas xState specific defintions
 */
export const canvasDisplayStateEntry: StateEntryProps = {
  version: 1,
  type: 'renderCanvasDisplay',
  temporary: false,
  payload: {
    getValueFrom: '',
    saveResultTo: '',
    isEcho: true,
    tooltipFullscreenMessage:
      'builder.nodes.sceneCanvas.state.fullscreenTooltipMessage',
  },
  getValueType: [ReadWriteType.characters],
  saveResultType: [ReadWriteType.characters],
  displayName: 'Constellation Board Display',
  nodeType: 'canvasDisplayStateEntry',
  handleOutCount: 1,
};
