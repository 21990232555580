import { Handle, NodeProps, Position } from 'reactflow';
import { PostItDisplay } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { useGlobalStyles, translateKey } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  Post It Display react-flow node for module builder graph canvas
*/

export const PostItDisplayNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <PostItDisplay
          title={translateKey(intl, payload.title)}
          message={intl.formatMessage({
            id: 'builder.nodes.postitdisplay.messagetext',
            defaultMessage:
              'Der Text wird im Chat aus der Variable gelesen (getValueFrom)',
          })}
          headline={translateKey(intl, payload.headline)}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="postitdisplayoutputhandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
