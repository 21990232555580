import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  d: {
    id: 'formulaNodeStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kannst du eine Formel berechnen, also z.B. round((value1+value2)/2).',
  },
});

//
//  formula editor
//
// is using https://www.npmjs.com/package/expr-eval
// List of Operators can be found here: https://github.com/silentmatt/expr-eval#unary-operators
// Good doc: https://silentmatt.com/javascript-expression-evaluator/
//
export const formulaNodeStateEntry: StateEntryProps = {
  type: 'setFormula',
  temporary: false,
  payload: {
    getValuesFrom: [],
    saveResultTo: '',
    formula: '',
  },
  getValueType: ReadWriteType.number,
  saveResultType: ReadWriteType.number,
  displayName: 'Formula calculation',
  nodeType: 'formulaNodeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 1,
};
