import React, { useContext } from 'react';
import { Node } from 'reactflow';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { evoachDefaultTheme } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { getDataAsNodeDataProps } from '../nodes';
import { getSelectedNodes } from '../ModuleEditor/ReactFlowHelper';
import { ModuleEditorContext } from '../ModuleEditor/ModuleEditorContext';

import { EntryLists } from './EntryLists';
import { QualityTab } from './QualityTab';

/*
  the tab implementation in this component is derived from the examples 
  at https://mui.com/components/tabs/
*/

const Tab = styled(TabUnstyled)`
  font-family: Roboto;
  color: #808080;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: normal;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  border: none;
  display: flex;
  justify-content: center;

  &:focus {
    color: #fff;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #ffffff;
    color: ${evoachDefaultTheme.palette.primary.main};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: Roboto;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  background-color: #cccccc;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

/*
  General property pane structure
*/
export const ElementPropertySidebar: React.FC = () => {
  const intl = useIntl();

  const { nodes } = useContext(ModuleEditorContext);
  const selectedNodes = getSelectedNodes(nodes);

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const maxHeight = vh - 160 + 'px';

  return (
    <Box
      sx={{
        typography: 'body1',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#cccccc',
        borderRadius: '5px',
        marginRight: 0,
        width: '100%',
      }}
    >
      <TabsUnstyled
        defaultValue={0}
        style={{
          width: '100%',
        }}
        component="div"
      >
        <TabsList>
          <Tab>
            <Typography
              variant="body2"
              fontWeight="medium"
              fontSize="14"
              noWrap
            >
              {intl.formatMessage({
                id: 'builder.propertypane.title',
                defaultMessage: 'Eigenschaften',
              })}
            </Typography>
          </Tab>
          <Tab>
            <Typography variant="body2" fontWeight="medium" fontSize="14">
              {intl.formatMessage({
                id: 'builder.propertypane.quality',
                defaultMessage: 'Qualität',
              })}
            </Typography>
          </Tab>
        </TabsList>
        <TabPanel value={0} style={{ paddingLeft: '5px' }}>
          <div
            style={{
              height: maxHeight,
              maxHeight: maxHeight,
              overflow: 'auto',
            }}
          >
            {selectedNodes?.map((node: Node<any>) => {
              return (
                <div key={`SelectedElement-${node.id}`}>
                  <EntryLists
                    nodeData={getDataAsNodeDataProps(node)}
                    elementId={node.id}
                  />
                </div>
              );
            })}
            {(!selectedNodes || selectedNodes.length < 1) && (
              <Typography variant="body2" fontWeight="normal" fontSize="14">
                {intl.formatMessage({
                  id: 'builder.propertypane.noselectedelementtext',
                  defaultMessage:
                    'Wenn du ein Element selektierst, kannst du hier die Eigenschaften des Elementes ändern.',
                })}
              </Typography>
            )}
          </div>
        </TabPanel>

        <TabPanel value={1} style={{ paddingLeft: '5px' }}>
          <div
            style={{
              height: maxHeight,
              maxHeight: maxHeight,
              overflow: 'auto',
            }}
          >
            <QualityTab />
          </div>
        </TabPanel>
      </TabsUnstyled>
    </Box>
  );
};
