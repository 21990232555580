import { useMutation } from 'react-query';
import { useContext } from 'react';

import { ModuleProps } from '../../entities';
import { TranslationContext } from '../../components/stateMachineTranslationContext';
import { authorizedPut } from '../authorizedApi';

export const useUpdateModule = () => {
  const { stateMachineLocale } = useContext(TranslationContext);

  const { isLoading, data, isError, error, mutate } = useMutation(
    async (data: ModuleProps) => {
      const url = `/module/${
        data.moduleid
      }?language=${stateMachineLocale.toUpperCase()}`;

      const updateModuleCall = authorizedPut(url, data);
      const response = await updateModuleCall();

      const responseData = await response.json();

      return responseData;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    mutate,
    module: data,
    moduleId: data?.moduleid,
  };
};
