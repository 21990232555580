import { Handle, NodeProps, Position } from 'reactflow';
import {
  RadarchartDisplay,
  RadarchartDisplayProps,
  defaultRadarChartOptions,
  evoachDefaultTheme,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { useGlobalStyles, translateKey } from './base.node';

/*
  RadarChartNode react-flow node for module builder graph canvas
*/

export const RadarChartNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  const tickAmount: number = payload.tickAmount
    ? parseInt(payload.tickAmount)
    : 10;

  const autoGenProps: RadarchartDisplayProps = {
    width: payload.width ? parseInt(payload.width) : undefined,
    labels: ['', '', '', '', ''],
    title: intl.formatMessage({
      id: payload.title ?? 'builder.nodes.radarChartDisplay.state.title',
      defaultMessage: '',
    }),
    series: [
      {
        name: translateKey(intl, payload.radarLabels[0]),
        data: [1, 2, 3, 4, 5, 6, 7],
        color: evoachDefaultTheme.palette.primary.main,
      },
      {
        name: translateKey(intl, payload.radarLabels[1]),
        data: [8, 7, 6, 5, 4, 3, 2],
        color: evoachDefaultTheme.palette.secondary.main,
      },
    ],
    options: {
      ...defaultRadarChartOptions,
      yaxis: {
        ...defaultRadarChartOptions.yaxis,
        max: tickAmount,
        tickAmount: tickAmount,
      },
    },
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <Typography variant="body2" component="span">
          {intl.formatMessage({
            id: 'builder.nodes.radarchart.autogen.hint',
            defaultMessage: 'Labels werden generiert',
          })}
        </Typography>
        <RadarchartDisplay {...autoGenProps} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="radarcharthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
