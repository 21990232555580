import {
  CardSize,
  ObjectAddCardHorizontal,
  ObjectCardHorizontal,
} from '@evoach/ui-components';
import { Box } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../account';
import { ProgramModule } from '../../entities';
import { useEnvironment } from '../../environment/useEnvironment';
import { mapImageToPublicAsset } from '../reception/assets/AssetHelper';

import { ProgramModuleByJsonDialog } from './ProgramModuleByJsonDialog';

/*
  ProgramModuleListTimeline

  Pls check call hierarchy with responsibilities below

  ProgramEditorPage                     - page for router, check route params, load module
    |_ ProgramEditor                    - basic layout and mutate function, manage selected module
        |_ ProgramMetaEditor            - edit basic information for a program
!       |_ ProgramModuleListTimeline    - edit timeline / order of modules
        |_ ProgramModuleEditor          - edit a selected module

 */

export type ProgramModuleListTimelineProps = {
  programModules: ProgramModule[];
  selectModule: Function;
  onChange: Function;
  selectedModuleIndex: number;
  addNewModule: Function;
};

export const ProgramModuleListTimeline: React.FC<
  ProgramModuleListTimelineProps
> = ({
  programModules,
  selectModule,
  onChange,
  selectedModuleIndex,
  addNewModule,
}) => {
  const intl = useIntl();

  const { hasRole } = useContext(AccountContext);
  const { playerBasePath } = useEnvironment();

  const [isOpenProgramModuleDialog, setIsOpenProgramModuleDialog] =
    useState<boolean>(false);

  const openProgramModuleByJsonDialog = () => {
    setIsOpenProgramModuleDialog(true);
  };

  const onAddModuleByJson = (mods: any) => {
    let modules = {};
    try {
      modules = JSON.parse(mods);
    } catch (reason: unknown) {
      console.error(reason);
    }

    addNewModule(modules);
  };

  const onDeleteModule = (moduleIndex: number) => {
    if (moduleIndex < 0 || moduleIndex > programModules.length - 1) return;
    delete programModules[moduleIndex];
    onChange();
  };

  const onDuplicateModule = (moduleIndex: number) => {
    if (moduleIndex < 0 || moduleIndex > programModules.length - 1) return;
    const duplicatedModule = cloneDeep(programModules[moduleIndex]);
    const currentLastIndex = programModules.reduce(
      (prev, curr) => (curr.id > prev ? curr.id : prev),
      0
    );
    duplicatedModule.id = currentLastIndex + 1;
    programModules.splice(moduleIndex, 0, duplicatedModule);
    onChange();
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const items = programModules;
    // cut element that was dragged
    const [cutElement] = programModules.splice(source.index, 1);
    // insert element at destination position without cutting destination
    items.splice(destination.index, 0, cutElement);
    programModules = items;
    selectModule(destination.index);
    onChange();
  };

  return (
    // <Box sx={{ height: '800px', overflow: 'scroll' }}>
    <Box>
      <Box
        key="moduleprog_add"
        sx={{ marginBottom: '10px', position: 'sticky', top: 0, zIndex: 100 }}
      >
        <ObjectAddCardHorizontal
          cardSize={CardSize.MINI}
          onClick={() => addNewModule()}
          label={intl.formatMessage({
            id: 'builder.programs.programmodulelist.newprogrammodul',
            defaultMessage: 'Neues Programmmodul hinzufügen',
          })}
        />
        {hasRole(RoleEnum.EVOACHADMIN) && (
          <ObjectAddCardHorizontal
            cardSize={CardSize.MINI}
            onClick={() => openProgramModuleByJsonDialog()}
            label={intl.formatMessage({
              id: 'builder.programs.programmodulelist.newprogrammodulbyJSON',
              defaultMessage: 'Programmmmodul über JSON hinzufügen',
            })}
          />
        )}
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="modules">
          {(provided) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {programModules
                  .filter((module) => module !== undefined && module !== null)
                  .map((module: ProgramModule, index: number) => {
                    //console.log('moduleid: ' + module.id);
                    return (
                      <Draggable
                        key={module.id + ''}
                        draggableId={module.id + ''}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            onClick={() => {
                              selectModule(index);
                            }}
                            style={{ cursor: 'grab', marginBottom: '1rem' }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div style={{ marginBottom: '1rem' }}>
                              <ObjectCardHorizontal
                                title={module.title}
                                description={module.description}
                                duration={module.duration}
                                cardSize={CardSize.MINI}
                                deleteCallback={() => onDeleteModule(index)}
                                duplicateCallback={() =>
                                  onDuplicateModule(index)
                                }
                                imageSrc={
                                  module?.resolvedsrc
                                    ? module.resolvedsrc
                                    : module?.src
                                    ? module.src
                                    : mapImageToPublicAsset(
                                        module?.image,
                                        playerBasePath
                                      )
                                }
                                selected={index === selectedModuleIndex}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <ProgramModuleByJsonDialog
        open={isOpenProgramModuleDialog}
        onCloseHandler={(res: any) => {
          setIsOpenProgramModuleDialog(false);
          if (res) {
            onAddModuleByJson(res);
          }
        }}
      />
    </Box>
  );
};
