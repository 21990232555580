import { Buffer } from 'buffer';

import { Edge, isEdge, isNode, Node } from 'reactflow';
import { IntlShape } from 'react-intl';

import { ModuleProps } from '../../entities';
import { getOutgoingEdges } from '../ModuleEditor/moduleHelper';
import {
  compareMultipleNumbersStateEntry,
  compareNumbersStateEntry,
  formulaNodeStateEntry,
} from '../nodes';
import { isPayloadTranslated } from '../nodes/base.state';

/**
 * get all nodes of which not all outgoing handles are connected with edges and other nodes
 * @param {ModuleProps} module to be checked
 * @returns {Node<any>[]} array of nodes that have out(=source) handles that are not connected
 */
export const getNodesWithUnconnectedOutHandles = (
  module: ModuleProps
): Node<any>[] => {
  const criticalNodes: Node<any>[] = [];

  // no module? skip ...
  if (!module) return criticalNodes;

  const buildergraph = module.buildergraph;

  // no elements in graph? skip
  if (!buildergraph || buildergraph.length === 0) return criticalNodes;

  const nodes = buildergraph.filter(isNode);
  const edges = buildergraph.filter(isEdge);

  // for each node check whether all outgoing handles are connected;
  // if not, add node to list of critical nodes that are returnded
  nodes.forEach((node: Node<any>) => {
    if (
      !areAllOutgoingHandlesConnected(node, edges) &&
      node.type !== 'phaseEndStateEntry'
    ) {
      criticalNodes.push(node);
    }
  });

  return criticalNodes;
};

/**
 * checks if the module is greater than ...
 * @param {ModuleProps} module to be checked
 * @returns {boolean} returns true if...
 */
export const checkIfModuleTooBig = (module: ModuleProps): boolean => {
  if (!module) return false;

  const buildergraph = module.buildergraph;
  if (!buildergraph || buildergraph.length === 0) return false;

  const moduleJSON = JSON.stringify(module);
  const byteSize = Buffer.byteLength(moduleJSON, 'utf8');

  if (byteSize > 5000000) {
    return true;
  }
  if (module.buildergraph.filter(isNode).length > 300) {
    return true;
  }
  return false;
};

/**
 * get all nodes whose progressPercentage equals Zero
 * @param {ModuleProps} module to be checked
 * @returns {Node<any>[]} array of nodes whose progressPercentage equals Zero
 */
export const getNodesWith0Percentage = (module: ModuleProps): Node<any>[] => {
  const criticalNodes: Node<any>[] = [];

  // no module? skip ...
  if (!module) return criticalNodes;

  const buildergraph = module.buildergraph;

  // no elements in graph? skip
  if (!buildergraph || buildergraph.length === 0) return criticalNodes;

  const nodes = buildergraph.filter(isNode);

  // for each node check whether the progressPercentage equals zero
  nodes.forEach((node: Node<any>) => {
    if (
      nodePercentageEqualsZero(node) &&
      node.type !== 'phaseEndStateEntry' &&
      node.type !== 'noteStateEntry'
    ) {
      criticalNodes.push(node);
    }
  });

  return criticalNodes;
};

/**
 * determine whether the progressPercentage equals Zero
 * @param {Node<any>} node that is checked
 * @returns {boolean} true = the progressPercentage equals Zero
 */
export const nodePercentageEqualsZero = (node: Node<any>): boolean => {
  let returnvalue = true;
  node.data.state.entry.forEach((entry: any) => {
    console.log(entry.nodeType);
    if (entry.nodeType === 'setProgressPercentEntry') {
      if (
        entry.payload.progressPercent === 0 ||
        entry.payload.progressPercent === '0'
      ) {
        returnvalue = true;
      } else {
        returnvalue = false;
      }
    }
  });
  return returnvalue;
};

/**

 * determine whether all outgoing handles of a node are connected or not
 * @param {Node<any>} node that is checked
 * @param {Edge<any>[]} edges that are checked
 * @returns {boolean} true = all out handles have an edge thus are connected, false = at least one handle not connected
 */
export const areAllOutgoingHandlesConnected = (
  node: Node<any>,
  edges: Edge<any>[]
): boolean => {
  // how many outgoing handles do we expect for the current node?
  let maxEdges =
    node.data.handleOutCount ?? node.data.state.entry[0].handleOutCount;

  // if undefined then we have an old module or we forgot to set the value in the .state file
  if (maxEdges === undefined) {
    // get legacy function that returns number of outgoing edges
    maxEdges = getLegacyCount(node.data.nodeType);
  }

  // the number of outgoing handles varies depending on the node config
  if (maxEdges === 'dynamic') {
    // we have to determine the number based on keyTexts
    maxEdges = node.data.state.entry[0].payload.keyTexts.length;

    if (node.type === 'compareMultipleNumbersStateEntry') {
      maxEdges++; // one output handle is generated automatically
    }
  }

  // find number of outgoing edges
  const numOutGoingEdges = getOutgoingEdges(node, edges).length;

  // all handles connected?
  return maxEdges === numOutGoingEdges;
};

/**
 * get handleOutCount for state entry - fallback option for modules that use
 * nodes without the handleOutCount property.
 * @param {string} nodeType
 * @returns {number | 'dynamic'} number of outgoing edges
 */
export const getLegacyCount = (nodeType: string): number | 'dynamic' => {
  switch (nodeType) {
    case 'ratingInputStateEntry':
      return 6;
    case 'moodInputStateEntry':
      return 3;
    case 'loopEndStateEntry':
    case 'yesNoButtonStateEntry':
      return 2;
    case 'loopStartStateEntry':
      return 1;
    case 'noteStateEntry':
      return 0;
    case 'selectionCardStateEntry':
    case 'scaleInputMultiStateEntry':
    case 'randomCoachMessageStateEntry':
    case 'radioButtonStateEntry':
    case 'multiButtonStateEntry':
    case 'setMultipleCompareNumbers':
      return 'dynamic';
    default: // the majority of nodes has only one output
      return 1;
  }
};

/**
 * determine whether a phase end node is existing
 * @param {ModuleProps} module to be checked
 * @returns {boolean} true = end node is existing, false = end node missing
 */
export const checkForEndNode = (module: ModuleProps): boolean => {
  const buildergraph = module.buildergraph;
  if (!buildergraph || buildergraph.length === 0) return false;
  const nodes = buildergraph.filter(isNode);
  return nodes.some((node) => node.type === 'phaseEndStateEntry');
};

/**
 * checkForTranslations checks all nodes for translations. it looks at each
 * key of the payload in entry[0] and determines whether there is a translation
 * in intl.messages available of not.
 * For arrays, it checks whether the array contains strings or objects.
 *
 * @param {ModuleProps} module to be checked
 * @returns {Node<any>[]} array of nodes where translations are lacking
 */
export const checkForTranslations = (
  module: ModuleProps,
  intl: IntlShape
): Node<any>[] => {
  // init get buildergraph from module and extract nodes
  const buildergraph = module.buildergraph;
  if (!buildergraph || buildergraph.length === 0) return [];
  const nodes = buildergraph.filter(isNode);
  const untranslatedNodes: Node<any>[] = [];

  // run through all nodes
  nodes.forEach((node: Node<any>) => {
    // if any of the props is translateable but has no translation, we
    // add the node of the list of untranslated nodes

    if (!isPayloadTranslated(node.data.state.entry[0].payload, intl)) {
      untranslatedNodes.push(node);
    }
  });

  return untranslatedNodes;
};

/**
 * check whether important nodes have a variable assignement. Nodes to be checked:
 * - setCompareNumbers
 * - setFormula
 * - setMultipleCompareNumbers
 *
 * @param {ModuleProps} module to be checked
 * @returns {Node<any>[]} list of nodes to be affected
 */
export const checkForNodesWithoutVariables = (
  module: ModuleProps
): Node<any>[] => {
  const buildergraph = module.buildergraph;
  if (!buildergraph || buildergraph.length === 0) return [];
  const nodes = buildergraph.filter(isNode);

  const nodeTypesToCompare = [
    compareNumbersStateEntry.nodeType,
    formulaNodeStateEntry.nodeType,
    compareMultipleNumbersStateEntry.nodeType,
  ];

  return nodes
    .filter((node) => nodeTypesToCompare.includes(node.type + ''))
    .filter((node) => {
      const payload = node.data.state.entry[0].payload;
      switch (node.type) {
        case compareNumbersStateEntry.nodeType:
          return (
            payload.firstValueToCompare === '' ||
            payload.secondValueToCompare === ''
          );
        case formulaNodeStateEntry.nodeType:
          return (
            payload.getValuesFrom === undefined ||
            payload.getValuesFrom.length === 0
          );
        case compareMultipleNumbersStateEntry.nodeType:
          return (
            payload.getValueFrom === undefined ||
            payload.getValueFrom === '' ||
            payload.getValueFrom.startsWith('evoachechokey')
          );
        default:
          return false;
      }
    });
};
