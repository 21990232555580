import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'aiSummarizeStateEntry.description',
    defaultMessage:
      'Dieser Prompt fasst die Texte zusammen, die als Variablen übergeben werden.',
  },
  maxSentences: {
    id: 'aiSummarizeStateEntry.maxSentences',
    defaultMessage:
      'Die KI wird versuchen, die Zusammenfassung in diese Anzahl Sätze zu fassen.',
  },
  getValuesFrom: {
    id: 'aiSummarizeStateEntry.getValuesFrom',
    defaultMessage:
      'Gib an, aus welchen Variableninhalten die Zusammenfassung generiert werden soll.',
  },
  saveResultTo: {
    id: 'aiSummarizeStateEntry.saveResultTo',
    defaultMessage:
      'Gib die Variable an, in der die Zusammenfassung gespeichert werden soll.',
  },
});

/**
 * The freeMicrochatPrompt component is used to define a prompt for a
 * microchat. The prompt is encoded for Player sessions and is not revealed
 * neither in API nor to the user.
 */
export const aiSummarizeStateEntry: StateEntryProps = {
  version: 1,
  type: 'sendExternal',
  temporary: false,
  payload: {
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.SUMMARIZE,
    getValuesFrom: [],
    saveResultTo: '',
    maxSentences: 5,
  },
  getValueType: [ReadWriteType.string, ReadWriteType.stringArray],
  saveResultType: ReadWriteType.string,
  displayName: 'Summary (AI)',
  nodeType: 'aiSummarizeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 1,
};
