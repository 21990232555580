import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
  Typography,
  Link,
  Box,
} from '@mui/material';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { useIntl } from 'react-intl';

import { formulaNodeStateEntry } from '../nodes';

const textFieldEditorMessages = defineMessages({
  dialogTitle: {
    id: 'builder.moduleeditor.propertiessidebar.textfield.editor.dialogTitle',
    defaultMessage: 'Text editieren',
  },
  dialogSaveButton: {
    id: 'builder.moduleeditor.propertiessidebar.textfield.editor.dialogSaveButton',
    defaultMessage: 'Speichern',
  },
});

export interface MetadataEditorProps {
  open: boolean;
  onClose: () => void;
  label: string;
  value: string;
  onChange: Function;
  nodeType?: string;
  availableVariables?: string[];
}
export const TextFieldDialog: React.FC<MetadataEditorProps> = ({
  open,
  onClose,
  label,
  value,
  onChange,
  nodeType,
  availableVariables,
}) => {
  const intl = useIntl();

  const [localValue, setLocalValue] = useState<string>(value);
  const [input, setInput] = useState<any>(undefined);
  const [cursorPosition, setCursorPosition] = useState<number>(0);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const {
    listening,
    browserSupportsSpeechRecognition,
    transcript,
    finalTranscript,
    resetTranscript,
  } = useSpeechRecognition(); // resetTranscript,

  // check real toggle and not only re-render of listening variable
  const [lastListening, setLastListening] = useState<boolean>(listening);

  useEffect(() => {
    //
    if (lastListening !== listening) {
      if (finalTranscript.toLowerCase().trim().replaceAll(/\s/g, '') !== '') {
        setLocalValue(localValue + ' ' + finalTranscript);
        onChange(localValue + ' ' + finalTranscript);
        resetTranscript();
      }
      setLastListening(!lastListening);
    }
  }, [
    listening,
    lastListening,
    finalTranscript,
    localValue,
    value,
    onChange,
    resetTranscript,
  ]);

  const appendVar = (variable: string) => {
    setLocalValue(localValue + variable);
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage {...textFieldEditorMessages.dialogTitle} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'builder.moduleeditor.propertiessidebar.textfield.editor.hint',
            defaultMessage:
              'Du kannst den Text entweder mit der Tastatur editieren oder diktieren, wenn dein Browser das unterstützt. Diktierte Texte werden automatisch ans Ende angefügt.',
          })}
          &nbsp;
          <br />
          &nbsp;
          <br />
          {availableVariables !== undefined &&
            Array.isArray(availableVariables) &&
            availableVariables.length === 0 &&
            nodeType !== formulaNodeStateEntry.nodeType && (
              <>
                {intl.formatMessage({
                  id: 'builder.moduleeditor.propertiessidebar.textfield.editor.hintusevars',
                  defaultMessage:
                    'Hinweis: Du kannst mit diesem Element Variablen verwenden, wenn du Variablen im Feld "Erlaubte Textvariablen" auswählst.',
                })}
              </>
            )}
          {availableVariables !== undefined &&
            Array.isArray(availableVariables) &&
            availableVariables.length === 0 &&
            nodeType === formulaNodeStateEntry.nodeType && (
              <>
                {intl.formatMessage({
                  id: 'builder.moduleeditor.propertiessidebar.textfield.editor.hintusevarsformula',
                  defaultMessage:
                    'Hinweis: Du kannst mit diesem Element Variablen verwenden, wenn du Variablen im Feld "Wert erhalten von" auswählst.',
                })}
              </>
            )}
          {availableVariables !== undefined &&
            Array.isArray(availableVariables) &&
            availableVariables.length > 0 &&
            nodeType !== formulaNodeStateEntry.nodeType && (
              <>
                {intl.formatMessage({
                  id: 'builder.moduleeditor.propertiessidebar.textfield.editor.hintwhichvars',
                  defaultMessage:
                    'Du kannst die folgenden Variablen nutzen, indem du auf diese klickst. Alternativ kannst du {variablenname} in deinem Text verwenden, den Variablennamen also in geschweifte Klammern einschließen. Im Chat wird die Variable dann mit dem Wert der Variable ersetzt. Diese Variablen stehen zur Verfügung:',
                })}{' '}
                <b>
                  {availableVariables.map((varn: string) => (
                    <Box component="span" key={'availvarn' + varn}>
                      <Link
                        fontWeight="bold"
                        component="button"
                        onClick={() => appendVar('{' + varn + '}')}
                      >
                        {'{' + varn + '}'}
                      </Link>{' '}
                    </Box>
                  ))}
                </b>
              </>
            )}
          {availableVariables !== undefined &&
            Array.isArray(availableVariables) &&
            availableVariables.length > 0 &&
            nodeType === formulaNodeStateEntry.nodeType && (
              <>
                {intl.formatMessage({
                  id: 'builder.moduleeditor.propertiessidebar.textfield.editor.hintwhichvarsformula',
                  defaultMessage:
                    'Du kannst in deiner Formel die folgenden Variablen nutzen, indem du auf diese klickst. Alternativ kannst du den Variablennamen (ohne geschweifte Klammern) in deiner Formel verwenden. Diese Variablen stehen zur Verfügung:',
                })}{' '}
                <b>
                  {availableVariables.map((varn: string) => (
                    <Box component="span" key={'availvarn' + varn}>
                      <Link
                        component="button"
                        fontWeight="bold"
                        onClick={() => {
                          setLocalValue(
                            localValue.slice(0, cursorPosition) +
                              varn +
                              localValue.slice(cursorPosition)
                          );
                        }}
                      >
                        {varn}
                      </Link>{' '}
                    </Box>
                  ))}
                </b>
              </>
            )}
        </Typography>
        <div
          style={{
            paddingTop: '0px',
            marginTop: '20px',
          }}
        >
          <TextField
            ref={() => {
              const input = document.getElementById(
                'donotcatchpasteevent_testfielddialog'
              );
              setInput(input);
            }}
            id="donotcatchpasteevent_testfielddialog"
            label={label}
            value={localValue + transcript}
            multiline
            minRows={10}
            maxRows={10}
            key="TextFieldDialog"
            autoFocus
            autoComplete="off"
            fullWidth
            onChange={(event) => {
              const value = event.target.value;

              if (typeof input === 'object' && input !== null) {
                setCursorPosition(input.selectionStart);
              }
              setLocalValue(value);
            }}
            onClick={() => {
              if (typeof input === 'object' && input !== null) {
                setCursorPosition(input.selectionStart);
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {browserSupportsSpeechRecognition && (
          <>
            {browserSupportsSpeechRecognition && listening && (
              <KeyboardVoiceIcon fontSize="small" color="primary" />
            )}
            {browserSupportsSpeechRecognition && !listening && (
              <KeyboardVoiceIcon fontSize="small" color="secondary" />
            )}
            <Button
              onClick={() => {
                SpeechRecognition.startListening({
                  language: 'de-DE',
                  continuous: false,
                });
              }}
            >
              {intl.formatMessage({
                id: 'builder.moduleeditor.propertiessidebar.textfield.editor.startdictation',
                defaultMessage: 'Diktieren starten',
              })}
            </Button>
          </>
        )}
        <Button
          color="secondary"
          onClick={() => {
            onChange(localValue);
            onClose();
          }}
        >
          <FormattedMessage {...textFieldEditorMessages.dialogSaveButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
