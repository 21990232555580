import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';

//import { resolveS3URL } from '../../api/asset/resolves3';
import { useFetchAssetUrl } from '../../api/asset';

import { useGlobalStyles } from './base.node';
import defaultAvatar from './setAvatarMoDefault.jpg';
/*
  Set Avatar Node react-flow node for module builder graph canvas
*/

export const SetAvatarNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const externalSrc = payload.src
    ? intl
        .formatMessage({
          id: payload.src,
          defaultMessage: '',
        })
        .trim()
    : '';

  const s3url = useFetchAssetUrl(
    payload.assetid && payload.assetid.trim() !== ''
      ? intl.formatMessage({
          id: payload.assetid,
          defaultMessage: '',
        })
      : ''
  ).url;

  const finalUrl =
    !s3url || s3url.trim() === '' || s3url === 'filenotfound'
      ? externalSrc
      : s3url;

  // if url can't be rsolved, provide error image
  const imgsrc =
    !finalUrl ||
    finalUrl.trim() === '' ||
    !finalUrl.toLowerCase().startsWith('http')
      ? defaultAvatar
      : finalUrl;

  return (
    <span style={{ height: 10 }}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <img
          src={imgsrc}
          className={classes['coachother']}
          alt="Coaching Avatar"
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="aftercoachingavatarhandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </span>
  );
};
