import { useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { Alert } from './Alert';

//
// generated with http://patorjk.com/software/taag/#p=display&h=1&v=0&f=Big&t=evoach
// I had to manually tweak the output to make it look good, e.g. replacing \ with \\
//
// message is printed when window is resized (assuming that this means: open developer console)
//
const asciiMessage = `
                                 ___    
                                 | |    
 ___  __   __  ___    ___   ___  | |__  
/ _ \\ \\ \\ / / / _ \\  / _ | / __| | '_ \\ 
| __/  \\ V / | (_) | |(_|| |(__  | | | |
\\___|   \\_/   \\___/  \\___| \\___| |_| |_|

`;

let asciiMessageDisplayed = false;

export type WindowContextProps = {
  /**
   * height of the visible area of the current window
   */
  clientHeight: number;
  /**
   * width of the visible area of the current window
   */
  clientWidth: number;
  /**
   * Replacement for the window.alert statement
   * Use it with const { alert } = useContext(WindowContext);
   */
  alert: Function;
  /**
   * true if browser width is less than 720px
   */
  isMobile: boolean;
};

export const WindowContext = React.createContext<WindowContextProps>({
  clientHeight: 0,
  clientWidth: 0,
  alert: () => {},
  isMobile: false,
});

/**
 * This window context provider provides the width and the height of the current window.
 * It also updated these values when the window is resized.
 */
export type WindowContextProviderProps = {
  children?: React.ReactNode;
};
export const WindowContextProvider: React.FC<WindowContextProviderProps> = ({
  children,
}) => {
  // function to get the current height of the window
  const getVh = useCallback(() => {
    return Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
  }, []);

  const getVw = useCallback(() => {
    return Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
  }, []);

  const [clientHeight, setVh] = useState<number>(getVh());
  const [clientWidth, setVw] = useState<number>(getVw());
  const isMobile = useMediaQuery('(max-width:720px)');

  useEffect(() => {
    function handleResize() {
      setVh(getVh());
      setVw(getVw());
      if (!asciiMessageDisplayed) {
        console.log(asciiMessage);
        console.log(
          'Curious how we built this? Learn more about coaching with chatbots at https://www.evoach.com'
        );
        asciiMessageDisplayed = true;
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getVh, getVw]);

  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const alert = (message: string = 'alert') => {
    setAlertMessage(message);
    setAlertIsOpen(true);
  };

  return (
    <WindowContext.Provider
      value={{
        clientHeight,
        clientWidth,
        alert,
        isMobile,
      }}
    >
      {children}
      <Alert
        isOpen={alertIsOpen}
        message={alertMessage}
        onClose={() => setAlertIsOpen(false)}
      />
    </WindowContext.Provider>
  );
};
