import { ReadWriteType, StateEntryProps } from '.';

export const moodInputStateEntry: StateEntryProps = {
  type: 'renderMoodInput',
  temporary: true,
  payload: {
    saveResultTo: 'mood_result',
  },
  saveResultType: ReadWriteType.string,
  displayName: 'Mood Input',
  nodeType: 'moodInputStateEntry',
  handleOutCount: 3,
};
