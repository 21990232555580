import { Checkbox, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { authorizedPut } from '../../../api';

type AssetAiPrepareCheckboxProps = {
  assetid: string;
  isActivated: boolean;
  onUpdate?: Function;
};

export const AssetAiPrepareCheckbox: React.FC<AssetAiPrepareCheckboxProps> = ({
  assetid,
  isActivated = false,
  onUpdate,
}) => {
  const intl = useIntl();

  const [useForAi, setUseForAi] = useState(isActivated);

  const handleCheckboxChange = async () => {
    setUseForAi(!useForAi);
    // update setting of asset
    console.log(!useForAi);
    const updateUrl: RequestInfo = `/asset/${assetid}`;
    const updateCall = authorizedPut(updateUrl, {
      useforai: !useForAi,
    });
    await updateCall();

    // call update function in parent of provided
    if (onUpdate) {
      onUpdate();
    }
  };

  if (!assetid) {
    return <></>;
  }

  return (
    <Typography>
      {intl.formatMessage({
        id: 'player.assets.enableai.description',
        defaultMessage:
          'Diese Datei kann für die Nutzung in KI Funktionen verwendet werden.',
      })}
      <br />
      {useForAi
        ? intl.formatMessage({
            id: 'player.assets.enableai.ischecked',
            defaultMessage:
              'Wenn du den Haken entfernst, werden alle KI Funktionen mit dieser Datei deaktiviert. Wenn die Datei bereits in Chatbots verwendet wird, werden die Chatbots nicht mehr funktionieren und du musst sie von Hand anpassen.',
          })
        : intl.formatMessage({
            id: 'player.assets.enableai.isnotchecked',
            defaultMessage:
              'Wenn du den Haken setzt, wird die Datei für die Nutzung in KI Funktionen vorbereitet. Das kann einige Minuten dauern.',
          })}
      <br />
      <label>
        <Checkbox
          color="primary"
          checked={useForAi}
          onChange={handleCheckboxChange}
        />
        {intl.formatMessage({
          id: 'player.assets.enableai.checkboxlabel',
          defaultMessage: 'Datei für Nutzung in KI Funktionen aktivieren',
        })}
      </label>
    </Typography>
  );
};
