/*
  ModuleEditor

  Pls check call hierarchy with responsibilities below

  BuilderPage                     - page for router, check route params, load module
    |_ ModuleBuilderWrapper       - management of module translation which is independent of the UI translation
      |_ ModuleBuilder            - keep state of Reract Flow nodes and edges, manage undo logic
 !       |_ ModuleEditor           - provide toolbar on top of editor canvas
          |_ ModuleEditorpane     - ReactFlow provider + ReactFlow component + management of canvas actions

*/

import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import { Edge, Node, Viewport } from 'reactflow';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import AssessmentIcon from '@mui/icons-material/Assessment';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import PercentIcon from '@mui/icons-material/Percent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
//import TranslateIcon from '@mui/icons-material/Translate';
import UndoIcon from '@mui/icons-material/Undo';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import HelpIcon from '@mui/icons-material/Help';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';

// Local Translation context to manage translation of graph and state machine
import { TranslationContext } from '../stateMachineTranslationContext';
import { AccountContext, RoleEnum } from '../../account';
import { AppRoutes } from '../../routing/routes';
import { SupportedModuleLocales } from '../../intl/SupportedLocales';
import { DevToolsContext } from '../../devtools/DevToolsContext';
import { getUserLanguage } from '../../intl/TranslationContext';

import { cleanUpTranslations } from './moduleUtils';
import { MagicEditor } from './MagicEditor';
import {
  autoGenerateProgressValues,
  estimateModuleDuration,
} from './moduleHelper';
import { generateModule } from './moduleUtils';
import { PercentageDialog } from './PercentageDialog';
import { DurationDialog } from './DurationDialog';
import {
  LanguageSelectionHeightOptions,
  MetaDataEditorLanguageSelection,
} from './MetaDataEditorLanguageSelection';
import { ModuleExternalEmbeddingButton } from './ModuleExternalEmbeddingButton';
import { ModulePreviewButton } from './ModulePreviewButton';
import { ReactFlowElements } from './ReactFlowHelper';
import { autoSave } from './autosave';
import { ExtractTemplateDialog } from './ExtractTemplateDialog';
import { ModuleEditorContext } from './ModuleEditorContext';

import { ModuleEditorPane, MetadataEditor, ModuleDiagnose } from '.';

const moduleEditorMessages = defineMessages({
  saved: {
    id: 'builder.moduleeditor.saved',
    defaultMessage: 'Gespeichert:',
  },
  autoSaveOn: {
    id: 'builder.moduleeditor.autosaveon',
    defaultMessage:
      'Automatisches Speichern ist eingeschaltet. Klicken zum Ausschalten.',
  },
  autoSaveOff: {
    id: 'builder.moduleeditor.autosaveoff',
    defaultMessage:
      'Automatisches Speichern ist ausgeschaltet. Klicken zum Einschalten.',
  },
  previewButtonText: {
    id: 'builder.moduleeditor.testbuttontext',
    defaultMessage: 'Testen',
  },
  openMetaDataEditorDialog: {
    id: 'builder.moduleeditor.openMetaDataEditorDialog',
    defaultMessage: 'Modulbeschreibung bearbeiten',
  },
  alertTitle: {
    id: 'builder.moduleeditor.alert.title',
    defaultMessage: 'Fehler',
  },
  alertHint: {
    id: 'builder.moduleeditor.alert.hint',
    defaultMessage: 'Beim Speichern des Moduls ist ein Fehler aufgetreten.',
  },
  alertCloseHint: {
    id: 'builder.moduleeditor.alert.closehint',
    defaultMessage: 'Diese Meldung schließt sich automatisch nach 15 Sekunden.',
  },
  openModuleDiagnoseDialog: {
    id: 'builder.moduleeditor.alert.diagnosebuttontext',
    defaultMessage: 'Diagnose',
  },
  cleanUp: {
    id: 'builder.moduleeditor.button.cleanup',
    defaultMessage: 'Aufräumen',
  },
  save: {
    id: 'builder.moduleeditor.button.save',
    defaultMessage: 'Speichern (STRG+S bzw. CMD+S)',
  },
});

//const AUTOSAVEINTERVAL = 10000; // 10 seconds

export interface ModuleEditorProps {
  moduleIsLoading: boolean;
}

export const ModuleEditor: React.FC<ModuleEditorProps> = ({
  moduleIsLoading,
}) => {
  const intl = useIntl();
  let navigate = useNavigate();
  const { hasRole } = useContext(AccountContext);

  const { module, nodes, edges, updateElements, saveModule, undoElements } =
    useContext(ModuleEditorContext);

  // automatically open MetaData Dialog when new module was created and is edited the first time
  const [isMetadataDialogOpen, setIsMetadataDialogOpen] = useState<boolean>(
    !module.buildergraph ||
      module.buildergraph.length === 0 ||
      module.buildergraph.length === undefined
  );

  const [isDiagnoseDialogOpen, setIsDiagnoseDialogOpen] =
    useState<boolean>(false);

  const [isPercentageDialogOpen, setIsPercentageDialogOpen] =
    useState<boolean>(false);

  const [isDurationDialogOpen, setIsDurationDialogOpen] =
    useState<boolean>(false);

  const {
    stateMachineTranslation,
    stateMachineLocale,
    removeStateMachineTranslation,
    addOrUpdateMetaDataTranslation,
    metadataTranslation,
  } = useContext(TranslationContext);

  const { l } = useContext(DevToolsContext);

  const [command, setCommand] = useState<string | undefined>(undefined);

  const updateModuleProps = (key: string, value: any) => {
    module[key] = value;
  };

  const handleOpenMetadataEditorDialog = () => {
    setIsMetadataDialogOpen(!isMetadataDialogOpen);
    if (isMetadataDialogOpen) {
      onModuleSave();
    }
  };

  const handleOpenDiagnoseDialog = () => {
    setIsDiagnoseDialogOpen(!isDiagnoseDialogOpen);
    //onModuleSave();
  };

  /**
   * trigger paste event by simulatig a keyboard CMD+V action ...
   */
  const pasteElements = () => {
    const evt = new KeyboardEvent('keydown', {
      key: 'v',
      keyCode: 86,
      code: 'KeyV',
      ctrlKey: true,
      shiftKey: false,
      altKey: false,
      metaKey: false,
    });
    window.dispatchEvent(evt);
  };

  const [isOpenExtract, setIsOpenExtract] = useState<boolean>(false);

  const handleOpenExtractDialog = () => {
    setIsOpenExtract(!isOpenExtract);
  };

  const [isOpenMagic, setIsOpenMagic] = useState<boolean>(false);

  const handleOpenMagicDialog = () => {
    setIsOpenMagic(!isOpenMagic);
  };

  //const [autoSaveOn, setautoSaveOn] = useState<boolean>(true);
  const getElements = () => {
    return (nodes as ReactFlowElements).concat(edges);
  };

  const [lastSaved, setLastSaved] = useState<string>('');
  const onModuleSave = () => {
    l('ModuleEditor: onModuleSave()');

    /* Object.keys(stateMachineTranslation).forEach((key: string) =>
      console.log('### ' + stateMachineTranslation[key])
    ); */

    const modToBeSaved = generateModule(
      nodes,
      edges,
      stateMachineTranslation,
      metadataTranslation,
      module
    );

    l('ModuleEditor: module to be saved:');
    l(modToBeSaved);

    saveModule(modToBeSaved);
    setLastSaved(
      intl.formatTime(Date.now(), {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
    );
  };

  const updateModuleMetadataProps = (key: string, value: any) => {
    module.metadata[key] = value;
  };

  /**
   * handle clean up of module
   * 1. remove unused translations
   * 2. remove non-existing elements from builder graph
   */
  const handleCleanUp = () => {
    const locmodule = generateModule(
      nodes,
      edges,
      stateMachineTranslation,
      metadataTranslation,
      module
    );

    let mynodes = nodes;
    let myedges = edges;

    // 1. remove non existing nodes from buildergraph
    mynodes = nodes.filter(
      (node: Node<any>) => !node.type?.startsWith('snippet')
    );

    // 2. remove unconnected edges
    const listOfAllNodeIds = mynodes.map((node: Node<any>) => node.id);

    // collect all edge ids that are not properly connected
    // or that contain non-existing handles
    const sourceIdsOfUnconnectedEdges = myedges
      .filter(
        (edge: Edge<any>) =>
          !listOfAllNodeIds.includes(edge.source) ||
          !listOfAllNodeIds.includes(edge.target)
      )
      .map((edge: Edge<any>) => edge.id);

    // remove all elements previously collected
    myedges = myedges.filter(
      (edge: Edge<any>) => !sourceIdsOfUnconnectedEdges.includes(edge.id)
    );

    // 3. remove edges that connect a node with itself

    // collect all edge ids that are not properly connected
    // or that contain non-existing handles
    const sourceIdsOfHiddenEdges = myedges
      .filter((edge: Edge<any>) => edge.source === edge.target)
      .map((edge: Edge<any>) => edge.id);

    // remove all elements previously collected
    myedges = myedges.filter(
      (edge: Edge<any>) => !sourceIdsOfHiddenEdges.includes(edge.id)
    );

    // 4. clean up translations
    const newsmtrans = cleanUpTranslations(
      locmodule.statemachine,
      stateMachineTranslation
    );
    const remainingKeys = Object.keys(newsmtrans);
    Object.keys(stateMachineTranslation).forEach((transkey: string) => {
      if (!remainingKeys.includes(transkey)) {
        removeStateMachineTranslation(transkey);
      }
    });

    updateElements((mynodes as ReactFlowElements).concat(myedges));
  };

  /**
   * auto generate progress values. Use a heuristics that goes like this:
   */
  const percentageDialogOnFeedback = (feedback: string) => {
    if (feedback === 'ok') {
      updateElements(autoGenerateProgressValues(getElements()));
    }
    setIsPercentageDialogOpen(false);
  };

  /**
   * estimate duration of the module and set metadata
   */
  const durationDialogOnFeedback = (feedback: string) => {
    setIsDurationDialogOpen(false);
    if (feedback === 'ok') {
      // estimate value and set duration in metadata with text
      const durationText = intl.formatMessage(
        {
          id: 'builder.moduleeditor.durationestimationtemplate',
          defaultMessage: 'ca. {estimatedDuration} Minuten',
        },
        {
          estimatedDuration: estimateModuleDuration(
            getElements(),
            stateMachineTranslation
          ),
        }
      );

      const durationKey = module.metadata.duration;
      addOrUpdateMetaDataTranslation(durationKey, durationText);

      // show modified module metadata in dialog
      setIsMetadataDialogOpen(true);
    }
  };

  //
  // new route to open translationmanagent
  //
  const openTranslationManagement = () => {
    if (!stateMachineLocale || stateMachineLocale === '') return;
    navigate(
      `${AppRoutes.TRANSLATION}/${
        module.moduleid
      }/${stateMachineLocale.toUpperCase()}`
    );
  };

  //
  // link to FAQ on evoach.com
  //
  const handleOpenFAQPage = () => {
    const locale = getUserLanguage().toLowerCase();
    const url = 'https://www.evoach.com/helpandfaqs?lang=' + locale;
    window.open(url, '_blank');
  };

  //
  // change route to switch language in builder
  //
  const onLanguageChange = (language: string) => {
    if (!language || language === '') return;
    navigate(
      `${AppRoutes.BUILDER}/${module.moduleid}/${language.toUpperCase()}`
    );
  };

  //
  // if editor canvas is zoomed or moved, we store the current viewport in the
  // metadata of the module in order to restore it when re-loadig the module
  //
  const [viewPort, setViewPort] = useState<Viewport | undefined>(
    module.metadata.creatorviewport
  );
  useEffect(() => {
    module.metadata.creatorviewport = viewPort;
  }, [module.metadata, viewPort]);

  //
  // provide updateVIewPort function to ReactFlow onMove event in
  // ModuleEditorPane and update both the state and localstorage here
  //
  const updateViewport = (viewport: Viewport) => {
    setViewPort(viewport);
    // also set viewport in localstorage => so we can keep the viewport
    // even if you pan and zoom and switch languages but do not save anything
    localStorage.setItem(
      'evoach.' + module.moduleid + '.viewport',
      JSON.stringify(viewport)
    );
  };

  //
  // if the statemachinelanguage changes, get the last viewport from the
  // localstorage as it may have changed before but wasn't stored.
  //
  useEffect(() => {
    // get last viewport from localStorage and reset
    const viewport = localStorage.getItem(
      'evoach.' + module.moduleid + '.viewport'
    );
    // if there was no viewport saved or if the module was never opened in
    // the current browser (localStorage!), we automatically fall back to the
    // viewport saved in the module (if existing)
    if (viewport) {
      setViewPort(JSON.parse(viewport) as Viewport);
    }
  }, [module.moduleid, stateMachineLocale]);

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      style={{ paddingTop: '0px' }}
    >
      <Grid
        item
        xs={1}
        style={{
          marginLeft: 'auto',
          marginRight: '5px',
          marginBottom: '0px',
        }}
      >
        {hasRole(RoleEnum.EVOACHADMIN) && (
          <Box component="span" sx={{ marginRight: '50px' }}>
            <IconButton onClick={handleOpenMagicDialog}>
              <Tooltip placement="top" arrow title="Magic">
                <KeyboardVoiceIcon />
              </Tooltip>
            </IconButton>

            <MagicEditor
              open={isOpenMagic}
              onClose={handleOpenMagicDialog}
              onCommand={setCommand}
            />

            <IconButton
              onClick={handleOpenDiagnoseDialog}
              aria-label={intl.formatMessage(
                moduleEditorMessages.openModuleDiagnoseDialog
              )}
            >
              <Tooltip
                placement="top"
                arrow
                title={intl.formatMessage(
                  moduleEditorMessages.openModuleDiagnoseDialog
                )}
              >
                <AssessmentIcon />
              </Tooltip>
            </IconButton>

            <IconButton
              onClick={handleCleanUp}
              aria-label={intl.formatMessage(moduleEditorMessages.cleanUp)}
            >
              <Tooltip
                placement="top"
                arrow
                title={intl.formatMessage(moduleEditorMessages.cleanUp)}
              >
                <SanitizerIcon />
              </Tooltip>
            </IconButton>

            <ModuleDiagnose
              module={module}
              open={isDiagnoseDialogOpen}
              onClose={handleOpenDiagnoseDialog}
            />
          </Box>
        )}
        <ModuleExternalEmbeddingButton
          module={module}
          isLoading={moduleIsLoading}
          onUpdate={() => autoSave()}
        />
        <div
          style={{
            display: 'inline-flex',
            verticalAlign: 'middle',
            justifyContent: 'left',
            marginLeft: '20px',
          }}
        >
          <IconButton onClick={() => handleOpenExtractDialog()}>
            <Tooltip
              placement="top"
              arrow
              title={intl.formatMessage({
                id: 'builder.moduleeditor.extracttemaplte',
                defaultMessage:
                  'Neue Vorlage aus selektierten Elementen erstellen',
              })}
            >
              <ContentPasteGoIcon />
            </Tooltip>
          </IconButton>

          <ExtractTemplateDialog
            open={isOpenExtract}
            onClose={handleOpenExtractDialog}
            module={module}
            selectedNodes={nodes.filter((node: Node<any>) => node.selected)}
          />
        </div>
        <div
          style={{
            display: 'inline-flex',
            verticalAlign: 'middle',
            justifyContent: 'left',
            marginLeft: '20px',
          }}
        >
          <IconButton onClick={() => undoElements()}>
            <Tooltip
              placement="top"
              arrow
              title={intl.formatMessage({
                id: 'builder.moduleeditor.undobutton',
                defaultMessage: 'Rückgängig machen (STRG+Z bzw. CMD+Z)',
              })}
            >
              <UndoIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => pasteElements()}>
            <Tooltip
              placement="top"
              arrow
              title={intl.formatMessage({
                id: 'builder.moduleeditor.pastebutton',
                defaultMessage:
                  'Elemente oder Texte aus der Zwischenablage einfügen (STRG+V bzw. CMD+V)',
              })}
            >
              <ContentPasteIcon />
            </Tooltip>
          </IconButton>
        </div>
        <div
          style={{
            display: 'inline-flex',
            verticalAlign: 'middle',
            justifyContent: 'left',
            marginLeft: '20px',
          }}
        >
          <MetaDataEditorLanguageSelection
            onLanguageChange={onLanguageChange}
            moduleLocale={stateMachineLocale}
            excludeLocales={Object.keys(SupportedModuleLocales).filter(
              (lo: string) => !module.metadata.moduleLanguages.includes(lo)
            )}
            heightOption={LanguageSelectionHeightOptions.NARROW}
            displayLabel={false}
          />
        </div>
        <IconButton onClick={handleOpenFAQPage}>
          <Tooltip placement="top" arrow title="FAQ">
            <HelpIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => openTranslationManagement()}>
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage({
              id: 'builder.moduleeditor.displayedmodulelanguage',
              defaultMessage:
                'Angezeigte Modulsprache im Übersetzungsmanagement öffnen.',
            })}
          >
            <LanguageOutlinedIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => setIsDurationDialogOpen(true)}>
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage({
              id: 'builder.moduleeditor.generateduration',
              defaultMessage:
                'Dauer des Moduls schätzen und in Modulbeschreibung speichern.',
            })}
          >
            <AccessTimeIcon />
          </Tooltip>
        </IconButton>
        <DurationDialog
          open={isDurationDialogOpen}
          onFeedback={durationDialogOnFeedback}
        />
        <IconButton onClick={() => setIsPercentageDialogOpen(true)}>
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage({
              id: 'builder.moduleeditor.generatepercentageprogress',
              defaultMessage:
                'Prozentangaben für Chat-Fortschrtt automatisch berechnen.',
            })}
          >
            <PercentIcon />
          </Tooltip>
        </IconButton>
        <PercentageDialog
          open={isPercentageDialogOpen}
          onFeedback={percentageDialogOnFeedback}
        />
        <IconButton
          onClick={handleOpenMetadataEditorDialog}
          aria-label={intl.formatMessage(
            moduleEditorMessages.openMetaDataEditorDialog
          )}
        >
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage(
              moduleEditorMessages.openMetaDataEditorDialog
            )}
          >
            <SettingsIcon />
          </Tooltip>
        </IconButton>
        <MetadataEditor
          open={isMetadataDialogOpen}
          onClose={handleOpenMetadataEditorDialog}
          addOrUpdateMetadataTranslation={addOrUpdateMetaDataTranslation}
          updateModuleProps={updateModuleProps}
          updateModuleMetadataProps={updateModuleMetadataProps}
        />
        <ModulePreviewButton
          moduleid={module.moduleid}
          isLoading={moduleIsLoading}
          onsave={onModuleSave}
          stateMachineLocale={stateMachineLocale}
          disabled={module.buildergraph.length === 0}
        />
        <IconButton onClick={onModuleSave} disabled={moduleIsLoading}>
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage(moduleEditorMessages.save)}
          >
            <SaveIcon color="secondary" />
          </Tooltip>
        </IconButton>
        <Typography variant="caption">
          <FormattedMessage {...moduleEditorMessages.saved} /> {lastSaved}
        </Typography>
      </Grid>
      <Grid
        item
        xs={11}
        style={{
          paddingTop: '5px',
          paddingBottom: '5px',
          marginTop: '0px',
          maxHeight: '1vh',
        }}
      >
        <ModuleEditorPane
          saveModuleHandler={onModuleSave}
          command={command}
          updateViewport={updateViewport}
          viewPort={viewPort}
        />
      </Grid>
    </Grid>
  );
};
