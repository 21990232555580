import { Handle, NodeProps, Position } from 'reactflow';
import { NextButton } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';

/*
  Link Button react-flow node for module builder graph canvas
*/

export const LinkButtonNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <NextButton buttonText={translateKey(intl, payload.buttonText)} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nexthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
