import { Handle, NodeProps, Position } from 'reactflow';
import { MoodInput } from '@evoach/ui-components';

import { useGlobalStyles } from './base.node';

/*
  MoodInput react-flow node for module builder graph canvas
*/

export const MoodInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const payload = nodeProps.data.state.entry[0].payload;
  // note: the hanlde ids (like 'yeshandle') are used in hooks/useCreateModule=>mapStates
  // for recognizing the edges to this node and to create state transitions in the
  // statemachine

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <MoodInput saveResultTo={payload.saveResultTo} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="badhandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '15%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="neutralhandle"
        isConnectable={nodeProps.isConnectable}
        style={{ left: '50%' }}
        className={classes['react-flow__handle']}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="goodhandle"
        isConnectable={nodeProps.isConnectable}
        style={{ left: '85%' }}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
