import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.downloadButton.state.buttonText',
    defaultMessage: 'Datei herunterladen',
  },
});

/**
 * Download Button
 */
export const downloadButtonStateEntry: StateEntryProps = {
  version: 1,
  type: 'renderDownloadButton',
  temporary: false,
  payload: {
    buttonText: 'builder.nodes.downloadButton.state.buttonText',
    src: ' ',
    assetid: ' ', // the blank space is important, as empty strings ar enot recognized by intl
  },
  displayName: 'Download Button',
  nodeType: 'downloadButtonStateEntry',
  handleOutCount: 1,
};
