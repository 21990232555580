import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'paraphraseNodeStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kann mit Hilfe Künstlicher Intelligenz eine Eingabe paraphrasiert und in eine Variable geschrieben werden.',
  },
});

/**
 * The paraphrase-component is used to retrieve a paraphrased output for the input.
 * This is the state for paraphrasing the input of getValueFrom. The result is
 * put into saveResultType
 */
export const paraphraseNodeStateEntry: StateEntryProps = {
  version: 2,
  type: 'sendExternal',
  temporary: false,
  payload: {
    getValueFrom: '',
    saveResultTo: '',
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.PARAPHRASE,
    prompt: '',
  },
  getValueType: ReadWriteType.string,
  saveResultType: ReadWriteType.string,
  displayName: 'AI Paraphrasing',
  nodeType: 'paraphraseNodeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 1,
};
