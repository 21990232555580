import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';

import {
  generateCSV,
  generateCsvDownload,
  getAnalytics,
} from './analyticsHelper';

export const AdminStatsSubTab: React.FC = () => {
  const [analysisId, setAnalysisId] = useState<string>('');
  const [currentDownload, setCurrentDownload] = useState<string[] | undefined>(
    []
  );

  // generate a hidden download link and click to download file
  const downloadCsv = () => {
    generateCsvDownload(currentDownload);
  };

  useEffect(() => {
    setCurrentDownload(undefined);
    getAnalytics(analysisId).then((result: any) => {
      setCurrentDownload(generateCSV(result));
    });
  }, [analysisId]);

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12}>
        <Grid container spacing={3} direction="row">
          <Grid item xs={5}>
            <Button
              variant="contained"
              onClick={(_evt) => {
                setAnalysisId('cec1e2fc-b84c-4941-8e5a-c3e4a099ade7');
              }}
              color="secondary"
              style={{ marginTop: '10px' }}
            >
              Liste aller Benutzer und der Anzahl der Module / Templates, die
              sie erstellt haben.
            </Button>
            <Button
              variant="contained"
              onClick={(_evt) => {
                setAnalysisId('fdc1e3fc-1267-4941-8e5a-c3e4a099ade7');
              }}
              color="secondary"
              style={{ marginTop: '10px' }}
            >
              Auswertung aller Sessions incl. der Accounts, denen die
              zugehörigen Module gehören. Es werden auch Sessions von Modulen
              analysiert, die schon gelöscht wurden.
            </Button>
            <Button
              variant="contained"
              onClick={(_evt) => {
                setAnalysisId('9981e3fc-1267-4141-8e5a-c3e4cbf1ade7');
              }}
              color="secondary"
              style={{ marginTop: '10px' }}
            >
              Liste aller Module (ohne Templates!) inkl. Besitzer und Anzahl der
              Sessions, die damit erzeugt wurden.
            </Button>
            <Button
              variant="contained"
              onClick={(_evt) => {
                setAnalysisId('b36d1edd-e84f-4562-9862-2ba8054c88cf');
              }}
              color="secondary"
              style={{ marginTop: '10px' }}
            >
              Liste aller Accounts, die eine gültige kostenpflichtige
              Subscription haben
            </Button>
          </Grid>
          <Grid item xs={7}>
            {analysisId === 'cec1e2fc-b84c-4941-8e5a-c3e4a099ade7' && (
              <>
                Liste aller Benutzer und der Anzahl der Module / Templates, die
                sie erstellt haben.
                <p>
                  <Button onClick={downloadCsv}>Download CSV File</Button>
                </p>
              </>
            )}
            {analysisId === 'fdc1e3fc-1267-4941-8e5a-c3e4a099ade7' && (
              <>
                Analyse aller Sessions (kein Preview). Aggregation nach
                originalmoduleid
                <Button onClick={downloadCsv}>Download CSV File</Button>
              </>
            )}
            {analysisId === '9981e3fc-1267-4141-8e5a-c3e4cbf1ade7' && (
              <>
                Liste aller Module (ohne Templates!) inkl. Besitzer und Anzahl
                der Sessions, die damit erzeugt wurden
                <Button onClick={downloadCsv}>Download CSV File</Button>
              </>
            )}
            {analysisId === 'b36d1edd-e84f-4562-9862-2ba8054c88cf' && (
              <>
                Liste aller Accounts, die eine gültige kostenpflichtige
                Subscription haben. Abgelaufene Subscriptions werden nicht
                angezeigt. Nicht kostenpflichtige Subscriptions werden nicht
                angezeigt.
                <br />
                <Button onClick={downloadCsv}>Download CSV File</Button>
                <p>
                  SubscriptionTypes, die im CSV angezeigt werden: (BASIC = 2),
                  (GROW = 3), (PRO = 4), (STARTER = 5)
                </p>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
