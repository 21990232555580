import { CopyLink, evoachDefaultTheme } from '@evoach/ui-components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { authorizedDelete, authorizedPost } from '../../../api/authorizedApi';
import { useFetchProgramInstancePermissionsByProgramInstance } from '../../../api/program/useFetchProgramInstancePermissions';
import { ProgramInstancePermission } from '../../../entities';
import { useEnvironment } from '../../../environment/useEnvironment';

export interface ManageCoacheesDialogProps {
  open: boolean;
  onClose: Function;
  programInstanceId: string;
  programTitle: string;
}

export const ManageCoacheesDialog: React.FC<ManageCoacheesDialogProps> = ({
  open,
  onClose,
  programInstanceId,
  programTitle,
}) => {
  const intl = useIntl();
  const { playerBasePath } = useEnvironment();

  const { programInstancePermissions, refetch } =
    useFetchProgramInstancePermissionsByProgramInstance(programInstanceId);
  console.log(programInstancePermissions);
  //
  // is there already an invitation existing (expect only one although
  // it is an array)
  //
  const existingInvitation = useMemo(() => {
    if (!programInstancePermissions) return [];
    return programInstancePermissions.filter(
      (perm: ProgramInstancePermission) =>
        perm.invitationid !== null && perm.invitationid !== undefined
    );
  }, [programInstancePermissions]);

  //
  // filter all acceptedinvitations and print them below
  //
  const acceptedInvitations = useMemo(() => {
    if (!programInstancePermissions) return [];
    const inviIds: string[] = [];
    return programInstancePermissions.filter(
      (perm: ProgramInstancePermission) => {
        if (
          perm.invitinginvitationid !== null &&
          perm.invitinginvitationid !== undefined
        ) {
          if (inviIds.includes(perm.invitinginvitationid)) {
            return true;
          } else {
            inviIds.push(perm.invitinginvitationid);
            return true;
          }
        } else {
          return false;
        }
      }
    );
  }, [programInstancePermissions]);

  /**
   * create invitation link and refetch programInstancePermissions
   */
  const createInvitation = async () => {
    const invitURL = `/programinstance/${programInstanceId}/invitation`;

    const createShareLinkCall = authorizedPost(invitURL);
    await createShareLinkCall();

    // TODO error handling
    refetch();
  };

  const revokeAcceptedInvitation = async (accountId: string) => {
    if (
      window.confirm(
        intl.formatMessage({
          id: 'builder.coachingprogram.list.managecoachees.confirmrevoke',
          defaultMessage:
            'Bist du sicher, dass die Person aus dem Coaching Programm gelöscht werden soll?',
        })
      )
    ) {
      const permissionIdlist = programInstancePermissions.filter(
        (perm: ProgramInstancePermission) =>
          perm.account?.accountid === accountId &&
          perm.invitinginvitationid !== null &&
          perm.invitinginvitationid !== undefined
      );
      if (!permissionIdlist || permissionIdlist.length === 0) {
        return;
      }
      const permissionId = permissionIdlist[0].programinstancepermissionid;
      const revokeInviteURL = `/programinstance/${programInstanceId}/allpermissions/${permissionId}`;

      const revokeInviteCall = authorizedDelete(revokeInviteURL);
      await revokeInviteCall();

      refetch();
    }
  };

  if (!open) return <></>;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({
          id: 'builder.coachingprgram.list.managecoachees.title',
          defaultMessage: 'Teilnehmer verwalten',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {existingInvitation.length === 0 && (
            <Box display="flex" flexDirection="column">
              <Box>
                <Typography component="span">
                  {intl.formatMessage({
                    id: 'builder.coachingprgram.list.managecoachees.hint',
                    defaultMessage:
                      'Du hast bisher keinen Einladungslink für dieses Program erstellt und noch keine Einladungen verschickt.',
                  })}
                  <br />

                  {intl.formatMessage(
                    {
                      id: 'builder.coachingprgram.list.managecoachees.hint2',
                      defaultMessage:
                        'Erstelle eine Einladungslink für "{program}", den du an die Teilnehmer verschicken kannst.',
                    },
                    { program: programTitle }
                  )}
                </Typography>
              </Box>
              <Box>
                <Button onClick={createInvitation} sx={{ marginTop: '20px' }}>
                  {intl.formatMessage({
                    id: 'builder.coachingprgram.list.managecoachees.createinvitation',
                    defaultMessage: 'Einladungslink erstellen.',
                  })}
                </Button>
              </Box>
            </Box>
          )}

          {existingInvitation.length > 0 && (
            <>
              <Typography
                component="span"
                variant="subtitle2"
                style={{ marginTop: 15 }}
              >
                {intl.formatMessage({
                  id: 'builder.coachingprgram.list.managecoachees.existinginvit',
                  defaultMessage:
                    'Hier ist dein Einladungslink, den du an die Teilnehmer verteilen kannst. Jeder der den Link besitzt, kann am Programm teilnehmen.',
                })}
              </Typography>
              <Box sx={{ marginTop: '20px' }}>
                <CopyLink
                  buttonText={intl.formatMessage({
                    id: 'coachingprgram.list.managecoachees.copylink',
                    defaultMessage: 'Link kopieren',
                  })}
                  link={
                    playerBasePath +
                    `/programinvitation/${existingInvitation[0].invitationid}`
                  }
                />
              </Box>
              <Box sx={{ marginTop: '20px' }}>
                {acceptedInvitations.length === 0 && (
                  <Typography component="span">
                    {intl.formatMessage({
                      id: 'coachingprgram.list.managecoachees.nooneaccpetedsofar',
                      defaultMessage:
                        'Bisher hat noch niemand deine Einladung akzeptiert und nimmt am Programm teil. Sobald deine Einladug akzeptiert wurde, siehst du hier die Teilnehmer.',
                    })}
                  </Typography>
                )}
                {acceptedInvitations.length > 0 && (
                  <Typography
                    component="div"
                    sx={{ maxHeight: '400px', overflow: 'scroll' }}
                  >
                    {intl.formatMessage({
                      id: 'coachingprgram.list.managecoachees.someoneaccpeted',
                      defaultMessage:
                        'Bisher haben folgende Teilnehmer deine Einladung akzeptiert und nehmen am Programm teil:',
                    })}
                    {acceptedInvitations.map(
                      (
                        acceptedInvite: ProgramInstancePermission,
                        index: number
                      ) => (
                        <Box
                          sx={{ marginTop: '1em' }}
                          key={`invitkey${acceptedInvite.programinstancepermissionid}_${index}`}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                            }}
                          >
                            <span>{`${acceptedInvite.account.givenname} ${acceptedInvite.account.familyname} (${acceptedInvite.account.email})`}</span>
                            <Tooltip
                              placement="top"
                              arrow
                              title={intl.formatMessage({
                                id: 'coachingprogram.list.managecoachees.deletetooltip',
                                defaultMessage: 'Löschen',
                              })}
                            >
                              <DeleteIcon
                                sx={{
                                  cursor: 'pointer',
                                  color:
                                    evoachDefaultTheme.palette.secondary.main,
                                  marginLeft: '0.5em',
                                }}
                                color="secondary"
                                onClick={() =>
                                  revokeAcceptedInvitation(
                                    acceptedInvite.account.accountid
                                  )
                                }
                              />
                            </Tooltip>
                          </div>
                        </Box>
                      )
                    )}
                  </Typography>
                )}
              </Box>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {intl.formatMessage({
            id: 'builder.coachingprgram.list.managecoachees.closebutton',
            defaultMessage: 'Schließen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
