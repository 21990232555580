import { AudioEnum, FormattedInputEnum } from '@evoach/ui-components';
import { FormControl } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { EvoachMenuItem, EvoachSelect } from '../customMaterialComponents';
import { Description } from '../Description';
import { MultipleDisplayEnum, StateEntryProps } from '../nodes';
import {
  ExternalServiceEnum,
  ExternalServiceEnumNameMappings,
} from '../../entities/ExternalServicesTypes';
import { AccountContext, RoleEnum } from '../../account';

/*
  ! ElementPropertyPayloadSelect

  Select field (e.g., for enum lists)
*/
interface ElementPropertyPayloadSelectProps {
  payloadKey: string;
  entry: StateEntryProps;
  updatePayloadValue: Function;
}

const KeysToArray = (enumlist: any): string[] => {
  return Object.keys(enumlist).map((key) => enumlist[key]);
};

export const ElementPropertyPayloadSelect: React.FC<
  ElementPropertyPayloadSelectProps
> = ({ payloadKey, entry, updatePayloadValue }) => {
  const [valueFrom, setValueFrom] = React.useState<string>(
    entry.payload[payloadKey] as string
  );

  const intl = useIntl();
  const { hasRole } = useContext(AccountContext);

  const selectableValues: string[] = useMemo(() => {
    payloadKey === 'bubbleType'
      ? KeysToArray(MultipleDisplayEnum)
      : payloadKey === 'formatType'
      ? KeysToArray(FormattedInputEnum)
      : KeysToArray(AudioEnum);

    switch (payloadKey) {
      case 'bubbleType':
        return KeysToArray(MultipleDisplayEnum);
      case 'formatType':
        return KeysToArray(FormattedInputEnum);
      case 'audioImage':
        return KeysToArray(AudioEnum);
      case 'externalService':
        // selection for evoach admin only
        if (hasRole(RoleEnum.EVOACHADMIN)) {
          setValueFrom(
            ExternalServiceEnumNameMappings[entry.payload[payloadKey] ?? '']
          );
          return KeysToArray(ExternalServiceEnum).map(
            (key) => ExternalServiceEnumNameMappings[key]
          );
        } else {
          return [];
        }
      default:
        return [];
    }
  }, [entry.payload, hasRole, payloadKey]);

  const handleChange = (value: string) => {
    setValueFrom(value);
    updatePayloadValue(value, payloadKey, entry);
  };

  // no selectable values, no select field
  if (selectableValues.length < 1) {
    if (payloadKey === 'externalService') {
      return (
        <>
          {intl.formatMessage(
            {
              id: 'builder.elementpropertypayloadselect.aimodel.hintz',
              defaultMessage: 'Als Sprachmodell wird {model} verwendet.',
            },
            {
              model: (
                <strong>
                  {
                    ExternalServiceEnumNameMappings[
                      entry.payload[payloadKey] ?? ''
                    ]
                  }
                </strong>
              ),
            }
          )}
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <FormControl
      disabled={!selectableValues || selectableValues.length < 1}
      sx={{ width: '95%' }}
    >
      <Description nodetype={entry.nodeType} propname={payloadKey} />

      <EvoachSelect
        sx={{ marginTop: '10px' }}
        value={valueFrom}
        onChange={(event) => handleChange(event.target.value as string)}
      >
        {selectableValues.map((selectableValue, index) => (
          <EvoachMenuItem
            key={`SelectableValue${index}`}
            value={selectableValue}
          >
            {selectableValue}
          </EvoachMenuItem>
        ))}
      </EvoachSelect>
    </FormControl>
  );
};
