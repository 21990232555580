import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.timeInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

export const timeInputStateEntry: StateEntryProps = {
  type: 'renderTimeInput',
  temporary: true,
  payload: {
    time: new Date().toUTCString(),
    buttonText: 'builder.nodes.timeInput.state.buttonText',
    saveResultTo: 'time_result',
  },
  saveResultType: ReadWriteType.time,
  displayName: 'Time Input',
  nodeType: 'timeInputStateEntry',
  handleOutCount: 1,
};
