import { useContext } from 'react';
import { useQuery } from 'react-query';

import { TranslationContext } from '../../intl/TranslationContext';
import { authorizedGet } from '../authorizedApi';

export const useFetchInvitations = () => {
  const { locale } = useContext(TranslationContext);

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    'invitationsList',
    async () => {
      const getInvitationList = authorizedGet(`/invitation?language=${locale}`);
      const response = await getInvitationList();
      return await response.json();
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    invitations: data,
    refetch,
  };
};
