import { FormControl, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { EvoachSelect } from '../../customMaterialComponents';

export interface TranslationTypeSelectionProps {
  onTranslationTypeChange: Function;
  translationType: string;
}

export const TranslationTypeSelection: React.FC<
  TranslationTypeSelectionProps
> = ({ onTranslationTypeChange, translationType }) => {
  const intl = useIntl();

  const [currentType, setcurrentType] = useState<string>(
    translationType ?? 'meta'
  );

  useEffect(() => {
    if (translationType === undefined) return;
    setcurrentType(translationType);
  }, [translationType]);

  const handleChange = (newtype: string) => {
    setcurrentType(newtype);
    onTranslationTypeChange(newtype);
  };

  return (
    <div
      style={{
        display: 'inline-flex',
        verticalAlign: 'middle',
        justifyContent: 'left',
        marginLeft: '20px',
      }}
    >
      <FormControl>
        <EvoachSelect
          value={currentType}
          onChange={(event) => handleChange(event.target.value as string)}
          sx={{ height: '30px' }}
        >
          <MenuItem key="transrtypoe_meta" value="meta">
            {intl.formatMessage({
              id: 'builder.translationeditor.choosetranstype.metadata',
              defaultMessage: 'Chatbot-Beschreibung',
            })}
          </MenuItem>
          <MenuItem key="transrtypoe_chatbot" value="chatbot">
            {intl.formatMessage({
              id: 'builder.translationeditor.choosetranstype.chatbot',
              defaultMessage: 'Chatbot-Texte',
            })}
          </MenuItem>
        </EvoachSelect>
      </FormControl>
    </div>
  );
};
