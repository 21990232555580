import React, { useContext } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ModuleRouteParams } from '../routing/routes';
import { useFetchModuleSummary } from '../api/module/useFetchModule';
import { TranslationSidebar } from '../components/reception/translation/TranslationSidebar';
import { TranslationEditor } from '../components/reception/translation/TranslationEditor';
import {
  getCheck,
  isQaCheckFine,
} from '../components/reception/analytics/qaTools';
import { WindowContext } from '../components/window/WindowContext';

//import { ModuleBuilderWrapper } from '../components/ModuleBuilderWrapper';

const translPageMessages = defineMessages({
  error: {
    id: 'builder.pages.translation.error',
    defaultMessage: 'Error: ',
  },
  loading: {
    id: 'builder.router.approutertrans.loading',
    defaultMessage: 'Übersetzungs-Management wird geladen ...',
  },
});

export const TranslationPage: React.FC = () => {
  const { moduleId, moduleLanguage } = useParams<ModuleRouteParams>();
  const { isLoading, isError, error, module, refetch } = useFetchModuleSummary(
    moduleId,
    moduleLanguage
  );

  const { alert } = useContext(WindowContext);

  const intl = useIntl();

  /**
   * check translation QA of a module id there is more than one language
   * available. Set languages if available in check result
   * @returns
   */
  const performQACheck = async () => {
    // module not set? return.
    if (!module || !module.moduleid) return;

    // only check if there is more than one language
    if (module.metadata.moduleLanguages.length < 2) return;

    // there are at least two languages; perform backend check
    const checkResult = await getCheck(module.moduleid);

    if (!isQaCheckFine(checkResult)) {
      /*
      checkResult looks like this:
      {
        "moduleDefaultLanguage": "EN",
        "moduleLanguages": [
            "EN",
            "FR"
        ],
        "qualityMetrics": {
            "EN": {
                "missingKeys": []
            },
            "FR": {
                "missingKeys": []
            }
        },
        "sameTextKeys": {
            "oz8m16h0.message": [
                "EN",
                "FR"
            ],
            "cbdtutfj.message": [
                "EN",
                "FR"
            ]
        }
      }
      */
      if (Object.keys(checkResult.sameTextKeys).length > 0) {
        alert(
          intl.formatMessage({
            id: 'builder.router.approutertrans.untranslated',
            defaultMessage: 'Möglicherweise sind einige Texte nicht übersetzt.',
          })
        );
      } else {
        alert(
          intl.formatMessage({
            id: 'builder.router.approutertrans.otherissues',
            defaultMessage:
              'Nicht alle Sprachen scheinen fehlerfrei zu sein. Bitte überprüfe deine Übersetzungen.',
          })
        );
      }
    } else {
      alert(
        intl.formatMessage({
          id: 'builder.router.approutertrans.noerrorr',
          defaultMessage: 'Es wurden keine Probleme gefunden.',
        })
      );
    }
  };

  return (
    <div>
      {isError && (
        <Typography variant="body2">
          <FormattedMessage {...translPageMessages.error} />
          {error?.message}
        </Typography>
      )}
      {isLoading && (
        <Typography variant="body2">
          <FormattedMessage {...translPageMessages.loading} />

          <CircularProgress />
        </Typography>
      )}
      {!isLoading && !isError && (
        <Grid
          container
          spacing={2}
          direction="row"
          alignContent="center"
          style={{
            marginLeft: '-15px',
          }}
        >
          <Grid
            item
            xs={3}
            style={{
              paddingLeft: '5px',
              paddingTop: '5px',
            }}
          >
            <TranslationSidebar
              module={module}
              refetchModule={refetch}
              performQACheck={performQACheck}
            />
          </Grid>
          <Grid
            item
            xs={9}
            style={{
              paddingLeft: '5px',
              paddingTop: '5px',
              paddingRight: '0px',
            }}
          >
            <TranslationEditor module={module} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
