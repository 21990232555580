import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  placeholderTexts: {
    id: 'builder.nodes.multipleInput.state.placeholderTexts',
    defaultMessage: 'Schreib hier… ',
  },
  buttonText: {
    id: 'builder.nodes.multipleInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
  message: {
    id: 'builder.nodes.multipleInput.state.message',
    defaultMessage: 'Nachricht, die vor dem Multiple Input erscheint',
  },
});

/**
 *
 * ! important
 *
 * this is a composite object that is not properly named!"
 * it comprises a CoachMessage and a MultipleInput"
 * If you want to edit a real Multiple Input, go to
 * MultipleInputSingle
 */

export const multipleInputStateEntry: StateEntryProps = {
  type: 'renderMultipleInput',
  temporary: true,
  payload: {
    placeholderTexts: ['builder.nodes.multipleInput.state.placeholderTexts'],
    buttonText: 'builder.nodes.multipleInput.state.buttonText',
    saveResultTo: 'snippets',
    getValueFrom: '',
    minItems: 1,
    maxItems: 10,
    message: 'builder.nodes.multipleInput.state.message',
  },
  saveResultType: ReadWriteType.stringArray,
  getValueType: ReadWriteType.stringArray,
  displayName: 'Multiple Input',
  nodeType: 'multipleInputStateEntry',
  handleOutCount: 1,
};
