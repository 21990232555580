import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  TextField,
  IconButton,
  Tooltip,
  Switch,
} from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useIntl } from 'react-intl';

import {
  isSaveResultToKey,
  StateEntryProps,
  isValidVariableName,
} from '../../nodes';
import { ModuleGlobalVarProps } from '../../../entities';
import { ModuleEditorContext } from '../../ModuleEditor/ModuleEditorContext';
import { WindowContext } from '../../window/WindowContext';
import { useFetchProvidedGlobalVariables } from '../../../api/module/useFetchProvidedGlobalVariables';
import { AccountContext } from '../../../account';
import { FeatureEnum } from '../../../entities/subscriptionTypes';

import { PropertyHeaderLine } from './PropertyHeadLine';

/**
 * Variable edit container for all SaveResultTo keys
 */
export interface VariableEditProps {
  entry: StateEntryProps;
  onRefactor?: Function;
  onFocus?: Function;
}

export const VariableEdit: React.FC<VariableEditProps> = ({
  entry,
  onRefactor,
  onFocus,
}) => {
  return (
    <Stack>
      {Object.keys(entry.payload).find(isSaveResultToKey) && (
        <Box>
          <PropertyHeaderLine header="Variable Name" />
          <VariableTextfield
            entry={entry}
            onRefactor={onRefactor}
            onFocus={onFocus}
          />
        </Box>
      )}
    </Stack>
  );
};

/**
 * VariableTextfield for actually entering the value.
 * Provieds functionality for re-factoring and defintion of
 * global variables.
 */
interface VariableTextfieldProps {
  entry: StateEntryProps;
  onRefactor?: Function;
  onFocus?: Function;
}
const notTranslatedOrEmptyString = (value: string | any) => {
  if (value && isValidVariableName(value)) {
    return value;
  }
  return '';
};
const VariableTextfield: React.FC<VariableTextfieldProps> = ({
  entry,
  onRefactor,
  onFocus,
}) => {
  const intl = useIntl();
  const { updateElements, nodes, edges, module, saveModule } =
    useContext(ModuleEditorContext);

  const { hasFeature } = useContext(AccountContext);

  // all global variables that are provided also by other chatbots
  const { globalVariables } = useFetchProvidedGlobalVariables(module?.moduleid);

  // all global variables provided by the current module
  const providedGlobalVariablesOfCurrentModule: ModuleGlobalVarProps[] =
    module.providedglobalvariables;

  const { alert } = useContext(WindowContext);

  const initialValue = entry.payload['saveResultTo'];

  const [elementValue, setElementValue] = useState<string>(
    notTranslatedOrEmptyString(initialValue)
  );

  useEffect(() => {
    setElementValue(notTranslatedOrEmptyString(initialValue));
  }, [initialValue]);

  // set to checked if included in gloabLvariables = providedglobalvariables
  const [globalVarChecked, setGlobalVarChecked] = useState<boolean>(
    providedGlobalVariablesOfCurrentModule
      .map((g: ModuleGlobalVarProps) => g.name)
      .includes(notTranslatedOrEmptyString(initialValue))
  );

  //
  // special function for editing variables in EnryList/VariableInput
  // for PROD-1856 => global variables
  //
  const updateGlobalVariables = (
    propname: string,
    variableinfo: ModuleGlobalVarProps,
    type: 'add' | 'remove'
  ) => {
    if (type === 'add') {
      if (module[propname]) {
        (module[propname] as ModuleGlobalVarProps[]).push(variableinfo);
      } else {
        module[propname] = [variableinfo];
      }
    } else {
      module[propname] = (module[propname] as ModuleGlobalVarProps[]).filter(
        (v: ModuleGlobalVarProps) => v.name !== variableinfo.name
      );
    }

    saveModule(module);
  };

  /**
   * update a saveResultTo value with a new string
   *
   * @param {string} newSaveResultToValue
   */
  const updateSaveResultTo = (newSaveResultToValue: string) => {
    const payload = entry.payload;
    if (payload) {
      // if value is set, take it, if not use elementId as value
      // this is to always have a variable for echos
      if (newSaveResultToValue) {
        payload['saveResultTo'] = newSaveResultToValue;
      } else {
        payload['saveResultTo'] = '';
      }

      // prepare nodes to be changed if we are in a loop
      //let newnodes = nodes;

      // update elements in state , false = not part of undo operation
      updateElements([...nodes, ...edges], false);
    }
  };

  const updateElementValue = (newValue: string) => {
    updateSaveResultTo(newValue);
    setElementValue(newValue);
  };

  const updateGlobals = (addOrRemove: boolean) => {
    // PROD-1858 - check whether this variable already exists as global
    // variable and for another nodeType or saveResultToType
    // get all variables that are duplicates with different saveResultType
    const globalVarsWithDifferentTypeAndOfSameName = globalVariables.filter(
      (varinfo: ModuleGlobalVarProps) => {
        return (
          varinfo.name === elementValue &&
          varinfo.saveResultType !== entry.saveResultType
        );
      }
    );
    // if duplicates exists, show error message and exit
    if (globalVarsWithDifferentTypeAndOfSameName.length > 0) {
      alert(
        intl.formatMessage({
          id: 'builder.editor.variablesinput.globalchange.duplicatename.warning',
          defaultMessage:
            'Es existiert bereits eine globale Variable mit diesem Namen und einem anderen Datentyp! Daher kann diese Variable nicht Chatbot-übergreifend verfügbar gemacht werden. Bitte wähle einen anderen Namen für die Variable.',
        })
      );
      return;
    }

    // everyting is fine? no duplicate? Then create new global var
    updateGlobalVariables(
      'providedglobalvariables',
      {
        name: elementValue,
        nodeType: entry.nodeType,
        saveResultType: entry.saveResultType,
      } as ModuleGlobalVarProps,
      addOrRemove ? 'add' : 'remove'
    );
    setGlobalVarChecked(addOrRemove);
    if (!addOrRemove) {
      alert(
        intl.formatMessage({
          id: 'builder.editor.variablesinput.globalchange.warning',
          defaultMessage:
            'Bitte denke daran, ggf. die Chatbots anzupassen, in denen diese Variable übergreifend genutzt wird.',
        })
      );
    }
  };

  const isError = elementValue.indexOf(' ') > -1;
  return (
    <>
      <Tooltip
        title={
          isError
            ? intl.formatMessage({
                id: 'builder.editor.variablesinput.spaces.tooltip',
                defaultMessage:
                  'Variablennamen sollten keine Leerzeichen enthalten, weil dies in zukünftigen Komponenten zu Problemen führen kann.',
              })
            : globalVarChecked
            ? intl.formatMessage({
                id: 'builder.editor.variablesinput.globalvars.tooltip',
                defaultMessage:
                  'Wenn eine Variable Chatbot-übergreifend genutzt wird, kannst du den Namen nicht ändern. Bitte deaktiviere zuerst die Chatbot-übergreifende Nutzung mit dem Schalter unter dem Eingabefeld. ',
              })
            : intl.formatMessage({
                id: 'builder.editor.variablesinput.default.tooltip',
                defaultMessage: 'Hier kannst du den Variablennamen ändern.',
              })
        }
        arrow
        placement="top"
      >
        <TextField
          disabled={globalVarChecked}
          id="donotcatchpasteevent_variable"
          key="saveresultto"
          value={elementValue}
          onChange={(evt) => updateElementValue(evt.target.value)}
          style={{ width: '80%' }}
          onFocus={() => {
            if (onFocus) onFocus();
          }}
          error={isError}
        />
      </Tooltip>
      <Tooltip
        title={intl.formatMessage({
          id: 'builder.editor.variablesinput.variablerefactor.tooltip',
          defaultMessage:
            'Variablennamen in allen Komponenten anpassen, in der diese Variable verwendet wird.',
        })}
      >
        <IconButton
          onClick={() => {
            if (onRefactor) onRefactor();
          }}
        >
          <AutoFixHighIcon />
        </IconButton>
      </Tooltip>

      <Switch
        color="secondary"
        disabled={!hasFeature(FeatureEnum.GLOBALVARS_B)}
        checked={globalVarChecked}
        onChange={(e: any) => {
          updateGlobals(e.target.checked);
        }}
      />
      <Tooltip
        title={
          !hasFeature(FeatureEnum.GLOBALVARS_B)
            ? intl.formatMessage({
                id: 'builder.editor.variablesinput.globalvar.tooltip.nofeature',
                defaultMessage:
                  'Diese Funktion ist nur einem höheren Abo-Modell verfügbar.',
              })
            : intl.formatMessage({
                id: 'builder.editor.variablesinput.globalvar.tooltip',
                defaultMessage:
                  'Du kannst diese Variable Chatbot-übergreifend verfügbar machen. Damit kannst du Inhalte aus diesem Chatbot auch in deinen anderen Chatbots verwenden. Der Wert ist natürlich nur in deinen Chatbots und für deinen Coachee sichtbar.',
              })
        }
        arrow
        placement="top"
      >
        <span>
          {intl.formatMessage({
            id: 'builder.editor.variablesinput.globalvar.hint',
            defaultMessage: 'Chatbot-übergreifend verfügbar',
          })}
        </span>
      </Tooltip>
    </>
  );
};
