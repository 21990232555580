import { CardImageEnum } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { SupportedModuleLocales } from '../intl/SupportedLocales';

import { Account } from './Account';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * entity Program in DB
 *
 */
export interface Program {
  [index: string]:
    | number
    | string
    | boolean
    | Date
    | Account
    | ProgramProps
    | ProgramPermission[]
    | string[]
    | ProgramTypeEnum;

  /**
   * pid is the internal DB id of a program
   */
  pid: number;
  /**
   * programid is the public id (UUID) used in links
   */
  programid: string;
  tscreated: Date;
  tsupdated: Date;
  /**
   * the program is a JSON in DB and is described by ProgramProps internally
   */
  program: ProgramProps;
  /**
   * DB FK to account to whom the program belongs to
   */
  account: Account;
  /**
   * list of permissions for this program
   */
  permissions: ProgramPermission[];
  /**
   *  describes type of a program
   *  1 = program without sub-modules (default) (linear)
   *  2 = program with sub-modules (linear)
   */
  programtype: ProgramTypeEnum;
  /**
   * if programtype === 1, programmmodules = [], otherwise it contains
   * a list of programids that are used as sub-programs
   */
  programmodules: string[];
  /**
   * is true if this program is created as sub-program of another program,
   * false otherweise
   */
  issubprogram: boolean;
}

/**
 * type of a program - introduced to distinguish programs and sub-programs
 */
export enum ProgramTypeEnum {
  PROGRAM_WITHOUT_SUBMODULES = 1,
  PROGRAM_WITH_SUBMODULES = 2,
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * type definition for programs permissions
 */
export interface ProgramPermission {
  /**
   * uuid
   */
  programpermissionid: string;
  /**
   * user can edit program
   */
  CoachPermission_Edit: boolean;
  /**
   * user owns program
   */
  owns: boolean;
  /**
   * inviation uuid for invitation links
   */
  invitationid: string | null;
  /**
   * uuid of original invitation
   */
  invitinginvitationid: string | null;
  /**
   * linked account to whom this permission belongs to.
   */
  account: Account;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * type definition for programs and program instances
 */
export interface ProgramProps {
  [index: string]:
    | number
    | string
    | Date
    | boolean
    | ProgramImageEnum
    | ProgramModule[]
    | undefined
    | string[];

  /**
   * title of a program or program instance
   */
  title: string;
  /**
   * description of a program / program instance. May be localized! Refer to optional "language" prop
   */
  description: string;
  /**
   * image to be displayed in a card view of the program (instance). Can be of Type CreatorImageEnum or CardImageEnum
   */
  image?: ProgramImageEnum;
  /**
   * duration as string to enable "manual"/textual description of a duration for a program, something like '6 months'
   */
  duration: string;
  /**
   * array of {ProgramModule} modules within the program. May be empty!
   */
  modules: ProgramModule[];
  /**
   * startDate is only set for program instances. Programs are an abstract definitions and do not have an explicit start date.
   */
  startDate?: Date; // for program instances only!
  /**
   * the language is of type SupportedModuleLocales. Each program has only one translation. If you want
   * to provide a program in several languages, you have to duplicate the program and translate all texts in its properties
   * manually. There is no such thing like ProgramTranslations in the backend (as we have for modules for instance).
   * Duplicated programs have no reference to each other and work as independent programs! That means that you can't
   * get a list of all languages of a program but only a list of programs with languages.
   */
  language: SupportedModuleLocales;
  /**
   * labels of a program set by the coach
   */
  labels?: string[];
  /**
   * external URL to an image that is displayed in the ObjectCard of a program
   */
  src?: string;
  /**
   * assetid of an image that is displayed in the ObjectCard of a program. Has to
   * be resolved  when loading a module and before displaying.
   */
  assetid?: string;
  /**
   * depending on what image, src and assetid contains, the resolvedsrc is set.
   * Priority: assetid before src before image
   */
  resolvedsrc?: string;
  /**
   * internal number that is used to sort a list of programs / instances on the screen
   */
  ordernumber?: number;
  /**
   * assetid of key visual
   */
  keyvisualassetid?: string;
  /**
   * external src url of key visual
   */
  keyvisualsrc?: string;
  /**
   * position of key visual
   */
  keyvisualposition?: KeyVisualPositionEnum;
  /**
   * position of key visual
   */
  keyvisuallinktext?: string;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 *
 */
export interface ProgramModule {
  [index: string]:
    | number
    | string
    | Date
    | boolean
    | ProgramImageEnum
    | ProgramModuleAction[]
    | undefined;

  /**
   * id is used to order the sequence of the modules
   */
  id: number;
  /**
   * ??? do we need header ???
   */
  header: string;
  title: string;
  description: string;
  /**
   * duration as string to enable "manual"/textual description of a duration
   */
  duration: string;
  /**
   * date offset defines the offset of this module as a number of days after the start of the program (instance).
   * Used in programs, copied to program instances. Stays there for reference. In instances, startDate prop is set.
   */
  dateOffset: number; // offset in days
  /**
   * defines the explicit start date of a module - it can be derived by startDate of the program (instance) and the
   * dateOffset prop. Nevertheless, it can be manually edited in Creator to adapt automatically calcualted dates that
   * fall into holidays, to weekends, etc.
   */
  startDate?: Date; // for program instances only!
  /**
   * image to be dispayed in cards / module lists when displaying a program
   */
  image: ProgramImageEnum;
  /**
   * actions that are associated with that program. For programs, there may only be action types, for instances
   * there may also be action links.
   *
   * - the first element in the array, is considered the default module action, which is always displayed on the Module
   * - all consecutive elements are considered ressources, which are shown in a different section of the Module
   */
  actions: ProgramModuleAction[];
  /**
   * is used to group program modules. All modules with the same group string, will be displayed together
   */
  group?: string;
  /**
   * external URL to an image that is displayed in the ObjectCard of a program module
   */
  src?: string;
  /**
   * assetid of an image that is displayed in the ObjectCard of a program module.
   * Has to be resolved  when loading a module and before displaying.
   */
  assetid?: string;
  /**
   * depending on what image, src and assetid contains, the resolvedsrc is set.
   * Priority: assetid before src before image
   */
  resolvedsrc?: string;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * Program module actions define all the actions a coachee has to perform within a module of a given program (instance)
 */
export interface ProgramModuleAction {
  [index: string]: string | ProgramModuleActionLinkTypeEnum | undefined;

  /**
   * Links are provided for instances, only. For a generic program, you don't
   * have to provide concrete Zoom links etc..
   */
  link?: string;
  /**
   * Link types may be used in programs and instances. For programs, they may
   * indicate what type of action will be required when joining an instance
   * without actually linking an action to it.
   */
  linkType: ProgramModuleActionLinkTypeEnum; // this may be interesting for programs, too
  /**
   * If linkType is chatbot, this prop contains the moduleid of the module to
   * be started.
   */
  moduleid?: string;
  /**
   * If linkType is an uploadable document, this prop contains the assetid of
   * the document.
   */
  assetid?: string;
  /**
   * In addition to a link or a selected module, we introduce a textual
   * description of an action
   */
  description?: string;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * this ENUM defines the different actions. We can use this type to render a proper icon in the action part.
 *
 * It is important that the keys of the ENUM are exactly the same as the values because we need that for selection lists.
 */
export enum ProgramModuleActionLinkTypeEnum {
  VIDEOCALL = 'VIDEOCALL',
  PERSONALMEETING = 'PERSONALMEETING',
  CHATBOT = 'CHATBOT',
  WORKSHEET = 'WORKSHEET',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  ARTICLE = 'ARTICLE',
  VIDEO = 'WATCHVIDEO',
  AUDIO = 'LISTENAUDIO',
  COMMUNITY = 'COMMUNITY',
  WHITEBOARD = 'WHITEBOARD',
  FACE2FACE = 'PERSONALMEETING',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  SLACK = 'SLACK',
  PDF = 'PDF',
  ZIP = 'ZIP',
  PRESENTATION = 'PRESENTATION',
  LINKEDIN = 'LINKEDIN',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  SKYPE = 'SKYPE',
  MSPOWERPOINT = 'MSPOWERPOINT',
  MSWORD = 'MSWORD',
  MSEXCEL = 'MSEXCEL',
  ZOOM = 'ZOOM',
  GOOGLESHEETS = 'GOOGLESHEETS',
  GOOGLEDOCS = 'GOOGLEDOCS',
  MSTEAMS = 'MSTEAMS',
}

// defines the position of the key visual
export enum KeyVisualPositionEnum {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

// add translations for the enum to display this stuff as text
export const programModuleActionMessages = defineMessages({
  download: {
    id: 'builder.programs.actionedit.DOWNLOAD',
    defaultMessage: 'Download bearbeiten',
  },
  WORKSHEET: {
    id: 'builder.programs.actionedit.WORKSHEET',
    defaultMessage: 'Arbeitsblatt',
  },
  QUESTIONNAIRE: {
    id: 'builder.programs.actionedit.QUESTIONNAIRE',
    defaultMessage: 'Fragebogen',
  },
  ARTICLE: {
    id: 'builder.programs.actionedit.ARTICLE',
    defaultMessage: 'Artikel',
  },
  zoom: {
    id: 'builder.programs.actionedit.ZOOMSESSION',
    defaultMessage: 'Zoom Session',
  },
  video: {
    id: 'builder.programs.actionedit.VIDEOCALL',
    defaultMessage: 'Video Call',
  },
  FACE2FACE: {
    id: 'builder.programs.actionedit.FACE2FACE',
    defaultMessage: 'Persönliches Treffen',
  },
  PERSONALMEETING: {
    id: 'builder.programs.actionedit.PERSONALMEETING',
    defaultMessage: 'Persönliches Treffen',
  },
  CHATBOT: {
    id: 'builder.programs.actionedit.CHATBOT',
    defaultMessage: 'Coaching Chatbot',
  },
  VIDEO: {
    id: 'builder.programs.actionedit.VIDEO',
    defaultMessage: 'Video anschauen',
  },
  AUDIO: {
    id: 'builder.programs.actionedit.AUDIO',
    defaultMessage: 'Audio anhören',
  },
  COMMUNITY: {
    id: 'builder.programs.actionedit.COMMUNITY',
    defaultMessage: 'Community',
  },
  WHITEBOARD: {
    id: 'builder.programs.actionedit.WHITEBOARD',
    defaultMessage: 'Whiteboard',
  },
  YOUTUBE: {
    id: 'builder.programs.actionedit.YOUTUBE',
    defaultMessage: 'YouTube',
  },
  TWITTER: {
    id: 'builder.programs.actionedit.TWITTER',
    defaultMessage: 'Twitter',
  },
  SLACK: {
    id: 'builder.programs.actionedit.SLACK',
    defaultMessage: 'Slack',
  },
  PDF: {
    id: 'builder.programs.actionedit.PDF',
    defaultMessage: 'Pdf',
  },
  ZIP: {
    id: 'builder.programs.actionedit.ZIP',
    defaultMessage: 'Zip Datei',
  },
  PRESENTATION: {
    id: 'builder.programs.actionedit.PRESENTATION',
    defaultMessage: 'Presentation',
  },
  LINKEDIN: {
    id: 'builder.programs.actionedit.LINKEDIN',
    defaultMessage: 'LinkedIn',
  },
  FACEBOOK: {
    id: 'builder.programs.actionedit.FACEBOOK',
    defaultMessage: 'Facebook',
  },
  INSTAGRAM: {
    id: 'builder.programs.actionedit.INSTAGRAM',
    defaultMessage: 'Instagram',
  },
  SKYPE: {
    id: 'builder.programs.actionedit.SKYPE',
    defaultMessage: 'Skype',
  },
  MSPOWERPOINT: {
    id: 'builder.programs.actionedit.MSPOWERPOINT',
    defaultMessage: 'Microsoft PowerPoint',
  },
  MSWORD: {
    id: 'builder.programs.actionedit.MSWORD',
    defaultMessage: 'Microsoft Word',
  },
  MSEXCEL: {
    id: 'builder.programs.actionedit.MSEXCEL',
    defaultMessage: 'Microsoft Excel',
  },
  ZOOM: {
    id: 'builder.programs.actionedit.ZOOM',
    defaultMessage: 'Zoom Meeting',
  },
  GOOGLESHEETS: {
    id: 'builder.programs.actionedit.GOOGLESHEETS',
    defaultMessage: 'Google Sheets',
  },
  GOOGLEDOCS: {
    id: 'builder.programs.actionedit.GOOGLEDOCS',
    defaultMessage: 'Google Docs',
  },
  MSTEAMS: {
    id: 'builder.programs.actionedit.MSTEAMS',
    defaultMessage: 'Microsoft Team',
  },
});

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * if we intend to use images for icons that are not part of UI-components, we have to define them in creator.
 * This enum contains all images/icons that are not part of UI-components CardImageEnum but will be part of
 * ProgramImageEnum.
 */
export enum CreatorImageEnum {
  ZOOM = 'zoom',
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * ProgramImageEnum is a combination of the two image enums that can be used to display icons
 */
export type ProgramImageEnum = CardImageEnum; // CreatorImageEnum

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * initial demo program to show the structure
 */
export const initialProgramTemplate: ProgramProps = {
  title: 'Demo Program',
  description: 'Initial demo program',
  image: CardImageEnum.acceptance,
  language: SupportedModuleLocales.EN,
  duration: '6 months',
  modules: [
    {
      id: 0,
      header: 'Module header',
      title: 'Module title',
      description: 'Module description',
      duration: '1 hour',
      dateOffset: 0,
      image: CardImageEnum.balloons,
      actions: [
        {
          link: 'https://jumptozoom',
          linkType: ProgramModuleActionLinkTypeEnum.VIDEOCALL,
          description: 'Our Zoom Session',
        },
      ],
    },
  ],
};

// default texts when a new program is created
export const initialProgramDefaultMessages = defineMessages({
  t1: {
    id: 'builder.programs.emptyprogram.description',
    defaultMessage: 'Beschreibung des Programms',
  },
  t2: {
    id: 'builder.programs.emptyprogram.title',
    defaultMessage: 'Programmtitel',
  },
  t3: {
    id: 'builder.programs.emptyprogram.duration',
    defaultMessage: 'Dauer des Programms',
  },
  t4: {
    id: 'builder.programs.emptyprogram.modtitle',
    defaultMessage: 'Modultitel',
  },
  t5: {
    id: 'builder.programs.emptyprogram.moddesc',
    defaultMessage: 'Beschreibung des Moduls',
  },
  t6: {
    id: 'builder.programs.emptyprogram.moddur',
    defaultMessage: 'Dauer des Moduls',
  },
  t7: {
    id: 'builder.programs.emptyprogram.actiondesc',
    defaultMessage: 'Unsere Zoom Session',
  },
});
