import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { NodeDataProps } from '../nodes';
import { useQueryStatsForComponent } from '../../api/analytics/useQueryStatsForComponent';

export interface AnalyticsDisplayProps {
  open: boolean;
  onClose: () => void;
  value: NodeDataProps;
  moduleid: string;
}
export const AnalyticsDisplay: React.FC<AnalyticsDisplayProps> = ({
  open,
  onClose,
  value,
  moduleid,
}) => {
  const intl = useIntl();

  const { statistics } = useQueryStatsForComponent(
    moduleid,
    value?.state?.stateKey
  );

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.analyticsdisplay.title',
          defaultMessage: 'Nutzungsstatistiken für Komponente',
        })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: '10px' }}>
          <Typography component="span">
            {intl.formatMessage({
              id: 'builder.analyticsdisplay.desc0',
              defaultMessage: 'Hinweise zur Statistik:',
            })}
            <ul>
              <li>
                {intl.formatMessage({
                  id: 'builder.analyticsdisplay.desc1',
                  defaultMessage:
                    'Die Statistik für die Komponente gilt nur für Chatbot-Sessions, die nach August 2023 durchgeführt wurden. Für frühere Chatbot-Sessions sind keine Daten für die Komponente vorhanden.',
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: 'builder.programs.analyticsdisplay.desc2',
                  defaultMessage:
                    'Die Angaben sind Echtzeit und können sich entsprechend zwischen zwei Aufrufen dieses Dialoges ändern.',
                })}
              </li>
            </ul>
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {intl.formatMessage({
                    id: 'builder.analyticsdisplay.h1',
                    defaultMessage: 'Statistik',
                  })}
                </TableCell>
                <TableCell align="left" width="100">
                  {intl.formatMessage({
                    id: 'builder.analyticsdisplay.h2',
                    defaultMessage: 'Anzahl',
                  })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  {intl.formatMessage({
                    id: 'builder.analyticsdisplay.s1',
                    defaultMessage:
                      'Gesamtanzahl Chatbot Sessions mit diesem Chatbot',
                  })}
                </TableCell>
                <TableCell>{statistics?.numberOfSessions ?? 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {intl.formatMessage({
                    id: 'builder.pranalyticsdisplayograms.s2',
                    defaultMessage:
                      'Anzahl Chatbot Sessions seit Zählung der Komponente (Ende August 2023)',
                  })}
                </TableCell>
                <TableCell>
                  {statistics?.numberOfSessionsSinceFormatChange ?? 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {intl.formatMessage({
                    id: 'builder.analyticsdisplay.s3',
                    defaultMessage:
                      'Anzahl Chatbot Sessions mit dieser Komponente',
                  })}
                </TableCell>
                <TableCell>
                  {statistics?.numberOfSessionsWithStateKey ?? 0} (
                  {Math.round(
                    (((statistics?.numberOfSessionsWithStateKey ?? 0) - 0) /
                      (statistics?.numberOfSessionsSinceFormatChange ?? 1) -
                      0) *
                      100
                  ) + ' %'}
                  )
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {intl.formatMessage({
            id: 'builder.analyticsdisplay.close',
            defaultMessage: 'Schließen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
