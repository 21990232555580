import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from '@mui/material';

import { SubscriptionList } from './SubscriptionList';

interface SubscriptionDialogProps {
  accountid: string;
  isOpen: boolean;
  onClose: Function;
}

export const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  accountid,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'builder.admin.subscriptiondialog.title',
            defaultMessage: 'Liste der Subscription Plans',
          })}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'builder.admin.subscriptiondialog.desc',
              defaultMessage:
                'In dieser Liste findet sich die Historie aller Subscription Plans. Liegt das Endedatum in der Vergangenheit, ist der Plan abgelaufen, andernfalls ist er noch aktiv.',
            })}
          </Typography>
          <SubscriptionList accountid={accountid} />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            {intl.formatMessage({
              id: 'builder.admin.subscriptiondialog.button.close',
              defaultMessage: 'Schließen',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
