import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TextField, Typography } from '@mui/material';
import { Node } from 'reactflow';

import { ReadWriteType, StateEntryProps } from '../../nodes';
import { TranslationContext } from '../../stateMachineTranslationContext';

import { GetValueFromSelect } from './GetValueFromSelect';
import { PropertyHeaderLine } from './PropertyHeadLine';

export interface RadarChartInputProps {
  entry: StateEntryProps;
  updateRadarChartAutoGeneration: Function;
  nodes: Node<any>[];
}

export const RadarChartInput: React.FC<RadarChartInputProps> = ({
  entry,
  updateRadarChartAutoGeneration,
  nodes,
}) => {
  const intl = useIntl();
  const { addOrUpdateStateMachineTranslation } =
    React.useContext(TranslationContext);

  const [getLabelsVariable, setGetLabelsVariable] = useState<string>(
    entry.payload.getLabelsFrom ?? ''
  );
  const [valuesFrom, setValuesFrom] = useState<string[]>(
    entry.payload.getValuesFrom ?? []
  );
  const [getRadarLabelsVariable] = useState<string[]>(
    entry.payload.radarLabels ? entry.payload.radarLabels : ['', '']
  );
  const updateAutoGenValueSeries1 = (value: string, _index: number) => {
    valuesFrom[0] = value;
    setValuesFrom(valuesFrom);
    updateRadarChartAutoGeneration('series', valuesFrom);
  };

  const updateAutoGenValueSeries2 = (value: string, _index: number) => {
    valuesFrom[1] = value;
    setValuesFrom(valuesFrom);
    updateRadarChartAutoGeneration('series', valuesFrom);
  };

  const updateAutoGenValueLabels = (value: string, _index: number) => {
    setGetLabelsVariable(value);
    updateRadarChartAutoGeneration('labels', value);
  };

  const updateAutoGenRadarLabels = (value: string, _index: number) => {
    addOrUpdateStateMachineTranslation(getRadarLabelsVariable[_index], value);
    updateRadarChartAutoGeneration('radarLabels', getRadarLabelsVariable);
  };

  return (
    <div>
      <PropertyHeaderLine
        header={intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.radarcharinput.header',
          defaultMessage: 'Radar Chart Werte',
        })}
      />
      <Typography variant="body2" component="span">
        {intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.radarcharinput.autoGenLabels.hint',
          defaultMessage: 'Liste für Labels',
        })}
      </Typography>
      <GetValueFromSelect
        updateValueFrom={updateAutoGenValueLabels}
        currentValue={getLabelsVariable}
        getValueType={ReadWriteType.stringArray}
        nodes={nodes}
      />
      {intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.radarcharinput.autoGenSeries.radarLabel1',
        defaultMessage: 'Name von Serie 1',
      })}
      <TextField
        id="donotcatchpasteevent_radarchart_val_1"
        key="radarLabel1"
        value={intl.formatMessage({
          id: getRadarLabelsVariable[0],
        })}
        onChange={(evt) => updateAutoGenRadarLabels(evt.target.value, 0)}
        style={{ width: '95%' }}
      />
      {intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.radarcharinput.autoGenSeries.hint1',
        defaultMessage: 'Liste für Werte von Serie 1.',
      })}
      <GetValueFromSelect
        updateValueFrom={updateAutoGenValueSeries1}
        currentValue={valuesFrom[0]}
        getValueType={ReadWriteType.numberArray}
        nodes={nodes}
      />
      {intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.radarcharinput.autoGenSeries.radarLabel2',
        defaultMessage: 'Name von Serie 2',
      })}
      <TextField
        id="donotcatchpasteevent_radarchart_val_1"
        key="radarLabel2"
        value={intl.formatMessage({
          id: getRadarLabelsVariable[1],
        })}
        onChange={(evt) => updateAutoGenRadarLabels(evt.target.value, 1)}
        style={{ width: '95%' }}
      />
      {intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.radarcharinput.autoGenSeries.hint2',
        defaultMessage: 'Liste für Werte von Serie 2.',
      })}
      <GetValueFromSelect
        updateValueFrom={updateAutoGenValueSeries2}
        currentValue={valuesFrom[1]}
        getValueType={ReadWriteType.numberArray}
        nodes={nodes}
      />
    </div>
  );
};
