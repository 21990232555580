import React from 'react';
import { Edge, Node } from 'reactflow';

import { initialModule, ModuleProps } from '../../entities';

import { ReactFlowElements } from './ReactFlowHelper';

export type ModuleEditorContextProps = {
  module: ModuleProps;
  nodes: Node<any>[];
  edges: Edge<any>[];
  updateElements: (elements: ReactFlowElements, storeForUndo?: boolean) => void;
  saveModule: Function;
  updateNodes: (nodes: Node<any>[], storeForUndo?: boolean) => void;
  updateEdges: (edges: Edge<any>[], storeForUndo?: boolean) => void;
  undoElements: Function;
  moduleLoadedInDefaultLanguage: boolean;
  moduleid: string;
};

const initModuleEditorContext = {
  module: initialModule,
  nodes: [],
  edges: [],
  updateElements: () => {},
  saveModule: () => {},
  updateNodes: () => {},
  updateEdges: () => {},
  undoElements: () => {},
  moduleLoadedInDefaultLanguage: false,
  moduleid: '',
};

export const ModuleEditorContext =
  React.createContext<ModuleEditorContextProps>(initModuleEditorContext);

export type ModuleEditorContextProviderProps = {
  children?: React.ReactNode;
};

export const ModuleEditorContextProvider: React.FC<
  ModuleEditorContextProviderProps
> = ({ children }) => {
  return (
    <ModuleEditorContext.Provider value={initModuleEditorContext}>
      {children}
    </ModuleEditorContext.Provider>
  );
};
