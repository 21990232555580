import { MultiplePercentageScaleInput } from '@evoach/ui-components';
import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  MultiplePercentageScaleInputNode react-flow node for module builder graph canvas
*/

export const MultiplePercentageScaleInputNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  let scaleNames: string[] = [];
  if (
    payload.scaleNames &&
    (payload.getLabelsFrom === undefined || payload.getLabelsFrom.trim() === '')
  ) {
    scaleNames = payload.scaleNames.map((scale: string) =>
      translateKey(intl, scale)
    );
  } else {
    scaleNames = [
      intl.formatMessage({
        id: 'builder.nodes.multiplepercent.getLabelsFrom.message',
        defaultMessage:
          'Die Titel der Schieberegler werden aus der Variable in getLabelsFrom generiert.',
      }),
    ];
  }
  return (
    <>
      <Handle
        type="target"
        id="multipercscaleoutputhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '340px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <MultiplePercentageScaleInput
          message={translateKey(intl, payload.message)}
          altText={translateKey(intl, payload.altText)}
          buttonText={translateKey(intl, payload.buttonText)}
          saveResultTo="test"
          scaleNames={scaleNames}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="multipercscaleoutputhandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
