import { CardImageEnum } from '@evoach/ui-components';

import { Asset } from '../../../entities/Asset';

import { CardImageList } from './CardImageList';

/**
 * add all files that are available in CardImageList which
 * is generated in Player by the script in generate.mjs
 *
 * @param {string} playerBasePath - get player base path from useEnvironment
 * @returns {Asset[]} additional assets
 */
export const getPublicAssets = (playerBasePath: string) => {
  const assets: Asset[] = [];

  CardImageList.forEach((filename: string) => {
    assets.push({
      asid: 0,
      assetId: '',
      isDefaultAsset: true,
      isPresent: true,
      key: filename, // filename!
      thumbnailUrl: `${playerBasePath}/assets/cardimages2/${filename}`,
      tscreated: Date(),
      tsupdated: Date(),
      account: undefined,
      metadata: {
        externalUrl: `${playerBasePath}/assets/cardimages2/${filename}`,
      },
      useforai: false,
    });
  });

  return assets;
};

/**
 * CardImageListParsed is a mapped helper list to map CardImageEnum to filenames
 */
const CardImageListParsed = CardImageList.map((filename: string) => {
  return filename
    .replace(' ', '_')
    .replace(' ', '_')
    .replace(' ', '_')
    .replace('.png', '')
    .replace('.jpg', '');
});

/**
 * map a CardImageEnum to a filename of a public asset
 *
 * @param {CardImageEnum} image to be mapped to a filename
 * @param {string} playerBasePath as defined in useEnvironment()
 * @returns
 */
export const mapImageToPublicAsset = (
  image: CardImageEnum | undefined,
  playerBasePath: string
) => {
  if (!image) {
    return `${playerBasePath}/assets/noimg.png`;
  }
  const imgAsString = image as string;
  const fileIndex = CardImageListParsed.findIndex((val) => val === imgAsString);
  return fileIndex > -1 && CardImageList[fileIndex]
    ? `${playerBasePath}/assets/cardimages2/${CardImageList[fileIndex]}`
    : '';
};
