import { defineMessages } from 'react-intl';
import { ComponentSize } from '@evoach/ui-components';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.nextButton.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

export const imageSelectorDisplayStateEntry: StateEntryProps = {
  type: 'renderImageSelectorDisplay',
  temporary: true,
  payload: {
    getValueFrom: '',
    buttonText: 'builder.nodes.nextButton.state.buttonText',
    componentSize: ComponentSize.SMALL,
    showTitles: true,
    isEcho: true,
    saveResultTo: '',
  },
  getValueType: ReadWriteType.typedObject,
  displayName: 'Image Selector Display',
  nodeType: 'imageSelectorDisplayStateEntry',
  handleOutCount: 1,
};
