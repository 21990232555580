import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  fan: {
    id: 'builder.nodes.needsInput.state.needs.fan',
    defaultMessage: 'Bedürfnisfächer',
  },
  nonviolentcom: {
    id: 'builder.nodes.needsInput.state.needs.nonviolentcommunication',
    defaultMessage: 'Gewaltfreie Kommuniktation',
  },
  extendedneeds: {
    id: 'builder.nodes.needsInput.state.needs.extendedneeds',
    defaultMessage: 'Erweiterte Bedürfnisse',
  },
  rest: {
    id: 'builder.nodes.needsInput.state.needs.rest',
    defaultMessage: 'Ruhe/Erholung',
  },
  beauty: {
    id: 'builder.nodes.needsInput.state.needs.beauty',
    defaultMessage: 'Schönheit',
  },
  community: {
    id: 'builder.nodes.needsInput.state.needs.community',
    defaultMessage: 'Gemeinschaft',
  },
  support: {
    id: 'builder.nodes.needsInput.state.needs.support',
    defaultMessage: 'Unterstützung',
  },
  sympathy: {
    id: 'builder.nodes.needsInput.state.needs.sympathy',
    defaultMessage: 'Verständnis/Mitgefühl',
  },
  loveSecurity: {
    id: 'builder.nodes.needsInput.state.needs.loveSecurity',
    defaultMessage: 'Liebe/Geborgenheit',
  },
  peace: {
    id: 'builder.nodes.needsInput.state.needs.peace',
    defaultMessage: 'Frieden',
  },
  hope: {
    id: 'builder.nodes.needsInput.state.needs.hope',
    defaultMessage: 'Hoffnung',
  },
  inspiration: {
    id: 'builder.nodes.needsInput.state.needs.inspiration',
    defaultMessage: 'Inspiration/Kreativität',
  },
  balance: {
    id: 'builder.nodes.needsInput.state.needs.balance',
    defaultMessage: 'Balance',
  },
  beHeard: {
    id: 'builder.nodes.needsInput.state.needs.beHeard',
    defaultMessage: 'Gehört/Gesehen werden',
  },
  freedomAutonomy: {
    id: 'builder.nodes.needsInput.state.needs.freedomAutonomy',
    defaultMessage: 'Freiheit/Autonomie',
  },
  vitality: {
    id: 'builder.nodes.needsInput.state.needs.vitality',
    defaultMessage: 'Lebensfreude',
  },
  development: {
    id: 'builder.nodes.needsInput.state.needs.development',
    defaultMessage: 'Entwicklung/Wachstum',
  },
  trust: {
    id: 'builder.nodes.needsInput.state.needs.trust',
    defaultMessage: 'Vertrauen',
  },
  reliability: {
    id: 'builder.nodes.needsInput.state.needs.reliability',
    defaultMessage: 'Verlässlichkeit',
  },
  contribution: {
    id: 'builder.nodes.needsInput.state.needs.contribution',
    defaultMessage: 'Beitrag leisten',
  },
  clarity: {
    id: 'builder.nodes.needsInput.state.needs.clarity',
    defaultMessage: 'Klarheit',
  },
  fulfillment: {
    id: 'builder.nodes.needsInput.state.needs.fulfillment',
    defaultMessage: 'Sinn/Erfüllung',
  },
  security: {
    id: 'builder.nodes.needsInput.state.needs.security',
    defaultMessage: 'Sicherheit/Schutz',
  },
  recognition: {
    id: 'builder.nodes.needsInput.state.needs.recognition',
    defaultMessage: 'Respekt/Anerkennung',
  },
  wellbeing: {
    id: 'builder.nodes.needsInput.state.needs.wellbeing',
    defaultMessage: 'Wohlbefinden',
  },
  connectivity: {
    id: 'builder.nodes.needsInput.state.needs.connectivity',
    defaultMessage: 'Verbundenheit',
  },
  honesty: {
    id: 'builder.nodes.needsInput.state.needs.honesty',
    defaultMessage: 'Ehrlichkeit',
  },
  joy: {
    id: 'builder.nodes.needsInput.state.needs.joy',
    defaultMessage: 'Freude',
  },
  autonomy: {
    id: 'builder.nodes.needsInput.state.needs.autonomy',
    defaultMessage: 'Autonomie',
  },
  meaning: {
    id: 'builder.nodes.needsInput.state.needs.meaning',
    defaultMessage: 'Sinn',
  },
  food: {
    id: 'builder.nodes.needsInput.state.needs.food',
    defaultMessage: 'Nahrung',
  },
  health: {
    id: 'builder.nodes.needsInput.state.needs.health',
    defaultMessage: 'Gesundheit',
  },
  touch: {
    id: 'builder.nodes.needsInput.state.needs.touch',
    defaultMessage: 'Berührung',
  },
  sleep: {
    id: 'builder.nodes.needsInput.state.needs.sleep',
    defaultMessage: 'Ruhe/Schlaf',
  },
  guard: {
    id: 'builder.nodes.needsInput.state.needs.guard',
    defaultMessage: 'Schutz',
  },
  safety: {
    id: 'builder.nodes.needsInput.state.needs.safety',
    defaultMessage: 'Sicherheit',
  },
  harmony: {
    id: 'builder.nodes.needsInput.state.needs.harmony',
    defaultMessage: 'Harmonie',
  },
  membership: {
    id: 'builder.nodes.needsInput.state.needs.membership',
    defaultMessage: 'Zugehörigkeit',
  },
  love: {
    id: 'builder.nodes.needsInput.state.needs.love',
    defaultMessage: 'Liebe',
  },
  closeness: {
    id: 'builder.nodes.needsInput.state.needs.closeness',
    defaultMessage: 'Nähe',
  },
  assistance: {
    id: 'builder.nodes.needsInput.state.needs.assistance',
    defaultMessage: 'Unterstützung',
  },
  understand: {
    id: 'builder.nodes.needsInput.state.needs.understand',
    defaultMessage: 'Verstehen',
  },
  empathy: {
    id: 'builder.nodes.needsInput.state.needs.empathy',
    defaultMessage: 'Empathie',
  },
  respect: {
    id: 'builder.nodes.needsInput.state.needs.respect',
    defaultMessage: 'Respekt',
  },
  appreciation: {
    id: 'builder.nodes.needsInput.state.needs.appreciation',
    defaultMessage: 'Wertschätzung',
  },
  individualFulfilment: {
    id: 'builder.nodes.needsInput.state.needs.individualFulfilment',
    defaultMessage: 'Selbstverwirklichung',
  },
  freedom: {
    id: 'builder.nodes.needsInput.state.needs.freedom',
    defaultMessage: 'Freiheit',
  },
  study: {
    id: 'builder.nodes.needsInput.state.needs.study',
    defaultMessage: 'Lernen',
  },
  grow: {
    id: 'builder.nodes.needsInput.state.needs.grow',
    defaultMessage: 'Wachstum',
  },
  contributionAbility: {
    id: 'builder.nodes.needsInput.state.needs.contributionAbility',
    defaultMessage: 'Beitragen können',
  },
  operative: {
    id: 'builder.nodes.needsInput.state.needs.operative',
    defaultMessage: 'Wirksam sein',
  },
  clearance: {
    id: 'builder.nodes.needsInput.state.needs.clearance',
    defaultMessage: 'Klarheit',
  },
  creativity: {
    id: 'builder.nodes.needsInput.state.needs.creativity',
    defaultMessage: 'Kreativität',
  },
  letGo: {
    id: 'builder.nodes.needsInput.state.needs.letGo',
    defaultMessage: 'Loslassen',
  },
  buttonText: {
    id: 'builder.nodes.needsInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
  placeholder: {
    id: 'builder.nodes.needsInput.state.placeholder',
    defaultMessage: 'Füge ein Bedürfnis hinzu…',
  },
});

export const predefinedNeedsSet: Record<string, any>[] = [];

// TODO user specific hard-coded lists
// save per list item index explicitely in object as some lists elements are not displayed
// save per list item the list of accountids that are allowed to see the list (+role evoachAdmin)

// for lists see also https://evoach.atlassian.net/browse/PROD-984

// set 1 - based on Sylvias requirements
predefinedNeedsSet.push({
  needsListName: 'builder.nodes.needsInput.state.needs.fan',
  needs: [
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.rest',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.beauty',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.community',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.support',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.sympathy',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.loveSecurity',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.peace',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.hope',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.inspiration',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.balance',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.beHeard',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.freedomAutonomy',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.vitality',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.development',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.trust',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.reliability',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.contribution',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.clarity',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.fulfillment',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.security',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.recognition',
      starred: false,
    },
  ],
});

// set 2 - non-violent communication
predefinedNeedsSet.push({
  needsListName: 'builder.nodes.needsInput.state.needs.nonviolentcommunication',
  needs: [
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.wellbeing',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.connectivity',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.honesty',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.joy',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.peace',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.autonomy',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.meaning',
      starred: false,
    },
  ],
});

// set 3 - extended basics
// Extented needs / (nonviolent communication adaption by evoach)
predefinedNeedsSet.push({
  needsListName: 'builder.nodes.needsInput.state.needs.extendedneeds',
  needs: [
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.food',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.health',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.touch',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.sleep',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.guard',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.safety',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.peace',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.harmony',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.membership',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.love',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.closeness',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.connectivity',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.assistance',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.understand',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.empathy',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.respect',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.appreciation',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.individualFulfilment',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.honesty',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.freedom',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.fulfillment',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.study',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.grow',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.contributionAbility',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.operative',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.clearance',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.vitality',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.creativity',
      starred: false,
    },
    {
      type: 'needsinput',
      value: 'builder.nodes.needsInput.state.needs.letGo',
      starred: false,
    },
  ],
});

export const needsInputStateEntry: StateEntryProps = {
  type: 'renderNeedsInput',
  temporary: true,
  payload: {
    placeholderTexts: ['builder.nodes.needsInput.state.placeholder'],
    elements: predefinedNeedsSet[0].needs,
    predefinedNeedsIndex: 0,
    buttonText: 'builder.nodes.needsInput.state.buttonText',
    saveResultTo: 'needsinput',
    getValuesFrom: [''],
    minItems: 1,
    maxItems: 5,
    hideOwnInput: false,
    selectAll: false,
    //message: 'bitte wähle deine Bedürfnisse aus', // later for composite
  },
  saveResultType: ReadWriteType.typedObject,
  getValueType: [ReadWriteType.stringArray, ReadWriteType.typedObject],
  displayName: 'Needs Input',
  nodeType: 'needsInputStateEntry',
  handleOutCount: 1,
};
