import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'aiClassificationNodeStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kann mit Hilfe Künstlicher Intelligenz eine Eingabe klassifiziert werden. Anschließend wird abhängig vom Ergebnis verzweigt.',
  },
});

export const ClassificationTypes: Array<Record<string, any>> = [];

/*
    Sample result format for Topic Classification

    {
      "Work/life balance": <probablity of "Work/lifebalance" as decimal number>,
      "Career growth":  <probablity of "Career growth" as decimal number>,
      "Setting boundaries": <probability of "Setting boundaries" as decimal number>,
      "Communication skills": <probablity of "Communication skills" as decimal number>,
      "Conflict": <probablity of "Conflict"as decimal number>
    }
*/

// index 0
//
// ! Very important !
// for the classification, it is very important that the sequence in the list of
// classification keys matches the sequence of the corresponding handles in keyTexts
// This is implicitely assumed in externalActions.ts in Player
//
ClassificationTypes.push({
  title: 'Topic Classification with OpenAI',
  externalService: ExternalServiceEnum.OPENAI,
  promptType: PromptEnum.TOPICCLASSIFICATION,
  // classification keys are used by AI as JSON keys
  classificationKeys: [
    'Work/life balance',
    'Career growth',
    'Setting boundaries',
    'Communication skills',
    'Conflict',
  ],
  // classificatioj texts are used as description of out handles in a node
  // they will be translated in the Creator UI.
  // TODO may be used later to extract the name of the category that was selected by the classification
  // TODO could then be added to payload and used in Player as variable content
  // ! TODO if used in Player => translate in module, not in Creator UI
  classificationTexts: [
    'Work/Life-Balance',
    'Karriere',
    'Grenzen setzen',
    'Kommunikationsfähigkeiten',
    'Konflikt',
  ],
  keyTexts: ['worklife', 'career', 'boundaries', 'commnuication', 'conflict'],
  handleOutCount: 5,
});

// TODO ClassificationTypes not yet used => may be used in future to select different classification types

/**
 * The AI classification is used to retrieve a JSON that classifies an input into
 * different categories.
 */
export const aiClassificationNodeStateEntry: StateEntryProps = {
  version: 1,
  type: 'sendExternal',
  temporary: false,
  payload: {
    getValueFrom: '',
    externalService: ExternalServiceEnum.OPENAI,
    promptType: PromptEnum.TOPICCLASSIFICATION,
    prompt: '',
    // keyTexts only placeholder ==> will be automatically replaced by random values!
    // These random values are used to identify the out handles of a node
    keyTexts: ['worklife', 'career', 'boundaries', 'commnuication', 'conflict'],
    classificationKeys: [
      'Work/life balance',
      'Career growth',
      'Setting boundaries',
      'Communication skills',
      'Conflict',
    ],
  },
  getValueType: ReadWriteType.string,
  displayName: 'AI Classification',
  nodeType: 'aiClassificationNodeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 5,
  predefinedListIndex: 0, // s. ClassificationTypes above
};
