import React from 'react';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { useFetchSubscriptions } from '../../api/subscription/useFetchSubscriptions';
import { Subscription } from '../../entities/Subscription';
import { SubscriptionTypeText } from '../reception/subscriptions/SubscriptionTypeText';
import { formatDate, useTableStyles } from '../tableHelper';
import { SubscriptionType } from '../../entities/subscriptionTypes';
import { useEnvironment } from '../../environment/useEnvironment';

export type SubscriptionsProps = {
  accountId?: string;
};
export const Subscriptions: React.FC<SubscriptionsProps> = ({ accountId }) => {
  const { subscriptions } = useFetchSubscriptions(accountId);

  const env = useEnvironment();

  const classes = useTableStyles();
  const intl = useIntl();
  const planIsActive = (enddate: any) =>
    enddate === null ||
    (enddate !== null && new Date(enddate) > new Date(Date.now()));

  const activeSubscriptions = subscriptions?.filter((s: Subscription) =>
    planIsActive(s.enddate)
  );

  const anyStripeSubscriptions = subscriptions?.filter(
    (s: Subscription) => s.stripecustomerid && s.stripesubscriptionid
  );

  return (
    <div
      className="profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '5rem',
      }}
    >
      {activeSubscriptions && activeSubscriptions.length > 0 ? (
        <TableContainer
          component={Paper}
          style={{
            minHeight: '100%',
            maxWidth: '50%',
            overflowX: 'initial',
          }}
        >
          <Table
            stickyHeader
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                paddingBottom: '0.5rem',
              },
            }}
          >
            <colgroup>
              <col style={{ width: '10%', textAlign: 'left' }} />
              <col style={{ width: '10%', textAlign: 'left' }} />
              <col style={{ width: '10%', textAlign: 'left' }} />
              <col style={{ width: '10%', textAlign: 'left' }} />
            </colgroup>
            <TableHead className={classes.noWrapChildren}>
              <TableRow>
                <TableCell>
                  {intl.formatMessage({
                    id: 'builder.subscriptionlist.table.header.type',
                    defaultMessage: 'Typ',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'builder.subscriptionlist.table.header.start',
                    defaultMessage: 'Aktiviert am',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'builder.subscriptionlist.table.header.end',
                    defaultMessage: 'Enddatum',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'builder.subscriptionlist.table.header.action',
                    defaultMessage: 'Aktion',
                  })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeSubscriptions.map((row: Subscription) => (
                <TableRow
                  key={'subsclistrow' + row.subscriptionid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                >
                  <TableCell>
                    <SubscriptionTypeText type={row.subscriptioninfo.type} />
                  </TableCell>
                  <TableCell>{formatDate(row.startdate)}</TableCell>
                  <TableCell>{formatDate(row.enddate)}</TableCell>
                  <TableCell>
                    {row.subscriptioninfo.type !== SubscriptionType.FREE_TIER &&
                      row.subscriptioninfo.type !==
                        SubscriptionType.TRIAL_30_DAYS &&
                      !row.enddate &&
                      row.stripecustomerid &&
                      row.stripesubscriptionid && (
                        <Typography variant="body2" sx={{ marginTop: '20px' }}>
                          <Link href={env.stripeCustomerPortalUrl}>
                            {intl.formatMessage({
                              id: 'player.subscriptionlist.cancel',
                              defaultMessage: 'Kündigen',
                            })}
                          </Link>
                        </Typography>
                      )}

                    {row.subscriptioninfo.type !== SubscriptionType.FREE_TIER &&
                      row.subscriptioninfo.type !==
                        SubscriptionType.TRIAL_30_DAYS &&
                      !row.enddate &&
                      !row.stripecustomerid &&
                      !row.stripesubscriptionid && (
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'player.subscriptionlist.cancelsupporttooltip',
                            defaultMessage:
                              'Dieser Plan wurde dir von evoach manuell zugewiesen. Kontaktiere bitte deinen Ansprechpartner bei evoach, um ihn zu kündigen.',
                          })}
                          arrow
                          placement="top-start"
                        >
                          <Typography
                            variant="body2"
                            sx={{ marginTop: '20px' }}
                          >
                            {intl.formatMessage({
                              id: 'player.subscriptionlist.cancelsupport',
                              defaultMessage: 'Kündigen',
                            })}
                          </Typography>
                        </Tooltip>
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <Typography variant="body2" sx={{ marginTop: '20px' }}>
            {intl.formatMessage({
              id: 'player.subscriptionlist.nosubscriptions',
              defaultMessage: 'Keine Subscriptions vorhanden.',
            })}
          </Typography>
          <Typography variant="body2" sx={{ marginTop: '20px' }}>
            <Link href="/subscribe">
              {intl.formatMessage({
                id: 'player.subscriptionlist.subscribehere',
                defaultMessage: 'Hier kannst du einen Plan buchen.',
              })}
            </Link>
          </Typography>
        </>
      )}

      {anyStripeSubscriptions && anyStripeSubscriptions.length > 0 && (
        <Typography variant="body2" sx={{ marginTop: '20px' }}>
          <Link href={env.stripeCustomerPortalUrl}>
            {intl.formatMessage({
              id: 'player.subscriptionlist.seeinvoices',
              defaultMessage: 'Hier kannst du deine Rechnungen einsehen.',
            })}
          </Link>
        </Typography>
      )}
    </div>
  );
};
