import { Handle, NodeProps, Position } from 'reactflow';
import { CoachMessage } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  CoachMessage react-flow node for module builder graph canvas
*/

export const CoachMessageNode: React.FC<NodeProps<any>> = (
  nodeProps: NodeProps<any>
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  const version = nodeProps.data.state.entry[0].version;
  const cmStyle: Record<string, string> =
    typeof version === 'number' && version >= 3 ? { maxWidth: '400px' } : {};
  const byGetValuesFrom =
    payload.getValuesFrom === undefined || payload.getValuesFrom === null
      ? []
      : payload.getValuesFrom.filter((value: string) => value !== '');

  //
  // if the content of the coach message is completely replaced by a variable,
  // we do not display the message entry but a generic information
  //
  const message =
    byGetValuesFrom.length === 0
      ? translateKey(intl, payload.message)
      : intl.formatMessage(
          {
            id: 'builder.nodes.coachmessage.getvaluefrom',
            defaultMessage:
              'Der Inhalt dieser Coach Message wird aus diesen Variablen gelesen: {invar}',
          },
          {
            invar: <b>{byGetValuesFrom.join(', ')}</b>,
          }
        );

  //
  // render actual node
  //
  return (
    <>
      <Handle
        type="target"
        id="messageinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={cmStyle}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />

        <CoachMessage
          showAiBadge={payload.showAiBadge}
          message={message}
          aiTooltipText={
            payload.showAiBadge ? translateKey(intl, payload.helperText) : ''
          }
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="messageouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
