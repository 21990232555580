import { CoachMessage, YesNoButton } from '@evoach/ui-components';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';

import { getProtectedText } from '../../intl/getProtectedText';
import { TranslationContext } from '../stateMachineTranslationContext';

import { useGlobalStyles, translateKey } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  ShareSession react-flow node for module builder graph canvas
*/

export const ShareSessionNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const { stateMachineLocale } = useContext(TranslationContext);

  const payload = nodeProps.data.state.entry[0].payload;

  const nodeHint = intl.formatMessage({
    id: 'builder.nodes.elements.sharesession.nodeHint',
    defaultMessage:
      'Dieser Knoten teilt nach einer Bestätigung durch den Coachee die momentane Sitzung mit dem Coach.',
  });
  const editableMessageHint = intl.formatMessage({
    id: 'builder.nodes.elements.sharesession.editableMessageHint',
    defaultMessage: 'Die erste Nachricht ist rechts editierbar:',
  });
  const protectedMessageHint = intl.formatMessage({
    id: 'builder.nodes.elements.sharesession.protectedMessageHint',
    defaultMessage:
      'Die zweite Nachricht wird immer angezeigt und ist nicht änderbar:',
  });
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ maxWidth: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {nodeHint}
          <br />
          <br />
          <div style={{ paddingBottom: '1em' }}>{editableMessageHint}</div>
          <CoachMessage
            message={translateKey(intl, payload.message)}
          ></CoachMessage>
          <br />
          <div style={{ paddingBottom: '1em' }}>{protectedMessageHint}</div>
          <CoachMessage
            message={getProtectedText(
              'shareSession.notifyCoachee',
              stateMachineLocale
            )}
          ></CoachMessage>
        </Typography>
        <div>
          <div style={{ margin: '1em 10%' }}>
            <YesNoButton
              yesButtonText={getProtectedText(
                'shareSession.yes',
                stateMachineLocale
              )}
              noButtonText={getProtectedText(
                'shareSession.no',
                stateMachineLocale
              )}
              saveResultTo="evoachechokey.yesNo"
            />
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="yeshandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '30%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        style={{ left: '70%' }}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
