import { Handle, NodeProps, Position } from 'reactflow';
import { NextButton } from '@evoach/ui-components';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';
import { NavigateTargetEnum } from './NavigateButton.state';

/*
  Navigate Button react-flow node for module builder graph canvas
*/

export const NavigateButtonNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  let hint = '';
  switch (payload.navigateTarget) {
    case NavigateTargetEnum.SESSIONLIST:
      hint = intl.formatMessage({
        id: 'builder.nodes.navigatebutton.hint.session',
        defaultMessage:
          'Der Button navigiert zur Liste der Chatbot Protokolle.',
      });
      break;
    case NavigateTargetEnum.PROGRAMLIST:
      hint = intl.formatMessage({
        id: 'builder.nodes.navigatebutton.hint.program',
        defaultMessage:
          'Der Button navigiert zur Liste der Coaching Programme.',
      });
      break;
    case NavigateTargetEnum.MODULELIST:
    default:
      hint = intl.formatMessage({
        id: 'builder.nodes.navigatebutton.hint.module',
        defaultMessage: 'Der Button navigiert zur Liste der Chatbot Themen.',
      });
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography>{hint}</Typography>
        <br />
        <NextButton buttonText={translateKey(intl, payload.buttonText)} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nexthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
