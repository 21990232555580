import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Box, MobileStepper } from '@mui/material';

//import MobileStepper from '@mui/material/MobileStepper';
//import Paper from '@mui/material/Paper';

//import Button from '@mui/material/Button';
//import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

/**
 *
 * implementation adapted from this example
 *
 * @see https://mui.com/material-ui/react-stepper/#text-with-carousel-effect
 *
 */

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    imgPath: '/assets/notloggedinslider/2_levelup.png',
  },
  {
    imgPath: '/assets/notloggedinslider/1_personal.png',
  },
  {
    imgPath: '/assets/notloggedinslider/3_magic.png',
  },
];

function Carousel() {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoPlay
        interval={7000}
      >
        {images.map((step, index) => (
          <div key={index + 'step1'}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: 'block',
                  maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        backButton={<></>}
        nextButton={<></>}
      />
    </Box>
  );
}

export default Carousel;
