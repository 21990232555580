import { useMemo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ImageItem, ImageSelect } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { getResolvedUrl } from '../../api/asset/useFetchAssetUrl';

import { CustomNodeMenu } from './base.nodemenu';
import { translateKey, useGlobalStyles } from './base.node';

import { isImageSelectorEntry } from '.';

export const ImageSelectorNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload =
    nodeProps.data.state.entry.find(isImageSelectorEntry)?.payload;

  // ImageSelect expects an array of ImageItems, payload contains ImageProps
  // transform it by mapping ...

  const images: ImageItem[] = useMemo(() => {
    const images: ImageItem[] = [];
    // resolve all urls - is not working with hook as we need several urls and
    // can't use a hook for that
    for (let i = 0; i < payload.images.length; i++) {
      getResolvedUrl(
        translateKey(intl, payload.images[i].assetid),
        translateKey(intl, payload.images[i].src)
      ).then((resUrl: string) => {
        images.push({
          type: payload.images[i].type,
          value: translateKey(intl, payload.images[i].value),
          resolvedsrc: resUrl,
          src: translateKey(intl, payload.images[i].src),
          assetid: translateKey(intl, payload.images[i].assetid),
        });
      });
    }
    return images;
  }, [intl, payload.images]);

  /* console.log(
    'in node: ' + images.map((img: any) => img.src)[images.length - 1]
  );
 */
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <ImageSelect
          buttonText={translateKey(intl, payload.buttonText)}
          images={images}
          saveResultTo=""
          maxItems={payload.maxItems}
          minItems={payload.minItems}
          displayItems={payload.displayItems}
          showTitles={payload.showTitles}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nexthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
