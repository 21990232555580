import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  Box,
} from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';

import { PrintTemplateSelection } from '../PrintTemplates/PrintTemplateSelection';
import { AccountMetainfo } from '../../../entities/AccountTypes';
import { PrintTemplate } from '../../../entities/PrintTemplatesListTypes';
import { authorizedPut } from '../../../api/authorizedApi';

interface AdminEditUserProps {
  metainfos: AccountMetainfo;
  accountid: string;
  isOpen: boolean;
  /**
   * onClose(requiresRefetch: boolean) => {}
   */
  onClose: Function;
}

export const AdminEditUser: React.FC<AdminEditUserProps> = ({
  metainfos,
  accountid,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  // initial data from current metainfos
  const [accountTemplates, setAccountTemplates] = useState<PrintTemplate[]>(
    metainfos?.printtemplates ?? []
  );

  useEffect(() => {
    setAccountTemplates(metainfos?.printtemplates ?? []);
  }, [metainfos?.printtemplates]);

  const onChangeTemplateIds = (templates: PrintTemplate[]) => {
    //console.log(accountTemplates);
    // TODO today, we only handle certificates. In future, we may handle
    // also other types. If we filter them, this assignement has to consider
    // that only the filtered type was changed!
    setAccountTemplates(templates);
  };

  // Save button was clicked
  const onSave = () => {
    metainfos.printtemplates = accountTemplates;
    const updateBody = {
      metainfos: { ...metainfos, printtemplates: accountTemplates },
    } as AccountMetainfo;

    // update metainfos
    const updateCall = authorizedPut(`/account/${accountid}`, updateBody);
    updateCall()
      .then(() => {
        onClose(true); // true == refetch
      })
      .catch(() => {
        // TODO fehler
      });
  };

  // if element is not open, do not render anything
  if (!isOpen) {
    return null;
  }

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.adminedituser.dialog.title',
          defaultMessage: 'Benutzereinstellungen editieren',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'builder.adminedituser.dialog.content',
            defaultMessage:
              'Hier kannst du die Benutzereinstellungen wie zum Beispiel die Zuordnung von "Print-Templates" ändern.',
          })}
        </Typography>
        <Box sx={{ marginTop: '20px' }}>
          <PrintTemplateSelection
            onChange={onChangeTemplateIds}
            defaultTemplates={accountTemplates}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <EvoachButton
          color="secondary"
          onClick={() => {
            setAccountTemplates(metainfos?.printtemplates ?? []);
            onClose(false); // do not refetch
          }}
        >
          {intl.formatMessage({
            id: 'builder.adminedituser.dialog.button.cancel',
            defaultMessage: 'Abbrechen',
          })}
        </EvoachButton>
        <EvoachButton
          color="secondary"
          onClick={() => {
            onSave();
          }}
        >
          {intl.formatMessage({
            id: 'builder.adminedituser.dialog.button.save',
            defaultMessage: 'Speichern',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
