import { generateRandomString } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';
/*
  RandomCoachMessageStateEntry xState specific defintions
*/

defineMessages({
  message1: {
    id: 'builder.nodes.randomCoachMessage.state.message1',
    defaultMessage: 'Erste Zufallsnachricht',
  },
  message2: {
    id: 'builder.nodes.randomCoachMessage.state.message2',
    defaultMessage: 'Zweite Zufallsnachricht',
  },
});

/**
 * randomCoachMessageStateEntry
 *
 * v1 = init
 * v2 = add getStringValues
 */
export const randomCoachMessageSingleStateEntry: StateEntryProps = {
  version: 'v2',
  type: 'renderRandomCoachMessage', // same as for randomCoachMessageStateEntry (Multi!)
  temporary: false,
  payload: {
    messages: [
      'builder.nodes.randomCoachMessage.state.message1',
      'builder.nodes.randomCoachMessage.state.message2',
    ],
    keyTexts: [
      'radiokey.' + generateRandomString(4),
      'radiokey.' + generateRandomString(4),
    ],
    saveResultTo: 'selectedMessage',
    getStringValues: [''], // PROD-1401
  },
  saveResultType: [ReadWriteType.string],
  displayName: 'Random Coach Message Single', // rename to multi because we may need a single output handle in the future
  nodeType: 'randomCoachMessageSingleStateEntry',
  handleOutCount: 1,
};
