import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  headline: {
    id: 'builder.nodes.postItDisplay.state.headline',
    defaultMessage: 'Überschrift',
  },
  title: {
    id: 'builder.nodes.postItDisplay.state.title',
    defaultMessage: 'Titel',
  },
});

export const postItDisplayStateEntry: StateEntryProps = {
  type: 'renderPostItDisplay',
  temporary: false,
  payload: {
    headline: 'builder.nodes.postItDisplay.state.headline',
    title: 'builder.nodes.postItDisplay.state.title',
    getValueFrom: '',
  },
  getValueType: ReadWriteType.string,
  displayName: 'Post-It Display',
  nodeType: 'postItDisplayStateEntry',
  handleOutCount: 1,
};
