import { Handle, NodeProps, Position } from 'reactflow';
import { Typography } from '@mui/material';

import { Description } from '../Description';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  Formula 
*/

export const FormulaNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();

  const payload = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;

  return (
    <>
      <Handle
        type="target"
        id="formulain"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ width: '300px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          <Description nodetype={nodeType} propname="description" />
          <br />
          {payload.formula}
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="formulaout"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
