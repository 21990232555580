import {
  EmotionEnum,
  EmotionIcon,
  generateRandomString,
} from '@evoach/ui-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { autoSave } from '../../ModuleEditor/autosave';
import {
  aiGenericClassificationStateEntry,
  compareMultipleNumbersStateEntry,
  multiplePercentageScaleInputStateEntry,
} from '../../nodes';
import {
  EmotionProps,
  emotionsInputStateEntry,
} from '../../nodes/EmotionsInput.state';
import { TranslationContext } from '../../stateMachineTranslationContext';
import { WindowContext } from '../../window/WindowContext';

const maxListElements = 10;

const listEditorMessages = defineMessages({
  dialogTitle: {
    id: 'builder.moduleeditor.propertiessidebar.listeditordialog.dialogTitle',
    defaultMessage: 'Listeneditor',
  },
  dialogSaveButton: {
    id: 'builder.moduleeditor.propertiessidebar.listeditordialog.dialogSaveButton',
    defaultMessage: 'Speichern',
  },
  dialogCloseButton: {
    id: 'builder.moduleeditor.propertiessidebar.listeditordialog.dialogCloseButton',
    defaultMessage: 'Abbrechen',
  },
});
export interface ListEditorDialogProps {
  listelements: Array<any>;
  listkeys?: string[];
  open: boolean;
  onClose: () => void;
  updateElements: Function;
  nodeType?: string;
  keyNumbers?: number[];
  updateKeyNumbers?: Function;
}

/**
 * Editor for editing text list.
 *
 * Plus:
 * - if keyNumbers is present,
 *
 * @param {ListEditorDialogProps}
 * @returns
 */
export const ListEditorDialog: React.FC<ListEditorDialogProps> = ({
  listelements,
  listkeys,
  open,
  onClose,
  updateElements,
  nodeType = emotionsInputStateEntry.nodeType,
  keyNumbers,
  updateKeyNumbers,
}) => {
  const intl = useIntl();
  const { addOrUpdateStateMachineTranslation } = useContext(TranslationContext);
  const { alert } = useContext(WindowContext);

  const [listElements, setListElements] = useState<any[]>(listelements ?? []);

  const [listKeys, setListKeys] = useState<string[]>(listkeys ?? []);
  //listkeys ? listkeys.filter((element: any) => element) : []

  const hasKeyNumbers = keyNumbers && keyNumbers.length > 0 && updateKeyNumbers;

  const [listKeyNumbers, setListKeyNumbers] = useState<number[]>(
    keyNumbers ?? []
  );

  const updateListKeyNumbers = (value: string, index: number) => {
    listKeyNumbers[index] = parseInt(value);
    setListKeyNumbers(listKeyNumbers);
    updateElements(listElements, listKeys, false, listKeyNumbers);
  };

  const isEmotionInput = nodeType === emotionsInputStateEntry.nodeType;
  const isMultiplePercentInput =
    nodeType === multiplePercentageScaleInputStateEntry.nodeType;
  const isStringListInput = !isEmotionInput && !isMultiplePercentInput;
  const isCompareMultipleNumbers =
    nodeType === compareMultipleNumbersStateEntry.nodeType;
  const isAiGenericClassification =
    nodeType === aiGenericClassificationStateEntry.nodeType;

  const textLabel = useMemo(() => {
    switch (nodeType) {
      case emotionsInputStateEntry.nodeType:
        return intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.listeditordialog.label.emotion',
          defaultMessage: 'Name der Emotion',
        });
      case multiplePercentageScaleInputStateEntry.nodeType:
        return intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.listeditordialog.label.scale',
          defaultMessage: 'Name der Skala',
        });
      case compareMultipleNumbersStateEntry.nodeType:
        return intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.listeditordialog.label.comparemulitnum',
          defaultMessage: 'Grenze des nächsten Wertebereichs',
        });
      case aiGenericClassificationStateEntry.nodeType:
        return intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.listeditordialog.label.aigenericoptions',
          defaultMessage: 'Option zur Klassifikation',
        });
      default:
        return intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.listeditordialog.label.default',
          defaultMessage: 'Text des Elementes',
        });
    }
  }, [intl, nodeType]);
  /* 
    nodeType === selectionCardStateEntry.nodeType ||
    nodeType === multiButtonStateEntry.nodeType ||
    nodeType === radioButtonStateEntry.nodeType; */
  //const typeOfElements = (elements: Array<any>) => typeof elements;

  const listDescription = useMemo(() => {
    if (isAiGenericClassification) {
      return intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.listeditordialog.aigenclass.description',
        defaultMessage:
          'Hier kannst du die verschidenen Optionen zur Klassifikation festlegen. Das können einzelne Begriffe, aber auch Beschreibungen der Klasse sein.',
      });
    }
    return intl.formatMessage({
      id: 'builder.moduleeditor.propertiessidebar.listeditordialog.description',
      defaultMessage:
        'Hier kannst du Listeneinträge hinzufügen, ändern und entfernen.',
    });
  }, [intl, isAiGenericClassification]);

  /** update text of a user defined emotion */
  const updateListEntryText = (newValue: string | number, index: number) => {
    if (isEmotionInput) {
      addOrUpdateStateMachineTranslation(
        listElements[index].value,
        newValue + ''
      );
    }
    if (isStringListInput || isMultiplePercentInput) {
      addOrUpdateStateMachineTranslation(listElements[index], newValue + '');
    }
    if (isCompareMultipleNumbers || isAiGenericClassification) {
      listElements[index] = newValue;
    }
    updateElements(listElements, listKeys, false, listKeyNumbers);
  };

  /** update icon of a user defined emotion */
  const updateListEntryIcon = (icon: string, index: number) => {
    listElements[index].icon = icon as EmotionEnum;
    setListElements(listElements);
    updateElements(listElements, listKeys, true, listKeyNumbers);
  };

  /** remove an emotion from the list of self-defined emotions */
  const removeListEntry = useCallback(
    (index: number) => {
      let newListKeys = cloneDeep(listKeys);
      // if we edit numbers, we do not have to handle translations
      if (isCompareMultipleNumbers) {
        listElements.splice(index, 1);
        newListKeys.splice(index, 1);
        setListElements(listElements);
        setListKeys(newListKeys);
        updateElements(listElements, newListKeys, true, listKeyNumbers);
      } else {
        // for any other type of input, we have to handle translations,
        // even depending on the component

        if (isEmotionInput) {
          listElements.splice(index, 1);
        }

        // isStringListInput is true for all stateEntries but for EmotionInput
        if (isStringListInput || isMultiplePercentInput) {
          listElements.splice(index, 1);
          if (newListKeys) {
            // listkeys = keyTexts
            // remove listkeys ==> even if we use buttonTexts for listkeys,
            // this is not valid for duplicated modules! Thus, we have
            // to carefully remove listkeys, too.
            newListKeys.splice(index, 1);
          }
        }

        // if there are keyNumbers, remove the element at index
        if (hasKeyNumbers) {
          listKeyNumbers.splice(index, 1);
          setListKeyNumbers(listKeyNumbers);
        }

        setListElements(listElements);
        updateElements(listElements, newListKeys, true, listKeyNumbers);
        setListKeys(newListKeys);
      }
    },
    [
      hasKeyNumbers,
      isCompareMultipleNumbers,
      isEmotionInput,
      isMultiplePercentInput,
      isStringListInput,
      listElements,
      listKeyNumbers,
      listKeys,
      updateElements,
    ]
  );

  /** do not use index for generation although this is better readable
   * because it generates chaos when you delete elements within a list */
  const getTranslationKey = () =>
    `${generateRandomString(4)}.listeditor.${generateRandomString(2)}`;

  /** add a user defined emotion with default values */
  const addListEntry = () => {
    if (listElements.length > maxListElements) {
      alert(
        intl.formatMessage(
          {
            id: 'builder.moduleeditor.propertiessidebar.listeditordialog.maxelements',
            defaultMessage:
              'Es dürfen maximal {maxListElements} Einträge eingegeben werden',
          },
          { maxListElements }
        )
      );
      return;
    }

    if (isCompareMultipleNumbers) {
      listElements.push(0); // add 0 as neww number
      listKeys.push('mulitcompare.' + generateRandomString(4));
    } else {
      if (isAiGenericClassification) {
        // classificationKeys are not translated !!!
        listElements.push(''); // add empty string as default value
        listKeys.push('aigenclass.' + generateRandomString(4));
      } else {
        const translationKey = getTranslationKey();
        if (isEmotionInput) {
          listElements.push({
            type: 'emotioninput',
            icon: EmotionEnum.INDIFFERENT,
            value: translationKey,
            starred: false,
          } as EmotionProps);
        }
        if (isStringListInput || isMultiplePercentInput) {
          listElements.push(translationKey);
          if (listKeys) {
            listKeys.push('keytext.' + generateRandomString(4));
          }
        }
        // if there are keyNumbers, add an element with value of index
        if (hasKeyNumbers) {
          listKeyNumbers.push(listKeyNumbers.length);
          setListKeyNumbers(listKeyNumbers);
        }

        addOrUpdateStateMachineTranslation(
          translationKey,
          intl.formatMessage({
            id: 'builder.moduleeditor.propertiessidebar.listeditordialog.placeholdertext',
            defaultMessage: 'Text',
          })
        );
      }
    }
    setListElements(listElements);
    setListKeys(listKeys);
    updateElements(listElements, listKeys, true, listKeyNumbers);
  };

  // if we leave a single edit field, we autosave, even before the save
  // button was clicked. This is necessary to not loose content when
  // switching window foxus between edits of different entries.
  const onBlurAutoSave = () => {
    autoSave();
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage {...listEditorMessages.dialogTitle} />
      </DialogTitle>
      <DialogContent sx={{ maxHeight: '600px', minHeight: '600px' }}>
        <div style={{ paddingBottom: '15px' }}>
          <Typography component="span">{listDescription}</Typography>
        </div>
        <Stack spacing={2}>
          {listElements.map((element, index) => {
            const keyval = isEmotionInput ? element.value : element;
            return (
              <Stack direction="row" key={`dirstack${index}`}>
                <Stack direction="row" key={'StackKey_' + index}>
                  <TextField
                    id={`donotcatchpasteevent_listeditordialog_${index}`}
                    key={`${nodeType}.OwnListSection.${index}`}
                    value={
                      isCompareMultipleNumbers || isAiGenericClassification
                        ? keyval
                        : intl.formatMessage({
                            id: keyval,
                          })
                    }
                    onChange={(evt) =>
                      updateListEntryText(evt.target.value, index)
                    }
                    onBlur={onBlurAutoSave}
                    type={isCompareMultipleNumbers ? 'number' : 'string'}
                    label={textLabel}
                    sx={isAiGenericClassification ? { width: '500px' } : {}}
                  />
                  {isEmotionInput && (
                    <Box
                      sx={{ marginLeft: '10px' }}
                      key={`OwnListSectioniconselect+${index}`}
                    >
                      <EvoachSelect
                        key="iconselect"
                        onChange={(evt) =>
                          updateListEntryIcon(evt.target.value as string, index)
                        }
                        value={element.icon}
                        maxRows={5}
                      >
                        {Object.keys(EmotionEnum).map(
                          (emotionicon: string, index: number) => (
                            <EvoachMenuItem
                              key={`Emotionicon${index}`}
                              value={emotionicon}
                            >
                              <EmotionIcon
                                emotion={emotionicon as EmotionEnum}
                              />
                            </EvoachMenuItem>
                          )
                        )}
                      </EvoachSelect>
                    </Box>
                  )}
                  {hasKeyNumbers && (
                    <TextField
                      sx={{ marginLeft: '10px' }}
                      id={`donotcatchpasteevent_listeditordialogkeynumbers_${index}`}
                      key={`OwnListSectionKeynumbers+${index}`}
                      value={listKeyNumbers[index]}
                      onChange={(evt) => {
                        updateListKeyNumbers(evt.target.value, index);
                      }}
                      onBlur={onBlurAutoSave}
                      type="number"
                      label={intl.formatMessage({
                        id: 'builder.moduleeditor.propertiessidebar.listeditordialog.numericcorrespondent',
                        defaultMessage: 'Numerische Entsprechung',
                      })}
                    />
                  )}
                </Stack>
                <IconButton onClick={() => removeListEntry(index)}>
                  <RemoveCircleIcon />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
        <IconButton onClick={addListEntry} disabled={listElements.length > 20}>
          <AddCircleIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          <FormattedMessage {...listEditorMessages.dialogSaveButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
