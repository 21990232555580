import { useQuery } from 'react-query';

import { ProgramInstancePermission } from '../../entities';
import { authorizedGet } from '../authorizedApi';

/**
 * get all program instance permissions by programInstanceId, i.e.
 *    - owns
 *    - invitations
 *    - accepted invitations
 *
 * This list is used to organize the "Manage Coachees" dialog in the
 * Coaching Program Instances List
 *
 * @param {string} programInstanceId to be fetched
 * @returns isLoading, isError, error, programInstancePermissions: ProgramInstancePermission[], refetch
 */
export const useFetchProgramInstancePermissionsByProgramInstance = (
  programInstanceId: string = ''
) => {
  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `programinstancepermissions-${programInstanceId}`,
    async () => {
      if (!programInstanceId || programInstanceId.trim() === '')
        return undefined;

      const url = `/programinstance/${programInstanceId}/allpermissions`;

      const fetchProgramInstncePermissionsCall = authorizedGet(url);
      const response = await fetchProgramInstncePermissionsCall();
      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    programInstancePermissions: data as ProgramInstancePermission[],
    refetch,
  };
};
