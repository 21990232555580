import React, { useContext, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { AccountContext, RoleEnum } from '../account';
import { AppRoutes, ReceptionRoutesArray } from '../routing/routes';
import { ProductTourManager } from '../producttour/ProductTourManager';
import { AppHeaderContext } from '../layout/AppHeaderContext';

import { useReceptionTourPage } from './ReceptionPage.tour';

const receptionPageMessages = defineMessages({
  moduletab: {
    id: 'builder.pages.reception.moduletab.header',
    defaultMessage: 'Chatbot Themen',
  },
  templatetab: {
    id: 'builder.pages.reception.templatetab.header',
    defaultMessage: 'Vorlagen',
  },
  templatestab: {
    id: 'builder.pages.reception.templatestab.header',
    defaultMessage: 'Vorlagen-Verwaltung',
  },
  invitationtabold: {
    id: 'builder.pages.reception.invitationtab.headerold',
    defaultMessage: 'Einladungen',
  },
  invitationtab: {
    id: 'builder.pages.reception.invitationtab.header',
    defaultMessage: 'Einladungen',
  },
  sharedmodulestab: {
    id: 'builder.pages.reception.sharedmodulestab.header',
    defaultMessage: 'Geteilte Chatbots',
  },
  loadingaccount: {
    id: 'builder.pages.reception.loadaccount.hint',
    defaultMessage: 'Anmeldung läuft',
  },
  loadingaccount1: {
    id: 'builder.pages.reception.loadaccount2.hint',
    defaultMessage:
      'Danke, dass du evoach benutzt. Anwendung wird gestartet ...',
  },
  loadingaccount2: {
    id: 'builder.pages.reception.loadaccount3.hint',
    defaultMessage:
      'Heute scheint es etwas länger zu dauern. Vielen Dank für deine Geduld ...',
  },
  assettab: {
    id: 'builder.pages.reception.assettab.header',
    defaultMessage: 'Dateien',
  },
  analyticstab: {
    id: 'builder.pages.reception.analyticstab.header',
    defaultMessage: 'Analysen',
  },
  programtab: {
    id: 'builder.pages.reception.programtab.header',
    defaultMessage: 'Coaching Programme',
  },
  programinstancetab: {
    id: 'builder.pages.reception.programinstancetab.header',
    defaultMessage: 'Laufende Programme',
  },
  sharedsessiontab: {
    id: 'builder.pages.reception.sharedsessiontab.header',
    defaultMessage: 'Geteilte Protokolle',
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} component="span">
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const getTabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const ReceptionPage: React.FC = () => {
  const history = useLocation();

  const { tourid, toursteps } = useReceptionTourPage();
  const { setAppHeaderTitle, setAppHeaderHomeroute } =
    useContext(AppHeaderContext);
  useEffect(() => {
    setAppHeaderHomeroute('/reception');
  });

  useEffect(() => {
    const newTab = '/' + history.pathname.split('/').pop();
    if (newTab === '/reception') {
      setTabValue(ReceptionRoutesArray[0]);
    } else {
      setTabValue(newTab);
    }
  }, [history]);

  const intl = useIntl();

  const {
    isLoading: accountIsLoading,
    hasRole,
    failureCount,
  } = useContext(AccountContext);

  const [tabvalue, setTabValue] = React.useState(ReceptionRoutesArray[0]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setAppHeaderTitle(
      intl.formatMessage({
        id: 'builder.layout.app.header',
        defaultMessage: 'Chatbot Creator',
      })
    );
  }, [intl, setAppHeaderTitle]);

  if (accountIsLoading) {
    if (failureCount > 0 && failureCount <= 4)
      return (
        <Typography>
          {intl.formatMessage(receptionPageMessages.loadingaccount1)}
        </Typography>
      );

    if (failureCount > 4 && failureCount <= 6)
      return (
        <Typography>
          {intl.formatMessage(receptionPageMessages.loadingaccount2)}
        </Typography>
      );

    return (
      <Typography>
        {intl.formatMessage(receptionPageMessages.loadingaccount)}
      </Typography>
    );
  }

  if (!hasRole(RoleEnum.COACH)) {
    return (
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'builder.pages.reception.notacoach',
          defaultMessage:
            'Du musst als Coach registriert sein, um den Chatbot Editor zu nutzen. Bitte setz Dich mit deinem Ansprechpartner bei evoach in Verbindung.',
        })}
      </Typography>
    );
  }

  const pendingInvitation = localStorage.getItem('evoachModuleInvitation');
  if (pendingInvitation && pendingInvitation !== '') {
    return <Navigate to={AppRoutes.INVITATION + '/pending'} />;
  }

  const pendingProgramInvitation = localStorage.getItem(
    'evoachProgramInvitation'
  );
  if (pendingProgramInvitation && pendingProgramInvitation !== '') {
    return <Navigate to={AppRoutes.PROGRAMINVITATION + '/pending'} />;
  }

  return (
    <>
      <ProductTourManager tourid={tourid} toursteps={toursteps} />
      <Box sx={{ width: '100%' }} component="span">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} component="span">
          <Tabs value={tabvalue} onChange={handleChange} aria-label="Reception">
            <Tab
              value={ReceptionRoutesArray[0]}
              to={'/reception' + ReceptionRoutesArray[0]}
              component={Link}
              label={intl.formatMessage(receptionPageMessages.moduletab)}
              {...getTabProps(0)}
              data-tut="reactour__moduletab"
            />
            <Tab
              value={ReceptionRoutesArray[1]}
              to={'/reception' + ReceptionRoutesArray[1]}
              component={Link}
              label={intl.formatMessage(receptionPageMessages.programtab)}
              {...getTabProps(1)}
              data-tut="reactour__programtab"
            />
            <Tab
              value={ReceptionRoutesArray[3]}
              to={'/reception' + ReceptionRoutesArray[3]}
              label={intl.formatMessage(receptionPageMessages.invitationtab)}
              component={Link}
              {...getTabProps(3)}
            />
            <Tab
              value={ReceptionRoutesArray[4]}
              to={'/reception' + ReceptionRoutesArray[4]}
              label={intl.formatMessage(receptionPageMessages.sharedmodulestab)}
              component={Link}
              {...getTabProps(4)}
            />
            <Tab
              value={ReceptionRoutesArray[5]}
              to={'/reception' + ReceptionRoutesArray[5]}
              label={intl.formatMessage(receptionPageMessages.assettab)}
              component={Link}
              {...getTabProps(5)}
            />
            <Tab
              value={ReceptionRoutesArray[6]}
              to={'/reception' + ReceptionRoutesArray[6]}
              label={intl.formatMessage(receptionPageMessages.sharedsessiontab)}
              component={Link}
              {...getTabProps(6)}
            />
            <Tab
              value={ReceptionRoutesArray[7]}
              to={'/reception' + ReceptionRoutesArray[7]}
              label={intl.formatMessage(receptionPageMessages.templatetab)}
              component={Link}
              {...getTabProps(7)}
            />
            <Tab
              value={ReceptionRoutesArray[8]}
              to={'/reception' + ReceptionRoutesArray[8]}
              label={intl.formatMessage(receptionPageMessages.analyticstab)}
              component={Link}
              {...getTabProps(8)}
            />
            {hasRole(RoleEnum.EVOACHADMIN) && (
              <Tab
                value={ReceptionRoutesArray[9]}
                to={'/reception' + ReceptionRoutesArray[9]}
                component={Link}
                label="Analytics (evoach only)"
                {...getTabProps(9)}
              />
            )}
          </Tabs>
        </Box>
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[0]} />
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[1]} />
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[2]} />
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[3]} />
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[4]} />
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[5]} />
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[6]} />
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[7]} />
        <TabPanel value={tabvalue} index={ReceptionRoutesArray[8]} />
        {hasRole(RoleEnum.EVOACHADMIN) && (
          <TabPanel value={tabvalue} index={ReceptionRoutesArray[9]} />
        )}

        <Outlet />
      </Box>
    </>
  );
};
