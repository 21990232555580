import { EvoachButton } from '@evoach/ui-components';
import { Box, Divider, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { validate as isUuid } from 'uuid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { authorizedGet } from '../../../api/authorizedApi';
import { ModulePermission } from '../../../entities/ModulePermissions';
import { DevToolsContext } from '../../../devtools/DevToolsContext';
import { ModuleProps } from '../../../entities/Module';

import { AdminCopyModuleByJson } from './AdminCopyModuleByJson';

export const AdminSingleModule: React.FC = () => {
  const { l } = useContext(DevToolsContext);

  const [moduleid, setModuleid] = useState<string>('');

  const [module, setModule] = useState<ModuleProps | undefined>();

  const [permissions, setPermissions] = useState<ModulePermission[]>([]);

  const startcheck = async () => {
    if (isUuid(moduleid)) {
      // load module info
      const urlM = `/module/summary/${moduleid}?language=DE`;
      const fetchModule = authorizedGet(urlM);
      const responseM = await fetchModule();
      const dataM = await responseM.json();
      setModule(dataM);
      // load permissions
      const url = `/module/${moduleid}/modulepermission`;
      const fetchModuleCall = authorizedGet(url);
      const response = await fetchModuleCall();
      const data = await response.json();
      setPermissions(data);

      // TODO add list of translations, i.e., check whether all languages are really available
    } else {
      alert('This is not a valid ModuleId');
    }
  };

  const displayBool = (val: boolean | undefined) => {
    if (val === undefined) {
      return <>undefined</>;
    }
    if (val) {
      return <CheckIcon htmlColor="#00FF00" />;
    } else {
      return <CloseIcon htmlColor="#FF0000" />;
    }
  };

  const getPermType = (perm: ModulePermission) => {
    if (!perm.invitinginvitationid && !perm.invitationid && perm.owns) {
      return !perm.invitinginvitationid ? 'Owner' : 'Co-Creator';
    } else {
      if (perm.invitationid) {
        return 'Created invitation';
      } else {
        if (perm.invitinginvitationid) {
          return perm.owns ? 'Co-Creator' : 'Accepted invitation';
        } else {
          if (!!perm.hide) {
            return 'Implicit'; // PROD-1809
          } else {
            return 'Unknown type';
          }
        }
      }
    }
  };

  //console.log(module);

  l(permissions);

  return (
    <Box>
      <Box>
        <TextField
          value={moduleid}
          onFocus={() => {
            setModule(undefined);
          }}
          onChange={(evt: any) => {
            setModuleid(evt.target.value);
          }}
          placeholder="Please enter a module id"
          size="small"
          label="Module Id"
        />
        <EvoachButton sx={{ marginLeft: '20px' }} onClick={startcheck}>
          Check
        </EvoachButton>
      </Box>
      {!moduleid || moduleid === '' || module === undefined ? (
        'Please enter a module id'
      ) : (
        <>
          <Divider sx={{ marginTop: '30px' }} />
          <Typography variant="h5">Module Info</Typography>
          <Box sx={{ marginTop: '20px' }}>
            <Box>
              <table>
                <tbody>
                  <tr>
                    <td>Module Id</td>
                    <td>{module?.moduleid}</td>
                  </tr>
                  <tr>
                    <td>Mid</td>
                    <td>{module?.mid}</td>
                  </tr>
                  <tr>
                    <td>Template or Module?</td>
                    <td>{module?.issubmodule ? 'Template' : 'Module'}</td>
                  </tr>
                  <tr>
                    <td>Defaultmodule?</td>
                    <td>{displayBool(module?.isdefaultmodule)}</td>
                  </tr>
                  <tr>
                    <td>Categories</td>
                    <td> {module?.metadata.categories}</td>
                  </tr>
                  <tr>
                    <td>Languages</td>
                    <td> {module?.metadata.moduleLanguages}</td>
                  </tr>
                  <tr>
                    <td>Default language</td>
                    <td> {module?.metadata.defaultLanguage}</td>
                  </tr>
                  <tr>
                    <td>Display progress bar if public</td>
                    <td> {displayBool(module?.publicsessionprogressbar)}</td>
                  </tr>
                </tbody>
              </table>
              <AdminCopyModuleByJson moduleid={module?.moduleid} />
            </Box>
          </Box>
          <Divider sx={{ marginTop: '30px' }} />
          <Typography variant="h5">Module Permissions</Typography>
          <Box sx={{ marginTop: '20px' }}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th align="left">Type</th>
                  <th align="left">Account</th>
                  <th align="left">Inviting Account</th>
                  <th>Owns</th>
                  <th>Duplicate</th>
                  <th>Edit</th>
                  <th>Invite</th>
                  <th>Start Session</th>
                  <th align="left">Created</th>
                  <th align="left">InvitationId</th>
                </tr>
              </thead>
              <tbody>
                {permissions &&
                  permissions.map((perm: ModulePermission) => (
                    <tr key={perm.modulepermissionid}>
                      <td align="left">
                        <>{getPermType(perm)}</>
                      </td>
                      <td>
                        <>
                          {perm.account === null
                            ? 'none'
                            : perm.account.email + ` (${perm.account.aid})`}
                        </>
                      </td>
                      <td>
                        <>
                          {perm.invitingaccount === null
                            ? 'none'
                            : perm.invitingaccount.email +
                              ` (${perm.invitingaccount.aid})`}
                        </>
                      </td>
                      <td align="center">{displayBool(perm.owns)}</td>
                      <td align="center">
                        {displayBool(perm.CoachPermission_CopyModuleAsTemplate)}
                      </td>
                      <td align="center">
                        {displayBool(perm.CoachPermission_EditOriginalModule)}
                      </td>
                      <td align="center">
                        {displayBool(
                          perm.CoachPermission_InviteCoacheesToSessions
                        )}
                      </td>
                      <td align="center">
                        {displayBool(perm.CoacheePermission_StartSession)}
                      </td>
                      <td>
                        <>{perm.tscreated}</>
                      </td>
                      <td>
                        <>
                          {perm.invitationid ??
                            perm.invitinginvitationid ??
                            '-'}
                        </>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Box>
        </>
      )}
    </Box>
  );
};
