import { Handle, NodeProps, Position } from 'reactflow';
import {
  PolarchartDisplay,
  PolarchartDisplayProps,
  defaultPolarChartOptions,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  PolarChartNode react-flow node for module builder graph canvas
*/

const seriesDummy = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

export const PolarChartNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  const tickAmount: number = payload.tickAmount
    ? parseInt(payload.tickAmount)
    : 10;

  const series = seriesDummy.slice(0, payload.labels.length);

  const props: PolarchartDisplayProps = {
    labels: payload.labels
      ? payload.labels.map((label: string) => {
          return translateKey(intl, label);
        })
      : [
          intl.formatMessage({
            id: 'builder.nodes.polarchart.autogen.hint',
            defaultMessage: 'Labels werden generiert',
          }),
        ],
    series: series,
    options: {
      ...defaultPolarChartOptions,
      yaxis: {
        ...defaultPolarChartOptions.yaxis,
        max: tickAmount,
        tickAmount: tickAmount,
      },
    },
    title: intl.formatMessage({
      id: payload.title ?? 'builder.nodes.polarChartDisplay.state.title',
      defaultMessage: '',
    }),
  };

  const autoGenProps: PolarchartDisplayProps = {
    width: parseInt(payload.width),
    labels: [
      intl.formatMessage({
        id: 'builder.nodes.polarchart.autogen.hint',
        defaultMessage: 'Labels werden generiert',
      }),
    ],
    series: [5],
    options: {
      ...defaultPolarChartOptions,
      yaxis: {
        ...defaultPolarChartOptions.yaxis,
        max: tickAmount,
        tickAmount: tickAmount,
      },
    },
    title: intl.formatMessage({
      id: payload.title ?? 'builder.nodes.polarChartDisplay.state.title',
      defaultMessage: '',
    }),
  };

  // values will be autogenerated
  const autoGen = !(
    (payload.getLabelsFrom === undefined ||
      payload.getLabelsFrom.trim() === '') &&
    (payload.getSeriesFrom === undefined || payload.getSeriesFrom.trim() === '')
  );

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        {!autoGen && <PolarchartDisplay {...props} />}
        {autoGen && <PolarchartDisplay {...autoGenProps} />}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="polarcharthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
