import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  CoachMessage react-flow node for module builder graph canvas
*/

export const RandomCoachMessageSingleNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const stringArrayMesssage = intl.formatMessage({
    id: 'builder.nodes.elements.randomCoachMessage.hint',
    defaultMessage:
      'Dieser Knoten definiert eine Liste von Nachrichten, von denen eine Nachricht zufällig ausgewählt und als Coach Message im Chat angezeigt wird.',
  });

  const testListHint = intl.formatMessage({
    id: 'builder.nodes.elements.randomCoachMessage.listhint',
    defaultMessage: 'Die Nachrichten lauten:',
  });

  const translatedLabels = payload.messages
    ? payload.messages.map((message: string) => translateKey(intl, message))
    : [''];

  return (
    <>
      <Handle
        type="target"
        id="messageinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ maxWidth: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {stringArrayMesssage}
          <strong>
            {(payload.saveResultTo + '').startsWith('evoachechokey')
              ? ''
              : payload.saveResultTo}
          </strong>
          <br />
          {testListHint}
          <br />
          <ol>
            {translatedLabels.map((label: string, index: number) => (
              <li key={'label' + index}>{label}</li>
            ))}
          </ol>
        </Typography>
      </div>
      <Handle
        id="randommessageouthandle"
        className={classes['react-flow__handle']}
        type="source"
        position={Position.Bottom}
        isConnectable={nodeProps.isConnectable}
      />
    </>
  );
};
