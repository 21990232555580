import { Handle, NodeProps, Position } from 'reactflow';
import {
  CharacterIcon,
  CanvasEditor,
  UninitializedCharacter,
  CharacterRole,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  CanvasEditorNode - react-flow node for module builder graph canvas
   requires input from CharacterSelector
*/

export const CanvasEditorNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const dummyElements: UninitializedCharacter[] = [
    {
      name: 'Character 1',
      icon: CharacterIcon.BEAR,
      role: [CharacterRole.VICTIM],
      statement: 'Meine Meinung wird selten respektiert...',
    },
    {
      name: 'Character 2',
      icon: CharacterIcon.DRAGON,
      role: [CharacterRole.APPROACHER],
      statement: 'Ich möchte nur helfen...',
    },
  ];
  return (
    <>
      <Handle
        type="target"
        id="canvasinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '600px', height: '490px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <CanvasEditor
          isEcho={true}
          initialCharacters={dummyElements}
          buttonText={translateKey(intl, payload.buttonText)}
          tooltipFullscreenMessage={translateKey(
            intl,
            payload.tooltipFullscreenMessage
          )}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="canvasouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
