import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  Box,
} from '@mui/material';

interface AccountMetainfosDialogProps {
  metainfos: Record<string, any>;
  isOpen: boolean;
  onClose: Function;
}

export const AccountMetainfosDialog: React.FC<AccountMetainfosDialogProps> = ({
  metainfos,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'builder.admin.AccountMetainfosDialog.title',
            defaultMessage: 'Metainformationen des Benutzers',
          })}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" component="span">
            {intl.formatMessage({
              id: 'builder.admin.AccountMetainfosDialog.desc',
              defaultMessage:
                'In dieser Liste finden sich alle Metainfos eines Benutzers, etwa die URL Parameter aus dem Registrierungslink, den der Benutzer verwendet hatte.',
            })}

            <Box sx={{ marginTop: '20px' }}>
              {!metainfos || Object.keys(metainfos).length === 0 ? (
                <>
                  {intl.formatMessage({
                    id: 'builder.admin.AccountMetainfosDialog.nouser',
                    defaultMessage:
                      'Für den Benutzer sind keine Metainfos vorhanden.',
                  })}
                </>
              ) : (
                <pre>{JSON.stringify(metainfos, null, 2)}</pre>
              )}
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            {intl.formatMessage({
              id: 'builder.admin.AccountMetainfosDialog.button.close',
              defaultMessage: 'Schließen',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
