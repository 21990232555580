import { useIntl } from 'react-intl';
import { defineMessages } from '@formatjs/intl';
import React, { useState } from 'react';
import { EvoachButton } from '@evoach/ui-components';

import { authorizedDelete } from '../../../api/authorizedApi';
import { ConfirmDeletionDialog } from '../modules/ConfirmDeletionDialogue';

const deleteButtonMessages = defineMessages({
  deleteButtonText: {
    id: 'builder.reception.assets.deletebutton.text',
    defaultMessage: 'Datei löschen',
  },
});

/*
    ! Delete Button
    deletes the selected invitation
*/

interface AssetDeleteButtonProps {
  assetid: string;
  onDelete?: Function;
}

export const AssetDeleteButton: React.FC<AssetDeleteButtonProps> = ({
  assetid,
  onDelete,
}) => {
  const intl = useIntl();

  const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] =
    useState<boolean>(false);

  const deleteAsset = async (assetid: string) => {
    const deletionurl: RequestInfo = `/asset/${assetid}`;

    const deleteCall = authorizedDelete(deletionurl);
    setIsDeletionConfirmationOpen(true);
    try {
      await deleteCall();
    } catch (_reason: any) {
      alert(
        intl.formatMessage({
          id: 'builder.reception.assets.deletion.error',
          defaultMessage: 'Beim Löschen der Datei ist ein Fehler aufgetreten.',
        })
      );
    }

    if (onDelete) {
      onDelete();
    }
  };

  if (!assetid) {
    return <></>;
  }

  return (
    <>
      <EvoachButton
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => {
          setIsDeletionConfirmationOpen(true);
        }}
      >
        {intl.formatMessage(deleteButtonMessages.deleteButtonText)}
      </EvoachButton>
      <ConfirmDeletionDialog
        title={intl.formatMessage({
          id: 'builder.reception.assets.deletiondialogue.title',
          defaultMessage: 'Datei löschen',
        })}
        message={intl.formatMessage({
          id: 'builder.reception.assets.deletiondialogue.message',
          defaultMessage:
            'Wenn du das Löschen bestätigst, wird die Datei gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.',
        })}
        open={isDeletionConfirmationOpen}
        onConfirm={() => deleteAsset(assetid)}
        onClose={() => {
          setIsDeletionConfirmationOpen(false);
        }}
      />
    </>
  );
};
