import { defineMessages } from '@formatjs/intl';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import {
  AppRoutes,
  InvitationRouteParams,
  ReceptionRoutes,
} from '../routing/routes';
import {
  ApiError,
  useAcceptInvitationMutation,
  useAcceptProgramInvitationMutation,
} from '../api';
import { ReceptionButton } from '../components/ReceptionButton';

const invitationPageMessages = defineMessages({
  invitationFailedHint: {
    id: 'builder.pages.invitation.failed.hint',
    defaultMessage: 'Akzeptieren der Einladung fehlgeschlagen',
  },
  invitationIdNoCorrect: {
    id: 'builder.pages.invitation.failed.wrongid',
    defaultMessage: 'Dein Einladungscode scheint falsch zu sein.',
  },
  unknownError: {
    id: 'builder.pages.invitation.failed.unknown',
    defaultMessage: 'Irgendetwas ist schiefgegangen.',
  },
  contactCoach: {
    id: 'builder.pages.invitation.failed.contactCoach',
    defaultMessage:
      'Bitte kontaktiere den Coach, von dem du die Einladung erhalten hast.',
  },
  acceptanceInProgress: {
    id: 'builder.pages.invitation.success.acceptanceInProgress',
    defaultMessage:
      'Deine Einladung wird akzeptiert. Bitte einen Augenblick Geduld ...',
  },
});

export const InvitationPage: React.FC = () => {
  const { pathname } = useLocation();

  // 1) get Invitation from URL
  const { invitationId: invitationIdByUrl } =
    useParams<InvitationRouteParams>();
  // 2) get invitation from localstorage
  const invitationIdByLocalStorage = localStorage.getItem(
    'evoachModuleInvitation'
  );

  const programInvitationIdByLocalStorage = localStorage.getItem(
    'evoachProgramInvitation'
  );

  const isModuleInvitation = pathname.startsWith(`${AppRoutes.INVITATION}`);
  const isProgramInvitation = pathname.startsWith(
    `${AppRoutes.PROGRAMINVITATION}`
  );

  // if invitation is in URL, take it, if not, use localstorage
  const invitationId =
    invitationIdByUrl && invitationIdByUrl !== 'pending'
      ? invitationIdByUrl
      : invitationIdByLocalStorage;

  // if invitation is in URL, take it, if not, use localstorage
  const programInvitationId =
    invitationIdByUrl && invitationIdByUrl !== 'pending'
      ? invitationIdByUrl
      : programInvitationIdByLocalStorage;

  const { mutate, isError, error, isLoading, data } =
    useAcceptInvitationMutation();

  const {
    mutate: programMutate,
    isError: programIsError,
    error: programError,
    isLoading: programisLoading,
  } = useAcceptProgramInvitationMutation();

  // accept invitation for a module
  useEffect(() => {
    if (!isModuleInvitation) return;
    localStorage.setItem('evoachModuleInvitation', '');
    if (invitationId) {
      const localStorageHighlightKey = 'evoach.creator.modules.highlighted';
      mutate(invitationId, {
        // write recently accepted modules to localStorage to show badge on card
        onSuccess: async (data: any) => {
          const previouslyHighlightedModules = JSON.parse(
            localStorage.getItem(localStorageHighlightKey) ?? '[]'
          );
          const moduleIds = data.map((modulepermission: any) => {
            return {
              moduleid: modulepermission.module.moduleid,
              highlightedDate: Date.now(),
            };
          });
          const stringifiedModuleIds = JSON.stringify(
            previouslyHighlightedModules.concat(moduleIds)
          );
          localStorage.setItem(localStorageHighlightKey, stringifiedModuleIds);
        },
      });
    }
  }, [invitationId, mutate, invitationIdByLocalStorage, isModuleInvitation]);

  // accept invitation for a program
  useEffect(() => {
    if (!isProgramInvitation) return;
    localStorage.setItem('evoachProgramInvitation', '');
    if (programInvitationId) {
      programMutate(programInvitationId);
    }
  }, [
    programInvitationId,
    programMutate,
    programInvitationIdByLocalStorage,
    isProgramInvitation,
  ]);

  return (
    <Typography component="span" variant="h6">
      {(isError || programIsError) && (
        <div>
          <FormattedMessage {...invitationPageMessages.invitationFailedHint} />
          {(error instanceof ApiError && error.httpStatus === 400) ||
          (programError instanceof ApiError &&
            programError.httpStatus === 400) ? (
            <div style={{ marginTop: 15 }}>
              <FormattedMessage
                {...invitationPageMessages.invitationIdNoCorrect}
              />
            </div>
          ) : (
            <div style={{ marginTop: 15 }}>
              <FormattedMessage {...invitationPageMessages.unknownError} />
            </div>
          )}

          <div style={{ marginTop: 15 }}>
            <FormattedMessage {...invitationPageMessages.contactCoach} />
          </div>
        </div>
      )}

      {(isLoading || programisLoading) && (
        <div>
          <FormattedMessage {...invitationPageMessages.acceptanceInProgress} />
        </div>
      )}
      {!isLoading && !isError && data !== undefined && isModuleInvitation && (
        <Navigate to={`${AppRoutes.RECEPTION}/${ReceptionRoutes.MODULES}`} />
      )}
      {!programisLoading && !programIsError && isProgramInvitation && (
        <Navigate
          to={`${AppRoutes.RECEPTION}/${ReceptionRoutes.COACHING_PROGRAMS}`}
        />
      )}
      {(isLoading || isError || programIsError || programisLoading) && (
        <div style={{ marginTop: 15 }}>
          <ReceptionButton />
        </div>
      )}
    </Typography>
  );
};
