import { CoachMessage } from '@evoach/ui-components';
import { useIntl } from 'react-intl';
import { Handle, NodeProps, Position } from 'reactflow';

import { Description } from '../Description';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  SendMailNode 
*/

export const CreateCertificateNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ width: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Description nodetype={nodeType} propname="description" />
        <div>
          <CoachMessage
            message={translateKey(intl, payload.message)}
          ></CoachMessage>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
