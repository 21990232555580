import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';
import { keycloak } from '../../keycloak';
import { Subscription } from '../../entities/Subscription';

export const useFetchSubscriptions = (accountid?: string) => {
  const { isLoading, data, isError, error, failureCount, refetch } = useQuery<
    Subscription[],
    Error
  >(
    'subscription' + accountid,
    async () => {
      try {
        // if an accountid is provided, try to get it explicitely
        // and check for evoach admin rights, get for token else
        const getUrl = accountid
          ? `/account/${accountid}/subscriptions`
          : '/subscription/';
        const getSubscriptions = authorizedGet(getUrl);
        const getSubscriptionResponse = await getSubscriptions();

        const res = await getSubscriptionResponse.json();

        return res;
      } catch (_error) {
        return [];
      }
    },
    {
      retry: 1,
      // only load when we have an auth token
      enabled: !!keycloak.token,
    }
  );

  return {
    isLoading,
    subscriptions: data,
    isError,
    error,
    failureCount,
    refetch,
  };
};
