import { generateRandomString } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.multiButton.state.buttonText',
    defaultMessage: 'Weiter',
  },
  buttonText1: {
    id: 'builder.nodes.multiButton.state.buttonText1',
    defaultMessage: 'Erster Button',
  },
  buttonText2: {
    id: 'builder.nodes.multiButton.state.buttonText2',
    defaultMessage: 'Zweiter Button',
  },
});

export const multiButtonStateEntry: StateEntryProps = {
  type: 'renderMultiButton',
  temporary: true,
  payload: {
    buttonText: 'builder.nodes.multiButton.state.buttonText',
    buttonTexts: [
      'builder.nodes.multiButton.state.buttonText1',
      'builder.nodes.multiButton.state.buttonText2',
    ],
    saveResultTo: 'multibutton_result',
    keyTexts: [
      'multibutton.' + generateRandomString(4),
      'multibutton.' + generateRandomString(4),
    ],
    keyNumbers: [0, 1],
  },
  saveResultType: ReadWriteType.string,
  displayName: 'Multi Button',
  nodeType: 'multiButtonStateEntry',
  handleOutCount: 'dynamic',
};
