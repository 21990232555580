import React, { ChangeEvent } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';

import { StateEntryProps } from '../../nodes';
import { Description } from '../../Description';

//import { PropertyHeaderLine } from '.';

/*
  ! Options Input

  all properties in payload are provided as editiable controls (except variables)
  the options input is dedicated to values in the payload section that are of type boolean
*/

interface OptionsInputProps {
  entry: StateEntryProps;
  elementId: string;
  updatePayloadValue: Function;
}

export const OptionsInput: React.FC<OptionsInputProps> = ({
  entry,
  elementId,
  updatePayloadValue,
}) => {
  // when a checkbox is toggled, set corresponding payload value
  const togglePayloadValue = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!event.target || !event.target.id || event.target.id === '') {
      return;
    }
    const payloadKey = event.target.id;
    updatePayloadValue(checked, payloadKey, entry);
    //entry.payload[payloadKey] = checked;
  };

  // is echo should not be deisplayed
  const invalidPayloadKeys = ['isEcho'];

  return (
    <Stack spacing={1}>
      <FormGroup>
        {Object.keys(entry.payload)
          .filter((payloadkey) => !invalidPayloadKeys.includes(payloadkey))
          .filter(
            (payloadKey) => typeof entry.payload[payloadKey] === 'boolean'
          )
          .map((payloadKey) => (
            <Box key={`ElementIdAndKey-${elementId}-${payloadKey}`}>
              <Description nodetype={entry.nodeType} propname={payloadKey} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={entry.payload[payloadKey] as boolean}
                    onChange={togglePayloadValue}
                    key={payloadKey}
                    id={payloadKey}
                    disabled={payloadKey === 'showAiBadge'}
                  />
                }
                label={payloadKey}
              />
            </Box>
          ))}
      </FormGroup>
    </Stack>
  );
};
