import { evoachDefaultTheme } from '@evoach/ui-components';
import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';

import { useTableStyles } from '../../tableHelper';

interface LabelEditProps {
  initialLabels: string[];
  onUpdate?: Function;
  width?: string;
  sx?: Record<string, string>;
  noLabelsText?: string;
  onClose?: Function;
}

export const LabelEdit: React.FC<LabelEditProps> = ({
  initialLabels = [],
  onUpdate,
  width = '100%',
  sx = {},
  noLabelsText = '',
  onClose,
}) => {
  const classes = useTableStyles();
  const intl = useIntl();

  const [labels, setLabels] = useState<string[]>(initialLabels);

  const [currentInput, setCurrentInput] = useState<string>('');

  const handleKey = (keyCode: number) => {
    if (keyCode !== 13) return;

    if (currentInput.trim() === '') {
      if (onUpdate) onUpdate(labels);
      if (onClose) onClose();
      setCurrentInput('');
      return;
    }

    setLabels(labels.concat(currentInput));
    setCurrentInput('');
  };

  useEffect(() => {
    if (onUpdate) onUpdate(labels);
  }, [labels, onUpdate]);

  const removeLabel = (index: number) => {
    setLabels(
      labels.filter((_val: string, lindex: number) => lindex !== index)
    );
  };

  const getLabelJsx = (label: string, index: number) => {
    return (
      <Box
        key={'labelbox' + index}
        component="span"
        sx={{
          marginTop: '10px',
          marginLeft: '5px',
          padding: '10px',
          border: `1px solid ${evoachDefaultTheme.palette.secondary.main}`,
          typography: 'body2',
          backgroundColor: evoachDefaultTheme.palette.secondary.light,
          borderRadius: '5px',
        }}
      >
        <Box
          component="span"
          sx={{
            fontWeight: '500',
            verticalAlign: 'middle',
            padding: '5px',
          }}
        >
          {label}
        </Box>
        <ClearIcon
          className={classes.cursorPointer}
          sx={{
            fontSize: '18px',
            verticalAlign: 'middle',
            marginRight: '2px',
            marginLeft: 'auto',
          }}
          onClick={() => removeLabel(index)}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...sx,
        width: width,
        padding: '5px',
        border: `1px solid ${evoachDefaultTheme.palette.secondary.main}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {labels.length > 0 ? (
          labels.map((label: string, index: number) =>
            getLabelJsx(label, index)
          )
        ) : (
          <Typography>{noLabelsText}</Typography>
        )}
      </Box>

      <TextField
        sx={{ marginTop: '20px', width: '100%' }}
        value={currentInput}
        onChange={(evt: any) => setCurrentInput(evt.target.value)}
        onKeyUp={(evt: any) => handleKey(evt.keyCode)}
        focused
        placeholder={intl.formatMessage({
          id: 'builder.labeledit.placeholder',
          defaultMessage:
            'Gib ein Label ein und bestätige mit der Return-Taste.',
        })}
      />
    </Box>
  );
};
