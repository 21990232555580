import { Node } from 'reactflow';
import makeStyles from '@mui/styles/makeStyles';
import { evoachDefaultTheme } from '@evoach/ui-components';
import { IntlShape } from 'react-intl';

import { StateProps } from './base.state';

import {
  arrayCombineNumberNodeStateEntry,
  arrayCombineStringNodeStateEntry,
} from '.';

/**
 * NOT_YET_TRANSLATED returns the message that something is not translated
 * based on the current UI locale
 *
 * @param {IntlShape} intl you can get intl by useIntl() by React intl
 * @returns
 */
export const NOT_YET_TRANSLATED = (intl: IntlShape) => {
  return intl.formatMessage({
    id: 'builder.global.notyettranslated',
    defaultMessage: 'Noch nicht übersetzt.',
  });
};

/**
 * translateKey returns the translation for key. If key does not exist
 * in intl.messages, the NOT_YET_TRANSLATED(intl) message is returned.
 * This prevents the browser from printing miles of error messages if a
 * translation is not yet available for a module.
 *
 * @param {IntlShape} intl you can get intl by useIntl() by React intl
 * @param {string | undefined} key - translation key
 * @returns
 */
export const translateKey = (intl: IntlShape, key: string | undefined) => {
  if (!key || key.trim() === '') {
    return 'no key';
  }
  if (intl.messages[key]) {
    return intl.formatMessage({
      id: key,
      defaultMessage: NOT_YET_TRANSLATED(intl),
    });
  } else {
    return NOT_YET_TRANSLATED(intl);
  }
};

/**
 * calculate percentage of handle position in a node for dynamic numbers
 * of hanldes.
 *
 * @param {number} handleCount number of handles in sum
 * @param {number} handleIndex index of handle for whoch the position should be calculated for
 * @returns
 */
export const calculateHandlePosition = (
  handleCount: number,
  handleIndex: number
) => `${(100 / (handleCount + 1)) * (handleIndex + 1)}%`;

/**
 * base node data props
 */
export interface NodeDataProps {
  state: StateProps;
  nodeMiniMapColor?: string;
  nodeproperties?: object;
  nodeType?: string;
  label?: any;
  helperStates: StateProps[]; // s. PROD-1124, needed for StartNewSession
}

/**
 * returns displayName of node as defined in .state. file
 *
 * @param {NodeDataProps} nodeDataProps
 * @returns {string} displayName of node as defined in .state
 */
export const getDisplayName = (nodeDataProps: NodeDataProps): string => {
  let retVal;
  switch (nodeDataProps.nodeType) {
    case 'arrayCombineStringNodeStateEntry':
      retVal = arrayCombineStringNodeStateEntry.displayName;
      break;
    case 'arrayCombineNumberNodeStateEntry':
      retVal = arrayCombineNumberNodeStateEntry.displayName;
      break;
    default:
      retVal = 'unknown node: ' + nodeDataProps.nodeType;
  }
  return retVal + '';
};

export const getDataAsNodeDataProps = (node: Node<any>) =>
  node.data as NodeDataProps;

export const useGlobalStyles = makeStyles({
  root: {
    borderRadius: '6px',
    padding: 5,
    borderColor: '#000000',
    backgroundColor: '#cccccc' /*coach-message-background-color*/, // f2f2f2
  },
  'react-flow__handle': {
    width: 15,
    height: 15,
  },
  phasenodestyle: {
    borderRadius: '6px',
    padding: 5,
    borderStyle: 'dotted',
    borderWidth: 1,
    borderColor: '#000000',
    backgroundColor: evoachDefaultTheme.palette.primary.light,
    color: '#000000',
    fontFamily: 'Roboto',
  },
  controlelementstyle: {
    borderRadius: '6px',
    padding: 5,
    borderStyle: 'dotted',
    borderWidth: 1,
    borderColor: '#000000',
    backgroundColor: evoachDefaultTheme.palette.success.light,
    color: '#000000',
    fontFamily: 'Roboto',
  },
  'react-flow_controlement_handle': {
    width: 10,
    height: 10,
    backgroundColor: evoachDefaultTheme.palette.success.light,
  },
  greennodestyle: {
    padding: 5,
    borderStyle: 'dotted',
    borderWidth: 1,
    borderColor: '#000000',
    backgroundColor: '#00ff00',
    color: '#ffffff',
    fontFamily: 'Roboto',
  },
  elementselectionbutton: {
    paddingLeft: '20px',
    borderStyle: 'solid',
    borderWidth: 0,
    borderColor: '#FFFFFF',
    color: '#808080',
    fontFamily: 'Roboto',
  },
  elementselectionheader: {
    padding: 3,
    color: '#000000',
    fontFamily: 'Roboto',
    fontWeight: 'inherit',
    fontSize: '12px',
    marginTop: '5px',
  },
  coachother: {
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '7rem',
    display: 'block',
    height: '7rem',
    width: '7rem',
    margin: '0 0 15px 0',
  },
  selectStyletrue: {
    borderColor: evoachDefaultTheme.palette.secondary.main,
    borderWidth: '5px',
    borderStyle: 'solid',
    borderRadius: '6px',
    padding: 5,
    backgroundColor: '#cccccc',
  },
  phasenodestyleselected: {
    borderColor: evoachDefaultTheme.palette.secondary.main,
    borderWidth: '5px',
    borderStyle: 'solid',
    borderRadius: '6px',
    padding: 5,
    backgroundColor: evoachDefaultTheme.palette.primary.light,
    color: '#000000',
    fontFamily: 'Roboto',
  },
  controlelementstyleselected: {
    borderColor: evoachDefaultTheme.palette.secondary.main,
    borderWidth: '5px',
    borderStyle: 'solid',
    borderRadius: '4px',
    padding: 5,
    backgroundColor: evoachDefaultTheme.palette.success.light,
    color: '#000000',
    fontFamily: 'Roboto',
  },
  handletextstyle: {
    backgroundColor: '#000000',
    width: '80px',
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: 'Roboto',
    position: 'relative',
    left: '-40px',
  },
});
