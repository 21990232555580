import { useIntl } from 'react-intl';
import { defineMessages } from '@formatjs/intl';
import React, { useEffect, useState } from 'react';
import { EvoachButton } from '@evoach/ui-components';

import { authorizedPut } from '../../../api/authorizedApi';

const makeDefaultButtonMessages = defineMessages({
  defaultButtonTextOn: {
    id: 'builder.reception.assets.defaultbutton.on',
    defaultMessage: 'Datei veröffentlichen',
  },
  defaultButtonTextOff: {
    id: 'builder.reception.assets.defaultbutton.off',
    defaultMessage: 'Veröffentlichung beenden',
  },
});

/*
    ! Default Button
    toggles isDefaultAsset of an asset with assetid
    Making some asset an default asset is displayed as "publish asset"
*/

interface AssetDefaultButtonProps {
  assetid: string;
  isDefault: boolean;
  onUpdate?: Function;
}

export const AssetDefaultButton: React.FC<AssetDefaultButtonProps> = ({
  assetid,
  onUpdate,
  isDefault,
}) => {
  const intl = useIntl();

  const [isDefaultAsset, setIsDefaultAsset] = useState<boolean>(isDefault);

  useEffect(() => {
    setIsDefaultAsset(isDefault);
  }, [isDefault]);

  const updateAsset = async (assetid: string) => {
    const updateUrl: RequestInfo = `/asset/${assetid}`;

    const updateCall = authorizedPut(updateUrl, {
      isDefaultAsset: !isDefaultAsset,
    });
    await updateCall();

    setIsDefaultAsset(!isDefaultAsset);

    if (onUpdate) {
      onUpdate();
    }
  };

  if (!assetid) {
    return <></>;
  }

  return (
    <EvoachButton
      variant="contained"
      color="secondary"
      size="small"
      onClick={async () => {
        await updateAsset(assetid);
      }}
    >
      {!isDefaultAsset
        ? intl.formatMessage(makeDefaultButtonMessages.defaultButtonTextOn)
        : intl.formatMessage(makeDefaultButtonMessages.defaultButtonTextOff)}
    </EvoachButton>
  );
};
