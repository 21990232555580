import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from '@mui/material';

export enum EditSessionObjectType {
  CHATBOT = 0,
  PROGRAM = 1,
}

interface EditSessionDialogProps {
  dialogType: number;
  isOpen: boolean;
  onClose: Function;
  editSessionObjectType: EditSessionObjectType;
}

export const EditSessionDialog: React.FC<EditSessionDialogProps> = ({
  dialogType,
  isOpen,
  onClose,
  editSessionObjectType,
}) => {
  const intl = useIntl();

  if (!isOpen) {
    return null;
  }

  const editSessionObjectVariants = {
    nominativeCase: {
      program: intl.formatMessage({
        id: 'builder.editsessiondialog.editobject.program',
        defaultMessage: 'Programm',
      }),
      chatbot: intl.formatMessage({
        id: 'builder.editsessiondialog.editobject.chatbot',
        defaultMessage: 'Chatbot',
      }),
    },
    accusativeCase: {
      program: intl.formatMessage({
        id: 'builder.editsessiondialog.editobject.programaccusative',
        defaultMessage: 'das Programm',
      }),

      chatbot: intl.formatMessage({
        id: 'builder.editsessiondialog.editobject.chatbotaccusative',
        defaultMessage: 'den Chatbot',
      }),
    },
  };

  const editObjectNominative =
    editSessionObjectType === EditSessionObjectType.CHATBOT
      ? editSessionObjectVariants.nominativeCase.chatbot
      : editSessionObjectVariants.nominativeCase.program;

  const editObjectAccusative =
    editSessionObjectType === EditSessionObjectType.CHATBOT
      ? editSessionObjectVariants.accusativeCase.chatbot
      : editSessionObjectVariants.accusativeCase.program;

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogTitle
        sx={{
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        }}
      >
        {intl.formatMessage(
          {
            id: 'builder.editsessiondialog.title',
            defaultMessage: '{obj} wird schon editiert',
          },
          { obj: editObjectNominative }
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {dialogType === 1 && (
            <>
              {intl.formatMessage(
                {
                  id: 'builder.editsessiondialog.content1',
                  defaultMessage:
                    'Du hast bereits einen Editor mit diesem {obj} geöffnet. ',
                },
                { obj: editObjectNominative }
              )}
            </>
          )}
          {dialogType === 2 && (
            <>
              {intl.formatMessage(
                {
                  id: 'builder.editsessiondialog.content2',
                  defaultMessage: 'Eine andere Person editiert {obj} bereits. ',
                },
                { obj: editObjectAccusative }
              )}
            </>
          )}
          {dialogType === 3 && (
            <>
              {intl.formatMessage(
                {
                  id: 'builder.editsessiondialog.content3',
                  defaultMessage: 'Mehrere Personen editieren {obj} bereits. ',
                },
                { obj: editObjectAccusative }
              )}
            </>
          )}
          {dialogType === 4 && (
            <>
              {intl.formatMessage(
                {
                  id: 'builder.editsessiondialog.content4',
                  defaultMessage:
                    'Ich kann aktuell leider nicht prüfen, ob {obj} schon jemand editiert. ',
                },
                { obj: editObjectAccusative }
              )}
            </>
          )}

          {intl.formatMessage(
            {
              id: 'builder.editsessiondialog.contentall',
              defaultMessage:
                'Möchtest du {obj} trotzdem im Editor öffnen? Es kann zu Fehlern kommen, wenn man {obj} gleichzeitig in zwei Editoren bearbeitet.',
            },
            { obj: editObjectAccusative }
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            onClose(false); // FALSE => do not edit
          }}
        >
          {intl.formatMessage({
            id: 'builder.editsessiondialog.button.close',
            defaultMessage: 'Abbrechen',
          })}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            onClose(true); // TRUE => start editor anyway
          }}
        >
          {intl.formatMessage({
            id: 'builder.editsessiondialog.button.edit',
            defaultMessage: 'Editor trotzdem öffnen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
