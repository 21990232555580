import { Typography } from '@mui/material';
import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  CompareNumbers react-flow node for module builder graph canvas
*/

export const CompareNumbersNode: React.FC<NodeProps<any>> = (
  nodeProps: NodeProps<any>
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const stringArrayMesssage = intl.formatMessage({
    id: 'builder.nodes.elements.compareNumbers.hint',
    defaultMessage:
      'Dieser Knoten vergleicht zwei Zahlenwerte miteinander. Ausgehend vom Ergebnis dieses Vergleichs wird einer der drei Ausgänge genommen:',
  });

  const left = intl.formatMessage({
    id: 'builder.nodes.elements.compareNumbers.left',
    defaultMessage: 'Links',
  });

  const right = intl.formatMessage({
    id: 'builder.nodes.elements.compareNumbers.right',
    defaultMessage: 'Rechts',
  });

  const center = intl.formatMessage({
    id: 'builder.nodes.elements.compareNumbers.center',
    defaultMessage: 'Mitte',
  });

  const pleaseChooseVariable = intl.formatMessage({
    id: 'builder.nodes.elements.compareNumbers.pleaseChooseVariable',
    defaultMessage: 'Bitte wähle rechts die zu vergleichenden Variablen aus.',
  });

  const payload = nodeProps.data.state.entry[0].payload;
  const firstValueToCompare = payload.firstValueToCompare ?? [''];
  const secondValueToCompare = payload.secondValueToCompare ?? [''];

  return (
    <>
      <Handle
        type="target"
        id="comparenumbersin"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ maxWidth: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {stringArrayMesssage}
          <br />
          {firstValueToCompare !== '' && secondValueToCompare !== '' && (
            <ul>
              <li>
                {firstValueToCompare} &lt; {secondValueToCompare}: {left}
              </li>
              <li>
                {firstValueToCompare} = {secondValueToCompare}: {center}
              </li>
              <li>
                {firstValueToCompare} &gt; {secondValueToCompare}: {right}
              </li>
            </ul>
          )}
          {(firstValueToCompare === '' || secondValueToCompare === '') && (
            <>
              <br></br> {pleaseChooseVariable}
            </>
          )}
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="comparenumbersout1"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '25%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="comparenumbersout2"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '50%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="comparenumbersout3"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '75%' }}
      />
    </>
  );
};
