import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { evoachDefaultTheme, ObjectCardFlags } from '@evoach/ui-components';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../routing/routes';
import { useFetchModulesQuery } from '../../../api/module/useFetchModulesQuery';
import { ModuleProps } from '../../../entities/Module';

import { getCheck, isQaCheckFine } from './qaTools';

const useStyles = makeStyles({
  longTextNoWrap: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '18vw',
    textOverflow: 'ellipsis',
  },
  noWrapChildren: { '& > *': { whiteSpace: 'nowrap' } },
  noWrap: { whiteSpace: 'nowrap' },
  cursorPointer: {
    cursor: 'pointer',
    color: evoachDefaultTheme.palette.secondary.main,
    textDecoration: 'underline',
    textDecorationColor: evoachDefaultTheme.palette.secondary.main,
  },
});

export const AnalysisModuleTranslationQuality: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  let navigate = useNavigate();

  const { modules } = useFetchModulesQuery('all');

  const [checkedModules, setCheckedModules] = useState<
    Record<string, string | undefined>
  >({});

  const [qaChecks, setQaChecks] = useState<Record<string, any>>({});

  //
  // if modules are loaded, init check status to "undefined"
  //
  useEffect(() => {
    if (modules === undefined) {
      return;
    }
    const checked: Record<string, string | undefined> = {};
    modules.forEach((mod: ModuleProps) => {
      checked[mod.moduleid] = undefined;
    });
    setCheckedModules(checked);
  }, [modules]);

  const jumpToTranslationManagement = (
    defaultLanguage: string,
    moduleid: string
  ) => {
    if (!moduleid || moduleid.trim() === '') return;
    navigate(`${AppRoutes.TRANSLATION}/${moduleid}/${defaultLanguage}`);
  };

  //
  // "check all" button clickes
  //
  const batchCheckQa = () => {
    // check ?
    checkAll();
  };

  /**
   * check all modules that have more than one language
   * @returns updates states
   */
  const checkAll = async () => {
    // only check modules that have more than 1 language
    const mods = rows
      .filter((row: any) => row.moduleLanguages.length > 1)
      .map((row: any) => row.moduleid);
    if (mods.length === 0) return;
    async function checkAll() {
      const res: Record<string, any> = {
        checkedModules: {},
        qaChecks: {},
      };
      for (let i = 0; i < mods.length; i++) {
        // TODO async call check
        if (checkedModules[mods[i]] === undefined) {
          res.checkedModules[mods[i]] = 'checked';
          res.qaChecks[mods[i]] = await getCheck(mods[i]);
        }
      }
      return res;
    }

    // collect all results and then proceed
    checkAll().then((res: any) => {
      setQaChecks(res.qaChecks);
      setCheckedModules(res.checkedModules);
    });
  };

  //
  // calculate list as combination of the module list and the check status
  // and the check results
  //
  const rows = useMemo(() => {
    if (modules === undefined) {
      return [];
    }

    return modules
      .filter(
        (module: ModuleProps) =>
          module &&
          (module.permissions[0].owns === true ||
            module.permissions[0].CoachPermission_EditOriginalModule === true)
      )
      .filter(
        (module: ModuleProps) =>
          module && !!module.metadata && !!module.metadata.title
      )
      .map((module: ModuleProps) => {
        return {
          moduleid: module.moduleid,
          title: module.metadata.title,
          defaultLanguage: module.metadata.defaultLanguage,
          moduleLanguages: module.metadata.moduleLanguages,
          checkStatus: checkedModules[module.moduleid],
          checkResult: qaChecks[module.moduleid],
        };
      });
  }, [checkedModules, modules, qaChecks]);

  return (
    <>
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'builder.analytics.moduletranslationquality.description',
          defaultMessage:
            'Mit dieser Analyse kannst du prüfen, ob du deine Module vollständig übersetzt hat. Die Analyse schaut nach, ob in jeder Modulsprache alle Texte vorhanden sind und ob es noch gleiche Texte in verschiedenen Sprachen gibt.',
        })}
        <br />
        {intl.formatMessage({
          id: 'builder.analytics.moduletranslationquality.gobuttonhint',
          defaultMessage:
            'Wenn du auf "Analyse starten" klickst, werden all deine Module und Vorlagen aufgelistet und eines nach dem anderen geprüft.',
        })}
        <br />
        <Button onClick={batchCheckQa}>
          {intl.formatMessage({
            id: 'builder.analytics.moduletranslationquality.gobutton',
            defaultMessage: 'Analyse starten',
          })}
        </Button>
      </Typography>
      <div
        id="modtransqaanalyticschart"
        style={{ marginLeft: '5px', marginTop: '30px' }}
      >
        {modules !== undefined && rows.length > 0 ? (
          <TableContainer
            component={Paper}
            style={{
              minWidth: '800px',
              minHeight: '100%',
              overflowX: 'initial',
            }}
          >
            <Table
              stickyHeader
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                  paddingBottom: '0.5rem',
                },
              }}
            >
              <colgroup>
                <col style={{ width: '30%', textAlign: 'left' }} />
                <col style={{ width: '3%', textAlign: 'left' }} />
                <col style={{ width: '6%', textAlign: 'left' }} />
                <col style={{ width: '20%', textAlign: 'left' }} />
              </colgroup>
              <TableHead className={classes.noWrapChildren}>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.analytics.moduletranslationquality.table.title',
                      defaultMessage: 'Titel',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.analytics.moduletranslationquality.table.defaultlang',
                      defaultMessage: 'Default',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.analytics.moduletranslationquality.table.availlang',
                      defaultMessage: 'Verfügbar',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'builder.analytics.moduletranslationquality.table.check',
                      defaultMessage: 'Prüfung',
                    })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((module: any) => {
                  return (
                    <TableRow key={'qatrans' + module.moduleid}>
                      <TableCell>
                        {intl.formatMessage({
                          id: module.title,
                        })}
                      </TableCell>
                      <TableCell>
                        <ObjectCardFlags flags={[module.defaultLanguage]} />
                      </TableCell>
                      <TableCell>
                        <ObjectCardFlags flags={module.moduleLanguages} />
                      </TableCell>
                      <TableCell>
                        {module.checkStatus === undefined && (
                          <>
                            {intl.formatMessage({
                              id: 'builder.analytics.moduletranslationquality.table.notchecked',
                              defaultMessage: 'Nicht geprüft.',
                            })}
                          </>
                        )}
                        {module.checkStatus === 'tobechecked' && (
                          <>
                            {intl.formatMessage({
                              id: 'builder.analytics.moduletranslationquality.table.tobechecked',
                              defaultMessage: 'Zu prüfen ...',
                            })}
                          </>
                        )}
                        {module.checkStatus === 'checked' &&
                          isQaCheckFine(module.checkResult) && (
                            <Typography color="primary">
                              {intl.formatMessage({
                                id: 'builder.analytics.moduletranslationquality.table.everythingok',
                                defaultMessage: 'Alles ok.',
                              })}
                            </Typography>
                          )}
                        {module.checkStatus === 'checked' &&
                          !isQaCheckFine(module.checkResult) && (
                            <Link
                              sx={{
                                color:
                                  evoachDefaultTheme.palette.secondary.main,
                                textDecoration: 'underline',
                                textDecorationColor:
                                  evoachDefaultTheme.palette.secondary.main,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                jumpToTranslationManagement(
                                  module.defaultLanguage,
                                  module.moduleid
                                );
                              }}
                            >
                              {intl.formatMessage({
                                id: 'builder.analytics.moduletranslationquality.table.issuesfound',
                                defaultMessage: 'Nicht alles ok',
                              })}
                            </Link>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            {intl.formatMessage({
              id: 'builder.analytics.moduletranslationquality.table.nomodules',
              defaultMessage:
                'Du hast noch keine eigenen Module oder Vorlagen erstellt.',
            })}
          </>
        )}
      </div>
    </>
  );
};
