export enum AppRoutes {
  BLANK = '/',
  LOGIN = '/login',
  SIGNUP = '/signup',
  SUBSCRIBE = '/subscribe',
  NEWBUILDER = '/newmodule',
  BUILDER = '/moduleeditor',
  RECEPTION = '/reception',
  INVITATION = '/invitation',
  PROFILE = '/profile',
  DISCLAIMER = '/disclaimer',
  TRANSLATION = '/translation',
  NEWPROGRAM = '/newprogram',
  PROGRAMEDITOR = '/programeditor',
  PROGRAMINVITATION = '/programinvitation',
  PLANS = '/plans',
}

export enum ReceptionRoutes {
  MODULES = 'modules',
  COACHING_PROGRAMS = 'coachingprograms',
  CURRENT_PROGRAMS = 'currentprograms',
  INVITATIONS = 'invitations',
  SHARED_MODULES = 'sharedmodules',
  ASSETS = 'assets',
  SHARED_SESSIONS = 'sharedsessions',
  TEMPLATES = 'templates',
  ANALYTICS = 'analytics',
  ANALYTICS_EVOACH = 'analyticsevoach',
}

export const ReceptionRoutesArray = Object.values(ReceptionRoutes).map(
  (route) => '/' + route
) as string[];

export const AppRoutesArray = Object.values(AppRoutes).map(
  (route) => '/' + route
) as string[];

export const ModuleRouteParamName = 'moduleId' as const;
export const ModuleRouteLangParamName = 'moduleLanguage' as const;
export interface ModuleRouteParams {
  [index: string]: string | undefined;
  [ModuleRouteParamName]: string | undefined;
  [ModuleRouteLangParamName]: string;
}

export const ProgramRouteParamName = 'programId' as const;
export const ProgramRouteTypeParamName = 'programRouteType' as const;
export enum ProgramRouteTypeEnum {
  PROGRAM = 'program',
  INSTANCE = 'instance',
}
export interface ProgramRouteParams {
  [index: string]: string | ProgramRouteTypeEnum;
  [ProgramRouteParamName]: string;
  [ProgramRouteTypeParamName]: ProgramRouteTypeEnum;
}

export const InvitationRouteParamName = 'invitationId' as const;
export interface InvitationRouteParams {
  [index: string]: string | undefined;
  [InvitationRouteParamName]: string;
}
