/**
 * EditSessionType describes for what object editor the object id is for
 * only used to improve readability
 */
export enum EditSessionTypeEnum {
  MODULE = 0,
  PROGRAM = 1,
  PROGRAMINSTANCE = 2,
}

/**
 *
 */
export interface EditSession {
  /**
   * internal edit session id in DB
   */
  eseid: number;

  /**
   * editsessionid is a UUID for the specific session and helps to externally
   * identify it
   */
  editsessionid: string;

  /**
   * reference to the actual object (module, program or program instance)
   * that is affected in this session
   */
  objectid: string;

  /**
   * accountid is not used as foreign DB key !
   */
  accountid?: string;

  /**
   * 0 = module
   * 1 = program
   * 2 = program instance
   */
  editsessiontype: EditSessionTypeEnum;

  // if row is created, set creation timestamp as session start

  tsstart: Date;

  // potential end timestamp of a session

  tsend: Date;
}
