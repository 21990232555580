import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';

/**
 * get a translation of a module
 * @param {string} moduleId to be fetched
 * @param {string} moduleLanguage to be fetched, if not set, the current state machine translation from TranslationContext is used
 * @returns isLoading, isError, error, module, refetch
 */
export const useFetchTranslation = (
  moduleId?: string,
  moduleLanguage?: string
) => {
  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `module-translation-${moduleId}-${moduleLanguage}`,
    async () => {
      if (!moduleId || moduleId.trim() === '') return {};
      if (!moduleLanguage || moduleLanguage.trim() === '') return {};

      const url = `/module/${moduleId}/translation?language=${moduleLanguage.toUpperCase()}`;

      const fetchModuleCall = authorizedGet(url);
      const response = await fetchModuleCall();

      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    translations: data as Record<string, any>,
    refetch,
  };
};
