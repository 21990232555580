/*
  ModuleBuilderWrapper

  Pls check call hierarchy with responsibilities below

  BuilderPage                     - page for router, check route params, load module
!    |_ ModuleBuilderWrapper       - management of module translation which is independent of the UI translation
      |_ ModuleBuilder            - keep state of Reract Flow nodes and edges, manage undo logic
        |_ ModuleEditor           - provide toolbar on top of editor canvas
          |_ ModuleEditorpane     - ReactFlow provider + ReactFlow component + management of canvas actions

*/

import React, { useContext, useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { useIntl } from 'react-intl';

import { ModuleProps } from '../entities/Module';
// Global Translation context to set title in AppHeader
import { TranslationContext as GlobalTranslationContext } from '../intl/TranslationContext';
import { TranslationProps } from '../entities/';
import { DevToolsContext } from '../devtools/DevToolsContext';
import { authorizedPatch } from '../api';
import { AppHeaderContext } from '../layout/AppHeaderContext';

import { ModuleBuilder } from './ModuleBuilder';
// Local Translation context to manage translation of graph and state machine
import { TranslationContext } from './stateMachineTranslationContext';

interface ModuleBuilderWrapperProps {
  module: ModuleProps;
}

export const ModuleBuilderWrapper: React.FC<ModuleBuilderWrapperProps> = ({
  module,
}) => {
  const intl = useIntl();

  //const { setMetadata } = useContext(ModuleMetadataContext);
  const { setAppHeaderTitle } = useContext(AppHeaderContext);

  const { setModuleMessages, locale, setGlobalStateMachineLocale } = useContext(
    GlobalTranslationContext
  );
  const { l } = useContext(DevToolsContext);

  // stateMachine locale is defined by module - we support more module languages than UI languages.
  // That's why we have to distinguihs these translations. If there is no translation information
  // available per module, we set module locale to current UI locale
  const [stateMachineLocale, setStateMachineLocale] = React.useState<string>(
    module.translations && module.translations.length > 0
      ? module.translations[0].lang
      : intl.locale
  );

  useEffect(() => {
    setGlobalStateMachineLocale(stateMachineLocale);
  }, [locale, setGlobalStateMachineLocale, stateMachineLocale]);

  // stateMachineTranslation => for texts in nodes of a module
  const [stateMachineTranslation, setStateMachineTranslation] =
    React.useState<TranslationProps>(
      module.translations.find(
        (translation) => translation.lang === stateMachineLocale
      )?.statemachinetranslation ?? {}
    );

  // metadataTranslation => for texts like title and description of a module
  const [metadataTranslation, setMetadataTranslation] =
    React.useState<TranslationProps>(
      module.translations.find(
        (translation) => translation.lang === stateMachineLocale
      )?.metadatatranslation ?? {}
    );

  /**
   * add or update state machine translations
   *
   * @param {string} key = translation key to be added or updated
   * @param {string} value = value to be set. If value is empty string '' it is set to space ' '.
   */
  const addOrUpdateStateMachineTranslation = (key: string, value: string) => {
    // it is very important to have a space ' ' and not an empty strin '' in the next line
    // empty strings '' are not handled as content by the translation. If you would assign
    // an empty string '' to a translation key, the translation would fail and return
    // the key as result

    l('ModuleBuilderWrapper: addOrUpdateStateMachineTranslation');
    l('stateMachineLocale: ' + stateMachineLocale);
    l('GlobalTranslationContext Locale: ' + locale);
    l('intl.locale: ' + intl.locale);
    l('module.translations[0].lang: ' + module.translations[0].lang);
    l(key + ' = ' + (value && value.length > 0 ? value : ' '));

    stateMachineTranslation[key] = value && value.length > 0 ? value : ' ';
    setStateMachineTranslation({ ...stateMachineTranslation });
    /* appendModuleMessages(
      stateMachineTranslation as unknown as Record<string, string>
    ); */
    setModuleMessages({
      ...metadataTranslation,
      ...stateMachineTranslation,
    });
    //setSingleTranslation(key, value);
  };

  /**
   * add or update metadata translations
   *
   * @param {string} key = translation key to be added or updated
   * @param {string} value = value to be set. If value is empty string '' it is set to space ' '.
   */
  const addOrUpdateMetaDataTranslation = (key: string, value: string) => {
    // it is very important to have a space ' ' and not an empty strin '' in the next line
    // empty strings '' are not handled as content by the translation. If you would assign
    // an empty string '' to a translation key, the translation would fail and return
    // the key as result

    l('ModuleBuilderWrapper: addOrUpdateMetaDataTranslation');
    l(key + ' = ' + value);
    l('stateMachineLocale: ' + stateMachineLocale);
    l('GlobalTranslationContext Locale: ' + locale);
    l('intl.locale: ' + intl.locale);
    l('module.translations[0].lang: ' + module.translations[0].lang);

    metadataTranslation[key] = value && value.length > 0 ? value : ' ';
    setMetadataTranslation({ ...metadataTranslation });

    //setSingleTranslation(key, value);
    setModuleMessages({
      ...metadataTranslation,
      ...stateMachineTranslation,
    });
  };

  /**
   * remove single state machine translation by key
   *
   * @param {string} key to be removed
   * @returns {TranslationProps} deleted element
   */
  const removeStateMachineTranslation = (key: string): TranslationProps => {
    l('ModuleBuilderWrapper: remove state machine tranaltion, key = ' + key);
    const returnValue = { [key]: stateMachineTranslation[key] };
    delete stateMachineTranslation[key];
    setStateMachineTranslation(stateMachineTranslation);
    return returnValue;
  };

  /**
   * remove single metadata translation by key
   *
   * @param {string} key to be removed
   * @returns {TranslationProps} deleted element
   */
  const removeMetaDataTranslation = (key: string): TranslationProps => {
    const returnValue = { [key]: metadataTranslation[key] };
    delete metadataTranslation[key];
    setMetadataTranslation(metadataTranslation);
    return returnValue;
  };

  // this useEffect updates the translations if a module is reloaded.
  // Putting stateMachineTranslation in the dependency array would to an
  // infinite loop as stateMachineTranslation would be changed on any render.
  // Pls do not remove eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    l(
      'ModuleBuilderWrapper: useEffect if mod.trans0.lang changes: ' +
        module.translations[0].lang
    );
    l(module.translations);
    // it is very important to preserve stateMachineTranslation here!
    // reason: PROD-1221
    setStateMachineTranslation({
      ...module.translations[0].statemachinetranslation,
    });
    setMetadataTranslation({
      ...module.translations[0].metadatatranslation,
    });
    setStateMachineLocale(module.translations[0].lang);

    //setMetadata(module.metadata);
    // PROD-1693 refactor
    setAppHeaderTitle(
      module?.metadata?.title
        ? intl.formatMessage({ id: module?.metadata?.title })
        : null
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [l, module, module.translations]);

  /**
   * save all matching translations of the template with moduleid = templateid
   * for the current module (moduleid provided in context as global variable)
   *
   * @param {string} templateId - module id of the template
   */
  const updateModuleWithTemplateTranslations = async (
    templateId: string,
    newTranslationKeys: Record<string, string>[]
  ) => {
    const url = `/module/${module.moduleid}/templatetranslation?templateid=${templateId}&currentlanguage=${stateMachineLocale}`;

    const addFurtherTemplateTranslationsToModule = authorizedPatch(
      url,
      newTranslationKeys
    );
    await addFurtherTemplateTranslationsToModule();

    //const data = await response.json();
  };

  // provide the Translation Context for the Module (not the global UI!)
  return (
    <TranslationContext.Provider
      value={{
        stateMachineTranslation: stateMachineTranslation,
        metadataTranslation: metadataTranslation,
        stateMachineLocale: stateMachineLocale,
        addOrUpdateStateMachineTranslation: addOrUpdateStateMachineTranslation,
        addOrUpdateMetaDataTranslation: addOrUpdateMetaDataTranslation,
        removeStateMachineTranslation: removeStateMachineTranslation,
        removeMetaDataTranslation: removeMetaDataTranslation,
        setStateMachineLocale: setStateMachineLocale,
        updateModuleWithTemplateTranslations:
          updateModuleWithTemplateTranslations,
      }}
    >
      <ReactFlowProvider>
        <ModuleBuilder module={module} />
      </ReactFlowProvider>
    </TranslationContext.Provider>
  );
};
