import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { Node } from 'reactflow';

import { ReadWriteType, StateEntryProps } from '../../nodes';
import { ListEditorDialog } from '../ListEditorDialog/ListEditorDialog';

import { PropertyHeaderLine } from './PropertyHeadLine';
import { GetValueFromSelect } from './GetValueFromSelect';

export interface MultiplePercentageScaleInputInputProps {
  entry: StateEntryProps;
  updateElementValues: Function;
  updateElementValuesAutoGeneration: Function;
  nodes: Node<any>[];
}

export const MultiplePercentageScaleInputInput: React.FC<
  MultiplePercentageScaleInputInputProps
> = ({
  entry,
  updateElementValues,
  updateElementValuesAutoGeneration,
  nodes,
}) => {
  const intl = useIntl();

  const [autoGenerate, setAutoGenerate] = useState<boolean>(
    !(
      entry.payload.getLabelsFrom === undefined ||
      entry.payload.getLabelsFrom.trim() === ''
    )
  );

  const toggleMultiselectEntryElementsGeneration = () => {
    setAutoGenerate(!autoGenerate);
  };

  const scaleNames = entry.payload.scaleNames ?? [];

  const [isListEditorOpen, setIsListEditorOpen] = useState<boolean>(false);

  const [getLabelsFrom, setGetLabelsFrom] = useState<string>(
    entry.payload.getLabelsFrom ?? ''
  );

  const [getSeriesFrom, setGetSeriesFrom] = useState<string>(
    entry.payload.getSeriesFrom ?? ''
  );

  const updateAutoGenValueSeries = (value: string, _index: number) => {
    setGetSeriesFrom(value);
    updateElementValuesAutoGeneration('series', value);
  };

  const updateAutoGenValueLabels = (value: string, _index: number) => {
    setGetLabelsFrom(value);
    updateElementValuesAutoGeneration('labels', value);
  };

  const handleListEditorDialog = () => {
    setIsListEditorOpen(!isListEditorOpen);
  };

  const v2JSX = (
    <Typography variant="body2" component="span">
      {intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.multiplepercentageinput.autoGenLabels.hint',
        defaultMessage: 'Titel für die Prozent-Schieberegler',
      })}
      <GetValueFromSelect
        updateValueFrom={updateAutoGenValueLabels}
        currentValue={getLabelsFrom}
        getValueType={ReadWriteType.stringArray}
        nodes={nodes}
      />
      {intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.multiplepercentageinput.autoGenSeries.hint',
        defaultMessage: 'Werte für die Prozent-Schieberegler (optional)',
      })}
      <GetValueFromSelect
        updateValueFrom={updateAutoGenValueSeries}
        currentValue={getSeriesFrom}
        getValueType={ReadWriteType.numberArray}
        nodes={nodes}
      />
    </Typography>
  );

  const v1JSX = (
    <>
      <Button color="secondary" onClick={handleListEditorDialog}>
        {intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.multiplepercentageinput.ownlistdialog',
          defaultMessage: 'Listeneinträge editieren',
        })}
      </Button>
    </>
  );

  const updateElements = (
    listelements: Array<any>,
    _listkeys?: string[],
    storeForUndo: boolean = true
  ) => {
    updateElementValues(listelements, storeForUndo);
  };

  return (
    <div>
      <PropertyHeaderLine
        header={intl.formatMessage({
          id: 'builder.moduleeditor.propertiesSidebar.multiplepercentageinput.header',
          defaultMessage: 'Titel der Eingabeelemente',
        })}
      />
      <br />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={autoGenerate}
              onChange={toggleMultiselectEntryElementsGeneration}
              key="multiselectEntryElementsAutoGenCheckbox"
              id="multiselectEntryElementsAutoGenCheckboxId"
            />
          }
          label={
            <Typography variant="body2" component="span">
              {intl.formatMessage({
                id: 'builder.moduleeditor.propertiessidebar.multiplepercentageinput.checkboxtext',
                defaultMessage: 'Werte aus Variablen generieren',
              })}
            </Typography>
          }
        />
      </FormGroup>
      {autoGenerate && v2JSX}
      {!autoGenerate && v1JSX}
      <ListEditorDialog
        listelements={scaleNames}
        open={isListEditorOpen}
        onClose={handleListEditorDialog}
        updateElements={updateElements}
        nodeType={entry.nodeType}
      />
    </div>
  );
};
