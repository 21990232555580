import { FormattedInputEnum } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  placeholderText: {
    id: 'builder.nodes.formattedInput.state.placeholderText',
    defaultMessage: 'Schreibe hier… ',
  },
  helperTextText: {
    id: 'builder.nodes.formattedInput.state.helperText',
    defaultMessage: 'Bitte überprüfe deine Eingabe',
  },
  formatType: {
    id: 'formattedInputStateEntry.formatType',
    defaultMessage:
      'Bitte wähle hier aus, was der Benutzer eingeben soll. Dafür wird dann automatisch eine Prüfung des richtigen Formates durchgeführt.',
  },
  helperTextDesc: {
    id: 'formattedInputStateEntry.helperText',
    defaultMessage:
      'Der folgende Text wird angezeigt, wenn die Eingabe des Benutzers nicht dem gewählten Format entspricht.',
  },
});

export const formattedInputStateEntry: StateEntryProps = {
  type: 'renderFormattedInput',
  temporary: true,
  payload: {
    placeholderText: 'builder.nodes.formattedInput.state.placeholderText',
    saveResultTo: 'currentThankName',
    helperText: 'builder.nodes.formattedInput.state.helperText',
    formatType: FormattedInputEnum.mail,
    getValueFrom: '',
  },
  getValueType: ReadWriteType.string,
  saveResultType: ReadWriteType.string,
  displayName: 'Formatted Input',
  nodeType: 'formattedInputStateEntry',
  handleOutCount: 1,
};
