import {
  ContentCopy,
  ContentCut,
  ContentPaste,
  Undo,
} from '@mui/icons-material';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { EvoachMenuItem } from '../customMaterialComponents';

export interface ModuleEditorContextMenuDisplayProps {
  x: number;
  y: number;
  show: boolean;
}

interface ModuleEditorContextMenuProps {
  show: ModuleEditorContextMenuDisplayProps;
  onClick: Function;
  clipboardFilled?: boolean;
}

/** context menu for editor
 * using MUI menu components, doc: https://mui.com/components/menus/
 */
export const ModuleEditorContextMenu: React.FC<ModuleEditorContextMenuProps> = (
  props
) => {
  const { show, onClick, clipboardFilled = false } = props;

  const intl = useIntl();

  /**
   * PROD-1804
   * Display menu not only if parent component asks for, but also if
   * there is a text in the clipboard of the browser. Implementation
   * inspired by @see https://www.sitepoint.com/clipboard-api/ and
   *
   */
  const [clipboardReallyFilled, setclipboardReallyFilled] =
    useState<boolean>(clipboardFilled);

  useEffect(() => {
    if (!show) return;
    if (clipboardFilled) {
      setclipboardReallyFilled(true);
      return;
    }

    // PROD-1932 - Firefox does not know navigator.clipboard.readText
    if (navigator && navigator.clipboard && navigator.clipboard.readText) {
      try {
        // read clipboard may cause a browser permission request
        navigator.clipboard
          .readText()
          .then((clipboardItems: string) => {
            // if a non-epty string is in keyboard, show "insert" menu
            setclipboardReallyFilled(
              clipboardItems !== undefined && clipboardItems !== ''
            );
          })
          .catch(() => {
            // in case of clipboard access error, disable "insert" menu
            setclipboardReallyFilled(false);
          });
      } catch (reason: unknown) {
        console.error(navigator);
        console.error(reason);
        setclipboardReallyFilled(false);
      }
    } else {
      // no navigator.clipboard.readText
      setclipboardReallyFilled(false);
    }
  }, [clipboardFilled, show]);

  if (!show.show) return <></>;

  const onContextMenu = (event: any) => {
    event.preventDefault();
  };

  const onLocalClick = (event: string) => {
    onClick(event);
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: show.x,
        top: show.y,
        zIndex: 10000,
      }}
      id="editorcontextmenu"
      onContextMenu={onContextMenu}
    >
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList>
          <EvoachMenuItem onClick={() => onLocalClick('cut')}>
            <ListItemIcon>
              <ContentCut fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {intl.formatMessage({
                id: 'builder.moduleeditor.contextmenu.cut',
                defaultMessage: 'Ausschneiden',
              })}
            </ListItemText>
          </EvoachMenuItem>
          <EvoachMenuItem onClick={() => onLocalClick('copy')}>
            <ListItemIcon>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {intl.formatMessage({
                id: 'builder.moduleeditor.contextmenu.copy',
                defaultMessage: 'Kopieren',
              })}
            </ListItemText>
          </EvoachMenuItem>
          <EvoachMenuItem
            onClick={() => onLocalClick('paste')}
            disabled={!clipboardReallyFilled}
          >
            <ListItemIcon>
              <ContentPaste fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {intl.formatMessage({
                id: 'builder.moduleeditor.contextmenu.paste',
                defaultMessage: 'Einfügen',
              })}
            </ListItemText>
          </EvoachMenuItem>

          <Divider />

          <EvoachMenuItem onClick={() => onLocalClick('undo')}>
            <ListItemIcon>
              <Undo fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {intl.formatMessage({
                id: 'builder.moduleeditor.contextmenu.undo',
                defaultMessage: 'Rückgängig',
              })}
            </ListItemText>
          </EvoachMenuItem>
        </MenuList>
      </Paper>
    </div>
  );
};
