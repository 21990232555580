import { evoachDefaultTheme } from '@evoach/ui-components';

import { ReadWriteType, StateEntryProps } from '.';

// ! important note:
// if you name the state entry
//    defineStringArrayNodeStateEntry
// the react-flow renderer crashes and I don't know why
// if you rename this entry to
//    variableDefineStringArrayNodeStateEntry
// everthing works fine.
// The name of the actual node is not critical and can be named DefineStringArrayNode

export const variableDefineStringArrayNodeStateEntry: StateEntryProps = {
  type: 'setStringArray',
  temporary: false,
  payload: {
    labels: [],
    saveResultTo: '',
  },
  saveResultType: ReadWriteType.stringArray,
  displayName: 'String Array',
  nodeType: 'variableDefineStringArrayNodeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 1,
};
