import { defineMessages } from '@formatjs/intl';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../routing/routes';

const receptionButtonMessages = defineMessages({
  recpetion: {
    id: 'builder.components.receptionbutton.text',
    defaultMessage: 'Zur Startseite',
  },
});

export const ReceptionButton: React.FC = () => {
  let navigate = useNavigate();
  const handleClick = () => {
    navigate(`${AppRoutes.RECEPTION}`);
  };
  return (
    <Button
      onClick={() => {
        handleClick();
      }}
    >
      <FormattedMessage {...receptionButtonMessages.recpetion} />
    </Button>
  );
};
