import { defineMessages } from '@formatjs/intl';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

const deleteDialogTexts = defineMessages({
  title: {
    id: 'builder.reception.invitation.deletedialog.title',
    defaultMessage: 'Einladung löschen?',
  },
  cancel: {
    id: 'builder.reception.invitation.deletedialog.cancel',
    defaultMessage: 'Abbrechen',
  },
  delete: {
    id: 'builder.reception.invitation.deletedialog.delete',
    defaultMessage: 'Löschen',
  },
  cascade: {
    id: 'builder.reception.invitation.deletedialog.cascade',
    defaultMessage: 'Bereits akzeptierte Einladungen zurückziehen',
  },
});

export interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: (isCascading: boolean) => void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  onClose,
  onDelete,
}) => {
  const intl = useIntl();
  const [isCascading, setIsCascading] = useState(false);

  const handleChange = (event: any) => {
    setIsCascading(event.target.checked);
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{intl.formatMessage(deleteDialogTexts.title)}</DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isCascading} onChange={handleChange} />}
            label={intl.formatMessage(deleteDialogTexts.cascade)}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={() => {
              setIsCascading(false);
              onClose();
            }}
          >
            {intl.formatMessage(deleteDialogTexts.cancel)}
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              onDelete(isCascading);
              setIsCascading(false);
              onClose();
            }}
          >
            {intl.formatMessage(deleteDialogTexts.delete)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
