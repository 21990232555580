import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { getDisplayName, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';
/*
  ArrayCombineNode 
*/

export const ArrayCombineNode: React.FC<NodeProps<any>> = (
  nodeProps: NodeProps<any>
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const combineArrayMessage = intl.formatMessage({
    id: 'builder.nodes.elements.ArrayCombine.hint',
    defaultMessage:
      'Dieser Knoten kombiniert mehrere einzelne Variablen zu einer Liste, die in einer neuen Variable gespeichert wird. Name der neuen Variable: ',
  });

  const payload = nodeProps.data.state.entry[0].payload;
  const getValuesFrom = payload.getValuesFrom ?? [''];

  return (
    <>
      <Handle
        type="target"
        id="combinearrayin"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ width: '300px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          <b>{getDisplayName(nodeProps.data)}</b> : {combineArrayMessage}
          <strong>
            {(payload.saveResultTo + '').startsWith('evoachechokey')
              ? ''
              : payload.saveResultTo}
          </strong>
          <br />
          <ol>
            {getValuesFrom.map((label: string, index: number) => (
              <li key={'label' + index}>{label}</li>
            ))}
          </ol>
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="combineArrayhandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
