import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

//
// define description for state entry
//
defineMessages({
  d: {
    id: 'checkVariableStateEntry.description',
    defaultMessage:
      'Diese Komponente erkennt, ob eine Variable bereits gefüllt wurde oder nicht. Abhängig vom Ergebnis verzweigt sie in einen anderen Pfad.',
  },
});

/*
  State entry for the CheckVariable component. It checks the
  variable in getValueFrom for being "undefined" or not.
  If undefined, take one output handle, otherwise the other.

  If getValueFrom is not set, it branches to "unset"
*/
export const checkVariableStateEntry: StateEntryProps = {
  version: 'v1',
  type: 'checkVariable',
  temporary: false,
  payload: {
    getValueFrom: '',
  },
  getValueType: Object.entries(ReadWriteType)
    .filter((e) => typeof e[1] === 'number')
    .map((e) => e[1]) as ReadWriteType[], // magic :-)
  displayName: 'Check Variable',
  nodeType: 'checkVariableStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 2,
};
