import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  Stack,
  Box,
} from '@mui/material';

import { ProgramRouteTypeEnum } from '../../routing/routes';
import { authorizedGet } from '../../api/authorizedApi';
import {
  LanguageSelectionHeightOptions,
  MetaDataEditorLanguageSelection,
} from '../ModuleEditor/MetaDataEditorLanguageSelection';
import { SupportedModuleLocales } from '../../intl/SupportedLocales';

interface TranslateProgramOrInstanceDialogProps {
  fromLanguage: string;
  isOpen: boolean;
  onClose: Function;
  programRouteType: ProgramRouteTypeEnum;
  programId: string | undefined;
}

export const TranslateProgramOrInstanceDialog: React.FC<
  TranslateProgramOrInstanceDialogProps
> = ({ fromLanguage, isOpen, onClose, programRouteType, programId }) => {
  const intl = useIntl();

  const [toLanguage, setToLanguage] = useState<string>('EN');

  const deeplTranslate = async () => {
    // eslint-disable-next-line no-restricted-globals
    const sure = confirm(
      intl.formatMessage({
        id: 'builder.programtranslation.langsel.warning2',
        defaultMessage:
          'Bist du sicher? Alle bisherigen Übersetzungen werden überschrieben. Diese Aktion kann nicht rückgängig gemacht werden.',
      })
    );

    if (!sure) {
      onClose(false);
      return;
    }

    // ! enable for all users independently of subscription tier
    // ! may be changhed in future

    // if user does not have proper plan, display message
    /*  if (!hasFeature(FeatureEnum.DEEPL_B)) {
      alert(
        'Diese Funktion ist in deinem Abo-Modell leider nicht verfügbar und ist nur in höheren Abo-Modellen verfügbar.'
      );
      return;
    } */

    //console.log(fromLanguage, toLanguage);
    // do not translate in same language
    if (fromLanguage === toLanguage) {
      onClose(false);
      return;
    }

    // different target depending on whether the editor is called
    // for a program or a program instance
    let baseUrl = '';

    if (programRouteType === ProgramRouteTypeEnum.INSTANCE) {
      baseUrl = `/programinstance/${programId}`;
    } else {
      baseUrl = `/program/${programId}`;
    }

    //
    // Do the rest with deepl
    //
    const deeplurl = `${baseUrl}/translation/deepl?translateto=${toLanguage}`;

    const deeplCall = authorizedGet(deeplurl);
    deeplCall()
      .then((res: any) => {
        res
          .json()
          .then((_res: any) => {
            // refetch program takes place in parent component
            onClose(false);
          })
          .catch((err: unknown) => console.error(err));
      })
      .catch((err: unknown) => console.error(err));
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.programtranslation..langselection.title',
          defaultMessage: 'In welche Sprache soll übersetzt werden?',
        })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2" component="span">
            {intl.formatMessage(
              {
                id: 'builder.programtranslation.langsel.desc',
                defaultMessage:
                  'Das Programm ist in der Sprache {lang}. In welche Sprache möchtest du übersetzen?',
              },
              {
                lang: <b>{fromLanguage}</b>,
              }
            )}
          </Typography>
          <Box sx={{ marginTop: '20px' }}>
            <MetaDataEditorLanguageSelection
              moduleLocale={fromLanguage}
              onLanguageChange={(lang: SupportedModuleLocales) => {
                setToLanguage(lang);
              }}
              displayLabel
              heightOption={LanguageSelectionHeightOptions.MEDIUM}
              excludeLocales={['CN', 'JP', 'KR', 'RU', 'TW']}
            />
          </Box>
          <Typography variant="body2" component="span">
            {intl.formatMessage({
              id: 'builder.programtranslation.langsel.desc2',
              defaultMessage:
                'Wenn du auf "Übersetzen" klickst, wird das Programm sofort im Hintergrund übersetzt. Dabei werden alle bisherigen Übersetzungen überschrieben. Diese Aktion kann nicht rückgängig gemacht werden.',
            })}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose(false)}>
          {intl.formatMessage({
            id: 'builder.programtranslation.button.cancel',
            defaultMessage: 'Abbrechen',
          })}
        </Button>
        <Button color="secondary" onClick={() => deeplTranslate()}>
          {intl.formatMessage({
            id: 'builder.programtranslation.button.translate',
            defaultMessage: 'Übersetzen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
