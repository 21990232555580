import React, { useContext, useState } from 'react';
import { defineMessages, IntlContext } from 'react-intl';
import { Button } from '@mui/material';

import { CreateProgramByJsonDialog } from '../coachingprograms/CreateProgramByJsonDialog';
import {
  EnvironmentName,
  useEnvironment,
} from '../../../environment/useEnvironment';

import { CreateModuleByJsonDialog } from './CreateModuleByJsonDialog';

const receptionPageMessages = defineMessages({
  createNewModule: {
    id: 'builder.reception.modules.createNewModuleByJson',
    defaultMessage: 'Chatbot via JSON',
  },
  createNewTemplate: {
    id: 'builder.reception.modules.createNewTemplateByJson',
    defaultMessage: 'Vorlage via JSON',
  },
  createNewProgram: {
    id: 'builder.reception.programs.createNewProgramByJson',
    defaultMessage: 'Programm via JSON',
  },
});

/**
 * render different description text and branch to different dialogs when
 * creating an object from a JSON (evoach only)
 */
export const enum NewObjectByJsonButtonTypeEnum {
  MODULE,
  TEMPLATE,
  PROGRAM,
}

interface NewModuleByJsonButtonProps {
  mode: NewObjectByJsonButtonTypeEnum;
}

export const NewObjectByJsonButton: React.FC<NewModuleByJsonButtonProps> = ({
  mode,
}) => {
  const intl = useContext(IntlContext);

  const { name } = useEnvironment();

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const onOpenHandler = () => {
    setDialogIsOpen(true);
  };

  const onCloseHandler = () => {
    setDialogIsOpen(false);
  };

  // do not show button if we are on production
  if (name === EnvironmentName.PRODUCTION) {
    return null;
  }

  return (
    <span>
      <span style={{ marginLeft: '10px' }}>
        <Button variant="outlined" color="secondary" onClick={onOpenHandler}>
          {mode === NewObjectByJsonButtonTypeEnum.MODULE && (
            <>{intl.formatMessage(receptionPageMessages.createNewModule)}</>
          )}
          {mode === NewObjectByJsonButtonTypeEnum.TEMPLATE && (
            <>{intl.formatMessage(receptionPageMessages.createNewTemplate)}</>
          )}
          {mode === NewObjectByJsonButtonTypeEnum.PROGRAM && (
            <>{intl.formatMessage(receptionPageMessages.createNewProgram)}</>
          )}
        </Button>
      </span>
      {(mode === NewObjectByJsonButtonTypeEnum.MODULE ||
        mode === NewObjectByJsonButtonTypeEnum.TEMPLATE) && (
        <CreateModuleByJsonDialog
          open={dialogIsOpen}
          onCloseHandler={onCloseHandler}
        />
      )}
      {mode === NewObjectByJsonButtonTypeEnum.PROGRAM && (
        <CreateProgramByJsonDialog
          open={dialogIsOpen}
          onCloseHandler={onCloseHandler}
        />
      )}
    </span>
  );
};
