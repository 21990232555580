import React, { useContext, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { AppHeader } from '@evoach/ui-components';
import { defineMessages, useIntl } from 'react-intl';
import { Box, Divider } from '@mui/material';

import { LanguageSwitch } from '../components/AvatarMenu/LanguageSwitch';
import { DevToggle } from '../devtools/DevToggle';
import { useEnvironment } from '../environment/useEnvironment';
import { AccountContext, RoleEnum } from '../account';
import { DisclaimerLink } from '../components/AvatarMenu/DisclaimerLink';
import { AppVersion } from '../components/AvatarMenu/AppVersion';
import { DevToolsContext } from '../devtools/DevToolsContext';
import { SupportLink } from '../components/AvatarMenu/SupportLink';
import { ProfileLink } from '../components/AvatarMenu/ProfileLink';
import { AccountProfile, EMPTY_PROFILE } from '../entities/AccountTypes';
import { ProductTourMenuLink } from '../components/AvatarMenu/ProductTourMenuLink';
import { useSession } from '../routing/useSession';

import { AppHeaderContext } from './AppHeaderContext';

const appLayoutMessages = defineMessages({
  header: {
    id: 'builder.layout.app.header',
    defaultMessage: 'Chatbot Creator',
  },
  loginLabel: {
    id: 'builder.layout.app.label.login',
    defaultMessage: 'Anmelden',
  },
  logoutLabel: {
    id: 'builder.layout.app.label.logout',
    defaultMessage: 'Abmelden',
  },
});

export type AppLayoutProps = {
  children?: React.ReactNode;
};
export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const intl = useIntl();

  const { feautures, builderBasePath } = useEnvironment();
  const { keycloak } = useKeycloak();
  const { appHeaderTitle, appHeaderHomeroute } = useContext(AppHeaderContext);

  //
  // start and stop edit sessions automatically based on React Router path
  // true => write to API. PROD-1113
  //
  useSession(true);

  const { l } = React.useContext(DevToolsContext);

  l('React Version: ' + React.version);

  const isAuthenticated = keycloak?.authenticated
    ? keycloak?.authenticated
    : false;

  const handleLoginLogout = () => {
    isAuthenticated
      ? keycloak?.logout({
          redirectUri: builderBasePath,
        })
      : keycloak?.login();
  };

  const loginLogoutLabel = isAuthenticated
    ? intl.formatMessage(appLayoutMessages.logoutLabel)
    : intl.formatMessage(appLayoutMessages.loginLabel);

  const { account, name, hasRole } = useContext(AccountContext);

  const additionalMenuItems = [<LanguageSwitch />, <ProductTourMenuLink />];

  additionalMenuItems.push(<SupportLink />);
  additionalMenuItems.push(<ProfileLink />);

  additionalMenuItems.push(<Divider />);
  additionalMenuItems.push(<DisclaimerLink />);

  if (feautures.showDevToolsToggle && hasRole(RoleEnum.EVOACHADMIN)) {
    additionalMenuItems.push(<DevToggle />);
    additionalMenuItems.push(<AppVersion />);
  }
  additionalMenuItems.push(<Divider />);

  // save in state
  const [coachProfile, setCoachProfile] = useState<AccountProfile>(
    account?.metainfos?.profile ?? EMPTY_PROFILE
  );

  // update state if account is changing (e.g. if loading takes time)
  useEffect(() => {
    if (!account) return;
    // always set empty profile to avoid textfields toggling between
    // unconrtrolled / controlled state
    setCoachProfile({
      ...EMPTY_PROFILE,
      ...(account?.metainfos?.profile ?? {}),
    });
  }, [account]);

  const profileSrc =
    coachProfile.profilePictureResolvedUrl &&
    coachProfile.profilePictureResolvedUrl !== ''
      ? coachProfile.profilePictureResolvedUrl
      : name === '? ?'
      ? '/logo192.png'
      : undefined;

  return (
    <>
      <Box>
        <AppHeader
          appheadertitle={
            appHeaderTitle
              ? `${appHeaderTitle}`
              : intl.formatMessage(appLayoutMessages.header)
          }
          loginLogoutLabel={loginLogoutLabel}
          handleLoginLogout={handleLoginLogout}
          additionalMenuItems={additionalMenuItems}
          avatarName={name === '? ?' ? 'evoach' : name}
          avatarSrc={profileSrc}
          homeRoute={appHeaderHomeroute}
        />
      </Box>
      <Box
        style={{
          marginTop: '1rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </>
  );
};
