import { Handle, NodeProps, Position } from 'reactflow';
import { TimeInput } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';

/*
  TimeInput react-flow node for module builder graph canvas
*/

export const TimeInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div style={{ minWidth: '322px' }}>
        <div
          className={
            nodeProps.selected ? classes.selectStyletrue : classes.root
          }
        >
          <TimeInput
            time={payload.time}
            buttonText={translateKey(intl, payload.buttonText)}
            saveResultTo={payload.saveResultTo}
          />
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
