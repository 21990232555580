import { evoachDefaultTheme } from '@evoach/ui-components';
import { Box, FormControl, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { AssetSort } from '../../PropertiesSidebar/assetHelper';

interface SortAssetBySelectProps {
  /**
   * onUpdate function should have signature like (value: AssetSort | undefined) => {}
   */
  onUpdate: Function;
  narrowMode?: boolean;
  showAllOption?: boolean;
}

export const SortAssetBySelect: React.FC<SortAssetBySelectProps> = ({
  onUpdate,
  narrowMode = false,
}) => {
  const intl = useIntl();

  const [valueSort, setValueSort] = useState<AssetSort>(AssetSort.BYDATE);

  return (
    <FormControl>
      <EvoachSelect
        value={valueSort}
        onChange={(event) => {
          setValueSort(event.target.value as AssetSort);
          onUpdate(event.target.value);
        }}
        renderValue={(value) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignmentBaseline: 'center',
                alignItems: 'center',
              }}
            >
              <Typography color="#999999" variant="body2">
                {intl.formatMessage({
                  id: 'builder.reception.assets.assetsortselect.assetsortdropdown.text',
                  defaultMessage: 'Sortieren nach:',
                })}
              </Typography>
              <Typography sx={{ marginLeft: '10px' }}>
                <>{value}</>
              </Typography>
            </Box>
          );
        }}
        sx={{
          height: narrowMode ? '25px' : '35px',
          color: narrowMode
            ? evoachDefaultTheme.palette.secondary.main
            : '#000000',
          borderColor: narrowMode
            ? evoachDefaultTheme.palette.secondary.main
            : '#000000',
          marginLeft: '0.5em',
        }}
      >
        {Object.values<string>(AssetSort).map(
          (assetsort: string, index: number) => (
            <EvoachMenuItem
              key={`languages_${index}`}
              value={assetsort}
              selected={assetsort === valueSort}
              sx={{
                color: narrowMode
                  ? evoachDefaultTheme.palette.secondary.main
                  : '#000000',
              }}
            >
              {assetsort}
            </EvoachMenuItem>
          )
        )}
      </EvoachSelect>
    </FormControl>
  );
};
