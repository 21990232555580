import { CoachMessage, YesNoButton } from '@evoach/ui-components';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';

import { useFetchModuleTranslation } from '../../api/translations/useFetchModuleTranslation';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  StartNewSessionNode react-flow node for module builder graph canvas
*/

export const StartNewSessionNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  // load translation of the module to be started in order to display the name
  // in canvas
  const { translation } = useFetchModuleTranslation(
    payload.moduleId,
    intl.locale
  );

  // memoize the translation of the module selected
  const moduleTitle = useMemo(() => {
    if (
      !translation ||
      !payload ||
      !payload.moduleTitleKey ||
      payload.moduleTitleKey === ''
    )
      return '-';
    return translation.metadatatranslation[payload.moduleTitleKey];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translation]);

  const nodeHint = intl.formatMessage({
    id: 'builder.nodes.elements.startNewSessionNode.nodeHint',
    defaultMessage:
      'Dieser Knoten gibt dem Coachee Zugang zu dem angegebenen Modul und startet ggf. eine neue Session.',
  });

  const nextModuleMessage = intl.formatMessage({
    id: 'builder.nodes.elements.startNewSessionNode.nextModuleMessage',
    defaultMessage: 'Nächstes Modul:',
  });

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ maxWidth: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {nodeHint}
          <br />
          &nbsp;
          <br />
          <CoachMessage message={translateKey(intl, payload.message)} />
          <br />
          <CoachMessage message={nextModuleMessage + ' ' + moduleTitle} />
        </Typography>
        <div>
          <div style={{ margin: '1em 10%' }}>
            <YesNoButton
              yesButtonText={translateKey(intl, payload.buttonTextYes)}
              noButtonText={translateKey(intl, payload.buttonTextNo)}
              saveResultTo="evoachechokey.yesNoStartNewSession"
            />
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="yeshandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '30%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        style={{ left: '70%' }}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
