import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from '@mui/material';

interface VarNameChangeWarningProps {
  /**
   * '' (default) ==> general message
   *
   * 'duplicate' ==> oldVarName was a dulicate and nothing was changed
   *
   * 'updated' ==> var names updated
   *
   * 'renamedandupdated' ==> new varname renamed and then var names updated
   *
   * 'duplicateask' ==> renaming variables would lead to a duplicate. Ask for confirmation
   */
  warningType: string;
  isOpen: boolean;
  onClose: Function;
  onConfirm?: Function;
}

export const VarNameChangeWarning: React.FC<VarNameChangeWarningProps> = ({
  warningType,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const intl = useIntl();

  if (!isOpen) {
    return null;
  }

  let warningMessage = '';
  switch (warningType) {
    case 'duplicate':
      warningMessage = intl.formatMessage({
        id: 'builder.dialog.varnamechangewarning.warning.duplicate',
        defaultMessage:
          'Du hast eine Variable geändert, die mehrfach in Komponenten als Variable verwendet wurde. Da wir das nicht automatisch auflösen können, haben wir keine Komponenten aktualisiert. Bitte prüfe alle Komponenten, die die geänderte Variable nutzen und weise sie ggf. neu zu. ',
      });
      break;
    case 'duplicatebutloop':
      warningMessage = intl.formatMessage({
        id: 'builder.dialog.varnamechangewarning.warning.duplicatebutloop',
        defaultMessage:
          'Du hast den Namen einer Schleife geändert. Der Schleifenname und zugehörige Variablen werden in deinem Modul mehrfach in Komponenten verwendet. Da wir das nicht automatisch auflösen können, haben wir keine Komponente aktualisiert, werden aber den Schleifennnamen ändern. Bitte prüfe alle Komponenten, die die geänderte Variable der Schleife nutzen und weise sie ggf. neu zu.',
      });
      break;
    case 'updated':
      warningMessage = intl.formatMessage({
        id: 'builder.dialog.varnamechangewarning.warning.updated',
        defaultMessage:
          'Alle Komponenten wurden mit dem neuen Variablennamen aktualisiert.',
      });
      break;

    case 'renamedandupdated':
      warningMessage = intl.formatMessage({
        id: 'builder.dialog.varnamechangewarning.warning.renamedandupdated',
        defaultMessage:
          'Deine neue Variable wurde umbenannt, um Duplikate bei Variablennamen zu vermeiden, und alle Komponenten wurden mit dem angepassten Variablennamen aktualisiert.',
      });
      break;
    case 'duplicateask':
      warningMessage = intl.formatMessage({
        id: 'builder.dialog.varnamechangewarning.warning.duplicateask',
        defaultMessage:
          'Der neue Name deiner Variable wird bereits verwendet. Soll die Variable trotzdem umbenannt werden (klicke auf "Duplikate zulassen") oder soll der Name automatisch angepasst werden, um Duplikate bei Variablennamen zu vermeiden (klicke auf "Variablennamen anpassen")',
      });
      break;
    default:
      warningMessage = intl.formatMessage({
        id: 'builder.dialog.varnamechangewarning.warning.default',
        defaultMessage:
          'Bei Namensänderungen von Variablen wird versucht, alle Komponenten zu aktualisieren, die diese Variable nutzen.',
      });
  }

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.dialog.varnamechangewarning.title',
          defaultMessage: 'Informationen zur Änderung von Variablen',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">{warningMessage}</Typography>
      </DialogContent>
      <DialogActions>
        {warningType === 'duplicateask' ? (
          <>
            <Button
              color="secondary"
              onClick={() => {
                if (onConfirm) onConfirm();
              }}
            >
              {intl.formatMessage({
                id: 'builder.varnamechangewarning.button.allowduplicate',
                defaultMessage: 'Duplikate zulassen',
              })}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                onClose();
              }}
            >
              {intl.formatMessage({
                id: 'builder.varnamechangewarning.button.changevarname',
                defaultMessage: 'Variablennamen anpassen',
              })}
            </Button>
          </>
        ) : (
          <Button
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            {intl.formatMessage({
              id: 'builder.varnamechangewarning.button.close',
              defaultMessage: 'Schließen',
            })}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
