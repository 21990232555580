import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Stack,
} from '@mui/material';
import ReactJson from 'react-json-view';

import { NodeDataProps } from '../nodes';

const debugMessages = defineMessages({
  dialogTitle: {
    id: 'builder.moduleeditor.propertiessidebar.textfield.editor.NodeDebugOutput.title',
    defaultMessage: 'Debug Ausgabe',
  },
  dialogCloseButton: {
    id: 'builder.moduleeditor.propertiessidebar.textfield.editor.NodeDebugOutput.closeButton',
    defaultMessage: 'Schließen',
  },
});
export interface NodeDebugOutputProps {
  open: boolean;
  onClose: () => void;
  value: NodeDataProps;
}
export const NodeDebugOutput: React.FC<NodeDebugOutputProps> = ({
  open,
  onClose,
  value,
}) => {
  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage {...debugMessages.dialogTitle} />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: '10px' }}>
          <ReactJson src={value as unknown as JSON} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          <FormattedMessage {...debugMessages.dialogCloseButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
