import { authorizedGet } from '../../../api/authorizedApi';

/**
 *
 * @param checkResult
 * @returns {boolean} true = everthing is ok, false = there is an issue
 */
export const isQaCheckFine = (checkResult: any): boolean => {
  if (checkResult === undefined) return true;

  let res = true;
  if (checkResult.qualityMetrics !== undefined) {
    Object.keys(checkResult.qualityMetrics).forEach((key: string) => {
      res = res && checkResult.qualityMetrics[key].missingKeys.length === 0;
    });
  }

  res = res && Object.keys(checkResult.sameTextKeys).length === 0;

  return res;
};

/**
   * get the quality check from backend 
   * 
   * @param {string} moduleid 
   * @returns an object like
   * 
        {
            "moduleDefaultLanguage": "DE",
            "moduleLanguages": [
                "DE",
                "EN"
            ],
            "qualityMetrics": {
                "DE": {
                    "missingKeys": ['yosdj.d']
                },
                "EN": {
                    "missingKeys": []
                }
            },
            "sameTextKeys": {
                "9yhecojz.buttonTextYes": [
                    "DE",
                    "EN"
                ],
            }
        }
   */
export const getCheck = async (moduleid: string) => {
  const url = `/module/${moduleid}/translation/quality`;

  const fetchAnalyticsCall = authorizedGet(url);
  const response = await fetchAnalyticsCall();

  return await response.json();
};
