import { generateRandomString } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { Edge, isEdge, isNode, Node, XYPosition } from 'reactflow';

import { TranslationProps } from '../../entities';
import {
  isLoopEnd,
  isLoopStart,
  isNote,
  isValidVariableName,
  isVariableProp,
  NodeDataProps,
  StateEdge,
  StateEntryProps,
  StateNode,
  StateYesNoAction,
} from '../nodes';
import * as CustomNodes from '../nodes';

import { ReactFlowElements } from './ReactFlowHelper';

/** find a phase start node if existing
 *
 * @param {Node<any>[]} nodes = list of nodes to be searched in
 * @return {string} nodeid or empty string if no start phase node was found
 */
export const findPhaseStartNode = (nodes: Node<any>[]): string => {
  let startnodeid = '';
  if (nodes && nodes.length > 0) {
    nodes.forEach((node: Node<any>) => {
      if (node.type === 'phaseStartStateEntry') {
        startnodeid = node.data.state.stateKey;
      }
    });
  }
  return startnodeid;
};

/** find a phase end node if existing
 *
 * @param {Node<any>[]} nodes = list of nodes to be searched in
 * @return {Node} node or undefined if no end phase node was found
 */
export const findPhaseEndNode = (nodes: Node<any>[]): Node | undefined => {
  let endnode: Node<any> | undefined = undefined;
  if (nodes && nodes.length > 0) {
    nodes.forEach((node: Node<any>) => {
      if (node.type === 'phaseEndStateEntry') {
        endnode = node;
      }
    });
  }
  return endnode;
};

/** find any node that has an input handle but has no incoming edge
 * 1) search for a phase start node
 * 2) if there is no phase start node, take any node without incoming edges
 *
 * @param {Node<any>[]} nodes = list of nodes to be searched in
 * @param {Edge<any>[]} edges = list of edges to be checked
 * @return {string} nodeid or empty string if no start phase node was found
 */
export const findAnyStartNode = (nodes: Node<any>[], edges: Edge<any>[]) => {
  let startnodeid = findPhaseStartNode(nodes);

  if (startnodeid === undefined || startnodeid === '') {
    if (nodes && nodes.length > 0) {
      nodes
        .filter((node: Node<any>) => !isNote(node as StateNode))
        .forEach((node: Node<any>) => {
          const hasIncomingEdges = edges.reduce(
            (found: boolean, edge: any) =>
              found || edge.target + '' === node.id,
            false
          );
          startnodeid =
            !hasIncomingEdges && startnodeid === ''
              ? node.data.state.stateKey
              : startnodeid;
        });
    }
  }

  return startnodeid;
};

/**
 * checks whether a node with the given id exists
 *
 * @param {(string | undefined)} targetid id to check as node.id
 * @param {Node<any>[]} nodes list of nodes in which targetid is looked for
 * @return {boolean} true = node exists, false = node does not exists
 */
export const isNodeExisting = (
  targetid: string | undefined | StateYesNoAction,
  nodes: Node[]
) => {
  return targetid
    ? nodes.reduce(
        (exists: boolean, node) =>
          (exists =
            exists ||
            node.id ===
              (typeof targetid === 'string' ? targetid : targetid.target)),
        false
      )
    : false;
};

/**
 * containsLoopNodes checks whether a list of elements contains loop nodes
 *
 * @param {Node<any>[]} nodes
 * @return {boolean} true = contains at least one loop element, false = contains no loop elements
 */
export const containsLoopNodes = (nodes: Node<any>[]): boolean =>
  nodes
    .filter((node) => node) // array may contain undefined
    .reduce((contains: boolean, node: Node<any>) => {
      return contains || isLoopStart(node.data) || isLoopEnd(node.data);
    }, false);

/**
 * hasSaveResultTo checks whether a node has a state entry in its .data
 * property that contains a valid saveResultTo value
 *
 * @param {Node<any>} node
 * @return {boolean} true = contains at least one variable, false = contains no variable
 */
// check whether a state entry has a valid saveResultTo information
export const hasSaveResultTo = (node: Node<any>) => {
  if (
    !node.data.state.entry[0] ||
    !node.data.state.entry[0].payload ||
    !node.data.state.entry[0].payload.saveResultTo
  ) {
    return false;
  } else {
    return isValidVariableName(node.data.state.entry[0].payload.saveResultTo);
  }
};

/**
 * containsVariables checks whether a list of elements contains some variables
 * in a saveResultTo statement.
 *
 * @param {Node<any>[]} nodes
 * @return {boolean} true = contains at least one variable false = contains no variable
 */
export const containsVariables = (nodes: Node<any>[]): boolean => {
  // run through all elements
  return nodes
    .filter((node) => node) // node may be undefined, filter this out
    .reduce((contains: boolean, element: Node) => {
      return (contains = contains || hasSaveResultTo(element));
    }, false);
};

/**
 * getListOfVariables gets all variables from a list of elements.
 * The list is derived vom all saveResultTo values in entries
 *
 * @param {Node<any>[]} nodes
 * @return {string[]} list of variable names as string array, potentially empty array
 */
export const getListOfVariables = (nodes: Node<any>[]): string[] => {
  // run through all elements
  return nodes
    .filter((node: Node<any>) => hasSaveResultTo(node))
    .map(
      (node: Node<any>) =>
        node.data.state.entry[0].payload.saveResultTo as string
    );
};

/**
 * removeVariables removes all variables in listOfVariablesToRemove from the elements
 *
 * @param {Node<any>[]} nodes
 * @param {string[]} listOfVariablesToRemove
 * @return {Node<any>[]} modified elements with removed variables
 */
export const removeVariables = (
  listOfVariablesToRemove: string[],
  nodes: Node<any>[]
): Node<any>[] => {
  const newNodes = nodes.map((node: Node<any>) => {
    // if node, check for payload and remove variable if appropriate
    const payload = node.data.state?.entry[0].payload;
    if (payload) {
      const payloadProps = Object.keys(payload).filter(isVariableProp);
      //console.log(payloadProps);
      if (payloadProps.length > 0) {
        payloadProps.forEach((prop: string) => {
          if (typeof payload[prop] === 'string') {
            // string ==> delete variable
            payload[prop] = listOfVariablesToRemove.includes(payload[prop])
              ? ''
              : payload[prop];
          } else {
            // array
            payload[prop] = payload[prop].filter(
              (val: string) => !listOfVariablesToRemove.includes(val)
            );
          }
        });
      }
    }
    return node;
  });
  return newNodes;
};

/**
 * find the loop end node for a given loop start node
 *
 * @param {Node<any>} startnode
 * @param {Node<any>[]} nodes
 * @param {Edge<any>[]} edges
 * @return {(Node<any> | undefined)} returns node or undefined
 */
export const findLoopEndNode = (
  startnode: Node<any>,
  nodes: Node<any>[],
  edges: Edge<any>[]
): Node<any> | undefined => {
  // elements is the global variable containing all elements (passed as prop to component)
  const incomingEdge = edges.filter(
    (edge: Edge<any>) => edge.target === startnode.id
  )[0];

  return incomingEdge
    ? nodes.filter((node: Node<any>) => node.id === incomingEdge.source)[0]
    : undefined;
};

/**
 * find the loop start node for a given loop end node
 *
 * @param {Node} endnode
 * @param {Node<any>[]} nodes
 * @param {Edge<any>[]} edges
 * @return {(Node | undefined)} returns node or undefined
 */
export const findLoopStartNode = (
  endnode: Node<any>,
  nodes: Node<any>[],
  edges: Edge<any>[]
): Node | undefined => {
  // elements is the global variable containing all elements (passed as prop to component)
  const outgoingEdge = edges.filter(
    (edge: Edge<any>) => edge.source === endnode.id
  )[0];

  return outgoingEdge
    ? nodes.filter((node: Node<any>) => node.id === outgoingEdge.target)[0]
    : undefined;
};

/**
 * find the loop start node for a given loop end node
 *
 * @param {string} nodeid - id od the node to be found
 * @param {Node<any>} node - nodes to be searched
 * @return {Node<any> | undefined} returns the node or undefined if node doesn't exist
 */
export const findNodeByNodeId = (
  nodeid: string | null,
  nodes: Node<any>[]
): Node<any> | undefined => {
  return nodeid === null
    ? undefined
    : nodes.filter((node: Node<any>) => node.id === nodeid)[0];
};

/**
 * set loopName of endLoopNode to name of startLoopNode which is stored in saveResultTo
 *
 * @param {Edge<any>} edge - edge that connects two loop nodes
 * @param {Node<any>[]} nodes - list of elements to get the nodes to that edge
 * @return {void}
 */
export const connectLoopElementsByEdge = (
  edge: Edge<any>,
  nodes: Node<any>[]
) => {
  const startLoopNode = findNodeByNodeId(edge.target, nodes);
  const endLoopNode = findNodeByNodeId(edge.source, nodes);

  // set loopName of endLoopNode to name of startLoopNode which is stored in saveResultTo
  if (startLoopNode && endLoopNode) {
    endLoopNode.data.state.entry[0].payload.loopName =
      startLoopNode?.data.state.entry[0].payload.saveResultTo;
  }
};

/**
 * checkes whether nodeData is placed within a loop
 *
 * @param {NodeDataProps} nodeData -
 * @param {Node<any>[]} nodes - list of nodes
 * @param {Edge<any>[]} _edges - list of edges
 * @return {string} name of containing loop, undefined otherwise
 */
export const isWithinLoop = (
  nodeData: NodeDataProps,
  nodes: Node<any>[],
  edges: Edge<any>[]
): string | undefined => {
  //const edges = _edges as Edge<any>[];
  // 1. find start node
  let startnodeid = findPhaseStartNode(nodes);
  if (startnodeid === '') {
    startnodeid = findAnyStartNode(nodes, edges);
  }

  //console.log(nodeData);

  // 2. iterate through tree and check whether in loop
  const getOutgoingEdges = (nodeid: string) => {
    if (!nodeid) return [];
    return edges.filter((edge: Edge<any>) => edge.source === nodeid);
  };

  const getNode = (nodeid: string | null) => {
    if (!nodeid) return [];
    return nodes.filter((node: Node) => node.id === nodeid);
  };

  let visitedNodes: string[] = [];
  let nodeIsInLoop: string | undefined = undefined;

  // search node recursively and return true if found and within a loop
  // walk thorugh graph node by node by follwoing all edges
  const inLoop = (
    edges: Edge<any>[],
    currentlyInLoop: string | undefined
  ): string | undefined => {
    edges.forEach((edge: Edge<any>) => {
      const tnodes = getNode(edge.target);
      tnodes.forEach((node: Node) => {
        // may be a loop! if node was already visited, ignore it
        if (!visitedNodes.includes(node.id)) {
          // remember all visited nodes
          if (nodeData.state.stateKey === node.data.state.stateKey) {
            //console.log('finally: currentlyInLoop = ' + currentlyInLoop);
            nodeIsInLoop = currentlyInLoop;
            return currentlyInLoop;
          }
          visitedNodes.push(node.id);
          currentlyInLoop =
            node.data.nodeType === 'loopStartStateEntry'
              ? node.data.state.entry[0].payload.saveResultTo
              : currentlyInLoop;
          //console.log('currentlyInLoop 1= ' + currentlyInLoop);

          if (node.data.nodeType === 'loopEndStateEntry') {
            currentlyInLoop = undefined;
            //console.log('currentlyInLoop 2= ' + currentlyInLoop);
          }
          //console.log('nodeid(i) = ' + node.id);
          currentlyInLoop = inLoop(getOutgoingEdges(node.id), currentlyInLoop);
          //console.log('currentlyInLoop 3= ' + currentlyInLoop);
        }
        return currentlyInLoop;
      });

      nodeIsInLoop = currentlyInLoop;
      return currentlyInLoop;
    });

    return currentlyInLoop;
  };
  //console.log('nodeid(s) = ' + startnodeid);

  inLoop(getOutgoingEdges(startnodeid), undefined);

  //console.log('nodeIsInLoop = ' + nodeIsInLoop);

  return nodeIsInLoop;
};

/**
 * isWithinLoopByNodeId checkes whether node with nodeid is placed within a loop
 *
 * @params {string} nodeid -
 * @param {Node<any>[]} nodes
 * @param {Edge<any>[]} edges
 * @return {string} name of containing loop, undefined otherwise
 */
export const isWithinLoopByNodeId = (
  nodeid: string | null,
  nodes: Node<any>[],
  edges: Edge<any>[]
): string | undefined => {
  if (!nodeid) return undefined;
  const nodeData = findNodeByNodeId(nodeid, nodes);
  return nodeData ? isWithinLoop(nodeData.data, nodes, edges) : undefined;
};

/**
 * findNodeByGetValueFrom returns the node that has its saveResultTo value set to any of getValueFrom values
 *
 * @param {string | string[]} getValueFrom - value to search for
 * @param {Node<any>[]} nodes - list of nodes from the builder graph to search in
 * @return {Node<any>|undefined} a single node if found, undefined otherwise
 */
export const findNodeByGetValueFrom = (
  getValueFrom: string | string[],
  nodes: Node<any>[]
): Node<any> | undefined => {
  const searchedNodes = nodes
    .map((node: Node<any>) => {
      return node.data.state.entry
        .map((entry: StateEntryProps) => {
          if (entry && entry.payload && entry.payload.saveResultTo) {
            let mynode = undefined;
            if (typeof getValueFrom === 'string') {
              if (entry.payload.saveResultTo === getValueFrom) {
                mynode = node;
              }
            } else {
              if (Array.isArray(getValueFrom)) {
                if (getValueFrom.includes(entry.payload.saveResultTo)) {
                  mynode = node;
                }
              }
            }
            return mynode;
          } else {
            return undefined;
          }
        })
        .flat()
        .filter((entry: any) => entry !== undefined);
    })
    .flat();

  if (searchedNodes && searchedNodes.length > 0) {
    // expect only 1 !!
    return searchedNodes[0];
  } else {
    return undefined;
  }
};

/**
 * regenerateNodeIds adapts all ids in of all nodes in a builder graph and returns the mapping old id => new id
 * @param {ReactFlowElements} buildergraph - as it is passed as a reference, it is also modified!
 * @returns {Map<string,string>} returns a mapping of old node id => new node id . This can be used to use it with @see adjustEdges
 */
export const regenerateNodeIds = (buildergraph: ReactFlowElements) => {
  const idMap = new Map<string, string>();
  buildergraph = buildergraph.map((element: any) => {
    if (isEdge(element)) {
      return element;
    }
    const newId = generateRandomString(8);
    idMap.set(`${element.id}`, newId);
    element.id = newId;
    // although stateKey is modified when generating an state machie in module utils,
    // we adapt it here, too, to get different ids in property pane
    if (element.data && element.data.state) {
      element.data.state.stateKey = newId;
    }
    return element;
  });
  return { buildergraph: buildergraph, idmap: idMap };
};

/**
 * adjustEdges generates a new egde id and adapts ids in source and target
 * with the idMap
 * @param {ReactFlowElements} buildergraph - builder graph to be adjusted - to be checked => as it is passed as a reference, it is also modified!
 * @param {Map<string, string>} - id mapping old node id => new node id generated by @see regenerateNodeIds
 * @return updated builder graph
 */
export const adjustEdges = (
  buildergraph: ReactFlowElements,
  idMap: Map<string, string>
) => {
  //const idMap = new Map<string, string>();
  return buildergraph.map((element: any) => {
    if (isNode(element)) {
      return element;
    }
    element.id = generateRandomString(8);
    element.source = idMap.get(element.source) ?? '';
    element.target = idMap.get(element.target) ?? '';

    // PROD-1986, s. also function updateLegacyEdgeHandles in ModuleBuilder
    if (element?.sourceHandle === 'messagehandle') {
      element.sourceHandle = 'messageouthandle';
    }
    if (element?.targetHandle === 'messagehandle') {
      element.targetHandle = 'messageinhandle';
    }
    return element;
  });
};

/**
 * adjustDynamicHandles changes keyTexts in a node as well as their corresponding sourceHandles.
 * adjustDynamicHandles searches for nodes that contain elements with
 * dynamically generated handles like RadioButton and MultiButton. If they
 * exist, they are adapted for payload.keyTexts properties and for their
 * corresponding handles
 *
 * @param {ReactFlowElements} buildergraph to be checked => as it is passed as a reference, it is also modified!
 * @return {Map<string, string>()} returns a list of mappings old keyText ids => new keyText ids
 */
export const adjustDynamicHandles = (buildergraph: ReactFlowElements) => {
  // create mapping of old and new keyTexts
  const keyTextsMap = new Map<string, string>();

  return buildergraph.map((element: any) => {
    //
    if (isNode(element) && element.data && element.data.state) {
      if (
        element.data.state.entry[0].type === 'renderRadioButton' ||
        element.data.state.entry[0].type === 'renderMultiButton'
      ) {
        // if payload with keyTexts exist, then adapt
        if (
          element.data.state.entry[0].payload &&
          element.data.state.entry[0].payload.keyTexts
        ) {
          // update all keyTexts
          element.data.state.entry[0].payload.keyTexts =
            element.data.state.entry[0].payload.keyTexts.map((key: string) => {
              // replace each key and remember mapping oldKey=>newKey in keyTextsMap
              const newKey = 'keytext.' + generateRandomString(4);
              keyTextsMap.set(key, newKey);
              return newKey;
            });

          // if keytexts were adapted, we have to adapt all corresponding edges
          // 1. find all outgoing edges of current node
          let edgesToBeAdapted = buildergraph
            .filter(isEdge)
            .filter((edge: Edge<any>) => edge.source === element.id);
          // 2. adapt source handles of edges when affected by a keyText change above
          edgesToBeAdapted.forEach((edge: Edge<any>) => {
            //
            edge.sourceHandle = keyTextsMap.get(edge.sourceHandle + '');
            return edge;
          });
        }
      }
    }

    return element;
  });
};

/** updatePositions
 * @param {ReactFlowElements} elements list of elements with adjusted positions
 * @param {number} offsetX - value to be added to the x position of an element
 * @param {number} offsetY - value to be added to the y position of an element
 * @returns list of modified elements
 */
export const adjustNodePositions = (
  elements: ReactFlowElements,
  offsetX: number,
  offsetY: number
) => {
  // adjust the whole set of node positions by removing the offset
  // of the position of the "highest" element
  const elementOnTopPosition = getNodeWithMinYPosition(
    elements.filter(isNode)
  ).position;

  return elements.map((element: any) => {
    // adapt only nodes
    if (isEdge(element)) {
      return element;
    }
    element.position = {
      x: element.position.x + offsetX - elementOnTopPosition.x,
      y: element.position.y + offsetY - elementOnTopPosition.y,
    };
    // the __rf element is an undocumented property that keeps a lot of internal information
    // of the node and the handles, e.g. the handle positions. You can safely delete it.
    // if you do not delete it, the handle positions of the node may be wrongly calculated,
    // leading to "free floating" edges
    delete element.__rf;
    return element;
  });
};

/**
 * get node with minimal y position
 * This function is used to determine the top node of an insertion
 * and use its original position as offset for the new position
 */
export const getNodeWithMinYPosition = (nodes: Node<any>[]) =>
  nodes.reduce((topElement, currentElement) =>
    Math.min(topElement.position.y, currentElement.position.y) ===
    currentElement.position.y
      ? currentElement
      : topElement
  );

/** calculate the distance of a node and a given reference position
 * @param {XYPosition} referencePosition
 * @param {Node} node
 * @returns the distance in the unit of the inital coordinates
 */
export const getNodeDistance = (
  node: Node<any>,
  referencePosition: XYPosition
): number => {
  // credits go out to Pythagoras
  return Math.sqrt(
    Math.pow(node.position.x - referencePosition.x, 2) +
      Math.pow(node.position.y - referencePosition.y, 2)
  );
};

/** calculates the distances of a set of nodes to a given position in the canvas
 * and adds an ignoreNodeOffset if the node in nodes is "above" the projectedCursorPosition
 * @param {Node<any>[]} nodes
 * @param {XYPosition} projectedCursorPosition
 * @param {number} ignoreNodeOffset
 * @returns {number[]} array of distances
 */
export const getNodeDistances = (
  nodes: Node<any>[],
  projectedCursorPosition: XYPosition,
  ignoreNodeOffset: number = 10000
): number[] => {
  return nodes.map((node: Node) => {
    let dist = getNodeDistance(node, projectedCursorPosition);
    // if node is above the currect drop y, then ignore node
    // by adding a ignoreNodeOffset - this offset is also used
    // to distinguish nodes to move from those not to move
    if (node.position.y <= projectedCursorPosition.y) {
      dist = dist + ignoreNodeOffset;
    }
    return dist;
  });
};

/**
 * this function removes edges in a set of elements of which either the
 * target node or the source node is not present in the list of elements.
 * This function is needed because (depending on the selection in the canvas)
 * if may happen that edges are copied without their nodes. This leads to
 * errors => we need this function to remove such nodes
 */
export const removeLooseEdges = (
  elements: ReactFlowElements
): ReactFlowElements => {
  //
  return elements
    .map((element: any) => {
      if (isNode(element)) {
        return element;
      }
      if (
        !findNodeByNodeId(element.source, elements.filter(isNode)) ||
        !findNodeByNodeId(element.target, elements.filter(isNode))
      ) {
        return undefined;
      }
      return element;
    })
    .filter((element: any) => element !== undefined);
};

/**
 *
 * Return all edges of which the source node XOR the target node
 * (or both) are not in the list of nodes. Similar:  @see getContainedEdges
 *
 * @param {Node<any>[]} nodes = nodes
 * @param {Edge<any>[]} edges = edges
 * @returns {Edge<any>[]}
 */
export const getLooseEdges = (
  nodes: Node<any>[],
  edges: Edge<any>[]
): Edge<any>[] => {
  return edges.filter(
    (edge: Edge<any>) =>
      (!findNodeByNodeId(edge.source, nodes) &&
        findNodeByNodeId(edge.target, nodes)) ||
      (findNodeByNodeId(edge.source, nodes) &&
        !findNodeByNodeId(edge.target, nodes))
  );
};

/**
 *
 * Return all edges of which the source node and the target node
 * is in the list of nodes. This is similar to @see getLooseEdges
 *
 * @param {Node<any>[]} nodes = nodes to be removed
 * @param {Edge<any>[]} edges = all edges in the system
 * @returns {Edge<any>[]}
 */
export const getContainedEdges = (
  nodes: Node<any>[],
  edges: Edge<any>[]
): Edge<any>[] => {
  return edges.filter(
    (edge: Edge<any>) =>
      findNodeByNodeId(edge.source, nodes) &&
      findNodeByNodeId(edge.target, nodes)
  );
};

/** getEgdes receives a list of nodes and automatically creates egdes between these nodes.
 * It is used in Copy/Paste actions if we paste a set of nodes derived from clipboard data
 * and want to automatically connect these nodes
 * @param {Node<any>[]} newNodes - the list of nodes to be connected
 * @return {Edge<any>[]} list of edges that connects all the nodes
 */
export const getEdges = (newNodes: Node<any>[]): Edge<any>[] => {
  const newEdges: Edge<any>[] = [];
  newNodes.forEach((node: Node<any>, index: number) => {
    const targetId =
      index < newNodes.length - 1 ? newNodes[index + 1].id : undefined;
    if (targetId)
      newEdges.push(new StateEdge(generateRandomString(8), node.id, targetId));
  });
  return newEdges;
};

/**
 * move elements moves all elements with the given yOffset.
 * If ignoreNodeOffset and the array of nodeDistances is provided, only
 * elements that are above the ignoreoffSet are moved, others stay untouched
 * @param {Node<any>[]} nodes - list of elements in which nodes are moved
 * @param {number} yOffset - y offset to be moved
 * @param {number[]} nodeDistances @optional - list of node distances to a given position in canvas
 * @param {number} ignoreNodeOffset @optional - offset that is built into the nodeDistances and that indicates that this node should be ignored
 * @returns {ReactFlowElements} updated list of elements
 */
export const moveNodes = (
  nodes: Node<any>[],
  yOffset: number,
  nodeDistances?: number[],
  ignoreNodeOffset?: number,
  xOffset?: number
) => {
  // use independent index because we want to index nodes only
  let nodeindex = 0;

  return nodes.map((node: Node<any>) => {
    // if element is not an edge, it is a node => adapt position

    if (nodeDistances !== undefined && ignoreNodeOffset !== undefined) {
      // increment, no matter whether node is adapted or not
      nodeindex++;

      // if node and above inserting drop zone, leave untouched, too
      if (nodeDistances[nodeindex - 1] > ignoreNodeOffset) {
        return node;
      }
    }

    node.position = {
      x: node.position.x + (xOffset ?? 0),
      y: node.position.y + yOffset,
    };

    return node;
  });
};

/**
 * gets the outgoing edges for the current node
 * @param {Node<any>} node - node for which the outgoing edges are evaluated
 * @param {Edge<any>[]} edges - list of connections
 * @returns {Edge<any>[]} outgoing edges
 */
export const getOutgoingEdges = (
  node: Node<any>,
  edges: Edge<any>[]
): Edge<any>[] => {
  return edges.filter((edge: Edge<any>) => {
    return edge.source === node.id;
  });
};

/**
 * gets the incoming edges for the current node
 * @param {Node<any>} node - node for which the incoming edges are evaluated
 * @param {Edge<any>[]} edges - list of connections
 * @returns {Edge<any>[]} outgoing edges
 */
export const getIncomingEdges = (
  node: Node<any>,
  edges: Edge<any>[]
): Edge<any>[] => {
  return edges.filter((edge: Edge<any>) => {
    return edge.target === node.id;
  });
};

/**
 * finds any end node in elements. An end node is either a phase end node or if
 * no such node exists, a node without any outgoing egdes
 * @param {Node[]} nodes
 * @param {Edge[]} edges
 * @returns {Node<any> | undefined} undefined or a node
 */
export const getAnyEndNode = (
  nodes: Node<any>[],
  edges: Edge<any>[]
): Node<any> | undefined => {
  if (nodes.length === 0) return undefined;
  let endnode: Node<any> | undefined = findPhaseEndNode(nodes);
  // if (endnode !== undefined) {
  nodes.forEach((node: Node) => {
    if (node.data.nodeType !== 'noteStateEntry') {
      const hasOutgoingEdges = getOutgoingEdges(node, edges).length > 0;
      endnode = !hasOutgoingEdges && endnode === undefined ? node : endnode;
    }
  });
  // }
  return endnode;
};

/**
 * calculate the maximum depth of the graph provided in elementzs
 * @param {ReactFlowElements} elements - list of nodes and edges for the current graph
 * @returns {number} maximum depth
 */
export const maxGraphDepth = (elements: ReactFlowElements): number => {
  const nodes = elements.filter(isNode).map((node: Node<any>) => {
    node.data['depth'] = 0;
    return node;
  });
  const edges = elements.filter(isEdge) as Edge<any>[];

  const startnodeid = findAnyStartNode(nodes, edges);

  // start with startnode and annotate each node with depth value
  let maxdepth = 0;

  let currentNode = findNodeByNodeId(startnodeid, elements.filter(isNode));

  if (!currentNode) {
    return 0;
  }

  const annotateDepth = (
    currentNode: Node<any>,
    previousNode: Node<any> | undefined
  ): number => {
    if (currentNode.data['incallstack'] === true)
      return currentNode.data['depth'];

    // if startnode, set depth to 1
    if (previousNode === undefined) {
      currentNode.data['depth'] = 1;
    } else {
      // if not startnode, then set to previous' node depth + 1
      currentNode.data['depth'] = (previousNode.data['depth'] ?? 0) + 1;
    }

    // get all outgoing edges of a node
    const currentOutgoingEdges = getOutgoingEdges(currentNode, edges);

    let mdepth = currentNode.data['depth'];
    // check for each outgoing edge the next node
    currentOutgoingEdges.forEach((edge: Edge<any>) => {
      // get next node
      const nextNode = findNodeByNodeId(edge.target, elements.filter(isNode));

      // if there is a next Node, recursive call.
      //  If there is no next node, do nothing
      if (nextNode) {
        // is there already a depth defined?
        const nextDepth = nextNode.data['depth'];

        let depthReduce;
        // if the next node has no depth or if the depth is smaller
        // then the one of the current node, then recursive call
        if (nextDepth === undefined || nextDepth <= currentNode.data['depth']) {
          currentNode.data['incallstack'] = true; // consider loops
          // go the next node and annotate
          depthReduce = annotateDepth(nextNode, currentNode);
          currentNode.data['incallstack'] = false;
        } else {
          // nextdepth exists and > currentNode.data['depth']
          // then return depth of next node
          depthReduce = nextDepth;
        }
        // the maximum is the new mdepth
        mdepth = Math.max(depthReduce, mdepth);
      }
    });

    return mdepth;
  };

  maxdepth = annotateDepth(currentNode, undefined);
  //console.log('===> maxdepth: ' + maxdepth);

  return maxdepth;
};

/**
 * reset all percentage values to 0 in any node that has a concerning entry type
 * @param {ReactFlowElements} elements
 * @returns modified elements
 */
export const resetProgressValues = (
  elements: ReactFlowElements
): ReactFlowElements => {
  return elements.map((elem: any) => {
    // return edges untouched
    if (isEdge(elem)) {
      return elem;
    }

    // set progress to 0 if a corresponding entry exists
    if (
      elem.data.state.entry.length > 1 &&
      elem.data.state.entry[1].payload.progressPercent !== undefined
    ) {
      elem.data.state.entry[1].payload.progressPercent = 0;
    }
    return elem;
  });
};

/**
 * generate progress values based on current grapg length
 * 1) make a deep seach and search the longest path from start to end
 * 2) go through path and add a percentage for each step, calced based on path length
 *
 * @param {ReactFlowElements} origelements = list of original elements - will be deep-cloned before usage
 * @returns {ReactFlowElements} list of modified elements
 */
export const autoGenerateProgressValues = (
  origelements: ReactFlowElements
): ReactFlowElements => {
  if (!origelements || origelements.length === 0) {
    return origelements;
  }
  const elements = resetProgressValues(cloneDeep(origelements));
  const maxGraphDepthValue = maxGraphDepth(elements);
  const edges = elements.filter(isEdge);
  const endnode = getAnyEndNode(elements.filter(isNode), edges);

  //console.log(endnode);

  if (!endnode) {
    return origelements;
  }

  const annotatePercentage = (
    currentNode: Node,
    currentDepth: number
  ): void => {
    if (currentDepth === 0) return;
    if (currentNode.data['incallstack'] === true) return;

    if (
      currentNode.data.state.entry.length > 1 &&
      currentNode.data.state.entry[1].payload.progressPercent !== undefined
    ) {
      currentNode.data.state.entry[1].payload.progressPercent = Math.round(
        currentDepth * (100 / maxGraphDepthValue)
      );
    }
    // currentNode.data['visited'] = true;

    const incomingEdges = getIncomingEdges(currentNode, edges);

    if (incomingEdges.length > 0) {
      incomingEdges.forEach((edge: Edge<any>) => {
        const previousNode = findNodeByNodeId(
          edge.source,
          elements.filter(isNode)
        );
        if (!previousNode) {
          return;
        }

        if (
          previousNode.data.state.entry.length > 1 &&
          previousNode.data.state.entry[1].payload.progressPercent !== undefined
        ) {
          // there is a previus node with any progressPercent
          if (
            previousNode.data.state.entry[1].payload.progressPercent === 0 ||
            previousNode.data.state.entry[1].payload.progressPercent >
              Math.round((currentDepth - 1) * (100 / maxGraphDepthValue))
          ) {
            currentNode.data['incallstack'] = true; // detect loops
            annotatePercentage(previousNode, currentDepth - 1);
            currentNode.data['incallstack'] = false;
          }
          return;
        }
      });
    } else {
      // no incoming edges ==> start
      return;
    }
  };

  // walk backwards
  annotatePercentage(endnode, maxGraphDepthValue);

  return elements;
};

/**
 * estimates the time that it takes for user to cope with the node in the chat
 * @param {Node<any>} node for which the duration in caht should be estimated
 * @param {TranslationProps} stateMachineTranslation - needed to check text length etc.
 * @returns {number} duration
 */
const getDurationByNodeType = (
  node: Node<any>,
  stateMachineTranslation: TranslationProps
): number => {
  let duration = 0.5;

  try {
    if (
      node.type === 'coachMessageStateEntry' ||
      node.type === 'aiCoachMessageStateEntry'
    ) {
      // coach message: every character in the message takes 40 ms max (s. SessionPlayer)
      duration =
        (stateMachineTranslation[node.data.state.entry[0].payload.message]
          .length *
          (40 / 1000)) /
        60;
    }

    if (node.type === 'messageInputStateEntry') {
      // if you enter something, it may take up to 1 min
      duration = 0.5;
    }

    if (
      node.type === 'needsInputStateEntry' ||
      node.type === 'emotionsInputStateEntry'
    ) {
      // complex elements take at least 3 min
      duration = 2;
    }

    if (
      node.type === 'videoDisplayStateEntry' ||
      node.type === 'audioDisplayStateEntry'
    ) {
      // audio +video take 4 min
      duration = 3;
    }
  } catch (_e: any) {
    duration = 0.5;
    console.log('duration error');
  }

  return duration;
};

/**
 * estimate the duration for each node
 * @param {ReactFlowElements} elements - list of nodes and edges for the current graph
 * @param {TranslationProps} stateMachineTranslation - needed to check text length etc.
 * @returns {number} maximum depth
 */
export const estimatedGraphDuration = (
  elements: ReactFlowElements,
  stateMachineTranslation: TranslationProps
): number => {
  const nodes = elements.filter(isNode).map((node: Node<any>) => {
    node.data['duration'] = 0;
    return node;
  });
  const edges = elements.filter(isEdge) as Edge<any>[];

  const startnodeid = findAnyStartNode(nodes, edges);

  // start with startnode and annotate each node with depth value
  let maxduration = 0;

  let currentNode = findNodeByNodeId(startnodeid, elements.filter(isNode));

  if (!currentNode) {
    return 0;
  }

  const annotateDuration = (
    currentNode: Node,
    previousNode: Node | undefined
  ): number => {
    if (currentNode.data['incallstack'] === true)
      return currentNode.data['duration'];

    if (previousNode === undefined) {
      currentNode.data['duration'] = getDurationByNodeType(
        currentNode,
        stateMachineTranslation
      );
    } else {
      currentNode.data['duration'] =
        (previousNode.data['duration'] ?? 0) +
        getDurationByNodeType(currentNode, stateMachineTranslation);
    }

    const currentOutgoingEdges = getOutgoingEdges(currentNode, edges);

    const depth = currentOutgoingEdges.reduce(
      (duration: number, edge: Edge<any>) => {
        const nextNode = findNodeByNodeId(edge.target, elements.filter(isNode));
        //console.log(nextNode?.id);
        //console.log(depth);

        if (nextNode) {
          const nextDuration = nextNode.data['duration'];

          if (!nextDuration) {
            currentNode.data['incallstack'] = true; // consider loops
            duration = Math.max(
              annotateDuration(nextNode, currentNode),
              duration
            );
            currentNode.data['incallstack'] = false;
          } else {
            // depth in next node exists
            if (nextDuration <= currentNode.data['duration']) {
              // loop ==> <=, ==> keep currentnode depth
              // branching with different depths ==> >
              currentNode.data['incallstack'] = true; // consider loops
              duration = Math.max(
                annotateDuration(nextNode, currentNode),
                duration
              );
              currentNode.data['incallstack'] = false;
            } else {
              duration = currentNode.data['duration'];
            }
          }
        }
        return duration;
      },
      currentNode.data['duration']
    );

    return depth;
  };

  maxduration = annotateDuration(currentNode, undefined);

  return maxduration;
};
/**
 * estimate the module duration and return value in minutes
 *
 * @param {ReactFlowElements} origelements = list of original elements
 * @param {TranslationProps} stateMachineTranslation - needed to check text length etc.
 * @returns {number} estimated duration in minutes (min: 1 min.)
 */
export const estimateModuleDuration = (
  origelements: ReactFlowElements,
  stateMachineTranslation: TranslationProps
): number => {
  if (!origelements || origelements.length === 0) {
    return 0;
  }

  // 1) max depth
  const maxGraphDepthValue = Math.round(
    estimatedGraphDuration(origelements, stateMachineTranslation)
  );

  // value should be at least 1
  return Math.max(maxGraphDepthValue, 1);
};

/**
 * findAllTranslationsForNodes
 *
 * Find all translations that belong to a list of nodes.
 * Please note that this fuction is legacy is is currently not in use
 *
 * @param {Node<any>[]} nodelist - list of nodes that are checked
 * @param {TranslationProps} stateMachineTranslation - current StateMachineTranslation
 * @returns {TranslationProps} translations - list of translations
 */
export const findAllTranslationsForNodes = (
  nodelist: Node<any>[],
  stateMachineTranslation: TranslationProps
) => {
  const deletedTranslations: TranslationProps = {};

  nodelist.forEach((node: Node<any>) => {
    const payload = node.data.state.entry[0].payload;
    Object.keys(payload)
      .filter((key: string) =>
        CustomNodes.isTranslatedStatePayloadProp(key, payload[key])
      )
      .forEach((propertyKey) => {
        const valueOrArray = payload[propertyKey];
        if (Array.isArray(valueOrArray)) {
          // handle an array of texts / translation keys
          Object.keys(valueOrArray).forEach((key: string) => {
            const translationKey = payload[propertyKey][key];
            // remember key to be deleted
            deletedTranslations[translationKey] =
              stateMachineTranslation[translationKey];
          });
        } else {
          const translationKey = valueOrArray as string;
          deletedTranslations[translationKey] =
            stateMachineTranslation[translationKey];
        }
      });
  });
  return deletedTranslations;
};
