import { evoachDefaultTheme } from '@evoach/ui-components';

import { ReadWriteType, StateEntryProps } from '.';
/*
  CompareNumbersStateEntry xState specific defintions
*/

export const compareNumbersStateEntry: StateEntryProps = {
  version: 2,
  type: 'setCompareNumbers',
  temporary: false,
  payload: {
    firstValueToCompare: '',
    secondValueToCompare: '',
  },
  getValueType: ReadWriteType.number,
  displayName: 'Compare Numbers', // rename to multi because we may need a single output handle in the future
  nodeType: 'compareNumbersStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 3,
};
