import { EvoachButton } from '@evoach/ui-components';
import React from 'react';

import { useFetchModule } from '../../../api/module/useFetchModule';
import { generateDownload } from '../../../util/generateDownload';

interface AdminCopyModuleByJsonProps {
  moduleid: string;
}

export const AdminCopyModuleByJson: React.FC<AdminCopyModuleByJsonProps> = ({
  moduleid,
}) => {
  const { module } = useFetchModule(moduleid);

  const downloadJson = () => {
    // generate a download with the module
    generateDownload(
      [JSON.stringify(module)],
      moduleid + '.admin.evoach',
      'json'
    );
  };
  //
  return <EvoachButton onClick={downloadJson}>Download JSON</EvoachButton>;
};
