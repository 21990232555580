import { generateRandomString } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.scaleInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
  scaleName: {
    id: 'builder.nodes.scaleInput.state.scaleName',
    defaultMessage: 'Titel für 10er Skala',
  },
});

export const scaleInputMultiStateEntry: StateEntryProps = {
  version: 'v2',
  type: 'renderScaleInputMulti',
  temporary: true,
  payload: {
    buttonText: 'builder.nodes.scaleInput.state.buttonText',
    scaleName: 'builder.nodes.scaleInput.state.scaleName',
    saveResultTo: 'scale_result',
    keyTexts: [
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
      'scaleinputmulti.' + generateRandomString(4),
    ],
    scaleSize: 10,
    scale: 5,
    getValueFrom: '',
    getStringValues: [''],
  },
  saveResultType: ReadWriteType.number,
  getValueType: ReadWriteType.number,
  displayName: 'Scale Input Multi',
  nodeType: 'scaleInputMultiStateEntry',
  handleOutCount: 'dynamic',
};
