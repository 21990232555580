import { Handle, NodeProps, Position } from 'reactflow';
import { MultipleDisplayEnum, SingleDisplay } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  SingleDisplay
*/

export const SingleDisplayNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  const message = translateKey(intl, payload.message);
  const topics: string[] = [];
  topics.push(
    intl.formatMessage({
      id: 'builder.node.singledisplaynode.topic1.placeholder',
      defaultMessage: 'Thema 1',
    })
  );
  topics.push(
    intl.formatMessage({
      id: 'builder.node.singledisplaynode.topic2.placeholder',
      defaultMessage: 'Thema 2',
    })
  );
  topics.push(
    intl.formatMessage({
      id: 'builder.node.singledisplaynode.topic3.placeholder',
      defaultMessage: 'Thema 3',
    })
  );

  return (
    <>
      <Handle
        type="target"
        id="messageinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ maxWidth: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <SingleDisplay
          bubbleType={MultipleDisplayEnum.BUBBLE}
          message={message}
          topics={topics}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="messageouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
