import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.shareSession.state.message',
    defaultMessage: 'Hier ist deine editierbare Coach Nachricht.',
  },
});

export const shareSessionStateEntry: StateEntryProps = {
  type: 'renderShareSession',
  temporary: false,
  payload: {
    message: 'builder.nodes.shareSession.state.message',
  },
  displayName: 'Share Session',
  saveResultType: ReadWriteType.string,
  nodeType: 'shareSessionStateEntry',
  handleOutCount: 2, // number of outgoing handles
};
