import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'singleAnswerPromptStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kann mit Hilfe Künstlicher Intelligenz ein Prompt mit einer Eingabe gesendet werden. Das Ergebnis wird in eine Variable geschrieben.',
  },
  prompttype: {
    id: 'singleAnswerPromptStateEntry.promptType',
    defaultMessage:
      'Wähle die KI-Funktion, die in dieser Komponente verwendet werden soll.',
  },
  maxSentences: {
    id: 'singleAnswerPromptStateEntry.maxSentences',
    defaultMessage:
      'Max. Anzahl der Sätze, die von der KI zurückgegeben werden sollen. Der Parameter hat für "pickTop3Catgeories" keine Funktion.',
  },
  externalService: {
    id: 'singleAnswerPromptStateEntry.externalService',
    defaultMessage: 'Definiere Sprachmodell und Version.',
  },
});

/**
 * list of prompts that can be used within this node
 * ExternalServiceEnum.OPENAI_CHAT_35 only!
 * TODO we may extend this list by adding the service and distinguish the allowed prmpts by service
 */
export const singleAnswerPromptAllowedPromptTypes: PromptEnum[] = [
  PromptEnum.PARAPHRASE,
  PromptEnum.PICKTOP_3_CATEGORIES,
  PromptEnum.SUMMARIZE,
];
/*
remove from single Answer Prompt, PROD-2071
  PromptEnum.PARAPHRASE_AND_PICK_TOP_3_CATEGORIES,
  PromptEnum.PARAPHRASE_AND_PICK_TOP_CATEGORY,
  PromptEnum.PICK_BEST_FITTING_CATEGORY,
  PromptEnum.SINGLEPROMPT_SMART_PARAPHRASE,
*/

/**
 * The singleAnswerPrompt component is used to retrieve a single answer for a sepcific
 * prompt. It returns only a single string without further information, i,e,
 * no JSON information or chat information.
 */
export const singleAnswerPromptStateEntry: StateEntryProps = {
  version: 2,
  type: 'sendExternal',
  temporary: false,
  payload: {
    getValuesFrom: [],
    saveResultTo: '',
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.PARAPHRASE,
    prompt: '',
    maxSentences: 5, // only used in Summarize
  },
  getValueType: ReadWriteType.string,
  saveResultType: ReadWriteType.string,
  displayName: 'Single Answer Prompt (AI)',
  nodeType: 'singleAnswerPromptStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 1,
};
