import { Handle, NodeProps, Position } from 'reactflow';
import { ImageItem, ImageSelect } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';

/*
  YesNoButton react-flow node for module builder graph canvas
*/

export const ImageSelectorDisplayNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const exampleImages: ImageItem[] = [
    {
      type: 'imageselect',
      src: 'builder.nodes.imageselector.state.dummydata.acceptanceurl',
      assetid: ' ', //space is mandatory
      value: 'builder.nodes.imageselector.state.dummydata.acceptance',
      resolvedsrc:
        'https://staging.player.evoach.de/assets/cardimages2/acceptance.png',
    },
    {
      type: 'imageselect',
      src: 'builder.nodes.imageselector.state.dummydata.collaborateurl',
      assetid: ' ', //space is mandatory
      value: 'builder.nodes.imageselector.state.dummydata.collaborate',
      resolvedsrc:
        'https://staging.player.evoach.de/assets/cardimages2/collaborate.png',
    },
    {
      type: 'imageselect',
      src: 'builder.nodes.imageselector.state.dummydata.supervisionurl',
      assetid: ' ', //space is mandatory
      value: 'builder.nodes.imageselector.state.dummydata.supervision',
      resolvedsrc:
        'https://staging.player.evoach.de/assets/cardimages2/h_supervision.png',
    },
  ];

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <ImageSelect
          buttonText={translateKey(intl, payload.buttonText)}
          images={exampleImages}
          saveResultTo=""
          maxItems={payload.maxItems}
          minItems={payload.minItems}
          displayItems={payload.displayItems}
          isEcho={true}
          showTitles={payload.showTitles}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nexthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
