import {
  evoachDefaultTheme,
  generateRandomString,
} from '@evoach/ui-components';

import { ReadWriteType, StateEntryProps } from '.';
/*
  MultipleCompareNumbersStateEntry xState specific defintions
*/

export const compareMultipleNumbersStateEntry: StateEntryProps = {
  version: 1,
  type: 'setMultipleCompareNumbers',
  temporary: false,
  payload: {
    series: [0],
    keyTexts: ['mulitcompare.' + generateRandomString(4)],
    getValueFrom: '',
  },
  getValueType: ReadWriteType.number,
  displayName: 'Compare Multiple Numbers',
  nodeType: 'compareMultipleNumbersStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 'dynamic', // user can define the number of ranges
};
