import {
  CardImageEnum,
  EvoachButton,
  EvoachLinkify,
} from '@evoach/ui-components';
import {
  ContentCopy,
  InfoRounded,
  Label,
  ModeEdit,
  OpenInNew,
  Settings,
  WatchLater,
  Wysiwyg,
} from '@mui/icons-material';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Box,
  CardMedia,
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import {
  KeyVisualPositionEnum,
  Program,
  ProgramProps,
  ProgramTypeEnum,
} from '../../entities/Program';
import { useEnvironment } from '../../environment/useEnvironment';
import { SupportedModuleLocales } from '../../intl/SupportedLocales';
import { ProgramRouteTypeEnum } from '../../routing/routes';
//import { MetaDataEditorImageSelect } from '../ModuleEditor/MetaDataEditorImageSelect';
import { useFetchAsset } from '../../api/asset/useFetchAsset';
import { ProgramInstance } from '../../entities';
import {
  LanguageSelectionHeightOptions,
  MetaDataEditorLanguageSelection,
} from '../ModuleEditor/MetaDataEditorLanguageSelection';
import { AssetType } from '../PropertiesSidebar/assetHelper';
import { AssetMenu } from '../PropertiesSidebar/VariableInputs/AssetMenu';
import { mapImageToPublicAsset } from '../reception/assets/AssetHelper';
import { ProgramLabelDialog } from '../reception/coachingprograms/ProgramLabelDialog';
import { useTableStyles } from '../tableHelper';
import { WindowContext } from '../window/WindowContext';
import { AccountContext, RoleEnum } from '../../account';
import { authorizedGet, authorizedPost } from '../../api';
import {
  AsyncCallResponse,
  ExternalServiceEnum,
  PromptEnum,
  PromptResult,
} from '../../entities/ExternalServicesTypes';

import { ProgramKeyVisualDialog } from './ProgramKeyVisualDialog';
import { TranslateProgramOrInstanceDialog } from './TranslateProgramOrInstance';

/*
  ProgramMetaEditor

  Pls check call hierarchy with responsibilities below

  ProgramEditorPage                     - page for router, check route params, load module
    |_ ProgramEditor                    - basic layout and mutate function, manage selected module
!       |_ ProgramMetaEditor            - edit basic information for a program
        |_ ProgramModuleListTimeline    - edit timeline / order of modules
        |_ ProgramModuleEditor          - edit a selected module

 */

export type ProgramMetaEditorProps = {
  programId: string | undefined;
  program: Program | ProgramInstance;
  onChange: Function;
  programRouteType?: ProgramRouteTypeEnum;
  programType: ProgramTypeEnum;
  onChangeProgramType: Function;
  addNewModule: Function;
  refetchProgram: Function | undefined;
};

export const ProgramMetaEditor: React.FC<ProgramMetaEditorProps> = ({
  programId,
  program,
  onChange,
  programRouteType = ProgramRouteTypeEnum.PROGRAM,
  programType,
  onChangeProgramType,
  addNewModule,
  refetchProgram,
}) => {
  const programProps = useMemo(() => program?.program, [program?.program]);

  const intl = useIntl();
  const classes = useTableStyles();
  const { playerBasePath } = useEnvironment();

  const publicProgramUrl = playerBasePath + '/program/' + programId;

  const { alert } = useContext(WindowContext);
  const { hasRole } = useContext(AccountContext);

  const [translateDialogOpen, setTranslateDialogOpen] =
    useState<boolean>(false);

  const isProgramInstance =
    programRouteType === ProgramRouteTypeEnum.INSTANCE + '';

  // helper function
  const getMetaDataFromProgram = (programProps: ProgramProps) => {
    return {
      orderNumber:
        !programProps.ordernumber || programProps.ordernumber + '' === ''
          ? 0
          : programProps.ordernumber,
      programTitle: programProps.title ?? '',
      programDuration: programProps.duration ?? '',
      programDescription: programProps.description ?? '',
      programLanguage: programProps.language ?? SupportedModuleLocales.EN,
      startDate: programProps.startDate ?? new Date(),
    };
  };

  // cache program
  const [programMetaData, updateProgramMetaData] = useReducer(
    (prev: any, next: any) => {
      return { ...prev, ...next };
    },
    getMetaDataFromProgram(programProps)
  );

  useEffect(() => {
    updateProgramMetaData(getMetaDataFromProgram(programProps));
  }, [programId, programProps]);

  // UI states
  const [programLabelDialogIsOpen, setProgramLabelDialogIsOpen] =
    useState<boolean>(false);
  const [previewwindow, setpreviewwindow] = useState<any>(undefined);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialLabels, setInitialLabels] = useState<string[]>([]);
  const [hasProgramModules, setHasProgramModules] = useState(programType === 2);
  const [isKeyVisualModalOpen, setIsKeyVisualModalOpen] = useState(false);

  const handleCopyAcquisitionUrl = () => {
    navigator.clipboard.writeText(publicProgramUrl).then(() =>
      alert(
        intl.formatMessage({
          id: 'builder.programs.metaeditor.copytoclipboardsuccessful',
          defaultMessage: 'Link wurde in Zwischenablage kopiert!',
        })
      )
    );
  };

  const openPreview = () => {
    let newpreviewwindow: Window | null = previewwindow;

    if (!newpreviewwindow || newpreviewwindow.closed) {
      newpreviewwindow = window.open(
        publicProgramUrl,
        'coachingprogrampreviewwindow'
      );
    }

    if (newpreviewwindow) {
      newpreviewwindow.focus();
      setpreviewwindow(newpreviewwindow);
      newpreviewwindow.location = publicProgramUrl as unknown as Location;
    }
  };

  //
  // proxy to update the payload value.
  // Wrapped in useCallback because the reference has to be updated
  // for each state change. If we do not wrap it, the state(Entry) is
  // not properly updated.
  //
  const updatePreviewImageAssetValue = (
    val: string | CardImageEnum | undefined,
    key: 'assetid' | 'src' | 'image'
  ) => {
    if (key === 'image') {
      programProps[key] = val ? (val as CardImageEnum) : undefined;
    } else {
      programProps[key] = val;
    }

    if (key === 'src') {
      programProps['assetid'] = '';
    }
    onChange();
  };

  const { asset: previewImageAsset } = useFetchAsset(programProps.assetid);

  const previewImageExternalUrl =
    programProps.src && programProps.src !== ''
      ? programProps.src
      : programProps.image
      ? mapImageToPublicAsset(programProps.image, playerBasePath)
      : '';

  const previewImageAssetUrl = previewImageAsset?.url ?? '';

  //  assetUrl !== 'filenotfound'
  let previewImageUrlToUse = (
    previewImageAssetUrl !== undefined && previewImageAssetUrl?.trim() !== ''
      ? previewImageAssetUrl
      : previewImageExternalUrl
  ).trim();

  // KV
  const updateKeyVisualAssetValue = (
    val: string | undefined,
    key: 'assetid' | 'src'
  ) => {
    if (key === 'assetid') {
      programProps.keyvisualsrc = undefined;
      programProps.keyvisualassetid = val;
    }
    if (key === 'src') {
      programProps.keyvisualassetid = undefined;
      programProps.keyvisualsrc = val;
    }
    onChange();
  };

  const { asset: keyVisualAsset } = useFetchAsset(
    programProps.keyvisualassetid
  );

  const keyVisualExternalUrl = programProps.keyvisualsrc ?? '';

  const keyVisualAssetUrl = keyVisualAsset?.url ?? '';

  let keyVisualUrlToUse = (
    keyVisualAssetUrl !== undefined && keyVisualAssetUrl?.trim() !== ''
      ? keyVisualAssetUrl
      : keyVisualExternalUrl
  ).trim();

  const handleToggleIsProgramWithModules = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedProgramType = event.target.checked ? 2 : 1;
    onChangeProgramType(updatedProgramType);
    setHasProgramModules(event.target.checked);
  };

  /*   const showPrompt = (title: string) => {
    navigator.clipboard.writeText(generateModulesPrompt(title));
    alert('Prompt copied to clipboard');
  };
 */
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);

  const pollTaks = (taskid: string, retryCounter: number = 0) => {
    // no taskid? How dare you? Skip ...
    if (!taskid || taskid === '') {
      setShowProgressBar(false);
      return;
    }
    //
    // too many re-tries? We started at 0 and retry every 20 sec
    //
    retryCounter++;
    if (retryCounter > 10) {
      setShowProgressBar(false);
      alert(
        `AI call could not be completed after ${retryCounter * 20} seconds`
      );
      return;
    }
    //
    // try refetch
    //
    const aiCall = `/external/${taskid}`;

    // fetch task
    const fetchCall = authorizedGet(aiCall);
    fetchCall()
      .then((res: Response) => {
        res
          .json()
          .then((aiOutput: PromptResult) => {
            if (aiOutput && aiOutput.output !== '') {
              let modJson = {};
              try {
                modJson = JSON.parse(aiOutput.output);
                addNewModule(modJson);
              } catch (reason: unknown) {
                console.error(reason);
              }
              setShowProgressBar(false);
            } else {
              // retry again in 20 seconds
              setTimeout(() => pollTaks(taskid, retryCounter), 20000);
            }
          })
          .catch((_reason: unknown) => {
            console.error(_reason);
            // retry again in 20 seconds
            setTimeout(() => pollTaks(taskid, retryCounter), 20000);
          });
      })
      .catch((_reason: unknown) => {
        console.error(_reason);
        // retry again in 20 seconds
        setTimeout(() => pollTaks(taskid, retryCounter), 20000);
      });
  };

  const addProgramModuleByAi = () => {
    const aiCall = `/external/${ExternalServiceEnum.OPENAI}/prompt/${PromptEnum.INITALPROGRAMMODULE}`;

    const payload = {
      language: program.program.language,
      title: programProps.title,
      description: programProps.description,
    };
    setShowProgressBar(true);
    // fetch template from backend and then call onDropTemplate
    const fetchCall = authorizedPost(aiCall, payload);
    fetchCall().then((res: Response) => {
      res
        .json()
        .then((taskInfo: AsyncCallResponse) => {
          if (taskInfo && taskInfo.taskid !== '') {
            setTimeout(() => pollTaks(taskInfo.taskid, 0), 20000);
          } else {
            alert(
              "Unfortunately, the AI couldn't generate a proper program module. (2)"
            );
          }
        })
        .catch((_reason: unknown) => {
          console.error(_reason);
          setShowProgressBar(false);
          alert(
            "Unfortunately, the AI couldn't generate a proper program module. (1)"
          );
        });
    });
  };

  if (!programProps) {
    return <></>;
  }
  return (
    <>
      <Paper elevation={3} sx={{ padding: '1.5em', position: 'relative' }}>
        <Tooltip
          title={
            isEditMode
              ? intl.formatMessage({
                  id: 'builder.programs.metaeditor.show',
                  defaultMessage: 'Ansehen',
                })
              : intl.formatMessage({
                  id: 'builder.programs.metaeditor.change',
                  defaultMessage: 'Bearbeiten',
                })
          }
          placement="top"
          arrow
        >
          <Fab
            onClick={() => setIsEditMode(!isEditMode)}
            sx={{ position: 'absolute' }}
            color="secondary"
            size="medium"
          >
            {isEditMode ? <Wysiwyg /> : <ModeEdit />}
          </Fab>
        </Tooltip>
        <Box display="flex" justifyContent="space-evenly" flexDirection="row">
          <Box display="flex" flexDirection="row" flexShrink={0}>
            {!program.issubprogram && (
              <Box
                paddingLeft="30px"
                gap="10px"
                flexShrink={0}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box display="flex" justifyContent="center">
                  <Typography
                    display="flex"
                    variant="body1"
                    fontWeight={300}
                    alignItems="center"
                  >
                    {intl.formatMessage({
                      id: 'builder.programs.metaeditor.keyvisual',
                      defaultMessage: 'Banner',
                    })}
                    &nbsp;
                    {isEditMode ? (
                      <Tooltip
                        arrow
                        placement="top"
                        title={intl.formatMessage({
                          id: 'builder.programs.metaeditor.keyvisual.settings',
                          defaultMessage: 'Position und Links editieren',
                        })}
                      >
                        <Settings
                          color="secondary"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setIsKeyVisualModalOpen(true)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        placement="top"
                        title={intl.formatMessage({
                          id: 'builder.programs.metaeditor.keyvisual.info.tooltip',
                          defaultMessage:
                            'Wird als Banner oberhalb oder unterhalb des Programs angezeigt',
                        })}
                      >
                        <InfoRounded color="secondary" />
                      </Tooltip>
                    )}
                  </Typography>
                </Box>

                {keyVisualUrlToUse !== '' ? (
                  <CardMedia
                    component="img"
                    src={keyVisualUrlToUse}
                    sx={{ height: '160px', width: '160px', marginTop: '10px' }}
                  />
                ) : (
                  <Box
                    height="160px"
                    width="160px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    marginTop="10px"
                  >
                    <Typography variant="body2">
                      {intl.formatMessage({
                        id: 'builder.programs.metaeditor.nokeyvisual',
                        defaultMessage: 'Noch kein Banner ausgewählt',
                      })}
                    </Typography>
                  </Box>
                )}
                {isEditMode && !program.issubprogram && (
                  <Tooltip
                    arrow
                    placement="top"
                    title={intl.formatMessage({
                      id: 'builder.programs.metaeditor.keyvisual.tooltip',
                      defaultMessage:
                        'Optimales Seitenverhältnis 4:1 (z.B. 1000x250 Pixel)',
                    })}
                  >
                    <Box
                      flexShrink={0}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <AssetMenu
                        externalUrl={keyVisualUrlToUse}
                        updateAssetValue={updateKeyVisualAssetValue}
                        assetType={AssetType.IMAGE}
                        addPublicAssets
                      />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            )}
            <Box
              paddingLeft="15px"
              gap="10px"
              flexShrink={0}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box display="flex" justifyContent="center">
                <Typography
                  display="flex"
                  variant="body1"
                  fontWeight={300}
                  alignItems="center"
                >
                  {intl.formatMessage({
                    id: 'builder.programs.metaeditor.cardimage',
                    defaultMessage: 'Vorschaubild',
                  })}
                  &nbsp;
                  <Tooltip
                    arrow
                    placement="top"
                    title={intl.formatMessage({
                      id: 'builder.programs.metaeditor.previewimage.tooltip',
                      defaultMessage:
                        'Sichtbar auf der Karte in der Coaching Programm Übersicht',
                    })}
                  >
                    <InfoRounded color="secondary" />
                  </Tooltip>
                </Typography>
              </Box>
              <CardMedia
                component="img"
                src={previewImageUrlToUse}
                sx={{ height: '160px', width: '160px', marginTop: '10px' }}
              />
              {isEditMode && (
                <AssetMenu
                  externalUrl={previewImageExternalUrl}
                  updateAssetValue={updatePreviewImageAssetValue}
                  assetType={AssetType.IMAGE}
                  addPublicAssets
                />
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            width="55vw"
            flexGrow={0}
          >
            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              justifyContent="space-between"
            >
              <Box flexGrow={1}>
                {isEditMode ? (
                  <TextField
                    value={programMetaData.programTitle}
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={2}
                    onBlur={() => onChange()}
                    label={intl.formatMessage({
                      id: 'builder.programs.programmetaeditor.title',
                      defaultMessage: 'Titel',
                    })}
                    onChange={(evt: any) => {
                      updateProgramMetaData({
                        programTitle: evt.target.value,
                      });
                      programProps.title = evt.target.value;
                    }}
                  />
                ) : (
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    whiteSpace="pre-wrap"
                  >
                    {programMetaData.programTitle}
                  </Typography>
                )}
              </Box>

              {programId !== undefined && !isProgramInstance && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ height: '100%' }}
                  flexShrink={0}
                  flexGrow={1}
                >
                  <Tooltip
                    arrow
                    placement="top"
                    title={intl.formatMessage({
                      id: 'builder.programs.metaeditor.copytoclipboard',
                      defaultMessage:
                        'Öffentlichen Link zur Akquiseansicht in Zwischenablage kopieren. Dieser Link ist ohne Login bei evoach einsehbar.',
                    })}
                  >
                    <IconButton
                      color="secondary"
                      onClick={handleCopyAcquisitionUrl}
                    >
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    arrow
                    placement="top"
                    title={intl.formatMessage({
                      id: 'builder.programs.metaeditor.previewtooltop',
                      defaultMessage:
                        'Zeige eine Vorschau des Programms in der öffentlich zugänglichen Akquise-Ansicht des Programms, also der Landing Page des Programms, deren URL du an deine Kunden schicken kannst. ',
                    })}
                  >
                    <EvoachButton
                      onClick={openPreview}
                      sx={{ marginLeft: '20px' }}
                      endIcon={<OpenInNew />}
                    >
                      {intl.formatMessage({
                        id: 'builder.programs.programmetaeditor.preview',
                        defaultMessage: 'Vorschau',
                      })}
                    </EvoachButton>
                  </Tooltip>
                  {hasRole(RoleEnum.EVOACHADMIN) &&
                    !isProgramInstance &&
                    programType ===
                      ProgramTypeEnum.PROGRAM_WITHOUT_SUBMODULES && (
                      <>
                        {showProgressBar ? (
                          <Tooltip
                            arrow
                            placement="top"
                            title={intl.formatMessage({
                              id: 'builder.programs.metaeditor.programmodulesbyaiwait',
                              defaultMessage:
                                'Programmmodul wird generiert. Das kann bis zu zwei Minuten dauern.',
                            })}
                          >
                            <CircularProgress
                              sx={{ marginLeft: '20px' }}
                              color="primary"
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            arrow
                            placement="top"
                            title={intl.formatMessage({
                              id: 'builder.programs.metaeditor.programmodulesbyai',
                              defaultMessage:
                                'Fügt dem Programm ein Programmmodul hinzu, das auf Basis des Titels und der Beschreibung mit Hilfe einer Künstlichen Intelligenz generiert wird.',
                            })}
                          >
                            <EvoachButton
                              sx={{ marginLeft: '20px' }}
                              onClick={() => addProgramModuleByAi()}
                              endIcon={<OpenInNew />}
                            >
                              {intl.formatMessage({
                                id: 'builder.programs.programmetaeditor.moduleprompt',
                                defaultMessage: 'AI',
                              })}
                            </EvoachButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                </Box>
              )}
            </Box>
            <Box>
              {isEditMode ? (
                <TextField
                  label={intl.formatMessage({
                    id: 'builder.programs.programmetaeditor.description',
                    defaultMessage: 'Beschreibung',
                  })}
                  multiline
                  minRows={4}
                  maxRows={4}
                  fullWidth
                  onBlur={() => onChange()}
                  value={programMetaData.programDescription}
                  onChange={(evt: any) => {
                    updateProgramMetaData({
                      programDescription: evt.target.value,
                    });
                    programProps.description = evt.target.value;
                  }}
                />
              ) : (
                <Typography
                  variant="body1"
                  fontWeight={400}
                  whiteSpace="pre-wrap"
                  maxHeight="9em"
                  overflow="auto"
                  marginTop="10px"
                >
                  <EvoachLinkify>
                    {programMetaData.programDescription}
                  </EvoachLinkify>
                </Typography>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
              gap="10px"
            >
              <Box display="flex" flexWrap="nowrap" flexShrink={0}>
                {isEditMode ? (
                  <>
                    <TextField
                      value={programMetaData.programDuration}
                      onBlur={() => onChange()}
                      onChange={(evt: any) => {
                        updateProgramMetaData({
                          programDuration: evt.target.value,
                        });
                        programProps.duration = evt.target.value;
                      }}
                      label={intl.formatMessage({
                        id: 'builder.programs.programmetaeditor.duration',
                        defaultMessage: 'Dauer',
                      })}
                    />
                    <Box marginLeft="0.5em">
                      <MetaDataEditorLanguageSelection
                        moduleLocale={programMetaData.programLanguage}
                        onLanguageChange={(lang: SupportedModuleLocales) => {
                          updateProgramMetaData({
                            programLanguage: lang,
                          });
                          programProps.language = lang;
                        }}
                        displayLabel
                        heightOption={LanguageSelectionHeightOptions.MEDIUM}
                      />
                    </Box>
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'builder.programs.programmetaeditor.translate.tooltip',
                        defaultMessage:
                          'Hier kannst du dein Programm automatisch in eine andere Sprache übersetzen.',
                      })}
                      arrow
                      placement="top"
                    >
                      <IconButton onClick={() => setTranslateDialogOpen(true)}>
                        <TranslateIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title={intl.formatMessage({
                        id: 'builder.programs.programmetaeditor.sortnumber.help',
                        defaultMessage:
                          'Diese Nummer beinflusst die Reihenfolge in einer Liste von Programmen. Je größer die Nummer, desto weiter vorne wird das Programm in einer Liste auftauchen.',
                      })}
                      arrow
                      placement="top"
                    >
                      <TextField
                        sx={{ marginLeft: '10px', width: '150px' }}
                        value={programMetaData.orderNumber}
                        onBlur={() => onChange()}
                        onChange={(evt: any) => {
                          updateProgramMetaData({
                            orderNumber: evt.target.value,
                          });
                          programProps.ordernumber = evt.target.value;
                        }}
                        label={intl.formatMessage({
                          id: 'builder.programs.programmetaeditor.sortnumber',
                          defaultMessage: 'Sortierungszahl',
                        })}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    {programMetaData.programDuration && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap="10px"
                        marginTop="10px"
                      >
                        <WatchLater />
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          whiteSpace="pre-wrap"
                        >
                          {programMetaData.programDuration}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
              {isEditMode && (
                <Box display="flex" justifyContent="flex-end" flexShrink={0}>
                  {isProgramInstance ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label={intl.formatMessage({
                          id: 'builder.programs.programmetaeditor.startdate',
                          defaultMessage: 'Startdatum',
                        })}
                        inputFormat="dd/MM/yyyy"
                        value={programMetaData.startDate}
                        onChange={(evt) => {
                          if (evt) {
                            updateProgramMetaData({
                              startDate: evt,
                            });
                            programProps.startDate = evt;
                            onChange();
                          }
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                        disablePast
                      />
                    </LocalizationProvider>
                  ) : (
                    <Box display="flex" alignItems="center" height="100%">
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography>
                          {intl.formatMessage({
                            id: 'builder.programs.metaeditor.labels',
                            defaultMessage: 'Labels',
                          })}
                        </Typography>
                        <Tooltip
                          arrow
                          placement="top"
                          title={intl.formatMessage({
                            id: 'builder.programs.metaeditor.editlabels',
                            defaultMessage: 'Programmlabel bearbeiten',
                          })}
                        >
                          <IconButton
                            color="inherit"
                            className={classes.cursorPointer}
                            onClick={() => {
                              setInitialLabels(programProps.labels ?? []);
                              setProgramLabelDialogIsOpen(true);
                            }}
                          >
                            <Label />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      {false && (
                        <Box margin="0px 20px">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={hasProgramModules}
                                  onChange={handleToggleIsProgramWithModules}
                                  color="secondary"
                                />
                              }
                              labelPlacement="start"
                              label={intl.formatMessage({
                                id: 'builder.programs.metaeditor.modules',
                                defaultMessage: 'Modulansicht',
                              })}
                            />
                          </FormGroup>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>

      <ProgramLabelDialog
        program={programProps}
        isOpen={programLabelDialogIsOpen}
        onClose={() => {
          setProgramLabelDialogIsOpen(false);
        }}
        initialLabels={initialLabels}
        onUpdate={async (_labels: string[]) => {
          setProgramLabelDialogIsOpen(false);
        }}
      />

      <ProgramKeyVisualDialog
        isOpen={isKeyVisualModalOpen}
        handleClose={() => setIsKeyVisualModalOpen(false)}
        setKeyVisualPosition={(pos: KeyVisualPositionEnum) => {
          program.program.keyvisualposition = pos;
          onChange();
        }}
        linkTexts={program.program.keyvisuallinktext}
        setKeyVisualLinks={(linkText: string) => {
          program.program.keyvisuallinktext = linkText;
          onChange();
        }}
        position={program.program.keyvisualposition ?? undefined}
      />

      <TranslateProgramOrInstanceDialog
        fromLanguage={programMetaData.programLanguage}
        isOpen={translateDialogOpen}
        onClose={(close: boolean) => {
          setTranslateDialogOpen(close);
          if (refetchProgram) {
            refetchProgram();
          }
        }}
        programId={programId}
        programRouteType={programRouteType}
      />
    </>
  );
};
