import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.coachMessage.state.message',
    defaultMessage: 'Hier ist deine Coach Nachricht.',
  },
  d: {
    id: 'coachMessageStateEntry.description',
    defaultMessage:
      'Mit einer Coach Message kannst du als Coach eine Nachricht anzeigen. Die Nachricht kann vollständig aus einer Variable kommen oder ein Text sein, in den Variablen eingebaut werden.',
  },
});

/**
 * CoachMessage xState specific defintions
 * versions
 * - string: v1 OR undefined => initial version
 * - string: v2 => getStringValues
 * - number: 3 => apply maxWidth in builder for each node - do not change visualisation
 *    in builder for old nodes as it changes layout
 * - 4 => PROD-1969 - ai flag
 * - 5 => PROD-1993, ai badge and helper Text should be set automatically => remove from props
 *
 * Please note that there is a duplicate of this component called
 * AICoachMessage => s. AICoachMessage.state.tsx
 */
export const coachMessageStateEntry: StateEntryProps = {
  version: 5,
  type: 'renderCoachMessage',
  temporary: false,
  payload: {
    message: 'builder.nodes.coachMessage.state.message',
    getValuesFrom: [''],
    getStringValues: [''],
    //showAiBadge: false, //  PROD-1969
    //helperText: 'aicoachmessage.aitooltip', // if showAiBadge is true, this helperText is displayed as tooltip
  },
  getValueType: [
    ReadWriteType.string,
    ReadWriteType.stringArray,
    ReadWriteType.typedObject,
  ],
  displayName: 'Coach Message',
  nodeType: 'coachMessageStateEntry',
  handleOutCount: 1,
};
