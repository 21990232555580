import { evoachDefaultTheme } from '@evoach/ui-components';
import { MenuItem, Select, styled } from '@mui/material';

//
// EvoachSelect component was built for PROD-1340 to change highlighting color
// in select lists. We didn't manage to put that style in the UI-components
// theme. As a workaround, we put the styling in this component and replaced all
// MUI Select components with EvoachSelect.
//
export const EvoachSelect = styled(Select)(() => ({
  '&.MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: evoachDefaultTheme.palette.secondary.main,
    },
  },
}));

export const EvoachMenuItem = styled(MenuItem)(() => ({
  '&.Mui-selected, &:hover, &.Mui-selected.Mui-focusVisible': {
    backgroundColor: evoachDefaultTheme.palette.secondary.light,
  },
  '&.Mui-selected': {
    '&:hover': {
      backgroundColor: evoachDefaultTheme.palette.secondary.main,
      color: evoachDefaultTheme.palette.secondary.contrastText,
    },
  },
}));
