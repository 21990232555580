import { Handle, NodeProps, Position } from 'reactflow';
import { ScaleButton } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { useGlobalStyles, translateKey } from './base.node';

/** ScaleButton component for requesting a scale value by clicking on a button */

export const ScaleButtonNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: 450 }}
      >
        <ScaleButton
          saveResultTo={payload.saveResultTo}
          minText={translateKey(intl, payload.minText)}
          maxText={translateKey(intl, payload.maxText)}
          scaleMin={payload.scaleMin as number}
          scaleMax={payload.scaleMax as number}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="scaleoutputhandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
