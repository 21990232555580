import { getEnvironment } from '../environment/useEnvironment';
import { keycloak } from '../keycloak';

import { ApiError } from './ApiError';

const TOKEN_EXPIRY_BUFFER_SECONDS = 30;

export type HttpMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH';

const authorizedApiCall = (
  addApiUrl: boolean = true,
  path: string,
  method: HttpMethod,
  body?: Record<string, any>
) => {
  const { apiBasePath } = getEnvironment();
  const url = addApiUrl ? `${apiBasePath}${path}` : path;

  const apiCallFn = async () => {
    await keycloak.updateToken(TOKEN_EXPIRY_BUFFER_SECONDS);

    const response = await fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json',
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    // fetch does not throw on non-2XX errors, so we have to do it on our own in order to
    // notify react-query of an error
    if (!response.ok) {
      throw new ApiError(
        response.status,
        `Received non-2XX status code for ${response.url}: ${response.statusText}`,
        await response.json()
      );
    }

    return response;
  };

  return apiCallFn;
};

export const authorizedGet = (path: string, addApiUrl?: boolean) =>
  authorizedApiCall(addApiUrl, path, 'GET');

export const authorizedPost = (
  path: string,
  body?: Record<string, any>,
  addApiUrl?: boolean
) => authorizedApiCall(addApiUrl, path, 'POST', body);

export const authorizedPut = (
  path: string,
  body?: Record<string, any>,
  addApiUrl?: boolean
) => authorizedApiCall(addApiUrl, path, 'PUT', body);

export const authorizedPatch = (
  path: string,
  body?: Record<string, any>,
  addApiUrl?: boolean
) => authorizedApiCall(addApiUrl, path, 'PATCH', body);

export const authorizedDelete = (
  path: string,
  body?: Record<string, any>,
  addApiUrl?: boolean
) => authorizedApiCall(addApiUrl, path, 'DELETE', body);
