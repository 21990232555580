import React, { useContext, useEffect, useMemo, useState } from 'react';

import { keycloak } from '../keycloak';
import { useFetchOrCreateAccountQuery } from '../api';
import { FeatureEnum } from '../entities/subscriptionTypes';
import { DevToolsContext } from '../devtools/DevToolsContext';

import {
  AccountContextProps,
  RoleEnum,
  TextSpeed,
} from './AccountContextProps';
import { useEvoachKeycloak } from './useEvoachKeycloak';

export const AccountContext = React.createContext<AccountContextProps>({
  isLoading: true,

  isError: false,
  error: null,

  isAuthenticated: false,
  token: null,

  account: null,

  name: '',
  email: '',

  preferences: {
    textSpeed: TextSpeed.MEDIUM,
    setTextSpeed: () => {},
  },

  roles: [],

  hasRole: () => {},

  failureCount: 0,

  hasFeature: () => {},
  getFeature: () => {},

  refetchAccount: () => {},
});

export type AccountContextProviderProps = {
  children?: React.ReactNode;
};

export const AccountContextProvider: React.FC<AccountContextProviderProps> = ({
  children,
}) => {
  const { l } = useContext(DevToolsContext);

  // get tokens from keycloak
  const {
    isLoading: keycloakIsLoading,
    isAuthenticated,
    token,
  } = useEvoachKeycloak();

  // remeber in localstorage of the user logged in at least once
  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem('evoach.atleastonceloggedininthisbrowser', 'true');
    }
  }, [isAuthenticated]);

  // get account from backend
  const {
    isLoading: backendIsLoading,
    account,
    isError,
    error,
    failureCount,
    refetch,
  } = useFetchOrCreateAccountQuery();

  // get cumulative loading state
  const isLoading = useMemo(() => {
    return keycloakIsLoading || backendIsLoading;
  }, [backendIsLoading, keycloakIsLoading]);

  // get name (from backend account object)
  const name = useMemo(() => {
    if (account?.givenname && account?.familyname) {
      return `${account.givenname} ${account.familyname}`;
    } else {
      return '? ?';
    }
  }, [account?.familyname, account?.givenname]);

  // create an array with roles from the token
  const roles = useMemo(() => {
    if (!token) {
      return [];
    }
    let roles = keycloak.tokenParsed?.realm_access?.roles
      .filter((role: string) =>
        Object.values(RoleEnum).includes(role as RoleEnum)
      )
      .map((role: string) => role as RoleEnum);
    if (roles === undefined) {
      roles = [];
    }
    return roles;
  }, [token]);

  /**
   * provide a function to check whether the current account is in the corresponding role
   * @param {RoleEnum} role of user to be checked
   * @returns {boolean} true = has role, false otherwise
   */
  const hasRole = (role: RoleEnum): boolean => {
    return roles.includes(role);
  };

  // get all features when account loads and memoize them
  const features = account?.features;

  l('AccountContext: features: ');
  l(features);

  /**
   * provide a function to check whether the current account has a specific feature
   * @param {FeatureEnum} feature of user to be checked
   * @returns {boolean} true = has feature (i.e. bool value is true, number value exists), false otherwise
   */
  const hasFeature = (feature: FeatureEnum): boolean => {
    // for bool, check true false, for number, check existence,
    // for both check !== undefined

    // no features available
    if (!features) return false;

    // features available, but specific feature undefined
    if (!features[feature]) return false;

    // sprecific feature available
    return typeof features[feature] === 'boolean'
      ? (features[feature] as boolean) // return bool value
      : true; // for numbers, return true because its available
  };

  /**
   * provide a function to return the value of a feature
   * @param {FeatureEnum} feature of user to be checked
   * @returns {boolean | number | undefined} undefined, if feature is not available, bool or number value otherwise, depending on feature type
   */
  const getFeature = (feature: FeatureEnum): boolean | number | undefined => {
    // no features available
    if (!features) return undefined;

    // features available, but specific feature undefined
    if (!features[feature]) return undefined;

    // sprecific feature available
    return features[feature];
  };

  /**
   * get email (from backend account object)
   */
  const email = useMemo(() => {
    return account?.email ?? '';
  }, [account?.email]);

  // preferences
  const [textSpeed, setTextSpeed] = useState<TextSpeed>(TextSpeed.MEDIUM);

  return (
    <AccountContext.Provider
      value={{
        isLoading,

        isError,
        error,

        isAuthenticated,
        token,

        account,

        name,
        email,

        preferences: {
          textSpeed,
          setTextSpeed,
        },

        roles,

        hasRole,

        failureCount,

        hasFeature,
        getFeature,

        refetchAccount: refetch,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
