import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { ModuleEditorContext } from '../../ModuleEditor/ModuleEditorContext';
import {
  HotOrNotListEntryProps,
  predefinedHotOrNotLists,
  StateEntryProps,
} from '../../nodes';
import { WindowContext } from '../../window/WindowContext';

import { PropertyHeaderLine } from './PropertyHeadLine';

export interface HotOrNotInputProps {
  entry: StateEntryProps;
  updateHotOrNotValues: Function;
}

//
// ! Hinweis
// Mae, 31.05.2022 - I leave old code in this component for future use
// if we add caoabilities to integrate own lists of elements
//

/** HotOrNot property input component ==> select pe-defined list
 *
 * @param {StateEntryProps} entry - complete entry of the HotOrNotSelector node
 * @param {Function} updateHotOrNotValues - handler to pass the index of the selected value
 *
 * TODO in a future version, this control may even handle "custom lists" with an editor of texts+icons. We may use PolarChartInput as template
 */
export const HotOrNotInput: React.FC<HotOrNotInputProps> = ({
  entry,
  updateHotOrNotValues,
}) => {
  const intl = useIntl();

  const { alert } = useContext(WindowContext);
  const { moduleLoadedInDefaultLanguage } = useContext(ModuleEditorContext);

  const [currentListSelection, setCurrentListSelection] = useState<number>(
    entry.predefinedListIndex ?? 0
  );

  const ownList = false;

  /** update the hot or not list with a pre-defined list entry  */
  const updateHotOrNotListIndex = (value: number) => {
    if (!moduleLoadedInDefaultLanguage) {
      alert(
        intl.formatMessage({
          id: 'builder.hotornotinput.notindefaultlang',
          defaultMessage:
            'Du kannst deinem Chatbot nur dann neue Elemente hinzufügen oder Listen in Elementen ändern, wenn du ihn in der Default-Sprache geladen hast.',
        })
      );
      return;
    }
    setCurrentListSelection(value);
    updateHotOrNotValues(value);
  };

  return (
    <div>
      {!ownList && (
        <>
          <PropertyHeaderLine
            header={intl.formatMessage({
              id: 'builder.moduleeditor.propertiessidebar.hotornotinput.predefined',
              defaultMessage: 'Vordefinierte Liste auswählen',
            })}
          />
          <EvoachSelect
            value={currentListSelection}
            onChange={(event) => {
              updateHotOrNotListIndex(event.target.value as number);
            }}
          >
            {predefinedHotOrNotLists.map(
              (list: HotOrNotListEntryProps, index: number) => (
                <EvoachMenuItem key={`hotornotlistentry${index}`} value={index}>
                  {intl.formatMessage({
                    id: list.listName,
                  })}
                </EvoachMenuItem>
              )
            )}
          </EvoachSelect>
        </>
      )}
    </div>
  );
};
