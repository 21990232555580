import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconButton, Stack, TextField } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { generateRandomString } from '@evoach/ui-components';

import { TranslationContext } from '../../stateMachineTranslationContext';
import { StateEntryProps, translateKey } from '../../nodes';

import { PropertyHeaderLine } from './PropertyHeadLine';

export interface PlaceholderTextsInputProps {
  elementId: string;
  entry: StateEntryProps;
  updateMultipleInputValues: Function;
}

export const PlaceholderTextsInput: React.FC<PlaceholderTextsInputProps> = ({
  elementId,
  entry,
  updateMultipleInputValues,
}) => {
  const intl = useIntl();

  const { addOrUpdateStateMachineTranslation, removeStateMachineTranslation } =
    React.useContext(TranslationContext);

  const [placeholderTexts, setPlaceholderTexts] = useState<string[]>(
    entry.payload.placeholderTexts ?? []
  );

  /*  useEffect(() => {
    setPlaceholderTexts(
      entry.payload.placeholderTexts
        ? entry.payload.placeholderTexts.map((key: string) =>
            translateKey(intl, key)
          )
        : []
    );
  }, [entry.payload, intl]);
 */

  const getTranslationKey = () =>
    `${elementId}.placeholderTexts.${generateRandomString(2)}`;

  const addPlaceholder = () => {
    const defaultPlaceholderText =
      intl.formatMessage({
        id: 'builder.moduleeditor.propertiessidebar.placeholdertextinput.defaultplaceholdertext',
        defaultMessage: 'Neuer Platzhaltertext',
      }) + '';
    const translationKey = getTranslationKey();
    placeholderTexts.push(translationKey);

    addOrUpdateStateMachineTranslation(translationKey, defaultPlaceholderText);
    setPlaceholderTexts(placeholderTexts);
    updateMultipleInputValues(placeholderTexts);
  };

  const updatePlaceholder = (value: string, index: number) => {
    addOrUpdateStateMachineTranslation(placeholderTexts[index], value);
    updateMultipleInputValues(placeholderTexts);
  };

  const removePlaceholder = (index: number) => {
    const removedText = placeholderTexts.splice(index, 1)[0];
    removeStateMachineTranslation(removedText);

    setPlaceholderTexts(placeholderTexts);
    updateMultipleInputValues(placeholderTexts, true);
  };

  //console.log(placeholderTexts);

  return (
    <>
      {Object.keys(entry.payload)
        .filter((key: string) => key === 'placeholderTexts')
        .map(() => (
          <div key="unique">
            <PropertyHeaderLine
              header={intl.formatMessage({
                id: 'builder.moduleeditor.propertiessidebar.placeholdertextinput.title',
                defaultMessage: 'Platzhaltertexte',
              })}
            />
            <Stack spacing={1}>
              {placeholderTexts.map((placeholderText, index) => (
                <Stack key={placeholderText} direction="row" spacing={1}>
                  <TextField
                    id={'donotcatchpasteevent_placeholderTexts_' + index}
                    key={`e${index}`}
                    value={translateKey(intl, placeholderText)}
                    onChange={(evt) =>
                      updatePlaceholder(evt.target.value, index)
                    }
                  />
                  <IconButton onClick={() => removePlaceholder(index)}>
                    <RemoveCircleIcon />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
            <IconButton
              onClick={addPlaceholder}
              disabled={placeholderTexts.length > 3}
            >
              <AddCircleIcon />
            </IconButton>
          </div>
        ))}
    </>
  );
};
