import {
  CardImageEnum,
  EvoachButton,
  evoachDefaultTheme,
} from '@evoach/ui-components';
import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUpdateProgramOrInstance } from '../../api/program/useUpdateProgram';
import {
  Program,
  ProgramInstance,
  ProgramModuleActionLinkTypeEnum,
  ProgramTypeEnum,
} from '../../entities';
import { useEnvironment } from '../../environment/useEnvironment';
import { AppHeaderContext } from '../../layout/AppHeaderContext';
import { AppRoutes, ProgramRouteTypeEnum } from '../../routing/routes';
import { sendErrorMail } from '../ErrorBoundary';
import { mapImageToPublicAsset } from '../reception/assets/AssetHelper';
import { CoachingProgramsList } from '../reception/coachingprograms/CoachingProgramsList';
import { WindowContext } from '../window/WindowContext';

import { ProgramMetaEditor } from './ProgramMetaEditor';
import { ProgramModuleEditor } from './ProgramModuleEditor';
import { ProgramModuleListTimeline } from './ProgramModuleListTimeline';

/*
  ProgramEditor

  Pls check call hierarchy with responsibilities below

  ProgramEditorPage                     - page for router, check route params, load module
!    |_ ProgramEditor                    - basic layout and mutate function, manage selected module
        |_ ProgramMetaEditor            - edit basic information for a program
        |_ ProgramModuleListTimeline    - edit timeline / order of modules
        |_ ProgramModuleEditor          - edit a selected module

 */

export type ProgramEditorProps = {
  program: Program | ProgramInstance;
  programRouteType: ProgramRouteTypeEnum | undefined;
  programId: string | undefined;
  refetchProgram?: Function | undefined;
};

export const ProgramEditor: React.FC<ProgramEditorProps> = ({
  program,
  programRouteType,
  programId,
  refetchProgram,
}) => {
  // isLoading is necessary to prevent CoachingProgramList from
  // rendering before the mutate call is done. Without waiting for
  // isLoading = false, there are timing issues and CoachingProgramList
  // may display wrong results
  const { isError, error, mutate, isLoading } = useUpdateProgramOrInstance(
    programId + '',
    programRouteType
  );

  const intl = useIntl();

  const { playerBasePath } = useEnvironment();

  const { alert } = useContext(WindowContext);

  const programProps = program?.program ?? {};

  // set header with title and back-navigation to coachingprograms
  const { setAppHeaderTitle, setAppHeaderHomeroute } =
    useContext(AppHeaderContext);

  // when mounting, set header
  useEffect(() => {
    setAppHeaderTitle(programProps.title);
    setAppHeaderHomeroute(
      programRouteType === ProgramRouteTypeEnum.PROGRAM
        ? '/reception/coachingprograms' // list of programs
        : '/reception/currentprograms' // list of program instances
    );
  });

  //let navigate = useNavigate();
  const { search } = useLocation();
  const navigate = useNavigate();

  const [selectedModuleIndex, setSelectedModuleIndex] = useState<number>(
    programProps.modules.length - 1 ?? -1
  );

  // if id changes in route but page remains the same, we have to reset
  // parameters. This happens when editing sub-programs and using the back-button
  useEffect(() => {
    setSelectedModuleIndex(-1);
  }, [program.programid, program.programtype]);

  //
  // Define backurl if query parameter is given.
  // Parameters are set in editProgram of CoachingProgramsList.
  //
  const backurl = useMemo(() => {
    const query = new URLSearchParams(search);

    const bi = query.get('instance');
    const bp = query.get('program');
    if (bi && bi !== '') {
      return `${AppRoutes.PROGRAMEDITOR}/${bi}/instance`;
    } else {
      if (bp && bp !== '') {
        return `${AppRoutes.PROGRAMEDITOR}/${bp}/program`;
      } else {
        return '';
      }
    }
  }, [search]);

  const handleProgramTypeUpdate = (updatedProgramType: ProgramTypeEnum) => {
    // do not set setHasProgramModules here, but wait for mutation
    // in onChange to complete, see there

    // update object
    if (updatedProgramType !== undefined) {
      program.programtype = updatedProgramType;
    }

    // save object
    onChange();
  };

  const onChange = () => {
    program.program.modules = program.program.modules.filter(
      (module) => module
    );
    //console.log(program);

    mutate(program, {
      onError(error, _variables, _context) {
        sendErrorMail(error, 'triggered in onChange in ProgramEditor.tsx');
        alert(
          intl.formatMessage({
            id: 'builder.programs.programeditor.erroronsave',
            defaultMessage:
              'Beim Speichern ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
          })
        );
      },
    });
  };

  //console.log('program.programtype:', program.programtype);

  // select new module
  const selectModule = (moduleIndex: number) => {
    if (moduleIndex > -1 && moduleIndex < programProps.modules.length)
      setSelectedModuleIndex(moduleIndex);
  };

  if (isError) {
    // this console.error is on purpose
    console.error(error);
    return (
      <Typography>
        {intl.formatMessage({
          id: 'builder.programs.programeditor.save.errormessage',
          defaultMessage:
            'Beim Speichern des Programms ist ein Fehler aufgetreten.',
        })}
        <br />
        {error as string}
      </Typography>
    );
  }

  const onProgramSelectionChanged = (programid: string, selected: boolean) => {
    // only used in selection mode
    // remove or add new programid
    if (selected) {
      program.programmodules.push(programid);
    } else {
      program.programmodules = program.programmodules.filter(
        (pid: string) => pid !== programid
      );
    }
    // write to backend
    onChange();
  };

  const addNewModule = (modinfo?: any) => {
    //
    const emptyDefaultModule = {
      id: 0,
      title: intl.formatMessage({
        id: 'builder.programs.programmodulelist.newtitle',
        defaultMessage: 'Neues Programm Modul',
      }),
      description: intl.formatMessage({
        id: 'builder.programs.programmodulelist.newdescription',
        defaultMessage: 'Beschreibung des neuen Programmmoduls.',
      }),
      image: CardImageEnum.a_day_off,
      imageSrc: mapImageToPublicAsset(CardImageEnum.a_day_off, playerBasePath),
      duration: intl.formatMessage({
        id: 'builder.programs.programmodulelist.newduration',
        defaultMessage: 'Neue Dauer',
      }),
      dateOffset: 3,
      actions: [
        {
          link: '',
          linkType: ProgramModuleActionLinkTypeEnum.VIDEOCALL,
          description: '',
        },
      ],
      header: 'header',
    };

    const mod2add = modinfo ?? emptyDefaultModule;

    const currentLastIndex = programProps.modules.reduce(
      (prev, curr) => (curr.id > prev ? curr.id : prev),
      0
    );
    if (Array.isArray(mod2add)) {
      mod2add.forEach((mod: any, index) => {
        programProps.modules.push({
          ...mod,
          id: currentLastIndex + index + 1,
        });
      });
    } else {
      programProps.modules.push({ ...mod2add, id: currentLastIndex + 1 });
    }

    onChange();
  };

  //sx={{ border: 'solid 1px #000000' }}

  return (
    <>
      {backurl && backurl !== '' && (
        <Box marginBottom="1em">
          <EvoachButton onClick={() => navigate(backurl)}>
            <ArrowBack sx={{ marginRight: '0.5em' }} />
            {intl.formatMessage({
              id: 'builder.programs.programeditor.subprograms.backmessage',
              defaultMessage: 'Zurück zum übergeordneten Programm',
            })}
          </EvoachButton>
        </Box>
      )}
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            borderBottom:
              'solid 1px ' + evoachDefaultTheme.palette.primary.main,
            paddingBottom: '20px',
          }}
        >
          <ProgramMetaEditor
            program={program}
            onChange={onChange}
            programId={programId}
            programRouteType={programRouteType}
            programType={program.programtype}
            onChangeProgramType={handleProgramTypeUpdate}
            addNewModule={addNewModule}
            refetchProgram={refetchProgram}
          />
        </Grid>
        {program.programtype !== ProgramTypeEnum.PROGRAM_WITH_SUBMODULES ? (
          <>
            <Grid item xs={4}>
              <Box sx={{ height: '100%', position: 'sticky', top: 10 }}>
                <ProgramModuleListTimeline
                  programModules={programProps.modules}
                  selectModule={selectModule}
                  selectedModuleIndex={selectedModuleIndex}
                  onChange={onChange}
                  addNewModule={addNewModule}
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box style={{ position: 'sticky', top: 10 }}>
                <ProgramModuleEditor
                  selectedModule={
                    selectedModuleIndex > -1
                      ? programProps.modules[selectedModuleIndex]
                      : undefined
                  }
                  onChange={onChange}
                  programRouteType={programRouteType}
                  moduleGroups={
                    programProps.modules
                      .filter((p) => p.group !== undefined && p.group !== '')
                      .map((p) => p.group)
                      .filter((g, i, a) => a.indexOf(g) === i) ?? undefined
                  }
                />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Box style={{ position: 'sticky', top: 10 }}>
              {!isLoading && (
                <CoachingProgramsList
                  instanceMode={
                    programRouteType === ProgramRouteTypeEnum.INSTANCE
                  }
                  subProgramMode={
                    program.programtype ===
                    ProgramTypeEnum.PROGRAM_WITH_SUBMODULES
                  }
                  onSelect={onProgramSelectionChanged}
                  selectedProgramIds={program.programmodules}
                  programModules={program.programmodules}
                  parentProgramId={
                    program.programtype ===
                      ProgramTypeEnum.PROGRAM_WITH_SUBMODULES &&
                    programRouteType === ProgramRouteTypeEnum.PROGRAM
                      ? (program.programid as string)
                      : (program.programinstanceid as string)
                  }
                />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
