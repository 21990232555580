import React, { useState } from 'react';
import { Grid } from '@mui/material';

import { Asset } from '../../../entities/Asset';

import { AssetSelector } from './AssetSelector';
import { AssetPreview } from './AssetPreview';
export const AssetTab: React.FC = () => {
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>();

  return (
    <div>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={8}>
              <AssetSelector
                onAssetSelect={(asset: Asset) => setSelectedAsset(asset)}
                onUpdateList={() => setSelectedAsset(undefined)}
                asset={selectedAsset}
                addPublicAssets
              />
            </Grid>
            <Grid item xs={4}>
              <AssetPreview
                asset={selectedAsset}
                onUpdate={() => setSelectedAsset(undefined)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
