import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.multiplePercentagScaleInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
  message: {
    id: 'builder.nodes.multiplePercentagScaleInput.state.message',
    defaultMessage: 'Info: Die Summe aller Werte darf 100 nicht überschreiten.',
  },
  alttext: {
    id: 'builder.nodes.multiplePercentagScaleInput.state.alttext',
    defaultMessage:
      'Fehler: Die Summe aller Werte darf 100 nicht überschreiten.',
  },
});

export const multiplePercentageScaleInputStateEntry: StateEntryProps = {
  type: 'renderMultiplePercentageScaleInput',
  temporary: true,
  payload: {
    buttonText: 'builder.nodes.multiplePercentagScaleInput.state.buttonText',
    scaleNames: [''],
    saveResultTo: 'multiscale_result',
    getLabelsFrom: '',
    getSeriesFrom: '',
    getStringValues: [''],
    message: 'builder.nodes.multiplePercentagScaleInput.state.message',
    altText: 'builder.nodes.multiplePercentagScaleInput.state.alttext',
  },
  saveResultType: [ReadWriteType.numberArray, ReadWriteType.number],
  displayName: 'Multiple Percentage Scale Input',
  nodeType: 'multiplePercentageScaleInputStateEntry',
  handleOutCount: 1,
};
