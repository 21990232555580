import { IntlShape } from 'react-intl';
import { translateWithFallback } from '@evoach/ui-components';

/** translate with fallback
 *
 * @param {string} messageId - translation keys to be translated
 * @param {IntlShape} intl - react-intl dependency
 * @param {Record<string, any>} varcontents @optional  - list of variable names with values to be formatted into the translated string
 * @return {string} translated texts
 */
export const translateCreatorWithFallback = (
  intl: IntlShape,
  messageId: string,
  defaultMessage = '',
  varcontents?: Record<string, any>
) => {
  // use ui-components
  const message = translateWithFallback(intl, messageId, varcontents);
  return message === messageId ? defaultMessage : message;
};
