import {
  generateRandomString,
  ImageDisplay,
  ImageItem,
} from '@evoach/ui-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { getResolvedUrl } from '../../../api/asset/useFetchAssetUrl';
import { translateKey } from '../../nodes';
import { TranslationContext } from '../../stateMachineTranslationContext';
import { TranslationContext as GlobalTranslationContext } from '../../../intl/TranslationContext';
import { AssetType } from '../assetHelper';
import { AssetMenu } from '../VariableInputs/AssetMenu';

const listEditorMessages = defineMessages({
  dialogTitle: {
    id: 'builder.moduleeditor.propertiessidebar.listeditordialog.dialogTitle',
    defaultMessage: 'Listeneditor',
  },
  dialogSaveButton: {
    id: 'builder.moduleeditor.propertiessidebar.listeditordialog.dialogSaveButton',
    defaultMessage: 'Speichern',
  },
  dialogCloseButton: {
    id: 'builder.moduleeditor.propertiessidebar.listeditordialog.dialogCloseButton',
    defaultMessage: 'Abbrechen',
  },
});
export interface ImageListEditorDialogProps {
  listelements: ImageItem[];
  listkeys?: string[];
  open: boolean;
  onClose: () => void;
  updateElements: Function;
  keyNumbers?: number[];
  updateKeyNumbers?: Function;
}

const cachedAssets: Record<string, string> = { '': 'filenotfound' };
cachedAssets[' '] = 'filenotfound';

export const deleteCachedAsset = (assetid: string) => {
  try {
    if (assetid) {
      delete cachedAssets[assetid];
    }
  } catch (_reason: any) {}
};

/**
 * Editor for editing image lists
 *
 * Plus:
 * - if keyNumbers is present,
 *
 * @param {ImageListEditorDialogProps}
 * @returns
 */
export const ImageListEditorDialog: React.FC<ImageListEditorDialogProps> = ({
  listelements,
  listkeys,
  open,
  onClose,
  updateElements,
}) => {
  const intl = useIntl();
  const { addOrUpdateStateMachineTranslation } = useContext(TranslationContext);
  const { defaultMessages } = useContext(GlobalTranslationContext);

  const [listElements, setListElements] = useState<ImageItem[]>(
    listelements ?? []
  );

  const [listKeys, setListKeys] = useState<string[]>(listkeys ?? []);

  /** remove an emotion from the list of self-defined emotions */
  const removeListEntry = (index: number) => {
    let newListKeys = cloneDeep(listKeys);
    const newListElements = cloneDeep(listElements);

    // fake a translation update to force re-render of nodes
    // as this index is removed in the next step, the updated translation has no effect
    addOrUpdateStateMachineTranslation(newListElements[index].assetid, 'none');

    newListElements.splice(index, 1);
    if (newListKeys) {
      // listkeys = keyTexts
      // remove listkeys ==> even if we use buttonTexts for listkeys,
      // this is not valid for duplicated modules! Thus, we have
      // to carefully remove listkeys, too.
      newListKeys.splice(index, 1);
    }

    setListElements(newListElements);
    setListKeys(newListKeys);
    updateElements(newListElements, newListKeys, true);
  };

  /** do not use index for generation although this is better readable
   * because it generates chaos when you delete elements within a list */
  const getTranslationKey = () =>
    `${generateRandomString(4)}.listeditor.${generateRandomString(2)}`;

  /** add a user defined emotion with default values */
  const addListEntry = () => {
    // to understand adding keys, see also initArrayTranslation in modulePaster.ts
    const translationKey = getTranslationKey();
    const translationKeyValue = translationKey + '.value';
    const translationKeySrc = translationKey + '.src';
    const translationKeyAssetId = translationKey + '.assetid';
    listElements.push({
      type: 'imageselect',
      value: translationKeyValue,
      assetid: translationKeyAssetId,
      src: translationKeySrc,
      resolvedsrc: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    });
    if (listKeys) {
      listKeys.push('keytext.' + generateRandomString(4));
    }

    // empty value
    addOrUpdateStateMachineTranslation(
      translationKeyValue,
      defaultMessages['builder.nodes.imageselector.state.dummydata.burger']
    );
    // dummy value for external src
    addOrUpdateStateMachineTranslation(
      translationKeySrc,
      'https://images.unsplash.com/photo-1551782450-a2132b4ba21d'
    );
    // no assetid selected
    addOrUpdateStateMachineTranslation(translationKeyAssetId, ' ');

    setListElements(listElements);
    setListKeys(listKeys);
    updateElements(listElements, listKeys, true);
  };

  // if we leave a single edit field, we autosave, even before the save
  // button was clicked. This is necessary to not loose content when
  // switching window foxus between edits of different entries.
  // const onBlurAutoSave = () => {
  //   autoSave();
  // };

  const updateCurrentAsset = async (
    key: string,
    newValue: string,
    index: number
  ) => {
    const listElementsNew = cloneDeep(listElements);

    // get resolved URL and wait for result before updating elements
    getResolvedUrl(
      key === 'src'
        ? ' '
        : key === 'assetid'
        ? newValue
        : intl.formatMessage({ id: listElementsNew[index].assetid }),
      key === 'src'
        ? newValue
        : intl.formatMessage({ id: listElementsNew[index].src })
    ).then((resolvedsrc: string) => {
      // successful promise => set parameters and update state
      listElementsNew[index].resolvedsrc = resolvedsrc;

      // set new value for affected translation key
      addOrUpdateStateMachineTranslation(
        key === 'assetid'
          ? listElementsNew[index].assetid
          : listElementsNew[index].src,
        newValue
      );

      setListElements(listElementsNew);
      setListKeys(listKeys);
      updateElements(listElementsNew, listKeys, false);
    });
  };

  const updateCurrentTitle = async (newValue: string, index: number) => {
    const listElementsNew = cloneDeep(listElements);

    //listElementsNew[index].value = newValue;
    addOrUpdateStateMachineTranslation(listElementsNew[index].value, newValue);

    updateElements(listElementsNew, listKeys, false);
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage {...listEditorMessages.dialogTitle} />
      </DialogTitle>
      <DialogContent sx={{ maxHeight: '600px', minHeight: '600px' }}>
        <div style={{ paddingBottom: '15px' }}>
          <Typography component="span">
            {intl.formatMessage({
              id: 'builder.moduleeditor.propertiessidebar.listeditordialog.description',
              defaultMessage:
                'Hier kannst du Listeneinträge hinzufügen, ändern und entfernen.',
            })}
          </Typography>
        </div>
        <Stack spacing={2}>
          {listElements.map((element, index: number) => {
            return (
              <Stack direction="row" key={`dirstack${index}`}>
                <Stack direction="row" key={'StackKey_' + index}>
                  <div style={{ paddingRight: '8px' }}>
                    <ImageDisplay
                      resolvedsrc={element.resolvedsrc ?? ''}
                      src={intl.formatMessage({
                        id: element.src,
                        defaultMessage: '',
                      })}
                      assetid={intl.formatMessage({
                        id: element.assetid,
                        defaultMessage: '',
                      })}
                      width={100}
                      alt={intl.formatMessage({
                        id: element.value,
                        defaultMessage: '',
                      })}
                    />
                  </div>
                  <div style={{ alignSelf: 'center', width: '142px' }}>
                    <AssetMenu
                      externalUrl={intl.formatMessage({
                        id: element.src,
                        defaultMessage: '',
                      })}
                      updateAssetValue={(newvalue: string, key: string) =>
                        updateCurrentAsset(key, newvalue, index)
                      }
                      assetType={AssetType.IMAGE}
                    />
                  </div>
                </Stack>
                <div style={{ alignSelf: 'center' }}>
                  <TextField
                    id={'donotcatchpasteevent_' + index}
                    value={translateKey(intl, element.value)}
                    onChange={(evt) =>
                      updateCurrentTitle(evt.target.value, index)
                    }
                    key={'imageSelectorTitle' + index}
                    disabled={'imageSelectorTitle' + index === 'loopName'}
                    autoComplete="off"
                    autoCorrect="off"
                    style={{
                      width: '95%',
                    }}
                  />
                </div>
                <IconButton onClick={() => removeListEntry(index)}>
                  <RemoveCircleIcon />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
        <IconButton onClick={addListEntry} disabled={listElements.length > 20}>
          <AddCircleIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            updateElements(listElements, listKeys, false);
            onClose();
          }}
        >
          <FormattedMessage {...listEditorMessages.dialogSaveButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
