import { evoachDefaultTheme } from '@evoach/ui-components';

import { ReadWriteType, StateEntryProps } from '.';
/*
  loopStartStateEntry xState specific defintions
*/
export const loopStartStateEntry: StateEntryProps = {
  version: 'v1',
  type: 'loopStart',
  temporary: false,
  payload: {
    getValueFrom: '',
    saveResultTo: 'nextllopitem',
  },
  getValueType: [
    ReadWriteType.stringArray,
    ReadWriteType.numberArray,
    ReadWriteType.typedObject,
  ],
  saveResultType: [
    ReadWriteType.string,
    ReadWriteType.number,
    ReadWriteType.typedObject,
  ],
  displayName: 'Loop Start',
  nodeType: 'loopStartStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 1,
};
