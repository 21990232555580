import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.radarChartDisplay.state.title',
    defaultMessage: 'Radar Chart Titel',
  },
});

export const radarChartStateEntry: StateEntryProps = {
  version: 'v2',
  type: 'renderRadarchartDisplay',
  temporary: false,
  payload: {
    series: [],
    labels: [],
    tickAmount: 10,
    getLabelsFrom: '',
    radarLabels: ['', ''],
    getValuesFrom: [''],
    title: 'builder.nodes.radarChartDisplay.state.title',
  },
  getValueType: [ReadWriteType.number, ReadWriteType.numberArray],
  displayName: 'Radar Chart',
  nodeType: 'radarChartStateEntry',
  handleOutCount: 1,
};

/* history
v2 
- add getLabelsFrom and getSeriesFrom to payload
- allow autogeneration of labels and values based on variables, e.g. from loops
v1 
- add labels and variables each manually
*/
