import { defineMessages } from 'react-intl';
import { evoachDefaultTheme } from '@evoach/ui-components';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.coachMessage.state.message',
    defaultMessage: 'Hier ist deine Coach Nachricht.',
  },
  dpara: {
    id: 'combineToStringStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kannst du einen Text aus einer Nachricht und Variablen zusammenbauen und in einer neuen Variable speichern.',
  },
});

//
// Build a string out of a message and a list of variables and store
// the result in a new variable.
// Feature was requested by Sascha Geßler and Heiko (not paid), Mae, 25.07.2023
//

export const combineToStringStateEntry: StateEntryProps = {
  type: 'setCombineToString',
  temporary: false,
  payload: {
    getStringValues: [''],
    saveResultTo: '',
    message: '',
  },
  getValueType: [
    ReadWriteType.string,
    ReadWriteType.stringArray,
    ReadWriteType.number,
    ReadWriteType.numberArray,
    ReadWriteType.typedObject,
  ],
  saveResultType: ReadWriteType.string,
  displayName: 'Combine to string',
  nodeType: 'combineToStringStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.success.light,
  handleOutCount: 1,
};
