import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  aiGenericClassificationStateEntryd: {
    id: 'aiGenericClassificationStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kann mit Hilfe Künstlicher Intelligenz eine Eingabe klassifiziert werden. Anschließend wird abhängig vom Ergebnis verzweigt.',
  },
  aiGenericClassificationStateEntrym: {
    id: 'aiGenericClassificationStateEntry.message',
    defaultMessage:
      'Beschreibe hier, was die KI klassifizieren soll, und definiere die Randbedinungen.',
  },
  aiGenericClassificationStateEntryo: {
    id: 'aiGenericClassificationStateEntry.classificationKeys',
    defaultMessage:
      'Definiere die Liste der Optionen, nach denen klassifiziert werden soll.',
  },
  externalService: {
    id: 'aiGenericClassificationStateEntry.externalService',
    defaultMessage: 'Wähle dein Sprachmodell aus.',
  },
  defaultMessage: {
    id: 'builder.nodes.aiGenericClassificationStateEntry.defaultMessage',
    defaultMessage:
      'Klassifiziere die Eingabe des Benutzers auf Basis des Kontextes bzw. der Chat-History. Wähle zur Klassifikation eine der bereitgestellten Optionen.',
  },
});

/**
 * AiGenericClassificationNode allows a generic classification of a chat or
 * a variable. It is defined by a prompt describing the task and a list of
 * options to choose from.
 */
export const aiGenericClassificationStateEntry: StateEntryProps = {
  version: 1,
  type: 'sendExternal',
  temporary: false,
  payload: {
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.GENERIC_CLASSIFICATION,
    // prompt is the text that describes the classification task to the AI
    message: 'builder.nodes.aiGenericClassificationStateEntry.defaultMessage',
    // keyTexts only placeholder ==> will be automatically replaced by random values!
    // These random values are used to identify the out handles of a node
    keyTexts: [],
    // texts that describe the classification option, manually entered by user
    classificationKeys: [],
    getValuesFrom: [],
    getStringValues: [''],
  },
  getValueType: [ReadWriteType.string, ReadWriteType.stringArray],
  displayName: 'Classification (AI)',
  nodeType: 'aiGenericClassificationStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 'dynamic',
};
