import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  LanguageSelectionHeightOptions,
  MetaDataEditorLanguageSelection,
} from '../../ModuleEditor/MetaDataEditorLanguageSelection';

export interface NewModuleDialogProps {
  isOpen: boolean;
  onClose: Function;
}

export const NewModuleDialog: React.FC<NewModuleDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const intl = useIntl();

  const [toLang, setToLang] = useState<string>(intl.locale);

  const onCloseDialog = async (doit: boolean) => {
    // call parent component
    onClose(doit, toLang);
  };

  const onLanguageChange = (newLang: string) => {
    setToLang(newLang);
  };

  return (
    <>
      <Dialog fullWidth open={isOpen}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'builder.modulelist.newmoduledialog.title',
            defaultMessage: 'Neuen Chatbot erstellen',
          })}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2" component="span">
              {intl.formatMessage({
                id: 'builder.modulelist.newmoduledialog.description',
                defaultMessage:
                  'In welcher Sprache soll der neue Chatbot initial erstellt werden? Hinweis: Du kannst später weitere Sprachen hinzufügen.',
              })}
            </Typography>
            <MetaDataEditorLanguageSelection
              onLanguageChange={onLanguageChange}
              moduleLocale={intl.locale}
              heightOption={LanguageSelectionHeightOptions.SMALL}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onCloseDialog(false)}>
            {intl.formatMessage({
              id: 'builder.modulelist.newmoduledialog.cancel',
              defaultMessage: 'Abbrechen',
            })}
          </Button>
          <Button color="secondary" onClick={() => onCloseDialog(true)}>
            {intl.formatMessage({
              id: 'builder.modulelist.newmoduledialog.ok',
              defaultMessage: 'Ok',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
