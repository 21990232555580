import { Box, Button, Link, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import Carousel from '../components/notloggedin/Carousel';
import { useEnvironment } from '../environment/useEnvironment';
import { keycloak } from '../keycloak';
import { AppRoutes, InvitationRouteParams } from '../routing/routes';

interface NotLoggedInPageProps {
  isSignupPage?: boolean;
}

export const NotLoggedInPage: React.FC<NotLoggedInPageProps> = ({
  isSignupPage = false,
}) => {
  const { invitationId } = useParams<InvitationRouteParams>();
  const { pathname, search } = useLocation();
  const { builderBasePath } = useEnvironment();
  const intl = useIntl();

  //
  // show signup or login messages?
  //
  // 1) isSignupPage is set to true ==> signup
  // 2) login page
  //  2.1) atLeastOneLogin ==> login
  //  2.2) atLeastOneLogin not true ==> signup
  //
  // evoach.atleastonceloggedininthisbrowser is set in localstorage via
  // AccountContext if the user at least once was successfully authenticated
  //
  const showSignUp = useMemo(() => {
    const atLeastOneLogin = localStorage.getItem(
      'evoach.atleastonceloggedininthisbrowser'
    );
    return isSignupPage || !(atLeastOneLogin === 'true');
  }, [isSignupPage]);

  //
  // if a coachee clicks on an invitation link but is not authenticated, the
  // NotLoggedInPage is rendered directly via AppRoutes and is not called by
  // redirection!
  // That enables us to check whether the route path is an invitation path.
  // If yes, we put the invitation id in localStorage. After sign-in/-up of a
  // user, the invitation id is found in the corresponding callback page
  // (InvitationPage or ReceptionPage) and is accepted there.
  // Fix for PROD-1348
  //
  if (pathname.startsWith(`${AppRoutes.INVITATION}`) && invitationId) {
    localStorage.setItem('evoachModuleInvitation', invitationId);
  }

  if (pathname.startsWith(`${AppRoutes.PROGRAMINVITATION}`) && invitationId) {
    localStorage.setItem('evoachProgramInvitation', invitationId);
  }

  const customRedirectUrl = useCallback(() => {
    let redirectUrl = '';
    const urlQueryParams: Record<string, string> = {};
    const query = new URLSearchParams(search);
    query.forEach((val: string, key: string) => {
      urlQueryParams[key] = val;
    });
    if (urlQueryParams['custom_redirect']) {
      redirectUrl = `/${urlQueryParams['custom_redirect']}`;
    }

    return redirectUrl;
  }, [search]);

  const jumpToKeycloakSignUp = useCallback(() => {
    window.location.href = keycloak.createRegisterUrl({
      redirectUri: `${builderBasePath}${customRedirectUrl()}`,
    });
  }, [builderBasePath, customRedirectUrl]);

  const jumpToKeycloakLogin = useCallback(() => {
    window.location.href = keycloak.createLoginUrl({
      redirectUri: `${builderBasePath}${customRedirectUrl()}`,
    });
  }, [builderBasePath, customRedirectUrl]);

  //
  // if query parameter signup_redirect=true is set, we automatically redirect
  // to the signup page in Keycloak.
  //
  useEffect(() => {
    // build an JSON object of all query parameters
    const urlQueryParames: Record<string, string> = {};
    // if there is no campaign parameter, set it to "invitation"
    // if there is another c parameter provided, this manually set parameter
    // will be overwritten
    if (pathname.startsWith(`${AppRoutes.PROGRAMINVITATION}`) && invitationId) {
      urlQueryParames['c'] = 'programinvitation';
      urlQueryParames['appSource'] = 'creator';
    }
    if (pathname.startsWith(`${AppRoutes.INVITATION}`) && invitationId) {
      urlQueryParames['c'] = 'moduleinvitation';
      urlQueryParames['appSource'] = 'creator';
    }

    if (search) {
      const query = new URLSearchParams(search);

      // build an JSON object of all query parameters
      query.forEach((val: string, key: string) => {
        urlQueryParames[key] = val;
      });
    }

    //
    // stores any sign-up parameters like utm_campaign=trululu or source=tralala
    // these are stored in the user account when the account is created during
    // the first login
    //
    localStorage.setItem(
      'evoachSignUpParameters',
      JSON.stringify(urlQueryParames)
    );

    if (
      urlQueryParames['signup_redirect'] &&
      urlQueryParames['signup_redirect'] === 'true'
    ) {
      jumpToKeycloakSignUp();
    }
  }, [builderBasePath, invitationId, jumpToKeycloakSignUp, pathname, search]);

  return (
    <Box
      sx={{
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          maxWidth: '400px',
          marginRight: '20px',
          border: '1px solid rgba(0, 0, 0, 0.125)',
          borderRadius: '0.5rem',
          padding: '20px',
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Carousel />
      </Box>
      <Box
        sx={{
          maxWidth: '400px',
          backgroundColor: '#fff',
          border: '1px solid rgba(0, 0, 0, 0.125)',
          borderRadius: '0.5rem',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '20px',
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        }}
      >
        {!showSignUp && (
          <Typography
            component="span"
            fontSize={18}
            fontWeight={300}
            color="#686869"
          >
            <div style={{ paddingBottom: 20, paddingTop: 10 }}>
              {intl.formatMessage({
                id: 'builder.pages.notloggedin.hint',
                defaultMessage: 'Hallo, du bist nicht angemeldet.',
              })}
            </div>
            <div style={{ paddingBottom: 20 }}>
              {intl.formatMessage({
                id: 'builder.pages.notloggedin.inactive',
                defaultMessage:
                  'Du wirst automatisch abgemeldet, wenn du längere Zeit nicht aktiv bist.',
              })}
              <br />

              <Button sx={{ marginTop: '20px' }} onClick={jumpToKeycloakLogin}>
                {intl.formatMessage({
                  id: 'builder.pages.notloggedin.button.signin',
                  defaultMessage: 'Anmelden',
                })}
              </Button>
            </div>
            <Typography
              style={{ paddingBottom: 20, marginTop: '80px' }}
              variant="body2"
            >
              {intl.formatMessage(
                {
                  id: 'builder.pages.notloggedin.noaccountyet',
                  defaultMessage:
                    'Wenn du noch kein Konto bei uns hast, kannst du dich {reglink}.',
                },
                {
                  reglink: (
                    <Link
                      color="secondary"
                      variant="body1"
                      onClick={jumpToKeycloakSignUp}
                      sx={{ cursor: 'pointer' }}
                    >
                      {intl.formatMessage({
                        id: 'builder.pages.notloggedin.signuphere',
                        defaultMessage: 'hier registrieren',
                      })}
                    </Link>
                  ),
                }
              )}
            </Typography>
            <div
              style={{
                paddingBottom: 10,
                alignContent: 'right',
                alignSelf: 'right',
              }}
            ></div>
          </Typography>
        )}
        {showSignUp && (
          <Typography
            component="span"
            fontSize={18}
            fontWeight={300}
            color="#686869"
          >
            <div style={{ paddingBottom: 20, paddingTop: 10 }}>
              {intl.formatMessage({
                id: 'builder.pages.notloggedin.neversignedin',
                defaultMessage:
                  'Wenn du noch kein Konto bei uns hast, kannst du dich hier anmelden.',
              })}
              <br />

              <Button sx={{ marginTop: '20px' }} onClick={jumpToKeycloakSignUp}>
                {intl.formatMessage({
                  id: 'builder.pages.notloggedin.button.signup',
                  defaultMessage: 'Registrieren',
                })}
              </Button>
            </div>
            <Typography
              style={{ paddingBottom: 20, marginTop: '80px' }}
              variant="body2"
            >
              {intl.formatMessage(
                {
                  id: 'builder.pages.notloggedin.accountbutnotloggiedin',
                  defaultMessage:
                    'Wenn du schon bei uns registriert bist, kannst du dich {reglink}.',
                },
                {
                  reglink: (
                    <Link
                      color="secondary"
                      variant="body1"
                      onClick={jumpToKeycloakLogin}
                      sx={{ cursor: 'pointer' }}
                    >
                      {intl.formatMessage({
                        id: 'builder.pages.notloggedin.signinhere',
                        defaultMessage: 'hier anmelden',
                      })}
                    </Link>
                  ),
                }
              )}
            </Typography>
            <div
              style={{
                paddingBottom: 10,
                alignContent: 'right',
                alignSelf: 'right',
              }}
            ></div>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
