import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'listPromptStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kann mit Hilfe Künstlicher Intelligenz ein Prompt mit einer Eingabe gesendet werden. Das Ergebnis ist eine Liste und wird in eine Variable geschrieben.',
  },
});

/**
 * list of prompts that can be used within this node
 * ExternalServiceEnum.OPENAI_CHAT_35 only!
 * TODO we may extend this list by adding the service and distinguish the allowed prmpts by service
 */
export const listPromptAllowedPromptTypes: PromptEnum[] = [
  PromptEnum.LISTPROMPT_SUBGOAL_SUGGESTIONS,
];
/**
 * The listPromptStateEntry component is used to retrieve an answer for a sepcific
 * prompt. It returns a list of objects that can be used to feed Multiple Ouput or
 * similar components.
 */
export const ailistPromptStateEntry: StateEntryProps = {
  version: 1,
  type: 'sendExternal',
  temporary: false,
  payload: {
    getValueFrom: '',
    saveResultTo: '',
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.LISTPROMPT_SUBGOAL_SUGGESTIONS,
    prompt: '',
  },
  getValueType: ReadWriteType.string,
  saveResultType: ReadWriteType.typedObject,
  displayName: 'List Prompt (AI)',
  nodeType: 'ailistPromptStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 1,
};
