import React from 'react';
import {
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { NavigateTargetEnum, StateEntryProps } from '../../nodes';

/*
  ! Navigation Target Input for the NavigateButton prop navigationTarget

*/

interface NavigationTargetInputProps {
  entry: StateEntryProps;
  elementId: string;
  updateNavigateTarget: Function;
}

export const NavigationTargetInput: React.FC<NavigationTargetInputProps> = ({
  entry,
  elementId,
  updateNavigateTarget,
}) => {
  const [navigateTarget, setNavigateTarget] =
    React.useState<NavigateTargetEnum>(
      entry.payload.navigateTarget ?? NavigateTargetEnum.MODULELIST
    );

  const intl = useIntl();

  const changeNavigateTarget = (
    event: SelectChangeEvent,
    _c: React.ReactNode
  ) => {
    const newTarget = event.target.value as unknown as NavigateTargetEnum;
    setNavigateTarget(newTarget);
    updateNavigateTarget(newTarget, entry);
  };

  return entry.payload.navigateTarget !== undefined ? (
    <Stack spacing={1}>
      <FormControl
        key={`ElementIdAndKey-${elementId}-size`}
        sx={{ width: '95%' }}
      >
        <InputLabel id="demo-simple-select-label">
          {intl.formatMessage({
            id: 'builder.propertiessidebar.entrylist.navigatetarget.target',
            defaultMessage: 'Navigationsziel',
          })}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={navigateTarget + ''}
          label={intl.formatMessage({
            id: 'builder.propertiessidebar.entrylist.navigatetarget.target',
            defaultMessage: 'Navigationsziel',
          })}
          onChange={changeNavigateTarget}
        >
          <MenuItem value={NavigateTargetEnum.MODULELIST}>
            {intl.formatMessage({
              id: 'builder.propertiessidebar.entrylist.navigatetarget.module',
              defaultMessage: 'Modulliste',
            })}
          </MenuItem>
          <MenuItem value={NavigateTargetEnum.SESSIONLIST}>
            {intl.formatMessage({
              id: 'builder.propertiessidebar.entrylist.navigatetarget.session',
              defaultMessage: 'Sessionliste',
            })}
          </MenuItem>
          <MenuItem value={NavigateTargetEnum.PROGRAMLIST}>
            {intl.formatMessage({
              id: 'builder.propertiessidebar.entrylist.navigatetarget.program',
              defaultMessage: 'Programmliste',
            })}
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>
  ) : null;
};
