import { evoachDefaultTheme } from '@evoach/ui-components';
import { Box, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import { useIntl } from 'react-intl';

import { useFetchAnalytics } from '../../../api/analytics';

export const AnalysisModuleSessionProgress: React.FC = () => {
  const intl = useIntl();

  //const analysisId = 'a5523ded-dfd1-423c-ad20-947b53d8d55d';
  const analysisId: string = '2c308fa8-9fad-4a19-a2e3-38272e1b5f19';
  const { analysis } = useFetchAnalytics(analysisId);

  // console.log(analysis);

  /*

  ! First implementatiom / legacy

  Quick and dirty, do grouping on client side ==> bad implementation

analysisId === 'a5523ded-dfd1-423c-ad20-947b53d8d55d'

session: 
{
    "CoacheeAid": 1,
    "CoacheeMail": "christian@evoach.com",
    "CoachMail": "christian@evoach.com",
    "coachAccountAid": 1,
    "lang": "DE",
    "tscreated": "2022-05-30 16:00:19.303",
    "publicsession": true,
    "sessionprogress": "0",
    "numwidgetdata": 1,
    "isdefaultmodule": false,
    "owns": true,
    "sessionid": "20fecaf4-7f1b-40ee-b368-4cb767f99154"
}
  */

  return (
    <>
      {analysis &&
        analysis.map((singleAnalysis: any) => {
          // module title

          const moduleid = singleAnalysis.originalmoduleid;

          const title = singleAnalysis.title ?? 'Error: Title not found';

          // calc series +labels for chart
          const labels = singleAnalysis.sessionProgress.map(
            (sess: any) => sess.sessionprogress + '%'
          );
          let sumSessions = 0;
          const series = singleAnalysis.sessionProgress.map((sess: any) => {
            sumSessions += sess.session_count - 0;
            return sess.session_count - 0;
          });

          // get all languages
          const languages = singleAnalysis.languages;

          // get

          const widgetdataLabels = singleAnalysis.widgetProgress.map(
            (sess: any) => {
              return (sess.numwidgetdata ?? 0) + ' components';
            }
          );
          const widgetdataSeries = singleAnalysis.widgetProgress.map(
            (sess: any) => {
              return sess.session_count - 0;
            }
          );
          // sum of all sessions
          const sumsess = sumSessions; //singleAnalysis[moduleid].sessions.length;

          return (
            <Box key={moduleid} sx={{ marginBottom: '30px' }}>
              <Typography variant="h5" component="span">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: '20px' }}>
                Module Id: {moduleid}
              </Typography>
              {series.length === 1 ? (
                <Typography variant="body2" component="span">
                  {intl.formatMessage(
                    {
                      id: 'builder.analytics.allsame',
                      defaultMessage:
                        'Es gibt {nums} Sessions mit diesem Modul und alle wurden zu {label} bearbeitet.',
                    },
                    { nums: series[0] + '', label: labels[0] }
                  )}
                  {labels[0] === '0%' && (
                    <>
                      {intl.formatMessage({
                        id: 'builder.analytics.zeropercent',
                        defaultMessage:
                          'Da alle mit 0% bearbeitet wurden, könnte es sein, dass du vergessen hast, in diesem Modul die Angaben für den Fortschritt des Moduls zu konfigurieren.',
                      })}
                    </>
                  )}
                </Typography>
              ) : (
                <Typography variant="body2" component="span">
                  {intl.formatMessage(
                    {
                      id: 'builder.analytics.numsession',
                      defaultMessage:
                        'Mit diesem Modul wurden {sumsess} Sessions bearbeitet.',
                    },
                    { sumsess: sumsess }
                  )}
                  <Chart
                    options={{
                      labels: labels.map((l: string) => l + ' progress'),
                      colors: [evoachDefaultTheme.palette.primary.main],
                      legend: {
                        show: true,
                        showForSingleSeries: true,
                      },
                    }}
                    series={[
                      {
                        data: series,
                        name: 'Number of sessions for the given progress',
                      },
                    ]}
                    height={300}
                    type="bar"
                    id={'apexchart' + moduleid}
                    key={'apexchart' + moduleid}
                  />
                  <Chart
                    options={{
                      labels: widgetdataLabels,
                      colors: [evoachDefaultTheme.palette.primary.main],

                      legend: {
                        show: true,
                        showForSingleSeries: true,
                      },
                    }}
                    series={[
                      {
                        data: widgetdataSeries,
                        name: 'Number of session with this number of components',
                      },
                    ]}
                    height={300}
                    type="bar"
                    id={'apexchartwidget' + moduleid}
                    key={'apexchartwidget' + moduleid}
                  />
                </Typography>
              )}
              {languages.length === 1 ? (
                <Typography variant="body2">
                  {intl.formatMessage(
                    {
                      id: 'builder.analytics.onlyonelanguage',
                      defaultMessage:
                        'Das Modul wurde mit nur einer Sprache bearbeitet: {lang}',
                    },
                    { lang: languages[0].language }
                  )}
                </Typography>
              ) : (
                <Chart
                  options={{
                    labels: languages.map((l: any) => l.language),
                    dataLabels: {
                      enabled: true,
                      formatter: function (_val, _opt) {
                        const l = languages[_opt.seriesIndex];
                        return l.language + ' = ' + l.lang_count;
                      },
                    },
                    legend: {
                      show: true,
                      showForSingleSeries: true,
                    },
                  }}
                  series={languages.map((l: any) => l.lang_count - 0)}
                  height={200}
                  type="pie"
                  id={'apexchartlangpie' + moduleid}
                  key={'apexchartlangpie' + moduleid}
                />
              )}
            </Box>
          );
        })}
    </>
  );
};
