import { Box, Typography } from '@mui/material';
import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';

import { useGlobalStyles, calculateHandlePosition } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  MultipleCompareNumbers react-flow node for module builder graph canvas
*/

export const MultipleCompareNumbersNode: React.FC<NodeProps<any>> = (
  nodeProps: NodeProps<any>
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const stringArrayMesssage = intl.formatMessage({
    id: 'builder.nodes.elements.multipleCompareNumbers.hint',
    defaultMessage:
      'Dieser Knoten vergleicht eine Zahl mit verschiedenen Intervallen und verzweigt entsprechend. Die Intervalle sind:',
  });

  const payload = nodeProps.data.state.entry[0].payload;
  const series = payload.series ?? [];

  const varName =
    payload.getValueFrom === undefined || payload.getValueFrom === ''
      ? intl.formatMessage({
          id: 'builder.nodes.elements.multipleCompareNumbers.novar',
          defaultMessage: 'Bitte Variable wählen.',
        })
      : payload.getValueFrom;

  //
  // it is very important to use the translation key for the id
  // as it is used for the .on target in the state machine transition
  // the id defines the "sourceHandle" in moduleUtils
  //
  // Note: for compare numbers, we have to add one more handle than we
  // have values as we have to add the final comparison! This is different
  // to other nodes with dynamic handles!
  //
  const sourceHandles = payload.keyTexts
    ?.map((keyText: string, index: number) => (
      <Handle
        id={keyText}
        key={keyText + index}
        className={classes['react-flow__handle']}
        type="source"
        position={Position.Bottom}
        style={{
          left: calculateHandlePosition(
            payload.keyTexts?.length + 1 ?? 1,
            index
          ),
        }}
      />
    ))
    .concat(
      <Handle
        id="mulitcompare.final"
        key={'mulitcompare.final' + (payload.keyTexts?.length + 1)}
        className={classes['react-flow__handle']}
        type="source"
        position={Position.Bottom}
        style={{
          left: calculateHandlePosition(
            payload.keyTexts?.length + 1 ?? 1,
            payload.keyTexts?.length
          ),
        }}
      />
    );

  return (
    <>
      <Handle
        type="target"
        id="comparenumbersin"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ maxWidth: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {stringArrayMesssage}
          <br />
          &nbsp;
          <br />
          {series.map((intervalValue: number, index: number) => {
            let printVal = '';
            // \u221E = unicode for infinity sign
            if (index === 0) {
              printVal = '- \u221E < ' + varName + ' <= ' + intervalValue;
            } else {
              printVal =
                payload.series[index - 1] +
                ' < ' +
                varName +
                ' <= ' +
                intervalValue;
            }
            return <Box key={'intervalbox' + index}>{printVal}</Box>;
          })}
          {series && series.length > 0 && (
            <Box key={'intervalbox' + (series.length + 1)}>
              {series[series.length - 1] + ' < ' + varName + ' <= \u221E'}
            </Box>
          )}
        </Typography>
      </div>
      {sourceHandles}
    </>
  );
};
