import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'aiMicrochatStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kann mit Hilfe Künstlicher Intelligenz ein Micro-Chat zu einem bestimmten Thema durchgeführt werden. Die Kontrolle über den Chatverlauf hat die KI.',
  },
  externalService: {
    id: 'aiMicrochatStateEntry.externalService',
    defaultMessage: 'Wähle dein Sprachmodell aus.',
  },
  promptType: {
    id: 'aiMicrochatStateEntry.promptType',
    defaultMessage:
      'In der folgenden Auswahl bestimmst du, welcher Micro-Chat ausgeführt wird.',
  },
  maxTurns: {
    id: 'aiMicrochatStateEntry.maxTurns',
    defaultMessage:
      'maxTurns bestimmt die maximale Anzahl von Frage-Antwort-Wechseln in der Konversation.',
  },
  emoticons: {
    id: 'aiMicrochatStateEntry.emoticons',
    defaultMessage:
      'Mit der Eigenschaft "emoticons" kannst du in den Eingabefeldern des Coachees die Option zur Auswahl von Emojis ein- und abschalten.',
  },
});

/**
 * list of prompts that can be used within this node
 */
export const aiMicrochatAllowedPromptTypes: PromptEnum[] = [
  PromptEnum.MICROCHAT_5_WHY,
  PromptEnum.MICROCHAT_SUPERVISOR,
  PromptEnum.MICROCHAT_EXECUTIVE_COACHING_EXPLORE,
  PromptEnum.MICROCHAT_AI_COACH, // same as solution focused - changed name
  PromptEnum.MICROCHAT_SOLUTION_FOCUSED,
  PromptEnum.MICROCHAT_FOLLOW_UP_QUESTIONS,
  PromptEnum.MICROCHAT_REFLECTION_SUPERVISOR, // customer specific / PROD-2071 -  ReflectionSupervisor (Cristina)
  PromptEnum.MICROCHAT_HENLEY_8, // customer specific / PROD-2071 - Henley Eight (Supervisor für Jonathan Passmore)
  PromptEnum.MICROCHAT_MAGICQUESTIONSET, // customer specific / PROD-2071 - MagicQuestionSet (Research Project David Tee)
  PromptEnum.MICROCHAT_PEAKLY_AI_COACH, // customer specific /  Peakly AI Coac
  PromptEnum.MICROCHAT_LEARNING_REFLECTIONS, // customer specific
  PromptEnum.MICROCHAT_GROW_COACH, // Rebecca via Slack,
  PromptEnum.MICROCHAT_CLARIFY_COACHING_TOPIC, // Universal bot: clarify coaching topic
  PromptEnum.MICROCHAT_ONBOARDING, // Universal bot: onboarding
];

//
// list of prompts that are only visible for evoach admin
// the list is used in PromptSelect.tsx
//
export const evoachAdminOnlyPrompts = [
  PromptEnum.MICROCHAT_REFLECTION_SUPERVISOR,
  PromptEnum.MICROCHAT_MAGICQUESTIONSET,
  PromptEnum.MICROCHAT_HENLEY_8,
  PromptEnum.MICROCHAT_EXECUTIVE_COACHING_EXPLORE,
  PromptEnum.MICROCHAT_PEAKLY_AI_COACH,
  PromptEnum.MICROCHAT_CLARIFY_COACHING_TOPIC, // Universal bot: clarify coaching topic
  PromptEnum.MICROCHAT_ONBOARDING, // Universal bot: onboarding
];

// help to define the maxTurns depending on the microchat type. Default should be 10.
export const aiMicrochatMaxTurns = {
  [PromptEnum.MICROCHAT_5_WHY as string]: 10,
  [PromptEnum.MICROCHAT_SUPERVISOR as string]: 10,
  [PromptEnum.MICROCHAT_MAGICQUESTIONSET as string]: 25, // at least 17 questions!
  [PromptEnum.MICROCHAT_HENLEY_8]: 15,
  [PromptEnum.MICROCHAT_SOLUTION_FOCUSED]: 20, // at least 20 questions + summary
  [PromptEnum.MICROCHAT_AI_COACH]: 20, // same as solution focused - changed name
  [PromptEnum.MICROCHAT_FOLLOW_UP_QUESTIONS]: 15, // 8 questions with summary and buffer
  [PromptEnum.MICROCHAT_REFLECTION_SUPERVISOR]: 25, // at least 12 qustions
  [PromptEnum.MICROCHAT_EXECUTIVE_COACHING_EXPLORE]: 15, // at least 15 qustions,
  [PromptEnum.MICROCHAT_PEAKLY_AI_COACH]: 20, // at least 20 questions,
  [PromptEnum.MICROCHAT_LEARNING_REFLECTIONS]: 25, // at least 25 questions
  [PromptEnum.MICROCHAT_GROW_COACH]: 25, // at least 25 questions
  [PromptEnum.MICROCHAT_CLARIFY_COACHING_TOPIC]: 7, // Universal bot: clarify coaching topic
  [PromptEnum.MICROCHAT_ONBOARDING]: 5, // Universal bot: onboarding
};

/**
 * the AI Micro-Chat component allows to hand over control of the chat to
 * an AI. The control is automatically handed back to the state machine
 * if the AI thinks that it's ready - or after reaching maxTurns of Q&A with
 * the bot.
 */
export const aiMicrochatStateEntry: StateEntryProps = {
  version: 2,
  type: 'renderInitMicrochat',
  temporary: false,
  payload: {
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.MICROCHAT_5_WHY,
    maxTurns: 10, // hidden for user ==> hidden via ValuesInput.tsx
    emoticons: true,
  },
  displayName: 'Micro-Chat (AI)',
  nodeType: 'aiMicrochatStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 1,
};
