import {
  FormControl,
  Input,
  InputAdornment,
  Stack,
  CardMedia,
  Box,
} from '@mui/material'; // Typography
import React, { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl'; // defineMessages, FormattedMessage,
import TreeMenu from 'react-simple-tree-menu';
import SearchIcon from '@mui/icons-material/Search';
import { evoachDefaultTheme } from '@evoach/ui-components';

//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { AccountContext, RoleEnum } from '../../account';
import { useGlobalStyles, StateEntryProps } from '../nodes';

import { ListItem } from './TreeViewHelper';
import {
  evoachSpecialNodes,
  globalTreeData,
} from './ElementSelectionTreeElements';
import { previewImages } from './ElementSelectionPreviewImages';

/*
  ! Component ElementSelection

  React component that displays the list of draggable elements to be dragged to the editor

  The documentation of the tree component can be found here:
  - https://iannbing.github.io/react-simple-tree-menu/?path=/story/treemenu--control-treemenu-from-both-its-parent-and-opennodes
  - https://github.com/iannbing/react-simple-tree-menu
  - https://github.com/iannbing/react-simple-tree-menu/blob/master/stories/index.stories.js
*/

export const ElementSelection: React.FC = () => {
  const styles = useGlobalStyles();
  const intl = useIntl();
  const { hasRole, hasFeature } = useContext(AccountContext);

  const treeData = useMemo(() => {
    let returnTree = JSON.parse(JSON.stringify(globalTreeData));
    //
    // apply role specific filtering
    //
    if (hasRole(RoleEnum.EVOACHADMIN)) {
      // add special elements and return anything for evoach Admin
      returnTree = returnTree.concat(evoachSpecialNodes);
    } else {
      // remove elements that are for betatesters, only
      if (!hasRole(RoleEnum.BETATESTER)) {
        returnTree = returnTree.filter((elem: any) => !elem.betaOnly);
      }
    }

    //
    // apply permission specific filtering
    //
    returnTree = returnTree.filter((elem: any) => {
      if (!elem.withFeature) {
        // if no specific feature is configured, make it available feture indepenedent
        return true;
      } else {
        // if a feature is configured, check if the user has the feature
        if (hasFeature(elem.withFeature)) {
          return true;
        } else {
          return false;
        }
      }
    });

    return returnTree;
  }, [hasFeature, hasRole]);

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement> | React.DragEvent<HTMLLIElement>,
    nodeType: string,
    stateEntry: StateEntryProps
  ) => {
    event.dataTransfer.effectAllowed = 'move';
    // if we intend to add an element that is handled by a
    // hard-coded template,
    if (!!stateEntry.handledByTemplate) {
      // will be handled by OnDropTemplate in ModuleEditorPane
      event.dataTransfer.setData('application/reactflow/template', nodeType);
    } else {
      // transfer state entry content of current element (e.g. "coachMessage")
      // to editor drop event
      event.dataTransfer.setData(
        'application/reactflow/stateEntry',
        JSON.stringify(stateEntry)
      );
    }
  };

  const [imageHidden, setImageHidden] = useState<number>(0);
  const [image, setImage] = useState<string>('');

  const showPreviewImage = (element: any, offsetTop?: number) => {
    const imagename =
      element !== ''
        ? element.nodeType.toLowerCase().replaceAll('stateentry', '')
        : '';
    setImageHidden(
      imagename === '' || previewImages[image] === undefined
        ? 0
        : offsetTop ?? 0
    );
    if (imagename !== '') {
      setImage(imagename);
    }
  };

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const maxHeight = vh - 200 + 'px';

  return (
    <Box width="99%">
      <TreeMenu data={treeData} debounceTime={125}>
        {({ search, items }) => {
          return (
            <>
              <FormControl variant="standard">
                <Input
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    if (search && e.target.value !== undefined) {
                      search(e.target.value);
                    }
                  }}
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  }
                  autoComplete="off"
                  autoCorrect="off"
                  placeholder={intl.formatMessage({
                    id: 'builder.moduleeditor.elementSelection.searchbox',
                    defaultMessage: 'Element suchen ...',
                  })}
                />
              </FormControl>
              <Stack
                sx={{
                  height: maxHeight,
                  maxHeight: maxHeight,
                  overflow: 'auto',
                  display: 'flex',
                  marginTop: '10px',
                }}
              >
                {items.map(({ _reset, ...props }, index: number) => {
                  // we have to map that here explicitely, because
                  // passing props leads to warnings due to wrongly
                  // named DOM nodes (props). It's an issue of the
                  // component
                  return ListItem({
                    level: props.level,
                    hasNodes: props.hasNodes,
                    isOpen: props.isOpen,
                    label: props.label,
                    searchTerm: props.searchTerm,
                    openNodes: props.openNodes,
                    toggleNode: props.toggleNode,
                    onClick: props.onClick,
                    focused: props.focused,
                    element: props.element,
                    styles: styles,
                    onDragStart: onDragStart,
                    index: index,
                    showPreviewImage: showPreviewImage,
                  });
                })}
              </Stack>
            </>
          );
        }}
      </TreeMenu>
      {imageHidden !== 0 && (
        <CardMedia
          component="img"
          key="previewimage"
          sx={{
            maxWidth: '200px',
            borderColor: evoachDefaultTheme.palette.secondary.main,
            borderStyle: 'solid',
            borderWidth: '1px',
            zIndex: 5000,
            position: 'absolute',
            left: '300px',
            top: imageHidden - 30 + 'px',
          }}
          image={previewImages[image]}
          hidden={imageHidden === 0}
        />
      )}
    </Box>
  );
};
