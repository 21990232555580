import { FormControl } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { Node } from 'reactflow';

import { useFetchProvidedGlobalVariables } from '../../../api/module/useFetchProvidedGlobalVariables';
import { ModuleGlobalVarProps } from '../../../entities';
import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { autoSave } from '../../ModuleEditor/autosave';
import { ModuleEditorContext } from '../../ModuleEditor/ModuleEditorContext';
import {
  getDataAsNodeDataProps,
  isHotOrNotEntry,
  isImageSelectorEntry,
  isMultiButtonEntry,
  isMultipleOutputEntry,
  isMultiplePercentageScaleEntry,
  isRadioButtonEntry,
  isSaveResultToEntry,
  isSelectionCardEntry,
  isValidVariableName,
  ReadWriteType,
  StateEntryProps,
} from '../../nodes';

export interface GetValueFromSelectProps {
  updateValueFrom: Function;
  currentValue: string | string[] | undefined;
  multiselect?: boolean;
  index?: number;
  getValueType: ReadWriteType | ReadWriteType[] | undefined;
  nodes: Node<any>[];
}

/** GetValueFrom Select generates a select list to select one or more values
 *
 * @param {Function} updateValueFrom handler to update a value
 * @param {string} currentValue current value of parameter
 * @param {boolean} multiselect false = only one value can be select, true = multiplevalues can be selected
 * @param {number} @optional index check whether a certain index of the initial value has to be updated
 * @param {ReadWriteType} getValueType defines the value type that is returned by the component
 */
export const GetValueFromSelect: React.FC<GetValueFromSelectProps> = ({
  updateValueFrom,
  currentValue,
  multiselect,
  index,
  getValueType,
  nodes,
}) => {
  const [valueFrom, setValueFrom] = useState<string | string[]>(
    currentValue === undefined || currentValue === null
      ? Array.isArray(currentValue)
        ? ['']
        : ''
      : currentValue
  );

  const [valueBeforeChange, setValueBeforeChange] = useState<string | string[]>(
    currentValue === undefined || currentValue === null
      ? Array.isArray(currentValue)
        ? ['']
        : ''
      : currentValue
  );

  // get current module
  const { module } = useContext(ModuleEditorContext);
  // exlcude current module
  const { globalVariables } = useFetchProvidedGlobalVariables(module.moduleid);

  // default
  const multiSelect = multiselect ? multiselect : false;

  /**
   * checkGetValueType - check whether the vartype is part of the current
   * getValueType and returns true if this is the case
   *
   * @param {number[] | number | undefined} currentGetValueType
   * @param {number} vartype
   * @returns {boolean} true if vartype is currentGetValueType
   */
  const checkGetValueType = (
    currentGetValueType: number[] | number | undefined,
    vartype: number | number[]
  ): boolean => {
    if (currentGetValueType === undefined || currentGetValueType === null)
      return false;

    const validGetValueType = Array.isArray(currentGetValueType)
      ? (currentGetValueType as number[])
      : (currentGetValueType as number);

    let varTypeCompare;
    if (Array.isArray(vartype)) {
      varTypeCompare = vartype.length > 0 ? vartype[0] : undefined;
    } else {
      varTypeCompare = vartype;
    }

    if (Array.isArray(validGetValueType)) {
      return varTypeCompare !== undefined
        ? validGetValueType.indexOf(varTypeCompare) > -1
        : false;
    } else {
      return varTypeCompare === validGetValueType;
    }
  };

  /**
   * Compare whether get and save types are equal. Return true if this is the case
   * and false else
   *
   * This is especially difficult because these value can be ReadWriteTypes
   * or an array (!) of ReadWriteTypes. Therefore we have to check the data types first.
   *
   * @param {number[] | number} currentGetValueType
   * @param {StateEntryProps | undefined) entry to be checked
   * @returns {boolean} true = getValueType(s) and saveResultType(s) match somehow
   */
  const isValidVariable = (
    entry: StateEntryProps | undefined,
    currentGetValueType: number[] | number | undefined
  ): boolean => {
    if (currentGetValueType === undefined || currentGetValueType === null)
      return false;

    const validGetValueType = Array.isArray(currentGetValueType)
      ? (currentGetValueType as number[])
      : (currentGetValueType as number);

    if (Array.isArray(entry?.saveResultType)) {
      if (Array.isArray(validGetValueType)) {
        // both are arrays
        // convert both to number arrays as otherwise "some" and "includes" do not work!
        const savs = entry?.saveResultType as number[];
        const gets = validGetValueType as number[];
        return savs.some((r) => gets.includes(r));
      } else {
        // only save saveResultType is array
        return entry?.saveResultType === undefined ||
          entry?.saveResultType === null
          ? false
          : entry?.saveResultType.indexOf(validGetValueType) > -1;
      }
    } else {
      if (Array.isArray(validGetValueType)) {
        // only getValueType is array, saveResultType is string
        return entry?.saveResultType === undefined ||
          entry?.saveResultType === null
          ? false
          : validGetValueType.includes(entry?.saveResultType);
      } else {
        // both are only strings
        return entry?.saveResultType === validGetValueType;
      }
    }
  };

  const displayGeneratedVariable = (
    entry: StateEntryProps | undefined
  ): boolean => {
    if (!entry) return false;
    return (
      entry &&
      !entry.payload.saveResultTo?.startsWith('evoachechokey') &&
      entry.payload.saveResultTo?.trim() !== ''
    );
  };

  const preparedVariables = nodes
    .map(getDataAsNodeDataProps)
    .map((nodeData) => nodeData.state.entry.find(isSaveResultToEntry))
    .filter((entry: StateEntryProps | undefined) => entry !== undefined);

  // pre-filter all nodes that may contain valid variables for the selection
  const validNodesList = preparedVariables.filter((entry) =>
    isValidVariable(entry, getValueType)
  );

  // selectable variables as defined in components
  const selectableValues = validNodesList
    .map((entry) => entry?.payload.saveResultTo as string)
    .filter((value) => value && value.length > 0)
    .filter((value) => isValidVariableName(value));

  // variables defined within loops get automatic additonal generated variables
  const generatedVarsForLoops = validNodesList
    .filter((entry) => entry?.payload.inLoop !== undefined)
    .map((entry) => {
      if (
        entry &&
        displayGeneratedVariable(entry) &&
        !entry.payload.inLoop?.startsWith('evoachechokey')
      ) {
        return entry.payload.inLoop + '_' + entry.payload.saveResultTo;
      }
      return '';
    })
    .filter((value) => value && value.length > 0);

  // ad <variable>_NOTSELECTED for:
  // - Mulitple Output
  // - HotOrNot // PROD-1402
  // add two variables: the actual variable from
  // above (regular variable) and an <variable>_NOTSELECTED value that contais only the unselected
  const multipleOutputVars = !checkGetValueType(
    getValueType,
    ReadWriteType.typedObject
  )
    ? []
    : preparedVariables
        .filter(
          (entry) => isMultipleOutputEntry(entry) || isHotOrNotEntry(entry)
        )
        .map((entry) => {
          if (entry && displayGeneratedVariable(entry)) {
            return entry.payload.saveResultTo + '_NOTSELECTED'; // this suffix is defined in UI components!
          }
          return '';
        });

  // MUltiplePercentageScaleInput delivers additional <saveresultto>_SUM value, PROD-1393
  const multiplePercentageVars = !checkGetValueType(
    getValueType,
    ReadWriteType.number
  )
    ? []
    : preparedVariables
        .filter((entry) => isMultiplePercentageScaleEntry(entry))
        .map((entry) => {
          if (entry && displayGeneratedVariable(entry)) {
            return entry.payload.saveResultTo + '_SUM'; // this suffix is defined in UI components!
          }
          return '';
        });

  // Selection card sends a text but also a number from keyNumbers!
  // The variable for _NUM is generated - add it here as it can't be found
  // in the machine graph!
  const generatedNumericValuesForTextLists = !checkGetValueType(
    getValueType,
    ReadWriteType.number
  )
    ? []
    : preparedVariables
        .filter(
          (entry) =>
            isSelectionCardEntry(entry) ||
            isMultiButtonEntry(entry) ||
            isRadioButtonEntry(entry)
        )
        .map((entry) => {
          if (entry && displayGeneratedVariable(entry)) {
            return entry.payload.saveResultTo + '_NUM'; // this suffix is defined in UI components!
          }
          return '';
        });

  const selectedAndAllVariables = !checkGetValueType(
    getValueType,
    ReadWriteType.typedObject
  )
    ? []
    : preparedVariables
        .filter((entry) => isImageSelectorEntry(entry))
        .map((entry) => {
          if (entry && displayGeneratedVariable(entry)) {
            return entry.payload.saveResultTo + '_ALL'; // this suffix is defined in UI components!
          }
          return '';
        });

  //
  // PROD-1856 - add global variables
  // TODO add content in backend when creating the session
  const globallyProvidedVariables = useMemo(() => {
    return globalVariables
      ? globalVariables
          .filter((gvar: ModuleGlobalVarProps) =>
            checkGetValueType(getValueType, gvar.saveResultType)
          )
          .map((gvar: ModuleGlobalVarProps) => gvar.name)
      : [];
  }, [getValueType, globalVariables]);

  /**
   * check whether the vars are global vars
   *
   * @param {string | string[]} vars
   * @returns
   */
  const containedGlobalVariable = (vars: string | string[]): string[] => {
    // one single variable
    if (typeof vars === 'string' && globallyProvidedVariables.includes(vars)) {
      return [vars];
    }
    // an array of variable names
    if (Array.isArray(vars)) {
      return vars.filter((varname: string) =>
        globallyProvidedVariables.includes(varname)
      );
    }

    return [];
  };

  //
  // PROD-1656 - add global variables
  // ! important note:
  // If you add variables here, you have to add them in useFetchSessionQuery.ts in Player
  // repo to be globally set.
  //
  const globalPersonVariables = !checkGetValueType(
    getValueType,
    ReadWriteType.string
  )
    ? []
    : ['COACHEE_FIRSTNAME', 'COACHEE_LASTNAME'];

  // add all generated variables to the list of generated variables
  const allAvailableVariables = [
    ...new Set(
      selectableValues
        .concat(generatedVarsForLoops)
        .concat(multipleOutputVars)
        .concat(multiplePercentageVars)
        .concat(generatedNumericValuesForTextLists)
        .concat(selectedAndAllVariables)
        .concat(globalPersonVariables)
        .concat(globallyProvidedVariables)
        .sort()
    ),
  ];

  //
  // select required var from list of provided vars
  //
  const getNodeOfGlobalVar = (
    varname: string,
    getValueType: ReadWriteType | ReadWriteType[] | undefined
  ): ModuleGlobalVarProps | undefined => {
    const vars = globalVariables.filter((gvar: ModuleGlobalVarProps) => {
      if (gvar.name === varname) {
        let srt;

        if (Array.isArray(gvar.saveResultType)) {
          if (gvar.saveResultType.length > 0) {
            srt = gvar.saveResultType[0];
          }
        } else {
          srt = gvar.saveResultType;
        }

        if (Array.isArray(getValueType)) {
          return srt === undefined ? false : getValueType.includes(srt);
        } else {
          return getValueType === srt;
        }
      }
      return false;
    });

    if (vars.length > 0) {
      return vars[0];
    } else {
      return undefined;
    }
  };
  /**
   * handleVariableSelectionChange manages selection of a variable in a select list
   *
   * @param {string | string[]} oneOrMoreVarNames - a var name or an array of varnames
   */
  const handleVariableSelectionChange = (
    oneOrMoreVarNames: string | string[]
  ) => {
    if (!Array.isArray(oneOrMoreVarNames)) {
      setValueFrom(oneOrMoreVarNames);
    } else {
      if (oneOrMoreVarNames.includes('')) {
        setValueFrom([]);
      } else {
        setValueFrom(
          oneOrMoreVarNames.filter(
            (val: string) => val !== null && val !== undefined
          )
        );
      }
    }

    // first check whether previously included global vars were removed
    const previouslyUsedGLobalVars = containedGlobalVariable(valueBeforeChange);
    const selectedGlobalVars = containedGlobalVariable(oneOrMoreVarNames);

    // Calc delta "previous" vars to "now" vars.
    // Which vars are in prev that are not contained now ?
    // => remove in module.requiredglobalvariables
    const tobeRemoved = previouslyUsedGLobalVars.filter(
      (vaname: string) => !selectedGlobalVars.includes(vaname)
    );

    // remove all variables that are contained in toBeRemoved
    module.requiredglobalvariables = module.requiredglobalvariables.filter(
      (gvar: ModuleGlobalVarProps) => {
        if (tobeRemoved.includes(gvar.name)) {
          let srt;

          if (Array.isArray(gvar.saveResultType)) {
            //
            if (gvar.saveResultType.length > 0) {
              srt = gvar.saveResultType[0];
            }
          } else {
            srt = gvar.saveResultType;
          }
          // in remove list: check type
          if (Array.isArray(getValueType)) {
            return srt ? !getValueType.includes(srt) : false;
          } else {
            return !(srt === getValueType);
          }
        } else {
          return true;
        }
      }
    );

    if (selectedGlobalVars.length > 0) {
      // there are new global vars available
      selectedGlobalVars.forEach((varname: string) => {
        // before adding, check whether it exists to avoid duplicates
        const exists =
          module.requiredglobalvariables.filter(
            (gvar: ModuleGlobalVarProps) => {
              let srt;
              if (Array.isArray(gvar.saveResultType)) {
                if (gvar.saveResultType.length > 0) {
                  srt = gvar.saveResultType[0];
                }
              } else {
                srt = gvar.saveResultType;
              }
              return (
                gvar.name === varname &&
                (Array.isArray(getValueType) && srt
                  ? getValueType.includes(srt)
                  : srt === getValueType)
              );
            }
          ).length > 0;

        // if not yet included, add it
        if (!exists) {
          const gvar = getNodeOfGlobalVar(varname, getValueType);

          module.requiredglobalvariables.push({
            name: varname,
            nodeType: gvar?.nodeType ?? '',
            saveResultType: gvar?.saveResultType ?? ReadWriteType.string,
          });
        }
      });
    }

    // remember new value in case it is changed again
    setValueBeforeChange(oneOrMoreVarNames);

    // index is potentially passed by PolarChartProperty
    index !== undefined
      ? updateValueFrom(oneOrMoreVarNames, index) // e.g. PolarChart properties
      : updateValueFrom(oneOrMoreVarNames); // any other getValuesFrom

    autoSave();
  };

  // PROD-1304 - if the variable list contains a variable name that
  // changes (e.g. in loops), we have to check whether the value
  // exists furthermore. valueFrom can be of type string and string[]
  const valueFromExists =
    typeof valueFrom === 'string'
      ? allAvailableVariables.includes(valueFrom)
      : valueFrom.reduce((howmany: number, val: string) => {
          return howmany + (allAvailableVariables.includes(val) ? 1 : 0);
        }, 0) === valueFrom.length;

  // PROD-1304 - if one of the current variables stored for getValuesFrom
  // does not exist any more, we have to remove it from the pre-select list
  // for the select component. Distinguish between string and string[]
  const valueFromCalc = valueFromExists
    ? valueFrom
    : typeof valueFrom === 'string'
    ? ''
    : valueFrom.filter((val: string) => allAvailableVariables.includes(val));

  return (
    <FormControl
      disabled={!allAvailableVariables || allAvailableVariables.length < 1}
      sx={{ width: '95%' }}
    >
      <EvoachSelect
        value={valueFromCalc}
        onChange={(event) =>
          handleVariableSelectionChange(event.target.value as string | string[])
        }
        multiple={multiSelect}
      >
        {!multiSelect && (
          <EvoachMenuItem key="SelectableValue-1" value="">
            None
          </EvoachMenuItem>
        )}
        {allAvailableVariables.map((selectableValue: string, index: number) => (
          <EvoachMenuItem
            key={`SelectableValue${index}`}
            value={selectableValue}
          >
            {selectableValue}
          </EvoachMenuItem>
        ))}
      </EvoachSelect>
    </FormControl>
  );
};
