/**
 * truncates a string after a certain length and adds a ellipsis (or a specified string instead).
 *
 * @param {string} string string to truncate
 * @param {number} length truncate after this length
 * @param {string} omissionString replaces the ellipsis at the end
 * @returns {string} truncated string
 */
export const truncateStringToLength = (
  string: string,
  length: number,
  omissionString?: string
) => {
  return string.length > length
    ? `${string.substring(0, length)}${omissionString ?? '...'}`
    : string;
};
