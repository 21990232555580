import { Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TranslationContext } from '../intl/TranslationContext';

interface DescriptionProps {
  /**
   * this defines the name of the state entry that is calling, e.g. "aiMicrochatStateEntry"
   */
  nodetype: string | undefined;
  /**
   * this is the name of the property of which the description is displayed. This may be
   * "description" for the whole component or the name of a property like "message" for
   * a certain property. The properties are the ones defined in StatePayloadProps
   */
  propname: string | undefined;
}

/**
 * Renders the descirption of a component or its properties.
 *
 * It assumes that there is a translation for the key nodetype.propname that
 * should be defined in the .state.tsx file of a component via defineMessage.
 * As an @example @see AiMicrochat.state.tsx
 *
 * The Description component is used both in .node.tsx implementations as well
 * as in input components for EntryList.tsx. If either nodetype or propname
 * are not defined, the component is not rendered. If both are non.empty but
 * the translation does no exists, a space " " is rendered.
 *
 * @param {string} nodetype - this defines the name of the state entry that is
 * calling, e.g. "aiMicrochatStateEntry"
 * @param {string} propname - this is the name of the property of which the
 * description is displayed. This may be "description" for the whole component
 * or the name of a property like "message" for a certain property. The properties
 * are the ones defined in StatePayloadProps.
 * @returns {JSX} the component to be rendered.
 */
export const Description: React.FC<DescriptionProps> = ({
  nodetype,
  propname,
}) => {
  const intl = useIntl();

  const { messages } = useContext(TranslationContext);

  const desc = useMemo(() => {
    const tkey = nodetype + '.' + propname;
    if (messages[tkey]) {
      return intl.formatMessage({
        id: tkey,
      });
    } else {
      return '';
    }
  }, [intl, messages, nodetype, propname]);

  return (
    <>{desc !== '' ? <Typography variant="body2">{desc}</Typography> : null}</>
  );
};
