import { CardImageEnum } from '@evoach/ui-components';
import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../account';
import { getAssetUrl } from '../../api';
import { authorizedPut } from '../../api/authorizedApi';
import { categoryTranslations, ModuleCategoryEnum } from '../../entities';
import { ModulePermission } from '../../entities/ModulePermissions';
import { useEnvironment } from '../../environment/useEnvironment';
import { EvoachMenuItem, EvoachSelect } from '../customMaterialComponents';
import { AssetType } from '../PropertiesSidebar/assetHelper';
import { AssetMenu } from '../PropertiesSidebar/VariableInputs/AssetMenu';
import { LabelEdit } from '../reception/analyticsevoach/LabelEdit';
import { mapImageToPublicAsset } from '../reception/assets/AssetHelper';

import { ModuleEditorContext } from './ModuleEditorContext';

const metaDataEditorMessages = defineMessages({
  dialogTitle: {
    id: 'builder.moduleeditor.metadata.dialogTitle',
    defaultMessage: 'Modulbeschreibung',
  },
  dialogSaveButton: {
    id: 'builder.moduleeditor.metadata.dialogSaveButton',
    defaultMessage: 'Speichern',
  },
  dialogCloseButton: {
    id: 'builder.moduleeditor.metadata.dialogCloseButton',
    defaultMessage: 'Abbrechen',
  },
  dialogissubmodule: {
    id: 'builder.moduleeditor.metadata.issubmoduletext',
    defaultMessage: 'Dieses Modul ist ein Submodul bzw. eine Vorlage',
  },
  dialogallowdefaulttemplate: {
    id: 'builder.moduleeditor.metadata.dialogallowdefaulttemplate',
    defaultMessage:
      'Das "Default"-Modul darf von Coaches als Vorlage dupliziert werden.',
  },
  dialogisdefault: {
    id: 'builder.moduleeditor.metadata.isdefaultmoduletext',
    defaultMessage:
      'Dieses Modul ist für alle Coachees bzw. Coaches sichtbar, d.h. es ist ein sogenanntes "Default"-Module.',
  },
  category: {
    id: 'builder.moduleeditor.metadata.category',
    defaultMessage: 'Kategorie',
  },
});
export interface MetadataEditorProps {
  open: boolean;
  onClose: () => void;
  addOrUpdateMetadataTranslation: Function;
  updateModuleProps: Function;
  updateModuleMetadataProps: Function;
}
export const MetadataEditor: React.FC<MetadataEditorProps> = ({
  open,
  onClose,
  addOrUpdateMetadataTranslation,
  updateModuleProps,
  updateModuleMetadataProps,
}) => {
  const metadatafield = ['title', 'description', 'duration'];
  const { module } = useContext(ModuleEditorContext);
  const metadata = module.metadata;

  const intl = useIntl();
  const { hasRole } = useContext(AccountContext);
  const { playerBasePath } = useEnvironment();

  const [moduleCategories, setModuleCategories] = useState<Array<String>>(
    (metadata.categories as string[]) ?? []
  );
  const [orderingNumber, setOrderingNumber] = useState(
    metadata.orderingnumber ?? 1000
  );
  const [categoryIsMissingError, setCategoryIsMissingError] = useState(false);

  const [issubmodule, setIsSubmodule] = useState<boolean>(module.issubmodule);
  const [isdefaultmodule, setisdefaultmodule] = useState<boolean>(
    module.isdefaultmodule
  );

  const [defaultAsTemplateAllowed, setdefaultAsTemplateAllowed] =
    useState<boolean>(
      module.permissions[0].CoachPermission_CopyModuleAsTemplate
    );

  const transIndex = useMemo(() => {
    return module.permissions.reduce(
      (transIndex: number, perm: ModulePermission, currentIndex: number) => {
        return perm.owns && perm.owns === true ? currentIndex : transIndex;
      },
      -1
    );
  }, [module.permissions]);

  const toggleDefaultAsTemplateAllowed = async () => {
    // do nothing if the user is not the owner of the module. Only an
    // owner can change the permissions of the own module.
    // Even if someone hacks this, the permission is checked in backend
    // in addition
    if (!module || transIndex === -1) {
      return;
    }

    module.permissions[transIndex].CoachPermission_CopyModuleAsTemplate =
      !defaultAsTemplateAllowed;
    // update permissions
    const updateCall = authorizedPut(
      `/module/${module.moduleid}/modulepermission/${module.permissions[transIndex].modulepermissionid}`,
      module.permissions[transIndex]
    );

    await updateCall();

    setdefaultAsTemplateAllowed(!defaultAsTemplateAllowed);
  };

  const [moduleIsEmpty, setModuleIsEmpty] = useState<boolean>(false);

  useEffect(() => {
    setModuleIsEmpty(
      !module.buildergraph ||
        module.buildergraph.length === 0 ||
        module.buildergraph.length === undefined ||
        module.buildergraph[0] === undefined
    );
  }, [module.buildergraph]);

  let titles: { [k: string]: string } = {};

  const [labels, setLabels] = useState<string[]>(metadata.labels as string[]);

  const updateLabels = () => {
    updateModuleMetadataProps('labels', labels);
  };

  // do not create these entries with a loop because
  // we can't extract the values by the yarn extract script in a loop
  titles['title'] = intl.formatMessage({
    id: 'builder.moduleeditor.metadata.title',
    defaultMessage: 'Titel',
  });
  titles['description'] = intl.formatMessage({
    id: 'builder.moduleeditor.metadata.description',
    defaultMessage: 'Beschreibung',
  });
  titles['duration'] = intl.formatMessage({
    id: 'builder.moduleeditor.metadata.duration',
    defaultMessage: 'Dauer',
  });

  const toggleSubModuleCheckbox = () => {
    setIsSubmodule(!issubmodule);
    updateModuleProps('issubmodule', !module.issubmodule);
  };
  const toggleDefaultModuleCheckbox = () => {
    setisdefaultmodule(!isdefaultmodule);
    updateModuleProps('isdefaultmodule', !module.isdefaultmodule);
  };

  const handleCategoryChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    const newCategories =
      typeof value === 'string' ? value.split(',') : (value as string[]);
    setModuleCategories(newCategories);
    setCategoryIsMissingError(false);

    updateModuleMetadataProps('categories', newCategories);
  };

  const handleOrderingNumberChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setOrderingNumber(value);
    updateModuleMetadataProps('orderingnumber', event.target.value);
  };

  //
  // update legacy prop image in metadata with enum
  //
  /* const onImageChange = (image: CardImageEnum) => {
    updateModuleMetadataProps('image', image);
  }; */

  const [urlToUse, seturlToUse] = useState<string>(
    !metadata?.resolvedsrc || metadata?.resolvedsrc === ''
      ? mapImageToPublicAsset(metadata.image, playerBasePath)
      : metadata?.resolvedsrc
  );

  //
  // proxy to update the metadata value.
  //
  const localUpdateAssetValue = (
    val: string | undefined | CardImageEnum,
    key: 'assetid' | 'src' | 'image'
  ) => {
    // update metadata is fine
    updateModuleMetadataProps(key, val);

    //console.log(key + ' = ' + val);

    // depending on what's happening, we have to set state for this component
    if (key !== 'image' && val) {
      if (key === 'src') {
        updateModuleMetadataProps('assetid', '');
        updateModuleMetadataProps('resolvedsrc', val);
        seturlToUse(val);
      } else {
        getAssetUrl(val).then((url: string) => {
          updateModuleMetadataProps('resolvedsrc', url);
          seturlToUse(url);
        });
      }
    } else {
      const u = mapImageToPublicAsset(
        val && val !== '' ? (val as CardImageEnum) : CardImageEnum.a_day_off,
        playerBasePath
      );
      updateModuleMetadataProps('resolvedsrc', u);
      seturlToUse(u);
    }
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage {...metaDataEditorMessages.dialogTitle} />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {metadatafield.map((metadataKey: string) => (
            <div key={`Metadata-${metadataKey}`} style={{ marginTop: 20 }}>
              <MetadataTextfield
                metadataKey={metadata[metadataKey] as string}
                addOrUpdateMetadataTranslation={addOrUpdateMetadataTranslation}
                labeltext={titles[metadataKey] as string}
              />
            </div>
          ))}
          {!module.issubmodule && (
            <Box width="100%">
              <FormControl fullWidth required error={categoryIsMissingError}>
                <InputLabel id="select-module-category-label" color="secondary">
                  {intl.formatMessage(metaDataEditorMessages.category)}
                </InputLabel>
                <EvoachSelect
                  labelId="select-module-category-label"
                  id="select-module-category"
                  defaultValue=""
                  required
                  multiple={false}
                  value={moduleCategories.length > 0 ? moduleCategories : ''}
                  input={
                    <OutlinedInput
                      label={intl.formatMessage(
                        metaDataEditorMessages.category
                      )}
                    />
                  }
                  renderValue={(selected) => {
                    return (selected as string[])
                      .map((category) =>
                        intl.formatMessage(
                          categoryTranslations[
                            category as keyof typeof categoryTranslations
                          ]
                        )
                      )
                      .join(', ');
                  }}
                  onChange={handleCategoryChange}
                >
                  {Object.keys(ModuleCategoryEnum).map((category) => (
                    <EvoachMenuItem key={category} value={category}>
                      <Checkbox
                        color={
                          moduleCategories.indexOf(category) > -1
                            ? 'default'
                            : 'primary'
                        }
                        checked={moduleCategories.indexOf(category) > -1}
                      />
                      {intl.formatMessage(
                        categoryTranslations[category as ModuleCategoryEnum]
                      )}
                    </EvoachMenuItem>
                  ))}
                </EvoachSelect>
                {categoryIsMissingError && (
                  <FormHelperText error={categoryIsMissingError}>
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.metadata.category.error',
                      defaultMessage: 'Bitte wähle eine Kategorie aus',
                    })}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          )}
          {hasRole(RoleEnum.EVOACHADMIN) && (
            <>
              <TextField
                label={intl.formatMessage({
                  id: 'builder.moduleeditor.metadata.orderingnumber',
                  defaultMessage: 'Sortierungs-Wert',
                })}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                value={orderingNumber}
                onChange={handleOrderingNumberChange}
              >
                asdf
              </TextField>
            </>
          )}
          <Typography variant="body2" component="span">
            {intl.formatMessage({
              id: 'builder.modulelabel',
              defaultMessage:
                'Die Labels sind nicht für den Coachee sichtbar. Sie werden zur Zeit auch nich nicht vom evoach System verwendet, werden aber für zukünftige Funktionen Verwendung finden (etwa: finde ähnlich Chatbots).',
            })}
          </Typography>

          <LabelEdit
            initialLabels={labels}
            sx={{ marginTop: '10px' }}
            width="inherit"
            noLabelsText={intl.formatMessage({
              id: 'builder.userlabeldialog.nolabels',
              defaultMessage: 'Es sind noch keine Labels vergeben',
            })}
            onUpdate={(labels: string[]) => {
              setLabels(labels);
              updateLabels();
            }}
            onClose={updateLabels}
          />

          {false && moduleIsEmpty && (
            <Typography component="span" variant="body1">
              {intl.formatMessage({
                id: 'builder.moduleeditor.metadata.language',
                defaultMessage:
                  'In welcher Ausgangssprache wird das Modul erstellt?',
              })}
              {module.translations[0].lang}
            </Typography>
          )}

          <Typography component="span" variant="body1">
            {intl.formatMessage({
              id: 'builder.moduleeditor.metadata.moduleimage',
              defaultMessage:
                'Welches Bild soll in der Modulliste angezeigt werden?',
            })}
            <Box sx={{ marginTop: '20px', width: '50%' }}>
              <CardMedia
                component="img"
                src={urlToUse}
                sx={{
                  height: '135px',
                  width: '135px',
                  marginBottom: '10px',
                }}
              />
              <AssetMenu
                externalUrl={
                  metadata?.src && metadata.src !== '' ? metadata.src : ''
                }
                updateAssetValue={localUpdateAssetValue}
                assetType={AssetType.IMAGE}
                addPublicAssets
              />
            </Box>
          </Typography>

          {hasRole(RoleEnum.EVOACHADMIN) && (
            <Typography component="span" variant="body1">
              <Checkbox
                id="issubmodulecheckbox"
                key="issubmodulecheckbox"
                checked={issubmodule}
                onChange={toggleSubModuleCheckbox}
              />
              <FormattedMessage {...metaDataEditorMessages.dialogissubmodule} />
            </Typography>
          )}
          {hasRole(RoleEnum.EVOACHADMIN) && (
            <>
              <Typography component="span" variant="body1">
                <Checkbox
                  id="isdefaultmodulecheckbox"
                  key="isdefaultmodulecheckbox"
                  checked={isdefaultmodule}
                  onChange={toggleDefaultModuleCheckbox}
                />
                <FormattedMessage {...metaDataEditorMessages.dialogisdefault} />
              </Typography>
              {isdefaultmodule && (
                <Typography component="span" variant="body1">
                  <Checkbox
                    id="allowdefaultmoduleastemplate"
                    key="allowdefaultmoduleastemplate"
                    checked={defaultAsTemplateAllowed}
                    onChange={toggleDefaultAsTemplateAllowed}
                  />
                  <FormattedMessage
                    {...metaDataEditorMessages.dialogallowdefaulttemplate}
                  />
                </Typography>
              )}
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            if (moduleCategories.length > 0 || module.issubmodule) {
              const close = onClose;
              close();
            } else {
              setCategoryIsMissingError(true);
            }
          }}
        >
          <FormattedMessage {...metaDataEditorMessages.dialogSaveButton} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface MetadataTextfieldProps {
  metadataKey: string;
  addOrUpdateMetadataTranslation: Function;
  labeltext: string;
}

const MetadataTextfield: React.FC<MetadataTextfieldProps> = ({
  metadataKey,
  addOrUpdateMetadataTranslation,
  labeltext,
}) => {
  const intl = useIntl();

  const [elementValue, setElementValue] = useState<string>(
    intl.formatMessage({
      id: metadataKey,
    })
  );

  const updateElementValue = (newValue: string) => {
    addOrUpdateMetadataTranslation(metadataKey, newValue);
    setElementValue(newValue);
  };

  return (
    <TextField
      multiline={true}
      label={labeltext}
      fullWidth={true}
      value={elementValue}
      onChange={(evt) => updateElementValue(evt.target.value)}
      key={metadataKey}
    />
  );
};
