import { assertUnreachable } from '../util/index';

import { SupportedLocales } from './SupportedLocales';

export const getLocaleMessages = async (locale: SupportedLocales) => {
  switch (locale) {
    case SupportedLocales.DE:
      return (await import('../lang/default.json')) as unknown as Record<
        string,
        string
      >;
    case SupportedLocales.EN:
      return (await import('../lang/en.json')) as unknown as Record<
        string,
        string
      >;

    default:
      assertUnreachable(locale);
  }
};
