import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  Set Directed Agent Mode Node
*/

export const SetDirectedAgentModeNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const nodeText = !!payload.directedAgentMode
    ? intl.formatMessage({
        id: ' builder.nodes.elements.setdirectedagentmode.hint.on',
        defaultMessage:
          'An dieser Stelle wird der "Directed Agent Mode" eingeschaltet. Alle Inhalte des Chats werden an die folgenden KI Komponenten weitergegeben, bis der "Directed Agent Mode" wieder ausgeschaltet wird.',
      })
    : intl.formatMessage({
        id: ' builder.nodes.elements.setdirectedagentmode.hint.off',
        defaultMessage:
          'Schaltet den "Directed Agent Mode" aus. Alle folgenden KI Komponenten haben keine Kenntnis über den bisherigen Chatverlauf.',
      });

  return (
    <span style={{ height: 10 }}>
      <Handle
        type="target"
        id="directedagendmodeinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ width: '300px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">{nodeText}</Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="directedagendmodeouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </span>
  );
};
