import React from 'react';

import { TranslationProps } from '../entities';

interface TranslationContextProps {
  metadataTranslation: TranslationProps;
  stateMachineTranslation: TranslationProps;
  stateMachineLocale: string;
  addOrUpdateStateMachineTranslation: (key: string, value: string) => void;
  removeStateMachineTranslation: (key: string) => void;
  addOrUpdateMetaDataTranslation: (key: string, value: string) => void;
  removeMetaDataTranslation: (key: string) => void;
  setStateMachineLocale: (locale: string) => void;
  updateModuleWithTemplateTranslations: (
    templateId: string,
    newTranslationKeys: Record<string, string>[]
  ) => void;
}

const defaultState = {
  stateMachineTranslation: { default: '' },
  metadataTranslation: { default: '' },
  stateMachineLocale: 'DE',
  addOrUpdateStateMachineTranslation: () => {},
  addOrUpdateMetaDataTranslation: () => {},
  removeMetaDataTranslation: () => {},
  removeStateMachineTranslation: () => {},
  setStateMachineLocale: () => {},
  updateModuleWithTemplateTranslations: () => {},
};

export const TranslationContext =
  React.createContext<TranslationContextProps>(defaultState);
