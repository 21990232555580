import { generateRandomString } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  radioText1: {
    id: 'builder.nodes.radioButton.state.radioText1',
    defaultMessage: 'Erster Eintrag',
  },
  radioText2: {
    id: 'builder.nodes.radioButton.state.radioText2',
    defaultMessage: 'Zweiter Eintrag',
  },
  buttonText: {
    id: 'builder.nodes.radioButton.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

export const radioButtonStateEntry: StateEntryProps = {
  type: 'renderRadioButton',
  temporary: true,
  payload: {
    radioTexts: [
      'builder.nodes.radioButton.state.radioText1',
      'builder.nodes.radioButton.state.radioText2',
    ],
    keyTexts: [
      'radiokey.' + generateRandomString(4),
      'radiokey.' + generateRandomString(4),
    ],
    buttonText: 'builder.nodes.radioButton.state.buttonText',
    saveResultTo: 'radioButtonVar',
    keyNumbers: [0, 1], // PROD-1873
  },
  saveResultType: ReadWriteType.string,
  displayName: 'Radio Button',
  nodeType: 'radioButtonStateEntry',
  handleOutCount: 'dynamic',
};
