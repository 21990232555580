import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useIntl } from 'react-intl';

import { authorizedDelete } from '../../../api/authorizedApi';

export interface DeleteLanguageButtonProps {
  moduleid: string;
  defaultLanguage: string;
  languageToDelete: string;
  refetchModule: Function;
}

export const DeleteLanguageButton: React.FC<DeleteLanguageButtonProps> = ({
  moduleid,
  defaultLanguage,
  languageToDelete,
  refetchModule,
}) => {
  const intl = useIntl();
  const [deleteLanguageDialogOpen, setDeleteLanguageDialogOpen] =
    useState<boolean>(false);

  const deleteLanguage = () => {
    setDeleteLanguageDialogOpen(!deleteLanguageDialogOpen);
  };

  const onClose = async (doit: boolean) => {
    if (doit) {
      const deleteTranslation = authorizedDelete(
        `/module/${moduleid}/translation?language=${languageToDelete}`
      );
      try {
        await deleteTranslation();
      } catch (error: any) {
        // catch error to close dialog properly. Do nothing.
        console.error(error);
      }
      refetchModule();
    }
    setDeleteLanguageDialogOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => deleteLanguage()}
        key={languageToDelete + 'icob'}
        style={{ verticalAlign: 'top' }}
        disabled={defaultLanguage === languageToDelete}
      >
        <Tooltip
          placement="top"
          arrow
          title={intl.formatMessage({
            id: 'builder.translationeditor.deletelanguage.button',
            defaultMessage: 'Sprache aus dem Modul entfernen.',
          })}
          key={languageToDelete + 'del'}
        >
          <DeleteForeverIcon key={languageToDelete + 'delicon'} />
        </Tooltip>
      </IconButton>

      <Dialog fullWidth open={deleteLanguageDialogOpen}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'builder.translationeditor.deletelanguage.title',
            defaultMessage: 'Sprachversion löschen',
          })}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2" component="span">
              {intl.formatMessage({
                id: 'builder.translationeditor.deletelanguage.description',
                defaultMessage:
                  'Die Sprachversion wird aus dem Modul entfernt.',
              })}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose(false)}>
            {intl.formatMessage({
              id: 'builder.translationeditor.dialog.cancel',
              defaultMessage: 'Abbrechen',
            })}
          </Button>
          <Button color="secondary" onClick={() => onClose(true)}>
            {intl.formatMessage({
              id: 'builder.translationeditor.dialog.ok',
              defaultMessage: 'Ok',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
