import { Handle, NodeProps, Position } from 'reactflow';
import { YesNoButton } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { useGlobalStyles, translateKey } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  YesNoButton react-flow node for module builder graph canvas
*/

export const YesNoButtonNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;
  // note: the hanlde ids (like 'yeshandle') are used in hooks/useCreateModule=>mapStates
  // for recognizing the edges to this node and to create state transitions in the
  // statemachine

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <YesNoButton
          yesButtonText={translateKey(intl, payload.buttonTextYes)}
          noButtonText={translateKey(intl, payload.buttonTextNo)}
          saveResultTo={payload.saveResultTo}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="yeshandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '30%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        style={{ left: '70%' }}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
