import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  d: {
    id: 'setDirectedAgentModeStateEntry.description',
    defaultMessage:
      'Diese Komponente schaltet den "Directed Agent Mode" ein, mit dem alle Inhalte eines Chats den enthaltenen KI Komponenten als Chatverlauf zur Verfügung gestellt werden.',
  },
  directedAgentMode: {
    id: 'setDirectedAgentModeStateEntry.directedAgentMode',
    defaultMessage:
      'An = Chatverlauf wird an KI Komponenten weitergegeben, Aus = KI Komponenten kennen nur eigenen Chatverlauf',
  },
});

/**
 * Directed Agent Mode component
 *
 * It helps to enable or disable the directed agent mode. It has no
 * visible representation in the chatbot. It sets parameters in the
 * metadata of the session context
 */
export const setDirectedAgentModeStateEntry: StateEntryProps = {
  type: 'setDirectedAgentMode',
  temporary: false,
  payload: {
    directedAgentMode: false,
  },
  displayName: 'Set Directed Agent Mode',
  nodeType: 'setDirectedAgentModeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  addPercentage: false,
  handleOutCount: 1,
};
