import {
  CardImageSize,
  CardIndicatorIcon,
  CardSize,
  formatDate,
  ObjectAddCard,
  ObjectCard,
  ObjectCardHeaderMenu,
  ObjectCardHeaderMenuItemProps,
} from '@evoach/ui-components';
import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AccountContext, RoleEnum } from '../../../account';
import {
  authorizedDelete,
  authorizedGet,
  authorizedPost,
  authorizedPut,
  useFetchModule,
  useFetchModulesQuery,
} from '../../../api';
import { permissionAggregate } from '../../../api/module/permissionAggregate';
import { DevToolsContext } from '../../../devtools/DevToolsContext';
import {
  categoryTranslations,
  EditSession,
  ModuleCategoryEnum,
} from '../../../entities';
import {
  initialModule,
  ModuleProps,
  prepareInitialModule,
} from '../../../entities/Module';
import { ModulePermission } from '../../../entities/ModulePermissions';
import { getEnvironment } from '../../../environment/useEnvironment';
import { TranslationContext as GlobalTranslationContext } from '../../../intl/TranslationContext';
import { AppRoutes } from '../../../routing/routes';
import { EvoachSelect } from '../../customMaterialComponents';
import { sendErrorMail } from '../../ErrorBoundary';
import {
  LanguageSelectionHeightOptions,
  MetaDataEditorLanguageSelection,
} from '../../ModuleEditor/MetaDataEditorLanguageSelection';
import { startPreviewSession } from '../../ModuleEditor/ModulePreviewButton';
import { WindowContext } from '../../window/WindowContext';

import { ConfirmDeletionDialog } from './ConfirmDeletionDialogue';
import { CreateInvitationButton } from './CreateInvitationButton';
import { EditSessionDialog, EditSessionObjectType } from './EditSessionDialog';
import {
  NewObjectByJsonButton,
  NewObjectByJsonButtonTypeEnum,
} from './NewModuleByJsonButton';
import { NewModuleDialog } from './NewModuleDialog';
import { ShareLinkDialog } from './ShareLink';

const moduleTemplateTexts = defineMessages({
  title: {
    id: 'builder.reception.modules.initialmodule.title',
    defaultMessage: 'Neuer Chatbot',
  },
  description: {
    id: 'builder.reception.modules.initialmodule.description',
    defaultMessage:
      'Dieser Chatbot wurde mit dem evoach Chatbot Creator gebaut.',
  },
  duration: {
    id: 'builder.reception.modules.initialmodule.duration',
    defaultMessage: 'Wenige Minuten',
  },
});

const templateTemplateTexts = defineMessages({
  title: {
    id: 'builder.reception.modules.initialtemplate.title',
    defaultMessage: 'Neue Vorlage',
  },
  description: {
    id: 'builder.reception.modules.initialtemplate.description',
    defaultMessage:
      'Diese Vorlage wurde mit dem evoach Chatbot Creator gebaut.',
  },
  duration: {
    id: 'builder.reception.modules.initialtemplate.duration',
    defaultMessage: 'Wenige Minuten',
  },
});

enum ModuleFilterOptions {
  ALL = 0,
  OWN = 1,
  DEFAULT = 2,
  SHARED = 3,
  ARCHIVED = 4,
}

interface ModuleListWithCardsProps {
  templateMode?: boolean;
}

/**
 *
 * Component ModuleListWithCards renders list of all modules (templateMode = false)
 * or templates (templateMode = true) and allows filtering and searching modules
 *
 */
export const ModuleListWithCards: React.FC<ModuleListWithCardsProps> = ({
  templateMode = false,
}) => {
  const intl = useIntl();
  let navigate = useNavigate();
  const { locale: UIlocale } = useContext(GlobalTranslationContext);
  const { l } = useContext(DevToolsContext);
  const { playerBasePath } = getEnvironment();

  const { hasRole, account } = useContext(AccountContext);

  const [moduleFilter, setModuleFilter] = useState(ModuleFilterOptions.ALL);
  const [filterLocale, setFilterLocale] = useState<string>('all');

  const [isShareLinkDialogOpen, setDialogIsOpen] = useState<boolean>(false);

  const { modules, refetch: refetchModules } =
    useFetchModulesQuery(filterLocale);

  // catch window resize events
  const { clientHeight } = useContext(WindowContext);

  const localStorageHighlightKey = `evoach.creator.modules.highlighted`;

  const unhighlight = (moduleid: string) => {
    const newHighlightedModules = JSON.parse(
      localStorage.getItem(localStorageHighlightKey) ?? '[]'
    ).filter((module: any) => module.moduleid !== moduleid);
    localStorage.setItem(
      localStorageHighlightKey,
      JSON.stringify(newHighlightedModules)
    );
  };

  l('Rerender ModuleListWithCards ===============================');

  const rows = useMemo(() => {
    const getBadgeText = (moduleid: string): string => {
      const newBadgeText = intl.formatMessage({
        id: 'builder.reception.modulelist.cards.newbadgetext',
        defaultMessage: 'Neu',
      });
      const highlightedModules = JSON.parse(
        localStorage.getItem(localStorageHighlightKey) ?? '[]'
      );
      // if module is in localStorage & new -> add badge text
      return Array.isArray(highlightedModules)
        ? highlightedModules.some((module: any) => {
            const isModuleInHighlightedList = module.moduleid === moduleid; // 1 day
            const expirationTime = 86400000;
            const isModuleRecentlyAdded =
              Date.now() - module.highlightedDate < expirationTime;
            return isModuleInHighlightedList && isModuleRecentlyAdded;
          })
          ? newBadgeText
          : ''
        : '';
    };

    //
    // get the account(s) who own or
    //
    const getModuleProviderAccounts = (
      permissions: ModulePermission[],
      isDefaultModule: boolean
    ) => {
      const coaches: Record<string, any> = {};
      permissions
        .filter(
          (perm: ModulePermission) =>
            perm.owns ||
            (perm.invitinginvitationid !== null &&
              perm.invitinginvitationid !== undefined &&
              perm.invitinginvitationid !== '') ||
            isDefaultModule
        )
        .forEach((perm: ModulePermission) => {
          if (isDefaultModule || perm.owns) {
            coaches[perm.account.accountid] = perm.account;
          } else {
            coaches[perm.invitingaccount.accountid] = perm.invitingaccount;
          }
        });
      return coaches;
    };

    return modules
      ? modules
          .filter(
            (module: any) =>
              module && !!module.metadata && !!module.metadata.title
          )
          .filter((module: any) => templateMode === module.issubmodule)
          .map((module: ModuleProps, mindex: number) => {
            const permaggr = permissionAggregate(module);

            const moduleisshared =
              permaggr.invitinginvitationid !== undefined &&
              permaggr.invitinginvitationid !== null &&
              permaggr.invitinginvitationid !== '';

            // log key if necessary
            l(module.metadata.title);

            return {
              id: mindex,
              modulename: module.metadata.title,
              moduleid: module.moduleid,
              moduledescription: module.metadata.description,
              moduleduration: module.metadata.duration,
              modulecategories: module.metadata.categories,
              moduleimage: module.metadata.image,
              modulesupportedlang: module.metadata.moduleLanguages,
              moduledefaultlanguage: module.metadata.defaultLanguage,
              modulenumphases: Array.isArray(module.metadata.phases)
                ? module.metadata.phases.length
                : 0,
              modulepermissionsaggregate: permaggr,
              modulecreation: module.tscreated,
              moduleisdefault: module.isdefaultmodule,
              moduleisown: permaggr.owns,
              moduleisshared: moduleisshared,
              moduleresolvedsrc: module.metadata.resolvedsrc,
              moduleresolvedcoachlogosrc: module.metadata.resolvedCoachLogoSrc,
              moduleprovider:
                module.isdefaultmodule || permaggr.owns
                  ? permaggr.account.givenname +
                    ' ' +
                    permaggr.account.familyname
                  : moduleisshared && permaggr.invitingaccount
                  ? permaggr.invitingaccount.givenname +
                    ' ' +
                    permaggr.invitingaccount.familyname
                  : '',
              moduleorderingnumber: module.metadata.orderingnumber ?? 1000,
              badgetext: getBadgeText(module.moduleid),
              invitingaccounts: getModuleProviderAccounts(
                module.permissions,
                module.isdefaultmodule
              ),
            };
          })
          .filter((module: any) => {
            switch (moduleFilter) {
              case ModuleFilterOptions.ALL:
                return module.modulepermissionsaggregate.archived !== true;
              case ModuleFilterOptions.OWN:
                return (
                  module.modulepermissionsaggregate.archived !== true &&
                  module.moduleisown
                );
              case ModuleFilterOptions.DEFAULT:
                return (
                  module.modulepermissionsaggregate.archived !== true &&
                  module.moduleisdefault
                );
              case ModuleFilterOptions.SHARED:
                return (
                  module.modulepermissionsaggregate.archived !== true &&
                  module.moduleisshared
                );
              case ModuleFilterOptions.ARCHIVED:
                return module.modulepermissionsaggregate.archived === true;
              default:
                return true;
            }
          })
          .sort((elem1: any, elem2: any) =>
            elem1.modulecreation < elem2.modulecreation ? 1 : -1
          )
          .sort((a: any, b: any) => {
            return b.moduleorderingnumber - a.moduleorderingnumber;
          })
      : [];
  }, [modules, intl, localStorageHighlightKey, templateMode, l, moduleFilter]);

  const [newModuleDialogIsOpen, setNewModuleDialogIsOpen] =
    useState<boolean>(false);

  const newModuleDialogClose = (doit: boolean, language: string) => {
    if (doit && !(!language || language === '')) {
      addNewModule(language);
    }
    setNewModuleDialogIsOpen(false);
  };

  // add new module
  const addNewModule = (language: string) => {
    if (!language || language === '') return;
    //const templateMode = false;
    prepareInitialModule(templateMode);

    // set module or template metadata information according to UI language
    initialModule.translations[0].metadatatranslation[
      initialModule.metadata.title
    ] = templateMode
      ? intl.formatMessage(templateTemplateTexts.title)
      : intl.formatMessage(moduleTemplateTexts.title);

    initialModule.translations[0].metadatatranslation[
      initialModule.metadata.description
    ] = templateMode
      ? intl.formatMessage(templateTemplateTexts.description)
      : intl.formatMessage(moduleTemplateTexts.description);

    initialModule.translations[0].metadatatranslation[
      initialModule.metadata.duration
    ] = templateMode
      ? intl.formatMessage(templateTemplateTexts.duration)
      : intl.formatMessage(moduleTemplateTexts.duration);

    initialModule.translations[0].lang = language;
    initialModule.metadata.defaultLanguage = language;
    initialModule.metadata.moduleLanguages = [language];

    // create new module
    navigate(AppRoutes.NEWBUILDER);
  };

  /**
   * helper function to open editor for module with moduleid
   * @param {string} moduleid
   */
  const jumpToEditor = (moduleid: string) => {
    navigate(
      `${AppRoutes.BUILDER}/${moduleid}/${
        filterLocale !== 'all' ? filterLocale : UIlocale
      }`
    );
  };

  const [sessionDialog, setSessionDialog] = useState<number>(0);
  const [currentModuleId, setCurrentModuleId] = useState<string>('');
  /**
   * Switch to editor to edit module and check whether there are
   * other people editing!
   *
   * @param {string} moduleid
   */
  const editModule = (moduleid: string) => {
    if (!moduleid || moduleid === '') return;

    // check for session
    const apiUrl = `/editsession/${moduleid}?type=0`;

    const startSessionCall = authorizedGet(apiUrl);
    startSessionCall()
      .then((response: Response) => {
        // rememeber id
        setCurrentModuleId(moduleid);

        // check for existing sessions
        response.json().then((res: EditSession[]) => {
          if (res.length === 0) {
            // no pending sessions
            jumpToEditor(moduleid);
          } else {
            if (res.length === 1) {
              // check whether this is my session
              if (res[0].accountid === account?.accountid) {
                setSessionDialog(1); // current user has 1 other session
              } else {
                setSessionDialog(2); // other user has 1 other session
              }
            } else {
              // warning, more than one user!
              setSessionDialog(3);
            }
          }
        });
      })
      .catch((_reason: unknown) => {
        // log to console in case of error
        l(_reason);
        // error => open anyway
        setSessionDialog(4);
      });
  };

  // duplicate a module by moduleid
  const duplicateModule = async (moduleid: string) => {
    if (!moduleid || moduleid === '') return;
    const duplicateURL = `/module/${moduleid}`;
    const duplicateCall = authorizedPost(duplicateURL);
    duplicateCall()
      .then(() => {
        refetchModules();
      })
      .catch((reason: unknown) => {
        l(reason);
        sendErrorMail(
          reason,
          'triggered in duplicateModule in ModuleListWithCards.tsx'
        );
        alert(
          intl.formatMessage({
            id: 'builder.reception.modulelist.duplicationerror',
            defaultMessage: 'Beim Duplizieren ist ein Fehler aufgetreten.',
          })
        );
      });
  };

  const createInvitation = () => {
    setDialogIsOpen(false);
    // TODO update list of invitations if existing
  };

  const cancelCreation = () => {
    setDialogIsOpen(false);
  };

  const [selectedModuleForSharing, setSelectedModuleForSharing] = useState<any>(
    []
  );

  // share a module by moduleid
  const shareModule = (moduleid: string) => {
    if (!moduleid || moduleid.trim() === '') return;

    setSelectedModuleForSharing(
      rows
        .filter((module: any) => moduleid === module.moduleid)
        .map((module: any) => {
          return {
            ...module,
            modulename: intl.formatMessage({ id: module.modulename }),
          };
        })
    );
    setDialogIsOpen(true);
  };

  // context menu in a selection card clicked => route to translation management for this module
  const jumpToTranslationManagement = (moduleid: string) => {
    if (!moduleid || moduleid.trim() === '') return;
    navigate(
      `${AppRoutes.TRANSLATION}/${moduleid}/${
        filterLocale !== 'all' ? filterLocale : UIlocale
      }`
    );
  };

  // delete a module (not the invitation, s. below!)

  const [moduleIdForDeletion, setModuleIdForDeletion] = useState<string>('');

  const reallyDeleteModule = async () => {
    if (!moduleIdForDeletion || moduleIdForDeletion === '') return;
    const deletionURL: RequestInfo = `/module/${moduleIdForDeletion}`;
    const deletionCall = authorizedDelete(deletionURL);
    await deletionCall();
    refetchModules();
    setModuleIdForDeletion('');
  };

  const deleteModule = (moduleid: string) => {
    if (!moduleid || moduleid === '') return;
    setModuleIdForDeletion(moduleid);
  };

  const [modulePermissionId, setModulePermissionId] = useState<string>('');

  // delete a module (not the invitation, s. below!)
  const deleteModuleInvitation = (
    modulepermissionid: string,
    moduleid: string
  ) => {
    if (!modulepermissionid || modulepermissionid === '') return;
    if (!moduleid || moduleid === '') return;
    setModulePermissionId(modulepermissionid);
    setModuleIdForDeletion(moduleid);
  };

  const reallyDeleteModuleInvitation = async () => {
    if (!modulePermissionId || modulePermissionId === '') return;
    if (!moduleIdForDeletion || moduleIdForDeletion === '') return;
    const deletionURL: RequestInfo = `/module/${moduleIdForDeletion}/modulepermission/${modulePermissionId}`;
    const deletionCall = authorizedDelete(deletionURL);
    await deletionCall();
    refetchModules();
    setModulePermissionId('');
    setModuleIdForDeletion('');
  };

  // archive modulepermission to hide module
  const toggleArchiveModule = async (
    moduleid: string,
    permissionAggregate: ModulePermission
  ) => {
    permissionAggregate.archived = !permissionAggregate.archived;
    const updateModulePermissionURL = `/module/${moduleid}/modulepermission/${permissionAggregate.modulepermissionid}`;
    const updateCall = authorizedPut(
      updateModulePermissionURL,
      permissionAggregate
    );
    await updateCall();
    refetchModules();
  };

  // prepare data for CopyToClipboard
  const [moduleid, setModuleid] = useState<string>('');
  // complete module for JSON copy
  const { module: moduleForJsonCopy } = useFetchModule(moduleid);
  // JSON of module as string
  const [moduleJsonAsString, setmoduleJsonAsString] = useState<string>('');

  useEffect(() => {
    if (!moduleForJsonCopy) setmoduleJsonAsString('');
    setmoduleJsonAsString(JSON.stringify(moduleForJsonCopy));
  }, [moduleForJsonCopy]);

  const copyModuleToClipBoard = (moduleid: string) => {
    if (!moduleid || moduleid === '') return;
    setModuleid(moduleid);
  };

  // if copy string changes, trigger click on copytoclipboard component
  useEffect(() => {
    if (!moduleJsonAsString || moduleJsonAsString.trim() === '') return;
    navigator.clipboard.writeText(moduleJsonAsString).then(() => {
      alert(
        intl.formatMessage({
          id: 'builder.reception.modulelist.jsoncopied',
          defaultMessage:
            'Module/Vorlage wurde als JSON in die Zwischenablage kopiert.',
        })
      );
      setmoduleJsonAsString('');
    });
  }, [intl, moduleJsonAsString]);

  const [moduleSelection, setModuleSelection] = useState<string[]>([]);
  // add to select list
  const addToSelectList = (moduleid: string) => {
    if (!moduleid || moduleid === '') return;
    // if id already exists, then remove; if not, add
    if (moduleSelection.includes(moduleid)) {
      // remove
      setModuleSelection(
        moduleSelection.filter(
          (existingmoduleid: string) => existingmoduleid !== moduleid
        )
      );
    } else {
      // add
      setModuleSelection(moduleSelection.concat(moduleid));
    }
  };

  const cardSize = CardSize.SMALL;

  // height calc
  const maxHeight = clientHeight - 315 + 'px';

  // PROD-1829
  const [previewwindow, setpreviewwindow] = useState<any>(undefined);

  /**
   * Each card rendering is the same and thus, we have  special function
   * that returns the JSX for the card that is added to the list
   *
   * @param row  - as defined above
   * @returns
   */
  const getCardJSX = (row: any) => {
    const menuitems: ObjectCardHeaderMenuItemProps[] = [];

    const permaggr: ModulePermission = row.modulepermissionsaggregate;

    // Edit module
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.modulelist.cards.menuitems.edit',
        defaultMessage: 'Editieren',
      }),
      disabled: !(permaggr.owns || permaggr.CoachPermission_EditOriginalModule),
      onClick: () => {
        unhighlight(moduleid);
        editModule(row.moduleid);
      },
    });

    // Edit module
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.modulelist.cards.menuitems.preview',
        defaultMessage: 'Vorschau',
      }),
      disabled: false,
      onClick: () => {
        unhighlight(moduleid);
        startPreviewSession(
          row.moduleid,
          previewwindow,
          playerBasePath,
          setpreviewwindow,
          row.moduledefaultlanguage
        );
      },
    });

    // duplicate module
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.modulelist.cards.menuitems.duplicate',
        defaultMessage: 'Duplizieren',
      }),
      disabled: !(
        (
          permaggr.owns ||
          permaggr.CoachPermission_CopyModuleAsTemplate ||
          row.moduleisdefault
        ) // ! quickfix 12.01.23
      ),
      onClick: () => duplicateModule(row.moduleid),
    });

    // share module is only allowed for owner of a module
    // if the coach who receives an invitation to a module wants to share
    // that module again, he has to dupliacte it (with approriate rights)
    // and then share it. By that, the original module is preserved  andd
    // remains protected
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.modulelist.cards.menuitems.share',
        defaultMessage: 'Teilen',
      }),
      disabled: !permaggr.owns,
      onClick: () => shareModule(row.moduleid),
    });

    // delete module
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.modulelist.cards.menuitems.delete',
        defaultMessage: 'Löschen',
      }),
      disabled: !permaggr.owns,
      onClick: () => deleteModule(row.moduleid),
    });

    // delete invitation of a module
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.modulelist.cards.menuitems.deleteacceptedinvitation',
        defaultMessage: 'Aus Liste entfernen',
      }),
      disabled: !permaggr.invitinginvitationid,
      onClick: () =>
        deleteModuleInvitation(permaggr.modulepermissionid, row.moduleid),
    });

    // enabled for owners only
    menuitems.push({
      text: intl.formatMessage({
        id: 'builder.reception.modulelist.cards.menuitems.translationmanagement',
        defaultMessage: 'Übersetzungs-Management',
      }),
      disabled: !(permaggr.owns || permaggr.CoachPermission_EditOriginalModule),
      onClick: () => jumpToTranslationManagement(row.moduleid),
    });

    menuitems.push({
      text: row.modulepermissionsaggregate.archived
        ? intl.formatMessage({
            id: 'builder.reception.modulelist.cards.menuitems.unarchive',
            defaultMessage: 'Aus Archiv wiederherstellen',
          })
        : intl.formatMessage({
            id: 'builder.reception.modulelist.cards.menuitems.archive',
            defaultMessage: 'Archivieren',
          }),
      disabled: row.moduleisdefault,
      onClick: () =>
        toggleArchiveModule(row.moduleid, row.modulepermissionsaggregate),
    });

    if (hasRole(RoleEnum.EVOACHADMIN)) {
      menuitems.push({
        text: intl.formatMessage({
          id: 'builder.reception.modulelist.cards.menuitems.copymodule2clipboard',
          defaultMessage: 'Modul als JSON in Zwischenablage kopieren',
        }),
        disabled: false,
        onClick: () => copyModuleToClipBoard(row.moduleid),
      });
    }

    // delete invitation of a module
    menuitems.push({
      text:
        intl.formatMessage({
          id: 'builder.reception.modulelist.cards.menuitems.createdatthe',
          defaultMessage: 'Erstellt am',
        }) +
        ' ' +
        formatDate(row.modulecreation.toString(), intl.locale),
      disabled: true,
      onClick: () => {},
    });

    const modulename = intl.formatMessage({ id: row.modulename });

    return (
      <ObjectCard
        title={
          !row.modulepermissionsaggregate.archived
            ? modulename
            : intl.formatMessage({
                id: 'builder.reception.modulelist.cards.title.archived',
                defaultMessage: '(Archiviert)  ',
              }) + modulename
        }
        description={intl.formatMessage({
          id: row.moduledescription,
        })}
        duration={intl.formatMessage({
          id: row.moduleduration,
        })}
        createdByImages={row.moduleresolvedcoachlogosrc}
        labels={row.modulecategories?.map((cat: string) =>
          intl.formatMessage(categoryTranslations[cat as ModuleCategoryEnum])
        )}
        id={row.moduleid}
        image={row.moduleimage}
        imageSrc={row.moduleresolvedsrc}
        imageSize={CardImageSize.MEDIUM}
        cardSize={cardSize}
        badgeText={row.badgetext}
        indicator={
          row.moduleisdefault && !row.moduleisshared
            ? CardIndicatorIcon.DEFAULTMODULE
            : row.moduleisshared
            ? CardIndicatorIcon.SHAREDMODULE
            : CardIndicatorIcon.NONE
        }
        indicatorDefaultModuleText={intl.formatMessage({
          id: 'builder.reception.modulelist.cards.tooltip.defaultmodule',
          defaultMessage: 'Dieses Modul ist ein öffentlich verfügbares Modul.',
        })}
        indicatorSharedModuleText={intl.formatMessage({
          id: 'builder.reception.modulelist.cards.tooltip.sharedmodule',
          defaultMessage: 'Dieses Modul hast du über eine Einladung erhalten.',
        })}
        flags={row.modulesupportedlang}
        headermenu={
          <>
            <ObjectCardHeaderMenu
              id={row.moduleid}
              buttonTitle={
                !(permaggr.owns || permaggr.CoachPermission_EditOriginalModule)
                  ? undefined
                  : intl.formatMessage({
                      id: 'builder.reception.modulelist.cards.editmodule',
                      defaultMessage: 'Editieren',
                    })
              }
              onButtonClick={
                !(permaggr.owns || permaggr.CoachPermission_EditOriginalModule)
                  ? undefined
                  : (moduleid: string) => {
                      unhighlight(moduleid);
                      editModule(moduleid);
                    }
              }
              selectable={
                permaggr.owns ||
                permaggr.CoachPermission_InviteCoacheesToSessions ||
                row.moduleisdefault
              }
              onSelect={(moduleid: string) => {
                addToSelectList(moduleid);
              }}
              menuitems={menuitems}
            />
          </>
        }
      />
    );
  };

  const getAddCardJSX = () => {
    return (
      <ObjectAddCard
        label={
          !templateMode
            ? intl.formatMessage({
                id: 'builder.reception.modulelist.cards.addnewmodule',
                defaultMessage: 'Neuen Chatbot erstellen',
              })
            : intl.formatMessage({
                id: 'builder.reception.modulelist.cards.addnewtemplate',
                defaultMessage: 'Neues Template erstellen',
              })
        }
        onClick={() => setNewModuleDialogIsOpen(true)}
        cardSize={cardSize}
      />
    );
  };

  // enable filtering the modules by typing text
  const [filteredList, setFilteredList] = useState<any>(rows);

  useEffect(() => {
    setFilteredList(rows);
  }, [rows]);

  const searchCard = (text: string) => {
    setFilteredList(
      rows
        .map((row: any) => {
          if (
            intl
              .formatMessage({ id: row.modulename })
              .toLowerCase()
              .includes(text.toLowerCase()) ||
            intl
              .formatMessage({ id: row.moduledescription })
              .toLowerCase()
              .includes(text.toLowerCase()) ||
            (row.moduleprovider + '').toLowerCase().includes(text.toLowerCase())
          ) {
            return row;
          } else {
            return undefined;
          }
        })
        .filter((row: any) => row !== undefined)
    );
  };

  /*
  for grid layout, pls have a look at
  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Basic_Concepts_of_Grid_Layout
  */

  // NEW UX for filtering
  const onLanguageChange = (newfilterlocale: string) => {
    setFilterLocale(newfilterlocale);
    refetchModules();
  };

  return (
    <div
      style={{
        width: 'fit-content',
        height: maxHeight,
        maxHeight: maxHeight,
        padding: '10px',
      }}
    >
      <ConfirmDeletionDialog
        title={
          !templateMode
            ? intl.formatMessage({
                id: 'builder.reception.modulelist.cards.deletiontitle',
                defaultMessage: 'Modul löschen',
              })
            : intl.formatMessage({
                id: 'builder.reception.modulelist.cards.deletiontitletemplate',
                defaultMessage: 'Template löschen',
              })
        }
        open={moduleIdForDeletion !== ''}
        onConfirm={() => reallyDeleteModule()}
        onClose={() => setModuleIdForDeletion('')}
      />
      <ConfirmDeletionDialog
        title={
          !templateMode
            ? intl.formatMessage({
                id: 'builder.reception.modulelist.cards.deletioninvitaiontitle',
                defaultMessage: 'Modul aus Einladung entfernen',
              })
            : intl.formatMessage({
                id: 'builder.reception.modulelist.cards.deletioninvitaiontitletemplate',
                defaultMessage: 'Template aus Einladung entfernen',
              })
        }
        open={modulePermissionId !== ''}
        onConfirm={() => reallyDeleteModuleInvitation()}
        onClose={() => {
          setModulePermissionId('');
          setModuleIdForDeletion('');
        }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ paddingRight: '10px' }}
      >
        <Box display="flex" flexShrink={0}>
          <Box sx={{ minWidth: '10em' }}>
            <FormControl size="small">
              <InputLabel id="demo-simple-select-label">
                {intl.formatMessage({
                  id: 'builder.reception.modulelist.cards.modules',
                  defaultMessage: 'Chatbots',
                })}
              </InputLabel>
              <EvoachSelect
                value={moduleFilter}
                onChange={(event: SelectChangeEvent<unknown>) =>
                  setModuleFilter(event.target.value as number)
                }
                label="Chatbots"
                sx={{ minWidth: '10em' }}
              >
                <MenuItem value={ModuleFilterOptions.ALL}>
                  {intl.formatMessage({
                    id: 'builder.reception.modulelist.cards.modulefilter.all',
                    defaultMessage: 'Alle',
                  })}
                </MenuItem>
                <MenuItem value={ModuleFilterOptions.OWN}>
                  {intl.formatMessage({
                    id: 'builder.reception.modulelist.cards.modulefilter.own',
                    defaultMessage: 'Meine Chatbots',
                  })}
                </MenuItem>
                <MenuItem value={ModuleFilterOptions.DEFAULT}>
                  {intl.formatMessage({
                    id: 'builder.reception.modulelist.cards.modulefilter.default',
                    defaultMessage: 'Evoach Chatbots',
                  })}
                </MenuItem>
                <MenuItem value={ModuleFilterOptions.SHARED}>
                  {intl.formatMessage({
                    id: 'builder.reception.modulelist.cards.modulefilter.shared',
                    defaultMessage: 'Mit mir geteilt',
                  })}
                </MenuItem>
                <MenuItem value={ModuleFilterOptions.ARCHIVED}>
                  {intl.formatMessage({
                    id: 'builder.reception.modulelist.cards.modulefilter',
                    defaultMessage: 'Archiviert',
                  })}
                </MenuItem>
              </EvoachSelect>
            </FormControl>
          </Box>
          <Box marginLeft="0.5em" height="20px">
            <MetaDataEditorLanguageSelection
              onLanguageChange={onLanguageChange}
              moduleLocale={filterLocale}
              showAllOption
              displayLabel
              heightOption={LanguageSelectionHeightOptions.SMALL}
            />
          </Box>
          <Box marginLeft="1em">
            <Input
              sx={{ height: '100%' }}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                if (searchCard && e.target.value !== undefined) {
                  searchCard(e.target.value);
                }
              }}
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              }
              autoComplete="off"
              autoCorrect="off"
              placeholder={
                !templateMode
                  ? intl.formatMessage({
                      id: 'builder.reception.modulelist.cards.searchbox',
                      defaultMessage: 'Chatbot suchen ...',
                    })
                  : intl.formatMessage({
                      id: 'builder.reception.modulelist.cards.searchboxtemplate',
                      defaultMessage: 'Vorlage suchen ...',
                    })
              }
            />
          </Box>
        </Box>
        <Box flexShrink={0}>
          {hasRole(RoleEnum.EVOACHADMIN) && (
            <NewObjectByJsonButton
              mode={
                templateMode
                  ? NewObjectByJsonButtonTypeEnum.TEMPLATE
                  : NewObjectByJsonButtonTypeEnum.MODULE
              }
            />
          )}
          <CreateInvitationButton
            modules={rows}
            disabled={moduleSelection.length === 0}
            preSelectedModules={moduleSelection}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <Box
          style={{ marginTop: '20px', marginRight: '10px' }}
          key="objectcardlistdiv_add"
          component="div"
        >
          {getAddCardJSX()}
        </Box>
        {filteredList.map((row: any, index: number) => {
          //
          return (
            <Box
              style={{ marginTop: '20px', marginRight: '10px' }}
              key={'objectcardlistdiv' + row.moduleid + '_' + index}
              component="div"
            >
              {getCardJSX(row)}
            </Box>
          );
        })}
      </Box>
      <NewModuleDialog
        isOpen={newModuleDialogIsOpen}
        onClose={newModuleDialogClose}
      />
      <ShareLinkDialog
        open={isShareLinkDialogOpen}
        onCancel={cancelCreation}
        onCreate={createInvitation}
        modules={selectedModuleForSharing ?? []}
        forCoach={RoleEnum.COACH}
      />
      <EditSessionDialog
        dialogType={sessionDialog}
        isOpen={sessionDialog > 0}
        onClose={(startEditor: boolean) => {
          setSessionDialog(0);
          if (startEditor) {
            jumpToEditor(currentModuleId);
          }
          setCurrentModuleId('');
        }}
        editSessionObjectType={EditSessionObjectType.CHATBOT}
      />
    </div>
  );
};
