import { useMutation } from 'react-query';

import { Program, ProgramInstance } from '../../entities';
import { ProgramRouteTypeEnum } from '../../routing/routes';
import { authorizedPut } from '../authorizedApi';

export const useUpdateProgramOrInstance = (
  programId: string,
  programRouteType: ProgramRouteTypeEnum | undefined
) => {
  const { isLoading, data, isError, error, mutate } = useMutation(
    async (data: Partial<Program | ProgramInstance>) => {
      // return if no type is indicated

      if (!programRouteType || !programId || programId === '') {
        return {
          isLoading: false,
          isError: true,
          error: 'programRouteType undefined',
          mutate: () => {},
          program: undefined,
        };
      }

      let url = '';
      if (programRouteType === ProgramRouteTypeEnum.INSTANCE) {
        url = `/programinstance/${programId}`;
      } else {
        url = `/program/${programId}`;
      }

      const updateProgramCall = authorizedPut(url, {
        program: data.program,
        programtype: data.programtype,
        programmodules: data.programmodules,
      });
      const response = await updateProgramCall();
      const responseData = await response.json();
      return responseData;
    },
    {
      retry: false,
    }
  );

  if (programRouteType === ProgramRouteTypeEnum.INSTANCE) {
    return {
      isLoading,
      isError,
      error,
      mutate,
      program: data as ProgramInstance,
    };
  } else {
    return {
      isLoading,
      isError,
      error,
      mutate,
      program: data as Program,
    };
  }
};
