import { useMutation } from 'react-query';

import { authorizedPut } from '../authorizedApi';

export const useUpdateSubscription = (subscriptionid: string) => {
  const { isLoading, data, isError, error, mutate } = useMutation(
    async (data: { enddate: Date | null }) => {
      // return if no type is indicated

      if (!subscriptionid || subscriptionid === '') {
        return {
          isLoading: false,
          isError: true,
          error: 'subscriptionid undefined',
          mutate: () => {},
          program: undefined,
        };
      }

      const url = `/subscription/${subscriptionid}`;

      const updateSubscription = authorizedPut(url, { ...data });
      const response = await updateSubscription();
      const responseData = await response.json();
      return responseData;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    mutate,
    subscription: data,
  };
};
