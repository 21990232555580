import { useQuery } from 'react-query';

import { PrintTemplate } from '../../entities/PrintTemplatesListTypes';
import { authorizedGet } from '../authorizedApi';

/**
 * Returns a list of all available printing templates. This function
 * works only for accounts with evoachAdmin role! For other users it
 * would return undefined or an error (403)
 *
 * @returns {PrintTemplate[] | undefined} printTemplates - list of print templates PrintTemplate[]
 */
export const useFetchPrintTemplates = () => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    'printTemplates',
    async () => {
      const templUrl: RequestInfo = `/print/templates`;
      const getTemplates = authorizedGet(templUrl);
      const response = await getTemplates();
      return await response.json();
    },
    {
      retry: false,
    }
  );
  return {
    isLoading,
    isError,
    error,
    printTemplates: (data ?? []) as PrintTemplate[],
    refetch,
  };
};
