import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.polarChartDisplay.state.title',
    defaultMessage: 'Polar Chart Titel',
  },
});

export const polarChartStateEntry: StateEntryProps = {
  version: 'v2',
  type: 'renderPolarchartDisplay',
  temporary: false,
  payload: {
    series: [],
    labels: [],
    tickAmount: 10,
    getLabelsFrom: '',
    getSeriesFrom: '',
    title: 'builder.nodes.polarChartDisplay.state.title',
  },
  getValueType: [ReadWriteType.number, ReadWriteType.numberArray],
  displayName: 'Polar Chart',
  nodeType: 'polarChartStateEntry',
  handleOutCount: 1,
};

/* history
v2 
- add getLabelsFrom and getSeriesFrom to payload
- allow autogeneration of labels and values based on variables, e.g. from loops
v1 
- add labels and variables each manually
*/
