import { generateRandomString } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText1: {
    id: 'builder.nodes.selectionCard.state.selectionCardText1',
    defaultMessage: 'Erste Selection Card',
  },
  buttonText2: {
    id: 'builder.nodes.selectionCard.state.selectionCardText2',
    defaultMessage: 'Zweite Selection Card',
  },
});

/**
 * v1 = init
 * v2 = introduce keyNumbers // PROD-1406
 */
export const selectionCardStateEntry: StateEntryProps = {
  version: 2,
  type: 'renderSelectionCard',
  temporary: true,
  payload: {
    buttonTexts: [
      'builder.nodes.selectionCard.state.selectionCardText1',
      'builder.nodes.selectionCard.state.selectionCardText2',
    ],
    saveResultTo: 'selectionCard_result',
    keyTexts: [
      'selectionCard.' + generateRandomString(4),
      'selectionCard.' + generateRandomString(4),
    ],
    displayInColums: false,
    keyNumbers: [0, 1], // PROD-1406
  },
  saveResultType: [ReadWriteType.string],
  displayName: 'Selection Card',
  nodeType: 'selectionCardStateEntry',
  handleOutCount: 'dynamic',
};
