import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  modulelist: {
    id: 'builder.nodes.navigatebutton.state.tomodulelist',
    defaultMessage: 'Zurück zur Liste deiner Module',
  },
  sessionlist: {
    id: 'builder.nodes.navigatebutton.state.tosessionlist',
    defaultMessage: 'Zurück zur Liste deiner Chatbot Sessions',
  },
  programlist: {
    id: 'builder.nodes.navigatebutton.state.tomprogramlist',
    defaultMessage: 'Zurück zur Liste deiner Coaching Programme',
  },
});

export enum NavigateTargetEnum {
  MODULELIST,
  PROGRAMLIST,
  SESSIONLIST,
}
export const navigateButtonStateEntry: StateEntryProps = {
  type: 'renderNavigateButton',
  temporary: false,
  payload: {
    buttonText: 'builder.nodes.navigatebutton.state.tomodulelist',
    navigateTarget: NavigateTargetEnum.MODULELIST,
  },
  displayName: 'Navigate Button',
  nodeType: 'navigateButtonStateEntry',
  handleOutCount: 1,
};
