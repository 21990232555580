import { ModuleProps } from '../../entities/Module';
import { ModulePermission } from '../../entities/ModulePermissions';

/**
 * permissionAggregate - combines all permissions of a single module to one
 * permissions object by OR - combining rights. This is useful if a module
 * is available through different invitations with different rights. Then,
 * the maximum rights as a combination of rights is permitted
 * @param {Module} module - module to be checked for permissions
 * @return {ModulePermission} - returns a single permission object
 */
export const permissionAggregate = (
  module: ModuleProps | undefined
): ModulePermission => {
  if (module === undefined) {
    return {
      CoachPermission_CopyModuleAsTemplate: false,
      CoachPermission_EditOriginalModule: false,
      CoacheePermission_StartSession: false,
      CoachPermission_InviteCoacheesToSessions: false,
      archived: false,
      owns: false,
      invitinginvitationid: '',
    } as ModulePermission;
  }

  return module.permissions.reduce(
    (aggr: ModulePermission, perm: ModulePermission) => {
      aggr.CoachPermission_CopyModuleAsTemplate =
        aggr.CoachPermission_CopyModuleAsTemplate ||
        (perm.CoachPermission_CopyModuleAsTemplate ?? false);

      aggr.CoachPermission_EditOriginalModule =
        aggr.CoachPermission_EditOriginalModule ||
        (perm.CoachPermission_EditOriginalModule ?? false);

      aggr.CoachPermission_InviteCoacheesToSessions =
        aggr.CoachPermission_InviteCoacheesToSessions ||
        (perm.CoachPermission_InviteCoacheesToSessions ?? false);

      aggr.CoacheePermission_StartSession =
        aggr.CoacheePermission_StartSession ||
        (perm.CoacheePermission_StartSession ?? false);

      aggr.owns = aggr.owns || (perm.owns ?? false);

      aggr.archived = aggr.archived || (perm.archived ?? false);

      aggr.invitinginvitationid =
        perm.invitinginvitationid ?? aggr.invitinginvitationid;

      aggr.modulepermissionid =
        perm.modulepermissionid ?? aggr.modulepermissionid;

      //aggr.account = perm.account ?? aggr.account;
      aggr.invitingaccount =
        !aggr.invitingaccount && perm.invitingaccount
          ? perm.invitingaccount
          : aggr.invitingaccount;
      // keep all other values untouched
      return aggr;
    },
    module.permissions[0]
  );
};
