import { Edge, Node } from 'reactflow';
import { cloneDeep } from 'lodash';

import {
  StateMachine,
  State,
  StateEntryProps,
  isTranslatedStatePayloadProp,
  isTranslatedStateEntryProp,
  EmotionProps,
  InvokeStateProps,
} from '../nodes';
import { initialModule, ModuleProps } from '../../entities/Module';
import { MetaDataProps, ModulePhase } from '../../entities/MetaData';
import { TranslationProps } from '../../entities/ModuleTranslation';

import {
  isNodeExisting,
  findAnyStartNode,
  findPhaseStartNode,
  getOutgoingEdges,
} from './moduleHelper';
import { ReactFlowElements } from './ReactFlowHelper';
import {
  getHelperStatesForExternalCallMultipleOut,
  getHelperStatesForExternalCallSingleOut,
  getHelperStatesForMicroChat,
  getHelperStatesForNewSession,
} from './moduleUtils.helperStates';

/**
 * addTargetToSourceNodeData
 *
 * Note: this function was necessary with React Flow 9 and was called in
 * ModuleEditorPane "onNodesConnect". With the re-factoring for React Flow 10
 * we didn't find any use for it.
 * Especially the state machine handling with { next: newEdge.target }
 * seems not to be necessary any more and is included in the generateModule
 * process in moduleUtils
 *
 * This function is currently not used. We keep it for reference
 *
 * @param {Edge} newEdge
 * @param {Node[]} nodes
 * @returns
 */
export const addTargetToSourceNodeData = (
  newEdge: Edge<any>,
  nodes: Node<any>[]
): ReactFlowElements => {
  if (!newEdge.source || !newEdge.target) {
    console.warn("Can't create new edge. An edge needs a source and a target.");
    return nodes;
  }

  const foundSourceNode = nodes.find((node) => newEdge.source === node.id);

  if (!foundSourceNode) {
    console.warn(
      `Node with id=${newEdge.source} does not exist, and can't be updated`
    );
    return nodes;
  } else {
    // fix state machine
    foundSourceNode.data.state.on = { next: newEdge.target };
    return nodes
      .filter((node) => node.id !== foundSourceNode.id)
      .concat(foundSourceNode);
  }
};

/**
 * checks whether all target nodes of a on-branching in the statemachine exist
 * if they note exist, the concerning key is deleted. This is to prevent
 * the state machine from running into an error when branching to an
 * unknown state
 *
 * @param {State} stateEntry entry fo whioch the on-keys are checked
 * @param {Node<any>[]} nodes node list of the nodes to be checked against
 */
const checkTargetNodeIds = (stateEntry: State, nodes: Node<any>[]) => {
  if (stateEntry && stateEntry.on && Object.keys(stateEntry.on).length > 0) {
    Object.keys(stateEntry.on)
      .filter(
        (key: string) => key !== 'writeToContext' && key !== 'setNavigation'
      )
      .forEach((key: string) => {
        if (stateEntry.on && !isNodeExisting(stateEntry.on[key], nodes)) {
          delete stateEntry.on[key];
        }
      });
  }
};

/**
 * translate react-flow graph (nodes and edges) to xState state machine (states and state transitions)
 * also change statekey to new node.ids that were initialized when dragging objects or templates to the graph
 *
 * @see generateModule
 * @param {Node<any>[]} nodes array of all nodes in the graph
 * @param {Edge<any>[]} edges array of all edged in the graph
 * @return {States} object with all states of a statemachine
 */
type HelperStatesType = {
  [k: string]: InvokeStateProps | State;
};

const mapStates = (nodes: Node<any>[], edges: Edge<any>[]) => {
  const helperStates: HelperStatesType = {};

  const states = nodes.map((node: Node<any>) => {
    const stateEntry: State = node.data.state as State;

    // fix statekeys ==> the node id may change! And statekeys refer
    // to nodeids ==> nodeid and statekey have to be identical
    stateEntry.stateKey = node.id;

    // reset always transitions to re-generate transitions
    // completely
    stateEntry.always = undefined;

    // do not store description in machine. It's used in creator only and is
    // retrieved from existing elements
    // TODO it would be less memory consing not to save the description key,
    // TODO but we leave it here as it alters the stateEntry and with the
    // TODO next reload, no description is shown
    //delete stateEntry.entry[0]['description'];

    // ... by looking at all edges that are outgoing from this node ...
    let found = false;
    edges
      .filter((edge) => edge.source === node.id)
      .forEach((edge) => {
        // ... and process them for each sourceHandle in case that there are is more than one handle.
        mapEdges(stateEntry, edge.sourceHandle, edge.target);
        found = true;
      });

    // the last node is not covered with the previous statement and is missed in the generated state machine
    // check whether the node has outgoing edges in the list of edges (found=true), if not, add it as
    // last node without a specific target; in that case, the source handle doesn't matter and is unkown
    if (!found) {
      mapEdges(stateEntry, 'unkownsourcehandle', '');
    }

    // check for each node whether all targetNodeIds in stateentry.on
    // exists. If not, set to empty.
    // This case can happen if a node was removed ==> then, the
    // handles in the state machine were not updated by the
    // removed edge
    checkTargetNodeIds(stateEntry, nodes);

    // if we have a "StartNewSessionState", we have to model additional
    // intermediate states to handle async requests in state machine
    // in Player (s. PROD-1124)
    // In addtion, we have to inject these new states in bewetween the
    // states connected in the previous steps.
    // A => B and A => C  becomes A => D => E => B, etc.

    if (node.data.nodeType === 'startNewSessionStateEntry') {
      // add three helper states

      if (node.data.state.on) {
        if (!node.data.state.on.no) {
          node.data.state.on.no = { target: '' };
        }
        if (!node.data.state.on.yes) {
          node.data.state.on.yes = { target: '' };
        }
        node.data.helperStates = getHelperStatesForNewSession(
          node.id,
          node.data.state.on.no.target ?? '',
          node.data.state.on.yes.target ?? ''
        );
      }
      // if helperstates are available:
      // 1) prepare for later merge with other states
      // 2) adapt target of current node to new helper states
      if (node.data.helperStates && node.data.helperStates.length > 0) {
        // 1) prepare new invoke states for later merge with standard states
        node.data.helperStates.forEach((state: InvokeStateProps) => {
          helperStates[state.stateKey] = state;
        });

        // 2) adapt targets of current node and inject new states
        // targets node ids are pre-defined based on current node.id
        node.data.state.on.no.target = node.id + '_setGrantPermission'; // perm only
        node.data.state.on.yes.target =
          node.id + '_setGrantPermissionAndSession'; // perm + session;
      }
    } else {
      // standard components do not have any helperStates.
      node.data.helperStates = {};
    }

    //
    // helper states for states with async and long running external API calls
    //
    if (
      [
        'paraphraseNodeStateEntry',
        'singleAnswerPromptStateEntry',
        'freeTextPromptStateEntry',
        'aiSummarizeStateEntry',
        'ailistPromptStateEntry',
      ].includes(node.data.nodeType)
    ) {
      //
      //get helper states
      node.data.helperStates = getHelperStatesForExternalCallSingleOut(
        node.id,
        node.data.state.on.next
      );
      // if helperstates are available:
      // 1) prepare for later merge with other states
      // 2) adapt target of current node to new helper states
      if (node.data.helperStates && node.data.helperStates.length > 0) {
        // 1) prepare new invoke states for later merge with standard states
        node.data.helperStates.forEach((state: InvokeStateProps | State) => {
          helperStates[state.stateKey] = state;
        });
      }
    } else {
      // standard components do not have any helperStates.
      node.data.helperStates = {};
    }

    //
    // AI classification helper states that consider multiple outputs
    //
    if (
      [
        'aiSentimentNodeStateEntry',
        'aiClassificationNodeStateEntry',
        'aiGenericClassificationStateEntry',
      ].includes(node.data.nodeType)
    ) {
      // for sentiment, we didn't generate random handle keys(ids) but use
      // fixed handle names. In order to generate proper helper states, we
      // need keyTexts, so we pass them here.
      if (
        !node.data.state.entry[0].payload.keyTexts &&
        node.data.nodeType === 'aiSentimentNodeStateEntry'
      ) {
        // set keytexts to key names
        node.data.state.entry[0].payload.keyTexts = [
          'positivesentimenthandle',
          'neutralsentimenthandle',
          'negativesentimenthandle',
        ];
      }
      // add three helper states

      node.data.helperStates = getHelperStatesForExternalCallMultipleOut(
        node.id,
        node.data.state.on.next,
        node.data.state.entry[0].payload.keyTexts,
        getOutgoingEdges(node, edges),
        node.data.nodeType
      );

      // if helperstates are available:
      // 1) prepare for later merge with other states
      // 2) adapt target of current node to new helper states
      if (node.data.helperStates && node.data.helperStates.length > 0) {
        // 1) prepare new invoke states for later merge with standard states
        node.data.helperStates.forEach((state: InvokeStateProps) => {
          helperStates[state.stateKey] = state;
        });
      }
    } else {
      // standard components do not have any helperStates.
      node.data.helperStates = {};
    }

    if (
      [
        'aiMicrochatStateEntry',
        'freeMicrochatPromptStateEntry',
        'aiDocumentChatStateEntry',
      ].includes(node.data.nodeType)
    ) {
      //
      //get helper states
      node.data.helperStates = getHelperStatesForMicroChat(
        node.id,
        node.data.state.on.next
      );
      // if helperstates are available:
      // 1) prepare for later merge with other states
      // 2) adapt target of current node to new helper states
      if (node.data.helperStates && node.data.helperStates.length > 0) {
        // 1) prepare new invoke states for later merge with standard states
        node.data.helperStates.forEach((state: InvokeStateProps | State) => {
          helperStates[state.stateKey] = state;
        });
      }
    } else {
      // standard components do not have any helperStates.
      node.data.helperStates = {};
    }

    // Then: return modified stateentry
    return [[stateEntry.stateKey, stateEntry]];
  });

  const listOfStates = Object.fromEntries(states.flat());

  // merge helper states (=special invoke states) with other states
  Object.keys(helperStates).forEach((key: string) => {
    listOfStates[helperStates[key].stateKey] = helperStates[key];
  });

  // check transitions for each node ...

  return listOfStates;
};

/**
 * checks whether a node is a phase node by looking at its internal type name
 *
 * @param {Node<any>} node - node to be checked
 * @return {boolean} true = is phase not, false = is not a phase node
 */
const isNodePhase = (node: Node<any>) =>
  !node ? false : node.type?.startsWith('phase');

/**
 * extract all phases from a builder graph and extract all nodes that describe phases in the correct order
 *
 * @param {Node[]} nodes list of nodes to be checked
 * @param {Edge<any>[]} edges list of edges to walk through the graph
 * @return {ModulePhase[]} return a list of module phases
 */
export const extractPhases = (nodes: Node[], edges: Edge<any>[]) => {
  let startnodeid = findPhaseStartNode(nodes);
  if (startnodeid === '') {
    startnodeid = findAnyStartNode(nodes, edges);
  }

  let phaseList: ModulePhase[] = [];
  let visitedNodes: string[] = [];

  const getOutgoingEdges = (nodeid: string) => {
    if (!nodeid) return [];
    return edges.filter((edge: Edge<any>) => edge.source === nodeid);
  };

  const getNode = (nodeid: string | null) => {
    if (!nodeid) return [];
    return nodes.filter((node: Node<any>) => node.id === nodeid);
  };

  // search phases recursively by deep search in graph;
  // walk thorugh graph node by node by follwoing all edges
  const searchPhases = (edges: Edge<any>[]) => {
    edges.forEach((edge: Edge<any>) => {
      const tnodes = getNode(edge.target);
      tnodes.forEach((node: Node<any>) => {
        // may be a loop! if node was already visited, ignore it
        if (!visitedNodes.includes(node.id)) {
          if (isNodePhase(node)) {
            phaseList.push({
              reference: node.id as string,
              text: node.data.state.entry[0].payload.phasetext as string,
            } as ModulePhase);
            // also set phase entry for state machine
            node.data.state.entry[0].payload.phase = node.id;
          }
          // remember all visited nodes
          visitedNodes.push(node.id);
          searchPhases(getOutgoingEdges(node.id));
        }
      });
    });
    return;
  };

  const startnode = getNode(startnodeid)[0];
  if (isNodePhase(startnode)) {
    phaseList.push({
      reference: startnode.id,
      text: startnode.data.state.entry[0].payload.phasetext,
    } as ModulePhase);
    // also set phase entry for state machine
    startnode.data.state.entry[0].payload.phase = startnode.id;
  }

  searchPhases(getOutgoingEdges(startnodeid));

  return phaseList;
};

/** generate module from graph in a format that contains the state machine, metadata and translations
 * In this step, the React-Flow grapg is translated into an xState state machine
 *
 * @param {Node<any>[]} nodes list of all nodes in the builder graph
 * @param {Edge<any>[]} edges list of all edges in the builder graph
 * @param {TranslationProps} statemachinetranslation all translation information for the state machine elements
 * @param {TranslationProps} metadatatranslation all translation information for the metadata (like phases, title, etc.)
 * @param {Module} module current state of the module - if not yet generated, take an empty stub as default
 */
export const generateModule = (
  nodes: Node<any>[],
  edges: Edge<any>[],
  statemachinetranslation: TranslationProps,
  metadatatranslation: TranslationProps,
  module: ModuleProps = initialModule
) => {
  // fix statekeys ==> the node id may change! And statekeys refer
  // to nodeids ==> nodeid and statekey have to be identical
  nodes = nodes.map((node: Node) => {
    node.data.state.stateKey = node.id;
    return node;
  });
  //const edges = elements.filter(isEdge) as Edge<any>[];

  // remove edges left in the machine that are not connected
  // these may be present due to
  // edges = cleanUpEdges(nodes, edges);

  // create new statemachine
  const stateMachine = new StateMachine();

  // find start node, try STart Phase node first
  stateMachine.definition.initial = findAnyStartNode(nodes, edges);
  // map edges generates all states with echos, conditional transitions, etc.
  stateMachine.definition.states = mapStates(nodes, edges);

  module.statemachine = stateMachine;

  // activate to cleanup for existing modules - PROD -1172
  statemachinetranslation = cleanUpTranslations(
    stateMachine,
    cloneDeep(statemachinetranslation)
  );

  module.metadata.phases = extractPhases(nodes, edges);

  const addTranslationToMetadataTranslation = (phase: ModulePhase): void => {
    metadatatranslation[phase.text] = statemachinetranslation[phase.text];
  };

  module.metadata.phases.forEach(addTranslationToMetadataTranslation);

  // fix for PROD-1644
  // clean up unsued translation keys / in it due to bugs like in PROD-1772
  metadatatranslation = cleanUpMetadataTranslations(
    module.metadata,
    metadatatranslation
  );

  module.translations[0].statemachinetranslation = statemachinetranslation;
  module.translations[0].metadatatranslation = metadatatranslation;

  module.buildergraph = (nodes as ReactFlowElements).concat(edges);

  return module;
};

/**
 * remove ghost egdes that are not visible but somehow left in the buildergraph
 *
 * @param {Node[]} nodes to be checked against
 * @param {Edge<any>[]} edges to be checked
 * @returns {Edge<any>[]} cleaned-up list of edges
 */
export const cleanUpEdges = (
  nodes: Node[],
  edges: Edge<any>[]
): Edge<any>[] => {
  const listOfAllNodeIds = nodes.map((node: Node<any>) => node.id);

  let myedges = cloneDeep(edges);

  // collect all edge ids that are not properly connected
  // or that contain non-existing handles
  const sourceIdsOfUnconnectedEdges = myedges
    .filter(
      (edge: Edge<any>) =>
        !listOfAllNodeIds.includes(edge.source) ||
        !listOfAllNodeIds.includes(edge.target)
    )
    .map((edge: Edge<any>) => edge.id);

  // remove all elements previously collected
  myedges = myedges.filter(
    (edge: Edge<any>) => !sourceIdsOfUnconnectedEdges.includes(edge.id)
  );

  return myedges;
};

/**
 * cleanUpTranslations
 *
 * delete all entries in statemachinetranslation that are not anywhere in the
 * stateMachine. This is very important, as we do not delete translations in the
 * translation context of the module when we remove nodes!
 *
 * @param {StateMachine} stateMachine is iterated to determine all used translation keys
 * @param {TranslationProps} statemachinetranslation is adapted by removing all entries that can not be found in stateMachine
 * @return {TranslationProps} returns a cleaned-up state machine translation
 */
export const cleanUpTranslations = (
  stateMachine: StateMachine,
  statemachinetranslation: TranslationProps
): TranslationProps => {
  // 1. collect all keys that are used in current stateMachine

  statemachinetranslation['testkey'] = 'hallo';

  const usedTranslationKeys: string[] = [];
  // check all states
  Object.keys(stateMachine.definition.states).forEach((statekey: string) => {
    // check whether entry is available. An entry may be undefine for
    // the special "invoke" states where external services are called. These
    // states do not have to be processed for translateable properties.
    if (stateMachine.definition.states[statekey].entry === undefined) {
      return;
    } else {
      // and all entry sections in each state

      const stateEntry = stateMachine.definition.states[statekey].entry;

      // if entry has no stateEntry, it is an InvokeType which was
      // introduced for PROD-1124; in that case, we to not have to
      // clean up translation
      if (
        stateEntry === undefined ||
        (Array.isArray(stateEntry) && stateEntry.length === 0)
      ) {
        return;
      }
      stateEntry.forEach((entry: StateEntryProps) => {
        // find the payload
        if (entry.payload) {
          // check all payload parameters
          Object.keys(entry.payload).forEach((payloadEntryKey: string) => {
            // but only for translateable values
            if (isTranslatedStatePayloadProp(payloadEntryKey)) {
              // if string, then take it
              if (typeof entry.payload[payloadEntryKey] === 'string') {
                usedTranslationKeys.push(
                  entry.payload[payloadEntryKey] as string
                );
              } else {
                // if array, then go deepter
                if (
                  entry.payload[payloadEntryKey] &&
                  Array.isArray(entry.payload[payloadEntryKey])
                ) {
                  const arrayList = entry.payload[
                    payloadEntryKey
                  ] as Array<any>;
                  arrayList.forEach((_subkey: string, index: number) => {
                    // array of strings
                    if (typeof arrayList[index] === 'string') {
                      usedTranslationKeys.push(arrayList[index] as string);
                    } else {
                      // array of objects
                      usedTranslationKeys.push(
                        arrayList[index].value as string
                      );

                      // if we are in a hot or not selector, the description prop
                      // contains also translations
                      if (arrayList[index].description !== undefined) {
                        usedTranslationKeys.push(
                          arrayList[index].description as string
                        );
                      }

                      // imageSelector props like src and assetid
                      if (
                        arrayList[index].src !== undefined &&
                        arrayList[index].assetid !== undefined
                      ) {
                        usedTranslationKeys.push(
                          arrayList[index].src as string
                        );
                        usedTranslationKeys.push(
                          arrayList[index].assetid as string
                        );
                      }
                    }
                  });
                }
              }
            }
          }); // iterate state entry payload entries
        }

        // check also for certain entry properties that are only interesting
        // for the builder and not for the payload of the player
        Object.keys(entry).forEach((stateEntryKey: string) => {
          // but only for translateable values
          if (
            isTranslatedStateEntryProp(stateEntryKey) &&
            entry[stateEntryKey] !== undefined
          ) {
            if (Array.isArray(entry[stateEntryKey])) {
              if (stateEntryKey === 'ownElements') {
                (entry[stateEntryKey] as EmotionProps[]).forEach(
                  (element: any) => {
                    if (element.value !== undefined) {
                      usedTranslationKeys.push(element.value as string);
                    }
                  }
                );
              }
            } else {
              if (entry[stateEntryKey] !== undefined)
                usedTranslationKeys.push(entry[stateEntryKey] as string);
            }
          }
        }); // iterate state entry entries
      });
      // iterate all entries in a state
    }
  }); // iterate all states

  // 2. find and delete all keys that are in statemachinetranslation but not in state machine
  Object.keys(statemachinetranslation).forEach((transkey: string) => {
    if (!usedTranslationKeys.includes(transkey)) {
      delete statemachinetranslation[transkey];
    }
  });

  // 3. return cleaned-up state machine
  return statemachinetranslation;
};

/**
 * mapEdges to xState state machine transition and add echos for components
 * this function performs several steps:
 * 1) add connections between states for conditional transitions depending on the handles
 * 2) add time based transitions for echo elements, e.g. for CoachMessage
 * 3) add auto-transiitions for special nodes, e.g. for Phases
 *
 * handle names in edge.sourceHandle can be found in the .node. implementation of a component in this project
 * stateentry trigger names (like ["on"]["yes"]) can be found in ui-components in the implementation
 * of the actual React component for the chat bot. Trigger like "yes" are directly send in the component while
 * send is the xState command to send a trigger
 *
 * Important: we assume, that the first entry in a state is the element to render (s. [0] below)
 * add percentage and phase handling as entries after(!) finishing this stateMapper
 * TODO this may not be true in future ...
 *
 * interpretation of these state modifications takes place in player app (assume it parallel on hard disk to builder app)
 * at file://./../../../../player/app/src/components/ModuleMachine.tsx
 *
 * @param {State} stateEntry stateentry that is modified in this call
 * @param {*} sourceHandle name of handle for the egde, may differ for different output handles of one node
 * @param {string} targetNodeId target node of edge
 * @return {State} modified stateEntry
 */
const mapEdges = (
  stateEntry: State,
  sourceHandle: any,
  targetNodeId: string
): State => {
  if (false && stateEntry.entry[0].type === 'loopEnd') {
    console.info(stateEntry);
    console.info('type:  ' + stateEntry.entry[0].type);
    console.info('sourceHandle:  ' + sourceHandle);
    console.info('targetNodeId:  ' + targetNodeId);
  }
  const payload = stateEntry.entry[0].payload;
  const currentSaveResultTo = payload.saveResultTo;

  // make sure that there is an on section, even if it is undefined
  // make sure that it is empty if undefined and remains as-is otherwise
  if (stateEntry['on'] === undefined) {
    stateEntry['on'] = {};
  }

  // if current entry has getValuesFrom, then get rid of potential null and undefined
  // values in the array. This happens in existing modules sometimes. But is already
  // fixed, but in database are some old modules that contain the bug. So clean it up here.
  const currentGetValuesFrom = payload.getValuesFrom;
  if (
    currentGetValuesFrom &&
    currentGetValuesFrom !== null &&
    Array.isArray(currentGetValuesFrom)
  ) {
    stateEntry.entry[0].payload.getValuesFrom = currentGetValuesFrom.filter(
      (val: string) => val !== null && val !== undefined
    );
  }

  // start processing entry
  switch (stateEntry.entry[0].type) {
    case 'renderCharacterSelector':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.entry[0].temporary = true;
      stateEntry.exit = [
        {
          type: 'renderCharacterSelector',
          payload: {
            getValuesFrom: [currentSaveResultTo + ''],
            isEcho: true,
          },
        },
      ];
      break;
    case 'renderHotOrNotSelector':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.entry[0].temporary = true;
      stateEntry.exit = [
        {
          type: 'renderValueCardList',
          payload: {
            getValueFrom: currentSaveResultTo,
            cardsPerRow: 2,
          },
        },
      ];
      break;
    case 'renderImageSelector':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.entry[0].temporary = true;
      stateEntry.exit = [
        {
          type: 'renderImageSelectorDisplay',
          payload: {
            getValueFrom: currentSaveResultTo,
            isEcho: true,
            showTitles: stateEntry.entry[0].payload.showTitles,
            componentSize: stateEntry.entry[0].payload.componentSize,
          },
        },
      ];
      break;
    case 'renderNavigateButton':
      stateEntry.on.next = targetNodeId;
      stateEntry.on['writeToContext'] = {
        actions: 'writeToContext',
      };
      stateEntry.on['setNavigation'] = {
        actions: 'setNavigation',
      };
      stateEntry.on.next = targetNodeId;

      stateEntry.entry[0].temporary = false;
      break;
    case 'renderDownloadButton':
    case 'renderBinarySorterDisplay':
    case 'renderImageSelectorDisplay':
      stateEntry.entry[0].temporary = false;
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
        },
      ];
      break;
    case 'renderBinarySorter':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.entry[0].temporary = true;
      stateEntry.exit = [
        {
          type: 'renderBinarySorterDisplay',
          payload: {
            getValueFrom: currentSaveResultTo,
            leftTitle: stateEntry.entry[0].payload.leftTitle,
            rightTitle: stateEntry.entry[0].payload.rightTitle,
            isEcho: true,
          },
        },
      ];
      break;
    // Render coach message
    case 'renderRandomCoachMessage':
      stateEntry.on[sourceHandle as string] = targetNodeId;
      if (stateEntry.always === undefined) {
        stateEntry['always'] = [];
      }

      if (targetNodeId && targetNodeId !== '') {
        stateEntry.always.push({
          target: targetNodeId,
          cond: {
            type: 'randomNodeTransition',
            targetNodeId: targetNodeId,
            sourceHandle: sourceHandle,
            saveResultTo: currentSaveResultTo + '',
          },
        });
      }

      stateEntry.exit = [
        {
          type: 'renderCoachMessage',
          payload: {
            getValuesFrom: [currentSaveResultTo ?? '0'],
          },
        },
      ];
      break;

    // Mae, 03.03.2023
    case 'renderYesNoButton':
      if (sourceHandle === 'yeshandle') {
        stateEntry.on.yes = {
          target: targetNodeId,
          actions: [],
        };
      } else {
        stateEntry.on.no = {
          target: targetNodeId,
          actions: [],
        };
      }
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];
      break;
    // Actions are used here to share a session with the coach. This pattern
    // can be used to generate all kinds of side effects.
    // See: https://xstate.js.org/docs/guides/actions.html#api
    case 'renderShareSession':
      if (sourceHandle === 'yeshandle') {
        stateEntry.on.yes = {
          target: targetNodeId,
          actions: ['setShareSession'],
        };
      } else {
        stateEntry.on.no = targetNodeId;
      }
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: 'evoachechokey.sharingDecision',
          },
        },
      ];
      break;

    // PROD-1686 - add echo for next button
    case 'renderNextButton':
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.on.next = targetNodeId;
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: 'evoach.interactiveecho',
          },
        },
      ];
      break;

    //
    // PROD-1124 - start a new session directly from a session
    case 'renderStartNewSession':
      if (sourceHandle === 'yeshandle') {
        stateEntry.on.yes = {
          target: targetNodeId,
          actions: [],
        };
      } else {
        stateEntry.on.no = {
          target: targetNodeId,
          actions: [],
        };
      }
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];
      break;
    //
    // send Mail node
    case 'renderSendMail':
      if (sourceHandle === 'yeshandle') {
        stateEntry.on.yes = {
          target: targetNodeId,
          actions: ['setSendMail'],
        };
      } else {
        stateEntry.on.no = targetNodeId;
      }
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: 'evoachechokey.sendMailDecision',
          },
        },
      ];
      break;
    case 'renderCreateCertificate':
      // trigger certificate via action setCreateCertificate
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
          actions: 'setCreateCertificate',
        },
      ];
      stateEntry.exit = [
        {
          type: 'renderCoachMessage',
          payload: {
            message: stateEntry.entry[0].payload.message,
          },
        },
      ];
      break;
    case 'renderCalenderExportComposite':
      stateEntry.on.next = targetNodeId;
      stateEntry.entry[0].temporary = true;
      stateEntry.exit = [
        {
          type: 'renderCalenderExportComposite',
          payload: {
            getValueFrom: stateEntry.entry[0].payload.getValueFrom,
            message: stateEntry.entry[0].payload.message,
            linkButtonText: stateEntry.entry[0].payload.linkButtonText,
            isEcho: true,
          },
        },
      ];
      break;
    case 'renderMoodInput':
      if (sourceHandle === 'badhandle') {
        stateEntry.on.bad = targetNodeId;
        stateEntry.on.very_bad = targetNodeId;
      }
      if (sourceHandle === 'neutralhandle') {
        stateEntry.on.neutral = targetNodeId;
      }
      if (sourceHandle === 'goodhandle') {
        stateEntry.on.good = targetNodeId;
        stateEntry.on.very_good = targetNodeId;
      }

      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };

      // after Mood Input print an echo with a Mood icon;
      // refering to the variable from the inital mood elements
      stateEntry.exit = [
        {
          type: 'renderMoodIcon',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];

      break;
    case 'sendExternalSentiment':
      // this on.next is required to retrieve the targetNodeId
      // when creating helper states. It is not used in state machine
      stateEntry.on.next = targetNodeId;

      if (sourceHandle === 'positivesentimenthandle') {
        stateEntry.on.positive = targetNodeId;
      }
      if (sourceHandle === 'neutralsentimenthandle') {
        stateEntry.on.neutral = targetNodeId;
      }
      if (sourceHandle === 'goodsentimenthandle') {
        stateEntry.on.negative = targetNodeId;
      }

      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };

      stateEntry.exit = [];

      // when entering this state, immediatly hand over control to helper states
      // s. getHelperStatesForExternalCall
      stateEntry.after = [
        {
          delay: 0,
          target: stateEntry.stateKey + '_initialExternalCall',
        },
      ];
      break;

    case 'renderInitMicrochat':
      stateEntry.exit = [];

      // !important
      // this on.next is required to retrieve the targetNodeId
      // when creating helper states. It is not used in state machine
      stateEntry.on.next = targetNodeId + '';

      stateEntry.after = [
        {
          delay: 0,
          target: stateEntry.stateKey + '_initialMicrochatExternalCall',
        },
      ];
      break;

    case 'sendExternal':
      stateEntry.exit = [];
      // this on.next is required to retrieve the targetNodeId
      // when creating helper states. It is not used in state machine
      stateEntry.on.next = targetNodeId;
      // when entering this state, immediatly hand over control to helper states
      // s. getHelperStatesForExternalCall
      stateEntry.after = [
        {
          delay: 0,
          target: stateEntry.stateKey + '_initialExternalCall',
        },
      ];
      break;
    // in case of a phase, create an autotransition and do not render anything
    case 'setDirectedAgentMode':
    case 'renderValueCardList':
    case 'renderAudioDisplay':
    case 'renderImageDisplay':
    case 'renderVideoDisplay':
    case 'setAvatarImage':
      stateEntry.after = [
        {
          delay: 500, // if set to 0, the avatar is not displayed properly
          target: targetNodeId,
        },
      ];
      break;
    case 'renderSceneCanvas':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.entry[0].temporary = true;
      stateEntry.exit = [
        {
          type: 'renderSceneCanvas',
          payload: {
            getValueFrom: currentSaveResultTo,
            isEcho: true,
          },
        },
      ];

      //isEcho: payload.isEcho,
      break;
    case 'renderCanvasEditor':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.entry[0].temporary = true;
      stateEntry.exit = [
        {
          type: 'renderCanvasEditor',
          payload: {
            getValueFrom: currentSaveResultTo,
            isEcho: true,
          },
        },
      ];

      //isEcho: payload.isEcho,
      break;
    case 'renderCanvasDisplay':
      stateEntry.entry[0].temporary = false;
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
        },
      ];
      //isEcho: payload.isEcho,
      break;
    case 'renderPostItDisplay':
      // fix bug for existing modules
      stateEntry.entry[0].temporary = false;
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
        },
      ];
      break;
    case 'setProgressPhase':
      // PROD-1493 - remove unnecessary on
      delete stateEntry.on;
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
        },
      ];
      break;

    // for a Coach message, generate an auto-transition that depends on
    // the length of the text displayed; text length factor and threedot
    // indictaor, s. Player App
    case 'renderSingleDisplay':
    case 'renderCoachMessage':
      stateEntry.after = {
        MESSAGE_LENGHT_BASED_DELAY: {
          target: targetNodeId,
        },
      };
      delete stateEntry['on'];
      break;
    case 'renderMultipleInput':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };

      // make sure that a proper integer is set for minItems / maxItems
      payload.minItems =
        typeof payload.minItems === 'string' && payload.minItems === ''
          ? 0
          : parseInt(payload.minItems + '');
      payload.maxItems =
        typeof payload.maxItems === 'string' && payload.maxItems === ''
          ? 100
          : parseInt(payload.maxItems + '');

      // echo all inputs as separate coacheemessages when exiting
      stateEntry.exit = [
        {
          type: 'renderCoachMessage',
          payload: {
            message: payload.message,
          },
        },
        {
          type: 'renderCoacheeMessages',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];

      break;
    case 'renderMultipleInputSingle':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };

      // make sure that a proper integer is set for minItems / maxItems
      payload.minItems =
        typeof payload.minItems === 'string' && payload.minItems === ''
          ? 0
          : parseInt(payload.minItems + '');
      payload.maxItems =
        typeof payload.maxItems === 'string' && payload.maxItems === ''
          ? 100
          : parseInt(payload.maxItems + '');

      // echo all inputs as separate coacheemessages when exiting
      // same like renderMultipleInput, but without message
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessages',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];

      break;
    case 'renderEmotionInputComposite':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };

      // make sure that a proper integer is set for minItems / maxItems
      payload.minItems =
        typeof payload.minItems === 'string' && payload.minItems === ''
          ? 0
          : parseInt(payload.minItems + '');
      payload.maxItems =
        typeof payload.maxItems === 'string' && payload.maxItems === ''
          ? 100
          : parseInt(payload.maxItems + '');

      // echo all inputs as separate coacheemessages when exiting
      stateEntry.exit = [
        {
          type: 'renderCoachMessage',
          payload: {
            message: stateEntry.entry[0].payload.message,
          },
        },
        {
          type: 'renderEmotionIcons',
          payload: {
            getValueFrom: currentSaveResultTo,
            objectPropertyContainingMessage: 'value',
          },
        },
      ];
      break;
    case 'renderEditableMultiselect':
    case 'renderNeedsInput':
    case 'renderValueSelector':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };

      // make sure that a proper integer is set for minItems / maxItems
      payload.minItems =
        typeof payload.minItems === 'string' && payload.minItems === ''
          ? 0
          : parseInt(payload.minItems + '');
      payload.maxItems =
        typeof payload.maxItems === 'string' && payload.maxItems === ''
          ? 100
          : parseInt(payload.maxItems + '');

      // echo all inputs as separate coacheemessages when exiting
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessages',
          payload: {
            getValueFrom: currentSaveResultTo,
            objectPropertyContainingMessage: 'value',
          },
        },
      ];

      break;

    case 'renderMultiplePercentageScaleInput':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      // render multiple Coachee Messages as output for echo
      // the correspondig variable is generated in UI-components
      // when sending the content
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessages',
          payload: {
            getValueFrom: currentSaveResultTo + '_ECHO',
          },
        },
      ];
      break;

    // after message Input print an echo with a CoacheeMessage;
    // adopt variable name from inital element
    case 'renderFormattedInput':
    case 'renderMessageInput':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];
      break;
    // after Post It Input print an echo with a PostIt Displays;
    // adopt variable name from inital element player/app/src/components/ModuleMachine.tsx
    case 'renderPostItInput':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.exit = [
        {
          type: 'renderPostItDisplay',
          payload: {
            title: stateEntry.entry[0].payload.title,
            headline: stateEntry.entry[0].payload.headline,
            getValueFrom: currentSaveResultTo,
            isEcho: true,
          },
        },
      ];
      break;
    case 'setMultipleCompareNumbers':
      // sourceHandle is based on keyTexts + 'mulitcompare.final'
      stateEntry.on[sourceHandle as string] = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      // the Multiple Compare component triggers an action that does the actual
      // compare and stores the target node in the context in a avariable.
      // After 10 ms, the next action triggers a send command to that target node
      // with the sendToTargetAfterMultipleCompareNumbers action
      // All actions are implemented in Player in compareActions.ts
      stateEntry.after = [
        {
          delay: 10,
          actions: 'sendToTargetAfterMultipleCompareNumbers',
        },
      ];
      break;
    case 'renderScaleInputMulti':
      // sourceHandle is translation key!
      // fix invalid next / PROD-1493
      if (sourceHandle.startsWith('keytext.')) {
        stateEntry.on.next = targetNodeId;
      }
      stateEntry.on[sourceHandle as string] = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      // echo for scale input with a coachee messsage ==> value, only, no text
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];
      break;
    case 'renderSelectionCard':
    case 'renderRadioButton':
    case 'renderMultiButton':
      // sourceHandle is translation key!
      stateEntry.on[sourceHandle as string] = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      // echo for scale input with a coachee messsage ==> value, only, no text
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];
      break;
    case 'renderScaleButton':
    case 'renderScaleInput':
    case 'renderPercentageScaleInput':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      // echo for scale input with a coachee messsage ==> value, only, no text
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];
      break;
    case 'renderMultipleOutputComposite':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };

      // make sure that a proper integer is set for minItems / maxItems
      payload.minItems =
        typeof payload.minItems === 'string' && payload.minItems === ''
          ? 0
          : parseInt(payload.minItems + '');
      payload.maxItems =
        typeof payload.maxItems === 'string' && payload.maxItems === ''
          ? 100
          : parseInt(payload.maxItems + '');

      // echo
      stateEntry.exit = [
        {
          type: 'renderCoachMessage',
          payload: {
            message: stateEntry.entry[0].payload.message,
          },
        },
        {
          type: 'renderCoacheeMessages',
          payload: {
            getValueFrom: currentSaveResultTo,
            objectPropertyContainingMessage: 'value',
          },
        },
      ];
      break;
    case 'renderRatingInput':
      if (sourceHandle === 'nexthandle') {
        // choose next of no rating is selected
        stateEntry.on.next = targetNodeId;
      } else {
        const star = sourceHandle.split('.')[1];
        // the 'rating'+star property is set via the send-command
        // in the onclick handler of the button within the control
        // pls refer to ui-components
        stateEntry.on['rating' + star] = targetNodeId;
      }
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };

      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: currentSaveResultTo,
          },
        },
      ];
      break;
    case 'renderTimeInput':
    case 'renderDateInput':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.exit = [
        {
          type: 'renderCoacheeMessage',
          payload: {
            getValueFrom: currentSaveResultTo,
            objectPropertyContainingMessage: 'value',
          },
        },
      ];
      break;
    case 'renderMultipleDisplayComposite':
      stateEntry.on.next = targetNodeId;
      stateEntry.exit = [
        {
          type: 'renderMultipleDisplayComposite',
          payload: {
            getValueFrom: stateEntry.entry[0].payload.getValueFrom,
            message: stateEntry.entry[0].payload.message,
            messages: stateEntry.entry[0].payload.messages,
            buttonText: stateEntry.entry[0].payload.buttonText,
            bubbleType: stateEntry.entry[0].payload.bubbleType,
            isEcho: true,
          },
          temporary: false,
        },
      ];
      break;
    case 'renderPolarchartInput':
      stateEntry.on.next = targetNodeId;
      stateEntry.on.writeToContext = {
        actions: 'writeToContext',
      };
      stateEntry.exit = [
        {
          type: 'renderPolarchartDisplay',
          payload: {
            getLabelsFrom: stateEntry.entry[0].payload.getLabelsFrom,
            title: stateEntry.entry[0].payload.title,
            tickAmount: stateEntry.entry[0].payload.tickAmount,
            width: stateEntry.entry[0].payload.width,
            series: stateEntry.entry[0].payload.series,
            labels: stateEntry.entry[0].payload.labels,
            getValuesFrom: [stateEntry.entry[0].payload.saveResultTo + ''],
          },
        },
      ];
      break;
    case 'renderLinkButton':
    case 'renderPolarchartDisplay':
    case 'renderRadarchartDisplay':
    case 'renderCopyLink':
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
        },
      ];
      break;

    case 'setCombineToString': // CombineToString node
    case 'setFormula': // calc formula and proceed
    case 'setCombineToArray': // ArrayCombine nodes
    case 'setStringArray': // DefineStringArray node
    case 'loopStart':
      stateEntry.on.next = targetNodeId;
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
        },
      ];
      break;
    case 'setSendMail': // SendMail node
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
        },
      ];
      // TODO what do we do with the feedback after sending a mail?
      break;
    case 'setCreateCertificate': // CreateCertificate node
      stateEntry.after = [
        {
          delay: 0,
          target: targetNodeId,
        },
      ];
      break;
    case 'checkVariable':
      stateEntry.on[sourceHandle as string] = targetNodeId;
      if (stateEntry.always === undefined) {
        stateEntry['always'] = [];
      }

      let checkVarGuard = '';
      // handle that jumps depending on comparison
      if (sourceHandle === 'checkvarouthandle_set') {
        checkVarGuard = 'setCheckGuard';
      }
      if (sourceHandle === 'checkvarouthandle_notset') {
        checkVarGuard = 'unSetCheckGuard';
      }

      const checkVarAlwaysIndex = stateEntry.always.reduce(
        (
          foundindex: number | undefined,
          value: Record<string, any>,
          currentIndex: number
        ) => (value.cond === checkVarGuard ? currentIndex : foundindex),
        undefined
      );

      if (checkVarAlwaysIndex !== undefined) {
        stateEntry.always[checkVarAlwaysIndex] = {
          target: targetNodeId,
          cond: checkVarGuard,
        };
      } else {
        if (checkVarGuard !== '') {
          stateEntry.always.push({
            target: targetNodeId,
            cond: checkVarGuard,
          });
        }
      }

      break;
    case 'setCompareNumbers':
      stateEntry.on[sourceHandle as string] = targetNodeId;
      if (stateEntry.always === undefined) {
        stateEntry['always'] = [];
      }

      let compareSearchGuard = '';
      // handle that jumps depending on comparison
      if (sourceHandle === 'comparenumbersout1') {
        compareSearchGuard = 'smallerCompareGuard';
      }
      if (sourceHandle === 'comparenumbersout2') {
        compareSearchGuard = 'equalCompareGuard';
      }
      if (sourceHandle === 'comparenumbersout3') {
        compareSearchGuard = 'biggerCompareGuard';
      }
      const compareNumbersAlwaysIndex = stateEntry.always.reduce(
        (
          foundindex: number | undefined,
          value: Record<string, any>,
          currentIndex: number
        ) => (value.cond === compareSearchGuard ? currentIndex : foundindex),
        undefined
      );

      if (compareNumbersAlwaysIndex !== undefined) {
        stateEntry.always[compareNumbersAlwaysIndex] = {
          target: targetNodeId,
          cond: compareSearchGuard,
        };
      } else {
        if (compareSearchGuard !== '') {
          stateEntry.always.push({
            target: targetNodeId,
            cond: compareSearchGuard,
          });
        }
      }
      break;
    case 'loopEnd':
      stateEntry.on.next = targetNodeId;
      // if stateEntry doesn't exist or is complete, then reset
      if (stateEntry.always === undefined) {
        stateEntry['always'] = [];
      }

      let searchGuard = '';
      // handle that jumps back to start node
      if (sourceHandle === 'loopout') {
        searchGuard = 'loopNextGuard';
      }
      if (sourceHandle === 'loopendouthandle') {
        searchGuard = 'loopEndGuard';
      }
      const alwaysindex = stateEntry.always.reduce(
        (
          foundindex: number | undefined,
          alwaysentry: Record<string, any>,
          currentIndex: number
        ) => (alwaysentry.cond === searchGuard ? currentIndex : foundindex),
        undefined
      );
      if (alwaysindex !== undefined) {
        stateEntry.always[alwaysindex] = {
          target: targetNodeId,
          cond: searchGuard,
        };
      } else {
        if (searchGuard !== '') {
          stateEntry.always.push({ target: targetNodeId, cond: searchGuard });
        }
      }

      break;
    // if there is no explicit trigger (e.g. for messages), we simply trigger "next"
    default:
      stateEntry.on.next = targetNodeId;
      break;
  }

  return stateEntry;
};

/**
 * cleanUpMetadataTranslations
 *
 * delete all entries in metadatatranslation that are not anywhere in the
 * metadata, i.e. phase or title, duration and description
 *
 * @param {MetaDataProps} metadata is iterated to determine all used translation keys
 * @param {TranslationProps} metadatatranslation is adapted by removing all entries that can not be found in stateMachine
 * @return {TranslationProps} returns a cleaned-up state machine translation
 */
export const cleanUpMetadataTranslations = (
  metadata: MetaDataProps,
  metadatatranslation: TranslationProps
): TranslationProps => {
  // 1. collect all keys that are used in current stateMachine

  let listOfUsedKeys: string[] = [];

  // collect keys used
  listOfUsedKeys.push(metadata.title);
  listOfUsedKeys.push(metadata.description);
  listOfUsedKeys.push(metadata.duration);
  if (metadata.phases && metadata.phases.length > 0) {
    metadata.phases.forEach((phase: ModulePhase) => {
      listOfUsedKeys.push(phase.text);
    });
  }

  // filter undefined
  listOfUsedKeys = listOfUsedKeys.filter((key) => key && key !== '');

  // remove unsed from metadatatranslation
  Object.keys(metadatatranslation).forEach((key: string) => {
    if (!listOfUsedKeys.includes(key)) {
      console.info(key + ' was deleted');

      delete metadatatranslation[key];
    }
  });

  // return cleaned up list
  return metadatatranslation;
};
