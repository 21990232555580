import React, { useContext, useState } from 'react';

import { AccountContext, RoleEnum } from '../account';

interface DevToolsContextProps {
  devToolsActivated: boolean;
  setDevToolsActivated: (newValue: boolean) => void;
  l: (message: any) => void;
}

export const DevToolsContext = React.createContext<DevToolsContextProps>({
  devToolsActivated: false,
  setDevToolsActivated: () => null,
  l: () => null,
});

export type DevToolsContextProviderProps = {
  children?: React.ReactNode;
};
export const DevToolsContextProvider: React.FC<
  DevToolsContextProviderProps
> = ({ children }) => {
  const { hasRole } = useContext(AccountContext);

  const devToolsActivatedStateByLocalStorage = localStorage.getItem(
    'devToolsActivatedStateByLocalStorage'
  );

  const [devToolsActivatedState, setDevToolsActivatedState] = useState(
    devToolsActivatedStateByLocalStorage === 'true'
  );

  localStorage.setItem(
    'devToolsActivatedStateByLocalStorage',
    (devToolsActivatedState ?? false) + ''
  );

  const l = (message: any) => {
    if (devToolsActivatedState || hasRole(RoleEnum.SUPPORT)) {
      console.log(message);
    }
  };

  return (
    <DevToolsContext.Provider
      value={{
        devToolsActivated: devToolsActivatedState,
        setDevToolsActivated: setDevToolsActivatedState,
        l: l,
      }}
    >
      {children}
    </DevToolsContext.Provider>
  );
};
