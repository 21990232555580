import { Typography } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';

import { authorizedPost } from '../api/authorizedApi';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * Generic ErrorBoundary component for React
 *
 * general ErrorBoundary docs can be find
 * @see https://reactjs.org/docs/error-boundaries.html
 *
 * for this Typescript version
 * @see https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/
 *
 * If you want to test an ErrorBoundary, you have to throw an exception.
 * !Important: exceptions encoded in event handlers (e.g. for a button onClick are not catched)!
 * Throw an execption like:
 *  throw new Error('my error message)
 */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    sendErrorMail(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Typography variant="body1">
          Sorry.. there was an error. We already informed our development team.
          Please reload page.
        </Typography>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

/**
 * send about the error to evoach dev
 *
 * @param {any} error
 * @param {any} errorInfo
 */
export const sendErrorMail = (error: any, errorInfo: any) => {
  console.error('Error boundary catched:', error, errorInfo);

  /*
    PROD-1933 
    
    navigator.userAgent

    Firefox reports:
    Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:105.0) Gecko/20100101 Firefox/105.0

    Chrome reports
    Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/110.0.0.0 Safari/537.36
    */
  const payload = {
    error: error,
    errorInfo: errorInfo,
    navigatorInfo: navigator?.userAgent ?? 'no navigator.userAgent',
  };
  const mailUrl = '/mail/29c2f247-f0a0-4f14-bfc8-fccd852558e1/fromtoken';
  // check whether we run locally => dev => do not send mails
  const host = window.location.host;
  // do not send mail in dev mode
  if (!host.startsWith('localhost')) {
    const sendMailNodeRequest = authorizedPost(mailUrl, payload);
    sendMailNodeRequest()
      .then((_res: unknown) => {})
      .catch((err: unknown) => console.log(err));
  } else {
    console.log('would have sent a mail on STAGING and PROD, but not on local');
  }
};
