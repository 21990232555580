import { CardImageEnum } from '@evoach/ui-components';
import { Add, Cancel, CheckCircle, Remove } from '@mui/icons-material';
import {
  Box,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListSubheader,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

//import { RoleEnum } from '../../account';
//import { AccountContext } from '../../account/AccountContext';
import { resolveProgramModuleAssets } from '../../api/program/useFetchProgram';
import { ProgramModule } from '../../entities/Program';
import { useEnvironment } from '../../environment/useEnvironment';
import { ProgramRouteTypeEnum } from '../../routing/routes';
import { EvoachMenuItem, EvoachSelect } from '../customMaterialComponents';
import ErrorBoundary from '../ErrorBoundary';
//import { MetaDataEditorImageSelect } from '../ModuleEditor/MetaDataEditorImageSelect';
import { AssetType } from '../PropertiesSidebar/assetHelper';
import { AssetMenu } from '../PropertiesSidebar/VariableInputs/AssetMenu';
import { mapImageToPublicAsset } from '../reception/assets/AssetHelper';

import { ProgramModuleActionEdit } from './ProgramModuleActionEdit';

/*
  ProgramEditorPage

  Pls check call hierarchy with responsibilities below

  ProgramEditorPage                     - page for router, check route params, load module
    |_ ProgramEditor                    - basic layout and mutate function, manage selected module
        |_ ProgramMetaEditor            - edit basic information for a program
        |_ ProgramModuleListTimeline    - edit timeline / order of modules
!       |_ ProgramModuleEditor          - edit a selected module

 */

export type ProgramModuleEditorProps = {
  selectedModule: ProgramModule | undefined;
  moduleGroups: (string | undefined)[];
  onChange: Function;
  programRouteType?: ProgramRouteTypeEnum;
};

export const ProgramModuleEditor: React.FC<ProgramModuleEditorProps> = ({
  selectedModule,
  moduleGroups,
  onChange,
  programRouteType = ProgramRouteTypeEnum.PROGRAM,
}) => {
  const intl = useIntl();
  //const { hasRole } = useContext(AccountContext);
  const { playerBasePath } = useEnvironment();

  const [programModuleDescription, setProgramModuleDescription] =
    useState<string>(selectedModule?.description ?? '');
  const [programModuleTitle, setProgramModuleTitle] = useState<string>(
    selectedModule?.title ?? ''
  );
  const [programModuleGroup, setProgramModuleGroup] = useState<string>(
    selectedModule?.group ?? ''
  );
  const [programModuleDuration, setProgramModuleDuration] = useState<string>(
    selectedModule?.duration ?? ''
  );
  const [programModuleDateOffset, setProgramModuleDateOffset] =
    useState<number>(selectedModule?.dateOffset ?? 0);

  /* const [programModuleImage, setProgramModuleImage] = useState<CardImageEnum>(
    selectedModule?.image ?? CardImageEnum.balloons
  ); */

  const [showNewGroupField, setShowNewGroupField] = useState(false);

  // update state if selected module changes
  useEffect(() => {
    if (!selectedModule) return;
    setProgramModuleDescription(selectedModule.description);
    setProgramModuleTitle(selectedModule.title);
    setProgramModuleDuration(selectedModule.duration);
    setProgramModuleGroup(selectedModule.group ?? '');
    setProgramModuleDateOffset(selectedModule.dateOffset);
    //setProgramModuleImage(selectedModule.image);
    setProgramModuleStartDate(selectedModule.startDate ?? new Date());
  }, [selectedModule]);

  const [programModuleStartDate, setProgramModuleStartDate] = useState<Date>(
    () => {
      if (
        programRouteType === ProgramRouteTypeEnum.INSTANCE &&
        selectedModule
      ) {
        if (selectedModule.startDate) {
          return selectedModule.startDate;
        } else {
          if (selectedModule.dateOffset && selectedModule.dateOffset > 0) {
            const nowSec = Date.now() + selectedModule.dateOffset * 3600 * 24;
            return new Date(nowSec);
          }
        }
      }
      return new Date();
    }
  );

  //
  // proxy to update the payload value.
  //
  const localUpdateAssetValue = (
    val: string | undefined | CardImageEnum,
    key: 'assetid' | 'src' | 'image'
  ) => {
    if (!selectedModule) return;

    if (key === 'image') {
      selectedModule[key] = val as CardImageEnum;
    } else {
      selectedModule[key] = val;
    }

    if (key === 'src') {
      selectedModule['assetid'] = '';
    }
    resolveProgramModuleAssets(selectedModule).then(() => {
      onChange();
    });
  };

  if (!selectedModule) {
    return (
      <Typography>
        {intl.formatMessage({
          id: 'builder.programs.programmoduleditor.noseelction',
          defaultMessage:
            'Bitte erstelle links ein Programmmodul oder klicke auf ein Programmmodul aus der Liste.',
        })}
      </Typography>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{ padding: '1.5em', maxHeight: '93vh', overflow: 'auto' }}
    >
      <Grid container spacing={2} overflow="auto">
        <Grid item xs={6}>
          <TextField
            value={programModuleTitle}
            fullWidth
            onBlur={() => onChange()}
            label={intl.formatMessage({
              id: 'builder.programs.programmoduleditor.labels.title',
              defaultMessage: 'Titel',
            })}
            onChange={(evt: any) => {
              setProgramModuleTitle(evt.target.value);
              selectedModule.title = evt.target.value;
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {!showNewGroupField && (
            <FormControl fullWidth>
              <InputLabel id="select-module-group">
                {intl.formatMessage({
                  id: 'builder.programs.programmoduleditor.labels.group',
                  defaultMessage: 'Modulgruppe',
                })}
              </InputLabel>
              <EvoachSelect
                labelId="select-module-group"
                id="select-module-group"
                label="Module group"
                value={programModuleGroup}
                onChange={(evt: any) => {
                  setProgramModuleGroup(evt.target.value);
                  selectedModule.group = evt.target.value;
                  onChange();
                }}
              >
                {moduleGroups.length > 0 && (
                  <ListSubheader>
                    {intl.formatMessage({
                      id: 'builder.programs.programmoduleditor.group.menucategory.existing',
                      defaultMessage: 'Bestehende Modulgruppen',
                    })}
                  </ListSubheader>
                )}

                {moduleGroups.map((modGroup, index) => (
                  <EvoachMenuItem
                    key={index}
                    value={modGroup}
                    divider={index === moduleGroups.length - 1}
                  >
                    {modGroup}
                  </EvoachMenuItem>
                ))}
                <ListSubheader>
                  {intl.formatMessage({
                    id: 'builder.programs.programmoduleditor.group.menucategory.actions',
                    defaultMessage: 'Aktionen',
                  })}
                </ListSubheader>
                <EvoachMenuItem
                  onClick={() => {
                    setProgramModuleGroup('');
                    setShowNewGroupField(true);
                  }}
                >
                  <ListItemIcon>
                    <Add fontSize="small" />
                  </ListItemIcon>
                  {intl.formatMessage({
                    id: 'builder.programs.programmoduleditor.labels.newgroup',
                    defaultMessage: 'Neue Modulgruppe erstellen',
                  })}
                </EvoachMenuItem>

                {programModuleGroup !== '' && (
                  <EvoachMenuItem
                    value=""
                    onClick={() => {
                      setProgramModuleGroup('');
                      selectedModule.group = undefined;
                      onChange();
                    }}
                  >
                    <ListItemIcon>
                      <Remove fontSize="small" />
                    </ListItemIcon>
                    {intl.formatMessage(
                      {
                        id: 'builder.programs.programmoduleditor.labels.nogroup',
                        defaultMessage: 'Aus "{moduleGroup}" entfernen',
                      },
                      { moduleGroup: programModuleGroup }
                    )}
                  </EvoachMenuItem>
                )}
              </EvoachSelect>
            </FormControl>
          )}
          {showNewGroupField && (
            <Box>
              <TextField
                fullWidth
                label={intl.formatMessage({
                  id: 'builder.programs.programmoduleditor.group.newgrouptitle',
                  defaultMessage: 'Neue Modulgruppe benennen',
                })}
                value={programModuleGroup}
                onChange={(evt: any) => {
                  setProgramModuleGroup(evt.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      <Tooltip
                        placement="top"
                        arrow
                        title={intl.formatMessage({
                          id: 'builder.programs.programmoduleditor.group.confirmtooltip',
                          defaultMessage: 'Modulgruppe anlegen',
                        })}
                      >
                        <IconButton
                          sx={{ padding: '0 12 0 0' }}
                          size="large"
                          onClick={() => {
                            selectedModule.group = programModuleGroup;
                            onChange();
                            setShowNewGroupField(false);
                          }}
                        >
                          <CheckCircle fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        arrow
                        title={intl.formatMessage({
                          id: 'builder.programs.programmoduleditor.group.canceltooltip',
                          defaultMessage: 'Abbrechen',
                        })}
                      >
                        <IconButton
                          sx={{ padding: 0 }}
                          size="large"
                          color="error"
                          onClick={() => {
                            setShowNewGroupField(false);
                            setProgramModuleGroup('');
                          }}
                        >
                          <Cancel fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                }}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={6} display="flex">
          <TextField
            label={intl.formatMessage({
              id: 'builder.programs.programmoduleditor.labels.description',
              defaultMessage: 'Beschreibung',
            })}
            multiline
            fullWidth
            minRows={4}
            maxRows={4}
            onBlur={() => onChange()}
            value={programModuleDescription}
            onChange={(evt: any) => {
              setProgramModuleDescription(evt.target.value);
              selectedModule.description = evt.target.value;
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CardMedia
            component="img"
            src={
              selectedModule?.resolvedsrc === undefined ||
              selectedModule.resolvedsrc === ''
                ? mapImageToPublicAsset(selectedModule.image, playerBasePath)
                : selectedModule.resolvedsrc
            }
            sx={{ height: '135px', width: '135px', marginBottom: '10px' }}
          />
          <AssetMenu
            externalUrl={
              selectedModule?.src ??
              (selectedModule.image
                ? mapImageToPublicAsset(selectedModule.image, playerBasePath)
                : '')
            }
            updateAssetValue={localUpdateAssetValue}
            assetType={AssetType.IMAGE}
            addPublicAssets
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={programModuleDuration}
            onBlur={() => onChange()}
            onChange={(evt: any) => {
              setProgramModuleDuration(evt.target.value);
              selectedModule.duration = evt.target.value;
            }}
            label={intl.formatMessage({
              id: 'builder.programs.programmoduleditor.labels.duration',
              defaultMessage: 'Textuelle Beschreibung der Dauer',
            })}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          {programRouteType === ProgramRouteTypeEnum.PROGRAM && (
            <TextField
              fullWidth
              label={intl.formatMessage({
                id: 'builder.programs.programmoduleditor.labels.daysoffset',
                defaultMessage:
                  'Wie viele Tage nach dem Programmstart findet dieses Modul statt?',
              })}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={programModuleDateOffset}
              onChange={(evt: any) => {
                let startOffset = parseInt(evt.target.value);
                startOffset = startOffset < 0 ? 0 : startOffset;
                setProgramModuleDateOffset(startOffset);
                selectedModule.dateOffset = startOffset;
                onChange();
              }}
            />
          )}
          {programRouteType === ProgramRouteTypeEnum.INSTANCE && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={intl.formatMessage({
                  id: 'builder.programs.programmoduleditor.startdate',
                  defaultMessage: 'Startdatum dieses Programmmoduls',
                })}
                inputFormat="dd/MM/yyyy"
                value={programModuleStartDate}
                onChange={(evt) => {
                  if (evt) {
                    setProgramModuleStartDate(evt);
                    selectedModule.startDate = evt;
                    onChange();
                    console.log(evt);
                  }
                }}
                renderInput={(params: any) => <TextField {...params} />}
                disablePast
              />
            </LocalizationProvider>
          )}
        </Grid>
        <Grid item xs={12}>
          <ErrorBoundary>
            <ProgramModuleActionEdit
              actions={selectedModule.actions}
              onChange={onChange}
            />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Paper>
  );
};
