import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
  Typography,
} from '@mui/material';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { useIntl } from 'react-intl';

/**
 * magic interface to the builder
 *
 * speech interface built with
 * https://www.npmjs.com/package/react-speech-recognition
 * using
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API
 *
 * TODO check out
 * https://www.google.com/intl/de/chrome/demos/speech.html
 
 *
 */
export interface MagicEditorProps {
  open: boolean;
  onClose: () => void;
  onCommand?: (command: string | undefined) => void;
}

// define the steps that are allowed during input
export enum MagicEditorInputStepsEnum {
  OFF,
  COMPONENT,
  CONTENT,
}

export const MagicEditor: React.FC<MagicEditorProps> = ({
  open,
  onClose,
  onCommand,
}) => {
  const intl = useIntl();

  const [inputStep, setInputStep] = useState<MagicEditorInputStepsEnum>(
    MagicEditorInputStepsEnum.OFF
  );

  const [componentTranscript, setComponentTranscript] = useState<
    string | undefined
  >(undefined);
  const [contentTranscript, setContentTranscript] = useState<
    string | undefined
  >(undefined);
  const [userGuidance, setUserGuidance] = useState<string>(
    intl.formatMessage({
      id: 'builder.voicedialog.userguidance.starttext',
      defaultMessage:
        'Klicke auf "Diktieren starten". Dann diktiere zuerst den Namen des Elementes, mach eine kurze Sprechpause und diktiere dann die Zusatzinformation zum Element. Der Fokus in den Eingabefeldern wechselt automatisch.',
    })
  );

  const activateContentStep = () => {
    setInputStep(MagicEditorInputStepsEnum.CONTENT);
    SpeechRecognition.startListening({
      language: 'de-DE',
      continuous: false,
    });
  };

  const [currentCommand, setCurrentCommand] = useState<string>('');
  // send command call back to parent component if command is stable and not-empty
  const commandCallback = useCallback(
    (element: string) => {
      // if input is non-empty, ...
      if (element.toLowerCase().trim().replaceAll(/\s/g, '') !== '') {
        // .. then set command first, ...
        if (inputStep === MagicEditorInputStepsEnum.COMPONENT) {
          setCurrentCommand(element + ': ');
        }
        // ... and then send with content ...
        if (
          inputStep === MagicEditorInputStepsEnum.CONTENT &&
          onCommand !== undefined
        ) {
          onCommand(currentCommand + element);
          setCurrentCommand('');
        }
      }
    },
    [onCommand, currentCommand, inputStep]
  );

  // https://github.com/JamesBrill/react-speech-recognition/blob/8ecb6052949e47a3fae8c6978abb4253ee1d00f1/README.md#Commands
  const commands = [
    {
      command: '*',
      callback: commandCallback,
    },
  ];

  const { transcript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition({ commands }); // resetTranscript,

  // check real toggle and not only re-render of listening variable
  const [lastListening, setLastListening] = useState<boolean>(listening);
  useEffect(() => {
    //
    if (lastListening !== listening) {
      if (!listening && inputStep === MagicEditorInputStepsEnum.COMPONENT) {
        activateContentStep();
      }
      if (!listening && inputStep === MagicEditorInputStepsEnum.CONTENT) {
        setInputStep(MagicEditorInputStepsEnum.OFF);
        setComponentTranscript('');
        setContentTranscript('');
        setUserGuidance(
          intl.formatMessage({
            id: 'builder.voicedialog.userguidance.commandsent',
            defaultMessage:
              'Deine Kommando zum Hinzufügen des Elementes wurde an den Editor geschickt.',
          })
        );
        setTimeout(() => {
          setUserGuidance(
            intl.formatMessage({
              id: 'builder.voicedialog.userguidance.starttext2',
              defaultMessage:
                'Klicke auf "Diktieren starten" und füge eine weiteres Element hinzu.',
            })
          );
        }, 5000);
      }
      setLastListening(!lastListening);
    }
  }, [listening, setLastListening, lastListening, inputStep, intl]);

  // set textfield depending on input state
  useEffect(() => {
    if (inputStep === MagicEditorInputStepsEnum.COMPONENT) {
      setUserGuidance(
        intl.formatMessage({
          id: 'builder.voicedialog.userguidance.component',
          defaultMessage: 'Spreche jetzt den Namen des Elementes ...',
        })
      );
      setComponentTranscript(transcript);
    }
    if (inputStep === MagicEditorInputStepsEnum.CONTENT) {
      setUserGuidance(
        intl.formatMessage({
          id: 'builder.voicedialog.userguidance.content',
          defaultMessage: 'Spreche jetzt die Zusatzinformation ...',
        })
      );
      setContentTranscript(transcript);
    }
  }, [transcript, inputStep, intl]);

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.voicedialog.userguidance.dialogttile',
          defaultMessage: 'Element durch Sprachbefehle hinzufügen',
        })}
      </DialogTitle>
      <DialogContent>
        {!browserSupportsSpeechRecognition &&
          intl.formatMessage({
            id: 'builder.voicedialog.userguidance.browsernotsupported',
            defaultMessage: 'Dein Browser unterstützt Spracheingabe nicht.',
          })}

        {browserSupportsSpeechRecognition && (
          <>
            <Typography variant="body2">{userGuidance}</Typography>
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                alignContent: 'middle',
                alignItems: 'baseline',
              }}
            >
              {inputStep === MagicEditorInputStepsEnum.COMPONENT && (
                <KeyboardVoiceIcon fontSize="small" color="primary" />
              )}
              {inputStep !== MagicEditorInputStepsEnum.COMPONENT && (
                <KeyboardVoiceIcon fontSize="small" color="secondary" />
              )}
              <TextField
                sx={{ marginLeft: '10px' }}
                label={intl.formatMessage({
                  id: 'builder.voicedialog.userguidance.componentlabel',
                  defaultMessage: 'Element',
                })}
                fullWidth={true}
                value={componentTranscript ?? ''}
                rows={1}
                key="voicecomponenttextfield"
                id="voicecomponenttextfield"
              />
            </div>
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                alignContent: 'middle',
                alignItems: 'baseline',
              }}
            >
              {inputStep === MagicEditorInputStepsEnum.CONTENT && (
                <KeyboardVoiceIcon fontSize="small" color="primary" />
              )}
              {inputStep !== MagicEditorInputStepsEnum.CONTENT && (
                <KeyboardVoiceIcon fontSize="small" color="secondary" />
              )}
              <TextField
                sx={{ marginLeft: '10px' }}
                label={intl.formatMessage({
                  id: 'builder.voicedialog.userguidance.contentlabel',
                  defaultMessage: 'Zusatzinformationen',
                })}
                fullWidth={true}
                value={contentTranscript ?? ''}
                rows={1}
                key="voicecontenttextfield"
                id="voicecontenttextfield"
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            SpeechRecognition.startListening({
              language: 'de-DE',
              continuous: false,
            });
            setInputStep(MagicEditorInputStepsEnum.COMPONENT);
          }}
        >
          {intl.formatMessage({
            id: 'builder.voicedialog.userguidance.startvoiceinput',
            defaultMessage: 'Diktieren starten',
          })}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            SpeechRecognition.stopListening();
            onClose();
          }}
        >
          {intl.formatMessage({
            id: 'builder.voicedialog.userguidance.closedialog',
            defaultMessage: 'Schließen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
