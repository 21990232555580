import { Handle, NodeProps, Position } from 'reactflow';
import { ScaleInput } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { useGlobalStyles, translateKey } from './base.node';

/*
  ScaleInput react-flow node for module builder graph canvas
*/

export const ScaleInputMultiNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;
  const calculateHandlePosition = (handleCount: number, handleIndex: number) =>
    `${(100 / (handleCount + 1)) * (handleIndex + 1)}%`;

  const sourceHandles = payload.keyTexts?.map(
    (keyText: string, index: number) => (
      <Handle
        id={keyText}
        key={keyText + index}
        className={classes['react-flow__handle']}
        type="source"
        position={Position.Bottom}
        style={{
          left: calculateHandlePosition(payload.keyTexts?.length ?? 1, index),
        }}
      />
    )
  );

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '340px' }}
      >
        <ScaleInput
          buttonText={translateKey(intl, payload.buttonText)}
          saveResultTo={payload.saveResultTo}
          scaleSize={payload.scaleSize ? parseInt(payload.scaleSize) : 10}
          scale={payload.scale ? parseInt(payload.scale) : 1}
          scaleName={translateKey(intl, payload.scaleName)}
        />
      </div>
      {sourceHandles}
    </>
  );
};
