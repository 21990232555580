import { defineMessages } from '@formatjs/intl';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import ErrorBoundary from '../../ErrorBoundary';

import { SharedModulesList } from './SharedModulesList';

export const SharedModulesTab: React.FC = () => {
  const intl = useIntl();
  const header = defineMessages({
    invitationheader: {
      id: 'builder.reception.sharedmodules.header',
      defaultMessage: 'Deine geteilten Chatbots und Vorlagen',
    },
  });

  return (
    <Box sx={{ padding: '0 1rem' }}>
      <Box sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <Typography variant="h5">
          {intl.formatMessage(header.invitationheader)}
        </Typography>
      </Box>
      <div style={{ height: '60vh' }}>
        <ErrorBoundary>
          <SharedModulesList />
        </ErrorBoundary>
      </div>
    </Box>
  );
};
