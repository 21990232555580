import {
  evoachDefaultTheme,
  renderURLIcon,
  URLIconEnum,
} from '@evoach/ui-components';
import { AddCircle, Delete } from '@mui/icons-material';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { authorizedGet } from '../../api';
import {
  ProgramModuleAction,
  ProgramModuleActionLinkTypeEnum,
} from '../../entities/Program';
import { EvoachSelect } from '../customMaterialComponents';
import { AssetType } from '../PropertiesSidebar/assetHelper';
import { AssetMenu } from '../PropertiesSidebar/VariableInputs/AssetMenu';
import { ModuleSelect } from '../PropertiesSidebar/VariableInputs/ModuleSelect';
import { ActionTypeDialoge } from '../reception/coachingprograms/ActionTypeDialoge';

/**
 * This component allow editing of a program module action.
 *
 * In a future version, we may consider several actions and then, we have to
 * refactor this component and make the selectedIndex flexible
 *
 */

export type ProgramModuleEditorProps = {
  actions: ProgramModuleAction[];
  onChange: Function;
};

export const ProgramModuleActionEdit: React.FC<ProgramModuleEditorProps> = ({
  actions,
  onChange,
}) => {
  const intl = useIntl();

  const [moduleActions, setModuleActions] =
    useState<Array<ProgramModuleAction>>(actions);
  const [actionTypeDialogIsOpen, setActionTypeDialogIsOpen] =
    useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<ProgramModuleAction>(
    {} as ProgramModuleAction
  );

  actions.forEach((action) => {
    action.linkType = action.linkType
      ? action.linkType
      : ProgramModuleActionLinkTypeEnum.VIDEOCALL;
  });

  const isUploadableActionType = (
    linkType: ProgramModuleActionLinkTypeEnum
  ) => {
    const uploadableActionTypes = [
      ProgramModuleActionLinkTypeEnum.GOOGLESHEETS,
      ProgramModuleActionLinkTypeEnum.GOOGLEDOCS,
      ProgramModuleActionLinkTypeEnum.MSPOWERPOINT,
      ProgramModuleActionLinkTypeEnum.MSWORD,
      ProgramModuleActionLinkTypeEnum.MSEXCEL,
      ProgramModuleActionLinkTypeEnum.WORKSHEET,
      ProgramModuleActionLinkTypeEnum.QUESTIONNAIRE,
      ProgramModuleActionLinkTypeEnum.ARTICLE,
      ProgramModuleActionLinkTypeEnum.PDF,
      ProgramModuleActionLinkTypeEnum.PRESENTATION,
    ];
    return uploadableActionTypes.some((value) => value === linkType);
  };

  useEffect(() => {
    if (!actions) return;
    setModuleActions(actions);
  }, [actions]);

  const updateDocumentSrcOrAssetId = async (
    val: string,
    key: 'assetid' | 'src'
  ) => {
    const currentActionIndex = actions.findIndex((element) =>
      isEqual(element, currentAction)
    );
    let updatedAction = currentAction;
    if (key === 'assetid') {
      const assetURL: RequestInfo = `/asset/${val}`;
      const getAsset = await authorizedGet(assetURL);
      const response = await getAsset();
      const asset = await response.json();

      updatedAction = {
        ...currentAction,
        assetid: val,
        link: asset.key,
        description: asset.key,
        moduleid: undefined,
      };
    }
    if (key === 'src') {
      updatedAction = {
        ...currentAction,
        assetid: undefined,
        link: val,
        moduleid: undefined,
      };
    }

    // make copy of array
    const updatedModuleActions: ProgramModuleAction[] =
      Array.from(moduleActions);

    // don't wait for refetch
    updatedModuleActions[currentActionIndex] = updatedAction;
    setModuleActions(updatedModuleActions);
    // actually update actions
    actions[currentActionIndex] = updatedAction;
    onChange();
  };

  return (
    <>
      <Box
        sx={{
          paddingTop: '1.5em',
          paddingBottom: '1.5em',
          paddingLeft: '1.5em',
        }}
      ></Box>
      <Stack spacing={2}>
        {moduleActions.map((action: ProgramModuleAction, index: number) => (
          <Box key={index} onClick={() => setCurrentAction(action)}>
            <Box
              key={index}
              sx={{
                borderLeft: 'solid 5px ',
                paddingLeft: '1em',
                paddingBottom: '1.5em',
                marginBottom: '0.5em',
                color: evoachDefaultTheme.palette.secondary.main,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={8}
                  display="flex"
                  flexDirection="row"
                  justifyContent="stretch"
                  gap="10px"
                >
                  {action.linkType !==
                    ProgramModuleActionLinkTypeEnum.CHATBOT && (
                    <Box flexGrow={1}>
                      <TextField
                        disabled={isUploadableActionType(action.linkType)}
                        value={action.link ?? ''}
                        label={
                          isUploadableActionType(action.linkType)
                            ? intl.formatMessage({
                                id: 'builder.programs.actionedit.filename',
                                defaultMessage: 'Dateiname',
                              })
                            : intl.formatMessage({
                                id: 'builder.programs.actionedit.linklabel',
                                defaultMessage: 'URL / Link',
                              })
                        }
                        fullWidth
                        onBlur={() => onChange()}
                        onChange={(evt) => {
                          const updatedModuleActions: ProgramModuleAction[] =
                            Array.from(actions);
                          updatedModuleActions[index].link = evt.target.value;
                          setModuleActions(updatedModuleActions);
                          actions[index].link = evt.target.value;
                        }}
                      />
                    </Box>
                  )}
                  {isUploadableActionType(action.linkType) && (
                    <Box>
                      <AssetMenu
                        externalUrl={
                          action.assetid
                            ? action.description ?? ''
                            : action.link ?? ''
                        }
                        updateAssetValue={updateDocumentSrcOrAssetId}
                        assetType={AssetType.DOCUMENT}
                        fullHeight
                      />
                    </Box>
                  )}
                  {action.linkType ===
                    ProgramModuleActionLinkTypeEnum.CHATBOT && (
                    <ModuleSelect
                      displayWidth="100%"
                      currentValue={action.moduleid ?? ''}
                      updateModuleInfo={(
                        moduleid: string,
                        _moduletitle: string,
                        titleTranslated: string
                      ) => {
                        const updatedModuleActions: ProgramModuleAction[] =
                          Array.from(actions);
                        updatedModuleActions[index].moduleid = moduleid;
                        updatedModuleActions[index].description =
                          titleTranslated;
                        setModuleActions(updatedModuleActions);

                        actions[index].moduleid = moduleid;
                        actions[index].description = titleTranslated;
                        onChange();
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth={true}>
                    <Tooltip
                      arrow
                      placement="top"
                      title={intl.formatMessage({
                        id: 'builder.action.edittypes',
                        defaultMessage: 'Typ der Aktion bearbeiten',
                      })}
                    >
                      <EvoachSelect
                        onOpen={(event: object) => {
                          console.log('event', event);
                          setCurrentAction(action);
                          setActionTypeDialogIsOpen(true);
                        }}
                        open={false}
                        sx={{ width: '100%' }}
                        value={1}
                      >
                        <MenuItem value={1}>
                          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography color="secondary" fontSize="0px">
                              {renderURLIcon(
                                action.linkType as unknown as URLIconEnum
                              )}
                            </Typography>
                            <Typography style={{ marginLeft: '10px' }}>
                              {intl.formatMessage({
                                id:
                                  'builder.programs.actionedit.' +
                                  action.linkType,
                                defaultMessage: 'Unbekannter Typ',
                              })}
                            </Typography>
                          </Box>
                        </MenuItem>
                      </EvoachSelect>
                    </Tooltip>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    value={action.description ?? ''}
                    label={
                      index === 0
                        ? intl.formatMessage({
                            id: 'builder.programs.actionedit.tooltipdesc',
                            defaultMessage: 'Tooltip für diese Aktion',
                          })
                        : intl.formatMessage({
                            id: 'builder.programs.actionedit.linkdesc',
                            defaultMessage: 'Beschreibung für diese Aktion',
                          })
                    }
                    fullWidth
                    onBlur={() => onChange()}
                    onChange={(evt) => {
                      const updatedModuleActions: ProgramModuleAction[] =
                        Array.from(actions);
                      updatedModuleActions[index].description =
                        evt.target.value;
                      setModuleActions(updatedModuleActions);
                      actions[index].description = evt.target.value;
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        actions.splice(index, 1);
                        setModuleActions(actions);
                        onChange();
                      }}
                    >
                      <Tooltip
                        title={intl.formatMessage({
                          id: 'builder.programs.actionedit.delete',
                          defaultMessage: 'Ressource löschen',
                        })}
                        arrow
                        placement="top"
                      >
                        <Delete>
                          {intl.formatMessage({
                            id: 'builder.programs.actionedit.primaryaction',
                            defaultMessage: 'Modul-Aktion',
                          })}
                        </Delete>
                      </Tooltip>
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {index === 0 && actions.length > 1 && (
              <Box
                sx={{
                  paddingTop: '1.5em',
                  paddingBottom: '0.5em',
                  paddingLeft: '1.5em',
                }}
              >
                <Typography>
                  {intl.formatMessage({
                    id: 'builder.programs.actionedit.furtherresources',
                    defaultMessage: 'Weitere Ressourcen:',
                  })}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
        <ActionTypeDialoge
          action={currentAction}
          isOpen={actionTypeDialogIsOpen}
          onClose={() => {
            setActionTypeDialogIsOpen(false);
          }}
          onUpdate={(_type: ProgramModuleActionLinkTypeEnum) => {
            //action.linkType = _type;
            setActionTypeDialogIsOpen(false);
            onChange();
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            paddingLeft: '1em',
            width: 'fit-content',
          }}
        >
          <IconButton
            color="secondary"
            onClick={() => {
              const newAction: ProgramModuleAction = {
                link: '',
                linkType: ProgramModuleActionLinkTypeEnum.VIDEOCALL,
                description: '',
                moduleid: '',
              };
              const newActions = actions.concat(newAction);
              setModuleActions(newActions);
              actions.push(newAction);
              onChange();
            }}
            size="large"
          >
            <AddCircle fontSize="medium" />
          </IconButton>
          <Typography>
            {actions.length > 0
              ? intl.formatMessage({
                  id: 'builder.programs.actionedit.addaction',
                  defaultMessage: 'Ressource hinzufügen',
                })
              : intl.formatMessage({
                  id: 'builder.programs.actionedit.addmainaction',
                  defaultMessage: 'Modulaktion hinzufügen',
                })}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};
