import React from 'react';
import {
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ComponentSize } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { StateEntryProps } from '../../nodes';

/*
  ! Size Input for ComponentSize

*/

interface SizeInputProps {
  entry: StateEntryProps;
  elementId: string;
  updateSize: Function;
}

export const SizeInput: React.FC<SizeInputProps> = ({
  entry,
  elementId,
  updateSize,
}) => {
  const [size, setSize] = React.useState<ComponentSize>(
    entry.payload.componentSize ?? ComponentSize.SMALL
  );

  const intl = useIntl();

  const changeSize = (event: SelectChangeEvent, _c: React.ReactNode) => {
    const newSize = event.target.value as ComponentSize;
    setSize(newSize);
    updateSize(newSize, entry);
  };

  return entry.payload.componentSize !== undefined ? (
    <Stack spacing={1}>
      <FormControl
        key={`ElementIdAndKey-${elementId}-size`}
        sx={{ width: '95%' }}
      >
        <InputLabel id="demo-simple-select-label">
          {intl.formatMessage({
            id: 'builder.propertiessidebar.entrylist.sizeInput.Size',
            defaultMessage: 'Größe',
          })}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={size}
          label="size"
          onChange={changeSize}
        >
          <MenuItem value={ComponentSize.SMALL}>
            {intl.formatMessage({
              id: 'builder.propertiessidebar.entrylist.sizeInput.Small',
              defaultMessage: 'Klein',
            })}
          </MenuItem>
          <MenuItem value={ComponentSize.MEDIUM}>
            {intl.formatMessage({
              id: 'builder.propertiessidebar.entrylist.sizeInput.Medium',
              defaultMessage: 'Mittel',
            })}
          </MenuItem>
          <MenuItem value={ComponentSize.LARGE}>
            {intl.formatMessage({
              id: 'builder.propertiessidebar.entrylist.sizeInput.Large',
              defaultMessage: 'Groß',
            })}
          </MenuItem>
        </Select>
      </FormControl>
      <Typography variant="body2" sx={{ width: '95%' }}>
        {intl.formatMessage({
          id: 'builder.nodes.imagedisplay.sizes',
          defaultMessage:
            'Hinweis: Die Konfiguration der Bildgröße wirkt sich erst im Chatbot aus.',
        })}
      </Typography>
    </Stack>
  ) : null;
};
