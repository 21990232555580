import { FC, useState } from 'react';
import { FormControl } from '@mui/material';
//import { useIntl } from 'react-intl';

import { Description } from '../../Description';
import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { useFetchAiChatDocuments } from '../../../api/asset/useFetchAiChatDocuments';
import { Asset } from '../../../entities';

interface DocumentSelectProps {
  nodeType: string;
  updateDocuments: (value: string[]) => void;
  currentValue: string[];
  displayWidth?: string;
}

const DocumentSelect: FC<DocumentSelectProps> = ({
  nodeType,
  updateDocuments,
  currentValue,
  displayWidth = '95%',
}) => {
  // const intl = useIntl();

  const [localValue, setLocalValue] = useState<string[]>(currentValue);

  const { documents } = useFetchAiChatDocuments();

  const handleChange = (event: any) => {
    const assetids = event.target.value as string[];
    setLocalValue(assetids);
    updateDocuments(assetids);
  };

  return (
    <FormControl sx={{ width: displayWidth }}>
      <Description nodetype={nodeType} propname="assetids" />

      <EvoachSelect
        labelId="document-select-label"
        id="document-select"
        multiple
        value={localValue}
        onChange={handleChange}
        style={{ margin: '10px' }}
      >
        {(documents ?? []).map((document: Asset) => (
          <EvoachMenuItem key={document.assetId} value={document.assetId}>
            {document.key}
          </EvoachMenuItem>
        ))}
      </EvoachSelect>
    </FormControl>
  );
};

export default DocumentSelect;
