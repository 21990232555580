import { defineMessages } from '@formatjs/intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  tableCellClasses,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFetchAccountsThatAccepted } from '../../../api';
import {
  generateCSV,
  generateCsvDownload,
} from '../analyticsevoach/analyticsHelper';

const invitationDetailsDialogTexts = defineMessages({
  titel: {
    id: 'builder.reception.invitation.detailsdialog.title',
    defaultMessage: 'Einladung akzeptiert von:',
  },
  mail: {
    id: 'builder.reception.invitation.detailsdialog.mail',
    defaultMessage: 'E-Mail',
  },
  date: {
    id: 'builder.reception.invitation.detailsdialog.date',
    defaultMessage: 'Datum',
  },
  name: {
    id: 'builder.reception.invitation.detailsdialog.name',
    defaultMessage: 'Name',
  },
  close: {
    id: 'builder.reception.invitation.detailsdialog.close',
    defaultMessage: 'Schließen',
  },
  downloadcsv: {
    id: 'builder.reception.invitation.detailsdialog.downloadcsv',
    defaultMessage: 'Download CSV',
  },
});

const useStyles = makeStyles({
  columnStyle: {
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export interface InvitationDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  invitationid: string;
  csvFileName: string;
}

export const InvitationDetailsDialog: React.FC<
  InvitationDetailsDialogProps
> = ({ open, onClose, invitationid }) => {
  const intl = useIntl();
  const { accounts } = useFetchAccountsThatAccepted(invitationid);
  const classes = useStyles();

  const rows = useMemo(() => {
    return accounts
      ? accounts.map((acc: any) => {
          return {
            name: acc.account.givenname + ' ' + acc.account.familyname,
            mail: acc.account.email,
            date: new Intl.DateTimeFormat(intl.locale, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(Date.parse(acc.tscreated + '')),
          };
        })
      : [];
  }, [accounts, intl]);

  const [currentDownload, setCurrentDownload] = useState<string[] | undefined>(
    []
  );

  useEffect(() => {
    setCurrentDownload(undefined);
    setCurrentDownload(generateCSV(rows));
  }, [rows]);

  // generate a hidden download link and click to download file
  const downloadCsv = () => {
    generateCsvDownload(currentDownload, 'invitations');
  };

  return (
    <Dialog open={open} maxWidth={false}>
      <DialogTitle>
        {intl.formatMessage(invitationDetailsDialogTexts.titel)}
      </DialogTitle>
      <DialogContent sx={{ width: '550px' }}>
        <Table
          stickyHeader
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
              paddingBottom: '0.5rem',
            },
          }}
        >
          <colgroup>
            <col
              style={{
                width: '35%',
                textAlign: 'left',
              }}
            />
            <col
              style={{
                width: '40%',
                textAlign: 'left',
              }}
            />
            <col style={{ width: '20%', textAlign: 'left' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                {intl.formatMessage(invitationDetailsDialogTexts.name)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(invitationDetailsDialogTexts.mail)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(invitationDetailsDialogTexts.date)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows !== undefined &&
              rows.map((row: any, index: number) => (
                <TableRow key={'invitation-detail-' + index}>
                  <TableCell className={classes.columnStyle}>
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.columnStyle}>
                    {row.mail}
                  </TableCell>
                  <TableCell className={classes.columnStyle}>
                    {row.date}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button onClick={downloadCsv}>
            {intl.formatMessage(invitationDetailsDialogTexts.downloadcsv)}
          </Button>
        </Box>
        <Box>
          <Button onClick={() => onClose()}>
            {intl.formatMessage(invitationDetailsDialogTexts.close)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
