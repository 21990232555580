import {
  evoachDefaultTheme,
  renderURLIcon,
  URLIconEnum,
} from '@evoach/ui-components';
import {
  Box,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ProgramModuleActionLinkTypeEnum } from '../../../entities';

interface TypeEditProps {
  initialType: ProgramModuleActionLinkTypeEnum;
  onUpdate?: Function;
  width?: string;
  sx?: Record<string, string>;
}

export const TypeEdit: React.FC<TypeEditProps> = ({
  initialType = ProgramModuleActionLinkTypeEnum.VIDEOCALL,
  onUpdate,
  width = '100%',
  sx = {},
}) => {
  const intl = useIntl();

  const [type, setType] =
    useState<ProgramModuleActionLinkTypeEnum>(initialType);

  const defaultType = 'typeEdit' + initialType;

  // map actual types to translated texsts
  const ActionTypes = useMemo(() => {
    return Object.keys(ProgramModuleActionLinkTypeEnum).map((key: string) => {
      return {
        key: key,
        displayText: intl.formatMessage({
          id: 'builder.programs.actionedit.' + key,
          defaultMessage: 'Unbekannter Typ',
        }),
      };
    });
  }, [intl]);

  useEffect(() => {
    if (onUpdate) onUpdate(type);
  }, [type, onUpdate]);

  return (
    <Box
      sx={{
        ...sx,
        width: width,
        padding: '5px',
        border: `1px solid ${evoachDefaultTheme.palette.secondary.main}`,
      }}
    >
      <RadioGroup defaultValue={defaultType}>
        <Box
          sx={{
            borderRadius: '6px',
            padding: '10px',
            display: 'grid',
            gridTemplateColumns: '25% 25% 25% 25%',
            overflow: 'scroll',
            maxHeight: '275px',
          }}
        >
          {ActionTypes.map((enumkey: Record<string, string>, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  backgroundColor: `${
                    enumkey.key === type
                      ? evoachDefaultTheme.palette.secondary.main
                      : evoachDefaultTheme.palette.success.contrastText
                  }`,
                  borderRadius: '6px',
                  padding: '0px',
                  paddingBottom: '25px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      icon={
                        <Icon
                          style={{
                            // marginBottom: '0px',
                            color: evoachDefaultTheme.palette.secondary.main,
                          }}
                        >
                          {renderURLIcon(enumkey.key as URLIconEnum)}
                        </Icon>
                      }
                      checkedIcon={
                        <Icon
                          style={{
                            color:
                              evoachDefaultTheme.palette.success.contrastText,
                          }}
                        >
                          {renderURLIcon(enumkey.key as URLIconEnum)}
                        </Icon>
                      }
                      onChange={() => {
                        setType(enumkey.key as ProgramModuleActionLinkTypeEnum);
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: `${
                          enumkey.key === type
                            ? evoachDefaultTheme.palette.success.contrastText
                            : evoachDefaultTheme.palette.secondary.main
                        }`,
                      }}
                    >
                      {enumkey.displayText}
                    </Typography>
                  }
                  labelPlacement="bottom"
                  value={'typeEdit' + enumkey.key}
                  key={enumkey.key + '_' + enumkey.key + '_labelid'}
                />
              </Box>
            );
          })}
        </Box>
      </RadioGroup>
    </Box>
  );
};
