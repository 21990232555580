import { ReadWriteType, StateEntryProps } from '.';

/**
 * CoachMessage xState specific defintions
 *
 */
export const valueCardListStateEntry: StateEntryProps = {
  version: 1,
  type: 'renderValueCardList',
  temporary: false,
  payload: {
    getValueFrom: '',
    cardsPerRow: 3,
  },
  getValueType: [ReadWriteType.typedObject],
  displayName: 'Value Card List',
  nodeType: 'valueCardListStateEntry',
  handleOutCount: 1,
};
