import { useContext } from 'react';
import { IntlProvider } from 'react-intl';

import { TranslationContext } from './TranslationContext';
import { SupportedLocales } from './SupportedLocales';

export type CustomIntlProviderProps = {
  children?: React.ReactNode;
};

export const CustomIntlProvider: React.FC<CustomIntlProviderProps> = ({
  children,
}) => {
  const { locale, messages } = useContext(TranslationContext);

  return (
    <IntlProvider
      defaultLocale={SupportedLocales.DE}
      locale={locale}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};
