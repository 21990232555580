import { SupportedLocales } from '../intl/SupportedLocales';

import { PrintTemplate } from './PrintTemplatesListTypes';

/**
 * this datatype exists in apps only. The Account metainfos are JSON in DB
 * and are not typed / validated by an interface
 */
export interface AccountMetainfo {
  /**
   * labels assigned to a user AdminUserList. These are sent to Marketing Automation (but not vice versa)
   */
  labels?: string[];
  /**
   * is an arbitrary list of key values that represent the query params of a sign-up call
   * Are used to identify campaigns, etc. Parameter is set in createNewAccount
   * in accountService.ts in backend (and only there!)
   */
  signupParams?: Record<string, string>;
  /**
   * store last UI language of user
   */
  language?: SupportedLocales;
  /**
   * Profile information of an account
   */
  profile?: AccountProfile;
  /**
   * templates to be used for certfificates, workbooks, etc.
   * Property keys are valus of PrintDocumentTypeEnum, e.g. "certificate"
   * @see getTemplateId in printService.ts in backend
   */
  printtemplates?: PrintTemplate[];
}

export interface AccountProfile {
  description?: string;
  linkedinProfileUrl?: string;
  facebookProfileUrl?: string;
  twitterProfileUrl?: string;
  instagramProfileUrl?: string;
  // pic => external URL
  profilePictureSrc?: string;
  logoPictureSrc?: string;
  // pic => assetId
  profilePictureAssetId?: string;
  logoPictureAssetId?: string;
  // pic resolved URL
  /**
   * only used in app context, not part of the profile. Is generated when
   * profile is loaded.
   */
  profilePictureResolvedUrl?: string;
  /**
   * only used in app context, not part of the profile. Is generated when
   * profile is loaded.
   */
  logoPictureResolvedUrl?: string;
  // PROD-1658
  websiteUrl?: string;
  /**
   * display this mail instead of coach mail address
   */
  coachingMail?: string;
  phone?: string;
  youtubeProfileUrl?: string;
  /**
   * allow any additional parameter
   */
  [k: string]: string | undefined;
}

export const EMPTY_PROFILE: AccountProfile = {
  description: '',
  linkedinProfileUrl: '',
  twitterProfileUrl: '',
  facebookProfileUrl: '',
  profilePictureAssetId: '',
  profilePictureSrc: '',
  logoPictureAssetId: '',
  logoPictureSrc: '',
};
