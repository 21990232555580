import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFetchPrintTemplates } from '../../../api/print/useFetchPrintTemplates';
import { PrintDocumentTypeEnum } from '../../../entities/PrintTaskTypes';
import { PrintTemplate } from '../../../entities/PrintTemplatesListTypes';
import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';

interface PrintTemplateSelectionProps {
  /**
   * onChange(templateIds: PrintTemplate[]) => {}
   */
  onChange?: Function;
  defaultTemplates: PrintTemplate[];
  isEditable?: boolean;
}

export const PrintTemplateSelection: React.FC<PrintTemplateSelectionProps> = ({
  onChange,
  defaultTemplates,
  isEditable = true,
}) => {
  const intl = useIntl();

  const { printTemplates } = useFetchPrintTemplates();

  // managing template selection
  const [templateIsMissingError, setTemplateIsMissingError] = useState(false);

  // if there are default certificates stored => set the value in advance
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<Array<string>>(
    []
  );

  useEffect(() => {
    if (!defaultTemplates) {
      setSelectedTemplateIds([]);
    }
    setSelectedTemplateIds(
      defaultTemplates
        .filter(
          (templ: PrintTemplate) =>
            templ.type === PrintDocumentTypeEnum.CERTIFICATE
        )
        .map((t) => t.id)
    );
  }, [defaultTemplates]);

  // a new template was selected
  const onTemplateSelect = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;

    // get list of Ids
    const newTemplates =
      typeof value === 'string' ? value.split(',') : (value as string[]);

    // set selected ids for control
    setSelectedTemplateIds(newTemplates);
    // mark that something is selected
    setTemplateIsMissingError(false);
    // trigger parent component that something has changed
    // send back an array of PrintTemplates to the parent component
    if (onChange) {
      onChange(
        printTemplates.filter((templ: PrintTemplate) =>
          newTemplates.includes(templ.id)
        )
      );
    }
  };

  // no printing templates available
  if (!printTemplates || printTemplates.length === 0) {
    return (
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'builder.printtemplateselection.notemplates',
          defaultMessage: 'Es sind keine Druckvorlagen verfügbar.',
        })}
      </Typography>
    );
  }

  const inputLabel =
    (isEditable
      ? intl.formatMessage({
          id: 'builder.printtemplateselection.inputlabel',
          defaultMessage: 'Bitte wähle eine Vorlage für Zertifikate aus:',
        })
      : intl.formatMessage({
          id: 'builder.printtemplateselection.inputlabel.noteditable',
          defaultMessage: 'Druckvorlage:',
        })) + ' ';

  // provide selection
  return (
    <FormControl fullWidth required error={templateIsMissingError}>
      <InputLabel id="select-template-label" color="secondary">
        {inputLabel}
      </InputLabel>
      <EvoachSelect
        labelId="select-template-label"
        id="select-template"
        defaultValue=""
        required
        disabled={!isEditable}
        multiple={false}
        value={selectedTemplateIds.length > 0 ? selectedTemplateIds : ''}
        input={<OutlinedInput label={inputLabel} />}
        renderValue={(selected) => {
          return (selected as string[])
            .map((selId: string) => {
              return printTemplates.filter(
                (templ: PrintTemplate) => templ.id === selId
              )[0].title;
            })
            .join(', ');
        }}
        onChange={onTemplateSelect}
      >
        {printTemplates
          .filter((templ) => templ.type === PrintDocumentTypeEnum.CERTIFICATE)
          .map((templ) => (
            <EvoachMenuItem key={templ.id} value={templ.id}>
              <Checkbox
                color={
                  selectedTemplateIds.indexOf(templ.id) > -1
                    ? 'default'
                    : 'primary'
                }
                checked={selectedTemplateIds.indexOf(templ.id) > -1}
              />
              {templ.title}
            </EvoachMenuItem>
          ))}
      </EvoachSelect>
      {templateIsMissingError && (
        <FormHelperText error={templateIsMissingError}>
          {inputLabel}
        </FormHelperText>
      )}
    </FormControl>
  );
};
