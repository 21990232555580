import { evoachDefaultTheme } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import {
  ExternalServiceEnum,
  PromptEnum,
} from '../../entities/ExternalServicesTypes';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  dpara: {
    id: 'aiSentimentNodeStateEntry.description',
    defaultMessage:
      'Mit dieser Komponente kann mit Hilfe Künstlicher Intelligenz die Stimmung einer Eingabe geprüft und entsprechend verzweigt werden.',
  },
});

/**
 * The AI sentiment is used to branch depending on the sentiment that is
 * detected in a given input.
 */
export const aiSentimentNodeStateEntry: StateEntryProps = {
  version: 1,
  type: 'sendExternalSentiment',
  temporary: false,
  payload: {
    getValueFrom: '',
    externalService: ExternalServiceEnum.OPENAI_CHAT_35,
    promptType: PromptEnum.CLASSIFY_SENTIMENT,
  },
  getValueType: ReadWriteType.string,
  displayName: 'AI Sentiment Classification',
  nodeType: 'aiSentimentNodeStateEntry',
  nodeMiniMapColor: evoachDefaultTheme.palette.primary.dark,
  handleOutCount: 3,
};
