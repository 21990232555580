import { evoachDefaultTheme } from '@evoach/ui-components';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AccountContextProvider } from './account';
//import { ModuleMetadataContextProvider } from './components/ModuleMetadataContext'; // removed with PROD-1693
import { WindowContextProvider } from './components/window/WindowContext';
import { DevToolsContextProvider } from './devtools/DevToolsContext';
import { CustomIntlProvider } from './intl/CustomIntlProvider';
import { TranslationContextProvider } from './intl/TranslationContext';
import { keycloak } from './keycloak';
import { AppHeaderContextProvider } from './layout/AppHeaderContext';
import { ProductTourContextProvider } from './producttour/ProductTourContext';
import { AppRouter } from './routing/AppRouter';

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ checkLoginIframe: false }}
    >
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={evoachDefaultTheme}>
              <TranslationContextProvider>
                <CustomIntlProvider>
                  <AccountContextProvider>
                    <ProductTourContextProvider>
                      <AppHeaderContextProvider>
                        <DevToolsContextProvider>
                          <WindowContextProvider>
                            <AppRouter />
                          </WindowContextProvider>
                        </DevToolsContextProvider>
                      </AppHeaderContextProvider>
                    </ProductTourContextProvider>
                  </AccountContextProvider>
                </CustomIntlProvider>
              </TranslationContextProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
};
