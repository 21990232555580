import { Handle, NodeProps, Position } from 'reactflow';
import { BinarySorter } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';

/*
  BinarySorterNode react-flow node for module builder graph canvas
*/

export const BinarySorterDisplayNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const elements = [
    {
      type: 'dummyinput',
      value: intl.formatMessage({
        id: 'builder.nodes.BinarySorterNode.elem.hint',
        defaultMessage: 'Die Texte kommen aus der Variable "getValuesFrom"',
      }),
      isLeft: false,
    },
  ];

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <BinarySorter
          saveResultTo="dummyresult"
          elements={elements}
          buttonText=""
          leftTitle={translateKey(intl, payload.leftTitle)}
          rightTitle={translateKey(intl, payload.rightTitle)}
          blindSpots={payload.blindSpots ?? false}
          arrows={payload.arrows ?? false}
          isEcho={true}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="binarysorteroutput"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
