// useFetchAiChatDocumentsimport { useQuery } from 'react-query';

import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';
import { Asset } from '../../entities';

export const useFetchAiChatDocuments = () => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    'aiDocumentList',
    async () => {
      const assetURL: RequestInfo = `/asset?addpreviewsrc=false`;

      const getAssetList = authorizedGet(assetURL);
      const response = await getAssetList();

      const data = (await response.json()) as Asset[];

      // return only assets that are used for AI and have embeddings
      return data.filter((asset: Asset) => asset.useforai);
      // .filter((asset: Asset) => (asset.metadata?.numEmbeddings ?? 0) > 0);
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    documents: data,
    refetch,
  };
};
