import { authorizedGet } from '../../../api/authorizedApi';
import { generateDownload } from '../../../util';

/**
 * perform API call and get analytics result
 * @param {string} analyticsId - internal ID (UUID) of analytics call in backend
 * @returns undefinded if analyticsId is invalid or a data object (Record)
 */
export const getAnalytics = async (analyticsId: string) => {
  if (!analyticsId || analyticsId === '') return undefined;

  const url = `/analytics/${analyticsId}`;

  const fetchAnalyticsCall = authorizedGet(url);
  const response = await fetchAnalyticsCall();

  const data = await response.json();

  return data;
};

/**
 * generate a CSV format that can be post-processed by the generateDownload below
 * inspired by https://www.geeksforgeeks.org/how-to-create-and-download-csv-file-in-javascript/
 *
 * @param {Record<string, any>[]} data - result object as returned by getAnalytics above
 * @returns a string array that contains the lines of the csv file. This has to be
 * post-processed by generateDownload
 */
export const generateCSV = (data: Record<string, any>[]) => {
  if (!data || !Array.isArray(data) || data.length === 0) return;

  // create an empty csv element
  let csvRows = [];

  // header names are the column names of the returned object. Add them at
  // the header of the CSV file
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(',').concat('\n'));

  data.forEach((row: any) => {
    let currentRow = row[headers[0]];
    for (let i = 1; i < headers.length; i++) {
      currentRow += ',' + row[headers[i]];
    }
    currentRow += '\n';
    csvRows.push(currentRow);
  });

  return csvRows;
};

/**
 * trigger the browser to download a CSV file whose rows/lines are defined by data
 *
 * inspired by https://www.geeksforgeeks.org/how-to-create-and-download-csv-file-in-javascript/
 *
 * @param {string[] | undefined} data - array of CSV lines
 * @returns {void} - triggers the download of a CSV file
 */
export const generateCsvDownload = (
  data: string[] | undefined,
  filename?: string
) => {
  if (!data || data.length === 0) return;

  generateDownload(data, filename ? filename : 'evoachadminreport', 'csv');
};
