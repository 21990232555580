import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useIntl } from 'react-intl';

//import { authorizedPut } from '../../../api/authorizedApi';
import { ModuleProps } from '../../../../entities';
import { useFetchModuleTranslation } from '../../../../api/translations';
import { generateDownload } from '../../../../util';

import { exportLanguageAsJson } from './importexport';

export interface ExportLanguageButtonProps {
  module: ModuleProps;
  exportLanguage: string;
}

export const ExportLanguageButton: React.FC<ExportLanguageButtonProps> = ({
  module,
  exportLanguage,
}) => {
  const intl = useIntl();

  const { translation } = useFetchModuleTranslation(
    module.moduleid,
    exportLanguage
  );

  const exportLanguageForDownload = () => {
    // get translations:
    const jsonAsStringArray = exportLanguageAsJson(translation);
    // generate a CSV file for download and start download
    generateDownload(
      jsonAsStringArray,
      exportLanguage + '.' + module.moduleid + '.evoach',
      'json'
    );
  };

  return (
    <>
      <IconButton
        onClick={() => {
          exportLanguageForDownload();
        }}
        key={exportLanguage + 'explang'}
        style={{ verticalAlign: 'top' }}
      >
        <Tooltip
          placement="top"
          arrow
          title={intl.formatMessage({
            id: 'builder.translationeditor.exporttranslation.title',
            defaultMessage: 'Texte für diese Sprache exportieren',
          })}
          key={exportLanguage + 'exportdefault'}
        >
          <FileDownloadIcon />
        </Tooltip>
      </IconButton>
    </>
  );
};
