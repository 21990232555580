import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';
/*
  CombineToStringNode 
*/

export const CombineToStringNode: React.FC<NodeProps<any>> = (
  nodeProps: NodeProps<any>
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const setCombineToStringMessage = intl.formatMessage({
    id: 'builder.nodes.elements.combinetostring.hint',
    defaultMessage:
      'Diese Komponente kombiniert deine Nachricht und die ausgewählten Variablen zu einem neuen Text, der dann in einer neuen Variable gespeichert wird. Namen der neuen Variablen: ',
  });

  const payload = nodeProps.data.state.entry[0].payload;
  const getStringValues = payload.getStringValues ?? [''];

  return (
    <>
      <Handle
        type="target"
        id="combinetostringinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{ width: '300px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          <b>{nodeProps.data.state.entry[0].displayName}</b> :{' '}
          {setCombineToStringMessage}
          <strong>
            {(payload.saveResultTo + '').startsWith('evoachechokey')
              ? ''
              : payload.saveResultTo}
          </strong>
          <br />
          <ol>
            {getStringValues.map((label: string, index: number) => (
              <li key={'label' + index}>{label}</li>
            ))}
          </ol>
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="combinetostringouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
