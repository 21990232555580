import { defineMessages } from 'react-intl';

import { StateEntryProps, ReadWriteType } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.BinarySorter.state.audioText',
    defaultMessage: 'Weiter',
  },
  leftTitle: {
    id: 'builder.nodes.BinarySorter.state.leftTitle',
    defaultMessage: 'Linker Titel',
  },
  rightTitle: {
    id: 'builder.nodes.BinarySorter.state.rightTitle',
    defaultMessage: 'Rechter Titel',
  },
});

export const binarySorterDisplayStateEntry: StateEntryProps = {
  type: 'renderBinarySorterDisplay',
  temporary: false, // TRUE as we implemented an explicit echo to remove the next button!
  payload: {
    getValuesFrom: [''],
    elements: [],
    saveResultTo: '',
    leftTitle: 'builder.nodes.BinarySorter.state.leftTitle',
    rightTitle: 'builder.nodes.BinarySorter.state.rightTitle',
    blindSpots: false,
    arrows: false,
    isEcho: true,
  },
  getValueType: [ReadWriteType.stringArray, ReadWriteType.typedObject], // array +number
  saveResultType: ReadWriteType.typedObject,
  displayName: 'Binary Sorter Display',
  nodeType: 'binarySorterDisplayStateEntry',
  handleOutCount: 1,
};
