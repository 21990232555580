import { CopyLink } from '@evoach/ui-components';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { SyntheticEvent, useContext, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../../../account';
import { authorizedPost } from '../../../../api';
import { useEnvironment } from '../../../../environment/useEnvironment';

import { SelectModules } from './SelectModule';

export const permissionDescriptionMessages = defineMessages({
  startsession: {
    id: 'builder.reception.modules.permissions.CoacheePermission_StartSession',
    defaultMessage:
      'Die eingeladene Person darf Coaching Sessions mit den Chatbots starten.',
  },
  invitesession: {
    id: 'builder.reception.modules.permissions.CoachPermission_InviteCoacheesToSessions',
    defaultMessage: 'Coachees zum Chatbot einladen.',
  },
  copymodule: {
    id: 'builder.reception.modules.permissions.CoachPermission_CopyModuleAsTemplate',
    defaultMessage: 'Chatbot kopieren und bearbeiten.',
  },
  cocreator: {
    id: 'builder.reception.modules.permissions.cocreator',
    defaultMessage: 'Zum Co-Creator machen.',
  },
  ownsmodule: {
    id: 'builder.reception.modules.permissions.owns',
    defaultMessage:
      'Die eingeladene Person hat die gleichen vollständigen Rechte wie die Person, die den Chatbot erstellt hat (Vollzugriff!).',
  },
  editorg: {
    id: 'builder.reception.modules.permissions.CoachPermission_EditOriginalModule',
    defaultMessage: 'Den Original Chatbot bearbeiten.',
  },
});

const shareLinkDialogMessages = defineMessages({
  dialogTitle: {
    id: 'builder.reception.modules.sharelink.dialog.dialogTitle',
    defaultMessage: 'Chatbot(s) für andere Coaches freigeben',
  },
  dialogTitleCoachee: {
    id: 'builder.reception.modules.sharelink.dialog.dialogTitleCoachee',
    defaultMessage: 'Coachees zu Chatbot(s) einladen',
  },
  dialogCreateButton: {
    id: 'builder.reception.modules.sharelink.dialog.dialogCreateButton',
    defaultMessage: 'Weiter',
  },
  dialogCloseButton: {
    id: 'builder.reception.modules.sharelink.dialog.dialogCloseButton',
    defaultMessage: 'Schließen',
  },
  dialoCancelButton: {
    id: 'builder.reception.modules.sharelink.dialog.dialogCancelButton',
    defaultMessage: 'Abbrechen',
  },
  dialogCoachRights: {
    id: 'builder.reception.modules.sharelink.coachrights',
    defaultMessage: 'Rechte für andere Coaches',
  },
});

export interface ShareLinkDialogProps {
  open: boolean;
  onCancel: () => void;
  onCreate: () => void;
  modules: Array<any>;
  preSelectedModules?: string[];
  forCoach: RoleEnum;
}

export const ShareLinkDialog: React.FC<ShareLinkDialogProps> = ({
  open,
  onCancel,
  onCreate,
  modules,
  forCoach,
}) => {
  const { playerBasePath, builderBasePath } = useEnvironment();

  const [permissionList, setPermissionList] = useState<string[]>([
    'coachee_createsession_permissions',
  ]);

  const [startChatbotImmediately, setStartChatbotImmediately] =
    useState<boolean>(false);
  const [invitationId, setInvitationId] = useState<string>('');

  const [invitationNote, setInvitationNote] = useState<string>('');

  const { hasRole } = useContext(AccountContext);

  const intl = useIntl();

  const handlePermissionCheckChange = (
    _event: SyntheticEvent<Element, Event>,
    checked: boolean,
    perm: string
  ) => {
    let newlist = permissionList;
    if (checked) {
      newlist.push(perm);
    } else {
      newlist.splice(
        newlist.findIndex((permission) => permission === perm),
        1
      );
    }
    setPermissionList(newlist);
  };

  const moduleIdList = useMemo(() => {
    if (!modules) return [];
    // map = get only ids
    // filter = make sure that the list contains every modulid only once (make them unique
    // PROD-1389)
    const moduleidlist = modules.map((module: any) => module.moduleid);
    // make unqiue
    return moduleidlist.filter(
      (moduleid: string, index: number) =>
        moduleidlist.indexOf(moduleid) === index
    );
  }, [modules]);

  const createShareLink = async () => {
    if (moduleIdList.length === 0 || permissionList.length === 0) {
      setInvitationId('');
      // TODO display proper error message
    }

    const invitationbody = {
      moduleids: moduleIdList,
      CoachPermission_InviteCoacheesToSessions:
        permissionList.findIndex(
          (permission) =>
            permission === 'coach_inviteotherstosessions_permissions'
        ) > -1,
      CoachPermission_CopyModuleAsTemplate:
        permissionList.findIndex(
          (permission) => permission === 'coach_copy_permissions'
        ) > -1,
      CoachPermission_EditOriginalModule:
        permissionList.findIndex(
          (permission) => permission === 'coach_editorig_permissions'
        ) > -1,
      CoacheePermission_StartSession: true, // set this right always for coach and coachees when sharig anything
      owns:
        permissionList.findIndex(
          (permission) => permission === 'coach_cocreator'
        ) > -1, // owns permission will be set for co-creator
      note: invitationNote,
    };

    const invitURL = `/invitation`;

    const createShareLinkCall = authorizedPost(invitURL, invitationbody);
    const response = await createShareLinkCall();

    // fetch does not throw on non-2XX errors, so we have to do it on our own in order to
    // notify react-query of an error
    //if (!response.ok) {
    //  throw new Error(`Recevied non-2XX status code for ${response.url}.`);
    //}
    if (!response.ok) {
      setInvitationId('');
      // TODO display proper error message
    } else {
      const data = await response.json();
      setInvitationId(data[0].invitationid);
    }
  };

  const handleCloseAfterCreation = () => {
    setInvitationId('');
    onCreate();
  };

  return (
    <>
      <Dialog fullWidth onClose={onCancel} open={open}>
        <DialogTitle>
          {forCoach === RoleEnum.COACH ? (
            <FormattedMessage {...shareLinkDialogMessages.dialogTitle} />
          ) : (
            <FormattedMessage {...shareLinkDialogMessages.dialogTitleCoachee} />
          )}
        </DialogTitle>
        <DialogContent>
          {invitationId !== '' && invitationId !== undefined ? (
            <div>
              <Typography
                component="span"
                variant="body1"
                fontWeight={500}
                style={{ marginTop: 15 }}
              >
                {intl.formatMessage({
                  id: 'builder.reception.modules.sharelink.dialog.yourcoachlinktitle',
                  defaultMessage: 'Geschützter Einladungslink',
                })}
              </Typography>
              <br />
              &nbsp;
              <br />
              <Typography
                component="span"
                variant="body2"
                style={{ marginTop: 15 }}
              >
                {forCoach === RoleEnum.COACH
                  ? intl.formatMessage({
                      id: 'builder.reception.modules.sharelink.dialog.yourcoachlink',
                      defaultMessage: 'Schicke diesen Link an andere Coaches.',
                    })
                  : intl.formatMessage({
                      id: 'builder.reception.modules.sharelink.dialog.yourcoacheelink',
                      defaultMessage: 'Schicke diesen Link an deine Coachees.',
                    })}
                <br />
                {intl.formatMessage({
                  id: 'builder.reception.modules.sharelink.dialog.yourlink2',
                  defaultMessage:
                    'Für jeden Einladungslink kannst du sehen, wer die Einladung akzeptiert hat. Du kannst diese Einladung später auch löschen und ungültig machen.',
                })}
              </Typography>
              <Box sx={{ marginTop: '20px' }}>
                <CopyLink
                  buttonText={intl.formatMessage({
                    id: 'builder.reception.modules.sharelink.buttonText',
                    defaultMessage: 'Link kopieren',
                  })}
                  link={
                    (forCoach === RoleEnum.COACH
                      ? builderBasePath
                      : playerBasePath) +
                    `/invitation/${invitationId}${
                      startChatbotImmediately && modules.length === 1
                        ? '?autostart=true'
                        : ''
                    }`
                  }
                />
              </Box>
              {forCoach === RoleEnum.COACHEE && modules.length === 1 && (
                <Box sx={{ marginTop: '20px' }}>
                  <Typography component="span" variant="body1" fontWeight={500}>
                    {intl.formatMessage({
                      id: 'builder.reception.modules.sharelink.dialog.publicplayertitle',
                      defaultMessage: 'Öffentlicher Link',
                    })}
                  </Typography>{' '}
                  <br />
                  &nbsp;
                  <br />
                  <Typography component="span" variant="body2">
                    {intl.formatMessage({
                      id: 'builder.reception.modules.sharelink.dialog.publicplayer',
                      defaultMessage:
                        'Mit diesem Link kann die eingeladene Person den Chatbot ohne Login ausprobieren. Die Chatbot Session wird nicht benutzerbezogen gespeichert und kann nicht mit dir geteilt werden.',
                    })}{' '}
                    {intl.formatMessage({
                      id: 'builder.reception.modules.sharelink.dialog.publicplayer2',
                      defaultMessage:
                        'Wichtige Info: Dieser Link kann später nicht ungültig gemacht oder gelöscht werden. Er ist nicht an den Einladungslink oben gekoppelt. Jeder, der den Link hat, kann damit beliebig viele Chatbot Sessions durchführen. Die einzige Möglichkeit, den Link später ungültig zu machen, ist den Chatbot zu löschen.',
                    })}
                  </Typography>
                  <Box sx={{ marginTop: '20px' }}>
                    <CopyLink
                      buttonText={intl.formatMessage({
                        id: 'builder.reception.modules.sharelink.buttonText',
                        defaultMessage: 'Link kopieren',
                      })}
                      link={`${playerBasePath}/publicplayer/${modules[0].moduleid}/${modules[0].moduledefaultlanguage}`}
                    />
                  </Box>
                </Box>
              )}
            </div>
          ) : (
            <FormGroup>
              <SelectModules modules={modules} />

              {forCoach === RoleEnum.COACH && (
                <>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    style={{ marginTop: 15 }}
                  >
                    {intl.formatMessage(
                      shareLinkDialogMessages.dialogCoachRights
                    )}
                  </Typography>

                  <FormControlLabel
                    onChange={(e, checked) =>
                      handlePermissionCheckChange(
                        e,
                        checked,
                        'coach_editorig_permissions'
                      )
                    }
                    key="coach_editorig_permissions"
                    control={<Checkbox id="coach_editorig_permissions" />}
                    id="coach_editorig_permissions"
                    label={
                      <Typography component="span" variant="body2">
                        {intl.formatMessage(
                          permissionDescriptionMessages.editorg
                        )}
                      </Typography>
                    }
                  />

                  <FormControlLabel
                    onChange={(e, checked) =>
                      handlePermissionCheckChange(
                        e,
                        checked,
                        'coach_copy_permissions'
                      )
                    }
                    key="coach_copy_permissions"
                    control={<Checkbox id="coach_copy_permissions" />}
                    id="coach_copy_permissions"
                    label={
                      <Typography component="span" variant="body2">
                        {intl.formatMessage(
                          permissionDescriptionMessages.copymodule
                        )}
                      </Typography>
                    }
                  />

                  <FormControlLabel
                    onChange={(e, checked) =>
                      handlePermissionCheckChange(
                        e,
                        checked,
                        'coach_inviteotherstosessions_permissions'
                      )
                    }
                    key="coach_inviteotherstosessions_permissions"
                    control={
                      <Checkbox id="coach_inviteotherstosessions_permissions" />
                    }
                    id="coach_inviteotherstosessions_permissions"
                    label={
                      <Typography component="span" variant="body2">
                        {intl.formatMessage(
                          permissionDescriptionMessages.invitesession
                        )}
                      </Typography>
                    }
                  />
                  {hasRole(RoleEnum.EVOACHADMIN) && (
                    <FormControlLabel
                      onChange={(e, checked) =>
                        handlePermissionCheckChange(
                          e,
                          checked,
                          'coach_cocreator'
                        )
                      }
                      key="coach_cocreator"
                      control={<Checkbox id="coach_cocreator_checkbox" />}
                      id="coach_cocreatorform"
                      label={
                        <Typography component="span" variant="body2">
                          {intl.formatMessage(
                            permissionDescriptionMessages.cocreator
                          )}{' '}
                          nur evoach Admin! Bitte noch nicht benutzen :-)
                        </Typography>
                      }
                    />
                  )}
                </>
              )}

              {forCoach === RoleEnum.COACHEE && modules.length === 1 && (
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    style={{ marginTop: 15 }}
                  >
                    {intl.formatMessage({
                      id: 'builder.invitation.singlechatbot.startimmediately.',
                      defaultMessage:
                        'Hier kannst du einstellen, dass der Chatbot sofort startet, wenn dein Coachee die Einladung akzeptiert.',
                    })}
                  </Typography>

                  <FormControlLabel
                    onChange={(_e, checked) =>
                      setStartChatbotImmediately(checked)
                    }
                    key="startimmeafterinvitaccept"
                    control={
                      <Checkbox
                        checked={startChatbotImmediately}
                        id="startimmeafterinvitaccept_checkbox"
                      />
                    }
                    id="startimmeafterinvitaccept"
                    label={
                      <Typography component="span" variant="body2">
                        {intl.formatMessage({
                          id: 'builder.invitation.singlechatbot.checkbox',
                          defaultMessage:
                            'Chatbot sofort starten, wenn Einladung akzeptiert wird.',
                        })}
                      </Typography>
                    }
                  />
                </>
              )}

              <Typography
                component="span"
                variant="subtitle2"
                style={{ marginTop: 15 }}
                key="shartext"
              >
                {intl.formatMessage({
                  id: 'builder.reception.modules.sharelink.dialog.note',
                  defaultMessage: 'Notiz (optional)',
                })}
              </Typography>
              <TextField
                variant="outlined"
                value={invitationNote}
                placeholder={intl.formatMessage({
                  id: 'builder.reception.modules.sharelink.dialog.notePlaceholder',
                  defaultMessage:
                    'Du kannst eine kurze Notiz für dich hinzufügen',
                })}
                onChange={(event) => {
                  const value = event.target.value;
                  setInvitationNote(value);
                }}
                key="notefieldinvite"
              />
            </FormGroup>
          )}
        </DialogContent>
        <DialogActions>
          {invitationId !== '' && invitationId !== undefined ? (
            <>
              <Button onClick={handleCloseAfterCreation}>
                <FormattedMessage
                  {...shareLinkDialogMessages.dialogCloseButton}
                />
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onCancel}>
                <FormattedMessage
                  {...shareLinkDialogMessages.dialoCancelButton}
                />
              </Button>
              <Button onClick={createShareLink}>
                <FormattedMessage
                  {...shareLinkDialogMessages.dialogCreateButton}
                />
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
