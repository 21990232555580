import { useMutation } from 'react-query';

import { authorizedPatch } from '../authorizedApi';

export const useAcceptProgramInvitationMutation = () => {
  return useMutation<any, Error, string>(
    async (invitationId: string) => {
      const acceptInivitation = authorizedPatch(
        `/program/${invitationId}/invitation`
      );
      const response = await acceptInivitation();
      const data = await response.json();
      return data;
    },
    {
      retry: false,
    }
  );
};
