import { Handle, NodeProps, Position } from 'reactflow';
import { RatingInput } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';

/*
  RatingInput react-flow node for module builder graph canvas
*/

export const RatingInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ paddingBottom: '15px' }}
      >
        <RatingInput
          buttonText={translateKey(intl, payload.buttonText)}
          saveResultTo={payload.saveResultTo}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nexthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '5%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="ratinghandle.1"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '30%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="ratinghandle.2"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '45%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="ratinghandle.3"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '60%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="ratinghandle.4"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '75%' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="ratinghandle.5"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
        style={{ left: '90%' }}
      />
    </>
  );
};
