import { useContext } from 'react';
import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';
import { keycloak } from '../../keycloak';
import { Account } from '../../entities';
import { AccountContext, RoleEnum } from '../../account';

export const useFetchAllAccounts = () => {
  const { hasRole } = useContext(AccountContext);

  const { isLoading, data, isError, error, failureCount, refetch } = useQuery<
    Account[],
    Error
  >(
    'allAccounts',
    async () => {
      try {
        if (!hasRole(RoleEnum.EVOACHADMIN)) {
          return [];
        }
        const getAccount = authorizedGet('/account/allaccounts');
        const getAccountResponse = await getAccount();

        const res = await getAccountResponse.json();

        return res;
      } catch (_error) {
        return [];
      }
    },
    {
      retry: 1,
      // only load when we have an auth token
      enabled: !!keycloak.token,
    }
  );

  return { isLoading, accounts: data, isError, error, failureCount, refetch };
};
