import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';

import {
  aiGenericClassificationStateEntry,
  isCompareMultipleNumbersStateEntry,
  StateEntryProps,
} from '../../nodes';
import { ListEditorDialog } from '../ListEditorDialog/ListEditorDialog';
import { autoSave } from '../../ModuleEditor/autosave';
import { Description } from '../../Description';

import { PropertyHeaderLine } from './PropertyHeadLine';

export interface MultiValueInputProps {
  /**
   * current (StateEntryProps) entry to be changed
   */
  entry: StateEntryProps;
  /**
   * calls function with parameters (listelements, listkeys, storeForUndo, listkeynumbers)
   */
  updateElementValues: Function;
}

/**
 *
 * @param {MultiValueInputProps}
 * @returns
 */
export const MultiValueInput: React.FC<MultiValueInputProps> = ({
  entry,
  updateElementValues,
}) => {
  const intl = useIntl();

  const keyTexts = entry.payload.keyTexts ?? [];
  const isMultiCompare = isCompareMultipleNumbersStateEntry(entry);

  /* const elements =
    (entry.payload.buttonTexts
      ? entry.payload.buttonTexts
      : entry.payload.radioTexts
      ? entry.payload.radioTexts
      : entry.payload.messages
      ? entry.payload.messages
      : entry.payload.labels
      ? entry.payload.labels
      : isMultiCompare
      ? entry.payload.series
      : []) ?? [];
 */

  const elements =
    entry.payload.buttonTexts ??
    entry.payload.radioTexts ??
    entry.payload.messages ??
    entry.payload.labels ??
    entry.payload.classificationKeys ??
    (isMultiCompare ? entry.payload.series : []) ??
    [];

  const keyNumbers = entry.payload.keyNumbers;

  const [isListEditorOpen, setIsListEditorOpen] = useState<boolean>(false);

  const handleListEditorDialog = () => {
    if (isListEditorOpen) {
      autoSave();
    }
    setIsListEditorOpen(!isListEditorOpen);
  };

  // update elements from Emotiondialog
  const updateElements = (
    listelements: Array<any>,
    listkeys: string[],
    storeForUndo: boolean = true,
    listkeynumbers: number[]
  ) => {
    updateElementValues(listelements, listkeys, storeForUndo, listkeynumbers);
  };

  const updateKeyNumbers = (_keynumbers: number[]) => {
    return;
  };

  const listEntryHeader =
    entry.nodeType === aiGenericClassificationStateEntry.nodeType
      ? intl.formatMessage({
          id: 'builder.moduleeditor.propertiesSidebar.multivalueinput.aigenclass.header1',
          defaultMessage: 'Klassifizierungsoptionen',
        })
      : intl.formatMessage({
          id: 'builder.moduleeditor.propertiesSidebar.multivalueinput.header',
          defaultMessage: 'Listeneinträge',
        });

  const buttonLabel =
    entry.nodeType === aiGenericClassificationStateEntry.nodeType
      ? intl.formatMessage({
          id: 'builder.moduleeditor.propertiesSidebar.multivalueinput.aigenclass.header2',
          defaultMessage: 'Optionen editieren',
        })
      : intl.formatMessage({
          id: 'builder.moduleeditor.propertiessidebar.multivalueinput.ownlistdialog',
          defaultMessage: 'Listeneinträge editieren',
        });

  const propname =
    entry.nodeType === aiGenericClassificationStateEntry.nodeType
      ? 'classificationKeys'
      : '.';

  return (
    <div>
      <PropertyHeaderLine header={listEntryHeader} />
      <br />
      <Description nodetype={entry.nodeType} propname={propname} />
      <br />
      <Button color="secondary" onClick={handleListEditorDialog}>
        {buttonLabel}
      </Button>
      <ListEditorDialog
        listelements={elements}
        listkeys={keyTexts}
        open={isListEditorOpen}
        onClose={handleListEditorDialog}
        updateElements={updateElements}
        nodeType={entry.nodeType}
        keyNumbers={keyNumbers}
        updateKeyNumbers={updateKeyNumbers}
      />
    </div>
  );
};
