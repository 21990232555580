import { Handle, NodeProps, Position } from 'reactflow';
import {
  CharacterIcon,
  SceneCanvas,
  UninitializedCharacter,
  CharacterRole,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  Scene Canvas - react-flow node for module builder graph canvas
   requires input from CharacterSelector
*/

export const SceneCanvasNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const dummyElements: UninitializedCharacter[] = [
    {
      role: [CharacterRole.MEDIATOR],
      name: 'Character 1',
      statement: '',
      icon: CharacterIcon.BEAR,
    },
    {
      role: [CharacterRole.MEDIATOR],
      name: 'Character 2',
      statement: '',
      icon: CharacterIcon.FOX,
    },
  ];

  return (
    <>
      <Handle
        type="target"
        id="messageinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <SceneCanvas
          initialCharacters={dummyElements}
          buttonText={translateKey(intl, payload.buttonText)}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="messageouthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
