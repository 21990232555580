import { CardImageEnum, ProductTourStepContent } from '@evoach/ui-components';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { AccountContext } from '../account';
import { mapImageToPublicAsset } from '../components/reception/assets/AssetHelper';
import { useEnvironment } from '../environment/useEnvironment';
import { ProductTourContext } from '../producttour/ProductTourContext';

/**
 *
 * Tours for the recption page.
 * Copy this file and rename the hook to use the hook in other pages.
 *
 * Usage:
 * In your component write
 *    const { tourid, toursteps } = useReceptionTourPage('<variant>');
 * to include a tour or its variant. For each variant, write an if-branch.
 * Then add
 *    <ProductTourManager tourid={tourid} toursteps={toursteps} />
 * as first component of your returned JSX in your component.
 *
 */
export const useReceptionTourPage = (_variant?: string) => {
  const intl = useIntl();
  const { name } = useContext(AccountContext);
  const { closeTour } = useContext(ProductTourContext);
  const { playerBasePath } = useEnvironment();

  const largePopoverWidth = '580px';
  const narrowPopoverWidth = '390px';

  const tourId = 'reception';

  return {
    tourid: tourId,
    toursteps: [
      {
        selector: '', // no selector = center on screen
        content: (
          <>
            <ProductTourStepContent
              displayWide={true}
              title={intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.1.title',
                defaultMessage: 'Willkommen bei evoach!',
              })}
              descriptionIllustrationSrc={mapImageToPublicAsset(
                CardImageEnum.powerful,
                playerBasePath
              )}
              texts={[
                intl.formatMessage(
                  {
                    id: 'builder.pages.reception.tour.welcome.1.description.1',
                    defaultMessage: 'Schön, dass du dabei bist{name}!',
                  },
                  { name: name ? ', ' + name : '' }
                ),
                intl.formatMessage({
                  id: 'builder.pages.reception.tour.welcome.1.description.2',
                  defaultMessage:
                    'Schau dir dieses kurze Einführungs-Video an und entdecke, was mit evoach möglich ist.',
                }),
              ]}
              youtubeEmbedURL="https://www.youtube-nocookie.com/embed/6nGJcYnNiTM"
            />
          </>
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
      {
        selector: '',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'builder.pages.reception.tour.welcome.2.title',
              defaultMessage: 'Probiere unsere Coaching Chatbots aus',
            })}
            descriptionIllustrationSrc={mapImageToPublicAsset(
              CardImageEnum.chat,
              playerBasePath
            )}
            texts={[
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.2.description.1',
                defaultMessage:
                  'Entdecke unsere vielfältigen Coaching Chatbot Module und lerne was deine Coachees damit erreichen können:',
              }),
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.2.description.2',
                defaultMessage:
                  'Starte unsere Chatbot Player App für Coachees auf https://player.evoach.de',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
      {
        selector: '',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'builder.pages.reception.tour.welcome.3.title',
              defaultMessage: 'Wo du Hilfe bekommst!',
            })}
            descriptionIllustrationSrc={mapImageToPublicAsset(
              CardImageEnum.adventure_map,
              playerBasePath
            )}
            texts={[
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.3.description.1',
                defaultMessage:
                  'Damit du direkt loslegen kannst, zeigen wir dir auf einer kleinen Tour die Creator App und erklären dir kurz und knapp die grundlegenden Funktionen.',
              }),
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.3.description.2',
                defaultMessage:
                  'Abgesehen davon gibt es auch noch andere detaillierte Touren, die du später machen kannst. Sowie Erklär- und Hilfe-Videos in den FAQs auf unserer Seite evoach.com/helpandfaqs',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
      {
        selector: '[data-tut="reactour__moduletab"]',
        content: (
          <ProductTourStepContent
            title={intl.formatMessage({
              id: 'builder.pages.reception.tour.welcome.4.title',
              defaultMessage: 'Modul Übersicht',
            })}
            texts={[
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.4.description',
                defaultMessage:
                  'Dein Startpunkt zum Einladen von Coachees sowie zum Anpassen und Bauen von Coaching Chatbot Modulen. Wie das genau funktioniert, erfährst du im Video.',
              }),
            ]}
            youtubeEmbedURL="https://www.youtube-nocookie.com/embed/VUkOUr0Ob24"
            exitLink={{
              linkText: intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.4.exit',
                defaultMessage: 'Jetzt Coachees einladen',
              }),
              callback: () => closeTour(tourId),
            }}
          />
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '[data-tut="reactour__moduletab"]',
        content: (
          <ProductTourStepContent
            title={intl.formatMessage({
              id: 'builder.pages.reception.tour.welcome.5.title',
              defaultMessage: 'Existierende Module anpassen',
            })}
            texts={[
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.5.description',
                defaultMessage:
                  'Du kannst gerne unsere bestehenden Module nach deinen Wünschen anpassen. Du kannst Links zu deiner Website oder deinem Buchungskalender hinzufügen, deine eigenen Audio oder Video Dateine hochladen und natürlich den Chatbot selbst verändern.',
              }),
            ]}
            youtubeEmbedURL="https://www.youtube-nocookie.com/embed/pWTDsJTNtQc"
            exitLink={{
              linkText: intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.5.exit',
                defaultMessage: 'Jetzt Module anpassen',
              }),
              callback: () => closeTour(tourId),
            }}
          />
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '[data-tut="reactour__moduletab"]',
        content: (
          <ProductTourStepContent
            title={intl.formatMessage({
              id: 'builder.pages.reception.tour.welcome.6.title',
              defaultMessage: 'Eigene Module erstellen',
            })}
            texts={[
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.6.description.1',
                defaultMessage:
                  'Mit evoach kannst du im Handumdrehen eigene Chatbot Coaching Module kreieren. Um es dir besonders leicht zu machen, stellen wir dir dafür eine Vielzahl an Vorlagen und Elementen zur Verfügung - ganz ohne Programmierkenntnisse.',
              }),
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.6.description.2',
                defaultMessage:
                  'Und solltest du Unterstützung brauchen, sind wir natürlich für Dich da!',
              }),
            ]}
            youtubeEmbedURL="https://www.youtube-nocookie.com/embed/EI29l1-M2W0"
            exitLink={{
              linkText: intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.6.exit',
                defaultMessage: 'Jetzt Module erstellen',
              }),
              callback: () => closeTour(tourId),
            }}
          />
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '[data-tut="reactour__avatar"]',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'builder.pages.reception.tour.welcome.7.title',
              defaultMessage: 'Sprache ändern & FAQs',
            })}
            descriptionIllustrationSrc={mapImageToPublicAsset(
              CardImageEnum.excitement,
              playerBasePath
            )}
            texts={[
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.7.description.1',
                defaultMessage:
                  'Klicke auf dein Profil, um die Anzeigesprache zu ändern. ',
              }),
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.7.description.2',
                defaultMessage:
                  'Außerdem findest du dort unsere FAQ und weitere Hilfe.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
      {
        selector: '',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'builder.pages.reception.tour.welcome.8.title',
              defaultMessage: 'You are ready to go!',
            })}
            descriptionIllustrationSrc={mapImageToPublicAsset(
              CardImageEnum.success,
              playerBasePath
            )}
            texts={[
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.8.description.1',
                defaultMessage:
                  'Wie versprochen haben wir es kurz und einfach gehalten. Starte einfach mit den Dingen, die dich am meisten interessieren.',
              }),
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.8.description.2',
                defaultMessage:
                  'Falls du noch mehr erfahren möchtest, kannst du dir zum Beispiel unsere Coaching Programme anschauen:',
              }),
              intl.formatMessage({
                id: 'builder.pages.reception.tour.welcome.8.description.3',
                defaultMessage: 'creator.evoach.de/reception/coachingprograms',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
    ],
  };
};
