import { Handle, NodeProps, Position } from 'reactflow';
import { ScaleInput } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { useGlobalStyles, translateKey } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  ScaleInput react-flow node for module builder graph canvas
*/

export const ScaleInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();

  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ width: '340px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <ScaleInput
          buttonText={translateKey(intl, payload.buttonText)}
          saveResultTo={payload.saveResultTo}
          scaleSize={payload.scaleSize ? parseInt(payload.scaleSize) : 10}
          scale={payload.scale ? parseInt(payload.scale) : 1}
          scaleName={translateKey(intl, payload.scaleName)}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="scaleoutputhandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
