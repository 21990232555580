import {
  audioDisplayStateEntry,
  messageInputStateEntry,
  moodInputStateEntry,
  nextButtonStateEntry,
  postItInputStateEntry,
  yesNoButtonStateEntry,
  coachMessageStateEntry,
  dateInputStateEntry,
  phaseStateEntry,
  phaseStartStateEntry,
  phaseEndStateEntry,
  multipleOutputCompositeStateEntry,
  ratingInputStateEntry,
  scaleInputStateEntry,
  percentageScaleInputStateEntry,
  scaleInputMultiStateEntry,
  timeInputStateEntry,
  polarChartStateEntry,
  radarChartStateEntry,
  polarChartInputStateEntry,
  postItDisplayStateEntry,
  videoDisplayStateEntry,
  multipleInputStateEntry,
  multipleDisplayCompositeStateEntry,
  radioButtonStateEntry,
  copyLinkStateEntry,
  imageDisplayStateEntry,
  imageSelectorDisplayStateEntry,
  setAvatarImageStateEntry,
  scaleButtonStateEntry,
  needsInputStateEntry,
  valueSelectorStateEntry,
  emotionsInputStateEntry,
  multiButtonStateEntry,
  noteStateEntry,
  binarySorterStateEntry,
  binarySorterDisplayStateEntry,
  variableDefineStringArrayNodeStateEntry,
  linkButtonStateEntry,
  editableMultiselectStateEntry,
  multipleInputSingleStateEntry,
  multipleInputDecomposedStateEntry,
  loopStartStateEntry,
  loopEndStateEntry,
  selectionCardStateEntry,
  randomCoachMessageStateEntry,
  randomCoachMessageSingleStateEntry,
  sceneCanvasStateEntry,
  characterSelectorStateEntry,
  arrayCombineNumberNodeStateEntry,
  arrayCombineStringNodeStateEntry,
  singleDisplayStateEntry,
  hotOrNotSelectorStateEntry,
  formattedInputStateEntry,
  sendMailNodeStateEntry,
  compareNumbersStateEntry,
  shareSessionStateEntry,
  startNewSessionStateEntry,
  valueCardListStateEntry,
  compareMultipleNumbersStateEntry,
  canvasEditorStateEntry,
  canvasDisplayStateEntry,
  calenderExportCompositeStateEntry,
  singleAnswerPromptStateEntry,
  freeTextPromptStateEntry,
  aiSentimentNodeStateEntry,
  aiMicrochatStateEntry,
  aiDocumentChatStateEntry,
  combineToStringStateEntry,
  aiGenericClassificationStateEntry,
} from '../nodes';
import { checkVariableStateEntry } from '../nodes/CheckVariable.state';
import { downloadButtonStateEntry } from '../nodes/DownloadButton.state';
import { formulaNodeStateEntry } from '../nodes/Formula.state';
import { loopStateEntry } from '../nodes/Loop.state';
import { multiplePercentageScaleInputStateEntry } from '../nodes/MultiplePercentageScaleInput.state';
import { createCertificateStateEntry } from '../nodes/CreateCertificate.state';
import { navigateButtonStateEntry } from '../nodes/NavigateButton.state';
import { imageSelectorStateEntry } from '../nodes/ImageSelector.state';
import { ailistPromptStateEntry } from '../nodes/AiListPrompt.state';
import { freeMicrochatPromptStateEntry } from '../nodes/AiFreeMicrochatPrompt.state';
import { aiSummarizeStateEntry } from '../nodes/AiSummarize.state';
import { aiCoachMessageStateEntry } from '../nodes/AICoachMessage.state';
import { FeatureEnum } from '../../entities/subscriptionTypes';
import { setDirectedAgentModeStateEntry } from '../nodes/SetDirectedAgentMode.state';

export const selectGroups = [
  { label: 'Structure' },
  { label: 'General' },
  { label: 'Buttons/Selections' },
  { label: 'Media Display' },
  { label: 'User Input' },
  { label: 'User Input Display' },
  { label: 'Tool Elements' },
  { label: 'Programming Elements' },
  { label: 'AI Elements', withFeature: FeatureEnum.AI_B },
];

// ! important note:
// if you add an entry in this list, you have to add it to
// the "nodeTypes" in ModuleEditorPane.tsx, too.

// Elements without a group entry are treated as "most used" elements and are
// shown at the top, above the grouped elements
export const selectableElements: Record<string, any> = [
  {
    group: 'Structure',
    element: phaseStartStateEntry,
  },
  {
    group: 'Structure',
    element: phaseStateEntry,
  },
  {
    group: 'Structure',
    element: phaseEndStateEntry,
  },
  {
    group: 'Structure',
    element: setAvatarImageStateEntry,
  },
  {
    group: 'Structure',
    element: noteStateEntry,
  },
  {
    group: 'General',
    element: coachMessageStateEntry,
    mostUsed: true,
  },
  {
    group: 'General',
    element: aiCoachMessageStateEntry,
    mostUsed: true,
  },
  {
    group: 'General',
    element: randomCoachMessageStateEntry,
  },
  {
    group: 'General',
    element: randomCoachMessageSingleStateEntry,
  },
  {
    group: 'Buttons/Selections',
    element: nextButtonStateEntry,
    mostUsed: true,
  },
  {
    group: 'Buttons/Selections',
    element: downloadButtonStateEntry,
  },
  {
    group: 'Buttons/Selections',
    element: linkButtonStateEntry,
  },
  {
    group: 'Buttons/Selections',
    element: yesNoButtonStateEntry,
    mostUsed: true,
  },
  {
    group: 'Buttons/Selections',
    element: radioButtonStateEntry,
  },
  {
    group: 'Buttons/Selections',
    element: scaleButtonStateEntry,
  },
  {
    group: 'Buttons/Selections',
    element: multiButtonStateEntry,
  },
  {
    group: 'Buttons/Selections',
    element: selectionCardStateEntry,
  },
  {
    group: 'Buttons/Selections',
    element: imageSelectorStateEntry,
  },
  {
    group: 'Buttons/Selections',
    element: calenderExportCompositeStateEntry,
  },
  {
    group: 'Media Display',
    element: imageSelectorDisplayStateEntry,
  },
  {
    group: 'Media Display',
    element: imageDisplayStateEntry,
    mostUsed: true,
  },
  {
    group: 'Media Display',
    element: audioDisplayStateEntry,
  },
  {
    group: 'Media Display',
    element: videoDisplayStateEntry,
    mostUsed: true,
  },
  {
    group: 'User Input',
    element: messageInputStateEntry,
    mostUsed: true,
  },
  {
    group: 'User Input',
    element: formattedInputStateEntry,
  },
  {
    group: 'User Input',
    element: multipleInputDecomposedStateEntry,
  },
  {
    group: 'User Input',
    element: moodInputStateEntry,
  },
  {
    group: 'User Input',
    element: scaleInputStateEntry,
    mostUsed: true,
  },
  {
    group: 'User Input',
    element: percentageScaleInputStateEntry,
  },
  {
    group: 'User Input',
    element: multiplePercentageScaleInputStateEntry,
  },
  {
    group: 'User Input',
    element: scaleInputMultiStateEntry,
  },
  {
    group: 'User Input',
    element: dateInputStateEntry,
  },
  {
    group: 'User Input',
    element: timeInputStateEntry,
  },
  {
    group: 'User Input',
    element: postItInputStateEntry,
  },
  {
    group: 'User Input',
    element: emotionsInputStateEntry,
  },
  {
    group: 'User Input',
    element: needsInputStateEntry,
  },
  {
    group: 'User Input',
    element: valueSelectorStateEntry,
  },
  {
    group: 'User Input',
    element: ratingInputStateEntry,
  },
  {
    group: 'User Input',
    element: polarChartInputStateEntry,
  },
  {
    group: 'User Input',
    element: editableMultiselectStateEntry,
  },
  {
    group: 'User Input',
    element: shareSessionStateEntry,
  },
  {
    group: 'User Input',
    element: startNewSessionStateEntry,
  },
  /* {
    group: 'User Input',
    element: hotOrNotSelectorStateEntry,
  }, */
  {
    group: 'User Input Display',
    element: multipleOutputCompositeStateEntry,
  },
  {
    group: 'User Input Display',
    element: postItDisplayStateEntry,
  },
  {
    group: 'User Input Display',
    element: multipleDisplayCompositeStateEntry,
  },
  {
    group: 'User Input Display',
    element: singleDisplayStateEntry,
  },
  {
    group: 'User Input Display',
    element: valueCardListStateEntry,
  },
  {
    group: 'Tool Elements',
    element: polarChartStateEntry,
  },
  {
    group: 'Tool Elements',
    element: radarChartStateEntry,
  },
  {
    group: 'Tool Elements',
    element: binarySorterStateEntry,
  },
  {
    group: 'Tool Elements',
    element: binarySorterDisplayStateEntry,
  },
  {
    group: 'Tool Elements',
    element: copyLinkStateEntry,
  },
  {
    group: 'Tool Elements',
    element: navigateButtonStateEntry,
  },
  {
    group: 'Tool Elements',
    element: createCertificateStateEntry,
  },
  {
    group: 'Programming Elements',
    element: loopStateEntry,
  },
  {
    group: 'Programming Elements',
    element: variableDefineStringArrayNodeStateEntry,
  },
  {
    group: 'Programming Elements',
    element: checkVariableStateEntry,
  }, // PROD-1866
  {
    group: 'Programming Elements',
    element: arrayCombineNumberNodeStateEntry,
  },
  {
    group: 'Programming Elements',
    element: arrayCombineStringNodeStateEntry,
  },
  {
    group: 'Programming Elements',
    element: compareNumbersStateEntry,
  },
  {
    group: 'Programming Elements',
    element: compareMultipleNumbersStateEntry,
  },
  {
    group: 'Programming Elements',
    element: formulaNodeStateEntry,
  },
  {
    group: 'Programming Elements',
    element: combineToStringStateEntry,
    betaOnly: true,
  },
  {
    group: 'AI Elements',
    element: singleAnswerPromptStateEntry,
  },
  {
    group: 'AI Elements',
    element: aiMicrochatStateEntry,
  },
  {
    group: 'AI Elements',
    element: aiCoachMessageStateEntry,
  },
];

export const mostUsedElements = selectableElements
  .filter((element: any) => 'mostUsed' in element)
  .map((element: any, elementIndex: number) => {
    return {
      key: 'favorite.' + elementIndex,
      label: element.element.displayName,
      nodes: [],
      element: element.element,
      betaOnly: element.betaOnly,
      withFeature: element.withFeature,
    };
  });

export const globalTreeData = selectGroups.map(
  (elementGroup: any, groupIndex: number) => {
    return {
      key: elementGroup.label,
      label: elementGroup.label,
      betaOnly: elementGroup.betaOnly,
      withFeature: elementGroup.withFeature,
      nodes: selectableElements
        .filter((element: any) => element.group === elementGroup.label)
        .map((element: any, elementIndex: number) => {
          return {
            key: 'groupnode' + groupIndex + '.' + elementIndex,
            label: element.element.displayName,
            nodes: [],
            element: element.element,
            betaOnly: element.betaOnly,
            withFeature: element.withFeature,
          };
        }),
    };
  }
);

// display most used elements first in SelectionSidebar
[].unshift.apply(globalTreeData, mostUsedElements);

export const evoachSpecialNodes = [
  {
    key: 'evoach only',
    label: 'evoach only',
    betaOnly: undefined,
    nodes: [
      {
        key: 'groupnode_evo.0',
        label: 'Multiple Input (single)',
        nodes: [],
        element: multipleInputSingleStateEntry,
      },
      {
        key: 'groupnode_evo.1',
        label: 'Multiple Input (composite)',
        nodes: [],
        element: multipleInputStateEntry,
      },
      {
        key: 'groupnode_evo.2',
        label: 'Loop Start',
        nodes: [],
        element: loopStartStateEntry,
      },
      {
        key: 'groupnode_evo.3',
        label: 'Loop End',
        nodes: [],
        element: loopEndStateEntry,
      },
      {
        key: 'groupnode_evo.4',
        label: 'Note',
        nodes: [],
        element: noteStateEntry,
      },
      {
        key: 'groupnode_evo.5',
        label: 'Character Selector',
        nodes: [],
        element: characterSelectorStateEntry,
      },
      {
        key: 'groupnode_evo.6',
        label: 'Scene Canvas',
        nodes: [],
        element: sceneCanvasStateEntry,
      },
      {
        key: 'groupnode_evo.7',
        label: 'Constellation Board',
        nodes: [],
        element: canvasEditorStateEntry,
      },
      {
        key: 'groupnode_evo.8',
        label: 'Constellation Display',
        nodes: [],
        element: canvasDisplayStateEntry,
      },
      {
        key: 'groupnode_evo.9',
        label: 'Hot-Or-Not Selector',
        nodes: [],
        element: hotOrNotSelectorStateEntry,
      },
      {
        key: 'groupnode_evo.10',
        label: 'Send Mail',
        nodes: [],
        element: sendMailNodeStateEntry,
      },
      /*  {
        key: 'groupnode_evo.11',
        label: 'Formula Calculation',
        nodes: [],
        element: formulaNodeStateEntry,
      }, */
      /*  {
        key: 'groupnode_evo.12',
        label: 'Paraphrase (AI)',
        nodes: [],
        element: paraphraseNodeStateEntry,
      },
      {
        key: 'groupnode_evo.13',
        label: 'Classification (AI)',
        nodes: [],
        element: aiClassificationNodeStateEntry,
      }, */
      /* {
        key: 'groupnode_evo.14',
        label: 'Single Answer Prompt (AI)',
        nodes: [],
        element: singleAnswerPromptStateEntry,
      }, */
      {
        key: 'groupnode_evo.15',
        label: 'Free Text Prompt (AI)',
        nodes: [],
        element: freeTextPromptStateEntry,
      },
      {
        key: 'groupnode_evo.16',
        label: 'Sentiment Analysis (AI)',
        nodes: [],
        element: aiSentimentNodeStateEntry,
      },
      /* {
        key: 'groupnode_evo.17',
        label: 'Micro-Chat (AI)',
        nodes: [],
        element: aiMicrochatStateEntry,
      }, */
      {
        key: 'groupnode_evo.18',
        label: 'List Prompt (AI)',
        nodes: [],
        element: ailistPromptStateEntry,
      },
      {
        key: 'groupnode_evo.19',
        label: 'Free Microchat Prompt (AI)',
        nodes: [],
        element: freeMicrochatPromptStateEntry,
      },
      {
        key: 'groupnode_evo.20',
        label: 'Summarize (AI)',
        nodes: [],
        element: aiSummarizeStateEntry,
      },
      {
        key: 'groupnode_evo.21',
        label: 'Chat with Documents (AI)',
        nodes: [],
        element: aiDocumentChatStateEntry,
      },
      {
        key: 'groupnode_evo.22',
        label: 'Set Directed Agent Mode (AI)',
        nodes: [],
        element: setDirectedAgentModeStateEntry,
      },
      {
        key: 'groupnode_evo.23',
        label: 'Classification (AI)',
        nodes: [],
        element: aiGenericClassificationStateEntry,
      },
    ],
  },
];
