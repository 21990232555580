import {
  CardImageEnum,
  ObjectCardCreatorImagesProps,
} from '@evoach/ui-components';
import { Viewport } from 'reactflow';
import { defineMessages } from 'react-intl';

export interface MetaDataProps {
  [index: string]:
    | undefined
    | number
    | Viewport
    | string
    | string[]
    | ModulePhase[]
    | CardImageEnum
    | ModuleCategoryEnum[]
    | ObjectCardCreatorImagesProps;
  moduleLanguages: string[];
  defaultLanguage: string;
  title: string;
  description: string;
  duration: string;
  tscreated: string;
  tsupdated: string;
  tspublished: string;
  phases: ModulePhase[];
  categories?: ModuleCategoryEnum[];
  creatorviewport?: Viewport; // store last canvas position in creator
  orderingnumber?: number;
  /**
   * @deprecated image enum to be displayed in ObjectCard
   */
  image: CardImageEnum;
  /**
   * external URL to an image that is displayed in the ObjectCard of a chatbot
   */
  src?: string;
  /**
   * assetid of an image that is displayed in the ObjectCard of a chatbot.
   * Has to be resolved  when loading a module and before displaying.
   */
  assetid?: string;
  /**
   * depending on what image, src and assetid contains, the resolvedsrc is set.
   * Priority: assetid before src before image
   */
  resolvedsrc?: string;
  /**
   * The image url to show the coach logo at the bottomof the object card
   * Priority: logo before profile pic
   */
  resolvedCoachLogoSrc?: ObjectCardCreatorImagesProps;

  labels?: string[]; // potential labels of a module
}

export interface ModulePhase {
  reference: string;
  text: string;
}

export enum ModuleCategoryEnum {
  selfcoaching = 'selfcoaching',
  selfassessment = 'selfassessment',
  before = 'before',
  after = 'after',
  other = 'other',
}

export const categoryTranslations = defineMessages({
  [ModuleCategoryEnum.selfcoaching]: {
    id: 'builder.moduleeditor.metadata.category.selfcoaching',
    defaultMessage: 'Selbst-Coaching',
  },
  [ModuleCategoryEnum.before]: {
    id: 'builder.moduleeditor.metadata.category.before',
    defaultMessage: 'Vorbereitung',
  },
  [ModuleCategoryEnum.after]: {
    id: 'builder.moduleeditor.metadata.category.after',
    defaultMessage: 'Nachbereitung',
  },
  [ModuleCategoryEnum.selfassessment]: {
    id: 'builder.moduleeditor.metadata.category.selfassessment',
    defaultMessage: 'Selbst-Evaluation',
  },
  [ModuleCategoryEnum.other]: {
    id: 'builder.moduleeditor.metadata.category.other',
    defaultMessage: 'Andere',
  },
});
