import { MoreVert } from '@mui/icons-material';
import { Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { SvgIcon } from 'material-ui';
import React, { ReactElement } from 'react';

export interface TemplateListMenuItemProps {
  icon?: ReactElement<SvgIcon>;
  text: string;
  disabled: boolean;
  onClick?: Function;
  hasDivider?: boolean;
}

const TemplateMenu = ({
  id,
  menuitems,
  modifiedText,
  createdText,
}: {
  id: string;
  menuitems: TemplateListMenuItemProps[];
  modifiedText?: string;
  createdText?: string;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="template-menu-button"
        aria-controls={open ? 'template-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="template-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuitems.map((menuitem: any, index: number) => (
          <MenuItem
            key={'objectcardheadermenu' + index}
            onClick={() => {
              if (!menuitem.disabled && menuitem.onClick) {
                menuitem.onClick(id, index);
              }
              handleClose();
            }}
            disabled={menuitem.disabled}
          >
            {menuitem.icon ?? ''}&nbsp;&nbsp;
            <Typography variant="body2" color="secondary">
              {menuitem.text}
            </Typography>
          </MenuItem>
        ))}
        <Divider />
        {createdText ? (
          <MenuItem disabled>
            <Typography variant="body2">{createdText}</Typography>
          </MenuItem>
        ) : (
          ''
        )}
        {modifiedText ? (
          <MenuItem disabled>
            <Typography variant="body2">{modifiedText}</Typography>
          </MenuItem>
        ) : (
          ''
        )}
      </Menu>
    </>
  );
};

export default TemplateMenu;
