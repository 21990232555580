import { evoachDefaultTheme } from '@evoach/ui-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface ProductTourButtonProps {
  text?: string;
  buttonType?: string;
}

export const ProductTourButton: React.FC<ProductTourButtonProps> = ({
  text,
  buttonType,
}) => {
  const intl = useIntl();

  let internalText = text ?? '';
  if (buttonType === 'next') {
    internalText = intl.formatMessage({
      id: 'builder.productttour.button.next',
      defaultMessage: 'Weiter',
    });
  }
  if (buttonType === 'laststepnext') {
    internalText = intl.formatMessage({
      id: 'builder.productttour.button.laststepnext',
      defaultMessage: "Los geht's!",
    });
  }

  if (buttonType === 'previous') {
    return (
      <Box
        sx={{
          transform: 'scale(1.4)',
          color: evoachDefaultTheme.palette.secondary.main,
        }}
      >
        <ArrowBackIosIcon />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          backgroundColor: evoachDefaultTheme.palette.secondary.main,
          padding: '10px',
          typography: 'body2',
          color: '#FFFFFF',
          fontWeight: '500',
          borderRadius: '4px',
          fontVariant: 'button',
        }}
      >
        {internalText}
      </Box>
    );
  }
};
