import { Node, Edge } from 'reactflow';

//
// Mae, 18.05.2022
//
// This file was created when migrating to React 18 an therefore migrating to React Flow 10
// It contains helper functions to facilitate migration.
//
// React Flow changes from 9=>10 can be found here https://reactflow.dev/docs/guides/migrate-to-v10/
//

/**
 * ReactFlowElements type replaces the old Elements type from ReactFlow
 */
export type ReactFlowElements = Array<Node | Edge>;

/**
 *
 * @param nodes
 * @param edges
 * @returns
 */
export const getSelectedElements = (
  nodes: Node<any>[],
  edges: Edge<any>[]
): ReactFlowElements =>
  (getSelectedNodes(nodes) as ReactFlowElements).concat(
    getSelectedEdges(edges) as ReactFlowElements
  );

/**
 *
 * @param nodes
 * @returns
 */
export const getSelectedNodes = (nodes: Node<any>[]): Node<any>[] =>
  nodes.filter((n: Node<any>) => n.selected);

/**
 *
 * @param edges
 * @returns
 */
export const getSelectedEdges = (edges: Edge<any>[]): Edge<any>[] =>
  edges.filter((e: Edge<any>) => e.selected);

/**
 *
 * @param edges
 * @returns
 */
export const getUnselectedEdges = (edges: Edge<any>[]): Edge<any>[] => {
  return edges.filter((edge: Edge<any>) => !edge.selected);
};

/**
 *
 * @param elements
 * @returns
 */
export const getSelectedElementsByElements = (
  elements: ReactFlowElements
): ReactFlowElements => {
  return elements.filter(
    (n: Node<any> | Edge<any>) => n.selected
  ) as ReactFlowElements;
};

/**
 *
 * @param elements
 * @returns
 */
export const setSelectedElements = (elements: ReactFlowElements) => {
  return elements.map((element: any) => {
    return { ...element, selected: true };
  });
};

/**
 *
 * @param elements
 * @returns
 */
export const resetSelectedElements = (elements: ReactFlowElements) => {
  return elements.map((element: any) => {
    return { ...element, selected: false };
  });
};
