import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  title: {
    id: 'builder.nodes.polarChartInput.state.title',
    defaultMessage: 'Polar Chart Input Titel',
  },
  buttonText: {
    id: 'builder.nodes.polarChartInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

export const polarChartInputStateEntry: StateEntryProps = {
  type: 'renderPolarchartInput',
  version: 'v2',
  temporary: true,
  payload: {
    series: [],
    labels: [],
    tickAmount: 10,
    getLabelsFrom: '',
    getSeriesFrom: '',
    title: 'builder.nodes.polarChartInput.state.title',
    saveResultTo: 'polarchart',
    buttonText: 'builder.nodes.polarChartInput.state.buttonText',
  },
  saveResultType: [ReadWriteType.number, ReadWriteType.numberArray],
  getValueType: [ReadWriteType.number, ReadWriteType.numberArray],
  displayName: 'Polar Chart Input',
  nodeType: 'polarChartInputStateEntry',
  handleOutCount: 1,
};

/*
version v2
there is no version v1. V2 is choosen to enable auto-generate options within node
s. implementation in PolarchartInput.tsx in builder (via EntryList.tsx)
*/
