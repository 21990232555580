import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.multipleDisplay.state.message',
    defaultMessage:
      'Diese Nachricht erscheint vor der Ausgabe der Nachrichten.',
  },
  buttonText: {
    id: 'builder.nodes.multipleDisplay.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

export enum MultipleDisplayEnum {
  DEFAULT = 'DEFAULT',
  BUBBLE = 'BUBBLE',
  CLOUD = 'CLOUD',
}

export const multipleDisplayCompositeStateEntry: StateEntryProps = {
  type: 'renderMultipleDisplayComposite',
  temporary: true, // TRUE as we implemented an explicit echo to remove the next button!
  payload: {
    topics: undefined,
    bubbleType: MultipleDisplayEnum.BUBBLE,
    getValueFrom: '',
    message: 'builder.nodes.multipleDisplay.state.message',
    buttonText: 'builder.nodes.multipleDisplay.state.buttonText',
  },
  getValueType: [
    ReadWriteType.stringArray,
    ReadWriteType.typedObject,
    ReadWriteType.string,
  ], // array +number
  displayName: 'Multiple Display',
  nodeType: 'multipleDisplayCompositeStateEntry',
  handleOutCount: 1,
};
