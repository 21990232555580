import { Handle, NodeProps, Position } from 'reactflow';
import { MultipleDisplayComposite } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';
/*
  MultipleDisplayComposite react-flow node for module builder graph canvas
*/

export const MultipleDisplayCompositeNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <MultipleDisplayComposite
          message={translateKey(intl, payload.message)}
          messages={[
            intl.formatMessage({
              id: 'builder.nodes.multipledisplaycomposite.topicsvar.hint',
              defaultMessage:
                'Die Texte kommen aus der Variable "getValueFrom"',
            }),
            intl.formatMessage({
              id: 'builder.nodes.multipledisplaycomposite.topicsvar.hint',
              defaultMessage:
                'Die Texte kommen aus der Variable "getValueFrom"',
            }),
            intl.formatMessage({
              id: 'builder.nodes.multipledisplaycomposite.topicsvar.hint',
              defaultMessage:
                'Die Texte kommen aus der Variable "getValueFrom"',
            }),
          ]}
          bubbleType={payload.bubbleType}
          buttonText={translateKey(intl, payload.buttonText)}
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="multipledisplayoutput"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
