/**
 * Trigger the browser to download a CSV file whose rows/lines are defined by data
 * The filename of the exported file is defined in fileNamePrefix. For export, the data
 * and time are automatically added.
 *
 * inspired by https://www.geeksforgeeks.org/how-to-create-and-download-csv-file-in-javascript/
 *
 * @param {any | undefined} data - data to be exported
 * @param {string} fileNamePrefix - prefix of the file to be generated.
 * @param {string} fileNameExtension - extension of the exported file
 * @returns {void} - triggers the download of a CSV file
 */
export const generateDownload = (
  data: string[] | undefined,
  fileNamePrefix: string = 'evoachexport',
  fileNameExtension: string = 'csv'
) => {
  if (!data || data.length === 0) return;
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob(data as BlobPart[], { type: 'text/csv' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // add an id to safely remove the element afterwards
  a.setAttribute('id', 'downloadfakeanchor');

  // make sure that target is not current tab/window
  a.setAttribute('target', '_blank');

  // Setting the anchor tag attribute for downloading
  // and passing the download file name

  const todayAndNow = new Date();
  const cdate = todayAndNow
    .toLocaleDateString('de-DE', {
      weekday: undefined,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replaceAll('.', '-');
  const ctime = todayAndNow
    .toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    })
    .replaceAll(':', '-');

  a.setAttribute(
    'download',
    `${fileNamePrefix}.${cdate}.${ctime}.${fileNameExtension}`
  );

  // Performing a download with click
  a.click();

  // clean up
  document.getElementById('downloadfakeanchor')?.remove();
};
