import { Handle, NodeProps, Position } from 'reactflow';
import { DateInput } from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  DateInput react-flow node for module builder graph canvas
*/

export const DateInputNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div style={{ minWidth: '333px' }}>
        <div
          className={
            nodeProps.selected ? classes.selectStyletrue : classes.root
          }
        >
          <CustomNodeMenu nodeid={nodeProps.id} />
          <DateInput
            date={payload.date}
            buttonText={translateKey(intl, payload.buttonText)}
            saveResultTo={payload.saveResultTo}
          />
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nohandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
