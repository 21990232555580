import { EvoachButton } from '@evoach/ui-components';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { useEnvironment } from '../environment/useEnvironment';
//import { SupportedLocales } from '../intl/SupportedLocales';
//import { TranslationContext } from '../intl/TranslationContext';

export const NoValidSubscriptionPage: React.FC = () => {
  const intl = useIntl();
  //const { locale } = useContext(TranslationContext);
  const { playerBasePath } = useEnvironment();

  return (
    <Box
      sx={{
        alignSelf: 'center',
        maxWidth: '50%',
      }}
    >
      <Box>
        <Typography variant="h5">
          {intl.formatMessage({
            id: 'builder.pages.novalidsubscription.header',
            defaultMessage: 'Deine Lizenz ist abgelaufen',
          })}
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '30px' }}>
          {intl.formatMessage({
            id: 'builder.pages.novalidsubscription.description',
            defaultMessage:
              'Deine Lizenz erlaubt leider keinen Zugriff mehr auf die Creator App. Das kann der Fall sein, wenn du dein Abo gekündigt hattest oder wenn deine 30-Tage-Testversion abgelaufen ist.',
          })}
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '30px' }}>
          {intl.formatMessage({
            id: 'builder.pages.novalidsubscription.description2',
            defaultMessage:
              'Wir freuen uns, wenn du mit uns weiterarbeiten möchtest. Unten gibt es einen Link zu unseren Lizenzmodellen. Wenn du nicht sicher bist, welches Abo für dich passt, kannst du gerne einen Beratungstermin vereinbaren.',
          })}{' '}
          <Link
            sx={{ marginTop: '30px', marginRight: '30px' }}
            href="https://calendly.com/evoach/check-in"
            target="_blank"
            variant="body1"
          >
            {intl.formatMessage({
              id: 'builder.pages.novalidsubscription.button.salessupport',
              defaultMessage: 'Beratungstermin vereinbaren',
            })}
          </Link>
        </Typography>

        <EvoachButton
          sx={{ marginTop: '30px', marginLeft: '30%' }}
          href="/subscribe"
          target="_blank"
        >
          {intl.formatMessage({
            id: 'builder.pages.novalidsubscription.button.upgrade',
            defaultMessage: 'Jetzt upgraden!',
          })}
        </EvoachButton>

        <Typography variant="body1" sx={{ marginTop: '30px' }}>
          {intl.formatMessage({
            id: 'builder.pages.novalidsubscription.description3',
            defaultMessage:
              'Deine Chatbots in der Player App kannst du weiterhin kostenlos nutzen.',
          })}{' '}
          <Link
            sx={{ marginTop: '30px' }}
            href={playerBasePath}
            target="_blank"
            variant="body1"
          >
            {intl.formatMessage({
              id: 'builder.pages.novalidsubscription.button.playerapp',
              defaultMessage: 'Zur Player App wechseln',
            })}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
