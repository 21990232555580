import { ModuleTranslation, TranslationProps } from '../../../../entities';

/**
 * export metadata translation and statemachine translation of a module to
 * a JSON file that can be translated and imported later
 * @param {ModuleTranslation} translation of a module
 * @returns { string[]} returns the export as an array of strings that represent
 * lines to be exported.
 */
export const exportLanguageAsJson = (
  translation: ModuleTranslation
): string[] => {
  // turn JSON in string array for export
  const jsonAsStringArray: string[] = [];

  // open JSON file
  jsonAsStringArray.push('{\n');

  // add metadata translations
  Object.keys(translation.metadatatranslation).forEach((key: string) => {
    // prettier-ignore
    /* const trans = (translation.metadatatranslation[key] as string).replace(
      /"/g,
      // eslint-disable-next-line no-useless-escape
      '\''
    ) */

    const trans = (translation.metadatatranslation[key] as string).replace(/"/g,
    // eslint-disable-next-line no-useless-escape
    '\\"'
  )
    jsonAsStringArray.push(
      '"metadatatranslation.' + key + '": "' + trans + '",\n'
    );
    // prettier-ignore
  });
  // add statemachinetranslation
  Object.keys(translation.statemachinetranslation).forEach(
    (key: string, index: number) => {
      // add a comma at the end of a line, but not at the last line!
      const comma =
        Object.keys(translation.statemachinetranslation).length - 1 === index
          ? '"\n'
          : '",\n';

      // prettier-ignore
      const trans = (translation.statemachinetranslation[key] as string).replace(
        /"/g,
        // eslint-disable-next-line no-useless-escape
        '\\"'
      )

      jsonAsStringArray.push(
        '"statemachinetranslation.' + key + '": "' + trans + comma
      );
    }
  );

  // close JSON file
  jsonAsStringArray.push('}\n');

  return jsonAsStringArray;
};

/**
 * try to parse a string and build a data obkect out of it
 * @param {string} filecontent
 * @param {string} importLanguage
 * @param {Function} logger function has to be passed by caller as it is
 * only available in the DevContext of a component
 * @returns
 */
export const parseTranslationFromString = (
  filecontent: string,
  importLanguage: string,
  l: Function
) => {
  try {
    const translation = JSON.parse(filecontent);
    // parse metadata and statemachine translations and adapt keys
    const parsedMetadataTranslation: TranslationProps = {};
    const parsedStatemachinetranslation: TranslationProps = {};

    Object.keys(translation).forEach((key: string) => {
      // check key target
      if (key.split('.')[0] === 'metadatatranslation') {
        const origKey = key.replace('metadatatranslation.', '');
        parsedMetadataTranslation[origKey] = translation[key];

        /* .replace(
          /'/g,
          '"'
        ); */
      } else {
        const origKey = key.replace('statemachinetranslation.', '');
        parsedStatemachinetranslation[origKey] = translation[key];
        /* .replace(
          /'/g,
          '"'
        ); */
      }
    });
    // prepare data object for API
    const data = {
      lang: importLanguage,
      metadatatranslation: parsedMetadataTranslation,
      statemachinetranslation: parsedStatemachinetranslation,
    };
    return data;
  } catch (_e) {
    l(_e);
    return undefined;
  }
};
