import { Node } from 'reactflow';

import { TranslationProps } from '../../entities';

/**
 * searches for all variables with name oldVarName in getValueFrom,
 * getValuesFrom and getStringValues and replaces every occurence by
 * newVarName.
 *
 * Attention: if you use same variables for different components, the
 * wrong variable settings may be applied!
 *
 *
 * @param {string} oldVarName
 * @param {string} newVarName
 * @param {Node<any>[]} nodes
 * @returns {Node<any>[]} list oif adapted nodes
 */
export const updateVariablesInNodes = (
  oldVarName: string,
  newVarName: string,
  nodes: Node<any>[]
): Node<any>[] => {
  // private helper for normal variables and generated variables
  const getVarChange = (existingVarname: string) => {
    if (existingVarname === oldVarName) {
      // found variable to be renamed directly
      return newVarName;
    } else {
      // found virtual / generated variable to be renamed
      if (existingVarname.startsWith(oldVarName + '_')) {
        return existingVarname.replace(oldVarName + '_', newVarName + '_');
      } else {
        // variable is not affected, keep value
        return existingVarname;
      }
    }
  };

  return nodes.map((node: Node<any>) => {
    let getValueFrom = node.data.state.entry[0].payload.getValueFrom;
    // if node uses variable in getValueFrom, change it
    if (getValueFrom !== undefined && getValueFrom !== '') {
      node.data.state.entry[0].payload.getValueFrom =
        getVarChange(getValueFrom);
    }

    let getStringValues = node.data.state.entry[0].payload.getStringValues;
    // if node uses variables in getStringValues, change it
    if (getStringValues !== undefined && getStringValues.length > 0) {
      //console.log(node.data.state.entry[0].payload);

      if (!(getStringValues.length === 1 && getStringValues[0].trim() === '')) {
        node.data.state.entry[0].payload.getStringValues = getStringValues.map(
          (existingVarname: string) => {
            return getVarChange(existingVarname);
          }
        );
      }
    }

    let getValuesFrom = node.data.state.entry[0].payload.getValuesFrom;
    // if node uses variable in getValuesFrom array, change it
    if (getValuesFrom !== undefined && getValuesFrom.length > 0) {
      if (!(getValuesFrom.length === 1 && getValuesFrom[0].trim() === '')) {
        node.data.state.entry[0].payload.getValuesFrom = getValuesFrom.map(
          (existingVarname: string) => {
            return getVarChange(existingVarname);
          }
        );
      }
    }

    let formula = node.data.state.entry[0].payload.formula;
    if (formula !== undefined && formula !== '') {
      node.data.state.entry[0].payload.formula = (formula as string).replaceAll(
        oldVarName,
        newVarName
      );
    }

    return node;
  });
};

/**
 * searches for all variables with name oldVarName in message props and
 * replaces every occurence by newVarName. This works by checking for
 * messages that use {<oldVarName>_i} and replace it by {<newVarName>_i}.
 *
 * Attention: if you use same variables for different components, the
 * wrong variable settings may be applied!
 *
 *
 * @param {string} oldVarName
 * @param {string} newVarName
 * @param {Node<any>[]} nodes
 * @param {TranslationProps} statemachine translations
 * @param {addOrUpdateStateMachineTranslation} function to update translation
 * @returns {void}
 */

export const updateVariablesInTranslations = (
  oldVarName: string,
  newVarName: string,
  nodes: Node<any>[],
  stateMachineTranslation: TranslationProps,
  addOrUpdateStateMachineTranslation: Function
): void => {
  nodes.forEach((node: Node<any>) => {
    // check for messages that use {<oldVarName>_i} and replace it by {<newVarName>_i}
    let messageKey = node.data.state.entry[0].payload.message as string;
    if (messageKey !== undefined && messageKey.trim() !== '') {
      const translation = stateMachineTranslation[messageKey];

      if (translation !== undefined && translation.trim() !== '') {
        addOrUpdateStateMachineTranslation(
          messageKey,
          translation.replaceAll(
            '{' + oldVarName + '_i}',
            '{' + newVarName + '_i}'
          )
        );
      }
    }

    return;
  });
};

/**
 * check whether oldVarName is used in more than one component as variable name
 *
 * @param {string} varName
 * @param {Node<any>[]} nodes
 * @returns {boolean} true if at least two components use the same variables
 */
export const isDuplicateVariable = (
  varName: string,
  nodes: Node<any>[]
): boolean => {
  return getDuplicateVariableNodesCount(varName, nodes) >= 2;
};

/**
 * check whether oldVarName is used in more than one component as variable name
 *
 * @param {string} varName
 * @param {Node<any>[]} nodes
 * @returns {boolean} true if at least two components use the same variables
 */
export const getDuplicateVariableNodesCount = (
  varName: string,
  nodes: Node<any>[]
): number => {
  return getDuplicateVariableNodes(varName, nodes).length;
};

/**
 * get all nodes that use the variable wirh name varName
 *
 * @param {string} varName
 * @param {Node<any>[]} nodes
 * @returns {Node<any>[]} list of nodes. May be empty
 */
export const getDuplicateVariableNodes = (
  varName: string,
  nodes: Node<any>[]
): Node<any>[] => {
  return nodes.filter((node: Node<any>) => {
    const saveResultTo = node.data.state.entry[0].payload.saveResultTo;
    // if (saveResultTo !== undefined) console.log(saveResultTo);
    return saveResultTo !== undefined && saveResultTo === varName;
  });
};
