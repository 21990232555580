import { Box, Typography } from '@mui/material';
import React from 'react';

import { Description } from '../../Description';
import { StateEntryProps } from '../../nodes';

interface PropertyHeaderLineProps {
  entry?: StateEntryProps;
  header?: string;
}

export const PropertyHeaderLine: React.FC<PropertyHeaderLineProps> = ({
  entry,
  header,
}) => {
  const localheader = header ?? entry?.displayName ?? 'Properties';

  return (
    <Box sx={{ width: '95%' }}>
      <Typography variant="body2" sx={{ marginBottom: '5px' }}>
        <b style={{ color: '#aa2e25' }}>{localheader}</b>
      </Typography>
      <Description nodetype={entry?.nodeType} propname="description" />
    </Box>
  );
};
