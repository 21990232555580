import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.multipleOutputComponent.state.message',
    defaultMessage:
      'Diese Nachricht erscheint vor den Einträgen, die sortiert werden können.',
  },
  buttonText: {
    id: 'builder.nodes.multipleOutputComponent.state.buttonText',
    defaultMessage: 'Weiter',
  },
  toolTipWarningMessage: {
    id: 'builder.nodes.multipleOutputComponent.state.tooltipWarning',
    defaultMessage:
      'Achtung! Du hast bereits die maximale Anzahl an Elementen ausgewählt!',
  },
});

export const multipleOutputCompositeStateEntry: StateEntryProps = {
  type: 'renderMultipleOutputComposite',
  temporary: true,
  payload: {
    hideDate: false,
    hideSelect: false,
    disableDrag: false,
    saveResultTo: 'multipleoutoutresult',
    getValuesFrom: [''],
    minItems: 1,
    maxItems: 10,
    message: 'builder.nodes.multipleOutputComponent.state.message',
    buttonText: 'builder.nodes.multipleOutputComponent.state.buttonText',
    toolTipWarningMessage:
      'builder.nodes.multipleOutputComponent.state.tooltipWarning',
  },
  saveResultType: [ReadWriteType.typedObject],
  getValueType: [ReadWriteType.stringArray, ReadWriteType.typedObject],
  displayName: 'Multiple Output',
  nodeType: 'multipleOutputCompositeStateEntry',
  handleOutCount: 1,
};
