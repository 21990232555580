import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { AccountContext } from '../../../account/AccountContext';
import { RoleEnum } from '../../../account/AccountContextProps';
import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import { ModuleEditorContext } from '../../ModuleEditor/ModuleEditorContext';
import {
  EmotionProps,
  predefinedEmotionsSet,
  StateEntryProps,
} from '../../nodes';
import { WindowContext } from '../../window/WindowContext';
import { ListEditorDialog } from '../ListEditorDialog/ListEditorDialog';

import { PropertyHeaderLine } from './PropertyHeadLine';

export interface EmotionInputInputProps {
  entry: StateEntryProps;
  updateEmotionInputValues: Function;
  updateOwnEmotionInput: Function;
}

/** EmotionInputInput component
 *
 * @param {StateEntryProps} entry - complete entry of the EmotionInput node
 * @param {Function} updateEmotionInputValues - handler to pass the index of the selected value
 *
 * TODO in a future version, this control may even handle "custom lists" with an editor of texts+icons. We may use PolarChartInput as template
 */
export const EmotionInputInput: React.FC<EmotionInputInputProps> = ({
  entry,
  updateEmotionInputValues,
  updateOwnEmotionInput,
}) => {
  const intl = useIntl();

  const { alert } = useContext(WindowContext);
  const { moduleLoadedInDefaultLanguage } = useContext(ModuleEditorContext);

  const [currentEmotionListSelection, setCurrentEmotionListSelection] =
    useState<number>(
      entry.predefinedEmotionsIndex
        ? entry.predefinedEmotionsIndex
        : entry.payload.predefinedEmotionsIndex ?? 0
    );

  const [ownList, setOwnList] = useState<boolean>(
    entry.useOwnElements ?? false
  );

  const displayNotDefaultError = () => {
    alert(
      intl.formatMessage({
        id: 'builder.updateemotioninput.notindefaultlang',
        defaultMessage:
          'Du kannst deinem Chatbot nur dann neue Elemente hinzufügen oder Listen in Elementen ändern, wenn du ihn in der Default-Sprache geladen hast.',
      })
    );
  };

  /** tooglw whether to use own list of pre-defined list and reset elements */
  const toggleOwnList = () => {
    if (!moduleLoadedInDefaultLanguage) {
      displayNotDefaultError();
      return;
    }

    // delete existing elements and make place for own list ==> index =-1
    if (!ownList) {
      updateEmotionInputValues(-1); // make place for own emotions
    } else {
      // update with last choosen pre-defined list
      updateEmotionInputValues(currentEmotionListSelection);
    }
    setOwnList(!ownList);
  };

  const { hasRole } = useContext(AccountContext);

  /** update the emotions with a pre-defined emotions list  */
  const updateEmotionList = (value: number) => {
    if (!moduleLoadedInDefaultLanguage) {
      displayNotDefaultError();
      return;
    }
    setCurrentEmotionListSelection(value);
    updateEmotionInputValues(value);
  };

  // entry.ownElements?.filter((element: any) => element) ?? []
  const [ownEmotions, setOwnEmotions] = useState<EmotionProps[]>(
    (entry.payload.elements ?? [])
      .map((elem: any) => elem as EmotionProps)
      .filter((element: any) => element) ?? []
  );

  const [isListEditorOpen, setIsListEditorOpen] = useState<boolean>(false);

  const handleListEditorDialog = () => {
    setIsListEditorOpen(!isListEditorOpen);
  };

  // update elements from Emotiondialog
  const updateElements = (elements: EmotionProps[]) => {
    setOwnEmotions(elements);
    updateOwnEmotionInput(elements);
  };

  return (
    <div>
      {!ownList && (
        <>
          <PropertyHeaderLine
            header={intl.formatMessage({
              id: 'builder.moduleeditor.propertiessidebar.emotionsinput.predefined',
              defaultMessage: 'Vordefinierte Emotionenliste auswählen',
            })}
          />
          <EvoachSelect
            value={currentEmotionListSelection}
            onChange={(event) => {
              updateEmotionList(event.target.value as number);
            }}
          >
            {predefinedEmotionsSet.map((emotionlist: any, index: number) => (
              <EvoachMenuItem key={`EmotionListEntry${index}`} value={index}>
                {intl.formatMessage({
                  id: emotionlist.emotionsListName,
                })}
              </EvoachMenuItem>
            ))}
          </EvoachSelect>
        </>
      )}
      {ownList && (
        <>
          <Button color="secondary" onClick={handleListEditorDialog}>
            {intl.formatMessage({
              id: 'builder.moduleeditor.propertiessidebar.emotionsinput.ownlistdialog',
              defaultMessage: 'Eigene Emotionenliste editieren',
            })}
          </Button>
        </>
      )}

      {hasRole(RoleEnum.EVOACHADMIN) && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={ownList}
                onChange={toggleOwnList}
                key="ownEmotionsListCheckbox"
                id="ownEmotionsListCheckboxId"
              />
            }
            label={
              <Typography variant="body2" component="span">
                {intl.formatMessage({
                  id: 'builder.moduleeditor.propertiessidebar.emotionsinput.checkboxtext',
                  defaultMessage: 'Eigene Emotionenliste verwenden',
                })}
              </Typography>
            }
          />
        </FormGroup>
      )}

      {ownList && (
        <ListEditorDialog
          listelements={ownEmotions}
          open={isListEditorOpen}
          onClose={handleListEditorDialog}
          updateElements={updateElements}
        />
      )}
    </div>
  );
};
