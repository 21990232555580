import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import React from 'react';
import { Node } from 'reactflow';

import {
  isGetStringValueFromKey,
  ReadWriteType,
  StateEntryProps,
} from '../../nodes';

import { GetValueFromSelect } from './GetValueFromSelect';
import { PropertyHeaderLine } from './PropertyHeadLine';

interface GetStringValueFromProps {
  entry: StateEntryProps;
  updateGetStringValueFrom: Function;
  nodes: Node<any>[];
}

export const GetStringValueFrom: React.FC<GetStringValueFromProps> = ({
  entry,
  updateGetStringValueFrom,
  nodes,
}) => {
  const intl = useIntl();
  return (
    <Stack spacing={1}>
      {Object.keys(entry.payload)
        .filter(isGetStringValueFromKey)
        .map((payloadKey: string) => {
          // prototype: allow strings, only
          const getValueType = [
            ReadWriteType.string,
            ReadWriteType.stringArray,
            ReadWriteType.number,
            ReadWriteType.numberArray,
          ];
          return (
            <div key={`ElementIdAndKey-${payloadKey}`}>
              <PropertyHeaderLine
                header={intl.formatMessage({
                  id: 'builder.moduleeditor.propertiesSideBar.allowedVariables',
                  defaultMessage: 'Erlaubte Textvariablen',
                })}
              />
              <GetValueFromSelect
                updateValueFrom={updateGetStringValueFrom}
                currentValue={entry.payload[payloadKey] as string[]}
                multiselect
                getValueType={getValueType}
                nodes={nodes}
              />
            </div>
          );
        })}
    </Stack>
  );
};
