import { useContext } from 'react';
import { useQuery } from 'react-query';

import { ModuleProps } from '../../entities/Module';
import { TranslationContext as GlobalTranslationContext } from '../../intl/TranslationContext';
import { authorizedGet } from '../authorizedApi';
import { TranslationContext } from '../../components/stateMachineTranslationContext';
import { getAssetUrl } from '../asset/useFetchAssetUrl';
//import { useEnvironment } from '../../environment/useEnvironment';
//import { mapImageToPublicAsset } from '../../components/reception/assets/AssetHelper';

/**
 * useFetchModules
 *
 * if you make changes here, consider reviweing useFetchModulesQuery
 * which is slightly different in managing languages
 */

export const useFetchModules = (
  enforcefallback?: boolean,
  issubmodule?: boolean,
  withdefaultmodules?: boolean
) => {
  const { locale, appendStaticMessages } = useContext(GlobalTranslationContext);
  const { stateMachineLocale } = useContext(TranslationContext);
  //const { playerBasePath } = useEnvironment();

  const currentLocale = stateMachineLocale ?? locale;

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    'modulesData',
    async () => {
      const getModulesURL = `/module?language=${currentLocale}${
        enforcefallback !== undefined
          ? '&enforcefallback=' + enforcefallback
          : ''
      }${issubmodule !== undefined ? '&issubmodule=' + issubmodule : ''}${
        withdefaultmodules !== undefined
          ? '&withdefaultmodules=' + withdefaultmodules
          : ''
      }`;

      console.log(getModulesURL);

      const fetchModulesCall = authorizedGet(getModulesURL);
      const response = await fetchModulesCall();

      const data: ModuleProps[] = await response.json();

      // resolve images for list
      for (let i = 0; i < data.length; i++) {
        if (data[i].metadata.assetid && data[i].metadata.assetid !== '') {
          data[i].metadata.resolvedsrc = await getAssetUrl(
            data[i].metadata.assetid ?? ''
          );
        } else {
          if (data[i].metadata.src && data[i].metadata.src !== '') {
            data[i].metadata.resolvedsrc = data[i].metadata.src;
          } else {
            if (data[i].metadata.image !== undefined) {
              //
              data[i].metadata.resolvedsrc = '';
              /*
              TODO this is not yet possible as we have to refactor UI components first - code already working

              data[i].metadata.resolvedsrc = mapImageToPublicAsset(
                data[i].metadata.image,
                playerBasePath
              );
              */
            } else {
              data[i].metadata.resolvedsrc = '';
            }
          }
        }
      }

      // get meta data translations for list
      const translationsOfAllModules = data
        .filter((module: ModuleProps) => Array.isArray(module.translations))
        .map((currentModule: ModuleProps) => {
          const allTranslations = currentModule.translations;
          const metaDataTranslationsInCurrentLanguage = allTranslations
            .filter((translations: any) => translations.lang === currentLocale)
            .map((translations: any) => translations.metadatatranslation)
            .reduce(
              (prev: any, metaTranslations: any) => ({
                ...prev,
                ...metaTranslations,
              }),
              {}
            );

          return metaDataTranslationsInCurrentLanguage;
        })
        .reduce(
          (prev: any, metaTranslations: any) => ({
            ...prev,
            ...metaTranslations,
          }),
          {}
        );

      // changed for PROD-1528, previously appendModuleMessages
      appendStaticMessages(translationsOfAllModules);

      return data;
    },
    {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    }
  );

  return {
    isLoading,
    isError,
    error,
    modules: data as ModuleProps[],
    refetch,
  };
};
