import { defineMessages } from '@formatjs/intl';
import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import { useEnvironment } from '../../../environment/useEnvironment';

const listButtonTexts = defineMessages({
  sharedSessionViewButton: {
    id: 'builder.components.modulelist.sharedsessionviewbutton.text',
    defaultMessage: 'Session anschauen',
  },
});

/*
    ! Button to resume a session
    resumes a session with the sessionid
*/

export const ViewSessionButton: React.FC = (params: any) => {
  const intl = useIntl();

  const { playerBasePath } = useEnvironment();
  const [previewwindow, setpreviewwindow] = useState<any>(undefined);

  const startSessionLink = (sessionid: string) => {
    if (sessionid && typeof sessionid === 'string') {
      // for Window handling, s. also https://developer.mozilla.org/en-US/docs/Web/API/Window/open
      // it isn't as easy as it seems!
      let newpreviewwindow: Window | null = previewwindow;

      if (!newpreviewwindow || newpreviewwindow.closed) {
        newpreviewwindow = window.open(
          `${playerBasePath}/coaching/${sessionid}`,
          'coachingbotpreviewwindow'
        );
      }

      if (newpreviewwindow) {
        newpreviewwindow.focus();
        setpreviewwindow(newpreviewwindow);
        newpreviewwindow.location =
          `${playerBasePath}/coaching/${sessionid}` as unknown as Location;
      }
    }
  };

  // params.row.moduleid
  return (
    <Tooltip
      title={intl.formatMessage(listButtonTexts.sharedSessionViewButton)}
    >
      <IconButton onClick={() => startSessionLink(params.row.sessionid)}>
        <PlayCircleIcon color="secondary" fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};
