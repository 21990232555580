import { SupportedModuleLocales } from '../intl/SupportedLocales';

import { PrintDocumentTypeEnum } from './PrintTaskTypes';

/**
 * generic description of a template
 */
export type PrintTemplate = {
  /**
   * templateid = UUID
   */
  id: string;
  /**
   * title of the template to be displayed in apps (for evoachAdmin or some coaches)
   */
  title: string;
  /**
   * template type
   */
  type: PrintDocumentTypeEnum;
  /**
   * list of languages for which the template is available
   */
  languages: SupportedModuleLocales[];
  /**
   * for internal tests only!
   * This is a marker to check whether the generated HTML of a template is the correct one.
   * It should have the format: "DE" : "mytext". If no marker is provider, the
   * marker is not tested by Jest
   */
  testMarker?: { [k: string]: string };
};

export const DefaultPrintTemplate = {
  id: 'ea11fdaf-3bda-4f1f-bfe2-510105d303ed',
  title: 'evoach Certificate',
  type: PrintDocumentTypeEnum.CERTIFICATE,
  languages: [SupportedModuleLocales.DE],
  testMarker: { DE: '<img alt="evoach" src="evoach' },
};
