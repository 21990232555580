import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useFetchProgramOrInstance } from '../api/program/useFetchProgram';
import { ErrorAccordion } from '../components/ErrorAccordion';
import { ProgramEditor } from '../components/ProgramEditor/ProgramEditor';
import { ProgramRouteParams } from '../routing/routes';

/*
  ProgramEditorPage

  Pls check call hierarchy with responsibilities below

!  ProgramEditorPage                     - page for router, check route params, load module
    |_ ProgramEditor                    - basic layout and mutate function, manage selected module
        |_ ProgramMetaEditor            - edit basic information for a program
        |_ ProgramModuleListTimeline    - edit timeline / order of modules
        |_ ProgramModuleEditor          - edit a selected module

 */

export const ProgramEditorPage: React.FC = () => {
  const { programId, programRouteType } = useParams<ProgramRouteParams>();

  // load program, Mutate / save operations happen on next level in ProgramEditor
  const { isLoading, isError, error, program, refetch } =
    useFetchProgramOrInstance(programId, programRouteType);

  const intl = useIntl();

  return (
    <>
      {isLoading && (
        <Typography>
          {intl.formatMessage({
            id: 'builder.programs.programeditorpage.load.message',
            defaultMessage: 'Programm wird geladen ...',
          })}
        </Typography>
      )}
      {isError && (
        <ErrorAccordion
          readableErrorMessage={intl.formatMessage({
            id: 'builder.programs.programeditorpage.load.errormessage',
            defaultMessage:
              'Beim Laden des Programms ist ein Fehler aufgetreten.',
          })}
          error={error}
        />
      )}
      {!isLoading && !isError && program && (
        <ProgramEditor
          program={program}
          programRouteType={programRouteType}
          programId={programId}
          refetchProgram={refetch}
        />
      )}
    </>
  );
};
