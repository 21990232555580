import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  placeholderText: {
    id: 'builder.nodes.messageInput.state.placeholderText',
    defaultMessage: 'Schreibe einfach mal drauf los… ',
  },
});

export const messageInputStateEntry: StateEntryProps = {
  type: 'renderMessageInput',
  temporary: true,
  payload: {
    placeholderText: 'builder.nodes.messageInput.state.placeholderText',
    rowCount: '1',
    milliSeconds: '0',
    saveResultTo: 'currentThankName',
    emoticons: true,
    getValueFrom: '',
  },
  getValueType: ReadWriteType.string,
  saveResultType: ReadWriteType.string,
  displayName: 'Message Input',
  nodeType: 'messageInputStateEntry',
  handleOutCount: 1,
};
