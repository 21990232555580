import { ImageItem } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';
import { ComponentSize } from '@evoach/ui-components';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  buttonText: {
    id: 'builder.nodes.imageselector.state.buttontext',
    defaultMessage: 'Weiter',
  },
  collaborate: {
    id: 'builder.nodes.imageselector.state.dummydata.collaborate',
    defaultMessage: 'Zusammenarbeit',
  },
  acceptance: {
    id: 'builder.nodes.imageselector.state.dummydata.acceptance',
    defaultMessage: 'Akzeptanz',
  },
  supervision: {
    id: 'builder.nodes.imageselector.state.dummydata.supervision',
    defaultMessage: 'Aufsicht',
  },
  acceptanceurl: {
    id: 'builder.nodes.imageselector.state.dummydata.acceptanceurl',
    defaultMessage:
      'https://staging.player.evoach.de/assets/cardimages2/acceptance.png',
  },
  collaborateurl: {
    id: 'builder.nodes.imageselector.state.dummydata.collaborateurl',
    defaultMessage:
      'https://staging.player.evoach.de/assets/cardimages2/collaborate.png',
  },
  supervisionurl: {
    id: 'builder.nodes.imageselector.state.dummydata.supervisionurl',
    defaultMessage:
      'https://staging.player.evoach.de/assets/cardimages2/h_supervision.png',
  },
});

//
// src contains external URL
// assetId contains assetid or ' ' of internal asset
// value contais description text
// all values have to be translated
//
const defaultImageList: ImageItem[] = [
  {
    type: 'imageselect',
    src: 'builder.nodes.imageselector.state.dummydata.acceptanceurl',
    assetid: ' ', //space is mandatory
    value: 'builder.nodes.imageselector.state.dummydata.acceptance',
    resolvedsrc:
      'https://staging.player.evoach.de/assets/cardimages2/acceptance.png',
  },
  {
    type: 'imageselect',
    src: 'builder.nodes.imageselector.state.dummydata.collaborateurl',
    assetid: ' ', //space is mandatory
    value: 'builder.nodes.imageselector.state.dummydata.collaborate',
    resolvedsrc:
      'https://staging.player.evoach.de/assets/cardimages2/collaborate.png',
  },
  {
    type: 'imageselect',
    src: 'builder.nodes.imageselector.state.dummydata.supervisionurl',
    assetid: ' ', //space is mandatory
    value: 'builder.nodes.imageselector.state.dummydata.supervision',
    resolvedsrc:
      'https://staging.player.evoach.de/assets/cardimages2/h_supervision.png',
  },
];

export const imageSelectorStateEntry: StateEntryProps = {
  type: 'renderImageSelector',
  temporary: true,
  payload: {
    images: defaultImageList,
    buttonText: 'builder.nodes.nextButton.state.buttonText',
    componentSize: ComponentSize.SMALL,
    maxItems: 1,
    minItems: 1,
    showTitles: true,
    saveResultTo: 'imageSelectorResult',
  },
  saveResultType: ReadWriteType.typedObject,
  displayName: 'Image Selector',
  nodeType: 'imageSelectorStateEntry',
  handleOutCount: 1,
};
