import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.sceneCanvas.state.buttonText',
    defaultMessage: 'Weiter',
  },
  fullscreenTooltipMessage: {
    id: 'builder.nodes.sceneCanvas.state.fullscreenTooltipMessage',
    defaultMessage: 'Arbeitsfläche maximieren',
  },
});

/**
 * Scene Canvas xState specific defintions
 */
export const canvasEditorStateEntry: StateEntryProps = {
  version: 1,
  type: 'renderCanvasEditor',
  temporary: true,
  payload: {
    getValueFrom: '',
    saveResultTo: 'canvasEditorResult',
    buttonText: 'builder.nodes.sceneCanvas.state.buttonText',
    tooltipFullscreenMessage:
      'builder.nodes.sceneCanvas.state.fullscreenTooltipMessage',
  },
  getValueType: [ReadWriteType.characters],
  saveResultType: [ReadWriteType.characters],
  displayName: 'Constellation Board',
  nodeType: 'canvasEditorStateEntry',
  handleOutCount: 1,
};
