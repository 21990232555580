import { defineMessages } from '@formatjs/intl';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { authorizedDelete, useFetchInvitations } from '../../../api';
import { useEnvironment } from '../../../environment/useEnvironment';
import { useTableStyles } from '../../tableHelper';
import { WindowContext } from '../../window/WindowContext';

import { DeleteDialog } from './DeleteDialog';
import { InvitationDetailsDialog } from './InvitationDetailsDialog';

const invitationListTexts = defineMessages({
  createdheader: {
    id: 'builder.reception.invitation.listheader.created',
    defaultMessage: 'Erstellt am',
  },
  includedmodulesheader: {
    id: 'builder.reception.invitation.listheader.includedmodules',
    defaultMessage: 'Enthaltene Module',
  },
  noteheader: {
    id: 'builder.reception.invitation.listheader.note',
    defaultMessage: 'Notiz',
  },
  acceptedheader: {
    id: 'builder.reception.invitation.listheader.accepted',
    defaultMessage: 'Akzeptiert von',
  },
  linkheader: {
    id: 'builder.reception.invitation.listheader.link',
    defaultMessage: 'Einladungslink',
  },
  deleteheader: {
    id: 'builder.reception.invitation.listheader.delete',
    defaultMessage: 'Löschen',
  },
  person: {
    id: 'builder.reception.invitation.person',
    defaultMessage: 'Personen',
  },
  confirm: {
    id: 'builder.reception.invitation.confirm',
    defaultMessage: 'Bist du sicher, dass du die Einladung löschen möchtest?',
  },
  copiedToClipboard: {
    id: 'builder.reception.invitation.copiedtoclipboard',
    defaultMessage: 'In Zwischenablage kopiert!',
  },
  emptyDefaultMessage: {
    id: 'builder.reception.invitation.emptydefaultmessage',
    defaultMessage: 'Du hast bisher noch keine Coachees eingeladen.',
  },
});

interface InvitationListProps {}

export const InvitationList: React.FC<InvitationListProps> = () => {
  const intl = useIntl();
  const classes = useTableStyles();
  const { invitations, refetch: refetchInvitations } = useFetchInvitations();
  const { playerBasePath } = useEnvironment();
  const { alert } = useContext(WindowContext);

  const [invitationDialogId, setInvitationDialogId] = useState<string>('');
  const [isInvitationDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [deleteDialogId, setDeleteDialogId] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsDialogOpen(invitationDialogId !== '');
  }, [invitationDialogId]);

  useEffect(() => {
    setIsDeleteDialogOpen(deleteDialogId !== '');
  }, [deleteDialogId]);

  const deleteInvitation = async (
    invitationid: string,
    isCascading: boolean
  ) => {
    const deletionurl: RequestInfo = `/invitation/${invitationid}`;
    const body = {
      isCascading: isCascading,
    };
    const deleteCall = authorizedDelete(deletionurl, body);
    await deleteCall();
  };

  /*
  Format of invitations, array of: 

    {
        modulepermissionid: '578d073d-c354-4c8b-9949-ecbda013f7d6',
        tscreated: 2023-03-30T12:50:49.468Z,
        CoachPermission_InviteCoacheesToSessions: false,
        CoachPermission_CopyModuleAsTemplate: false,
        CoachPermission_EditOriginalModule: false,
        CoacheePermission_StartSession: true,
        invitationid: '6710d70d-0dd8-4d68-9b4b-c915db2e47c6',
        invitinginvitationid: null,
        note: '',
        module: undefined,
        modules: [ 'FirstModule', 'SecondModule' ]
      }
  */
  const rows = useMemo(() => {
    return invitations
      ? invitations
          .filter((i: any) => i) // delete all null and undefined stuff
          .filter(
            (i: any) =>
              i.CoachPermission_InviteCoacheesToSessions !== true &&
              i.CoachPermission_CopyModuleAsTemplate !== true &&
              i.CoachPermission_EditOriginalModule !== true
          )
          .map((invitation: any, mindex: number) => {
            return {
              id: mindex,
              invitationId: invitation.invitationid,
              invitationCreatedRaw: new Date(invitation.tscreated), // needed for proper sorting, s. below
              invitationCreated: new Intl.DateTimeFormat(intl.locale, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }).format(Date.parse(invitation.tscreated + '')),
              modules: invitation.modules.join(', '),
              accountsAccepted: invitation.accountsAccepted ?? 0,
              note: invitation.note,
              invitationLink:
                playerBasePath + `/invitation/` + invitation.invitationid,
            };
          })
          .sort(
            (a: any, b: any) => b.invitationCreatedRaw - a.invitationCreatedRaw
          )
      : [];
  }, [invitations, intl, playerBasePath]);
  /*
  const rows = useMemo(() => {
    return invitations
      ? invitations
          .filter((invitation: any) =>
            invitation.rights
              ? !Object.values(invitation.rights).some((right) => right)
              : false
          )
          .map((invitation: any, mindex: number) => {
            return {
              id: mindex,
              invitationId: invitation.invitationid,
              invitationCreatedRaw: new Date(invitation.tscreated), // needed for proper sorting, s. below
              invitationCreated: new Intl.DateTimeFormat(intl.locale, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }).format(Date.parse(invitation.tscreated + '')),
              modules: invitation.modules
                .map((module: any) => {
                  return intl.formatMessage({
                    id: module.metadata.title,
                  });
                })
                .join(', '),
              accountsAccepted: invitation.accountsAccepted.length,
              note: invitation.note,
              invitationLink:
                playerBasePath + `/invitation/` + invitation.invitationid,
            };
          })
          .sort(
            (a: any, b: any) => b.invitationCreatedRaw - a.invitationCreatedRaw
          )
      : [];
  }, [invitations, intl, playerBasePath]);
*/
  const handleCopyInvitationUrl = (invitationUrl: string) => {
    navigator.clipboard
      .writeText(invitationUrl)
      .then(() =>
        alert(intl.formatMessage(invitationListTexts.copiedToClipboard))
      );
  };

  return (
    <>
      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogId('')}
        onDelete={(isCascading) =>
          deleteInvitation(deleteDialogId, isCascading).then(() =>
            refetchInvitations()
          )
        }
      />
      <InvitationDetailsDialog
        open={isInvitationDialogOpen}
        onClose={() => setInvitationDialogId('')}
        invitationid={invitationDialogId}
        csvFileName="invitation"
      />
      {rows.length > 0 ? (
        <TableContainer
          component={Paper}
          style={{ minWidth: '800px', minHeight: '100%', overflowX: 'initial' }}
        >
          <Table
            stickyHeader
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                paddingBottom: '0.5rem',
              },
            }}
          >
            <colgroup>
              <col style={{ width: '13%', textAlign: 'left' }} />
              <col style={{ width: '20%', textAlign: 'left' }} />
              <col style={{ width: '15%', textAlign: 'left' }} />
              <col style={{ width: '20%', textAlign: 'left' }} />
              <col style={{ width: '24%', textAlign: 'left' }} />
              <col style={{ width: '8%', textAlign: 'center' }} />
            </colgroup>
            <TableHead className={classes.noWrapChildren}>
              <TableRow>
                <TableCell>
                  {intl.formatMessage(invitationListTexts.createdheader)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(
                    invitationListTexts.includedmodulesheader
                  )}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(invitationListTexts.acceptedheader)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(invitationListTexts.linkheader)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(invitationListTexts.noteheader)}
                </TableCell>
                <TableCell align="center">
                  {intl.formatMessage(invitationListTexts.deleteheader)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.noWrap}
                  >
                    {row.invitationCreated}
                  </TableCell>
                  <TableCell>
                    <Tooltip placement="top" arrow title={row.modules}>
                      <div className={classes.longTextNoWrap}>
                        {row.modules}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {row.accountsAccepted > 0 ? (
                      <Link
                        color="inherit"
                        className={classes.cursorPointer}
                        onClick={() => setInvitationDialogId(row.invitationId)}
                      >
                        {row.accountsAccepted +
                          ' ' +
                          intl.formatMessage(invitationListTexts.person)}
                      </Link>
                    ) : (
                      <>
                        {row.accountsAccepted +
                          ' ' +
                          intl.formatMessage(invitationListTexts.person)}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span className={classes.longTextNoWrap}>
                      {row.invitationLink}
                    </span>
                    <ContentCopyIcon
                      className={classes.cursorPointer}
                      color="secondary"
                      onClick={() =>
                        handleCopyInvitationUrl(row.invitationLink)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {row.note !== undefined && (
                      <Tooltip placement="top" arrow title={row.note}>
                        <div className={classes.longTextNoWrap}>{row.note}</div>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <DeleteIcon
                      className={classes.cursorPointer}
                      color="secondary"
                      onClick={() => setDeleteDialogId(row.invitationId)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>
          {intl.formatMessage(invitationListTexts.emptyDefaultMessage)}
        </Typography>
      )}
    </>
  );
};
