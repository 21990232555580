import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import { cloneDeep } from 'lodash';
import { generateRandomString } from '@evoach/ui-components';

import { StateEntryProps } from '../../nodes';

import { PropertyHeaderLine } from './PropertyHeadLine';

export interface ScaleSizeInputProps {
  entry: StateEntryProps;
  updateElementValues: Function;
}

export const ScaleSizeInput: React.FC<ScaleSizeInputProps> = ({
  entry,
  updateElementValues,
}) => {
  const intl = useIntl();

  const [error, setError] = useState<boolean>(
    !entry.payload.scaleSize ||
      entry.payload.scaleSize < 0 ||
      entry.payload.scaleSize > 10
  );

  const [currScaleSize, setCurrScaleSize] = useState<string>(
    entry.payload.scaleSize ? entry.payload.scaleSize.toString() : ''
  );

  const [currKeyTexts, setCurrKeyTexts] = useState<string[]>(
    entry.payload.keyTexts ? entry.payload.keyTexts : []
  );

  const updateElements = (scaleSizeString: string) => {
    const scaleSize = parseInt(scaleSizeString);
    if (!isNaN(scaleSize) && scaleSize > 0 && scaleSize <= 10) {
      const keyTexts = currKeyTexts ? cloneDeep(currKeyTexts) : [];
      const scaleSizeKeysDiff = keyTexts.length - scaleSize;

      //append or delete elements from keyText depending on scaleInput number
      if (scaleSizeKeysDiff < 0) {
        for (var i = 0; i > scaleSizeKeysDiff; i--) {
          keyTexts.push('scaleinputmulti.' + generateRandomString(4));
        }
      } else if (scaleSizeKeysDiff > 0) {
        for (var j = 0; j < scaleSizeKeysDiff; j++) {
          keyTexts.pop();
        }
      }
      setCurrKeyTexts(keyTexts);
      updateElementValues(scaleSize, keyTexts);
      setCurrScaleSize(scaleSizeString);
      setError(false);
    } else {
      setError(true);
      setCurrScaleSize(scaleSizeString);
    }
  };

  return (
    <div>
      <PropertyHeaderLine
        header={intl.formatMessage({
          id: 'builder.moduleeditor.propertiesSidebar.scaleSizeInput.header',
          defaultMessage: 'Größe der Skala',
        })}
      />
      <br />
      <TextField
        id="donotcatchpasteevent_scaleSize"
        value={currScaleSize}
        onChange={(evt) => {
          updateElements(evt.target.value);
        }}
        key="scaleSize"
        autoComplete="off"
        autoCorrect="off"
        style={{
          width: '95%',
        }}
        color={error ? 'error' : 'secondary'}
      />
    </div>
  );
};
