import React, { useContext, useEffect, useState } from 'react';
//import { FormattedMessage } from 'react-intl';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import LinkIcon from '@mui/icons-material/Link';
import { CopyLink } from '@evoach/ui-components';

import { authorizedPut } from '../../api/authorizedApi';
import { useFetchModuleEmbed } from '../../api/module/useFetchModuleEmbed';
import { useEnvironment } from '../../environment/useEnvironment';
import { ModuleProps } from '../../entities/Module';
import { DevToolsContext } from '../../devtools/DevToolsContext';
import { TranslationContext } from '../stateMachineTranslationContext';
import { ApiError } from '../../api/ApiError';

//import { authorizedDelete, authorizedGet, authorizedPost } from '../../api';

export interface ModuleExternalEmbeddingButtonProps {
  module: ModuleProps;
  isLoading: boolean;
  onUpdate: Function;
}

export const ModuleExternalEmbeddingButton: React.FC<
  ModuleExternalEmbeddingButtonProps
> = (props) => {
  const intl = useIntl();
  const { playerBasePath } = useEnvironment();
  const { module, isLoading, onUpdate } = props;
  const { l } = useContext(DevToolsContext);
  const { stateMachineLocale } = useContext(TranslationContext);

  const checkForOwnership = () => {
    let isOwner = false;
    module.permissions.forEach((permission) => {
      if (permission.owns === true) {
        isOwner = true;
      }
    });
    return isOwner;
  };
  const isNotOwner = !checkForOwnership();

  const moduleid = module.moduleid;

  const { embeddingInformation, error } = useFetchModuleEmbed(moduleid);

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [isEmbeddable, setIsEmbeddable] = useState<boolean>(false);
  const [displayProgressbar, setdisplayProgressbar] = useState<boolean>(
    module.publicsessionprogressbar === undefined ||
      module.publicsessionprogressbar === null
      ? false
      : module.publicsessionprogressbar
  );
  const [referrerList, setReferrerList] = useState<string[]>(
    embeddingInformation?.allowedReferrer ?? []
  );

  // chatbot is not embeddable (checkbox) if no allowedReferrer was set
  // this is also the case if the user sets the checkbox and do not enter a
  // valid referrer URL
  useEffect(() => {
    const isembed =
      embeddingInformation &&
      embeddingInformation.allowedReferrer &&
      Array.isArray(embeddingInformation.allowedReferrer) &&
      embeddingInformation.allowedReferrer.length > 0;
    setIsEmbeddable(isembed);
    if (isembed) {
      setReferrerList(embeddingInformation.allowedReferrer);
    }
  }, [embeddingInformation]);

  const toggleEmbeddable = () => {
    setIsEmbeddable(!isEmbeddable);
  };

  const toggleDisplayProgressbar = () => {
    module.publicsessionprogressbar = !displayProgressbar;
    l(
      'ModuleExternalEmbeddingButton: module.publicsessionprogressbar: ' +
        module.publicsessionprogressbar
    );
    setdisplayProgressbar(!displayProgressbar);
  };

  const putDyn = async () => {
    if (isLoading || !moduleid) return;
    const url = `/module/${moduleid}/external`;
    const payload = { allowedReferrer: referrerList };
    try {
      const fetchDyn = authorizedPut(url, payload);
      const response = await fetchDyn();
      const data = await response.json();
      return data;
    } catch (_e: any) {
      console.log(_e);
    }
  };

  const onChangeRefererList = (reflist: string) => {
    // allow "," to separate several referers but remove spaces
    // remove trailing "/" if link is pasted from browser
    setReferrerList(
      reflist
        .split(',')
        .map((ref: string) => ref.trim())
        .map((ref: string) =>
          ref.endsWith('/') &&
          !(
            ref.endsWith('http:/') ||
            ref.endsWith('https:/') ||
            ref.endsWith('http://') ||
            ref.endsWith('https://')
          )
            ? ref.slice(0, -1)
            : ref
        )
    );
  };

  //const embedcode = `<iframe title="evoach Chatbot" src="${playerBasePath}/public/${moduleid}/DE" style="overflow:scroll;width:500px;height:700px;border:solid 1px #000000;"></iframe>`;
  //console.log('rel = ' + referrerList);
  //console.log('m.p = ' + module.publicsessionprogressbar);

  const embedcode = `<script>
  moduleid='${moduleid}';
  lang='${stateMachineLocale}';
  variant=0;
  dn=Date.now();
  document.write('<script src="${playerBasePath}/embed/evoachelem.js?dn='+dn+'"><'+'/'+'script>');
  document.write('<script id="evoachscript"  src="${playerBasePath}/embed/evoach.js?moduleid='+moduleid+'&lang='+lang+'&variant='+variant+'&dn='+dn+'"><'+'/'+'script>')
  </script>`;

  const linkText = `${playerBasePath}/publicplayer/${moduleid}/${stateMachineLocale}`;

  let embedPerm = true;
  if (error instanceof ApiError) {
    // API returns 403 if a user without "owns" permissions tries to read or
    // update settings for an embeddable module
    if (error.httpStatus === 403) {
      embedPerm = false;
    }
  }

  return (
    <>
      <Tooltip
        placement="top"
        arrow
        title={
          isNotOwner
            ? intl.formatMessage({
                id: 'builder.moduleeditor.externalembedding.icontooltipNotowner',
                defaultMessage:
                  'Nur der Besitzer des Chatbots darf das Einbetten in eine externe Webseite verwalten.',
              })
            : intl.formatMessage({
                id: 'builder.moduleeditor.externalembedding.icontooltip',
                defaultMessage: 'Chatbot in externe Webseiten einbetten.',
              })
        }
      >
        <span>
          <IconButton
            onClick={() => {
              setDialogIsOpen(true);
            }}
            disabled={
              false || isLoading || moduleid === undefined || isNotOwner
            }
          >
            <LinkIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Dialog fullWidth open={dialogIsOpen}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'builder.moduleeditor.externalembedding.dialogtitle',
            defaultMessage: 'Modul in externe Webseiten einbetten',
          })}
        </DialogTitle>
        <DialogContent>
          {embedPerm && (
            <Stack spacing={2}>
              <Typography component="span">
                {intl.formatMessage({
                  id: 'builder.moduleeditor.externalembedding.dialogdescription',
                  defaultMessage:
                    'Du kannst das Modul in eine externe Webseite einbetten. Dann kann es ohne Registrierung bei evoach genutzt werden. Du siehst dann aber nicht, wer das Modul genutzt hat.',
                })}
              </Typography>
              <Typography component="span">
                <Checkbox
                  id="embeddableModuleCheckbox"
                  key="embeddableModuleCheckbox"
                  checked={isEmbeddable}
                  onChange={toggleEmbeddable}
                />
                {intl.formatMessage({
                  id: 'builder.moduleeditor.externalembedding.dialogcheckboxembed',
                  defaultMessage: 'Ja, Modul in externe Webseiten einbetten',
                })}
              </Typography>
              {isEmbeddable && (
                <>
                  <Typography component="span">
                    <Checkbox
                      id="embeddableModuleCheckboxForProgressbar"
                      key="embeddableModuleCheckboxForProgressbar"
                      checked={displayProgressbar}
                      onChange={toggleDisplayProgressbar}
                    />
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.externalembedding.dialogdidsplayprogressbar',
                      defaultMessage:
                        'Phasen und Avatar beim Einbetten anzeigen',
                    })}
                  </Typography>
                  <Typography component="span">
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.externalembedding.refererinfo',
                      defaultMessage:
                        'Gib alle Domains an, in denen du das Modul einbetten möchtest. Gib die URLs durch ein Komma getrennt ein. Achte darauf, dass keine Leerzeichen dazwischen sind. Wenn du hier keine URL eingibst, funktioniert der Chatbot nicht in der entsprechenden Seite.',
                    })}
                    <TextField
                      id="donotcatchpasteevent_refererembeddable"
                      placeholder="https://www.mycoachingwebsite.org,https://mycoachingwebsite.org"
                      key="refererlist"
                      value={referrerList}
                      onChange={(evt: any) => {
                        onChangeRefererList(evt.target.value);
                      }}
                      sx={{ width: '100%', marginTop: '10px' }}
                    />
                  </Typography>
                  <Typography component="span">
                    {intl.formatMessage({
                      id: 'builder.moduleeditor.externalembedding.copyembedcode',
                      defaultMessage:
                        'Hier kannst du dir den Code zum Einbetten deines Moduls kopieren.',
                    })}
                    <div style={{ marginTop: '10px' }}>
                      <CopyLink
                        link={embedcode}
                        buttonText={intl.formatMessage({
                          id: 'builder.moduleeditor.externalembedding.copyembedcodebutton',
                          defaultMessage: 'Code kopieren',
                        })}
                      />
                    </div>
                  </Typography>

                  {false && (
                    <Typography component="span">
                      {intl.formatMessage({
                        id: 'builder.moduleeditor.externalembedding.copyfakeplayerlink',
                        defaultMessage:
                          'Hier kannst du den Link für den Player-ohne-Login kopieren.',
                      })}
                      <div style={{ marginTop: '10px' }}>
                        <CopyLink
                          link={linkText}
                          buttonText={intl.formatMessage({
                            id: 'builder.moduleeditor.externalembedding.copyfakeplayerebutton',
                            defaultMessage: 'Link kopieren',
                          })}
                        />
                      </div>
                    </Typography>
                  )}
                </>
              )}
            </Stack>
          )}
          {!embedPerm && (
            <Typography>
              {intl.formatMessage({
                id: 'builder.moduleeditor.externalembedding.icontooltip.noperm',
                defaultMessage:
                  'Nur der Besitzer des Moduls darf die Einstellungen zum Einbetten eines Moduls ändern.',
              })}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {embedPerm && (
            <Button
              color="secondary"
              onClick={() => {
                putDyn()
                  .then((_data: any) => {
                    //
                    // ! please leave this console.log in the code ! Christian, 20.10.22
                    console.log('putdyn success. Data:');
                    console.log(_data);
                  })
                  .catch((reason: any) => {
                    console.log('error in putDyn. Reason:');
                    console.log(reason);
                  })
                  .finally(() => {
                    // Mae, 18.11.2022 - was in then-branch, i.e., it didn't trigger correctly
                    // when putDyn threw error
                    // ! please leave this console.log in the code ! Christian, 18.11.22
                    console.log(
                      'update module ... module.publicsessionprogressbar => ' +
                        module.publicsessionprogressbar
                    );
                    setDialogIsOpen(false);
                    onUpdate(); // update module (metadata)
                  });
              }}
            >
              {intl.formatMessage({
                id: 'builder.moduleeditor.externalembedding.dialogsavebutton',
                defaultMessage: 'Speichern',
              })}
            </Button>
          )}
          {!embedPerm && (
            <Button
              color="secondary"
              onClick={() => {
                setDialogIsOpen(false);
              }}
            >
              {intl.formatMessage({
                id: 'builder.moduleeditor.externalembedding.dialogclosebutton',
                defaultMessage: 'Schließen',
              })}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
