import React, { useCallback, useState } from 'react'; // useContext
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { CircularProgress, Typography } from '@mui/material';
import { evoachDefaultTheme } from '@evoach/ui-components';

//import { AccountContext } from '../../../account';
import { authorizedPost, authorizedPut } from '../../../api/authorizedApi';
import { ApiError, deleteCachedAsset } from '../../../api';
import {
  isValidFileType,
  validExtensions,
} from '../../PropertiesSidebar/assetHelper';

interface DropzoneProps {
  /**
   * Function returns new asset from database.
   * Handler signature should be something like (asset: Asset) => {}
   */
  onUpdate: Function;
  /**
   * if asset already exists, overwrite
   */
  overWriteExistingAsset: boolean;
}

export const Dropzone: React.FC<DropzoneProps> = ({
  onUpdate,
  overWriteExistingAsset,
}) => {
  //const { account } = useContext(AccountContext);
  //const { uploadUrl } = useCreateAssetQuery(assetKey);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInDragZone, setIsInDragZone] = useState<boolean>(false);
  const intl = useIntl();

  const getUploadUrl = async (assetkey: string) => {
    // folder "accountid" for assetkey is automatically added in backend
    const getS3UploadUrl = authorizedPost(`/asset/uploadurl/${assetkey}`);
    const getUploadUrlReponse = await getS3UploadUrl();
    const data = await getUploadUrlReponse.json();
    return data;
  };

  /**
   * save a file in DB and overwrite (or not) if existing
   * @param {string} assetkey - filename
   * @param {boolean} overWriteExistingAsset - true = overwrite if exists, false, fail if exists
   * @returns {Promise<unknown>} save-in-db response
   */
  const saveAssetinDB = async (
    assetkey: string,
    overWriteExistingAsset: boolean
  ) => {
    const body = {
      assetfilename: assetkey,
    };
    const saveAssetInDB = authorizedPost(
      `/asset/?overwrite=${overWriteExistingAsset}`,
      body
    );
    const saveAssetInDBResponse = await saveAssetInDB();
    const response = await saveAssetInDBResponse.json();

    return response;
  };

  const setIsPresent = async (assetid: any) => {
    const body = {
      ispresent: true,
    };
    const updateAssetInDB = authorizedPut(`/asset/${assetid}`, body);
    const updateAsset = await updateAssetInDB();
    const data = await updateAsset.json();

    return data;
  };

  const uploadFile = async (
    uploadUrl: string,
    binaryStr: string | ArrayBuffer | null
  ) => {
    const result = await fetch(uploadUrl, {
      method: 'PUT',
      body: binaryStr,
    });
    return result;
  };

  const onDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      setIsInDragZone(false);

      if (acceptedFiles.length === 0) {
        if (fileRejections.length > 0) {
          onUpdate(-1); // -1 = all files rejected
        } else {
          onUpdate(undefined); // no accepted and no rejected => unknown
        }
        return;
      } else {
        if (fileRejections.length > 0) {
          onUpdate(-2); // -1 = some files rejecetd
        }
      }

      setIsLoading(true);

      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          // 1. Create asset key from aacountid + filename
          const filesplit = file.path.split('/');

          const assetKey: string =
            filesplit.length > 0 ? filesplit[filesplit.length - 1] : file.path;

          let dbasset;
          try {
            // 2. Save asset in DB
            dbasset = await saveAssetinDB(assetKey, overWriteExistingAsset);
          } catch (e: unknown) {
            setIsLoading(false);
            if (e instanceof ApiError) {
              onUpdate(e.httpStatus);
            } else {
              onUpdate(undefined);
            }
            return;
          }

          // 3. Get upload URL
          // Also reset s3 url cache for assetkey, especially in case that there
          // is an overwrite request
          deleteCachedAsset(dbasset.assetId);
          const uploadUrl = await getUploadUrl(assetKey);

          // 4. Upload to s3
          const binaryStr = reader.result;
          const fileUploaded = await uploadFile(uploadUrl.assetUrl, binaryStr);

          if (fileUploaded.ok) {
            // 5. When sccessfully uploaded, update DB entry and set isPresent = true
            await setIsPresent(dbasset.assetId);
          }
          onUpdate(dbasset);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [onUpdate, overWriteExistingAsset]
  );

  function dragEnter() {
    setIsInDragZone(true);
  }

  function dragLeave() {
    setIsInDragZone(false);
  }

  // check files before uploading whether they have a valid extension
  const fileValidator = (file: any) => {
    if (!isValidFileType(file.name)) {
      return {
        code: 'wrong-filetype',
        message: `Filetype not supported`,
      };
    }
    return null;
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    onDragEnter: () => dragEnter(),
    onDragLeave: () => dragLeave(),
    validator: fileValidator,
  });

  return (
    <div
      {...getRootProps()}
      style={{
        width: '98%',
        height: '110px',
        borderWidth: '3px',
        borderStyle: 'dashed',
        borderRadius: '0.5rem',
        borderColor: evoachDefaultTheme.palette.secondary.main,
        backgroundColor: isInDragZone
          ? evoachDefaultTheme.palette.secondary.light
          : 'white',
      }}
    >
      <input {...getInputProps()} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1em',
        }}
      >
        <Typography
          variant="body2"
          component="span"
          style={{ marginTop: '10px', marginLeft: '10px' }}
        >
          {fileRejections.length === 0 && (
            <>
              {intl.formatMessage({
                id: 'builder.reception.assests.dropzone.hint',
                defaultMessage:
                  'Bitte ziehe deine Dateien in diesen Bereich oder klicke in die Fläche, um eine Datei auszuwählen und hochzuladen.',
              })}
            </>
          )}
          {fileRejections.length > 0 && (
            <>
              {intl.formatMessage({
                id: 'builder.reception.assests.dropzone.supportedformats',
                defaultMessage: 'Die folgenden Formate werden unterstützt: ',
              })}
              {validExtensions.join(', ')}
            </>
          )}
        </Typography>
        {isLoading && <CircularProgress />}
      </div>
    </div>
  );
};
