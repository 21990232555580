import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { Description } from '../Description';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  SingleAnswerPromptNode
*/

export const SingleAnswerPromptNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;

  return (
    <>
      <Handle
        type="target"
        id={nodeProps.data.state.entry[0].nodeType + 'in'}
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{
          width: '400px',
          background: nodeProps.data.state.entry[0].nodeMiniMapColor,
        }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          <Description nodetype={nodeType} propname="description" />
          <br />
          &nbsp;
          <br />
          {intl.formatMessage(
            {
              id: 'builder.nodes.singleAnswerPrompt.selection.in',
              defaultMessage:
                'Die Inhalte dieser Variable werden an die KI gesendet: {invar}',
            },
            {
              invar: <b>{payload.getValueFrom ?? '- -'}</b>,
            }
          )}
          <br /> &nbsp;
          <br />
          {intl.formatMessage(
            {
              id: 'builder.nodes.singleAnswerPrompt.selection.out',
              defaultMessage:
                'Die Antwort der KI wird in diese Variable geschrieben: {outvar}',
            },
            {
              outvar: (
                <b>
                  {payload.saveResultTo &&
                  !payload.saveResultTo.startsWith('evoachechokey')
                    ? payload.saveResultTo
                    : '- -'}
                </b>
              ),
            }
          )}
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id={nodeProps.data.state.entry[0].nodeType + 'out'}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
