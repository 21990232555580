import {
  evoachDefaultTheme,
  formatDate,
  ObjectCardFlags,
} from '@evoach/ui-components';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SearchIcon from '@mui/icons-material/Search';
import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AccountContext, RoleEnum } from '../../../account';
import {
  authorizedDelete,
  authorizedPost,
  useFetchModule,
  useFetchModulesQuery,
} from '../../../api';
import { permissionAggregate } from '../../../api/module/permissionAggregate';
import {
  initialModule,
  ModuleProps,
  prepareInitialModule,
} from '../../../entities';
import { useEnvironment } from '../../../environment/useEnvironment';
import {
  TranslationContext as GlobalTranslationContext,
  TranslationContext,
} from '../../../intl/TranslationContext';
import { AppRoutes } from '../../../routing/routes';
import { EvoachMenuItem, EvoachSelect } from '../../customMaterialComponents';
import {
  LanguageSelectionHeightOptions,
  MetaDataEditorLanguageSelection,
} from '../../ModuleEditor/MetaDataEditorLanguageSelection';
import { startPreviewSession } from '../../ModuleEditor/ModulePreviewButton';
import { ShareLinkDialog } from '../modules';
import { ConfirmDeletionDialog } from '../modules/ConfirmDeletionDialogue';
import {
  NewObjectByJsonButton,
  NewObjectByJsonButtonTypeEnum,
} from '../modules/NewModuleByJsonButton';
import { NewModuleDialog } from '../modules/NewModuleDialog';

import { templateListTexts } from './templateListTexts';
import TemplateMenu, { TemplateListMenuItemProps } from './TemplateMenu';

const useStyles = makeStyles({
  longTextNoWrap: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '18vw',
    textOverflow: 'ellipsis',
  },
  noWrapChildren: { '& > *': { whiteSpace: 'nowrap' } },
  noWrap: { whiteSpace: 'nowrap' },
  cursorPointer: {
    cursor: 'pointer',
    color: evoachDefaultTheme.palette.secondary.main,
    textDecoration: 'underline',
    textDecorationColor: evoachDefaultTheme.palette.secondary.main,
  },
  spacedIcons: {
    '& > *': {
      marginRight: '0.5rem',
    },
  },
});

interface TemplateListProps {}

export const TemplateList: React.FC<TemplateListProps> = () => {
  const intl = useIntl();
  const classes = useStyles();
  let navigate = useNavigate();
  const { locale: UIlocale } = useContext(GlobalTranslationContext);
  const { locale: currentLocale } = useContext(TranslationContext);

  const { hasRole } = useContext(AccountContext);
  const { playerBasePath } = useEnvironment();

  const [newModuleDialogIsOpen, setNewModuleDialogIsOpen] =
    useState<boolean>(false);
  const [previewWindow, setPreviewWindow] = useState<any>(undefined);
  const [filterLocale, setFilterLocale] = useState<string>('all');
  const [selectedType, setSelectedType] = useState<string>('all');
  const [searchString, setSearchString] = useState<string>('');
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [selectedModuleForSharing, setSelectedTemplateForSharing] =
    useState<any>([]);
  const [modulePermissionId, setModulePermissionId] = useState<string>('');
  const [moduleIdForDeletion, setModuleIdForDeletion] = useState<string>('');
  const [moduleIdForJsonCopy, setModuleIdForJsonCopy] = useState<string>('');

  const { modules, refetch: refetchModules } =
    useFetchModulesQuery(filterLocale);

  // switch to editor to edit template
  const editModule = (moduleid: string) => {
    if (!moduleid || moduleid === '') return;
    navigate(
      `${AppRoutes.BUILDER}/${moduleid}/${
        filterLocale !== 'all' ? filterLocale : UIlocale
      }`
    );
  };

  // duplicate a template by moduleid
  const duplicateTemplate = async (moduleid: string) => {
    if (!moduleid || moduleid === '') return;
    const duplicateURL = `/module/${moduleid}`;
    const duplicateCall = authorizedPost(duplicateURL);
    await duplicateCall();
    refetchModules();
  };

  // close ModuleDialog
  const newModuleDialogClose = (doit: boolean, language: string) => {
    if (doit && !(!language || language === '')) {
      addNewTemplate(language);
    }
    setNewModuleDialogIsOpen(false);
  };

  // add new template
  const addNewTemplate = (language: string) => {
    if (!language || language === '') return;
    prepareInitialModule(true);
    // set template metadata information according to UI language
    initialModule.translations[0].metadatatranslation[
      initialModule.metadata.title
    ] = intl.formatMessage(templateListTexts.addNew);

    initialModule.translations[0].metadatatranslation[
      initialModule.metadata.description
    ] = intl.formatMessage(templateListTexts.addNewDescription);

    initialModule.translations[0].metadatatranslation[
      initialModule.metadata.duration
    ] = intl.formatMessage(templateListTexts.addNewDuration);

    initialModule.translations[0].lang = language;
    initialModule.metadata.defaultLanguage = language;
    initialModule.metadata.moduleLanguages = [language];

    navigate(AppRoutes.NEWBUILDER);
  };

  const onLanguageChange = (newfilterlocale: string) => {
    setFilterLocale(newfilterlocale);
    refetchModules();
  };

  const shareTemplate = (moduleid: string) => {
    if (!moduleid || moduleid.trim() === '') return;
    setSelectedTemplateForSharing(
      rows.filter((template: any) => moduleid === template.moduleid)
    );
    setDialogIsOpen(true);
  };

  const deleteTemplateInvitation = (
    modulepermissionid: string,
    moduleid: string
  ) => {
    if (!modulepermissionid || modulepermissionid === '') return;
    if (!moduleid || moduleid === '') return;
    setModulePermissionId(modulepermissionid);
    setModuleIdForDeletion(moduleid);
  };

  const reallyDeleteTemplate = async () => {
    if (!moduleIdForDeletion || moduleIdForDeletion === '') return;
    const deletionURL: RequestInfo = `/module/${moduleIdForDeletion}`;
    const deletionCall = authorizedDelete(deletionURL);
    await deletionCall();
    refetchModules();
    setModuleIdForDeletion('');
  };

  const reallyDeleteTemplateInvitation = async () => {
    if (!modulePermissionId || modulePermissionId === '') return;
    if (!moduleIdForDeletion || moduleIdForDeletion === '') return;
    const deletionURL: RequestInfo = `/module/${moduleIdForDeletion}/modulepermission/${modulePermissionId}`;
    const deletionCall = authorizedDelete(deletionURL);
    await deletionCall();
    refetchModules();
    setModulePermissionId('');
    setModuleIdForDeletion('');
  };

  const jumpToTranslationManagement = (moduleid: string) => {
    if (!moduleid || moduleid.trim() === '') return;
    navigate(
      `${AppRoutes.TRANSLATION}/${moduleid}/${
        filterLocale !== 'all' ? filterLocale : UIlocale
      }`
    );
  };

  const deleteTemplate = (moduleid: string) => {
    if (!moduleid || moduleid === '') return;
    setModuleIdForDeletion(moduleid);
  };

  const { module } = useFetchModule(moduleIdForJsonCopy);

  const copyModuleToClipBoard = (moduleid: string) => {
    if (!moduleid || moduleid === '') return;
    setModuleIdForJsonCopy(moduleid);
  };

  const [moduleJsonAsString, setmoduleJsonAsString] = useState<string>('');

  useEffect(() => {
    if (!module) setmoduleJsonAsString('');
    setmoduleJsonAsString(JSON.stringify(module));
  }, [module]);

  useEffect(() => {
    if (!moduleJsonAsString || moduleJsonAsString.trim() === '') return;
    navigator.clipboard.writeText(moduleJsonAsString);
  }, [moduleJsonAsString]);

  // used for the dropdown menu for each table row
  const getMenuItems = (row: any): TemplateListMenuItemProps[] => {
    const menuItems: TemplateListMenuItemProps[] = [];
    menuItems.push({
      text: intl.formatMessage(templateListTexts.preview),
      disabled: false,
      onClick: () =>
        startPreviewSession(
          row.moduleid,
          previewWindow,
          playerBasePath,
          setPreviewWindow,
          currentLocale
        ),
      hasDivider: true,
      icon: <RemoveRedEyeIcon color="secondary" />,
    });
    menuItems.push({
      text: intl.formatMessage(templateListTexts.duplicate),
      disabled: !(
        row.permissions.owns ||
        row.permissions.CoachPermission_CopyModuleAsTemplate
      ),
      onClick: () => duplicateTemplate(row.moduleid),
      icon: <ContentCopyIcon color="secondary" />,
    });
    menuItems.push({
      text: intl.formatMessage(templateListTexts.share),
      disabled: !row.permissions.owns,
      onClick: () => shareTemplate(row.moduleid),
      icon: <ShareIcon color="secondary" />,
    });
    menuItems.push({
      text: intl.formatMessage(templateListTexts.removeFromList),
      disabled: !row.permissions.invitinginvitationid,
      onClick: () =>
        deleteTemplateInvitation(
          row.permissions.modulepermissionid,
          row.moduleid
        ),
      icon: <DoDisturbOnIcon color="secondary" />,
    });
    menuItems.push({
      text: intl.formatMessage(templateListTexts.translationManagement),
      disabled: !(
        row.permissions.owns ||
        row.permissions.CoachPermission_EditOriginalModule
      ),
      onClick: () => jumpToTranslationManagement(row.moduleid),
      icon: <LanguageIcon color="secondary" />,
    });
    menuItems.push({
      text: intl.formatMessage(templateListTexts.delete),
      disabled: !row.permissions.owns,
      onClick: () => deleteTemplate(row.moduleid),
      hasDivider: true,
      icon: <DeleteIcon color="secondary" />,
    });
    if (hasRole(RoleEnum.EVOACHADMIN)) {
      menuItems.push({
        text: intl.formatMessage(templateListTexts.copyJsonToClipboard),
        disabled: false,
        onClick: () => copyModuleToClipBoard(row.moduleid),
        icon: <DataObjectIcon color="secondary" />,
      });
    }
    return menuItems;
  };

  // prepare modules (templates) to be rendered in table and to be used in
  // several helper functions
  const rows = useMemo(() => {
    const templates = modules?.filter(
      (module: ModuleProps) =>
        !!module.metadata && !!module.metadata.title && module.issubmodule
    );
    return templates
      ? templates
          .map((module: ModuleProps, mindex: number) => {
            const permaggr = permissionAggregate(module);
            const isshared =
              permaggr.invitinginvitationid !== undefined &&
              permaggr.invitinginvitationid !== null &&
              permaggr.invitinginvitationid !== '';

            return {
              id: mindex,
              title: intl.formatMessage({ id: module.metadata.title }),
              modulename: intl.formatMessage({ id: module.metadata.title }),
              moduleid: module.moduleid,
              description: intl.formatMessage({
                id: module.metadata.description,
              }),
              supportedLanguages: module.metadata.moduleLanguages,
              author:
                module.isdefaultmodule || permaggr.owns
                  ? permaggr.account.givenname +
                    ' ' +
                    permaggr.account.familyname
                  : isshared && permaggr.invitingaccount
                  ? permaggr.invitingaccount.givenname +
                    ' ' +
                    permaggr.invitingaccount.familyname
                  : '',
              duration: intl.formatMessage({
                id: module.metadata.duration,
              }),
              created: module.tscreated,
              modified: module.tsupdated,
              permissions: permaggr,
              isDefault: module.isdefaultmodule,
              isShared: isshared,
            };
          })
          .filter((row: any) => {
            const includedInTextSearch =
              searchString === '' || searchString === undefined
                ? true
                : (row.title + '')
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                  (row.description + '')
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                  (row.author + '')
                    .toLowerCase()
                    .includes(searchString.toLowerCase());
            const includedInLanguageFilter =
              selectedType === 'all' || selectedType === undefined
                ? true
                : (row.permissions.owns && selectedType === 'personal') ||
                  (row.isDefault && selectedType === 'public') ||
                  (row.isShared && selectedType === 'shared');
            return includedInTextSearch && includedInLanguageFilter;
          })
      : [];
  }, [intl, modules, selectedType, searchString]);

  return (
    <Box padding="10px">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexShrink={0}>
          <Box sx={{ minWidth: '10em' }}>
            <FormControl size="small">
              <InputLabel id="demo-simple-select-label">
                {intl.formatMessage({
                  id: 'builder.templates.filter.templates',
                  defaultMessage: 'Vorlagen',
                })}
              </InputLabel>
              <EvoachSelect
                value={selectedType}
                onChange={(event) =>
                  setSelectedType(event.target.value as string)
                }
                sx={{
                  minWidth: '10em',
                }}
                label="Templates"
              >
                <EvoachMenuItem
                  key="types_showAllOption"
                  value="all"
                  selected={selectedType === 'all'}
                >
                  {intl.formatMessage({
                    id: 'builder.templates.showAllOption.text',
                    defaultMessage: 'Alle',
                  })}
                </EvoachMenuItem>
                <EvoachMenuItem
                  key="types_showPublicOption"
                  value="public"
                  selected={selectedType === 'public'}
                >
                  {intl.formatMessage(templateListTexts.publicTemplate)}
                </EvoachMenuItem>
                <EvoachMenuItem
                  key="types_showSharedOption"
                  value="shared"
                  selected={selectedType === 'shared'}
                >
                  {intl.formatMessage(templateListTexts.sharedTemplate)}
                </EvoachMenuItem>
                <EvoachMenuItem
                  key="types_showPersonalOption"
                  value="personal"
                  selected={selectedType === 'personal'}
                >
                  {intl.formatMessage(templateListTexts.personalTemplate)}
                </EvoachMenuItem>
              </EvoachSelect>
            </FormControl>
          </Box>
          <Box marginLeft="0.5em">
            <MetaDataEditorLanguageSelection
              onLanguageChange={onLanguageChange}
              moduleLocale={filterLocale}
              showAllOption
              showFlags={true}
              displayLabel
              heightOption={LanguageSelectionHeightOptions.SMALL}
            />
          </Box>
          <Box marginLeft="1em">
            <Input
              sx={{ height: '100%' }}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              }
              autoComplete="off"
              autoCorrect="off"
              placeholder={intl.formatMessage({
                id: 'player.reception.template.searchbox',
                defaultMessage: 'Template suchen ...',
              })}
            />
          </Box>
        </Box>
        <Box flexShrink={0}>
          {hasRole(RoleEnum.EVOACHADMIN) && (
            <span style={{ marginLeft: '2em' }}>
              <NewObjectByJsonButton
                mode={NewObjectByJsonButtonTypeEnum.TEMPLATE}
              />
            </span>
          )}
          <Button
            sx={{ marginLeft: '1em' }}
            onClick={() => setNewModuleDialogIsOpen(true)}
          >
            <AddIcon /> &nbsp;{intl.formatMessage(templateListTexts.addNew)}
          </Button>
        </Box>
      </Box>
      {rows.length > 0 ? (
        <TableContainer
          component={Paper}
          style={{
            minWidth: '800px',
            minHeight: '100%',
            marginTop: '2em',
            overflowX: 'initial',
          }}
        >
          <Table
            stickyHeader
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                paddingBottom: '0.5rem',
              },
            }}
          >
            <TableHead>
              <TableRow className={classes.noWrapChildren}>
                <TableCell>
                  {intl.formatMessage(templateListTexts.title)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(templateListTexts.category)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(templateListTexts.description)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(templateListTexts.author)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(templateListTexts.duration)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(templateListTexts.languages)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(templateListTexts.type)}
                </TableCell>
                <TableCell>
                  {intl.formatMessage(templateListTexts.actions)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                >
                  <TableCell>
                    {row.title.substring(
                      row.title.lastIndexOf('/') + 1,
                      row.title.length
                    )}
                  </TableCell>
                  <TableCell>
                    {row.title.substring(0, row.title.lastIndexOf('/'))}
                  </TableCell>

                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.author}</TableCell>
                  <TableCell>{row.duration}</TableCell>
                  <TableCell>
                    <ObjectCardFlags flags={row.supportedLanguages} />
                  </TableCell>
                  <TableCell className={classes.noWrap}>
                    {row.isDefault && !row.isShared && (
                      <Tooltip
                        title={intl.formatMessage(
                          templateListTexts.publicTemplate
                        )}
                        placement="top"
                        arrow
                      >
                        <PublicIcon fontSize="small" />
                      </Tooltip>
                    )}
                    {row.isShared && (
                      <Tooltip
                        title={intl.formatMessage(
                          templateListTexts.sharedTemplate
                        )}
                        placement="top"
                        arrow
                      >
                        <ShareIcon fontSize="small" />
                      </Tooltip>
                    )}
                    {row.permissions.owns && (
                      <Tooltip
                        title={intl.formatMessage(
                          templateListTexts.personalTemplate
                        )}
                        placement="top"
                        arrow
                      >
                        <PersonIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: 'nowrap' }}>
                      <Button
                        id="basic-button"
                        onClick={() =>
                          row.permissions.owns ||
                          row.permissions.CoachPermission_EditOriginalModule
                            ? editModule(row.moduleid)
                            : duplicateTemplate(row.moduleid)
                        }
                        style={{ borderRadius: '5px', padding: '5px' }}
                      >
                        <Typography variant="inherit">
                          {row.permissions.owns ||
                          row.permissions.CoachPermission_EditOriginalModule
                            ? intl.formatMessage(templateListTexts.editButton)
                            : intl.formatMessage(templateListTexts.duplicate)}
                        </Typography>
                      </Button>
                      <TemplateMenu
                        id={row.id}
                        menuitems={getMenuItems(row)}
                        createdText={
                          row.created
                            ? intl.formatMessage(templateListTexts.created) +
                              ' ' +
                              formatDate(row.created, intl.locale)
                            : undefined
                        }
                        modifiedText={
                          row.modified
                            ? intl.formatMessage(
                                templateListTexts.lastModified
                              ) + formatDate(row.modified, intl.locale)
                            : undefined
                        }
                      ></TemplateMenu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingTop: '10em',
          }}
        >
          <Typography variant="body1">
            {intl.formatMessage(templateListTexts.emptyDefaultMessage)}
          </Typography>
        </Box>
      )}
      <NewModuleDialog
        isOpen={newModuleDialogIsOpen}
        onClose={newModuleDialogClose}
      />
      <ShareLinkDialog
        open={dialogIsOpen}
        onCancel={() => setDialogIsOpen(false)}
        onCreate={() => setDialogIsOpen(false)}
        modules={selectedModuleForSharing ?? []}
        forCoach={RoleEnum.COACH}
      />
      <ConfirmDeletionDialog
        title={intl.formatMessage({
          id: 'builder.reception.templates.menu.confirmdeletion',
          defaultMessage: 'Template löschen',
        })}
        open={moduleIdForDeletion !== ''}
        onConfirm={() => reallyDeleteTemplate()}
        onClose={() => setModuleIdForDeletion('')}
      />
      <ConfirmDeletionDialog
        title={intl.formatMessage({
          id: 'builder.reception.modulelist.cards.deletioninvitaiontitletemplate',
          defaultMessage: 'Template aus Einladung entfernen',
        })}
        open={modulePermissionId !== ''}
        onConfirm={() => reallyDeleteTemplateInvitation()}
        onClose={() => {
          setModulePermissionId('');
          setModuleIdForDeletion('');
        }}
      />
    </Box>
  );
};
