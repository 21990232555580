import { useContext } from 'react';
import { useMutation } from 'react-query';

import { AccountContext } from '../../account';
import { AccountMetainfo } from '../../entities/AccountTypes';
import { authorizedPut } from '../authorizedApi';

export const useUpdateAccount = () => {
  const { account } = useContext(AccountContext);

  const { isLoading, data, isError, error, mutate } = useMutation(
    async (metainfos: AccountMetainfo) => {
      if (!account)
        return {
          isLoading: false,
          isError: true,
          error: 'no accountid',
          mutate: () => {},
        };

      const updateBody = { metainfos: { ...metainfos } };

      const updateCall = authorizedPut(
        `/account/${account.accountid}`,
        updateBody
      );
      const response = await updateCall();
      const responseData = await response.json();

      return responseData;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    mutate,
    data,
  };
};
