import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import {
  formulaNodeStateEntry,
  isTranslatedStatePayloadProp,
  StateEntryProps,
  translateKey,
} from '../nodes';
import { autoSave } from '../ModuleEditor/autosave';
import { Description } from '../Description';

import { TextFieldDialog } from './TextFieldDialog';

/*
  ! ElementPropertyPayloadTextfield

  Text field
*/
interface ElementPropertyPayloadTextfieldProps {
  payloadKey: string;
  entry: StateEntryProps;
  updatePayloadValue: Function;
}

export const ElementPropertyPayloadTextfield: React.FC<
  ElementPropertyPayloadTextfieldProps
> = ({ payloadKey, entry, updatePayloadValue }) => {
  const intl = useIntl();

  // !(payloadKey === 'src' && payloadValue.toLowerCase().startsWith('http'))
  /*
  intl.formatMessage({
            id: payloadValue,
            defaultMessage: NOT_YET_TRANSLATED(intl),
          })
          */
  const initialValue = useCallback(
    (payloadKey: string, payloadValue: string | any) => {
      // loopNames should be empty if they start with an evoachkey
      if (payloadKey === 'loopName' && payloadValue.startsWith('evoachechokey'))
        return '';
      return isTranslatedStatePayloadProp(payloadKey, payloadValue)
        ? translateKey(intl, payloadValue)
        : payloadValue;
    },
    [intl]
  );
  const [elementValue, setElementValue] = useState<string>(
    initialValue(payloadKey, entry.payload[payloadKey])
  );
  useEffect(() => {
    setElementValue(initialValue(payloadKey, entry.payload[payloadKey]));
  }, [entry.payload, initialValue, payloadKey]);

  const updateElementValue = (newValue: string) => {
    updatePayloadValue(newValue, payloadKey, entry);
    setElementValue(newValue);
  };
  //console.log(payloadKey);

  const [isTextFieldDialogOpen, setIsTextFieldDialogOpen] =
    useState<boolean>(false);

  const onCloseTextFieldDialog = () => {
    setIsTextFieldDialogOpen(false);
    autoSave();
  };

  const onOpenTextFieldDialog = () => {
    setIsTextFieldDialogOpen(true);
  };

  const isTextFieldDialogKey =
    payloadKey === 'message' ||
    payloadKey === 'placeholderText' ||
    payloadKey === 'formula';

  return (
    <>
      <Description nodetype={entry.nodeType} propname={payloadKey} />
      <TextField
        id={'donotcatchpasteevent_' + payloadKey}
        label={payloadKey}
        value={elementValue}
        onChange={(evt) => updateElementValue(evt.target.value)}
        key={payloadKey}
        disabled={payloadKey === 'loopName'}
        autoComplete="off"
        autoCorrect="off"
        onClick={() => {
          if (isTextFieldDialogKey) {
            onOpenTextFieldDialog();
          }
        }}
        onBlur={() => {
          if (!isTextFieldDialogKey) {
            autoSave();
          }
        }}
        style={{
          width: '95%',
          marginTop: '20px',
        }}
      />

      <TextFieldDialog
        open={isTextFieldDialogOpen}
        onClose={onCloseTextFieldDialog}
        label={payloadKey}
        value={elementValue}
        onChange={(value: string) => updateElementValue(value)}
        nodeType={entry.nodeType}
        availableVariables={
          entry.nodeType === formulaNodeStateEntry.nodeType
            ? entry.payload.getValuesFrom?.filter((elem) => elem !== '')
            : entry.payload.getStringValues?.filter((elem) => elem !== '')
        }
      />
    </>
  );
};
