import { defineMessages } from 'react-intl';
import { evoachDefaultTheme } from '@evoach/ui-components';

import { StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.noteMessage.state.message',
    defaultMessage:
      'Diese Notiz ist nur für dich und wird nur hier angezeigt. Sie wird nicht für den Chatbot verwendet.',
  },
  d: {
    id: 'noteStateEntry.description',
    defaultMessage:
      'Diese Komponente dient nur der Kommentierung des Moduls hier im Editor und wird für den Chatbot nicht verwendet.',
  },
});

/**
 * Note component without any usage in the chatbot; for commenting purposes only
 */
export const noteStateEntry: StateEntryProps = {
  version: 3,
  type: 'note',
  temporary: false,
  payload: {
    message: 'builder.nodes.noteMessage.state.message',
  },
  nodeMiniMapColor: evoachDefaultTheme.palette.error.main,
  displayName: 'Note',
  nodeType: 'noteStateEntry',
  handleOutCount: 0, // number of outgoing handles
};
