import React from 'react';

/**
 * This context is used to wrap ReactFlow. It enables zwo sides to communicate:
 * 1) the component that contains React Flow can implement event handler for customer nodes
 * 2) customer nodes that are rendered within the React Flow canvas to use this
 * context and use these event handler. This allows custom buttons per node
 * that are handled by the parent component outside ReactFlow
 *
 * @see ModuleEditorPane.tsx
 */

interface ReactFlowNodeEventProps {
  onCutNode: (nodeid: string) => void; // cut node
  onCopyNode: (nodeid: string) => void; // copy node
  onPushNode: (nodeid: string, offset: number) => void; // move grapg fwith node down
  onDuplicateNode: (nodeid: string) => void; // duplicate a node => copy and paste in one step
  displayMenu: boolean; // on/off display context menu
}

const defaultState = {
  onCutNode: () => {},
  onCopyNode: () => {},
  onPushNode: () => {},
  onDuplicateNode: () => {},
  displayMenu: false,
};

export const ReactFlowNodeEventContext =
  React.createContext<ReactFlowNodeEventProps>(defaultState);
