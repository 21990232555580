import { Handle, NodeProps, Position } from 'reactflow';
import {
  MultipleOutputComposite,
  MultipleOutputCompositeProps,
  MultipleOutputItem,
} from '@evoach/ui-components';
import { useIntl } from 'react-intl';

import { useGlobalStyles, translateKey } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  MultipleOutputCompiste react-flow node for module builder graph canvas
*/

export const MultipleOuputCompositeNode: React.FC<NodeProps<any>> = (
  nodeProps: any
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  const elements: MultipleOutputItem[][] = [
    [
      {
        type: 'multipleoutput',
        value: intl.formatMessage({
          id: 'builder.nodes.multipleOutputComponent.item1',
          defaultMessage:
            'Diese Werte kommen nachher aus der "getValueFrom" Variable',
        }),
      },
      {
        type: 'multipleoutput',
        value: intl.formatMessage({
          id: 'builder.nodes.multipleOutputComponent.item1',
          defaultMessage:
            'Diese Werte kommen nachher aus der "getValueFrom" Variable',
        }),
      },
      {
        type: 'multipleoutput',
        value: intl.formatMessage({
          id: 'builder.nodes.multipleOutputComponent.item1',
          defaultMessage:
            'Diese Werte kommen nachher aus der "getValueFrom" Variable',
        }),
      },
    ],
  ];

  const props: MultipleOutputCompositeProps = {
    buttonText: translateKey(intl, payload.buttonText),
    elements: elements,
    saveResultTo: payload.saveResultTo,
    minItems: parseInt(payload.minItems),
    maxItems: parseInt(payload.maxItems),
    iconType: payload.iconType ? payload.iconType : 'star',
    selectAll: payload.selectAll,
    hideDate: payload.hideDate,
    hideSelect: payload.hideSelect,
    disableDrag: true,
    message: translateKey(intl, payload.message),
    toolTipWarningMessage: translateKey(intl, payload.toolTipWarningMessage),
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <MultipleOutputComposite {...props} />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="nexthandle"
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
