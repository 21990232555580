import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { DevToolsContext } from '../devtools/DevToolsContext';

import { getLocaleMessages } from './getLocaleMessages';
import { isSupportedLocale, SupportedLocales } from './SupportedLocales';

type Messages = Record<string, string>;

/**
 *  TranslationContextProps
 */
interface TranslationContextProps {
  locale: SupportedLocales;
  updateLocale: (newLocale: SupportedLocales) => void;

  messages: Messages;
  appendStaticMessages: (messages: Messages) => void;
  setModuleMessages: (messages: Messages) => void;
  appendModuleMessages: (messages: Messages) => void;

  setDefaultMessages: (moduleLocale: SupportedLocales) => void;
  defaultMessages: Messages;
  setSingleTranslation: (key: string, value: string) => void;
  globalStateMachineLocale: string;
  setGlobalStateMachineLocale: (locale: string) => void;
}

export const TranslationContext = React.createContext<TranslationContextProps>({
  locale: SupportedLocales.DE,
  updateLocale: () => null,

  messages: {},
  appendStaticMessages: () => null,
  setModuleMessages: () => null,
  appendModuleMessages: () => null,

  setDefaultMessages: () => null,
  defaultMessages: {},
  setSingleTranslation: () => null,
  globalStateMachineLocale: '',
  setGlobalStateMachineLocale: () => {},
});

/**
 * tries to determine the browser language
 * @returns browser language like 'de' or 'en' if supported, otherwise 'en' as default
 */
export const getBrowserLanguage = () => {
  const navigatorLocale = (navigator.language ?? 'en-GB')
    .split('-')[0]
    .toUpperCase();
  return isSupportedLocale(navigatorLocale)
    ? (navigatorLocale as SupportedLocales)
    : SupportedLocales.EN;
};

/**
 * get user language checks prefered language in several steps and falls back to default if necessary
 * @returns the user defined language (found in local storage of browser) or the browser language or the default ('en')
 */
export const getUserLanguage = () => {
  const browserSetting = getBrowserLanguage();
  let languageByLocalStorage;
  try {
    languageByLocalStorage = localStorage
      .getItem('evoachUILanguageSetting')
      ?.toUpperCase();
  } catch (_e) {}

  return (languageByLocalStorage ?? browserSetting) as SupportedLocales;
};

/**
 * Global translation context for UI translations
 */

export type TranslationContextProviderProps = {
  children?: React.ReactNode;
};

export const TranslationContextProvider: React.FC<
  TranslationContextProviderProps
> = ({ children }) => {
  const [locale, setLocale] = useState(getUserLanguage());
  const { l } = useContext(DevToolsContext);

  // set these messages as they are used before the locale is reset by useEffect
  // works without these dummies but with the dummies, we prevent console errors in browser
  const [staticMessages, setStaticMessages] = useState<Messages>({});
  const [moduleMessages, setModuleMessages] = useState<Messages>({});

  // stateMachineLocale - needed for the key of react_intl provider on next level
  const [globalStateMachineLocale, setGlobalStateMachineLocale] =
    useState<string>(locale);

  const [elementDefaultMessages, setElementDefaultMessages] =
    useState<Messages>({});

  const appendModuleMessages = (newModuleMessages: Messages) => {
    setModuleMessages((oldModuleMessages: Messages) => {
      l('TranslationContextProvider: appendModuleMessages:');
      l({ ...oldModuleMessages, ...newModuleMessages });

      return { ...oldModuleMessages, ...newModuleMessages };
    });
  };

  /**
   * appendStaticMessages was introdcued for PROD-1528 - if we add messages
   * for module lists to moduelMessages, these are overwritten when a module is
   * reloaded, e.g. after editor change.
   */
  const appendStaticMessages = (newStaticMessages: Messages) => {
    setStaticMessages((oldStaticMessages: Messages) => {
      l('TranslationContextProvider: appendStaticMessages:');
      l({ ...oldStaticMessages, ...newStaticMessages });
      return { ...oldStaticMessages, ...newStaticMessages };
    });
  };

  const setMessages = async (locale: SupportedLocales) => {
    const messages = await getLocaleMessages(locale);
    setStaticMessages({ ...staticMessages, ...messages });
  };

  const setDefaultMessages = async (locale: SupportedLocales) => {
    const messages = await getLocaleMessages(locale);
    setElementDefaultMessages({ ...elementDefaultMessages, ...messages });
  };

  useEffect(() => {
    setMessages(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  /**
   * update locale is provided to context. It sets the value in local storage of the browser and for the local state
   * @param {SupportedLocales} locale
   */
  const updateLocale = (locale: SupportedLocales) => {
    try {
      localStorage.setItem('evoachUILanguageSetting', locale as string);
    } catch (_e) {}
    setLocale(locale);
  };

  const setModuleMessagesProxy = (messages: Messages) => {
    setModuleMessages(messages);
  };

  const setSingleTranslation = (key: string, value: string) => {
    const modMes = cloneDeep(moduleMessages);
    modMes[key] = value;
    setModuleMessages(modMes);
  };

  const allMessages = useMemo(() => {
    return { ...staticMessages, ...moduleMessages };
  }, [moduleMessages, staticMessages]);

  // wait until initial messages are loaded
  if (Object.keys(staticMessages).length === 0) {
    return <></>;
  }

  return (
    <TranslationContext.Provider
      value={{
        locale: locale,
        messages: allMessages,
        updateLocale: (newLocale) => {
          updateLocale(newLocale);
        },
        setModuleMessages: setModuleMessagesProxy,
        appendModuleMessages,
        setDefaultMessages,
        defaultMessages: elementDefaultMessages,
        setSingleTranslation: setSingleTranslation,
        globalStateMachineLocale,
        setGlobalStateMachineLocale,
        appendStaticMessages,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
