import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

import { translateKey, useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  RandomCoachMessage react-flow node for module builder graph canvas
*/

export const RandomCoachMessageNode: React.FC<NodeProps<any>> = (
  nodeProps: NodeProps<any>
) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;

  // update internal position coordinates of handles when changing the number
  // of handles in this node.
  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    updateNodeInternals(nodeProps.id);
  }, [nodeProps.id, payload.keyTexts.length, updateNodeInternals]);

  const stringArrayMesssage = intl.formatMessage({
    id: 'builder.nodes.elements.randomCoachMessage.hint',
    defaultMessage:
      'Dieser Knoten definiert eine Liste von Nachrichten, von denen eine Nachricht zufällig ausgewählt und als Coach Message im Chat angezeigt wird.',
  });

  const testListHint = intl.formatMessage({
    id: 'builder.nodes.elements.randomCoachMessage.listhint',
    defaultMessage: 'Die Nachrichten lauten:',
  });

  const calculateHandlePosition = (handleCount: number, handleIndex: number) =>
    `${(100 / (handleCount + 1)) * (handleIndex + 1)}%`;

  const sourceHandles = payload.keyTexts?.map(
    (keyText: string, index: number) => (
      <Handle
        id={keyText}
        key={keyText + index}
        className={classes['react-flow__handle']}
        type="source"
        position={Position.Bottom}
        style={{
          left: calculateHandlePosition(payload.keyTexts?.length ?? 1, index),
        }}
      />
    )
  );
  const translatedLabels = payload.messages
    ? payload.messages.map((message: string) => translateKey(intl, message))
    : [''];

  return (
    <>
      <Handle
        type="target"
        id="messageinhandle"
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={nodeProps.selected ? classes.selectStyletrue : classes.root}
        style={{ maxWidth: '400px' }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span">
          {stringArrayMesssage}
          <strong>
            {(payload.saveResultTo + '').startsWith('evoachechokey')
              ? ''
              : payload.saveResultTo}
          </strong>
          <br />
          {testListHint}
          <br />
          <ol>
            {translatedLabels.map((label: string, index: number) => (
              <li key={'label' + index}>{label}</li>
            ))}
          </ol>
        </Typography>
      </div>
      {sourceHandles}
    </>
  );
};
