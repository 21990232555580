import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.characterSelector.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

/**
 * CharacterSelector xState specific defintions
 */
export const characterSelectorStateEntry: StateEntryProps = {
  version: 1,
  type: 'renderCharacterSelector',
  temporary: true,
  payload: {
    buttonText: 'builder.nodes.characterSelector.state.buttonText',
    getValuesFrom: [''],
    saveResultTo: 'characterSelections',
  },
  getValueType: [
    ReadWriteType.stringArray,
    ReadWriteType.typedObject,
    ReadWriteType.characters,
  ],
  saveResultType: [ReadWriteType.characters],
  displayName: 'Character Selector',
  nodeType: 'characterSelectorStateEntry',
  handleOutCount: 1,
};
