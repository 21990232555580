import { ComponentSize } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { StateEntryProps } from '.';

defineMessages({
  audioText: {
    id: 'builder.nodes.imageDisplay.state.altText',
    defaultMessage: 'Alternativer Text',
  },
  acceptance: {
    id: 'builder.nodes.imageDisplay.state.dummydata.acceptance',
    defaultMessage: 'Akzeptanz',
  },
  acceptanceurl: {
    id: 'builder.nodes.imageDisplay.state.dummydata.acceptanceurl',
    defaultMessage:
      'https://staging.player.evoach.de/assets/cardimages2/acceptance.png',
  },
});

export const imageDisplayStateEntry: StateEntryProps = {
  type: 'renderImageDisplay',
  temporary: false,
  payload: {
    src: 'builder.nodes.imageDisplay.state.dummydata.acceptanceurl',
    assetid: ' ',
    componentSize: ComponentSize.SMALL,
    altText: 'builder.nodes.imageDisplay.state.dummydata.acceptance',
  },
  displayName: 'Image Display',
  nodeType: 'imageDisplayStateEntry',
  handleOutCount: 1,
};
