import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  message: {
    id: 'builder.nodes.SingleDisplay.message',
    defaultMessage: 'Das ist deine Nachricht.',
  },
});

/**
 * Single Display ist actually a MultipleDisplay with only one message
 * and a topics bubble
 */
export const singleDisplayStateEntry: StateEntryProps = {
  version: 1,
  type: 'renderSingleDisplay',
  temporary: false,
  payload: {
    message: 'builder.nodes.SingleDisplay.message',
    getValueFrom: '',
    getStringValues: [''],
    getTopicsValuesFrom: [], // ReadWriteTyoes are defined in GetTopicsValueFrom.tsx
  },
  getValueType: [ReadWriteType.string],
  displayName: 'Single Display',
  nodeType: 'singleDisplayStateEntry',
  handleOutCount: 1,
};
