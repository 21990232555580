import { Handle, NodeProps, Position } from 'reactflow';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { Description } from '../Description';

import { useGlobalStyles } from './base.node';
import { CustomNodeMenu } from './base.nodemenu';

/*
  AI SUmmarize
*/

export const AiSummarizeNode: React.FC<NodeProps<any>> = (nodeProps: any) => {
  const classes = useGlobalStyles();
  const intl = useIntl();
  const payload = nodeProps.data.state.entry[0].payload;
  const nodeType = nodeProps.data.state.entry[0].nodeType;

  return (
    <>
      <Handle
        type="target"
        id={nodeProps.data.state.entry[0].nodeType + 'in'}
        position={Position.Top}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
      <div
        className={
          nodeProps.selected
            ? classes.controlelementstyleselected
            : classes.controlelementstyle
        }
        style={{
          width: '400px',
          background: nodeProps.data.state.entry[0].nodeMiniMapColor,
        }}
      >
        <CustomNodeMenu nodeid={nodeProps.id} />
        <Typography component="span" variant="body2">
          <Description nodetype={nodeType} propname="description" />
          <br />
          {intl.formatMessage({
            id: 'builder.nodes.AiSummarizeNode.vars',
            defaultMessage:
              'Der Inhalt der folgenden Variablen werden zusammengefasst:',
          })}{' '}
          <strong>{payload.getValuesFrom.join(', ')}</strong>
          <br />
          &nbsp;
          <br />
          {intl.formatMessage({
            id: 'builder.nodes.AiSummarizeNode.resvar',
            defaultMessage:
              'Die Zusammenfassung wird in dieser Variable gespeichert:',
          })}{' '}
          <strong>{payload.saveResultTo}</strong>
        </Typography>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id={nodeProps.data.state.entry[0].nodeType + 'out'}
        isConnectable={nodeProps.isConnectable}
        className={classes['react-flow__handle']}
      />
    </>
  );
};
