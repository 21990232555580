import { defineMessages } from 'react-intl';

import { ReadWriteType, StateEntryProps } from '.';

defineMessages({
  headline: {
    id: 'builder.nodes.postItInput.state.headline',
    defaultMessage: 'Überschrift',
  },
  title: {
    id: 'builder.nodes.postItInput.state.title',
    defaultMessage: 'Titel',
  },
  buttonText: {
    id: 'builder.nodes.postItInput.state.buttonText',
    defaultMessage: 'Weiter',
  },
});

export const postItInputStateEntry: StateEntryProps = {
  type: 'renderPostItInput',
  temporary: true,
  payload: {
    headline: 'builder.nodes.postItInput.state.headline',
    buttonText: 'builder.nodes.postItInput.state.buttonText',
    title: 'builder.nodes.postItInput.state.title',
    saveResultTo: '',
    getValueFrom: '',
  },
  saveResultType: ReadWriteType.string,
  getValueType: ReadWriteType.string,
  displayName: 'Post-It Input',
  nodeType: 'postItInputStateEntry',
  handleOutCount: 1,
};
