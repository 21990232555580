import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from '@mui/material';

import { authorizedPut } from '../../../api/authorizedApi';

import { LabelEdit } from './LabelEdit';

interface UserLabelDialogProps {
  initialLabels?: string[];
  accountid: string;
  isOpen: boolean;
  onClose: Function;
  onUpdate: Function;
}

export const UserLabelDialog: React.FC<UserLabelDialogProps> = ({
  initialLabels = [],
  isOpen,
  onClose,
  onUpdate,
  accountid,
}) => {
  const intl = useIntl();
  const [labels, setLabels] = useState<string[]>(initialLabels);

  if (!isOpen) {
    return null;
  }

  const updateLabels = async () => {
    const updateBody = { metainfos: { labels: labels } };

    const updateCall = authorizedPut(`/account/${accountid}`, updateBody);
    await updateCall();

    if (onUpdate) onUpdate(labels);
  };

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'builder.userlabeldialog.title',
          defaultMessage: 'Benutzern Labels zuweisen',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" component="span">
          {intl.formatMessage({
            id: 'builder.userlabeldialog.content',
            defaultMessage:
              'Hier kannst du jedem Benutzer Labels zuweisen oder die Labels löschen. Tipp einfach drauflos und mit der Return-Taste kannst du ein neues Label hinzufügen.',
          })}
          <LabelEdit
            initialLabels={initialLabels}
            sx={{ marginTop: '10px' }}
            noLabelsText={intl.formatMessage({
              id: 'builder.userlabeldialog.nolabels',
              defaultMessage: 'Es sind noch keine Labels vergeben',
            })}
            onUpdate={(labels: string[]) => setLabels(labels)}
            onClose={() => updateLabels()}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {intl.formatMessage({
            id: 'builder.userlabeldialog.button.close',
            defaultMessage: 'Abbrechen',
          })}
        </Button>
        <Button color="secondary" onClick={updateLabels}>
          {intl.formatMessage({
            id: 'builder.userlabeldialog.button.save',
            defaultMessage: 'Übernehmen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
