import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Position, NodeToolbar } from 'reactflow';
// import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import { Box, Stack, Tooltip } from '@mui/material';

import { ReactFlowNodeEventContext } from '../ModuleEditor/ReactFlowNodeEventContext';

export interface CustomNodeMenuProps {
  nodeid: string;
  display?: boolean;
}

export const CustomNodeMenu: React.FC<CustomNodeMenuProps> = ({ nodeid }) => {
  const intl = useIntl();
  const { onPushNode, onCutNode, onDuplicateNode, displayMenu } = useContext(
    ReactFlowNodeEventContext
  );

  if (!displayMenu) return <></>;

  return (
    <NodeToolbar position={Position.Left}>
      <Box
        bgcolor="#cccccc"
        padding="6px"
        borderRadius="6px"
        border="2px solid white"
        fontSize={10}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          gap="7px"
        >
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage({
              id: 'builder.nodes.nodemenu.duplicate',
              defaultMessage: 'Duplizieren',
            })}
          >
            <DifferenceOutlinedIcon
              fontSize="small"
              color="secondary"
              onClick={() => onDuplicateNode(nodeid)}
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage({
              id: 'builder.nodes.nodemenu.cut',
              defaultMessage: 'Ausschneiden',
            })}
          >
            <ContentCutIcon
              fontSize="small"
              color="secondary"
              onClick={() => onCutNode(nodeid)}
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage({
              id: 'builder.nodes.nodemenu.movedown',
              defaultMessage: 'Nach unten schieben',
            })}
          >
            <ArrowDownwardIcon
              fontSize="small"
              color="secondary"
              onClick={() => onPushNode(nodeid, 150)}
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            arrow
            title={intl.formatMessage({
              id: 'builder.nodes.nodemenu.moveup',
              defaultMessage: 'Nach oben schieben',
            })}
          >
            <ArrowUpwardIcon
              fontSize="small"
              color="secondary"
              onClick={() => onPushNode(nodeid, -150)}
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
        </Stack>
      </Box>
    </NodeToolbar>
  );
};
